import React, { useEffect, useState } from "react";
import { FetchJobdata } from "./hooks/getJobData";
const FilterJobContext = React.createContext();

export const FilterJobContextProvider = (props) => {
  const appURL = process.env.REACT_APP_JOB;

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredJobList, setFilteredJobList] = useState([]);

  const [categoryList, setCategoryList] = useState([]);
  const [category, setCategory] = useState("");
  const [categoryLabel, setCategoryLabel] = useState("");

  useEffect(() => {
    const params = {
      AuthCode: "r1d3r",
      Flag: "CC",
      Type: "POST",
      FetchURL: `${appURL}api/public/job`,
    };

    FetchJobdata(params)
      .then(function (result) {
        if (result.StatusCode === 200) {
          const postResult = result.Values || []; // Access Values array
          setCategoryList(postResult);
        } else {
          setCategoryList([]);
        }
      })
      .catch((err) => {
        setCategoryList([]);
      });
  }, []);

  const [companyList, setCompanyList] = useState([]);

  useEffect(() => {
    const params = {
      AuthCode: "r1d3r",
      Flag: "PC",
      Type: "POST",
      FetchURL: `${appURL}api/public/job`,
    };

    FetchJobdata(params)
      .then(function (result) {
        if (result.StatusCode === 200) {
          const postResult = result.Values || []; // Access Values array
          setCompanyList(postResult);
        } else {
          setCompanyList([]);
        }
      })
      .catch((err) => {
        setCompanyList([]);
      });
  }, []);
  return (
    <FilterJobContext.Provider
      value={{
        appURL,

        setSearchTerm,
        searchTerm,
        filteredJobList,
        setFilteredJobList,
        categoryList,
        setCategoryList,
        category,
        setCategory,
        companyList,
        categoryLabel,
        setCategoryLabel,
      }}
    >
      {props.children}
    </FilterJobContext.Provider>
  );
};

export default FilterJobContext;
