import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import EliteJobContext from "./EliteJobContext";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { FetchJobdata } from "../../hooks/getJobData";
import { GetEnglishDate, GetNepaliDate } from "../../hooks/dateConvertor";

function EliteJobState(props) {
  const { jobURL } = useContext(SidebarContext);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const [popup, setPopup] = useState(false);

  const togglePopup = () => {
    setPopup(!popup);
  };

  const [applicantPop, setApplicantPop] = useState(false);
  const [editPop, setEditPop] = useState(false);

  const toggleEditPopup = () => {
    setEditPop(!editPop);
  };

  const [jobFormError, setJobFormError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [editIsSubmit, setEditIsSubmit] = useState(false);
  const [loading, setLoading] = useState(true);

  const [closeChecked, setCloseChecked] = useState(false);

  const [categorySlug, setCategorySlug] = useState("");
  const [description, setDescription] = useState("");
  const [qualification, setQualification] = useState("");
  const [responsibility, setResponsibility] = useState("");
  var d = new Date();
  const jobvalue = {
    department: "",
    subDepartment: "",
    designation: "",
    shifts: "Morning",
    shiftType: "Weekly",
    jobType: "",
    title: "",
    description: "",
    responsibility: "",
    position: "",
    education: "",
    experience: "",
    startDate: "",
    expiryDate: GetEnglishDate(d),
    salaryFrom: "",
    salaryTo: "",
    interviewDate: GetEnglishDate(d),
    jobQual: "",
    jobResp: "",
    workType: "On-Site",
  };

  const [jobFormValue, setJobFormValue] = useState(jobvalue);

  const [image, setImage] = useState("");
  const [isUploaded, setIsUploaded] = useState(false);
  const [typeFile, setTypeFile] = useState("");

  const [originalList, setOriginalList] = useState(null);
  const [searchList, setSearchList] = useState(null);
  const [negotiable, setNegotiable] = useState(false);

  const [chooseDesignation, setChooseDesignation] = useState("-1");
  const [chooseShift, setChooseShift] = useState("-1");
  const [chooseShiftType, setChooseShiftType] = useState("-1");
  const [chooseJobType, setChooseJobType] = useState("-1");

  const [jobStatus, setJobStatus] = useState("");

  // api to get company info
  const [comInfo, setComInfo] = useState([]);
  useEffect(() => {
    getComInfo();
  }, []);

  const getComInfo = () => {
    const params = {
      ComID: comInfo?.SchID || "" ,
      Flag: "SI",
      Type: "POST",
      FetchURL: `${jobURL}api/job/com-sync`,
    };

    FetchJobdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values[0] ? result.Values[0] : "";
        setComInfo(postResult);
      } else {
        setComInfo([]);
      }
    });
  };

  //API to hit Job List

  const [jobList, setJobList] = useState([]);

  useEffect(() => {
    getJobList();
  }, []);
  const getJobList = () => {
    const params = {
      ComID: comInfo?.SchID || "" ,
      Flag: "S",
      UserID: comInfo?.UserID || "",
      // UserID: userinfo.UserID.toString(),
      Type: "POST",
      FetchURL: `${jobURL}api/admin/job`,
    };

    FetchJobdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setLoading(false);
        setJobList(postResult);
        setOriginalList(postResult);
      } else {
        setJobList([]);
        setOriginalList([]);
      }
    });
  };

  const [titleId, setTitleID] = useState();
  const [designationName, setDesignationName] = useState("");

  const handleEdit = (datas) => {
    setEditPop(true);
    setTitleID(datas.JobID.toString());
    easyList();
    setDesignationName(datas.Designation);
    setCloseChecked(false);
  };
  const [viewPop, setViewPop] = useState(false);
  const toggleViewPopup = () => {
    setViewPop(!viewPop);
  };
  const handleView = (datas) => {
    toggleViewPopup();
    easyList();
    setTitleID(datas.JobID.toString());
  };
  const [viewJob, setViewJob] = useState([]);
  //API to hit Job Info

  // const [staffInfoList, setStaffInfoList] = useState([])
  useEffect(() => {
    easyList();
  }, [titleId]);

  const easyList = () => {
    const params = {
      ComID: comInfo?.SchID || "" ,
      Flag: "SI",
      UserID: userinfo.UserID.toString(),
      JobID: titleId,
      FetchURL: `${jobURL}api/admin/job`,
      Type: "POST",
    };

    FetchJobdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const data = result.Values[0] ? result.Values[0] : "";
        setViewJob(data);
        setJobFormValue({
          designation: parseInt(data.Designation),
          title: data.Title,
          // description: data.JobDescription,
          shifts: data.Shift,
          shiftType: data.ShiftType,
          jobType: data.JobType,
          position: data.NoOfPos,
          education: data.Education,
          experience: data.Experience,
          interviewDate: data.InterviewDate,
          expiryDate: data.EndDate,
          salaryFrom:
            data.Salary === "Negotaible" ? "" : data.Salary.split("-")[0],
          salaryTo:
            data.Salary === "Negotaible" ? "" : data.Salary.split("-")[1],
          // jobQual: data.JobQual,
          // jobResp: data.JobResp,
          workType: data.WorkType,
        });
        setDescription(data.JobDescription);
        setQualification(data.JobQual);
        setResponsibility(data.JobResp);
        setImage(data.Banner);
        setCategorySlug(data.Slug);
        setNegotiable(data.Salary === "Negotaible" ? true : false);
      } else {
        setViewJob([]);
      }
    });
  };

  // to edit job

  const editJobdata = () => {
    const dataForm = {
      ComID: comInfo?.SchID || "" ,
      Flag: "U",
      UserID: userinfo.UserID.toString(),
      JobID: titleId.toString(),
      Title: jobFormValue.title,
      WorkType: jobFormValue.workType,
      Slug: categorySlug,
      Designation: jobFormValue.designation.toString(),
      JobType: jobFormValue.jobType,
      Banner: image !== null ? image.split(",")[1] : "",
      Salary: negotiable
        ? "Negotaible"
        : `${jobFormValue.salaryFrom} - ${jobFormValue.salaryTo}`,
      NoPos: jobFormValue.position,
      Education: jobFormValue.education,
      Experience: jobFormValue.experience,
      JobDescription: description,
      JobQual: qualification,
      JobResp: responsibility,
      // InterviewDate: jobFormValue.interviewDate,
      Shift: jobFormValue.shifts,
      ShiftType: jobFormValue.shiftType,
      ExpiryDate: jobFormValue.expiryDate,
      FetchURL: `${jobURL}api/admin/job`,
      Type: "POST",
    };
    FetchJobdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getJobList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
          theme: "light",
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        });
      }
    });
  };

  // to edit job ends

  //API to delete Job

  // //Everything to pop up and delete the notification list
  const [deletePopup, setDeletePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const [delId, setDelId] = useState("");

  const handleDelete = async (datas) => {
    setDeletePopup({
      show: true,
    });
    setDelId(datas);
  };

  // This will perform the deletion and hide the Confirmation Box

  const handleDeleteTrue = async () => {
    const dataForm = {
      ComID: comInfo?.SchID || "" ,
      Flag: "R",
      UserID: comInfo?.UserID || "",
      // UserID: userinfo.UserID.toString(),
      UserName: userinfo.UserName,
      JobID: delId.toString(),
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", Signature: "p0m76" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${jobURL}api/admin/job`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          getJobList();
          toast(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
        } else {
          toast(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
    });
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleDeleteFalse = () => {
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // Ends Here

  //Activate popup starts from here
  //Everything to pop up and approve the  list
  const [activatePopup, setActivatePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });
  // To approve the credit List

  const [Acdata, setACData] = useState();

  const handleActivate = async (data) => {
    setACData(data.JobID);

    setActivatePopup({
      show: true,
      data,
    });
  };

  // This will perform the approve and hide the Confirmation Box

  const handleActivateTrue = () => {
    const dataForm = {
      ComID: comInfo?.SchID || "" ,
      Flag: "AI",
       UserID: comInfo?.UserID || "",
      // UserID: userinfo.UserID.toString(),
      JobID: Acdata.toString(),
      IsActive: "A",
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", Signature: "p0m76" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${jobURL}api/admin/job`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          getJobList();
          toast(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
        } else {
          toast(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
    });
    setActivatePopup({
      show: false,
      data: null,
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleActivateFalse = () => {
    setActivatePopup({
      show: false,
      data: null,
    });
  };

  //Everything to pop up and activate  ends

  //Activate popup starts from here
  //Everything to pop up and approve the  list
  const [inActivatePopup, setInActivatePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });
  // To approve the credit List

  const [inactivedata, setInactiveData] = useState();

  const handleInActivate = async (data) => {
    setInactiveData(data.JobID);

    setInActivatePopup({
      show: true,
      data,
    });
  };

  // This will perform the approve and hide the Confirmation Box

  const handleInActivateTrue = () => {
    const dataForm = {
      ComID:comInfo?.SchID || "" ,
      Flag: "AI",
      UserID: comInfo?.UserID || "",
      // UserID: userinfo.UserID.toString(),
      JobID: inactivedata.toString(),
      IsActive: "I",
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", Signature: "p0m76" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${jobURL}api/admin/job`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          getJobList();
          toast(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
        } else {
          toast(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
    });
    setInActivatePopup({
      show: false,
      data: null,
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleInActivateFalse = () => {
    setInActivatePopup({
      show: false,
      data: null,
    });
  };

  //Everything to pop up and approve the chapter list ends

  // to get designation list
  const [jobDesignationList, setJobDesignatioList] = useState([]);

  useEffect(() => {
    getJobDesignatioList();
  }, []);

  const getJobDesignatioList = () => {
    const params = {
      Flag: "DG",
      Type: "POST",
      FetchURL: `${jobURL}api/admin/job`,
    };

    FetchJobdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setJobDesignatioList(postResult);
      } else {
        setJobDesignatioList([]);
      }
    });
  };

  const [candidatePop, setCandidatePop] = useState(false);
  return (
    <EliteJobContext.Provider
      value={{
        jobFormValue,
        setJobFormValue,
        jobvalue,
        popup,
        setPopup,
        jobFormError,
        setJobFormError,
        isSubmit,
        setIsSubmit,
        loading,
        setLoading,
        applicantPop,
        setApplicantPop,

        image,
        setImage,
        isUploaded,
        setIsUploaded,
        typeFile,
        setTypeFile,
        negotiable,
        setNegotiable,
        editPop,
        setEditPop,
        editIsSubmit,
        setEditIsSubmit,
        originalList,
        setOriginalList,

        jobList,
        getJobList,
        handleEdit,
        titleId,
        setJobList,

        searchList,

        chooseDesignation,
        setChooseDesignation,
        chooseShift,
        setChooseShift,
        chooseShiftType,
        setChooseShiftType,
        chooseJobType,
        setChooseJobType,

        jobStatus,
        setJobStatus,
        togglePopup,
        closeChecked,
        setCloseChecked,
        toggleEditPopup,
        editJobdata,

        deletePopup,
        handleDeleteTrue,
        handleDeleteFalse,
        handleDelete,

        categorySlug,
        setCategorySlug,

        handleActivate,
        handleInActivate,
        activatePopup,
        inActivatePopup,
        handleActivateTrue,
        handleActivateFalse,
        handleInActivateTrue,
        handleInActivateFalse,

        jobDesignationList,
        setJobDesignatioList,
        handleView,
        viewJob,
        viewPop,
        setViewPop,
        toggleViewPopup,
        description,
        setDescription,
        qualification,
        setQualification,
        responsibility,
        setResponsibility,
        designationName,

        candidatePop,
        setCandidatePop,
        comInfo
      }}
    >
      {props.children}
    </EliteJobContext.Provider>
  );
}
export default EliteJobState;
