import React, { useContext, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import { showImgPreview } from "../hooks/ImagePreview";
import SidebarContext from "../sidebarcontext/SidebarContext";
import "../TabbedView/TabbedView.css";
import Basic from "./Basic";
import Family from "./Family";
import "./StudentProfile.css";
import AcademicHeader from "../AcademicHeader";

export default function StudentProfile() {
  const { AcademicYear, todayDate } = useContext(SidebarContext);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const [imgPrv, setImgPrv] = useState(false);
  const [imagePre, setImagePre] = useState("");

  const [activeTab, setActiveTab] = useState({
    tab1: true,
    tab2: false,
  });
  const handleTab1 = () => {
    setActiveTab({
      tab1: true,
      tab2: false,
    });
  };
  const handleTab2 = () => {
    setActiveTab({
      tab1: false,
      tab2: true,
    });
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
        <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">Profile</div>
            <div className="page-date">
              <div className="sec-content">
                <AcademicHeader
                  todayDate={todayDate}
                  AcademicYear={AcademicYear}
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="sec-dataTable content-section main-content">
          <div className="profile-info">
            <div className="profilecontentLogo tl">
              <div className="profilemgmtImg tl">
                <img
                  src={userinfo.UserImage}
                  alt="dp"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setImagePre(userinfo.UserImage);
                    setImgPrv(true);
                  }}
                />
              </div>
            </div>
            <div>
              <h6 style={{ paddingLeft: "10px", textAlign: "left" }}>
                {userinfo.FullName}
              </h6>
              <p style={{ textAlign: "left", paddingLeft: "10px" }}>
                Class:&nbsp;{userinfo.ClassName}, Section:&nbsp;
                {userinfo.SectionName}, Roll No.:&nbsp;{userinfo.RollNo}
                <br />
                Admission Date:&nbsp;{userinfo.AdmissionDate} <br />
                {userinfo.SchName}
                <br />
              </p>
            </div>
          </div>

          <div className="tabview ">
            <div className="insidePopup--div">
              <ul>
                <li
                  className={activeTab.tab1 === true ? "active" : ""}
                  onClick={handleTab1}
                >
                  <i className="fa fa-file-text-o mr-1 icon"></i>Basic
                  Information{" "}
                </li>
                <li
                  className={activeTab.tab2 === true ? "active" : ""}
                  onClick={handleTab2}
                >
                  <i className="fa fa-file-text-o mr-1 icon"></i>Family
                  Information{" "}
                </li>
              </ul>
            </div>
          </div>

          <div>
            {activeTab.tab1 && <Basic userinfo={userinfo} />}
            {activeTab.tab2 && <Family userinfo={userinfo} />}
          </div>
        </div>
      </div>
      {imgPrv &&
        showImgPreview({
          img: imagePre,
          setTrigger: setImgPrv,
        })}
    </>
  );
}
