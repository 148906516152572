import React from "react";
import { FetchNewdata } from "./hooks/getData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function AcademicPop({
  appURL,
  AcademicYearList,
  closeViewPopup,
  userinfo,
}) {
  const handleClick = (data) => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserName: userinfo.UserName,
      AcademicYear: data.toString(),
      Academicyear: data.toString(),
      Flag: "C",
      Type: "POST",
      FetchURL: `${appURL}api/academic-year`,
    };

    FetchNewdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        closeViewPopup();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        closeViewPopup();
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };
  return (
    <>
      <div className="container-fluid basicform">
        {AcademicYearList.map((item, index) => {
          return (
            <div
              className={`${
                userinfo.AcademicYear.toString() === item.AcademicYear
                  ? "active"
                  : ""
              } academic__list`}
              key={index}
              onClick={() => handleClick(item.AcademicYear)}
            >
              {item.AcademicYear}
            </div>
          );
        })}
      </div>
      <div className="FormFooter">
        <div className="checkedClose"></div>
        <div>
          {/* <button className='btn btn-sm btn-cmpy ml-2' style={{ background: "green" }} onClick={formSubmit} >Submit</button> */}
          <button className="btn btn-sm btn-cmpy ml-2" onClick={closeViewPopup}>
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}
