import React, { useEffect } from "react";
import img3 from "./../../images/school/img3.jpg";
import Aos from "aos";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import {
  FaCalendar,
  FaCalendarAlt,
  FaClock,
  FaLocationArrow,
  FaMapMarkedAlt,
} from "react-icons/fa";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useState } from "react";
import { Fetchdata } from "../hooks/getData";
const Event = ({ schoolInfo }) => {
  const appURL = process.env.REACT_APP_URL;
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  useEffect(() => {
    Aos.init();
  }, []);
  //API for class list
  const [eventList, setEventList] = useState([]);
  useEffect(() => {
    getEventList();
  }, [schoolInfo]);

  const getEventList = () => {
    const params = {
      SchID: "ES25",
      // SchID: schoolInfo?.SchID,
      Count: "-1",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/upcoming-event`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.CalendarEventLst
          ? result.CalendarEventLst
          : "";
        setEventList(postResult);
      } else {
        setEventList([]);
      }
    });
  };
  return (
    <>
      {eventList.length > 0 && (
        <div className="section-b event__section" id="event__section">
          <div className="uk-container uk-container-xsmall">
            <div
              class=" new__title uk-text-left"
              data-aos="fade-up"
              data-aos-anchor-placement="center-bottom"
              data-aos-duration="1000"
            >
              <h2
                className="the__title small"
                style={{
                  color: `${schoolInfo.ScColor}`,
                }}
              >
                Our Events
              </h2>
              <p className="sub__heading">See our Latest Events</p>
            </div>

            <div className="my__calendar mx-auto" style={{ maxWidth: "300px" }}>
              <Calendar
                className="form-control form-control-sm mb-1"
                dateFormat="YYYY/MM/DD"
                theme="default"
                // hideDefaultValue={true}
                language="en"
                // placeholder={newMonth}
                // values={reportDateValue.fromDate}
                // onChange={handleFormDate}
              />
            </div>
            <div className="my__sidebar mt-4">
              <div className="my__carousel">
                <OwlCarousel
                  className="owl-theme"
                  dots={false}
                  // autoWidth
                  // loop
                  autoHeight
                  margin={30}
                  items={1}
                  nav
                >
                  {eventList.map((item) => (
                    <div key={item.EventID}>
                      <div className="uk-flex uk-flex-wrap uk-flex-between">
                        <div>
                          <h3 className="the__title big m-0">{item.Title}</h3>
                        </div>

                        <div>
                          <span className="uk-text-bold sub__title">
                            <FaCalendarAlt style={{ marginTop: "-2px" }} />{" "}
                            {item.NepaliDate}
                          </span>
                        </div>
                      </div>

                      <div className="the__content small">
                        <p>{item.Description}</p>
                      </div>

                      <div className="mt-4 uk-text-bold sub__title uk-text-left">
                        <FaMapMarkedAlt
                          className="me-1"
                          style={{ marginTop: "-2px" }}
                        />
                        {item.Venue}
                      </div>

                      <hr />

                      <div className="uk-flex uk-flex-between">
                        <div>
                          {item.IsHoliday === "Y" && (
                            <div className="sub__title holiday m-0">
                              Holiday
                            </div>
                          )}
                        </div>

                        <div>
                          <div className="sub__title">
                            <FaClock
                              style={{
                                marginTop: "-2px",
                                marginRight: "3px",
                              }}
                            />

                            {item.DaysRemaining < 0 &&
                              item.DaysRemaining !== -1 && (
                                <>{Math.abs(item.DaysRemaining)} days ago</>
                              )}
                            {item.DaysRemaining === -1 && <>Yesterday</>}
                            {item.DaysRemaining === 0 && <>Today</>}
                            {item.DaysRemaining === 1 && <>Tommorow</>}
                            {item.DaysRemaining > 0 &&
                              item.DaysRemaining !== 1 && (
                                <>{item.DaysRemaining} days remaining</>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </OwlCarousel>

                <div
                  className="my__new__overlay"
                  style={{
                    background: `${schoolInfo.ScColor}`,
                    opacity: 0.1,
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Event;
