import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";
import "tippy.js/dist/tippy.css";
import { Fetchdata } from "../hooks/getData";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Tippy from "@tippyjs/react";

export default function SubjectWiseSummary() {
  const { customStyles, appURL, currentYear } = useContext(SidebarContext);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);

  const [reportOption, setReportOption] = useState("");

  const handleReport = (e) => {
    const target = e.target;
    const value = target.value;
    setReportOption(value);
  };

  let date = [];
  for (let i = 2075; i <= currentYear; i++) {
    date.push(i);
  }

  const [selectYear, setSelectYear] = useState(currentYear);

  const handleYear = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectYear(value);
  };

  const [selectMonth, setSelectMonth] = useState("");

  const handleMonth = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectMonth(value);
  };

  const columns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },

    {
      name: reportOption === "Y" ? "Year" : "Month",

      selector: (row) =>
        reportOption === "Y"
          ? row.Year
          : reportOption === "M"
          ? row.MonthID === "01"
            ? "Baishakh"
            : row.MonthID === "02"
            ? "Jestha"
            : row.MonthID === "03"
            ? "Ashar"
            : row.MonthID === "04"
            ? "Shrawan"
            : row.MonthID === "05"
            ? "Bhadra"
            : row.MonthID === "06"
            ? "Asoj"
            : row.MonthID === "07"
            ? "Kartik"
            : row.MonthID === "08"
            ? "Mangsir"
            : row.MonthID === "09"
            ? "Poush"
            : row.MonthID === "10"
            ? "Magh"
            : row.MonthID === "11"
            ? "Falgun"
            : "Chaitra"
          : "",
    },
    {
      name: "Subject",

      selector: (row) => row.Subject,
    },

    {
      name: "Present",

      selector: (row) => row.PresentCT,
    },
    {
      name: "Absent",

      selector: (row) => row.AbsentCT,
    },
    {
      name: "Working Days",
      selector: (row) => parseInt(row.PresentCT) + parseInt(row.AbsentCT),
    },
  ];

  // API for attendance list
  const [attendanceList, setAttendanceList] = useState([]);

  useEffect(() => {
    getList();
  }, [reportOption, selectYear, selectMonth]);

  const getList = () => {
    const specificParams = {
      SchID: userinfo.SchID,
      MemID: userinfo.UserID,
      Flag: "SS",
      IsSubjective: "Y",
      DFlag: "N",
      DtFlag: reportOption === "Y" ? "SY" : reportOption === "M" ? "SM" : "",
      Date:
        reportOption === "Y"
          ? selectYear
          : reportOption === "M"
          ? selectYear + "/" + selectMonth
          : "",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/attendance`,
    };

    Fetchdata(specificParams).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.AttenLst ? result.AttenLst : "";
        setLoading(false);
        setAttendanceList(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        setAttendanceList([]);
        setOriginalList([]);
      }
    });
  };

  //This is for search purposes only

  const searchInput = useRef("");

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["Subject"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setAttendanceList(srchResult);
      } else {
        setAttendanceList({});
      }
    } else {
      setAttendanceList(originalList);
    }
  };

  /* This is for export csv */

  const dataToExcelYear = attendanceList.map((d, i) => ({
    "S.N.": i + 1,
    Year: d.Year,
    Subject: d.Subject,
    Present: d.PresentCT,
    Absent: d.AbsentCT,
    "Working Days": parseInt(d.PresentCT) + parseInt(d.AbsentCT),
  }));
  const dataToExcelMonth = attendanceList.map((d, i) => ({
    "S.N.": i + 1,
    Month:
      d.MonthID === "01"
        ? "Baishakh"
        : d.MonthID === "02"
        ? "Jestha"
        : d.MonthID === "03"
        ? "Ashar"
        : d.MonthID === "04"
        ? "Shrawan"
        : d.MonthID === "05"
        ? "Bhadra"
        : d.MonthID === "06"
        ? "Asoj"
        : d.MonthID === "07"
        ? "Kartik"
        : d.MonthID === "08"
        ? "Mangsir"
        : d.MonthID === "09"
        ? "Poush"
        : d.MonthID === "10"
        ? "Magh"
        : d.MonthID === "11"
        ? "Falgun"
        : "Chaitra",
    Subject: d.Subject,
    Present: d.PresentCT,
    Absent: d.AbsentCT,
    "Working Days": parseInt(d.PresentCT) + parseInt(d.AbsentCT),
  }));

  const toExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(
        reportOption === "Y" ? dataToExcelYear : dataToExcelMonth
      );
      var wscols = [
        { wch: 5 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ];
      worksheet["!cols"] = wscols;
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "Attendance_Summary_Subject");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_" + "export" + EXCEL_EXTENSION
        );
      }
    });
  };

  //end

  //This is to export pdf
  // pdf condition for year
  const dataWithIndexYear = attendanceList.map((d, i) => ({
    ...d,
    Index: i + 1,
    "Working Days": parseInt(d.PresentCT) + parseInt(d.AbsentCT),
  }));

  const colsYear = [
    { header: "S.N", field: "Index" },
    { header: "Year", field: "Year" },
    { header: "Subject", field: "Subject" },
    { header: "Present", field: "PresentCT" },
    { header: "Absent", field: "AbsentCT" },
    { header: "Working Days", field: "Working Days" },
  ];

  const exportColumnsYear = colsYear.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  // pdf condition for year ends

  // pdf condition for month

  const dataWithIndexMonth = attendanceList.map((d, i) => ({
    ...d,
    Index: i + 1,
    "Working Days": parseInt(d.PresentCT) + parseInt(d.AbsentCT),
    Month:
      d.MonthID === "01"
        ? "Baishakh"
        : d.MonthID === "02"
        ? "Jestha"
        : d.MonthID === "03"
        ? "Ashar"
        : d.MonthID === "04"
        ? "Shrawan"
        : d.MonthID === "05"
        ? "Bhadra"
        : d.MonthID === "06"
        ? "Asoj"
        : d.MonthID === "07"
        ? "Kartik"
        : d.MonthID === "08"
        ? "Mangsir"
        : d.MonthID === "09"
        ? "Poush"
        : d.MonthID === "10"
        ? "Magh"
        : d.MonthID === "11"
        ? "Falgun"
        : "Chaitra",
  }));

  const colsMonth = [
    { header: "S.N", field: "Index" },
    { header: "Month", field: "Month" },
    { header: "Subject", field: "Subject" },
    { header: "Present", field: "PresentCT" },
    { header: "Absent", field: "AbsentCT" },
    { header: "Working Days", field: "Working Days" },
  ];

  const exportColumnsMonth = colsMonth.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  // pdf condition for month ends

  const toPdf = () => {
    const doc = new jsPDF("p", "pt", "a4", true);

    var midPage = doc.internal.pageSize.getWidth() / 2;

    // doc.addImage(`${userinfo.SchPhoto}`, "JPG", 40, 40, 70, 60);

    doc.setFontSize(18);
    doc.text(`${userinfo.SchName}`, midPage, 50, null, null, "center");

    doc.setFontSize(15);
    doc.text(`${userinfo.SchAddress}`, midPage, 68, null, null, "center");

    doc.setFontSize(10);
    doc.text(
      `Tel:${userinfo.Phone}, E-mail:${userinfo.Email}`,
      midPage,
      85,
      null,
      null,
      "center"
    );

    doc.autoTable({
      startY: 100,
      theme: "grid",
      columns: reportOption === "Y" ? exportColumnsYear : exportColumnsMonth,
      body: reportOption === "Y" ? dataWithIndexYear : dataWithIndexMonth,
    });
    doc.save("Attendance_Summary_Subject.pdf");
  };
  //end

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      {/* <div>
                <ul>{date.map(name => <li key={name}> {name} </li>)}</ul>
            </div> */}
      <div className="sec-dataTable">
        <div className="upper-dataTbl">
          <Tippy content="PDF">
            <button className="exportButtons ml-1" onClick={toPdf}>
              <i className="bi bi-file-earmark-pdf-fill exportButtonIconsPdf"></i>
            </button>
          </Tippy>
          <Tippy content="Excel">
            <button className="exportButtons" onClick={toExcel}>
              <i className="bi bi-file-earmark-excel-fill exportButtonIconsExcel"></i>
            </button>
          </Tippy>
        </div>

        <hr className="new-hr-new" />
        {loading ? (
          <Spinner />
        ) : (
          <DataTable
            columns={columns}
            data={attendanceList}
            customStyles={customStyles}
            pagination
            fixedHeader
            fixedHeaderScrollHeight="280px"
            highlightOnHover
            pointerOnHover
            responsive
            dense
            striped
            subHeader
            subHeaderAlign="left"
            subHeaderComponent={
              <>
                <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                  <label className="list-label">Select Option</label>
                  <select
                    style={{ fontSize: "11px" }}
                    name="status"
                    className="form-control form-control-sm"
                    aria-label="Default select example "
                    value={reportOption}
                    onChange={handleReport}
                  >
                    <option
                      selected
                      disabled
                      value=""
                      style={{ fontSize: "11px" }}
                    >
                      Select Option
                    </option>
                    <option value="Y">Year</option>
                    <option value="M">Month</option>
                  </select>
                  <i class="fas fa-angle-down  notificationpositon"></i>
                </div>

                {reportOption === "Y" || reportOption === "M" ? (
                  <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                    <label className="list-label">Year</label>
                    <select
                      style={{ fontSize: "11px", marginBottom: "3px" }}
                      name="snotifiaction"
                      value={selectYear}
                      onChange={handleYear}
                      className="form-control form-control-sm"
                      aria-label="Default select example "
                    >
                      <option
                        value=""
                        disabled
                        selected
                        style={{ fontSize: "11px" }}
                      >
                        Select Year
                      </option>
                      {date
                        .map((item) => (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        ))
                        .reverse()}
                    </select>
                    <i class="fas fa-angle-down  notificationpositon"></i>
                  </div>
                ) : (
                  <></>
                )}
                {reportOption === "M" && (
                  <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                    <label className="list-label">Select Month</label>
                    <select
                      style={{ fontSize: "11px" }}
                      name="status"
                      className="form-control form-control-sm"
                      aria-label="Default select example "
                      value={selectMonth}
                      onChange={handleMonth}
                    >
                      <option
                        selected
                        disabled
                        value=""
                        style={{ fontSize: "11px" }}
                      >
                        Select Month
                      </option>
                      <option value="01">Baishakh</option>
                      <option value="02">Jestha</option>
                      <option value="03">Asar</option>
                      <option value="04">Shawan</option>
                      <option value="05">Bhadra</option>
                      <option value="06">Asoj</option>
                      <option value="07">Kartik</option>
                      <option value="08">Mangsir</option>
                      <option value="09">Poush</option>
                      <option value="10">Magh</option>
                      <option value="11">Falgun</option>
                      <option value="12">Chaitra</option>
                    </select>
                    <i class="fas fa-angle-down  notificationpositon"></i>
                  </div>
                )}

                <div
                  className={
                    reportOption === "Y"
                      ? "col-lg-2 offset-lg-6 offset-md-6 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
                      : reportOption === "M"
                      ? "col-lg-2 offset-lg-4 offset-md-4 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
                      : "col-lg-2 offset-md-8 offset-lg-8 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
                  }
                  style={{ paddingRight: "1px", marginBottom: "10px" }}
                >
                  <div className="d-flex">
                    <input
                      placeholder="Search"
                      ref={searchInput}
                      type="text"
                      className="form-control form-control-sm searchField"
                      onChange={searchHandler}
                    />
                  </div>
                </div>
              </>
            }
          />
        )}
      </div>
    </>
  );
}
