import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import CourseContext from "../contextfolder/CourseContext/CourseContext";
import CourseForm from "./CourseForm";
import DeletePop from "./DeletePop";
import CourseActivate from "./CourseActivate";
import CourseDeactivate from "./CourseDeactivate";
import CourseEdit from "./CourseEdit";
import AcademicHeader from "../AcademicHeader";

export default function CourseList() {
  const { todayDate, customStyles, AcademicYear } = useContext(SidebarContext);
  const {
    loading,
    originalList,
    popup,
    togglePopup,
    setIsSubmit,
    setCourseFormValue,
    setCourseFormError,
    classList,
    termList,
    subjectList,
    courseValue,
    chapterList,
    courseList,
    setCourseList,
    selectClass,
    setSelectClass,
    selectSubject,
    setSelectSubject,
    selectTerm,
    setSelectTerm,
    selectChapter,
    setSelectChapter,

    activatePopup,
    handleActivateTrue,
    handleActivateFalse,
    handleActivate,
    inActivatePopup,
    handleInActivateTrue,
    handleInActivateFalse,
    handleInActivate,
    deletePopup,
    handleDeleteTrue,
    handleDeleteFalse,
    handleDelete,
    toggleEditPopup,
    editPopup,
    setEditIsSubmit,
    editPop,
    setInputFields,
  } = useContext(CourseContext);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const searchInput = useRef("");

  const columns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "Title",
      selector: (row) => row.Title,
    },
    {
      name: "Description",
      selector: (row) => row.Description,
    },
    {
      name: "Duration",
      width: "150px",
      selector: (row) => row.Duration,
    },

    {
      name: "Status",
      // grow: 0,
      center: true,
      width: "120px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              {row.IsActive === "I" ? (
                <>
                  <button
                    type="button"
                    class="badge badge-success"
                    onClick={() => handleActivate(row)}
                  >
                    Activate
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    class="badge badge-danger"
                    onClick={() => handleInActivate(row)}
                  >
                    Deactivate
                  </button>
                </>
              )}
            </div>
          </>
        );
      },
    },

    {
      name: "Action",
      // grow: 0,
      center: true,
      width: "100px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <Tippy content="Edit">
                <i
                  className="fa fa-pencil solvespan--action"
                  onClick={() => editPop(row)}
                ></i>
              </Tippy>

              <Tippy content="Delete">
                <i
                  className="fa fa-trash-o rejectspan--action"
                  onClick={() => handleDelete(row.CoursePlanID)}
                ></i>
              </Tippy>
            </div>
          </>
        );
      },
    },
  ];

  const closePopup = (e) => {
    e.preventDefault();
    togglePopup();
    setCourseFormValue(courseValue);
    setCourseFormError({});
    setIsSubmit(false);
  };

  const closeEditPopup = (e) => {
    e.preventDefault();
    toggleEditPopup();
    setCourseFormError({});
    setEditIsSubmit(false);
  };

  const addnew = (e) => {
    e.preventDefault();
    togglePopup();
    setIsSubmit(false);
    setCourseFormValue(courseValue);
  };

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["Title"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setCourseList(srchResult);
      } else {
        setCourseList({});
      }
    } else {
      setCourseList(originalList);
    }
  };

  const handleClass = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectClass(value);
    setSelectSubject("");
    setSelectChapter("");
    setSelectTerm("");
  };

  const handleSubject = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectSubject(value);
    setSelectChapter("");
    setSelectTerm("");
  };

  const handleChapter = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectChapter(value);
    setSelectTerm("");
  };
  const handleTerm = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectTerm(value);
  };

  /* This is for export csv */

  // const dataToExcel = courseList.map((d, i) => ({
  //     "S.N.": i + 1,
  //     "Title": d.ChapterTitle,
  //     "Duration": d.ChapterDuration,
  // }));

  // const toExcel = () => {
  //     import("xlsx").then((xlsx) => {
  //         const worksheet = xlsx.utils.json_to_sheet(dataToExcel);
  //         var wscols = [
  //             { wch: 5 },
  //             { wch: 20 },
  //             { wch: 20 },
  //         ];
  //         worksheet["!cols"] = wscols;
  //         const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
  //         const excelBuffer = xlsx.write(workbook, {
  //             bookType: "xlsx",
  //             type: "array",
  //         });
  //         saveAsExcelFile(excelBuffer, "Course_Chapter");
  //     });
  // };

  // const saveAsExcelFile = (buffer, fileName) => {
  //     import("file-saver").then((module) => {
  //         if (module && module.default) {
  //             let EXCEL_TYPE =
  //                 "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  //             let EXCEL_EXTENSION = ".xlsx";
  //             const data = new Blob([buffer], {
  //                 type: EXCEL_TYPE,
  //             });

  //             module.default.saveAs(
  //                 data,
  //                 fileName + "_" + "export" + EXCEL_EXTENSION
  //             );
  //         }
  //     });
  // };

  //end

  //This is to export pdf

  // const dataWithIndex = courseList.map((d, i) => ({
  //     ...d,
  //     Index: i + 1,
  // }));

  // const cols = [
  //     { header: "S.N", field: "Index" },
  //     { header: "Chapter", field: "ChapterTitle" },
  //     { header: "Duration", field: "ChapterDuration" },
  // ];

  // const exportColumns = cols.map((col) => ({
  //     title: col.header,
  //     dataKey: col.field,
  // }));

  // const toPdf = () => {
  //     const doc = new jsPDF("p", "pt", "a4", true);

  //     var midPage = doc.internal.pageSize.getWidth() / 2;

  //     // doc.addImage(`${userinfo.SchPhoto}`, "JPG", 40, 40, 70, 60);

  //     doc.setFontSize(18);
  //     doc.text(`${userinfo.SchName}`, midPage, 50, null, null, "center");

  //     doc.setFontSize(15);
  //     doc.text(
  //         `${userinfo.SchAddress}`,
  //         midPage,
  //         68,
  //         null,
  //         null,
  //         "center"
  //     );

  //     doc.setFontSize(10);
  //     doc.text(`Tel:${userinfo.Phone}, E-mail:${userinfo.Email}`, midPage, 85, null, null, "center");

  //     doc.autoTable({
  //         startY: 100,
  //         theme: "grid",
  //         columns: exportColumns,
  //         body: dataWithIndex,
  //     });
  //     doc.save("Course_Chapter.pdf");
  // };
  //end

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
        <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">Course Plan</div>
            <div className="page-date">
              <div className="sec-content">
                <AcademicHeader
                  todayDate={todayDate}
                  AcademicYear={AcademicYear}
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <>
          <div className="sec-dataTable content-section main-content">
            <div className="upper-dataTbl">
              <div className="btn-addlnote">
                <button
                  type="button"
                  class="btn btn-sm"
                  style={{
                    background: "var(--MainColor)",
                    color: "white",
                  }}
                  onClick={addnew}
                >
                  Add New
                </button>
                {/* <div className="exportDiv">
                                    <Tippy content="Excel">
                                        <button className="exportButtons mx-2" onClick={toExcel}>
                                            <i className="bi bi-file-earmark-excel-fill exportButtonIconsExcel"></i>
                                        </button>
                                    </Tippy>

                                    <Tippy content="PDF">
                                        <button className="exportButtons "
                                            onClick={toPdf}
                                        >
                                            <i className="bi bi-file-earmark-pdf-fill exportButtonIconsPdf"></i>
                                        </button>
                                    </Tippy>

                                </div> */}
              </div>
            </div>
            <hr className="new-hr-new" />

            {loading ? (
              <Spinner />
            ) : (
              <DataTable
                columns={columns}
                data={courseList}
                customStyles={customStyles}
                pagination
                fixedHeader
                fixedHeaderScrollHeight="280px"
                highlightOnHover
                pointerOnHover
                responsive
                dense
                striped
                subHeader
                subHeaderAlign="left"
                subHeaderComponent={
                  <>
                    <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                      <label className="list-label">Class</label>
                      <select
                        style={{ fontSize: "11px" }}
                        name="snotifiaction"
                        value={selectClass}
                        onChange={handleClass}
                        className="form-control form-control-sm"
                        aria-label="Default control example "
                      >
                        <option
                          value=""
                          disabled
                          selected
                          style={{ fontSize: "11px" }}
                        >
                          Select Class
                        </option>

                        {classList.map((item) => (
                          <option key={item.ClassID} value={item.ClassID}>
                            {item.ClassName}
                          </option>
                        ))}
                      </select>
                      <i class="fas fa-angle-down  notificationpositon"></i>
                    </div>

                    <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                      <label className="list-label">Subject</label>
                      <select
                        style={{ fontSize: "11px" }}
                        name="snotifiaction"
                        value={selectSubject}
                        onChange={handleSubject}
                        className="form-control form-control-sm"
                        aria-label="Default select example"
                      >
                        <option
                          value=""
                          disabled
                          selected
                          style={{ fontSize: "11px" }}
                        >
                          Select Subject
                        </option>

                        {subjectList.map((item) => (
                          <option key={item.SubjectID} value={item.SubjectID}>
                            {item.SubjectName}
                          </option>
                        ))}
                      </select>
                      <i class="fas fa-angle-down  notificationpositon"></i>
                    </div>

                    <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                      <label className="list-label">Chapter</label>
                      <select
                        style={{ fontSize: "11px" }}
                        name="snotifiaction"
                        value={selectChapter}
                        onChange={handleChapter}
                        className="form-control form-control-sm"
                        aria-label="Default select example"
                      >
                        <option
                          value=""
                          disabled
                          selected
                          style={{ fontSize: "11px" }}
                        >
                          Select Chapter
                        </option>

                        {chapterList.map((item) => (
                          <option key={item.ChapterID} value={item.ChapterID}>
                            {item.ChapterTitle}
                          </option>
                        ))}
                      </select>
                      <i class="fas fa-angle-down  notificationpositon"></i>
                    </div>

                    <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                      <label className="list-label">Term</label>
                      <select
                        style={{ fontSize: "11px" }}
                        name="snotifiaction"
                        value={selectTerm}
                        onChange={handleTerm}
                        className="form-control form-control-sm"
                        aria-label="Default select example"
                      >
                        <option
                          value=""
                          disabled
                          selected
                          style={{ fontSize: "11px" }}
                        >
                          Select Term
                        </option>

                        {termList.map((item) => (
                          <option key={item.TermID} value={item.TermID}>
                            {item.TermName}
                          </option>
                        ))}
                      </select>
                      <i class="fas fa-angle-down  notificationpositon"></i>
                    </div>

                    <div
                      className="col-lg-2 offset-lg-2 offset-md-2 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
                      style={{
                        marginTop: "25px",
                        paddingRight: "1px",
                        marginBottom: "10px",
                      }}
                    >
                      <div className="d-flex">
                        <input
                          placeholder="Search"
                          ref={searchInput}
                          type="text"
                          className="form-control form-control-sm searchField"
                          onChange={searchHandler}
                        />
                      </div>
                    </div>
                  </>
                }
              />
            )}
          </div>
        </>
      </div>

      {popup && (
        <div className="modals">
          <div className="overlay"></div>

          <div className="notification-model-content">
            <div className="popUpHeader ">
              <div className="popUpHeaderText ">Add Course Plan</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closePopup}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="inside-modal">
              <CourseForm closePopup={closePopup} />
            </div>
          </div>
        </div>
      )}

      {editPopup && (
        <div className="modals">
          <div className="overlay"></div>

          <div className="notification-model-content">
            <div className="popUpHeader ">
              <div className="popUpHeaderText ">Course Plan Edit</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closeEditPopup}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="inside-modal">
              <CourseEdit closeEditPopup={closeEditPopup} />
            </div>
          </div>
        </div>
      )}

      {deletePopup.show && (
        <DeletePop
          handleDeleteTrue={handleDeleteTrue}
          handleDeleteFalse={handleDeleteFalse}
        />
      )}

      {activatePopup.show && (
        <CourseActivate
          handleActivateTrue={handleActivateTrue}
          handleActivateFalse={handleActivateFalse}
        />
      )}

      {inActivatePopup.show && (
        <CourseDeactivate
          handleInActivateTrue={handleInActivateTrue}
          handleInActivateFalse={handleInActivateFalse}
        />
      )}
    </>
  );
}
