import React, { useContext, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import SidebarContext from "../sidebarcontext/SidebarContext";
import "../TabbedView/TabbedView.css";
import Admin from "./Admin";
import RolePermission from "./RolePermission";
import AcademicHeader from "../AcademicHeader";

export default function AdminList() {
  const { AcademicYear, todayDate } = useContext(SidebarContext);
  const rolePermission = JSON.parse(localStorage.getItem("rolePermission"));
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [activeTab, setActiveTab] = useState({
    tab1: true,
    tab2: false,
  });
  const handleTab1 = () => {
    setActiveTab({
      tab1: true,
      tab2: false,
    });
  };
  const handleTab2 = () => {
    setActiveTab({
      tab1: false,
      tab2: true,
    });
  };

  // const isTrue = rolePermission.some(
  //   (role) =>
  //     role.UserID === userInfo.UserID &&
  //     role.Permission === "SUPERADMIN"
  // );

  const isTrue =
    rolePermission &&
    Array.isArray(rolePermission) &&
    rolePermission.some(
      (role) =>
        role.UserID === userInfo.UserID && role.Permission === "SUPERADMIN"
    );

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
        <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">
              {activeTab.tab1
                ? "Admin List"
                : activeTab.tab2
                ? "Role & Permission"
                : ""}
            </div>
            <div className="page-date">
              <div className="sec-content">
                <AcademicHeader
                  todayDate={todayDate}
                  AcademicYear={AcademicYear}
                />
              </div>
            </div>
          </div>
        </div>
        <hr />

        <div className="tabview ">
          <div className="insidePopup--div">
            <ul>
              <li
                className={activeTab.tab1 === true ? "active" : ""}
                onClick={handleTab1}
              >
                <i className="fa fa-file-text-o mr-1 icon"></i>Admin List{" "}
              </li>
              {!isTrue && (
                <li
                  className={activeTab.tab2 === true ? "active" : ""}
                  onClick={handleTab2}
                >
                  <i className="fa fa-file-text-o mr-1 icon"></i>Role &
                  Permission{" "}
                </li>
              )}
            </ul>
          </div>
        </div>
        <div className="content-section main-content">
          {activeTab.tab1 && <Admin />}
          {activeTab.tab2 && <RolePermission />}
        </div>
      </div>
    </>
  );
}
