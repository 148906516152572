import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import "../../css/Notification.css";
import SidebarContext from "../sidebarcontext/SidebarContext";
import Spinner from "../loading/spinner";
import StudentContext from "../contextfolder/StudentContext/StudentContext";
import ResetPassword from "./ResetPassword";
import DeletePop from "./DeletePop";
import { showImgPreview } from "../hooks/ImagePreview";
import Select from "react-select";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import ViewStudent from "./ViewStudent";
import AcademicHeader from "../AcademicHeader";

export default function StudentList() {
  const { todayDate, customStylesForImage, AcademicYear, hideAction } =
    useContext(SidebarContext);

  const {
    loading,
    originalList,
    studentList,
    setStudentList,
    resetPopup,
    handleResetFalse,
    handleResetTrue,
    handleReset,
    deletePopup,
    handleDeleteTrue,
    handleDeleteFalse,
    handleDelete,
    changePhoto,
    setSelectedOptions,
    classList,
    sectionList,
    setSelectClass,
    setSelectSection,
    selectClass,
    modal,
    toggleModal,
    addnew,
  } = useContext(StudentContext);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const [hideActionStudent, setHideActionStudent] = useState(false);

  useEffect(() => {
    if (userinfo.UserType === "A") {
      setHideActionStudent(!hideActionStudent);
    }
  }, []);

  const [imgPrv, setImgPrv] = useState(false);
  const [imagePre, setImagePre] = useState("");

  function handleChange(e, studentID) {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        changePhoto(studentID, e.target.result);
      };

      reader.readAsDataURL(e.target.files[0]);
    }
  }

  const handleClass = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectClass(value);
  };

  const handleSection = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectSection(value);
  };

  const closePopup = (e) => {
    e.preventDefault();
    toggleModal();
  };

  const searchInput = useRef("");

  const columns = [
    {
      name: "Roll No.",
      width: "90px",
      center: true,
      selector: (row) => row.RollNo,
    },
    {
      name: "Image",
      center: true,
      width: "90px",
      selector: (row) => {
        return (
          <>
            <div className="staffContentLogo">
              <div className="staffImg">
                <img
                  src={row.Image}
                  alt=""
                  onClick={() => {
                    setImagePre(row.Image);
                    setImgPrv(true);
                  }}
                />
              </div>
            </div>
          </>
        );
      },
    },
    {
      name: "Redg. Number",
      width: "130px",
      selector: (row) => row.RegNum,
    },
    {
      name: "Full Name",
      selector: (row) => row.StdFullName,
    },
    {
      name: "User Name",
      width: "100px",
      selector: (row) => row.StdUserName,
    },
    {
      name: "Gender",
      width: "100px",
      selector: (row) => row.Gender,
    },
    {
      name: "Mobile",
      width: "120px",
      selector: (row) => row.Mobile,
    },

    {
      name: "Address",
      width: "120px",
      selector: (row) => row.Address,
    },

    {
      name: "Action",
      center: true,
      width: "130px",
      omit: hideAction,
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex" style={{ marginTop: "6px" }}>
              <Tippy content="View Student">
                <i
                  className="bi bi-eye viewspan--action"
                  onClick={(e) =>
                    addnew([
                      e,
                      row.StdUserName,
                      row.StudentID,
                      row.ClassID,
                      row.SectionID,
                    ])
                  }
                ></i>
              </Tippy>

              <input
                type="file"
                id={`file-input-${row.StdUserName}`}
                onChange={(e) => handleChange(e, row.StdUserName)}
              />
              <Tippy content="Change Photo">
                <label htmlFor={`file-input-${row.StdUserName}`}>
                  {" "}
                  <i className="fa fa-user-circle-o editspan--action"> </i>{" "}
                </label>
              </Tippy>

              <Tippy content="Reset Password">
                <i
                  className="fa fa-repeat solvespan--action"
                  onClick={() =>
                    handleReset([row.StdUserName, row.StdUserName])
                  }
                ></i>
              </Tippy>
              <Tippy content="Delete">
                <i
                  className="fa fa-trash-o rejectspan--action"
                  onClick={() => handleDelete(row.StdUserName)}
                ></i>
              </Tippy>
            </div>
          </>
        );
      },
    },
    {
      name: "Action",
      center: true,
      width: "100px",
      omit: hideActionStudent,
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex" style={{ marginTop: "6px" }}>
              <Tippy content="View Student">
                <i
                  className="bi bi-eye viewspan--action"
                  onClick={(e) =>
                    addnew([
                      e,
                      row.StdUserName,
                      row.StudentID,
                      row.ClassID,
                      row.SectionID,
                    ])
                  }
                ></i>
              </Tippy>
            </div>
          </>
        );
      },
    },
  ];

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["StdFullName"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setStudentList(srchResult);
      } else {
        setStudentList({});
      }
    } else {
      setStudentList(originalList);
    }
  };

  const dropDownValue = sectionList.map((item) => ({
    value: item.SectionID,
    label: item.SectionName,
  }));

  /* This is for export csv */

  const dataToExcel = studentList.map((d, i) => ({
    "S.N.": i + 1,
    "Redg. Number": d.RegNum,
    "Full Name": d.StdFullName,
    "User Name": d.StdUserName,
    Gender: d.Gender,
    Mobile: d.Mobile,
    "Roll. No.": d.RollNo,
    Address: d.Address,
  }));

  const toExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(dataToExcel);
      var wscols = [
        { wch: 5 },
        { wch: 20 },
        { wch: 20 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ];
      worksheet["!cols"] = wscols;
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "Student");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "export" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  //end

  //This is to export pdf

  const dataWithIndex = studentList.map((d, i) => ({
    ...d,
    Index: i + 1,
  }));

  const cols = [
    { header: "S.N", field: "Index" },
    { header: "Redg. Number", field: "RegNum" },
    { header: "Full Name", field: "StdFullName" },
    { header: "User Name", field: "StdUserName" },
    { header: "Gender", field: "Gender" },
    { header: "Mobile", field: "Mobile" },
    { header: "Roll No.", field: "RollNo" },
    { header: "Address", field: "Address" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const toPdf = () => {
    const doc = new jsPDF("p", "pt", "a4", true);

    var midPage = doc.internal.pageSize.getWidth() / 2;

    // doc.addImage(userinfo.SchLogo, "PNG", 40, 40, 70, 60);

    doc.setFontSize(18);
    doc.text(userinfo.SchName, midPage, 50, null, null, "center");

    doc.setFontSize(15);
    doc.text(`${userinfo.SchAddress}`, midPage, 68, null, null, "center");

    doc.setFontSize(10);
    doc.text(
      `Tel:${userinfo.Phone}, E-mail:${userinfo.Email}`,
      midPage,
      85,
      null,
      null,
      "center"
    );

    doc.autoTable({
      startY: 100,
      theme: "grid",
      headStyles: {
        fillColor: [227, 227, 227],
        textColor: [0, 0, 0],
      },
      columns: exportColumns,
      body: dataWithIndex,
    });
    doc.save("Student.pdf");
  };
  //end

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />

      <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
        <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">Student List</div>
            <div className="page-date">
              <div className="sec-content">
                <AcademicHeader
                  todayDate={todayDate}
                  AcademicYear={AcademicYear}
                />
                {/* <span>|</span> Fiscal Year :{" "}
                                {fiscalYear.StartDate}
                                <span>-</span>
                                {fiscalYear.EndDate} */}
              </div>
            </div>
          </div>
        </div>
        <hr />
        <>
          <div className="sec-dataTable content-section main-content">
            <div className="upper-dataTbl">
              {/* <Tippy content="Print">
                                <button className="exportButtons ml-1" onClick={toPdf}>
                                    <i className="bi bi-file-earmark-arrow-down-fill exportButtonIconsPrint"></i>
                                </button>
                            </Tippy> */}

              <Tippy content="PDF">
                <button className="exportButtons ml-1" onClick={toPdf}>
                  <i className="bi bi-file-earmark-pdf-fill exportButtonIconsPdf"></i>
                </button>
              </Tippy>

              <Tippy content="Excel">
                <button className="exportButtons" onClick={toExcel}>
                  <i className="bi bi-file-earmark-excel-fill exportButtonIconsExcel"></i>
                </button>
              </Tippy>
            </div>
            <hr className="new-hr-new" />
            {loading ? (
              <Spinner />
            ) : (
              <>
                <DataTable
                  columns={columns}
                  data={studentList}
                  customStyles={customStylesForImage}
                  pagination
                  fixedHeader
                  fixedHeaderScrollHeight="280px"
                  highlightOnHover
                  pointerOnHover
                  responsive
                  dense
                  striped
                  subHeader
                  subHeaderAlign="left"
                  subHeaderComponent={
                    <>
                      <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                        <label className="list-label">Class</label>
                        <select
                          style={{ fontSize: "11px" }}
                          name="snotifiaction"
                          value={selectClass}
                          onChange={handleClass}
                          className="form-control form-control-sm"
                          aria-label="Default select example"
                        >
                          <option
                            value=""
                            disabled
                            selected
                            style={{ fontSize: "11px" }}
                          >
                            Select Class
                          </option>
                          {classList.map((item) => (
                            <option key={item.ClassID} value={item.ClassID}>
                              {item.ClassName}
                            </option>
                          ))}
                        </select>
                        <i class="fas fa-angle-down  notificationpositon"></i>
                      </div>

                      <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                        <label className="list-label">Section</label>
                        <Select
                          className="select"
                          style={{ fontSize: "11px" }}
                          isMulti
                          options={dropDownValue}
                          onChange={(item) =>
                            setSelectedOptions(
                              item.map((item) => ({
                                SectionID: item.value,
                              }))
                            )
                          }
                          isSearchable={false}
                        />
                        <i className="fas fa-angle-down  notificationpositon"></i>
                      </div>

                      {/*   <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                                            <label className="list-label">Section</label>

                                            <Multiselect
                                                displayValue="cat"
                                                options={drop}
                                                onSelect={(item) => setAsd(item.map((item) => ({
                                                    "SectionID": item.key,
                                                })))}
                                                onRemove={(e) => 
                                                // onRemove={setAsd(asd.filter((single) => single.SectionID !== drop.key))}
                                                showCheckbox
                                            /> 
                                            
                                        </div>*/}

                      <div
                        className="col-lg-2 offset-lg-6 offset-md-5 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
                        style={{
                          marginTop: "25px",
                          paddingRight: "1px",
                          marginBottom: "10px",
                        }}
                      >
                        <div className="d-flex">
                          <input
                            placeholder="Search"
                            ref={searchInput}
                            type="text"
                            className="form-control form-control-sm searchField"
                            onChange={searchHandler}
                          />
                        </div>
                      </div>
                    </>
                  }
                />
              </>
            )}
          </div>
        </>
      </div>

      {modal && (
        <div className="modals">
          <div className="overlay"></div>

          <div className="order-model-content">
            <div className="popUpHeader ">
              <div className="popUpHeaderText ">View Student Details</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closePopup}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="inside-order-modal">
              <ViewStudent closePopup={closePopup} />
            </div>
          </div>
        </div>
      )}

      {imgPrv &&
        showImgPreview({
          img: imagePre,
          setTrigger: setImgPrv,
        })}

      {resetPopup.show && (
        <ResetPassword
          handleResetTrue={handleResetTrue}
          handleResetFalse={handleResetFalse}
        />
      )}
      {deletePopup.show && (
        <DeletePop
          handleDeleteTrue={handleDeleteTrue}
          handleDeleteFalse={handleDeleteFalse}
        />
      )}
    </>
  );
}
