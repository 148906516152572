import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import SidebarContext from "../sidebarcontext/SidebarContext";
import Spinner from "../loading/spinner";
import AuthContext from "../auth-context";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import AdminLeaveContext from "../contextfolder/AdminLeaveContext/AdminLeaveContext";
import ApprovePop from "./ApprovePop";
import RejectPop from "./RejectPop";
import ViewAdminLeave from "./ViewAdminLeave";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { GetNepaliDate } from "../hooks/dateConvertor";
import AcademicHeader from "../AcademicHeader";

export default function AdminLeave() {
  const { AcademicYear, todayDate, customStyles } = useContext(SidebarContext);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const {
    originalList,
    classList,
    sectionList,
    selectSection,
    setSelectSection,
    selectClass,
    setSelectClass,
    studentList,
    selectStudent,
    setSelectStudent,
    teacherList,
    selectTeacher,
    setSelectTeacher,
    reportOption,
    setReportOption,
    selectStudentStatus,
    setSelectStudentStatus,
    selectTeacherStatus,
    setSelectTeacherStatus,
    selectAdminStatus,
    setSelectAdminStatus,
    dateForStudent,
    setDateForStudent,
    dateForTeacher,
    setDateForTeacher,
    dateForAdmin,
    setDateForAdmin,
    selectAdmin,
    setSelectAdmin,
    nepaliDate,
    setNepaliDate,
    selectAll,
    setSelectAll,
    leaveList,
    loading,
    setLeaveList,
    approvePopup,
    handleApproveFalse,
    handleApproveTrue,
    handleApprove,
    approveNotify,
    setApproveNotify,
    rejectPopup,
    handleRejectTrue,
    handleRejectFalse,
    handleReject,
    rejectNotify,
    setRejectNotify,
    modal,
    toggleModal,
    setRemarksIsSubmit,
    setCloseChecked,
    view,
  } = useContext(AdminLeaveContext);

  const handleClass = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectClass(value);
  };

  const handleSection = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectSection(value);
  };

  const handleStudent = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectStudent(value);
  };

  const handleTeacher = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectTeacher(value);
  };

  const handleAdmin = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectAdmin(value);
  };

  const closePopup = (e) => {
    e.preventDefault();
    toggleModal();
    setRemarksIsSubmit(false);
    setCloseChecked(false);
  };

  const styles = {
    popup: {
      marginTop:
        reportOption === "S"
          ? "0px"
          : userinfo.UserType === "T"
          ? "25px"
          : "25px",
      paddingLeft:
        reportOption === "S" || userinfo.UserType === "T" ? "0px" : "15px",
      marginBottom: "10px",
      paddingRight: "1px",
    },
  };

  const searchInput = useRef("");

  const columns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "Full Name",
      width: "200px",
      selector: (row) => row.Name,
    },
    {
      name: "User Type",
      width: "110px",
      selector: (row) =>
        row.UserType === "T"
          ? "Teacher"
          : row.UserType === "S"
          ? "Student"
          : row.UserType === "A"
          ? "Admin"
          : "",
    },
    {
      name: "Title",
      width: "150px",
      selector: (row) => row.Title,
    },
    {
      name: "From",
      width: "110px",
      selector: (row) => GetNepaliDate(row.From),
    },
    {
      name: "To",
      width: "110px",
      selector: (row) => GetNepaliDate(row.To),
    },
    {
      name: "Days",
      width: "90px",
      selector: (row) => row.NoOfDays,
    },
    {
      name: "By",
      width: "150px",
      selector: (row) =>
        row.IsApprovedID === "P" ? "Pending" : row.ApprovedBy,
    },
    // {
    //     name: "Created Date",
    //     width: "190px",
    //     selector: (row) => row.CreatedDate,
    // },
    {
      name: "Status",
      selector: (row) => {
        return (
          <>
            {row.IsApprovedID === "P" ? (
              <>
                <>
                  <button
                    type="button"
                    class="badge badge-success"
                    onClick={() => handleApprove(row.LeaveID)}
                    style={{ marginRight: "2px" }}
                  >
                    Approve
                  </button>
                </>

                <>
                  {/* <Tippy content="Reject">
                                        <i
                                            className="fa fa-thumbs-down rejectspan--action"
                                            onClick={() => handleReject(row.LeaveID)}
                                        >
                                        </i>
                                    </Tippy> */}
                  <button
                    type="button"
                    class="badge badge-danger"
                    onClick={() => handleReject(row.LeaveID)}
                  >
                    Reject
                  </button>
                </>
              </>
            ) : row.IsApprovedID === "Y" ? (
              "Approved"
            ) : row.IsApprovedID === "N" ? (
              "Rejected"
            ) : (
              ""
            )}
          </>
        );
      },
    },
    {
      name: "Action",
      center: true,
      width: "90px",
      selector: (row) => {
        return (
          <div className="ln-verition d-flex">
            <Tippy content="View">
              <i
                className="bi bi-eye  solvespan--action"
                style={{ paddingTop: "3px" }}
                onClick={() => view(row)}
              ></i>
            </Tippy>
          </div>
        );
      },
    },
  ];

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["Name"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setLeaveList(srchResult);
      } else {
        setLeaveList({});
      }
    } else {
      setLeaveList(originalList);
    }
  };

  const handleReport = (e) => {
    const target = e.target;
    const value = target.value;
    setReportOption(value);
  };

  const handleDate = ({ adDate }) => {
    setNepaliDate({ ...nepaliDate, fromDate: adDate });
  };

  const handleSelectAll = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectAll(value);
  };

  const handleStudentDate = ({ adDate }) => {
    setDateForStudent({ ...dateForStudent, studentDate: adDate });
  };

  const handleTeacherDate = ({ adDate }) => {
    setDateForTeacher({ ...dateForTeacher, teacherDate: adDate });
  };

  const handleAdminDate = ({ adDate }) => {
    setDateForAdmin({ ...dateForTeacher, adminDate: adDate });
  };

  const handleStudentStatus = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectStudentStatus(value);
  };

  const handleTeacherStatus = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectTeacherStatus(value);
  };

  const handleAdminStatus = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectAdminStatus(value);
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
        <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">Admin Leave</div>
            <div className="page-date">
              <div className="sec-content">
                <AcademicHeader
                  todayDate={todayDate}
                  AcademicYear={AcademicYear}
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <>
          <div className="sec-dataTable">
            {/* <div className="upper-dataTbl">
                            <div className="btn-addlnote mb-3">
                                <button
                                    type="button"
                                    class="btn btn-sm"
                                    style={{
                                        background: "var(--button-color)",
                                        color: "white",
                                    }}
                                    onClick={addLeaveNote}
                                >
                                    Add New
                                </button>
                            </div>
                        </div> */}
            <div className="sec-dataTable content-section main-content">
              {loading ? (
                <Spinner />
              ) : (
                <DataTable
                  columns={columns}
                  data={leaveList}
                  customStyles={customStyles}
                  pagination
                  fixedHeader
                  fixedHeaderScrollHeight="280px"
                  highlightOnHover
                  pointerOnHover
                  responsive
                  dense
                  striped
                  subHeader
                  subHeaderAlign="left"
                  subHeaderComponent={
                    <>
                      {userinfo.UserType !== "T" && (
                        <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                          <label className="list-label">Select Option</label>
                          <select
                            style={{ fontSize: "11px" }}
                            name="status"
                            className="form-control form-control-sm"
                            aria-label="Default select example "
                            value={reportOption}
                            onChange={handleReport}
                          >
                            <option
                              selected
                              disabled
                              value=""
                              style={{ fontSize: "11px" }}
                            >
                              Select Option
                            </option>
                            <option value="all">All</option>
                            <option value="S">Student</option>
                            <option value="T">Teacher</option>
                            <option value="A">Admin</option>
                          </select>
                          <i class="fas fa-angle-down  notificationpositon"></i>
                        </div>
                      )}

                      {reportOption === "all" && (
                        <>
                          <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                            <label className="list-label">Today Date</label>
                            <Calendar
                              className="form-control form-control-sm"
                              dateFormat="YYYY/MM/DD"
                              theme="default"
                              language="en"
                              values={nepaliDate}
                              onChange={handleDate}
                            />
                          </div>

                          <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                            <label className="list-label">Status</label>
                            <select
                              style={{ fontSize: "11px" }}
                              name="status"
                              className="form-control form-control-sm"
                              aria-label="Default select example "
                              value={selectAll}
                              onChange={handleSelectAll}
                            >
                              <option
                                selected
                                disabled
                                value=""
                                style={{ fontSize: "11px" }}
                              >
                                Select Option
                              </option>
                              <option value="-1">All</option>
                              <option value="P">Pending</option>
                              <option value="Y">Approve</option>
                              <option value="N">Cancel</option>
                            </select>
                            <i class="fas fa-angle-down  notificationpositon"></i>
                          </div>
                        </>
                      )}

                      {reportOption === "S" || userinfo.UserType === "T" ? (
                        <>
                          <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                            <label className="list-label">Class</label>
                            <select
                              style={{ fontSize: "11px" }}
                              name="snotifiaction"
                              value={selectClass}
                              onChange={handleClass}
                              className="form-control form-control-sm"
                              aria-label="Default select example"
                            >
                              <option
                                value=""
                                disabled
                                selected
                                style={{ fontSize: "11px" }}
                              >
                                Select Class
                              </option>
                              <option value="-1">All</option>
                              {classList.map((item) => (
                                <option key={item.ClassID} value={item.ClassID}>
                                  {item.ClassName}
                                </option>
                              ))}
                            </select>
                            <i class="fas fa-angle-down  notificationpositon"></i>
                          </div>

                          <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                            <label className="list-label">Section</label>
                            <select
                              style={{ fontSize: "11px" }}
                              name="snotifiaction"
                              value={selectSection}
                              onChange={handleSection}
                              className="form-control form-control-sm"
                              aria-label="Default select example "
                            >
                              <option
                                value=""
                                disabled
                                selected
                                style={{ fontSize: "11px" }}
                              >
                                Select Section
                              </option>
                              <option value="-1">All</option>
                              {sectionList.map((item) => (
                                <option
                                  key={item.SectionID}
                                  value={item.SectionID}
                                >
                                  {item.SectionName}
                                </option>
                              ))}
                            </select>
                            <i class="fas fa-angle-down  notificationpositon"></i>
                          </div>

                          <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                            <label className="list-label">Student</label>
                            <select
                              style={{ fontSize: "11px" }}
                              name="snotifiaction"
                              value={selectStudent}
                              onChange={handleStudent}
                              className="form-control form-control-sm"
                              aria-label="Default select example"
                            >
                              <option
                                value=""
                                disabled
                                selected
                                style={{ fontSize: "11px" }}
                              >
                                Select Student
                              </option>
                              <option value="-1">All</option>
                              {studentList.map((item) => (
                                <option
                                  key={item.StudentID}
                                  value={item.StudentID}
                                >
                                  {item.StdFullName}
                                </option>
                              ))}
                            </select>
                            <i class="fas fa-angle-down  notificationpositon"></i>
                          </div>

                          <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                            <label className="list-label">Status</label>
                            <select
                              style={{ fontSize: "11px" }}
                              name="status"
                              className="form-control form-control-sm"
                              aria-label="Default select example "
                              value={selectStudentStatus}
                              onChange={handleStudentStatus}
                            >
                              <option
                                selected
                                disabled
                                value=""
                                style={{ fontSize: "11px" }}
                              >
                                Select Option
                              </option>
                              <option value="-1">All</option>
                              <option value="P">Pending</option>
                              <option value="Y">Approve</option>
                              <option value="N">Cancel</option>
                            </select>
                            <i class="fas fa-angle-down  notificationpositon"></i>
                          </div>

                          <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                            <label className="list-label">Date</label>
                            <Calendar
                              className="form-control form-control-sm"
                              dateFormat="YYYY/MM/DD"
                              theme="default"
                              language="en"
                              values={dateForStudent}
                              onChange={handleStudentDate}
                            />
                          </div>
                        </>
                      ) : (
                        <> </>
                      )}

                      {reportOption === "T" && (
                        <>
                          <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                            <label className="list-label">Teacher</label>
                            <select
                              style={{ fontSize: "11px" }}
                              name="snotifiaction"
                              value={selectTeacher}
                              onChange={handleTeacher}
                              className="form-control form-control-sm"
                              aria-label="Default select example"
                            >
                              <option
                                value=""
                                disabled
                                selected
                                style={{ fontSize: "11px" }}
                              >
                                Select Teacher
                              </option>
                              <option value="-1">All</option>
                              {teacherList.map((item) => (
                                <option
                                  key={item.TeacherID}
                                  value={item.TeacherID}
                                >
                                  {item.TeacherName}
                                </option>
                              ))}
                            </select>
                            <i class="fas fa-angle-down  notificationpositon"></i>
                          </div>

                          <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                            <label className="list-label">Status</label>
                            <select
                              style={{ fontSize: "11px" }}
                              name="status"
                              className="form-control form-control-sm"
                              aria-label="Default select example "
                              value={selectTeacherStatus}
                              onChange={handleTeacherStatus}
                            >
                              <option
                                selected
                                disabled
                                value=""
                                style={{ fontSize: "11px" }}
                              >
                                Select Option
                              </option>
                              <option value="-1">All</option>
                              <option value="P">Pending</option>
                              <option value="Y">Approve</option>
                              <option value="N">Reject</option>
                            </select>
                            <i class="fas fa-angle-down  notificationpositon"></i>
                          </div>

                          <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                            <label className="list-label">Date</label>
                            <Calendar
                              className="form-control form-control-sm"
                              dateFormat="YYYY/MM/DD"
                              theme="default"
                              language="en"
                              values={dateForTeacher}
                              onChange={handleTeacherDate}
                            />
                          </div>
                        </>
                      )}

                      {reportOption === "A" && (
                        <>
                          <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                            <label className="list-label">Admin</label>
                            <select
                              style={{ fontSize: "11px" }}
                              name="status"
                              className="form-control form-control-sm"
                              aria-label="Default select example"
                              value={selectAdmin}
                              onChange={handleAdmin}
                            >
                              <option
                                selected
                                disabled
                                value=""
                                style={{ fontSize: "11px" }}
                              >
                                Select Option
                              </option>
                              <option value="-1">All</option>
                              <option value="1">Alex</option>
                            </select>
                            <i class="fas fa-angle-down  notificationpositon"></i>
                          </div>

                          <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                            <label className="list-label">Status</label>
                            <select
                              style={{ fontSize: "11px" }}
                              name="status"
                              className="form-control form-control-sm"
                              aria-label="Default select example "
                              value={selectAdminStatus}
                              onChange={handleAdminStatus}
                            >
                              <option
                                selected
                                disabled
                                value=""
                                style={{ fontSize: "11px" }}
                              >
                                Select Option
                              </option>
                              <option value="-1">All</option>
                              <option value="P">Pending</option>
                              <option value="Y">Approve</option>
                              <option value="N">Cancel</option>
                            </select>
                            <i class="fas fa-angle-down  notificationpositon"></i>
                          </div>

                          <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                            <label className="list-label">Select Date</label>
                            <Calendar
                              className="form-control form-control-sm"
                              dateFormat="YYYY/MM/DD"
                              theme="default"
                              language="en"
                              values={dateForAdmin}
                              onChange={handleAdminDate}
                            />
                          </div>
                        </>
                      )}

                      <div
                        className={
                          reportOption === "T" || reportOption === "A"
                            ? "col-lg-2 offset-lg-2 offset-md-2 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
                            : reportOption === "all"
                            ? "col-lg-2 offset-lg-4 offset-md-4 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
                            : userinfo.UserType === "T"
                            ? "col-lg-2 col-md-2 col-sm-2 dropdown2"
                            : reportOption === "S"
                            ? "col-lg-2 offset-lg-10 offset-md-10 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
                            : reportOption === ""
                            ? "col-lg-2 offset-lg-8 offset-md-8 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
                            : ""
                        }
                        style={styles.popup}
                      >
                        <div className="d-flex">
                          <input
                            placeholder="Search"
                            ref={searchInput}
                            type="text"
                            className="form-control form-control-sm searchField"
                            onChange={searchHandler}
                          />
                        </div>
                      </div>
                    </>
                  }
                />
              )}
            </div>
          </div>
        </>
      </div>
      {approvePopup.show && (
        <ApprovePop
          handleApproveTrue={handleApproveTrue}
          handleApproveFalse={handleApproveFalse}
          approveNotify={approveNotify}
          setApproveNotify={setApproveNotify}
        />
      )}
      {rejectPopup.show && (
        <RejectPop
          handleRejectTrue={handleRejectTrue}
          handleRejectFalse={handleRejectFalse}
          rejectNotify={rejectNotify}
          setRejectNotify={setRejectNotify}
        />
      )}

      {modal && (
        <div className="modals">
          <div className="overlay"></div>

          <div className="notification-model-content">
            <div className="popUpHeader ">
              <div className="popUpHeaderText ">Admin Leave</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closePopup}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="inside-modal">
              <ViewAdminLeave closePopup={closePopup} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
