import React, { useContext, useRef } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "jspdf-autotable";
import OrderContext from "../contextfolder/OrderContext/OrderContext";
import PaymentCreate from "./PaymentCreate";
import PaymentPayOrder from "./PaymentPayOrder";
import PaymentInfo from "./PaymentInfo";
import AcademicHeader from "../AcademicHeader";

export default function OrderPayment() {
  const { todayDate, customStyles, AcademicYear } = useContext(SidebarContext);
  const {
    payLoad,
    paySearch,
    paymentList,
    setPaymentList,
    choosePaymentStatus,
    setChoosePaymentStatus,
    setPaymentFormValue,
    paymentValue,
    setPaymentFormError,
    payIsSubmit,
    setPayIsSubmit,
    togglePayModal,
    payModal,
    setPayModal,
    payCloseChecked,
    setPayCloseChecked,
    setOrderIdSubmit,
    setNewOrder,
    handlePays,
    handlePayTrues,
    handlePayFalses,
    payPopups,
    togglePaymentPopup,
    paymentPopup,
    handleInfoPayment,
    setInfoPayment,
  } = useContext(OrderContext);

  const handlePayment = (e) => {
    const target = e.target;
    const value = target.value;
    setChoosePaymentStatus(value);
  };

  const searchInput = useRef("");

  const columns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "OrderID",
      width: "110px",
      selector: (row) => row.OrderID,
    },
    {
      name: "FullName",
      selector: (row) => row.FullName,
    },

    {
      name: "Amount",
      width: "100px",
      selector: (row) => row.PayAmount,
    },

    {
      name: "Payment Status",
      width: "130px",
      selector: (row) => {
        return (
          <>
            {row.PayStatus === "Pending" ? (
              <>
                <>
                  <button
                    type="button"
                    class="badge badge-success"
                    onClick={() => handlePays([row.OrderID, row.PayAmount])}
                    style={{ marginRight: "2px" }}
                  >
                    Pay
                  </button>
                </>
              </>
            ) : (
              row.PayStatus
            )}
          </>
        );
      },
    },
    {
      name: "Payment Date",
      width: "130px",
      selector: (row) => row.PayDate,
    },
    {
      name: "Payment Type",
      width: "130px",
      selector: (row) => row.PayType,
    },

    {
      name: "Action",
      // grow: 0,
      center: true,
      width: "79px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <Tippy content="View Order Info">
                <i
                  className="bi bi-eye viewspan--action"
                  onClick={() => handleInfoPayment(row.OrderID)}
                ></i>
              </Tippy>
            </div>
          </>
        );
      },
    },
  ];

  const closePopup = (e) => {
    e.preventDefault();
    togglePayModal();
    setPaymentFormValue(paymentValue);
    setPaymentFormError({});
    setPayIsSubmit(false);
    setOrderIdSubmit(false);
    setPayCloseChecked(false);
    setNewOrder([]);
  };

  const closeOrder = (e) => {
    e.preventDefault();
    togglePaymentPopup();
    setInfoPayment("");
  };

  const addnew = (e) => {
    e.preventDefault();
    togglePayModal();
    setPaymentFormValue(paymentValue);
    setPayIsSubmit(false);
    setOrderIdSubmit(false);
  };

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = paySearch.filter((list) => {
        return list["FullName"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setPaymentList(srchResult);
      } else {
        setPaymentList({});
      }
    } else {
      setPaymentList(paySearch);
    }
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
        <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">Payment</div>
            <div className="page-date">
              <div className="sec-content">
                <AcademicHeader
                  todayDate={todayDate}
                  AcademicYear={AcademicYear}
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <>
          <div className="sec-dataTable content-section main-content">
            <div className="upper-dataTbl">
              <div className="btn-addlnote">
                <button
                  type="button"
                  class="btn btn-sm"
                  style={{
                    background: "var(--MainColor)",
                    color: "white",
                  }}
                  onClick={addnew}
                >
                  Add New
                </button>
                {/* <div className="exportDiv">
                                    <Tippy content="Excel">
                                        <button className="exportButtons mx-2" 
                                        // onClick={toExcel}
                                        >
                                            <i className="bi bi-file-earmark-excel-fill exportButtonIconsExcel"></i>
                                        </button>
                                    </Tippy>

                                    <Tippy content="PDF">
                                        <button className="exportButtons "
                                            // onClick={toPdf}
                                        >
                                            <i className="bi bi-file-earmark-pdf-fill exportButtonIconsPdf"></i>
                                        </button>
                                    </Tippy>

                                </div> */}
              </div>
            </div>
            <hr className="new-hr" />
            {payLoad ? (
              <Spinner />
            ) : (
              <DataTable
                columns={columns}
                data={paymentList}
                customStyles={customStyles}
                pagination
                fixedHeader
                fixedHeaderScrollHeight="280px"
                highlightOnHover
                pointerOnHover
                responsive
                dense
                striped
                subHeader
                subHeaderAlign="left"
                subHeaderComponent={
                  <>
                    <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                      <label className="list-label">Payment Status</label>
                      <select
                        style={{ fontSize: "11px" }}
                        name="snotifiaction"
                        value={choosePaymentStatus}
                        onChange={handlePayment}
                        className="form-control form-control-sm"
                        aria-label="Default control example "
                      >
                        <option
                          value=""
                          disabled
                          selected
                          style={{ fontSize: "11px" }}
                        >
                          Select Payment Status
                        </option>
                        <option value="-1">All</option>
                        <option value="Y">Paid</option>
                        <option value="N">Pending</option>
                      </select>
                      <i class="fas fa-angle-down  notificationpositon"></i>
                    </div>

                    <div
                      className="col-lg-2 offset-lg-8 col-md-2 offset-md-8 col-sm-2 align-self-end Search"
                      style={{
                        marginTop: "25px",
                        padding: "inherit",
                        marginBottom: "10px",
                      }}
                    >
                      <div className="d-flex">
                        <input
                          placeholder="Search"
                          ref={searchInput}
                          type="text"
                          className="form-control form-control-sm searchField"
                          onChange={searchHandler}
                        />
                      </div>
                    </div>
                  </>
                }
              />
            )}
          </div>
        </>
      </div>

      {payModal && (
        <div className="modals">
          <div className="overlay">
            <div className="notification-model-content">
              <div className="popUpHeader ">
                <div className="popUpHeaderText ">Add Payment</div>
                <div style={{ cursor: "pointer" }}>
                  <i
                    className="bi bi-x "
                    onClick={closePopup}
                    style={{ fontSize: "25px" }}
                  ></i>
                </div>
              </div>
              <div className="inside-modal">
                <PaymentCreate closePopup={closePopup} />
              </div>
            </div>
          </div>
        </div>
      )}

      {payPopups.show && (
        <PaymentPayOrder
          handlePayTrues={handlePayTrues}
          handlePayFalses={handlePayFalses}
        />
      )}

      {paymentPopup && (
        <div className="modals">
          <div className="overlay">
            <div className="notification-model-content">
              <div className="popUpHeader ">
                <div className="popUpHeaderText ">Payment Info</div>
                <div style={{ cursor: "pointer" }}>
                  <i
                    className="bi bi-x "
                    onClick={closeOrder}
                    style={{ fontSize: "25px" }}
                  ></i>
                </div>
              </div>
              <div className="inside-modal">
                <PaymentInfo closeOrder={closeOrder} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
