import React from "react";
import "./StudentProfile.css";

function Basic({ userinfo }) {
    const services = [
        {
            title: "Full Name",
            body:
                userinfo.FullName === null || userinfo.FullName === "-"
                    ? "Not Mentioned"
                    : userinfo.FullName,
        },
        {
            title: "Date Of Birth",
            body: userinfo.DateOfBirth === null || userinfo.DateOfBirth === "-"
                ? "Not Mentioned"
                : userinfo.DateOfBirth,
        },
        {
            title: "Phone Number",
            body:
                userinfo.Phone === null || userinfo.Phone === "-"
                    ? "Not Mentioned"
                    : userinfo.Phone,
        },
        {
            title: "Email",
            body:
                userinfo.Email === null || userinfo.Email === "-"
                    ? "Not Mentioned"
                    : userinfo.Email,
        },
        {
            title: "Religion",
            body:
                userinfo.Religion === null || userinfo.Religion === "-"
                    ? "Not Mentioned"
                    : userinfo.Religion,
        },
        {
            title: "Blood Group",
            body:
                userinfo.BloodGroup === null || userinfo.BloodGroup === "-"
                    ? "Not Mentioned"
                    : userinfo.BloodGroup,
        },
        {
            title: "Home",
            body:
                userinfo.House === null || userinfo.House === "-"
                    ? "Not Mentioned"
                    : userinfo.House,
        },
        {
            title: "Medical History",
            body:
                userinfo.MedicalHistory === null || userinfo.MedicalHistory === "-"
                    ? "Not Mentioned"
                    : userinfo.MedicalHistory,
        },
        {
            title: "Temporary Address",
            body:
                userinfo.TempAddress === null || userinfo.TempAddress === "-"
                    ? "Not Mentioned"
                    : userinfo.TempAddress,
        },
        {
            title: "Permanent Address",
            body:
                userinfo.PermAddress === null || userinfo.PermAddress === "-"
                    ? "Not Mentioned"
                    : userinfo.PermAddress,
        },
    ];

    return (
        <div className="basic-center ">
            <div className="basic-dyno">
                {services.map((item, index) => {
                    return (
                        <article key={index} className="basic-info">
                            <h6 className="basic-title">{item.title}</h6>
                            <p style={{ fontSize: "14px" }}>{item.body}</p>
                        </article>
                    );
                })}
            </div>
        </div>
    );
}

export default Basic;
