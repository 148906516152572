import React, { useContext, useState, useEffect } from 'react'
import SuggestionContext from '../contextfolder/SuggestionContext/SuggestionContext';




export default function ViewSuggestion({ closeViewPopup }) {

    const context = useContext(SuggestionContext)

    const { togglePopup, remarksFormValue, setRemarksFormValue, remarksFormError, setRemarksFormError, remarksIsSubmit, setRemarksIsSubmit, remarksvalue, pushNotice, setPushNotice, state, viewdata, closeChecked, setCloseChecked } = context;

    const [state1, setState1] = useState(false)

    const handleOnChange = () => {
        setCloseChecked(!closeChecked);
    };

    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;
        setRemarksFormValue({ ...remarksFormValue, [name]: value });
    };


    const formSubmit = (e) => {
        e.preventDefault();
        setRemarksIsSubmit(true);
        setState1(true)
    }

    useEffect(() => {
        if (state1 === true && remarksIsSubmit) {
            viewdata(remarksFormValue)
            if (closeChecked === true) {
                togglePopup();
            }

        }
        setRemarksIsSubmit(false);

    }, [state1]);

    const validate = (values) => {

        const errors = {};
        if (!values.status) {
            errors.status = "Required";
        }
        // if (remarksFormValue.status !== "P") {
        //     if (!values.remarks) {
        //         errors.remarks = "Required";
        //     }
        // }

        return errors;
    };

    const handlePushNotification = () => {
        setPushNotice(!pushNotice)
    }

    return (
        <>

            <div className="container-fluid basicform" >

                <div>


                    {/* <div className="outer-wrapper"> */}
                    <table className="complain-table" style={{ width: "100%" }}>

                        <thead>
                            <tr className='tableHead '>
                                <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", position: "relative" }}>Full Name</td>
                                <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", position: "relative" }}>Suggestion Topic</td>
                            </tr>
                        </thead>
                        <tbody>




                            {state &&
                                <tr className="viewHead">
                                    <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", paddingTop: "0px" }} >{state.FullName}</td>
                                    <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", paddingTop: "0px" }}>{state.Topic}</td>
                                </tr>
                            }


                        </tbody>

                    </table>
                    {/* </div> */}
                </div>
                {state &&
                    <div >
                        <div style={{ padding: "0px 5px" }} >
                            <h4 style={{ fontSize: "12px", fontWeight: "bold" }}> Title </h4>
                            <p style={{ fontSize: "12px", color: "#212529" }}>{state.Title}</p>
                        </div>
                    </div>
                }
                {state &&
                    <div >
                        <div style={{ padding: "0px 5px" }} >
                            <h4 style={{ fontSize: "12px", fontWeight: "bold" }}> Description </h4>
                            <p style={{ fontSize: "12px", color: "#212529" }}>{state.Description}</p>
                        </div>
                    </div>
                }

                <form style={{ marginLeft: "4px" }}>
                    <div className="form-group">
                        <div>
                            <label className="form-label" htmlFor="purpose">Status</label>
                        </div>
                        <div>

                            {state.SuggestionStatusID === "P" ? (
                                <span
                                    className="badge badge-primary"

                                >
                                    {state.SuggestionStatus}
                                </span>
                            ) : state.SuggestionStatusID === "A" ? (
                                <span
                                    className="badge badge-success"

                                >
                                    {state.SuggestionStatus}
                                </span>
                            ) :
                                (
                                    <span
                                        className="badge badge-danger"

                                    >
                                        {state.SuggestionStatus}
                                    </span>
                                )}

                        </div>

                    </div>



                    {state.SuggestionStatusID !== "P" &&
                        <div className="form-group" style={{ marginTop: "3px" }}>
                            <label htmlFor="exampleFormControlTextarea1" className="form-label">Remarks</label>
                            <p style={{ fontSize: "12px", color: "#212529" }}>{state.Remarks}</p>

                        </div>
                    }

                </form>


                {/* <div style={{ paddingTop: "9px" }}>
                    <input
                        type="checkbox"
                        // className="form-check-input"
                        id="exampleCheck3"
                        name="notWorking"
                        onChange={handlePushNotification}
                        checked={pushNotice}
                    />
                    <label class="form-check-label" for="exampleCheck3" style={{ fontSize: "12px", paddingLeft: "3px" }}>
                        Notify User
                    </label>
                </div> */}


            </div>
            <div className="FormFooter">
                <div className='checkedClose'>

                </div>
                <div>
                    {/* <button className='btn btn-sm btn-cmpy ml-2' style={{ background: "green" }} onClick={formSubmit} >Submit</button> */}
                    <button className='btn btn-sm btn-cmpy ml-2' onClick={closeViewPopup}>Cancel</button>
                </div>
            </div>

        </>

    )
}
