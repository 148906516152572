import React, { useContext, useEffect, useState } from "react";
import { Fetchdata } from "../../hooks/getData";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { toast } from "react-toastify";
import QrPaymentContext from "./QrPaymentContext";

function QrPaymentState(props) {
  const { appURL } = useContext(SidebarContext);

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const [closeChecked, setCloseChecked] = useState(false);

  const initialValue = {
    PayMode: "",
    QRValue: "",
  };
  const [formValue, setFormValue] = useState(initialValue);
  const [formError, setFormError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [editIsSubmit, setEditIsSubmit] = useState(false);

  const [popup, setPopup] = useState(false);
  const togglePopup = () => {
    setPopup(!popup);
  };

  const [editPopup, setEditPopup] = useState(false);
  const toggleEditPopup = () => {
    setEditPopup(!editPopup);
  };

  const [viewPopup, setViewPopup] = useState(false);
  const [viewData, setViewData] = useState();
  const toggleViewPopup = () => {
    setViewPopup(!viewPopup);
  };

  const viewPop = (datas) => {
    setViewPopup(true);
    setViewData(datas);
  };

  const [paymodeList, setPayModeList] = useState([]);

  useEffect(() => {
    payList();
  }, []);

  const payList = () => {
    const params = {
      SchID: userinfo.SchID,
      Flag: "SP",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/pay-mode`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.SchoolLst ? result.SchoolLst : "";
        setPayModeList(postResult);
      } else {
        setPayModeList([]);
      }
    });
  };

  //API to create
  const fetchdata = () => {
    const dataForm = {
      ...formValue,
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "i",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/pay-mode`,
      Type: "POST",
    };
    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getdataList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };

  // API for  List
  const [dataList, setdataList] = useState([]);

  useEffect(() => {
    getdataList();
  }, []);

  const getdataList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "S",
      IsActive: "-1",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/pay-mode`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.SchoolLst ? result.SchoolLst : "";
        setLoading(false);
        setdataList(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        setdataList([]);
        setOriginalList([]);
      }
    });
  };

  const [titleId, setTitleID] = useState();
  const editPop = (datas) => {
    setEditPopup(true);
    setTitleID(datas.PayModeID);
    setFormValue({
      PayMode: datas.PayMode,
      QRValue: datas.QRValue,
    });
    setEditIsSubmit(false);
  };

  //API to edit chapter
  const editdata = () => {
    const dataForm = {
      ...formValue,
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "U",
      PayModeID: titleId.toString(),
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/pay-mode`,
      Type: "POST",
    };
    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getdataList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };

  // //Everything to pop up and delete the chapter list
  const [deletePopup, setDeletePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const [delData, setDelData] = useState();

  const handleDelete = async (data) => {
    setDelData(data);
    setDeletePopup({
      show: true,
      data,
    });
  };

  // This will perform the deletion and hide the Confirmation Box

  const handleDeleteTrue = async () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "R",
      PayModeID: delData.toString(),
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/pay-mode`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          getdataList();
          toast(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
        } else {
          toast(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
    });
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleDeleteFalse = () => {
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // Ends Here

  //Activate popup starts from here
  //Everything to pop up and approve the  list
  const [activatePopup, setActivatePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });
  // To approve the credit List

  const [Acdata, setACData] = useState();

  const handleActivate = async (data) => {
    setACData(data.PayModeID);

    setActivatePopup({
      show: true,
      data,
    });
  };

  // This will perform the approve and hide the Confirmation Box

  const handleActivateTrue = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "AI",
      PayModeID: Acdata.toString(),
      IsActive: "A",
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/pay-mode`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          getdataList();
          toast(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
        } else {
          toast(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
    });
    setActivatePopup({
      show: false,
      data: null,
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleActivateFalse = () => {
    setActivatePopup({
      show: false,
      data: null,
    });
  };

  //Everything to pop up and activate  ends

  //Activate popup starts from here
  //Everything to pop up and approve the  list
  const [inActivatePopup, setInActivatePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });
  // To approve the credit List

  const [inactivedata, setInactiveData] = useState();

  const handleInActivate = async (data) => {
    setInactiveData(data.PayModeID);

    setInActivatePopup({
      show: true,
      data,
    });
  };

  // This will perform the approve and hide the Confirmation Box

  const handleInActivateTrue = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "AI",
      PayModeID: inactivedata.toString(),
      IsActive: "I",
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/pay-mode`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          getdataList();
          toast(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
        } else {
          toast(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
    });
    setInActivatePopup({
      show: false,
      data: null,
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleInActivateFalse = () => {
    setInActivatePopup({
      show: false,
      data: null,
    });
  };

  //Everything to pop up and approve the chapter list ends

  return (
    <QrPaymentContext.Provider
      value={{
        loading,
        originalList,
        deletePopup,
        handleDeleteTrue,
        handleDeleteFalse,
        handleDelete,
        popup,
        setPopup,
        togglePopup,
        dataList,
        setdataList,
        formValue,
        setFormValue,
        formError,
        setFormError,
        isSubmit,
        initialValue,
        setIsSubmit,
        paymodeList,
        setPayModeList,
        closeChecked,
        setCloseChecked,
        fetchdata,
        activatePopup,
        setActivatePopup,
        handleActivate,
        handleActivateTrue,
        handleActivateFalse,
        inActivatePopup,
        setInActivatePopup,
        handleInActivate,
        handleInActivateTrue,
        handleInActivateFalse,

        toggleEditPopup,
        editPopup,
        editIsSubmit,
        setEditIsSubmit,
        editPop,
        editdata,
        toggleViewPopup,
        viewPopup,
        viewPop,
        viewData,
      }}
    >
      {props.children}
    </QrPaymentContext.Provider>
  );
}

export default QrPaymentState;
