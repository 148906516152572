import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../../css/Commission.css";
import ClassRoutineEdit from "./ClassRoutineEdit";
import ClassRoutineForm from "./ClassRoutineForm";
import DeletePop from "./DeletePop";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import ClassRoutineContext from "../../contextfolder/ClassRoutineContext/ClassRoutineContext";
import Spinner from "../../loading/spinner";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import "tippy.js/dist/tippy.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { GetTwelveHourFormatTime } from "../../hooks/dateConvertor";

export default function ClassRoutineList() {
  const { today, customStyles, AcademicYear, hideAction } =
    useContext(SidebarContext);
  const {
    loading,
    originalList,
    setClassRoutineList,
    popup,
    togglePopup,
    setIsSubmit,
    setClassFormValue,
    setClassFormError,
    classValue,
    classRoutineList,
    handleEdit,
    editPop,
    toggleEditPopup,
    setEditIsSubmit,
    deletePopup,
    handleDeleteTrue,
    handleDeleteFalse,
    handleDelete,
    classList,
    sectionList,
    selectClass,
    setSelectClass,
    selectSection,
    setSelectSection,
    chooseDay,
    setChooseDay,
    setCloseChecked,
  } = useContext(ClassRoutineContext);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const handleClass = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectClass(value);
    setSelectSection("");
    setChooseDay(today);
  };

  const handleSection = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectSection(value);
    setChooseDay(today);
  };

  const handleDay = (e) => {
    const target = e.target;
    const value = target.value;
    setChooseDay(value);
  };

  const searchInput = useRef("");

  const [hideClass, setHideClass] = useState(false);
  const [hideTeacher, setHideTeacher] = useState(false);

  useEffect(() => {
    if (userinfo.UserType === "A") {
      setHideClass(!hideClass);
    }
  }, []);

  useEffect(() => {
    if (userinfo.UserType === "T") {
      setHideTeacher(!hideTeacher);
    }
  }, []);

  const columns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "Class",
      width: "100px",
      selector: (row) => row.Class,
      omit: hideClass,
    },
    {
      name: "Period",
      // center: true,
      width: "90px",
      selector: (row) => row.Period,
    },
    {
      name: "Subject",
      // grow: 0,
      // center: true,
      // selector: (row) => row.Subject === null ? "Break Time" : row.Subject,
      selector: (row) => {
        if (row.IsBreakID === "E") {
          return "ECA";
        } else if (row.IsBreakID === "N") {
          return row.Subject;
        } else if (row.IsBreakID === "S") {
          return "Short Break";
        } else if (row.IsBreakID === "L") {
          return "Long Break";
        }
      },
    },
    {
      name: "Teacher",
      // grow: 0,
      selector: (row) => {
        if (row.IsBreakID === "E") {
          return "ECA";
        } else if (row.IsBreakID === "N") {
          return row.SubTeacher;
        } else if (row.IsBreakID === "S") {
          return "Short Break";
        } else if (row.IsBreakID === "L") {
          return "Long Break";
        }
      },
      omit: hideTeacher,
    },
    {
      name: "Start",
      // grow: 0,
      width: "120px",
      selector: (row) => row.StartTime,
    },
    {
      name: "End",
      width: "120px",
      selector: (row) => row.EndTime,
    },
    {
      name: "Room No.",
      width: "130px",
      selector: (row) => {
        if (row.IsBreakID === "E") {
          return "ECA";
        } else if (row.IsBreakID === "N") {
          return row.RoomNo;
        } else if (row.IsBreakID === "S") {
          return "Short Break";
        } else if (row.IsBreakID === "L") {
          return "Long Break";
        }
      },
    },
    {
      name: "Break",
      width: "105px",
      selector: (row) =>
        row.IsBreakID === "L"
          ? "Long Break"
          : row.IsBreakID === "S"
          ? "Short Break"
          : row.IsBreakID === "E"
          ? "ECA"
          : "Normal",
    },
    {
      name: "Action",
      // grow: 0,
      center: true,
      width: "100px",
      selector: (row) => {
        return (
          <>
            <div
              className="ln-verition d-flex"
              disabled={userinfo.UserType === "T"}
            >
              <Tippy content="Edit">
                <i
                  className="fa fa-pencil solvespan--action"
                  onClick={() => handleEdit(row)}
                ></i>
              </Tippy>
              <Tippy content="Delete">
                <i
                  className="fa fa-trash-o rejectspan--action"
                  onClick={() => handleDelete(row.RoutineID)}
                ></i>
              </Tippy>
            </div>
          </>
        );
      },
      omit: hideAction,
    },
  ];

  const closePopup = (e) => {
    e.preventDefault();
    togglePopup();
    setClassFormValue(classValue);
    setClassFormError({});
    setIsSubmit(false);
    setCloseChecked(false);
  };

  const closeEditPop = (e) => {
    e.preventDefault();
    toggleEditPopup();
    setClassFormError({});
    setEditIsSubmit(false);
    setCloseChecked(false);
  };

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["SubTeacher"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setClassRoutineList(srchResult);
      } else {
        setClassRoutineList({});
      }
    } else {
      setClassRoutineList(originalList);
    }
  };

  /* This is for export csv */

  const dataToExcel = classRoutineList.map((d, i) => ({
    "S.N.": i + 1,
    Period: d.Period,
    Subject:
      d.IsBreakID === "E"
        ? "ECA"
        : d.IsBreakID === "S"
        ? "Short Break"
        : d.IsBreakID === "L"
        ? "Long Break"
        : d.Subject,
    Teacher:
      d.IsBreakID === "E"
        ? "ECA"
        : d.IsBreakID === "S"
        ? "Short Break"
        : d.IsBreakID === "L"
        ? "Long Break"
        : d.SubTeacher,
    Start: d.StartTime,
    End: d.EndTime,
    "Room No.":
      d.IsBreakID === "E"
        ? "ECA"
        : d.IsBreakID === "S"
        ? "Short Break"
        : d.IsBreakID === "L"
        ? "Long Break"
        : d.RoomNo,
    Break:
      d.IsBreakID === "L"
        ? "Long Break"
        : d.IsBreakID === "S"
        ? "Short Break"
        : d.IsBreakID === "E"
        ? "ECA"
        : "Normal",
  }));

  const toExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(dataToExcel);
      var wscols = [
        { wch: 5 },
        { wch: 20 },
        { wch: 20 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ];
      worksheet["!cols"] = wscols;
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "Class_Routine");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_" + "export" + EXCEL_EXTENSION
        );
      }
    });
  };

  //end

  //This is to export pdf

  const dataWithIndex = classRoutineList.map((d, i) => ({
    ...d,
    Index: i + 1,
    Subject:
      d.IsBreakID === "E"
        ? "ECA"
        : d.IsBreakID === "S"
        ? "Short Break"
        : d.IsBreakID === "L"
        ? "Long Break"
        : d.Subject,
    Teacher:
      d.IsBreakID === "E"
        ? "ECA"
        : d.IsBreakID === "S"
        ? "Short Break"
        : d.IsBreakID === "L"
        ? "Long Break"
        : d.SubTeacher,
    "Room No.":
      d.IsBreakID === "E"
        ? "ECA"
        : d.IsBreakID === "S"
        ? "Short Break"
        : d.IsBreakID === "L"
        ? "Long Break"
        : d.RoomNo,
    Break:
      d.IsBreakID === "L"
        ? "Long Break"
        : d.IsBreakID === "S"
        ? "Short Break"
        : d.IsBreakID === "E"
        ? "ECA"
        : "Normal",
  }));

  const cols = [
    { header: "S.N", field: "Index" },
    { header: "Period", field: "Period" },
    { header: "Subject", field: "Subject" },
    { header: "Teacher", field: "Teacher" },
    { header: "Start", field: "StartTime" },
    { header: "End", field: "EndTime" },
    { header: "Room No.", field: "Room No." },
    { header: "Break.", field: "Break" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const toPdf = () => {
    const doc = new jsPDF("p", "pt", "a4", true);

    var midPage = doc.internal.pageSize.getWidth() / 2;

    // doc.addImage(`${userinfo.SchPhoto}`, "JPG", 40, 40, 70, 60);

    doc.setFontSize(18);
    doc.text(`${userinfo.SchName}`, midPage, 50, null, null, "center");

    doc.setFontSize(15);
    doc.text(`${userinfo.SchAddress}`, midPage, 68, null, null, "center");

    doc.setFontSize(10);
    doc.text(
      `Tel:${userinfo.Phone}, E-mail:${userinfo.Email}`,
      midPage,
      85,
      null,
      null,
      "center"
    );

    doc.autoTable({
      startY: 100,
      theme: "grid",
      columns: exportColumns,
      body: dataWithIndex,
    });
    doc.save("Class_Routine.pdf");
  };
  //end

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <>
        <div className="sec-dataTable">
          <div className="upper-dataTbl">
            <Tippy content="PDF">
              <button className="exportButtons ml-1" onClick={toPdf}>
                <i className="bi bi-file-earmark-pdf-fill exportButtonIconsPdf"></i>
              </button>
            </Tippy>
            <Tippy content="Excel">
              <button className="exportButtons" onClick={toExcel}>
                <i className="bi bi-file-earmark-excel-fill exportButtonIconsExcel"></i>
              </button>
            </Tippy>
          </div>
          <hr className="new-hr-new" />

          {loading ? (
            <Spinner />
          ) : (
            <DataTable
              columns={columns}
              data={classRoutineList}
              customStyles={customStyles}
              pagination
              fixedHeader
              fixedHeaderScrollHeight="280px"
              highlightOnHover
              pointerOnHover
              responsive
              dense
              striped
              subHeader
              subHeaderAlign="left"
              subHeaderComponent={
                <>
                  {userinfo.UserType === "T" ? (
                    <></>
                  ) : (
                    <>
                      <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                        <label className="list-label">Class</label>
                        <select
                          style={{ fontSize: "11px", marginBottom: "3px" }}
                          name="snotifiaction"
                          value={selectClass}
                          onChange={handleClass}
                          className="form-control form-control-sm"
                          aria-label="Default select example "
                        >
                          <option
                            value=""
                            disabled
                            selected
                            style={{ fontSize: "11px" }}
                          >
                            Select Class
                          </option>

                          {classList.map((item) => (
                            <option key={item.ClassID} value={item.ClassID}>
                              {item.ClassName}
                            </option>
                          ))}
                        </select>
                        <i class="fas fa-angle-down  notificationpositon"></i>
                      </div>

                      <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                        <label className="list-label">Section</label>
                        <select
                          style={{ fontSize: "11px", marginBottom: "3px" }}
                          name="snotifiaction"
                          value={selectSection}
                          onChange={handleSection}
                          className="form-control form-control-sm"
                          aria-label="Default select example "
                        >
                          <option
                            value=""
                            disabled
                            selected
                            style={{ fontSize: "11px" }}
                          >
                            Select Section
                          </option>

                          {sectionList.map((item) => (
                            <option key={item.SectionID} value={item.SectionID}>
                              {item.SectionName}
                            </option>
                          ))}
                        </select>
                        <i class="fas fa-angle-down  notificationpositon"></i>
                      </div>
                    </>
                  )}

                  <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                    <label className="list-label">Day</label>
                    <select
                      style={{ fontSize: "11px", marginBottom: "3px" }}
                      name="snotifiaction"
                      value={chooseDay}
                      onChange={handleDay}
                      className="form-control form-control-sm"
                      aria-label="Default select example "
                    >
                      <option
                        value=""
                        disabled
                        selected
                        style={{ fontSize: "11px" }}
                      >
                        Select Day
                      </option>
                      <option value="1">Sunday</option>
                      <option value="2">Monday</option>
                      <option value="3">Tuesday</option>
                      <option value="4">Wednesday</option>
                      <option value="5">Thursday</option>
                      <option value="6">Friday</option>
                      <option value="7">Saturday</option>
                    </select>
                    <i class="fas fa-angle-down  notificationpositon"></i>
                  </div>

                  <div
                    className={
                      userinfo.UserType === "T"
                        ? "col-lg-2 offset-lg-8 offset-md-8 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
                        : "col-lg-2 offset-lg-4 offset-md-4 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
                    }
                    style={{
                      marginTop: "15px",
                      paddingRight: "1px",
                      marginBottom: "10px",
                    }}
                  >
                    <div className="d-flex">
                      <input
                        placeholder="Search"
                        ref={searchInput}
                        type="text"
                        className="form-control form-control-sm searchField"
                        onChange={searchHandler}
                      />
                    </div>
                  </div>
                </>
              }
            />
          )}
        </div>
      </>

      {popup && (
        <div className="modals">
          <div className="overlay"></div>

          <div className="notification-model-content">
            <div className="popUpHeader ">
              <div className="popUpHeaderText ">Add Class Routine</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closePopup}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="inside-modal">
              <ClassRoutineForm closePopup={closePopup} />
            </div>
          </div>
        </div>
      )}

      {editPop && (
        <div className="modals">
          <div className="overlay"></div>

          <div className="notification-model-content">
            <div className="popUpHeader ">
              <div className="popUpHeaderText ">Edit Class Routine</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closeEditPop}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="inside-modal">
              <ClassRoutineEdit closeEditPop={closeEditPop} />
            </div>
          </div>
        </div>
      )}

      {deletePopup.show && (
        <DeletePop
          handleDeleteTrue={handleDeleteTrue}
          handleDeleteFalse={handleDeleteFalse}
        />
      )}
    </>
  );
}
