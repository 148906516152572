import React, { useContext, useEffect, useState } from "react";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { Fetchdata } from "../../hooks/getData";
import BusContext from "../../contextfolder/BusContext/BusContext";

export default function BusForm({ closePopup }) {
  const { appURL } = useContext(SidebarContext);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const context = useContext(BusContext);

  const {
    togglePopup,
    formError,
    setFormError,
    isSubmit,
    setIsSubmit,
    initialValue,
    formValue,
    setFormValue,
    fetchdata,
    closeChecked,
    setCloseChecked,
  } = context;


  const handleOnChange = () => {
    setCloseChecked(!closeChecked);
  };

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setFormValue({ ...formValue, [name]: value });
  };
 

  const formSubmit = (e) => {
    e.preventDefault();
    setFormError(validate(formValue));
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(formError).length === 0 && isSubmit) {
      fetchdata(formValue);
      if (closeChecked === true) {
        togglePopup();
      }
      setFormValue(initialValue);
      setIsSubmit(false);
    }
  }, [formError]);

  const validate = (values) => {
    const errors = {};
    if (!values.DriverName) {
      errors.DriverName = "Required";
    }
    if (!values.BusName) {
      errors.BusName = "Required";
    }
    if (!values.TrackingID) {
      errors.TrackingID = "Required";
    }
    if (!values.DriverContact) {
      errors.DriverContact = "Required";
    }

    return errors;
  };


  return (
    <>
      <div className="container-fluid basicform">
        <form>
         

          <div className="form-row">
            <div className="form-group col-md-6">
              <label
                className="form-label"
                htmlFor="BusName"
                style={{ fontSize: "12px" }}
              >
                Bus Name<sup style={{ color: "red" }}>*</sup>
              </label>
              <input
                id="BusName"
                style={{ fontSize: "13px" }}
                type="text"
                className="form-control form-control-sm"
                name="BusName"
                value={formValue.BusName}
                onChange={handleChange}
              />
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {formError.BusName}
              </p>
            </div>

            <div className="form-group col-md-6">
              <label
                className="form-label"
                htmlFor="TrackingID"
                style={{ fontSize: "12px" }}
              >
                Tracking ID<sup style={{ color: "red" }}>*</sup>
              </label>
              <input
                id="TrackingID"
                style={{ fontSize: "13px" }}
                type="text"
                className="form-control form-control-sm "
                name="TrackingID"
                value={formValue.TrackingID}
                onChange={handleChange}
              />
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {formError.TrackingID}
              </p>
            </div>
          </div>


          <div className="form-row">
            <div className="form-group col-md-6">
              <label
                className="form-label"
                htmlFor="DriverName"
                style={{ fontSize: "12px" }}
              >
                Driver Name<sup style={{ color: "red" }}>*</sup>
              </label>
              <input
                id="DriverName"
                style={{ fontSize: "13px" }}
                type="text"
                className="form-control form-control-sm"
                name="DriverName"
                value={formValue.DriverName}
                onChange={handleChange}
              />
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {formError.DriverName}
              </p>
            </div>

            <div className="form-group col-md-6">
              <label
                className="form-label"
                htmlFor="DriverContact"
                style={{ fontSize: "12px" }}
              >
                Driver Contact<sup style={{ color: "red" }}>*</sup>
              </label>
              <input
                id="DriverContact"
                style={{ fontSize: "13px" }}
                type="text"
                className="form-control form-control-sm "
                name="DriverContact"
                value={formValue.DriverContact}
                onChange={handleChange}
              />
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {formError.DriverContact}
              </p>
            </div>
          </div>
         
        </form>
      </div>
      <div className="FormFooter">
        <div className="checkedClose">
          <input
            type="checkbox"
            className="form-check-input"
            id="exampleCheck2"
            name="closeChecked"
            onChange={handleOnChange}
            checked={closeChecked}
          />
          <label class="form-check-label" for="exampleCheck2">
            Exit after submit
          </label>
        </div>
        <div>
          <button
            className="btn btn-sm btn-cmpy ml-2"
            style={{ background: "green" }}
            onClick={formSubmit}
          >
            Submit
          </button>
          <button className="btn btn-sm btn-cmpy ml-2" onClick={closePopup}>
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}
