import React, { useContext, useState, useEffect } from "react";
import SidebarContext from "../sidebarcontext/SidebarContext";
import { FetchJobdata } from "../hooks/getJobData";
import { toast } from "react-toastify";
import { FaUser, FaCalendarAlt } from "react-icons/fa";

export default function Comment({
  initialValue,
  formValue,
  setFormValue,
  formError,
  isSubmit,
  setIsSubmit,
  viewHelpDesk,
}) {
  const { jobURL } = useContext(SidebarContext);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setFormValue({ ...formValue, [name]: value });
  };

  // API for comment List
  const [commentList, setCommentList] = useState([]);

  useEffect(() => {
    getCommentList();
  }, []);

  const getCommentList = () => {
    const params = {
      AuthCode: "r1d3r",
      ComID: "ES45",
      Flag: "SC",
      TicketNo: viewHelpDesk.TicketNo,
      Email: userinfo.UserName,
      Type: "POST",
      FetchURL: `${jobURL}api/help-desk/client`,
    };

    FetchJobdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setCommentList(postResult);
      } else {
        setCommentList([]);
      }
    });
  };

  useEffect(() => {
    if (Object.keys(formError).length === 0 && isSubmit) {
      const dataForm = {
        AuthCode: "r1d3r",
        ComID: "ES45",
        Flag: "IC",
        TicketNo: viewHelpDesk.TicketNo,
        Email: userinfo.UserName,
        Comment: formValue.comment,
        FetchURL: `${jobURL}api/help-desk/client`,
        Type: "POST",
      };

      FetchJobdata(dataForm).then(function (result) {
        if (result.StatusCode === 200) {
          getCommentList();
          toast(result.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
        } else {
          toast("Error: " + result.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
      setFormValue(initialValue);
      setIsSubmit(false);
    }
  }, [formError]);

  const handleDeleteTrue = async (data) => {
    const dataForm = {
      AuthCode: "r1d3r",
      ComID: "ES45",
      Flag: "RC",
      CommentID: data.CommentID.toString(),
      Email: userinfo.UserName,
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", Signature: "p0m76" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${jobURL}api/help-desk/client`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          getCommentList();
          toast(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
        } else {
          toast(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
    });
  };

  return (
    <>
      {commentList.length > 0 && (
        <div className="comment__list">
          {commentList.map((props) => (
            <div className="comment__wrapper">
              <div>{props.Comment}</div>
              <div className="d-flex uk-flex-between mt-2">
                <div className="uk-flex">
                  <span>
                    <FaUser />
                    {props.Email}
                  </span>

                  <span>
                    <FaCalendarAlt />
                    {props.CreatedDate}
                  </span>
                </div>

                <div
                  className="remove__comment"
                  onClick={() => handleDeleteTrue(props)}
                >
                  Remove
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      <div className=" basicform comment__form">
        <form>
          <div className="form-group">
            {/* <label
              className="form-label"
              htmlFor="comment"
              style={{ fontSize: "12px" }}
            >
              Comment <sup style={{ color: "red" }}>*</sup>
            </label> */}
            <textarea
              placeholder="Add a comment"
              id="comment"
              style={{ fontSize: "13px" }}
              type="text"
              className="form-control form-control-sm "
              name="comment"
              value={formValue.comment}
              onChange={handleChange}
              rows="3"
            />
            <p className="errormsg " style={{ marginBottom: "0" }}>
              {formError.comment}
            </p>
          </div>
        </form>
      </div>
    </>
  );
}
