import React, { useContext, useEffect } from 'react'
import SliderContext from '../contextfolder/SliderContext/SliderContext';

export default function AcademicForm({ closePopup }) {

    const context = useContext(SliderContext)

    const { toggleModal, academicFormError, setAcademicFormError, academicIsSubmit, setAcademicIsSubmit, academicValue, academicFormValue, setAcademicFormValue, active, setActive, fetchacademicdata, closeChecked, setCloseChecked } = context;



    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;
        setAcademicFormValue({ ...academicFormValue, [name]: value });
    };

    const handleActive = () => {
        setActive(!active)
    }

    const handleOnChange = () => {
        setCloseChecked(!closeChecked);
    };



    const formSubmit = (e) => {
        e.preventDefault();
        setAcademicFormError(validate(academicFormValue));
        setAcademicIsSubmit(true);
    }

    useEffect(() => {
        if (Object.keys(academicFormError).length === 0 && academicIsSubmit) {
            fetchacademicdata(academicFormValue)
            if (closeChecked === true) {
                toggleModal();
            }
            setAcademicFormValue(academicValue)
            setAcademicIsSubmit(false);
        }

    }, [academicFormError]);

    const validate = (values) => {

        const errors = {};
        if (!values.year) {
            errors.year = "Required";
        }
        if (!values.from) {
            errors.from = "Required";
        }
        if (!values.to) {
            errors.to = "Required";
        }

        return errors;
    };



    return (
        <>

            <div className="container-fluid basicform" >

                <form >

                    <div className="form-group">
                        <label htmlFor="year" className="form-label" style={{ fontSize: "12px" }}>
                            Year<sup style={{ color: "red" }}>*</sup>
                        </label>
                        <input
                            id="year"
                            style={{ fontSize: "13px" }}
                            type="text"
                            className="form-control form-control-sm "
                            name="year"
                            value={academicFormValue.year}
                            onChange={handleChange}
                        />
                        <p className="errormsg " style={{ marginBottom: "0" }}>{academicFormError.year}</p>

                    </div>


                    <div className="form-row">

                        <div className="form-group col-md-6">
                            <label className="form-label" htmlFor="from">From <sup style={{ color: "red" }}>*</sup></label>
                            <input
                                id="from"
                                style={{ fontSize: "13px" }}
                                type="text"
                                className="form-control form-control-sm "
                                name="from"
                                value={academicFormValue.from}
                                onChange={handleChange}
                            />
                            <p className="errormsg " style={{ marginBottom: "0" }}>{academicFormError.from}</p>
                        </div>

                        <div className="form-group col-md-6">
                            <label className="form-label" htmlFor="to">To <sup style={{ color: "red" }}>*</sup></label>
                            <input
                                id="to"
                                style={{ fontSize: "13px" }}
                                type="text"
                                className="form-control form-control-sm "
                                name="to"
                                value={academicFormValue.to}
                                onChange={handleChange}
                            />
                            <p className="errormsg " style={{ marginBottom: "0" }}>{academicFormError.to}</p>
                        </div>

                    </div>

                    <div className="form-row">

                        <div style={{ marginLeft: "9px", marginTop: "15px" }}>
                            <input
                                type="checkbox"
                                // className="form-check-input"
                                id="exampleCheck3"
                                name="notWorking"
                                onChange={handleActive}
                                checked={active}
                            />
                            <label class="form-check-label" for="exampleCheck3" style={{ fontSize: "12px", paddingLeft: "3px" }}>
                                Active
                            </label>
                        </div>
                    </div>


                </form>


            </div>
            <div className="FormFooter">
                <div className='checkedClose'>
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck2"
                        name="closeChecked"
                        onChange={handleOnChange}
                        checked={closeChecked}
                    />
                    <label class="form-check-label" for="exampleCheck2">
                        Exit after submit
                    </label>
                </div>
                <div>
                    <button className='btn btn-sm btn-cmpy ml-2' style={{ background: "green" }} onClick={formSubmit} >Submit</button>
                    <button className='btn btn-sm btn-cmpy ml-2' onClick={closePopup}>Cancel</button>
                </div>
            </div>

        </>

    )
}
