import React, { useContext, useEffect, useState } from "react";
import { Fetchdata } from "../../hooks/getData";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { toast } from "react-toastify";
import CourseContext from "./CourseContext";

function CourseState(props) {
  const { appURL } = useContext(SidebarContext);

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const [closeChecked, setCloseChecked] = useState(false);

  const courseValue = {
    class: "",
    subject: "",
    term: "",
    chapter: "",
    title: "",
    duration: "",
    description: "",
  };
  const [courseFormValue, setCourseFormValue] = useState(courseValue);

  const [courseFormError, setCourseFormError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [editIsSubmit, setEditIsSubmit] = useState(false);

  const [popup, setPopup] = useState(false);
  const togglePopup = () => {
    setPopup(!popup);
  };

  const [editPopup, setEditPopup] = useState(false);
  const toggleEditPopup = () => {
    setEditPopup(!editPopup);
  };

  const [classList, setClassList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [chapterList, setChapterList] = useState([]);

  const [selectClass, setSelectClass] = useState("");
  const [selectSubject, setSelectSubject] = useState("");
  const [selectChapter, setSelectChapter] = useState("");
  const [selectTerm, setSelectTerm] = useState("");

  useEffect(() => {
    getClassList();
  }, []);

  const getClassList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      DFlag: "C",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setClassList(postResult);
      } else {
        setClassList([]);
      }
    });
  };

  //API to hit Subject List

  useEffect(() => {
    getSubjectList();
  }, [selectClass]);

  const getSubjectList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      ClassID: selectClass,
      DFlag: "SB",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";

        setSubjectList(postResult);
      } else {
        setSubjectList([]);
      }
    });
  };

  useEffect(() => {
    getChapterList();
  }, [selectClass, selectSubject]);

  const getChapterList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      Flag: "S",
      ClassID: selectClass,
      SubjectID: selectSubject,
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/course-chapter`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.AttenLst ? result.AttenLst : "";
        setChapterList(postResult);
      } else {
        setChapterList([]);
      }
    });
  };

  //API to hit Terminal list
  const [termList, setTermList] = useState([]);

  useEffect(() => {
    getTermList();
  }, []);

  const getTermList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      DFlag: "T",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";

        setTermList(postResult);
      } else {
        setTermList([]);
      }
    });
  };

  //API to create Course
  const fetchdata = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "i",
      ChapterID: courseFormValue.chapter,
      TermID: courseFormValue.term,
      Duration: courseFormValue.duration,
      Description: courseFormValue.description,
      Title: courseFormValue.title,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/course-plan`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getCourseList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };

  // API for Course List
  const [courseList, setCourseList] = useState([]);

  useEffect(() => {
    getCourseList();
  }, [selectChapter, selectTerm]);

  const getCourseList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      Flag: "S",
      TermID: selectTerm,
      ChapterID: selectChapter,
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/course-plan`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.AttenLst ? result.AttenLst : "";
        setLoading(false);
        setCourseList(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        setCourseList([]);
        setOriginalList([]);
      }
    });
  };

  const [titleId, setTitleID] = useState();
  const editPop = (datas) => {
    setEditPopup(true);
    setTitleID(datas.CoursePlanID);
    setCourseFormValue({
      chapter: datas.ChapterID.toString(),
      title: datas.Title,
      duration: datas.Duration,
      description: datas.Description,
    });
    setEditIsSubmit(false);
  };

  //API to edit chapter
  const editdata = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "U",
      CoursePlanID: titleId.toString(),
      ChapterID: selectChapter,
      TermID: selectTerm,
      Duration: courseFormValue.duration,
      Description: courseFormValue.description,
      Title: courseFormValue.title,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/course-plan`,
      Type: "POST",
    };
    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getCourseList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };

  // //Everything to pop up and delete the chapter list
  const [deletePopup, setDeletePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const [delData, setDelData] = useState();

  const handleDelete = async (data) => {
    setDelData(data);
    setDeletePopup({
      show: true,
      data,
    });
  };

  // This will perform the deletion and hide the Confirmation Box

  const handleDeleteTrue = async () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "R",
      CoursePlanID: delData.toString(),
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/course-plan`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          getCourseList();
          toast(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
        } else {
          toast(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
    });
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleDeleteFalse = () => {
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // Ends Here

  //Activate popup starts from here
  //Everything to pop up and approve the  list
  const [activatePopup, setActivatePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });
  // To approve the credit List

  const [Acdata, setACData] = useState();

  const handleActivate = async (data) => {
    setACData(data.CoursePlanID);

    setActivatePopup({
      show: true,
      data,
    });
  };

  // This will perform the approve and hide the Confirmation Box

  const handleActivateTrue = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "AI",
      CoursePlanID: Acdata.toString(),
      IsActive: "A",
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/course-plan`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          getCourseList();
          toast(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
        } else {
          toast(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
    });
    setActivatePopup({
      show: false,
      data: null,
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleActivateFalse = () => {
    setActivatePopup({
      show: false,
      data: null,
    });
  };

  //Everything to pop up and activate  ends

  //Activate popup starts from here
  //Everything to pop up and approve the  list
  const [inActivatePopup, setInActivatePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });
  // To approve the credit List

  const [inactivedata, setInactiveData] = useState();

  const handleInActivate = async (data) => {
    setInactiveData(data.CoursePlanID);

    setInActivatePopup({
      show: true,
      data,
    });
  };

  // This will perform the approve and hide the Confirmation Box

  const handleInActivateTrue = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "AI",
      CoursePlanID: inactivedata.toString(),
      IsActive: "I",
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/course-plan`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          getCourseList();
          toast(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
        } else {
          toast(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
    });
    setInActivatePopup({
      show: false,
      data: null,
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleInActivateFalse = () => {
    setInActivatePopup({
      show: false,
      data: null,
    });
  };

  //Everything to pop up and approve the chapter list ends

  return (
    <CourseContext.Provider
      value={{
        loading,
        originalList,
        deletePopup,
        handleDeleteTrue,
        handleDeleteFalse,
        handleDelete,
        popup,
        setPopup,
        togglePopup,
        chapterList,
        termList,
        courseFormValue,
        setCourseFormValue,
        courseFormError,
        setCourseFormError,
        isSubmit,
        courseValue,
        setIsSubmit,
        classList,
        selectClass,
        setSelectClass,
        closeChecked,
        setCloseChecked,
        subjectList,
        selectSubject,
        setSelectSubject,
        selectChapter,
        setSelectChapter,
        selectTerm,
        setSelectTerm,
        courseList,
        setCourseList,
        fetchdata,
        activatePopup,
        setActivatePopup,
        handleActivate,
        handleActivateTrue,
        handleActivateFalse,
        inActivatePopup,
        setInActivatePopup,
        handleInActivate,
        handleInActivateTrue,
        handleInActivateFalse,

        toggleEditPopup,
        editPopup,
        editIsSubmit,
        setEditIsSubmit,
        editPop,
        editdata,
      }}
    >
      {props.children}
    </CourseContext.Provider>
  );
}

export default CourseState;
