import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import jobLogo from "../../images/jobLogo.png";
import { MdLocationOn, MdOutlineAccessTimeFilled } from "react-icons/md";
import { FetchJobdata } from "../hooks/getJobData";
import html2canvas from "html2canvas";
import { saveAs } from "file-saver";
import EliteJobContext from "../contextfolder/EliteJobContext/EliteJobContext";
import { FiDownload } from "react-icons/fi";

const SingleDesign = () => {
  const { jobList } = useContext(EliteJobContext);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const [chooseDesignation, setChooseDesignation] = useState("");
  const [filteredList, setFilteredList] = useState([]);

  useEffect(() => {
    if (jobList) {
      setFilteredList([jobList[0]]);
    }
  }, [jobList]);

  const containerRef = useRef(null);

  const downloadJobListCard = () => {
    const container = containerRef.current;

    if (!container) {
      console.error("Container element not found");
      return;
    }

    html2canvas(container, {
      // Additional configuration options
      useCORS: true, // Enable cross-origin resource sharing (if needed)
    })
      .then((canvas) => {
        const dataUrl = canvas.toDataURL("image/png");
        saveAs(dataUrl, "jobListCard.png");
      })
      .catch((error) => {
        console.error("Error generating image", error);
      });
  };

  return (
    <div className="single__design">
      <div className="uk-flex uk-flex-wrap uk-flex-bottom uk-flex-between">
        {jobList.length > 0 && (
          <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
            <label className="list-label">Select Job</label>
            <select
              style={{ fontSize: "11px" }}
              name="status"
              className="form-control form-control-sm"
              aria-label="Default select example "
              onChange={(e) => {
                const filterList = jobList.filter(
                  (item) => item.JobID === parseInt(e.target.value)
                );
                setFilteredList(filterList);
              }}
            >
              {jobList.map((item) => (
                <option key={item.JobID} value={item.JobID}>
                  {item.Designation}
                </option>
              ))}
            </select>
            <i class="fas fa-angle-down  notificationpositon"></i>
          </div>
        )}

        <button
          className="btn btn-sm uk-flex uk-flex-middle mb-2"
          style={{ background: "rgb(11, 153, 11)", color: "white" }}
          onClick={downloadJobListCard}
        >
          Download <FiDownload className="ms-2" />
        </button>
      </div>
      <div className="single__design__card" ref={containerRef} id="jobListCard">
        <div className="single__design__header">
          <div>
            <Link>jobs.eliteinfotechnp.com</Link>
          </div>

          <div>
            <img src={jobLogo} alt="" />
          </div>
        </div>

        <div className="single__design__content">
          <h1>
            We're <span>hiring</span>
          </h1>

          <div>
            {filteredList &&
              filteredList.length > 0 &&
              filteredList.map((item) => {
                return (
                  <div
                    className="jobListsCard"
                    style={{ boxShadow: "0 0 3px rgba(30, 41, 59, 0.15)" }}
                  >
                    <div className="job__wrapper">
                      <div className="com__logo">
                        <img src={userinfo.SchLogo} alt="logo" />
                      </div>

                      <div class="job__content">
                        <h2
                          className="fw-bold m-0 fs-4 lh-5"
                          style={{ color: "#000" }}
                        >
                          {item?.Designation}
                        </h2>

                        <div className="com__info">{userinfo.SchName}</div>

                        <div className="job__txt mt-1">
                          <MdLocationOn
                            color="#0b4881"
                            size="1.5rem"
                            className="me-1"
                          />{" "}
                          {userinfo.SchAddress}
                        </div>
                      </div>
                    </div>

                    <div
                      className="uk-flex uk-flex-between uk-flex-middle mt-3"
                      style={{ borderTop: "1px solid #eee" }}
                    >
                      <div className="job__deadline uk-flex uk-flex-middle">
                        <MdOutlineAccessTimeFilled
                          color="#0b4881"
                          size="1.2rem"
                          className="me-1"
                          style={{ marginTop: "1px" }}
                        />{" "}
                        {item?.EndDate}
                      </div>
                      <div className="badge__wrapper">
                        <div className="job__badge">{item?.JobType}</div>
                        <div className="job__badge">On-Site</div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          {/* <div className="bottom__txt">
            <p>
              Send your CV at <strong>easysoftwarenepal@gmail.com</strong>
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default SingleDesign;
