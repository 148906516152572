import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";
import "tippy.js/dist/tippy.css";
import { Fetchdata } from "../hooks/getData";
import { showImgPreview } from "../hooks/ImagePreview";
import { GetNepaliDate } from "../hooks/dateConvertor";

export default function GeneralNotification() {
  const { customStyles, appURL } = useContext(SidebarContext);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);

  const [imgPrv, setImgPrv] = useState(false);
  const [imagePre, setImagePre] = useState("");

  // API for genral notification list
  const [notificationList, setNotificationList] = useState([]);

  useEffect(() => {
    getGeneralNotificationList();
  }, []);

  const getGeneralNotificationList = () => {
    const params = {
      SchID: userinfo.SchID,
      Flag: "SG",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/notification`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.NotificationLst ? result.NotificationLst : "";
        setLoading(false);
        setNotificationList(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        setNotificationList([]);
        setOriginalList([]);
      }
    });
  };

  const searchInput = useRef("");

  const columns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "Date",
      width: "120px",
      selector: (row) => GetNepaliDate(row.NotificationDate.split(" ")[0]),
    },
    {
      name: "Title",
      selector: (row) => row.Title,
      width: "150px",
    },
    {
      name: "Description",
      selector: (row) => row.Description,
    },
    {
      name: "Attachment",
      // grow: 0,
      center: true,
      width: "130px",
      selector: (row) => {
        return (
          <>
            <div className="staffContentLogo">
              <div className="staffImg">
                <img
                  src={row.Attachment}
                  alt=""
                  onClick={() => {
                    setImagePre(row.Attachment);
                    setImgPrv(true);
                  }}
                />
              </div>
            </div>
          </>
        );
      },
    },
  ];

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["Title"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setNotificationList(srchResult);
      } else {
        setNotificationList({});
      }
    } else {
      setNotificationList(originalList);
    }
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <div className="sec-dataTable">
        {loading ? (
          <Spinner />
        ) : (
          <DataTable
            columns={columns}
            data={notificationList}
            customStyles={customStyles}
            pagination
            fixedHeader
            fixedHeaderScrollHeight="280px"
            highlightOnHover
            pointerOnHover
            responsive
            dense
            striped
            subHeader
            subHeaderAlign="left"
            subHeaderComponent={
              <>
                <div
                  className="col-lg-2 offset-lg-10 offset-md-10 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
                  style={{ paddingRight: "1px", marginBottom: "10px" }}
                >
                  <div className="d-flex">
                    <input
                      placeholder="Search"
                      ref={searchInput}
                      type="text"
                      className="form-control form-control-sm searchField"
                      onChange={searchHandler}
                    />
                  </div>
                </div>
              </>
            }
          />
        )}
      </div>
      {imgPrv &&
        showImgPreview({
          img: imagePre,
          setTrigger: setImgPrv,
        })}
    </>
  );
}
