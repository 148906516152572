import React from 'react';
import '../css/Footer.css'

function Footer() {
    return <footer className="footerr mt-auto">
        Copyright <i className="far fa-copyright"></i> 2022 Easy Software Pvt.Ltd | All Right Reserved
    </footer>;
}

export default Footer;
