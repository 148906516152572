import React, { useContext, useEffect, useState } from "react";
import SidebarContext from "../sidebarcontext/SidebarContext";
import { Fetchdata } from "../hooks/getData";
import CourseContext from "../contextfolder/CourseContext/CourseContext";

export default function CourseForm({ closePopup }) {
  const { appURL } = useContext(SidebarContext);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const context = useContext(CourseContext);

  const {
    togglePopup,
    courseFormError,
    setCourseFormError,
    isSubmit,
    setIsSubmit,
    courseValue,
    courseFormValue,
    setCourseFormValue,
    fetchdata,
    closeChecked,
    setCloseChecked,
  } = context;

  const handleOnChange = () => {
    setCloseChecked(!closeChecked);
  };

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setCourseFormValue({ ...courseFormValue, [name]: value });
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setCourseFormError(validate(courseFormValue));
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(courseFormError).length === 0 && isSubmit) {
      fetchdata(courseFormValue);
      if (closeChecked === true) {
        togglePopup();
      }
      setCourseFormValue(courseValue);
      setIsSubmit(false);
    }
  }, [courseFormError]);

  const validate = (values) => {
    const errors = {};
    if (!values.class) {
      errors.class = "Required";
    }
    if (!values.chapter) {
      errors.chapter = "Required";
    }
    if (!values.term) {
      errors.term = "Required";
    }
    if (!values.title) {
      errors.title = "Required";
    }
    if (!values.duration) {
      errors.duration = "Required";
    }
    if (!values.description) {
      errors.description = "Required";
    }
    if (!values.subject) {
      errors.subject = "Required";
    }

    return errors;
  };

  //API for class list
  const [classList, setClassList] = useState([]);
  useEffect(() => {
    getClassList();
  }, []);

  const getClassList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      DFlag: "C",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setClassList(postResult);
      } else {
        setClassList([]);
      }
    });
  };

  //API for subject list
  const [subjectList, setSubjectList] = useState([]);

  useEffect(() => {
    getSubjectList();
  }, [courseFormValue.class]);

  const getSubjectList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      ClassID: courseFormValue.class,
      DFlag: "SB",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";

        setSubjectList(postResult);
      } else {
        setSubjectList([]);
      }
    });
  };

  //APi for Chapter List

  const [chapterList, setChapterList] = useState([]);

  useEffect(() => {
    getChapterList();
  }, [courseFormValue.class, courseFormValue.subject]);

  const getChapterList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      Flag: "S",
      ClassID: courseFormValue.class,
      SubjectID: courseFormValue.subject,
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/course-chapter`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.AttenLst ? result.AttenLst : "";
        setChapterList(postResult);
      } else {
        setChapterList([]);
      }
    });
  };

  //API to hit Terminal list
  const [termList, setTermList] = useState([]);

  useEffect(() => {
    getTermList();
  }, []);

  const getTermList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      DFlag: "T",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";

        setTermList(postResult);
      } else {
        setTermList([]);
      }
    });
  };

  return (
    <>
      <div className="container-fluid basicform">
        <form>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label className="form-label" htmlFor="class">
                Class<sup style={{ color: "red" }}>*</sup>
              </label>
              <select
                id="class"
                name="class"
                onChange={handleChange}
                value={courseFormValue.class}
                className="form-select form-select-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="" disabled selected style={{ fontSize: "11px" }}>
                  Select Class
                </option>
                {classList.map((item) => (
                  <option key={item.ClassID} value={item.ClassID}>
                    {item.ClassName}
                  </option>
                ))}
              </select>
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {courseFormError.class}
              </p>
            </div>

            <div className="form-group col-md-6">
              <label className="form-label" htmlFor="subject">
                Subject<sup style={{ color: "red" }}>*</sup>
              </label>
              <select
                id="subject"
                name="subject"
                onChange={handleChange}
                value={courseFormValue.subject}
                className="form-select form-select-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="" disabled selected style={{ fontSize: "11px" }}>
                  Select Subject
                </option>
                {subjectList.map((item) => (
                  <option key={item.SubjectID} value={item.SubjectID}>
                    {item.SubjectName}
                  </option>
                ))}
              </select>
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {courseFormError.subject}
              </p>
            </div>
          </div>

          <div className="form-group">
            <label className="form-label" htmlFor="term">
              Term<sup style={{ color: "red" }}>*</sup>
            </label>
            <select
              id="term"
              name="term"
              onChange={handleChange}
              value={courseFormValue.term}
              className="form-select form-select-sm mb-1"
              style={{ fontSize: "12px" }}
            >
              <option value="" disabled selected style={{ fontSize: "11px" }}>
                Select Term
              </option>
              {termList.map((item) => (
                <option key={item.TermID} value={item.TermID}>
                  {item.TermName}
                </option>
              ))}
            </select>
            <p className="errormsg " style={{ marginBottom: "0" }}>
              {courseFormError.term}
            </p>
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="chapter">
              Chapter<sup style={{ color: "red" }}>*</sup>
            </label>
            <select
              id="chapter"
              name="chapter"
              onChange={handleChange}
              value={courseFormValue.chapter}
              className="form-select form-select-sm mb-1"
              style={{ fontSize: "12px" }}
            >
              <option value="" disabled selected style={{ fontSize: "11px" }}>
                Select Chapter
              </option>
              {chapterList.map((item) => (
                <option key={item.ChapterID} value={item.ChapterID}>
                  {item.ChapterTitle}
                </option>
              ))}
            </select>
            <p className="errormsg " style={{ marginBottom: "0" }}>
              {courseFormError.chapter}
            </p>
          </div>

          <div className="form-group">
            <label
              className="form-label"
              htmlFor="title"
              style={{ fontSize: "12px" }}
            >
              Title<sup style={{ color: "red" }}>*</sup>
            </label>
            <input
              id="title"
              style={{ fontSize: "13px" }}
              type="text"
              className="form-control form-control-sm"
              name="title"
              value={courseFormValue.title}
              onChange={handleChange}
            />
            <p className="errormsg " style={{ marginBottom: "0" }}>
              {courseFormError.title}
            </p>
          </div>

          <div className="form-group">
            <label
              className="form-label"
              htmlFor="duration"
              style={{ fontSize: "12px" }}
            >
              Duration (Hour)<sup style={{ color: "red" }}>*</sup>
            </label>
            <input
              id="duration"
              style={{ fontSize: "13px" }}
              type="text"
              className="form-control form-control-sm"
              name="duration"
              value={courseFormValue.duration}
              onChange={handleChange}
            />
            <p className="errormsg " style={{ marginBottom: "0" }}>
              {courseFormError.duration}
            </p>
          </div>

          <div className="form-group">
            <label
              className="form-label"
              htmlFor="description"
              style={{ fontSize: "12px" }}
            >
              Description<sup style={{ color: "red" }}>*</sup>
            </label>
            <textarea
              id="description"
              style={{ fontSize: "13px" }}
              type="text"
              className="form-control form-control-sm "
              name="description"
              value={courseFormValue.description}
              onChange={handleChange}
            />
            <p className="errormsg " style={{ marginBottom: "0" }}>
              {courseFormError.description}
            </p>
          </div>
        </form>
      </div>
      <div className="FormFooter">
        <div className="checkedClose">
          <input
            type="checkbox"
            className="form-check-input"
            id="exampleCheck2"
            name="closeChecked"
            onChange={handleOnChange}
            checked={closeChecked}
          />
          <label class="form-check-label" for="exampleCheck2">
            Exit after submit
          </label>
        </div>
        <div>
          <button
            className="btn btn-sm btn-cmpy ml-2"
            style={{ background: "green" }}
            onClick={formSubmit}
          >
            Submit
          </button>
          <button className="btn btn-sm btn-cmpy ml-2" onClick={closePopup}>
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}
