import React, { useContext, useEffect, useState } from "react";
import "jspdf-autotable";
import StudentLogBookContext from "../contextfolder/StudentLogBookContext/StudentLogBookContext";

const ViewInfo = ({ closePopup }) => {
  const { infoID } = useContext(StudentLogBookContext);

  return (
    <>
      <div className="container-fluid basicform">
        <table className="complain-table" style={{ width: "100%" }}>
          <thead>
            <tr className="tableHead viewHead">
              <td
                className="tl"
                style={{
                  fontSize: "12px",
                  paddingLeft: "10px",
                  position: "relative",
                }}
              >
                Page No.
              </td>
              <td
                className="tl"
                style={{
                  fontSize: "12px",
                  paddingLeft: "10px",
                  position: "relative",
                }}
              >
                Lesson No.
              </td>
              <td
                className="tl"
                style={{
                  fontSize: "12px",
                  paddingLeft: "10px",
                  position: "relative",
                }}
              >
                Subject
              </td>
            </tr>
          </thead>
          <tbody>
            {infoID && (
              <tr className="viewHead">
                <td
                  className="tl"
                  style={{
                    fontSize: "12px",
                    paddingLeft: "10px",
                    paddingTop: "0px",
                  }}
                >
                  {infoID.PageNo}
                </td>
                <td
                  className="tl"
                  style={{
                    fontSize: "12px",
                    paddingLeft: "10px",
                    paddingTop: "0px",
                  }}
                >
                  {infoID.LessonNo}
                </td>
                <td
                  className="tl"
                  style={{
                    fontSize: "12px",
                    paddingLeft: "10px",
                    paddingTop: "0px",
                  }}
                >
                  {infoID.Subject}
                </td>
              </tr>
            )}
          </tbody>

          <thead>
            <tr className="tableHead viewHead">
              <td
                className="tl"
                style={{
                  fontSize: "12px",
                  paddingLeft: "10px",
                  position: "relative",
                }}
              >
                Lesson Taught
              </td>
            </tr>
          </thead>

          <tbody>
            {infoID && (
              <tr className="viewHead">
                <td
                  className="tl"
                  style={{
                    fontSize: "12px",
                    paddingLeft: "10px",
                    paddingTop: "0px",
                  }}
                >
                  {infoID.Lesson}
                </td>
              </tr>
            )}
          </tbody>

          <thead>
            <tr className="tableHead viewHead">
              <td
                className="tl"
                style={{
                  fontSize: "12px",
                  paddingLeft: "10px",
                  position: "relative",
                }}
              >
                Assignment Given
              </td>
            </tr>
          </thead>

          <tbody>
            {infoID && (
              <tr className="viewHead">
                <td
                  className="tl"
                  style={{
                    fontSize: "12px",
                    paddingLeft: "10px",
                    paddingTop: "0px",
                  }}
                >
                  {infoID.AssignmentGiven}
                </td>
              </tr>
            )}
          </tbody>

          <thead>
            <tr className="tableHead viewHead">
              <td
                className="tl"
                style={{
                  fontSize: "12px",
                  paddingLeft: "10px",
                  position: "relative",
                }}
              >
                Created Date
              </td>
              <td
                className="tl"
                style={{
                  fontSize: "12px",
                  paddingLeft: "10px",
                  position: "relative",
                }}
              >
                Last Updated Date
              </td>
            </tr>
          </thead>
          <tbody>
            {infoID && (
              <tr className="viewHead">
                <td
                  className="tl"
                  style={{
                    fontSize: "12px",
                    paddingLeft: "10px",
                    paddingTop: "0px",
                  }}
                >
                  {infoID.CreatedDate}
                </td>
                <td
                  className="tl"
                  style={{
                    fontSize: "12px",
                    paddingLeft: "10px",
                    paddingTop: "0px",
                  }}
                >
                  {infoID.LastUpdatedDate}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="FormFooter">
        <div></div>
        <div>
          <button className="btn btn-sm btn-cmpy ml-2" onClick={closePopup}>
            Cancel
          </button>
        </div>
      </div>
    </>
  );
};

export default ViewInfo;
