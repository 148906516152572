import React, { useContext, useState, useEffect } from 'react'
import { useRef } from 'react';
import DataTable from 'react-data-table-component';
import Spinner from '../loading/spinner';
import SidebarContext from '../sidebarcontext/SidebarContext';
import StudyMaterialContext from '../contextfolder/StudyMaterialContext/StudyMaterialContext';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import DeleteTopicPop from './DeleteTopicPop';



export default function StudyAlbumForm({ closeAlbumPopup }) {

    const context = useContext(StudyMaterialContext)

    const { albumFormError, setAlbumFormError, albumIsSubmit, setAlbumIsSubmit, albumValue, albumFormValue, setAlbumFormValue, albumSearch, loading, albumClassList, albumSectionList, albumStudentList, setCloseChecked, closeChecked, toggleAlbumPopup, fetchdata, studyAlbumList, setStudyAlbumList, isEdit, selectUser, editdata, handleTopicDelete, getStudyAlbumList,

        deleteTopicPopup, handleDeleteTopicTrue, handleDeleteTopicFalse,
    } = context;

    const userinfo = JSON.parse(localStorage.getItem("userInfo"));

    const handleOnChange = () => {
        setCloseChecked(!closeChecked);
        getStudyAlbumList()
    };

    const { customStyles } = useContext(SidebarContext)


    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;
        setAlbumFormValue({ ...albumFormValue, [name]: value });
    };

    const formSubmit = (e) => {
        e.preventDefault();
        setAlbumFormError(validate(albumFormValue));
        setAlbumIsSubmit(true);
    }
    useEffect(() => {
        if (Object.keys(albumFormError).length === 0 && albumIsSubmit) {
            fetchdata(albumFormValue)
            if (closeChecked === true) {
                toggleAlbumPopup();
            }
            setAlbumIsSubmit(false);
            setCloseChecked(false);
            setAlbumFormValue(albumValue)
        }

    }, [albumFormError]);





    const validate = (values) => {
        const errors = {};
        if (albumFormValue.selectdrop === "C" || albumFormValue.selectdrop === "S" || albumFormValue.selectdrop === "I") {
            if (!values.class) {
                errors.class = "Required";
            }
        }
        if (albumFormValue.selectdrop === "S" || albumFormValue.selectdrop === "I") {
            if (!values.section) {
                errors.section = "Required";
            }
        }
        if (albumFormValue.selectdrop === "I") {
            if (!values.student) {
                errors.student = "Required";
            }
        }
        if (!values.title) {
            errors.title = "Required";
        }
        if (!values.description) {
            errors.description = "Required";
        }
        return errors;
    };


    const columns = [
        {
            name: "S.N.",
            width: "59px",
            cell: (row, index) => index + 1,
        },

        {
            name: "Title",
            center: true,
            // grow: 0,
            selector: (row) => row.Title,
        },

        {
            name: "No. of Material",
            center: true,
            // grow: 0,
            selector: (row) => row.NoOfMaterial,
        },
        {
            name: "Action",
            center: true,
            selector: (row) => {
                return (
                    <>
                        <>
                            <div className="ln-verition d-flex">
                                <Tippy content="Edit">
                                    <i
                                        className="fa fa-pencil solvespan--action"
                                        onClick={() => selectUser(row)}
                                    >
                                    </i>
                                </Tippy>
                                <Tippy content="Delete">
                                    <i
                                        className="fa fa-trash-o rejectspan--action"
                                        onClick={() => handleTopicDelete(row.StudyAlbumID)}
                                    >
                                    </i>
                                </Tippy>
                            </div>
                        </>

                    </>
                );
            },
        },

    ];

    const searchInput = useRef("");
    const searchHandler = (e) => {
        e.preventDefault();

        const srchQuery = searchInput.current.value.toLowerCase();
        if (srchQuery) {

            let srchResult = albumSearch.filter((list) => {
                return list["Title"].toLowerCase().includes(srchQuery)
            });

            if (srchResult) {

                setStudyAlbumList(srchResult);
            } else {
                setStudyAlbumList({});
            }
        } else {
            setStudyAlbumList(albumSearch);
        }
    };



    return (
        <>

            <div className="container-fluid basicform" >

                <form >

                    <div className="form-group">
                        <label className="form-label" htmlFor="selectdrop">Select Option<sup style={{ color: "red" }}>*</sup></label>
                        <select id="selectdrop" name="selectdrop"
                            onChange={handleChange}
                            value={albumFormValue.selectdrop} className="form-select form-select-sm mb-1" style={{ fontSize: '12px' }}>
                            <option value="" selected disabled>Select Option</option>
                            <option value="-1">All</option>
                            <option value="C">Class</option>
                            <option value="S">Section</option>
                            <option value="I">Individual</option>
                        </select>
                    </div>

                    <div className="form-row">
                        {albumFormValue.selectdrop === "C" || albumFormValue.selectdrop === "S" || albumFormValue.selectdrop === "I" ? (
                            <div className={albumFormValue.selectdrop === "C" ? "form-group col-md-12" : albumFormValue.selectdrop === "S" ? "form-group col-md-6" : "form-group col-md-4"}>
                                <label className="form-label" htmlFor="class">Class<sup style={{ color: "red" }}>*</sup></label>
                                <select id="class" name="class"
                                    onChange={handleChange}
                                    value={albumFormValue.class} className="form-select form-select-sm mb-1" style={{ fontSize: '12px' }}
                                    disabled={isEdit === true}
                                >
                                    <option
                                        value=""
                                        disabled
                                        selected
                                        style={{ fontSize: "11px" }}
                                    >
                                        Select Class
                                    </option>
                                    {albumClassList.map((item) => (
                                        <option
                                            key={item.ClassID}
                                            value={item.ClassID}
                                        >
                                            {item.ClassName}
                                        </option>
                                    ))}
                                </select>
                                <p className="errormsg " style={{ marginBottom: "0" }}>{albumFormError.class}</p>
                            </div>
                        ) : (<></>)}

                        {albumFormValue.selectdrop === "S" || albumFormValue.selectdrop === "I" ? (
                            <div className={albumFormValue.selectdrop === "S" ? "form-group col-md-6" : "form-group col-md-4"}>
                                <label className="form-label" htmlFor="section">Section<sup style={{ color: "red" }}>*</sup></label>
                                <select id="section" name="section"
                                    onChange={handleChange}
                                    value={albumFormValue.section} className="form-select form-select-sm mb-1" style={{ fontSize: '12px' }}
                                    disabled={isEdit === true}>
                                    <option
                                        value=""
                                        disabled
                                        selected
                                        style={{ fontSize: "11px" }}
                                    >
                                        Select Section
                                    </option>
                                    {albumSectionList.map((item) => (
                                        <option
                                            key={item.SectionID}
                                            value={item.SectionID}
                                        >
                                            {item.SectionName}
                                        </option>
                                    ))}
                                </select>
                                <p className="errormsg " style={{ marginBottom: "0" }}>{albumFormError.section}</p>
                                <i className="fas fa-angle-down  notificationpositon"></i>
                            </div>
                        ) : (<></>)}
                        {albumFormValue.selectdrop === "I" ? (
                            <div className="form-group col-md-4">
                                <label className="form-label" htmlFor="ss">Student<sup style={{ color: "red" }}>*</sup></label>
                                <select id="ss" name="student"
                                    onChange={handleChange}
                                    value={albumFormValue.student} className="form-select form-select-sm mb-1" style={{ fontSize: '12px' }}
                                    disabled={isEdit === true}>
                                    <option
                                        value=""
                                        disabled
                                        selected
                                        style={{ fontSize: "11px" }}
                                    >
                                        Select Student
                                    </option>
                                    {albumStudentList.map((item) => (
                                        <option
                                            key={item.StudentID}
                                            value={item.StudentID}
                                        >
                                            {item.StdFullName}
                                        </option>
                                    ))}
                                </select>
                                <p className="errormsg " style={{ marginBottom: "0" }}>{albumFormError.student}</p>
                            </div>
                        ) : (<></>)}
                    </div>


                    <div className="form-group">
                        <label className="form-label" htmlFor="title" style={{ fontSize: "12px" }}>
                            Title<sup style={{ color: "red" }}>*</sup>
                        </label>
                        <input
                            id="title"
                            style={{ fontSize: "13px" }}
                            type="text"
                            className="form-control form-control-sm "
                            name="title"
                            value={albumFormValue.title}
                            onChange={handleChange}
                        />
                        <p className="errormsg " style={{ marginBottom: "0" }}>{albumFormError.title}</p>
                    </div>

                    <div className="form-group">

                        <label className="form-label" htmlFor="description" style={{ fontSize: "12px" }}>
                            Description <sup style={{ color: "red" }}>*</sup>
                        </label>
                        <textarea
                            id="description"
                            style={{ fontSize: "13px" }}
                            type="text"
                            className="form-control form-control-sm"
                            name="description"
                            value={albumFormValue.description}
                            onChange={handleChange}
                        />
                        <p className="errormsg " style={{ marginBottom: "0" }}>{albumFormError.description}</p>

                    </div>


                </form>

                <div className="sec-dataTable">

                    {loading ? (
                        <Spinner />
                    ) : (
                        <DataTable
                            columns={columns}
                            data={studyAlbumList}
                            customStyles={customStyles}
                            pagination
                            fixedHeader
                            fixedHeaderScrollHeight="280px"
                            highlightOnHover
                            pointerOnHover
                            responsive
                            dense
                            striped
                            subHeader
                            subHeaderAlign="right"
                            subHeaderComponent={
                                <>
                                    <div className="upper-dataTbl">
                                        <div className="d-flex">
                                            <input
                                                placeholder="Search"
                                                ref={searchInput}
                                                type="text"
                                                className="form-control form-control-sm searchField"
                                                onChange={searchHandler}
                                            />
                                        </div>
                                    </div>
                                </>
                            }
                        />
                    )}
                </div>
            </div>
            <div className="FormFooter">
                <div className='checkedClose'>
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck2"
                        name="closeChecked"
                        onChange={handleOnChange}
                        checked={closeChecked}
                    />
                    <label class="form-check-label" for="exampleCheck2">
                        Exit after {!isEdit ? "submit" : "update"}
                    </label>
                </div>
                <div>

                    {!isEdit ?
                        <button className='btn btn-sm btn-cmpy ml-2' style={{ background: "green" }} onClick={formSubmit} >Submit</button> :
                        <button className='btn btn-sm btn-cmpy ml-2' style={{ background: "green" }}
                            onClick={editdata}
                        >Update</button>
                    }
                    <button className='btn btn-sm btn-cmpy ml-2' onClick={closeAlbumPopup}>Cancel</button>

                </div>
            </div>

            {deleteTopicPopup.show && (
                <DeleteTopicPop
                    handleDeleteTopicTrue={handleDeleteTopicTrue}
                    handleDeleteTopicFalse={handleDeleteTopicFalse}
                />
            )}
        </>

    )
}
