import "../../css/ImagePreviewPopUpinside.css"
import CloseIcon from "../../images/CloseIcon.svg";

export const showImgPreview = (data) => {


    return (
        <>
            <div className="modals-pop">
                <div className="overlay-pop"></div>
                <div className="imgPrv-popup-inner-pop">
                    <div className="ImgPreview-pop">
                        <img
                            className="imgPrv-close-icon"
                            src={CloseIcon}
                            alt="CloseIcon"
                            onClick={() => data.setTrigger(false)}
                        />

                        <img
                            id="prv-image-pop"
                            src={data.img}
                            draggable={false}
                            alt="uploaded-img"
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
