import React, { useContext, useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";
import { Fetchdata } from "../hooks/getData";
import AcademicHeader from "../AcademicHeader";

export default function StudentCoursePlan() {
  const { todayDate, AcademicYear, appURL } = useContext(SidebarContext);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const [loading, setLoading] = useState(true);

  const [selectSubject, setSelectSubject] = useState("");
  const [selectChapter, setSelectChapter] = useState("");
  const [selectTerm, setSelectTerm] = useState("");

  const [subjectList, setSubjectList] = useState([]);
  const [chapterList, setChapterList] = useState([]);

  const [chapterDetails, setChapterDetails] = useState({});

  const handleSubject = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectSubject(value);
    setSelectChapter("");
    setSelectTerm("");
  };

  const handleChapter = (e) => {
    const selectedChapterId = e.target.value;

    // Find the selected chapter in the list
    const selectedChapter = chapterList.find(
      (chapter) => chapter.ChapterID === parseInt(selectedChapterId)
    );

    // Update the state with the selected chapter's details
    setSelectChapter(selectedChapterId);
    setChapterDetails(selectedChapter);
    setSelectTerm("");
  };

  const handleTerm = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectTerm(value);
  };

  useEffect(() => {
    getsubjectList();
  }, []);

  const getsubjectList = () => {
    const params = {
      SchID: userinfo.SchID,
      ClassID: userinfo.ClassID.toString(),
      DFlag: "SUBJECT",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/ref-values`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setSubjectList(postResult);
      } else {
        setSubjectList([]);
      }
    });
  };

  //APi to get chapter List
  useEffect(() => {
    getChapterList();
  }, [selectSubject]);

  const getChapterList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      Flag: "S",
      ClassID: userinfo.ClassID.toString(),
      SubjectID: selectSubject,
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/course-chapter`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.AttenLst ? result.AttenLst : "";
        setChapterList(postResult);
      } else {
        setChapterList([]);
      }
    });
  };

  //API to hit Terminal list
  const [termList, setTermList] = useState([]);

  useEffect(() => {
    getTermList();
  }, []);

  const getTermList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      DFlag: "T",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";

        setTermList(postResult);
      } else {
        setTermList([]);
      }
    });
  };
  const [courseList, setCourseList] = useState([]);

  useEffect(() => {
    getCourse();
  }, [selectChapter]);

  const getCourse = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      TermID: selectTerm,
      ChapterID: selectChapter,
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/course-plan`,
    };
    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.AttenLst ? result.AttenLst : "";
        setLoading(false);
        setCourseList(postResult);
      } else {
        setLoading(false);
        setCourseList([]);
      }
    });
  };

  if (courseList.length > 0) {
    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
        />

        <>
          <div className="sec-dataTable ">
            <div className="row m-0">
              <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                <label className="list-label">Subject</label>
                <select
                  style={{ fontSize: "11px" }}
                  name="snotifiaction"
                  value={selectSubject}
                  onChange={handleSubject}
                  className="form-control form-control-sm"
                  aria-label="Default select example"
                >
                  <option
                    value=""
                    disabled
                    selected
                    style={{ fontSize: "11px" }}
                  >
                    Select Subject
                  </option>
                  {subjectList.map((item) => (
                    <option key={item.Subject} value={item.Subject}>
                      {item.SubjectName}
                    </option>
                  ))}
                </select>
                <i class="fas fa-angle-down  notificationpositon"></i>
              </div>

              <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                <label className="list-label">Chapter</label>
                <select
                  style={{ fontSize: "11px" }}
                  name="snotifiaction"
                  value={selectChapter}
                  onChange={handleChapter}
                  className="form-control form-control-sm"
                  aria-label="Default select example"
                >
                  <option
                    value=""
                    disabled
                    selected
                    style={{ fontSize: "11px" }}
                  >
                    Select Chapter
                  </option>
                  {chapterList.map((item) => (
                    <option key={item.ChapterID} value={item.ChapterID}>
                      {item.ChapterTitle}
                    </option>
                  ))}
                </select>
                <i class="fas fa-angle-down  notificationpositon"></i>
              </div>

              <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                <label className="list-label">Term</label>
                <select
                  style={{ fontSize: "11px" }}
                  name="snotifiaction"
                  value={selectTerm}
                  onChange={handleTerm}
                  className="form-control form-control-sm"
                  aria-label="Default select example"
                >
                  <option
                    value=""
                    disabled
                    selected
                    style={{ fontSize: "11px" }}
                  >
                    Select Term
                  </option>

                  {termList.map((item) => (
                    <option key={item.TermID} value={item.TermID}>
                      {item.TermName}
                    </option>
                  ))}
                </select>
                <i class="fas fa-angle-down  notificationpositon"></i>
              </div>
            </div>

            <div style={{ height: "427px", overflowY: "scroll" }}>
              <div className="chapter-credit-div">
                <p className="chapter-credit">
                  {" "}
                  Chapter Number:{" "}
                  <span className="chapter-credit-span">
                    {" "}
                    {chapterDetails.ChapterNo}{" "}
                  </span>{" "}
                </p>
                <p className="chapter-credit">
                  Total Credit Hour:{" "}
                  <span className="chapter-credit-span">
                    {" "}
                    {chapterDetails.ChapterDuration}{" "}
                  </span>{" "}
                </p>
              </div>
              <div>
                <p className="chapter-credit">
                  {" "}
                  Title:{" "}
                  <span className="chapter-credit-span">
                    {chapterDetails.ChapterTitle}{" "}
                  </span>{" "}
                </p>
              </div>

              <div>
                <p className="chapter-credit"> Learning Objective </p>
                <div className="learning-obj">
                  {chapterDetails.LearningObj.split(", ").map((item, index) => (
                    <div key={index}>
                      <div className="learning-list">
                        {" "}
                        {index + 1}. {item}{" "}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <hr />
              <div>
                {courseList.map((course, index) => (
                  <>
                    <div key={index}>
                      <h5>{course.Title}</h5>
                      <p
                        className="learning-list"
                        style={{ marginBottom: "6px" }}
                      >
                        {course.Description}
                      </p>
                      <p className="chapter-credit">
                        Duration:
                        <span className="chapter-credit-span">
                          &nbsp;{course.Duration}
                        </span>
                      </p>
                    </div>
                    <hr />
                  </>
                ))}
              </div>
            </div>
          </div>
        </>
      </>
    );
  } else {
    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
        />
        <>
          <div className="sec-dataTable ">
            <div className="row m-0">
              <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                <label className="list-label">Subject</label>
                <select
                  style={{ fontSize: "11px" }}
                  name="snotifiaction"
                  value={selectSubject}
                  onChange={handleSubject}
                  className="form-control form-control-sm"
                  aria-label="Default select example"
                >
                  <option
                    value=""
                    disabled
                    selected
                    style={{ fontSize: "11px" }}
                  >
                    Select Subject
                  </option>

                  {subjectList.map((item) => (
                    <option key={item.Subject} value={item.Subject}>
                      {item.SubjectName}
                    </option>
                  ))}
                </select>
                <i class="fas fa-angle-down  notificationpositon"></i>
              </div>

              <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                <label className="list-label">Chapter</label>
                <select
                  style={{ fontSize: "11px" }}
                  name="snotifiaction"
                  value={selectChapter}
                  onChange={handleChapter}
                  className="form-control form-control-sm"
                  aria-label="Default select example"
                >
                  <option
                    value=""
                    disabled
                    selected
                    style={{ fontSize: "11px" }}
                  >
                    Select Chapter
                  </option>
                  {chapterList.map((item) => (
                    <option key={item.ChapterID} value={item.ChapterID}>
                      {item.ChapterTitle}
                    </option>
                  ))}
                </select>
                <i class="fas fa-angle-down  notificationpositon"></i>
              </div>

              <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                <label className="list-label">Term</label>
                <select
                  style={{ fontSize: "11px" }}
                  name="snotifiaction"
                  value={selectTerm}
                  onChange={handleTerm}
                  className="form-control form-control-sm"
                  aria-label="Default select example"
                >
                  <option
                    value=""
                    disabled
                    selected
                    style={{ fontSize: "11px" }}
                  >
                    Select Term
                  </option>

                  {termList.map((item) => (
                    <option key={item.TermID} value={item.TermID}>
                      {item.TermName}
                    </option>
                  ))}
                </select>
                <i class="fas fa-angle-down  notificationpositon"></i>
              </div>
            </div>
            <div className="text-center mt-4">
              <p>There are no records to display.</p>
            </div>
          </div>
        </>
      </>
    );
  }
}
