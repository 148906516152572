import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";
import { showImgPreview } from "../hooks/ImagePreview";
import DeletePop from "./DeletePop";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { GetNepaliDate } from "../hooks/dateConvertor";
import AdmissionContext from "../contextfolder/AdmissionContext/AdmissionContext";
import ViewAdmission from "./ViewAdmission";
import AdmissionForm from "./AdmissionForm";
import AdmissionEdit from "./AdmissionEdit";
import AcademicHeader from "../AcademicHeader";

export default function AdmissionList() {
  const { todayDate, customStylesForImage, AcademicYear } =
    useContext(SidebarContext);
  const {
    loading,
    originalList,
    popup,
    togglePopup,
    setIsSubmit,
    setFormValue,
    setFormError,
    admissionValue,
    admissionList,
    setAdmissionList,
    deletePopup,
    handleDeleteTrue,
    handleDeleteFalse,
    handleDelete,
    toggleEditPopup,
    setEditIsSubmit,
    setCloseChecked,
    editPop,
    handleEdit,
    toggleViewPopup,
    handleView,
    viewAdmission,
    viewPop,
    setImage,
    setIsUploaded,
  } = useContext(AdmissionContext);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const searchInput = useRef("");

  const columns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "Class",
      minWidth: "70px",
      selector: (row) => row.Class,
    },
    {
      name: "Section",
      minWidth: "90px",
      selector: (row) => row.Section,
    },
    {
      name: "FullName",
      width: "150px",
      selector: (row) => row.FullName,
    },
    {
      name: "Mobile",
      width: "120px",
      selector: (row) => row.UserMobile,
    },
    {
      name: "Date Of Birth",
      width: "120px",
      selector: (row) => row.DateOfBirth,
      // selector: (row) => GetNepaliDate(row.DateOfBirth),
    },
    {
      name: "Gender",
      width: "100px",
      selector: (row) => row.Gender,
    },
    {
      name: "Email",
      minWidth: "190px",
      selector: (row) => row.Email,
    },
    {
      name: "Nationality",
      width: "100px",
      selector: (row) => row.Nationality,
    },
    {
      name: "Action",
      // grow: 0,
      center: true,
      width: "120px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <Tippy content="View">
                <i
                  className="bi bi-eye  viewspan--action"
                  onClick={() => handleView(row)}
                ></i>
              </Tippy>
              <Tippy content="Edit">
                <i
                  className="fa fa-pencil solvespan--action"
                  onClick={() => handleEdit(row)}
                ></i>
              </Tippy>
              <Tippy content="Delete">
                <i
                  className="fa fa-trash-o rejectspan--action"
                  onClick={() => handleDelete(row)}
                ></i>
              </Tippy>
            </div>
          </>
        );
      },
    },
  ];

  const closePopup = (e) => {
    e.preventDefault();
    togglePopup();
    setFormValue(admissionValue);
    setFormError({});
    setCloseChecked(false);
    setIsSubmit(false);
    setImage("");
    setIsUploaded(false);
  };

  const closeEditPop = (e) => {
    e.preventDefault();
    toggleEditPopup();
    setFormError({});
    setEditIsSubmit(false);
    setCloseChecked(false);
  };

  const addnew = (e) => {
    e.preventDefault();
    togglePopup();
    setIsSubmit(false);
    setFormValue(admissionValue);
  };

  const closeViewPopup = (e) => {
    e.preventDefault();
    toggleViewPopup();
  };

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["FullName"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setAdmissionList(srchResult);
      } else {
        setAdmissionList({});
      }
    } else {
      setAdmissionList(originalList);
    }
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
        <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">Admission</div>
            <div className="page-date">
              <div className="sec-content">
                <AcademicHeader
                  todayDate={todayDate}
                  AcademicYear={AcademicYear}
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <>
          <div className="sec-dataTable content-section main-content">
            <div className="upper-dataTbl">
              <div className="btn-addlnote">
                <button
                  type="button"
                  class="btn btn-sm"
                  style={{
                    background: "var(--MainColor)",
                    color: "white",
                  }}
                  onClick={addnew}
                >
                  Add New
                </button>
              </div>
            </div>
            <hr className="new-hr-new" />
            {loading ? (
              <Spinner />
            ) : (
              <DataTable
                columns={columns}
                data={admissionList}
                customStyles={customStylesForImage}
                pagination
                fixedHeader
                fixedHeaderScrollHeight="280px"
                highlightOnHover
                pointerOnHover
                responsive
                dense
                striped
                subHeader
                subHeaderComponent={
                  <>
                    <div
                      className="col-lg-2 offset-lg-10 offset-md-10 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
                      style={{ paddingRight: "1px", marginBottom: "10px" }}
                    >
                      <div className="d-flex">
                        <input
                          placeholder="Search"
                          ref={searchInput}
                          type="text"
                          className="form-control form-control-sm searchField"
                          onChange={searchHandler}
                        />
                      </div>
                    </div>
                  </>
                }
              />
            )}
          </div>
        </>
      </div>

      {popup && (
        <div className="modals">
          <div className="overlay"></div>

          <div className="notification-model-content">
            <div className="popUpHeader ">
              <div className="popUpHeaderText ">Admission</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closePopup}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="inside-modal">
              <AdmissionForm closePopup={closePopup} />
            </div>
          </div>
        </div>
      )}

      {editPop && (
        <div className="modals">
          <div className="overlay"></div>

          <div className="notification-model-content">
            <div className="popUpHeader ">
              <div className="popUpHeaderText ">Admission Edit</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closeEditPop}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="inside-modal">
              <AdmissionEdit closeEditPop={closeEditPop} />
            </div>
          </div>
        </div>
      )}

      {viewPop && (
        <div className="modals">
          <div className="overlay"></div>

          <div className="notification-model-content">
            <div className="popUpHeader ">
              <div className="popUpHeaderText ">View Admission</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closeViewPopup}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="inside-modal">
              <ViewAdmission
                viewAdmission={viewAdmission}
                closeViewPopup={closeViewPopup}
              />
            </div>
          </div>
        </div>
      )}

      {deletePopup.show && (
        <DeletePop
          handleDeleteTrue={handleDeleteTrue}
          handleDeleteFalse={handleDeleteFalse}
        />
      )}
    </>
  );
}
