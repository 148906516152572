import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import SidebarContext from "../sidebarcontext/SidebarContext";
import Spinner from "../loading/spinner";
import AccountContext from "../contextfolder/AccountContext/AccountContext";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";

import { convertToNep } from "../hooks/dateConvertor";
import Tippy from "@tippyjs/react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { formatNumber } from "../hooks/drCr";
import PdfActivate from "./PdfActivate";
import PdfDeactivate from "./PdfDeactivate";

export default function AccountPdf() {
  const { todayDate, customStyles, currentYear, newMonth, userinfo } =
    useContext(SidebarContext);

  const {
    loading,
    originalList,
    classLists,
    sectionLists,
    selectClasses,
    setSelectClasses,
    selectSections,
    setSelectSections,
    accountPdfList,
    setAccountPdfList,
    pdfSearch,
    selectYear,
    setSelectYear,
    selectMonth,
    setSelectMonth,
    isAllow,
    setIsAllow,

    handleActivate,
    handleInActivate,
    activatePopup,
    inActivatePopup,
    handleActivateTrue,
    handleActivateFalse,
    handleInActivateTrue,
    handleInActivateFalse,
  } = useContext(AccountContext);

  const handleYear = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectYear(value);
    setSelectMonth("");
  };

  const handleAllow = (e) => {
    const target = e.target;
    const value = target.value;
    setIsAllow(value);
  };
  const handleMonth = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectMonth(value);
  };

  let date = [];
  for (let i = 2075; i <= currentYear; i++) {
    date.push(i);
  }

  const handleClass = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectClasses(value);
    setSelectSections("");
  };

  const handleSection = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectSections(value);
  };

  const searchInput = useRef("");

  const columns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "Fullname",
      selector: (row) => row.FullName,
    },
    // {
    //   name: "Month",
    //   width: "160px",
    //   selector: (row) =>
    //     selectMonth === "1"
    //       ? "Baishakh"
    //       : selectMonth === "2"
    //       ? "Jestha"
    //       : selectMonth === "3"
    //       ? "Ashar"
    //       : selectMonth === "4"
    //       ? "Shrawan"
    //       : selectMonth === "5"
    //       ? "Bhadra"
    //       : selectMonth === "6"
    //       ? "Asoj"
    //       : selectMonth === "7"
    //       ? "Kartik"
    //       : selectMonth === "8"
    //       ? "Mangsir"
    //       : selectMonth === "9"
    //       ? "Poush"
    //       : selectMonth === "10"
    //       ? "Magh"
    //       : selectMonth === "11"
    //       ? "Falgun"
    //       : "Chaitra",
    // },

    // {
    //   name: "Status",
    //   // grow: 0,
    //   center: true,
    //   width: "120px",
    //   selector: (row) => {
    //     return (
    //       <>
    //         <div className="ln-verition d-flex">
    //           {row.IsAllow === "N" ? (
    //             <>
    //               <button
    //                 type="button"
    //                 class="badge badge-success"
    //                 onClick={() => handleActivate(row)}
    //               >
    //                 Allow
    //               </button>
    //             </>
    //           ) : (
    //             <>
    //               <button
    //                 type="button"
    //                 class="badge badge-danger"
    //                 onClick={() => handleInActivate(row)}
    //               >
    //                 Disallow
    //               </button>
    //             </>
    //           )}
    //         </div>
    //       </>
    //     );
    //   },
    // },

    {
      name: "Action",
      center: true,
      width: "70px",
      // selector: (row) => row.CV + "/" + row.Cover,
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <Tippy content="View">
                <a
                  className="viewspan--action px-2"
                  style={{ color: "#fff", width: "max-content" }}
                  href={row.AccountUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="bi bi-eye"></i>
                </a>
              </Tippy>
            </div>
          </>
        );
      },
    },
  ];

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = pdfSearch.filter((list) => {
        return list["FullName"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setAccountPdfList(srchResult);
      } else {
        setAccountPdfList({});
      }
    } else {
      setAccountPdfList(pdfSearch);
    }
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />

      <div className="sec-dataTable">
        <div className="upper-dataTbl"></div>
        <hr className="new-hr-new" />

        <div className="row" style={{ margin: "0" }}>
          <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
            <label className="list-label">Class</label>
            <select
              style={{ fontSize: "11px", marginBottom: "3px" }}
              name="snotifiaction"
              value={selectClasses}
              onChange={handleClass}
              className="form-control form-control-sm"
              aria-label="Default select example "
            >
              <option value="" disabled selected style={{ fontSize: "11px" }}>
                Select Class
              </option>
              {classLists.map((item) => (
                <option key={item.ClassID} value={item.ClassID}>
                  {item.ClassName}
                </option>
              ))}
            </select>
            <i class="fas fa-angle-down  notificationpositon"></i>
          </div>

          <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
            <label className="list-label">Section</label>
            <select
              style={{ fontSize: "11px", marginBottom: "3px" }}
              name="snotifiaction"
              value={selectSections}
              onChange={handleSection}
              className="form-control form-control-sm"
              aria-label="Default select example "
            >
              <option value="" disabled selected style={{ fontSize: "11px" }}>
                Select Section
              </option>
              {sectionLists.map((item) => (
                <option key={item.SectionID} value={item.SectionID}>
                  {item.SectionName}
                </option>
              ))}
            </select>
            <i class="fas fa-angle-down  notificationpositon"></i>
          </div>

          <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
            <label className="list-label">Select Year</label>
            <select
              style={{ fontSize: "11px", marginBottom: "3px" }}
              name="snotifiaction"
              value={selectYear}
              onChange={handleYear}
              className="form-control form-control-sm"
              aria-label="Default select example "
            >
              <option value="" disabled selected style={{ fontSize: "11px" }}>
                Select Year
              </option>
              {date
                .map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))
                .reverse()}
            </select>
            <i class="fas fa-angle-down  notificationpositon"></i>
          </div>

          <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
            <label className="list-label">Select Month</label>
            <select
              style={{ fontSize: "11px" }}
              name="status"
              className="form-control form-control-sm"
              aria-label="Default select example "
              value={selectMonth}
              onChange={handleMonth}
            >
              <option selected disabled value="" style={{ fontSize: "11px" }}>
                Select Month
              </option>
              <option value="1">Baishakh</option>
              <option value="2">Jestha</option>
              <option value="3">Asar</option>
              <option value="4">Shawan</option>
              <option value="5">Bhadra</option>
              <option value="6">Asoj</option>
              <option value="7">Kartik</option>
              <option value="8">Mangsir</option>
              <option value="9">Poush</option>
              <option value="10">Magh</option>
              <option value="11">Falgun</option>
              <option value="12">Chaitra</option>
            </select>
            <i class="fas fa-angle-down  notificationpositon"></i>
          </div>

          {/* <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
            <label className="list-label">Select Status</label>
            <select
              style={{ fontSize: "11px", marginBottom: "3px" }}
              name="snotifiaction"
              value={isAllow}
              onChange={handleAllow}
              className="form-control form-control-sm"
              aria-label="Default select example "
            >
              <option value="" disabled selected style={{ fontSize: "11px" }}>
                Select Status
              </option>
              <option value="-1">All</option>
              <option value="Y">Allow</option>
              <option value="N">Disallow</option>
            </select>
            <i class="fas fa-angle-down  notificationpositon"></i>
          </div> */}

          <div
            className="col-lg-2 offset-lg-2 offset-md-2 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
            style={{
              marginTop: "20px",
              paddingRight: "1px",
              marginBottom: "16px",
            }}
          >
            <div className="d-flex">
              <input
                placeholder="Search"
                ref={searchInput}
                type="text"
                className="form-control form-control-sm searchField"
                onChange={searchHandler}
              />
            </div>
          </div>
        </div>

        {loading ? (
          <Spinner />
        ) : (
          <DataTable
            columns={columns}
            data={accountPdfList}
            customStyles={customStyles}
            pagination
            fixedHeader
            fixedHeaderScrollHeight="280px"
            highlightOnHover
            pointerOnHover
            responsive
            dense
            striped
          />
        )}
      </div>

      {activatePopup.show && (
        <PdfActivate
          handleActivateTrue={handleActivateTrue}
          handleActivateFalse={handleActivateFalse}
        />
      )}
      {inActivatePopup.show && (
        <PdfDeactivate
          handleInActivateTrue={handleInActivateTrue}
          handleInActivateFalse={handleInActivateFalse}
        />
      )}
    </>
  );
}
