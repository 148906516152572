import React, { useContext, useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";
import { Fetchdata } from "../hooks/getData";
import AcademicHeader from "../AcademicHeader";
import Tippy from "@tippyjs/react";
import DataTable from "react-data-table-component";

export default function StudentSyllabusPdf() {
  const { todayDate, AcademicYear, appURL, customStyles } =
    useContext(SidebarContext);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const [loading, setLoading] = useState(true);

  const [classList, setClassList] = useState([]);
  const [selectClass, setSelectClass] = useState("");

  useEffect(() => {
    getClassList();
  }, []);

  const getClassList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.ClassID.toString(),
      DFlag: "C",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setClassList(postResult);
        setLoading(false);
      } else {
        setLoading(false);
        setClassList([]);
      }
    });
  };

  const handleClass = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectClass(value);
  };

  const [dataList, setdataList] = useState([]);

  useEffect(() => {
    getSyllabusPdf();
  }, [selectClass]);

  const getSyllabusPdf = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      ClassID: selectClass,
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/attach-syllabus`,
    };
    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.AttenLst ? result.AttenLst : "";
        setLoading(false);
        setdataList(postResult);
      } else {
        setLoading(false);
        setdataList([]);
      }
    });
  };

  const columns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "Subject",
      selector: (row) => row.Subject,
    },

    {
      name: "Action",
      center: true,
      width: "110px",
      // selector: (row) => row.CV + "/" + row.Cover,
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <Tippy content="View">
                <a
                  className="solvespan--action px-2"
                  style={{ color: "#fff", width: "max-content" }}
                  href={row.FileUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i
                    className="bi bi-eye  solvespan--action m-0"
                    style={{ paddingTop: "3px" }}
                  ></i>
                </a>
              </Tippy>
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />

      <>
        <div className="sec-dataTable ">
          {loading ? (
            <Spinner />
          ) : (
            <DataTable
              columns={columns}
              data={dataList}
              customStyles={customStyles}
              pagination
              fixedHeader
              fixedHeaderScrollHeight="280px"
              highlightOnHover
              pointerOnHover
              responsive
              dense
              striped
              subHeader
              subHeaderAlign="left"
              subHeaderComponent={
                <>
                  <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                    <label className="list-label">Class</label>
                    <select
                      style={{ fontSize: "11px" }}
                      name="snotifiaction"
                      value={selectClass}
                      onChange={handleClass}
                      className="form-control form-control-sm"
                      aria-label="Default control example "
                    >
                      <option
                        value=""
                        disabled
                        selected
                        style={{ fontSize: "11px" }}
                      >
                        Select Class
                      </option>

                      {classList.map((item) => (
                        <option key={item.ClassID} value={item.ClassID}>
                          {item.ClassName}
                        </option>
                      ))}
                    </select>
                    <i class="fas fa-angle-down  notificationpositon"></i>
                  </div>
                </>
              }
            />
          )}
        </div>
      </>
    </>
  );
}
