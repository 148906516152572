import React, { useContext, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import ClassRoutineContext from "../contextfolder/ClassRoutineContext/ClassRoutineContext";
import ExamRoutineContext from "../contextfolder/ExamRoutineContext/ExamRoutineContext";
import SidebarContext from "../sidebarcontext/SidebarContext";
import "../TabbedView/TabbedView.css";
import ClassRoutineList from "./ClassRoutine/ClassRoutineList";
import ExamRoutineList from "./ExamRoutine/ExamRoutineList";
import UploadRoutine from "../UploadRoutine/UploadRoutine";
import UploadRoutineContext from "../contextfolder/UploadRoutineContext/UploadRoutineContext";
import TeacherRoutinePDF from "./RoutinePDF/TeacherRoutinePDF";
import AcademicHeader from "../AcademicHeader";

export default function AdminRoutine() {
  const { AcademicYear, todayDate } = useContext(SidebarContext);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const {
    toggleEPopup,
    setEIsSubmit,
    setExamFormValue,
    examValue,
    setECloseChecked,
  } = useContext(ExamRoutineContext);

  const {
    togglePopup,
    setIsSubmit,
    setClassFormValue,
    classValue,
    setCloseChecked,
  } = useContext(ClassRoutineContext);

  const {
    toggleUploadPopup,
    setUploadIsSubmit,
    setUploadFormValue,
    uploadValue,
    setCloseCheckeds,
  } = useContext(UploadRoutineContext);

  const [activeTab, setActiveTab] = useState({
    tab1: true,
    tab2: false,
    tab3: false,
  });
  const handleTab1 = () => {
    setActiveTab({
      tab1: true,
      tab2: false,
      tab3: false,
    });
  };
  const handleTab2 = () => {
    setActiveTab({
      tab1: false,
      tab2: true,
      tab3: false,
    });
  };
  const handleTab3 = () => {
    setActiveTab({
      tab1: false,
      tab2: false,
      tab3: true,
    });
  };

  const addnewEvent = (e) => {
    e.preventDefault();
    toggleEPopup();
    setEIsSubmit(false);
    setExamFormValue(examValue);
    setECloseChecked(false);
  };

  const addnew = (e) => {
    e.preventDefault();
    togglePopup();
    setIsSubmit(false);
    setClassFormValue(classValue);
    setCloseChecked(false);
  };
  const addUpload = (e) => {
    e.preventDefault();
    toggleUploadPopup();
    setUploadIsSubmit(false);
    setUploadFormValue(uploadValue);
    setCloseCheckeds(false);
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
        <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">
              {activeTab.tab1
                ? "Class Routine"
                : activeTab.tab2
                ? "Exam Routine"
                : activeTab.tab3 && userinfo.UserType === "A"
                ? "Upload Routine"
                : "Routine PDF"}
            </div>
            <div className="page-date">
              <div className="sec-content">
                <AcademicHeader
                  todayDate={todayDate}
                  AcademicYear={AcademicYear}
                />
              </div>
            </div>
          </div>
        </div>
        <hr />

        <div className="tabview ">
          <div className="insidePopup--div">
            <ul>
              <li
                className={activeTab.tab1 === true ? "active" : ""}
                onClick={handleTab1}
              >
                <i className="fa fa-file-text-o mr-1 icon"></i>Class Routine{" "}
              </li>
              <li
                className={activeTab.tab2 === true ? "active" : ""}
                onClick={handleTab2}
              >
                <i className="fa fa-file-text-o mr-1 icon"></i>Exam Routine{" "}
              </li>
              {userinfo.UserType === "A" && (
                <li
                  className={activeTab.tab3 === true ? "active" : ""}
                  onClick={handleTab3}
                >
                  <i className="fa fa-file-text-o mr-1 icon"></i>Upload Routine{" "}
                </li>
              )}
              {userinfo.UserType === "T" && (
                <li
                  className={activeTab.tab3 === true ? "active" : ""}
                  onClick={handleTab3}
                >
                  <i className="fa fa-file-text-o mr-1 icon"></i>Routine PDF{" "}
                </li>
              )}
            </ul>
          </div>

          {activeTab.tab2 === true ? (
            <div>
              {userinfo.UserType !== "T" && (
                <div
                  className="upper-dataTbl add-btn"
                  style={{ float: "right" }}
                >
                  <div className="btn-addlnote">
                    <button
                      type="button"
                      class="btn btn-sm"
                      style={{
                        background: "var(--MainColor)",
                        color: "white",
                      }}
                      onClick={addnewEvent}
                    >
                      Add New
                    </button>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <></>
          )}
          {activeTab.tab1 === true ? (
            <div>
              {userinfo.UserType !== "T" && (
                <div
                  className="upper-dataTbl add-btn"
                  style={{ float: "right" }}
                >
                  <div className="btn-addlnote">
                    <button
                      type="button"
                      class="btn btn-sm"
                      style={{
                        background: "var(--MainColor)",
                        color: "white",
                      }}
                      onClick={addnew}
                    >
                      Add New
                    </button>
                  </div>
                </div>
              )}{" "}
            </div>
          ) : (
            <></>
          )}

          {activeTab.tab3 === true ? (
            <div>
              {userinfo.UserType !== "T" && (
                <div
                  className="upper-dataTbl add-btn"
                  style={{ float: "right" }}
                >
                  <div className="btn-addlnote">
                    <button
                      type="button"
                      class="btn btn-sm"
                      style={{
                        background: "var(--MainColor)",
                        color: "white",
                      }}
                      onClick={addUpload}
                    >
                      Add New
                    </button>
                  </div>
                </div>
              )}{" "}
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className="content-section main-content">
          {activeTab.tab1 && <ClassRoutineList />}
          {activeTab.tab2 && <ExamRoutineList />}
          {activeTab.tab3 && (
            <>
              {userinfo.UserType === "A" ? (
                <UploadRoutine />
              ) : userinfo.UserType === "T" ? (
                <TeacherRoutinePDF />
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
