import React, { useContext, useState, useEffect } from 'react'
import SuggestionContext from '../contextfolder/SuggestionContext/SuggestionContext';

export default function StudentSuggestionForm({ closePopup }) {

    const context = useContext(SuggestionContext)

    const { toggleStdModal, stdSuggestionFormError, setStdSuggestionFormError, stdIsSubmit, setStdIsSubmit, stdSuggestionValue, stdSuggestionFormValue, setStdSuggestionFormValue, stdCloseChecked, setStdCloseChecked, stdTopicList,stdfetchdata} = context;


    const handleOnChange = () => {
        setStdCloseChecked(!stdCloseChecked);
    };
    


    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;
        setStdSuggestionFormValue({ ...stdSuggestionFormValue, [name]: value });
    };


    const formSubmit = (e) => {
        e.preventDefault();
        setStdSuggestionFormError(validate(stdSuggestionFormValue));
        setStdIsSubmit(true);
    }

    useEffect(() => {
        if (Object.keys(stdSuggestionFormError).length === 0 && stdIsSubmit) {
            stdfetchdata(stdSuggestionFormValue)
            if (stdCloseChecked === true) {
                toggleStdModal();
            }
            setStdSuggestionFormValue(stdSuggestionValue)
            setStdIsSubmit(false);
        }

    }, [stdSuggestionFormError]);

    const validate = (values) => {

        const errors = {};
        if (!values.title) {
            errors.title = "Required";
        }
        if (!values.topic) {
            errors.topic = "Required";
        }
        if (!values.description) {
            errors.description = "Required";
        }

        return errors;
    };



    return (
        <>

            <div className="container-fluid basicform" >
                <form >
                    <div className="form-group">
                        <label className="form-label" htmlFor="topic" style={{ fontSize: "12px" }}>
                            Topic<sup style={{ color: "red" }}>*</sup>
                        </label>
                        <select id="topic" name="topic"
                            onChange={handleChange}
                            value={stdSuggestionFormValue.topic} className="form-select form-select-sm mb-1" style={{ fontSize: '12px' }}>
                            <option value="" selected disabled>Select Option</option>
                            {stdTopicList.map((item, i) => <option key={i} value={item.TopicID}>{item.Topic}</option>)}
                        </select>
                        <p className="errormsg " style={{ marginBottom: "0" }}>{stdSuggestionFormError.topic}</p>
                    </div>

                    <div className="form-group">
                        <label className="form-label" htmlFor="title" style={{ fontSize: "12px" }}>
                            Title<sup style={{ color: "red" }}>*</sup>
                        </label>
                        <input
                            id="title"
                            style={{ fontSize: "13px" }}
                            type="text"
                            className="form-control form-control-sm "
                            name="title"
                            value={stdSuggestionFormValue.title}
                            onChange={handleChange}
                        />
                        <p className="errormsg " style={{ marginBottom: "0" }}>{stdSuggestionFormError.title}</p>
                    </div>


                    <div className="form-group">
                        <label className="form-label" htmlFor="description" style={{ fontSize: "12px" }}>
                            Description<sup style={{ color: "red" }}>*</sup>
                        </label>
                        <input
                            id="description"
                            style={{ fontSize: "13px" }}
                            type="text"
                            className="form-control form-control-sm "
                            name="description"
                            value={stdSuggestionFormValue.description}
                            onChange={handleChange}
                        />
                        <p className="errormsg " style={{ marginBottom: "0" }}>{stdSuggestionFormError.description}</p>
                    </div>
                </form>


            </div>
            <div className="FormFooter">
                <div className='checkedClose'>
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck2"
                        name="closeChecked"
                        onChange={handleOnChange}
                        checked={stdCloseChecked}
                    />
                    <label class="form-check-label" for="exampleCheck2">
                        Exit after submit
                    </label>
                </div>
                <div>
                    <button className='btn btn-sm btn-cmpy ml-2' style={{ background: "green" }} onClick={formSubmit} >Submit</button>
                    <button className='btn btn-sm btn-cmpy ml-2' onClick={closePopup}>Cancel</button>
                </div>
            </div>

        </>

    )
}
