import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import SidebarContext from "../sidebarcontext/SidebarContext";
import UploadRoutineContext from "../contextfolder/UploadRoutineContext/UploadRoutineContext";
import Spinner from "../loading/spinner";
import UploadRoutineForm from "./UploadRoutineForm";
import DeletePop from "./DeletePop";

export default function UploadRoutine() {
  const { today, customStyles, hideAction } = useContext(SidebarContext);
  const {
    loading,
    originalList,
    setUploadRoutineList,
    uploadPopup,
    toggleUploadPopup,
    setUploadIsSubmit,
    setUploadFormValue,
    setUploadFormError,
    uploadValue,
    uploadRoutineList,
    handleDelete,
    setCloseCheckeds,
    reportOption,
    setReportOption,
    setPdfBase64,
    handleDeleteTrue,
    handleDeleteFalse,
    deletePopup,
  } = useContext(UploadRoutineContext);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const handleReport = (e) => {
    const target = e.target;
    const value = target.value;
    setReportOption(value);
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const searchInput = useRef("");

  const columns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "Class",
      width: "400px",
      selector: (row) => row.Class,
    },
    reportOption === "C"
      ? {
          name: "Section",
          selector: (row) => row.Section,
        }
      : {
          name: "Term",
          selector: (row) => row.Term,
        },

    {
      name: "Action",
      // grow: 0,
      center: true,
      width: "100px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <Tippy content="View">
                <i
                  className="bi bi-eye  solvespan--action"
                  style={{ paddingTop: "3px" }}
                  onClick={() => openInNewTab(row.Attachment)}
                ></i>
              </Tippy>
              <Tippy content="Delete">
                <i
                  className="fa fa-trash-o rejectspan--action"
                  onClick={() => handleDelete(row.RoutineID)}
                ></i>
              </Tippy>
            </div>
          </>
        );
      },
    },
  ];

  const closePopup = (e) => {
    e.preventDefault();
    toggleUploadPopup();
    setUploadFormValue(uploadValue);
    setUploadFormError({});
    setUploadIsSubmit(false);
    setCloseCheckeds(false);
    setPdfBase64("");
  };

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return (
          list["Class"].toLowerCase().includes(srchQuery) ||
          list["Term"].toLowerCase().includes(srchQuery)
        );
      });

      if (srchResult) {
        setUploadRoutineList(srchResult);
      } else {
        setUploadRoutineList({});
      }
    } else {
      setUploadRoutineList(originalList);
    }
  };

  /* This is for export csv */

  const classExcell = uploadRoutineList.map((d, i) => ({
    "S.N.": i + 1,
    Class: d.Class,
    Section: d.Section,
  }));

  const examExcell = uploadRoutineList.map((d, i) => ({
    "S.N.": i + 1,
    Class: d.Class,
    Term: d.Term,
  }));

  const toExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(
        reportOption === "C" ? classExcell : examExcell
      );
      var wscols = [{ wch: 5 }, { wch: 20 }, { wch: 20 }];
      worksheet["!cols"] = wscols;
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "Upload_Routine");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_" + "export" + EXCEL_EXTENSION
        );
      }
    });
  };

  //end

  //This is to export pdf

  const dataWithIndex = uploadRoutineList.map((d, i) => ({
    ...d,
    Index: i + 1,
    Class: d.Class,
    Section: d.Section,
  }));

  const cols = [
    { header: "S.N", field: "Index" },
    { header: "Class", field: "Class" },
    { header: "Section", field: "Section" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const dataWithIndexE = uploadRoutineList.map((d, i) => ({
    ...d,
    Index: i + 1,
    Class: d.Class,
    Term: d.Term,
  }));

  const colsE = [
    { header: "S.N", field: "Index" },
    { header: "Class", field: "Class" },
    { header: "Term", field: "Term" },
  ];

  const exportColumnsE = colsE.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const toPdf = () => {
    const doc = new jsPDF("p", "pt", "a4", true);

    var midPage = doc.internal.pageSize.getWidth() / 2;

    // doc.addImage(`${userinfo.SchPhoto}`, "JPG", 40, 40, 70, 60);

    doc.setFontSize(18);
    doc.text(`${userinfo.SchName}`, midPage, 50, null, null, "center");

    doc.setFontSize(15);
    doc.text(`${userinfo.SchAddress}`, midPage, 68, null, null, "center");

    doc.setFontSize(10);
    doc.text(
      `Tel:${userinfo.Phone}, E-mail:${userinfo.Email}`,
      midPage,
      85,
      null,
      null,
      "center"
    );

    doc.autoTable({
      startY: 100,
      theme: "grid",
      columns: reportOption === "C" ? exportColumns : exportColumnsE,
      body: reportOption === "C" ? dataWithIndex : dataWithIndexE,
    });
    doc.save("Upload_Routine.pdf");
  };
  //end

  return (
    <>
      <>
        <div className="sec-dataTable">
          <div className="upper-dataTbl">
            <Tippy content="PDF">
              <button className="exportButtons ml-1" onClick={toPdf}>
                <i className="bi bi-file-earmark-pdf-fill exportButtonIconsPdf"></i>
              </button>
            </Tippy>
            <Tippy content="Excel">
              <button className="exportButtons" onClick={toExcel}>
                <i className="bi bi-file-earmark-excel-fill exportButtonIconsExcel"></i>
              </button>
            </Tippy>
          </div>
          <hr className="new-hr-new" />

          {loading ? (
            <Spinner />
          ) : (
            <DataTable
              columns={columns}
              data={uploadRoutineList}
              customStyles={customStyles}
              pagination
              fixedHeader
              fixedHeaderScrollHeight="280px"
              highlightOnHover
              pointerOnHover
              responsive
              dense
              striped
              subHeader
              subHeaderAlign="left"
              subHeaderComponent={
                <>
                  <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                    <label className="list-label">Select Option</label>
                    <select
                      style={{ fontSize: "11px" }}
                      name="status"
                      className="form-control form-control-sm"
                      aria-label="Default select example "
                      value={reportOption}
                      onChange={handleReport}
                    >
                      <option
                        selected
                        disabled
                        value=""
                        style={{ fontSize: "11px" }}
                      >
                        Select Option
                      </option>

                      <option value="C">Class Routine</option>
                      <option value="E">Exam Routine</option>
                    </select>
                    <i class="fas fa-angle-down  notificationpositon"></i>
                  </div>

                  <div
                    className="col-lg-2 offset-lg-8 offset-md-8 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
                    style={{
                      marginTop: "15px",
                      paddingRight: "1px",
                      marginBottom: "10px",
                    }}
                  >
                    <div className="d-flex">
                      <input
                        placeholder="Search"
                        ref={searchInput}
                        type="text"
                        className="form-control form-control-sm searchField"
                        onChange={searchHandler}
                      />
                    </div>
                  </div>
                </>
              }
            />
          )}
        </div>
      </>

      {uploadPopup && (
        <div className="modals">
          <div className="overlay"></div>

          <div className="notification-model-content">
            <div className="popUpHeader ">
              <div className="popUpHeaderText ">Upload Routine</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closePopup}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="inside-modal">
              <UploadRoutineForm closePopup={closePopup} />
            </div>
          </div>
        </div>
      )}

      {deletePopup.show && (
        <DeletePop
          handleDeleteTrue={handleDeleteTrue}
          handleDeleteFalse={handleDeleteFalse}
        />
      )}
    </>
  );
}
