import React, { useEffect, useState } from "react";
import NepaliDate from "nepali-date-converter";
import SidebarContext from "./SidebarContext";
import { defaultThemes } from "react-data-table-component";
import { Fetchdata } from "../hooks/getData";
import { toast } from "react-toastify";
import { GetEnglishDate, GetNepaliDate } from "../hooks/dateConvertor";

function SidebarState(props) {
  const appURL = process.env.REACT_APP_URL;
  const jobURL = process.env.REACT_APP_JOB;
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const AcademicYear = userinfo.AcademicYear;
  const SchExpiry = userinfo.SchExpiry || "";
  const [loading, setLoading] = useState(true);

  const [menutoggle, setMenutoggle] = useState(false);
  const [logoutDropdownactive, setlogoutDropdownactive] = useState(false);
  const [alert, setAlert] = useState({
    fade: "fade-default",
    msg: "",
    type: "",
  });
  const [logoutdata, setLogout] = useState(false);
  const [resetPassword, setresetPassword] = useState(false);
  const [msg, setMsg] = useState({});

  const [fiscalYear, setFiscalYear] = useState([]);
  const [todayDate] = useState(getNepaliDate());

  function getNepaliDate() {
    let date = new NepaliDate().getBS();
    let tm = date.month + 1 < 10 ? `0${date.month + 1}` : `${date.month + 1}`;
    let td = date.date < 10 ? `0${date.date}` : `${date.date}`;
    return `${date.year}/${tm}/${td}`;
  }

  function getCurrentYear() {
    let date = new NepaliDate().getBS();
    return `${date.year}`;
  }

  const [currentYear] = useState(getCurrentYear());

  // Months back
  // var months = new Date();

  // months.setMonth(months.getMonth() - 3);
  // const monthsBack = months.toLocaleDateString();

  // const newMonth = GetNepaliDate(monthsBack);

  //Months back ends

  const months = new Date();
  const formattedDate = new Date(
    months.getFullYear(),
    months.getMonth() - 3,
    months.getDate()
  );
  const monthsBack = `${formattedDate.getFullYear()}/${
    formattedDate.getMonth() + 1
  }/${formattedDate.getDate()}`;

  const newMonth = GetNepaliDate(monthsBack);

  // To get current day

  const d = new Date();
  let today = d.getDay() + 1;

  // To get current English Date

  const getEnglishDate = GetEnglishDate(todayDate);

  const currentDate = new Date();
  const expiryDate = SchExpiry ? new Date(SchExpiry) : new Date();
  const difference = expiryDate.getTime() - currentDate.getTime();
  const remainingDays = Math.ceil(difference / (24 * 60 * 60 * 1000));

  const nepaliMonths = [
    "Baisakh",
    "Jestha",
    "Ashad",
    "Shrawan",
    "Bhadra",
    "Ashwin",
    "Kartik",
    "Mangsir",
    "Poush",
    "Magh",
    "Falgun",
    "Chaitra",
  ];
  const convertToNumber = (value) => {
    // Using parseInt() to convert string to number
    return parseInt(value, 10); // Second argument (10) specifies base 10 for decimal numbers
    // Alternatively, you can use the unary plus (+) operator:
    // return +value;
  };

  let year = currentDate.getFullYear();
  let month = String(currentDate.getMonth() + 1).padStart(2, "0");
  let day = String(currentDate.getDate()).padStart(2, "0");

  const ExpiryDate = `${year}/${month}/${day}`;

  const remainingDate = SchExpiry ? GetNepaliDate(SchExpiry) : ExpiryDate;
  const remainingSplit = remainingDate.split("/");
  const remainingDay = remainingSplit[2];
  const remainingMonth = remainingSplit[1];
  const remainingYear = remainingSplit[0];
  const formattedRemainingDate =
    remainingDay +
    " " +
    nepaliMonths[convertToNumber(remainingMonth - 1)] +
    "," +
    " " +
    remainingYear;

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:last-of-type": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
        borderLeftStyle: "solid",
        borderLeftWidth: "1px",
        borderLeftColor: defaultThemes.default.divider.default,
      },
    },
    cells: {
      style: {
        "&:last-of-type": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
        borderLeftStyle: "solid",
        borderLeftWidth: "1px",
        borderLeftColor: defaultThemes.default.divider.default,
      },
    },
    rows: {
      style: {
        height: "17px",
      },
      highlightOnHoverStyle: {
        backgroundColor: "#f2f0f0",
        borderBottomColor: "#FFFFFF",
        outline: "1px solid #FFFFFF",
        fontWeight: "650",
      },
    },
  };

  const customStylesForImage = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:last-of-type": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
        borderLeftStyle: "solid",
        borderLeftWidth: "1px",
        borderLeftColor: defaultThemes.default.divider.default,
      },
    },
    cells: {
      style: {
        "&:last-of-type": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
        borderLeftStyle: "solid",
        borderLeftWidth: "1px",
        borderLeftColor: defaultThemes.default.divider.default,
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "#f2f0f0",
        borderBottomColor: "#FFFFFF",
        outline: "1px solid #FFFFFF",
        fontWeight: "650",
      },
    },
  };

  //API to list class reference values

  const [selectClass, setSelectClass] = useState("");
  const [classSearch, setClassSearch] = useState("");
  const [sectionSearch, setSectionSearch] = useState("");
  const [selectSection, setSelectSection] = useState("");
  const [termSearch, setTermSearch] = useState("");
  const [subjectSearch, setSubjectSearch] = useState("");

  const [classList, setClassList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [termList, setTermList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);

  useEffect(() => {
    getClassList();
  }, []);

  const getClassList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      DFlag: "C",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setLoading(false);
        setClassList(postResult);
        setClassSearch(postResult);
      } else {
        setClassList([]);
      }
    });
  };

  useEffect(() => {
    getSectionList();
  }, [selectClass]);

  const getSectionList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      ClassID: selectClass,
      DFlag: "S",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";

        setSectionList(postResult);
        setSectionSearch(postResult);
      } else {
        setSectionList([]);
      }
    });
  };

  useEffect(() => {
    getTermList();
  }, []);

  const getTermList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      DFlag: "T",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";

        setLoading(false);
        setTermList(postResult);
        setTermSearch(postResult);
      } else {
        setTermList([]);
      }
    });
  };

  useEffect(() => {
    getSubjectList();
  }, [selectClass]);

  const getSubjectList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      ClassID: selectClass,
      DFlag: "SB",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";

        setSubjectList(postResult);
        setSubjectSearch(postResult);
      } else {
        setSubjectList([]);
      }
    });
  };

  //State to remove action list when usertype === "T"

  const [hideAction, setHideAction] = useState(false);

  useEffect(() => {
    if (userinfo.UserType === "T") {
      setHideAction(!hideAction);
    }
  }, []);

  //State to remove action list when usertype === "T" emds

  // States for About School

  const [isUploaded, setIsUploaded] = useState(false);
  const [typeFile, setTypeFile] = useState("");
  const [image, setImage] = useState("");

  const aboutValue = {
    schoolName: "",
    address: "",
    contact: "",
    person: "",
    about: "",
    message: "",
    latitude: "0",
    longitude: "0",
    website: "",
    facebook: "",
    twitter: "",
    youtube: "",
  };
  const [aboutFormValue, setAboutFormValue] = useState(aboutValue);
  const [aboutFormError, setAboutFormError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  //API to edit school about
  const editdata = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "U",
      Address: aboutFormValue.address,
      Contact: aboutFormValue.contact,
      ContactPerson: aboutFormValue.person,
      About: aboutFormValue.about,
      Message: aboutFormValue.message,
      Latitude: aboutFormValue.latitude,
      Longitude: aboutFormValue.longitude,
      Website: aboutFormValue.website,
      Fb: aboutFormValue.facebook,
      Twitter: aboutFormValue.twitter,
      Youtube: aboutFormValue.youtube,
      Banner: image === null ? "" : image.split(",")[1],
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/school`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };

  useEffect(() => {
    setAboutFormValue({
      schoolName: userinfo.SchName,
      address: userinfo.SchAddress,
      contact: userinfo.Phone,
      person: userinfo.SchContactPerson,
      about: userinfo.AboutUs,
      message: userinfo.Msg,
      latitude: userinfo.LocLat,
      longitude: userinfo.LocLong,
      website: userinfo.SchWebsite,
      facebook: userinfo.SchFb,
      twitter: userinfo.SchTwitter,
      youtube: userinfo.SchYt,
    });
    setImage(userinfo.SchPhoto);
  }, []);

  const [isActive, setIsActive] = useState(true);
  return (
    <SidebarContext.Provider
      value={{
        menutoggle,
        setMenutoggle,
        logoutDropdownactive,
        setlogoutDropdownactive,
        alert,
        setAlert,
        logoutdata,
        setLogout,
        resetPassword,
        setresetPassword,
        msg,
        setMsg,
        fiscalYear,
        setFiscalYear,
        todayDate,
        customStyles,
        customStylesForImage,
        appURL,
        classList,
        setClassList,
        sectionList,
        setSectionList,
        selectSection,
        setSelectSection,
        selectClass,
        setSelectClass,
        classSearch,
        setClassSearch,
        loading,
        sectionSearch,
        setSectionSearch,
        setTermList,
        termList,
        termSearch,
        setTermSearch,
        subjectSearch,
        setSubjectSearch,
        subjectList,
        setSubjectList,
        isUploaded,
        setIsUploaded,
        typeFile,
        setTypeFile,
        image,
        setImage,
        aboutFormValue,
        setAboutFormValue,
        isSubmit,
        setIsSubmit,
        editdata,
        AcademicYear,
        aboutFormError,
        setAboutFormError,
        hideAction,
        currentYear,
        today,
        monthsBack,
        userinfo,
        getEnglishDate,
        remainingDays,

        newMonth,
        isActive,
        setIsActive,
        jobURL,
        formattedRemainingDate,
      }}
    >
      {props.children}
    </SidebarContext.Provider>
  );
}

export default SidebarState;
