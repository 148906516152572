import React, { useContext, useState, useEffect } from 'react'
import { useRef } from 'react';
import DataTable from 'react-data-table-component';
import SuggestionContext from '../contextfolder/SuggestionContext/SuggestionContext';
import Spinner from '../loading/spinner';
import SidebarContext from '../sidebarcontext/SidebarContext';
import DeleteTopicPop from './DeleteTopicPop';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';


export default function SuggestionForm({ closePopup }) {

    const context = useContext(SuggestionContext)

    const { suggestionFormError, setSuggestionFormError, isSubmit, setIsSubmit, suggestionValue, suggestionFormValue, setSuggestionFormValue, suggestionTopicList, fetchdata, setTitleId, editdata, suggestionTopicSearch, setSuggestionTopicList, loading, deleteTopicPopup, handleDeleteTopicTrue, handleDeleteTopicFalse, handleTopicDelete, closeChecked, setCloseChecked, toggleModal } = context;

    const { customStyles } = useContext(SidebarContext)

    const [isEdit, setIsEdit] = useState(false);

    const handleOnChange = () => {
        setCloseChecked(!closeChecked);
    };


    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;
        setSuggestionFormValue({ ...suggestionFormValue, [name]: value });
    };

    const formSubmit = (e) => {
        e.preventDefault();
        setSuggestionFormError(validate(suggestionFormValue));
        setIsSubmit(true);
    }

    useEffect(() => {
        if (Object.keys(suggestionFormError).length === 0 && isSubmit) {
            fetchdata(suggestionFormValue)
            if (closeChecked === true) {
                toggleModal();
            }
            setSuggestionFormValue(suggestionValue)
            setIsSubmit(false);

        }

    }, [suggestionFormError]);





    const validate = (values) => {
        const errors = {};
        if (!values.topic) {
            errors.topic = "Required";
        }
        return errors;
    };



    function selectUser(datas) {

        setSuggestionFormValue({
            topic: datas.Topic,
        });
        setTitleId(datas.TopicID);
        setIsEdit(true);
    }



    const columns = [
        {
            name: "S.N.",
            grow: 0,
            center: true,
            cell: (row, index) => index + 1,
        },

        {
            name: "Topic",
            center: true,
            // grow: 0,
            selector: (row) => row.Topic,
        },

        {
            name: "No. of Suggestion",
            center: true,
            // grow: 0,
            selector: (row) => row.NoOfSuggestion,
        },
        {
            name: "Action",
            center: true,
            selector: (row) => {
                return (
                    <>
                        <>
                            <div className="ln-verition d-flex">
                                <Tippy content="Edit">
                                    <i
                                        className="fa fa-pencil solvespan--action"
                                        onClick={() => selectUser(row)}
                                    >
                                    </i>
                                </Tippy>
                                <Tippy content="Delete">
                                    <i
                                        className="fa fa-trash-o rejectspan--action"
                                        onClick={() => handleTopicDelete(row.TopicID)}
                                    >
                                    </i>
                                </Tippy>
                            </div>
                        </>

                    </>
                );
            },
        },

    ];

    const searchInput = useRef("");
    const searchHandler = (e) => {
        e.preventDefault();

        const srchQuery = searchInput.current.value.toLowerCase();
        if (srchQuery) {

            let srchResult = suggestionTopicSearch.filter((list) => {
                return list["Topic"].toLowerCase().includes(srchQuery)
            });

            if (srchResult) {

                setSuggestionTopicList(srchResult);
            } else {
                setSuggestionTopicList({});
            }
        } else {
            setSuggestionTopicList(suggestionTopicSearch);
        }
    };


    return (
        <>

            <div className="container-fluid basicform" >

                <form >

                    <div className="form-group">
                        <label className="form-label" htmlFor="topic" style={{ fontSize: "12px" }}>
                            Topic<sup style={{ color: "red" }}>*</sup>
                        </label>
                        <input
                            id="topic"
                            style={{ fontSize: "13px" }}
                            type="text"
                            className="form-control form-control-sm "
                            name="topic"
                            value={suggestionFormValue.topic}
                            onChange={handleChange}
                        />
                        <p className="errormsg " style={{ marginBottom: "0" }}>{suggestionFormError.topic}</p>
                    </div>
                </form>
                <div className="sec-dataTable">

                    {loading ? (
                        <Spinner />
                    ) : (
                        <DataTable
                            columns={columns}
                            data={suggestionTopicList}
                            customStyles={customStyles}
                            pagination
                            fixedHeader
                            fixedHeaderScrollHeight="280px"
                            highlightOnHover
                            pointerOnHover
                            responsive
                            dense
                            striped
                            subHeader
                            subHeaderComponent={
                                <>
                                    <div className="upper-dataTbl">
                                        <div className="d-flex">
                                            <input
                                                placeholder="Search"
                                                ref={searchInput}
                                                type="text"
                                                className="form-control form-control-sm searchField"
                                                onChange={searchHandler}
                                            />
                                        </div>
                                    </div>
                                </>
                            }
                        />
                    )}
                </div>
            </div>
            <div className="FormFooter">
                <div className='checkedClose'>
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck2"
                        name="closeChecked"
                        onChange={handleOnChange}
                        checked={closeChecked}
                    />
                    <label class="form-check-label" for="exampleCheck2">
                        Exit after {!isEdit ? "submit" : "update"}
                    </label>
                </div>
                <div>
                    {!isEdit ?
                        <button className='btn btn-sm btn-cmpy ml-2' style={{ background: "green" }} onClick={formSubmit} >Submit</button> :
                        <button className='btn btn-sm btn-cmpy ml-2' style={{ background: "green" }} onClick={editdata} >Update</button>
                    }
                    <button className='btn btn-sm btn-cmpy ml-2' onClick={closePopup}>Cancel</button>
                </div>
            </div>

            {deleteTopicPopup.show && (
                <DeleteTopicPop
                    handleDeleteTopicTrue={handleDeleteTopicTrue}
                    handleDeleteTopicFalse={handleDeleteTopicFalse}
                />
            )}
        </>

    )
}
