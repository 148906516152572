import React from 'react'
import NepaliDate from 'nepali-date-converter';
import { useEffect } from 'react';
import { useState } from 'react';

const ViewBlog = ({ closeViewPopup, bloginfo
}) => {


    const [nepaliDate, setNepaliDate] = useState(null);

    useEffect(() => {
        // Convert Gregorian date to Nepali date
        if (bloginfo.BlogDate) {
            const gregorianDate = new Date(bloginfo.BlogDate);
            const convertedDate = new NepaliDate(gregorianDate);
            setNepaliDate(`${convertedDate.format('YYYY/MM/DD')}`);
        }
    }, [bloginfo.BlogDate]);







    return (
        <>
            <div className="container-fluid basicform" >
                {bloginfo &&
                    <>
                        <div style={{ padding: "0px 5px" }} >
                            <h4 style={{ fontSize: "17px", fontWeight: "bold", margin: "0px" }}> {bloginfo.Title} </h4>
                            <p style={{ fontSize: "12px", color: "#212529" }}>{bloginfo.CategoryName === null ? "Not Mentioned" : bloginfo.CategoryName}</p>
                        </div>


                        <div className="viewStaffContentLogoBlog">
                            <div className="viewStaffImgBlog">
                                <img src={bloginfo.Image} alt=""
                                // onClick={() => { setImagePre("##"); setImgPrv(true) }} 
                                />
                            </div>
                        </div>
                        <div className="profile-info-blog">
                            <div className="profilecontentLogoBlog tl">
                                <div className="profilemgmtImgBlog tl">
                                    <img
                                        src={bloginfo.CreatedUserImage}
                                        alt="dp"
                                        style={{ cursor: "pointer" }}
                                    />
                                </div>
                            </div>
                            <div>
                                <h6 style={{ paddingLeft: "10px", marginTop: "5px", fontWeight: "bold", textAlign: "left", fontSize: "13px", marginBottom: "0px" }}>{bloginfo.CreatedUser}</h6>
                                {nepaliDate &&
                                    <p style={{ textAlign: "left", paddingLeft: "10px" }}>
                                        {nepaliDate}
                                    </p>
                                }
                            </div>
                        </div>

                        <div style={{ marginTop: "10px" }} >
                            <p dangerouslySetInnerHTML={{ __html: bloginfo.Post }} />
                        </div>
                    </>
                }



            </div>

            <div className="FormFooter">
                <div>

                </div>
                <div>
                    {/* <button className='btn btn-sm btn-cmpy ml-2' style={{ background: "green" }} onClick={formSubmit} >Submit</button> */}
                    <button className='btn btn-sm btn-cmpy ml-2' onClick={closeViewPopup}>Cancel</button>
                </div>
            </div>
        </>
    )
}

export default ViewBlog