import React, { useContext, useState } from "react";
import AcademicPop from "./AcademicPop";
import SidebarContext from "./sidebarcontext/SidebarContext";
import { FetchNewdata } from "./hooks/getData";
import { useEffect } from "react";

const AcademicHeader = ({ todayDate, AcademicYear }) => {
  const { appURL } = useContext(SidebarContext);
  const [popup, setPopup] = useState(false);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const handleClose = () => {
    setPopup(false);
  };

  const [AcademicYearList, setAcademicYearList] = useState([]);

  useEffect(() => {
    getAcademicYearList();
  }, []);

  const getAcademicYearList = () => {
    const params = {
      SchID: userinfo.SchID,
      Flag: "S",
      Type: "POST",
      FetchURL: `${appURL}api/academic-year`,
    };

    FetchNewdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.sessionRes ? result.sessionRes : "";

        setAcademicYearList(postResult);
      } else {
        setAcademicYearList([]);
      }
    });
  };
  return (
    <>
      Today's Date: {todayDate} |{" "}
      <span onClick={() => setPopup(true)} className="changeAcademic">
        Academic Year: {AcademicYear}
      </span>
      {popup && (
        <div className="modals">
          <div className="overlay"></div>

          <div
            className="notification-model-content"
            style={{
              height: "max-content",
              minWidth: "300px",
              maxWidth: "300px",
              paddingBottom: "75px",
            }}
          >
            <div className="popUpHeader ">
              <div className="popUpHeaderText ">Academic List</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={handleClose}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="inside-modal" style={{ height: "max-content" }}>
              <AcademicPop
                AcademicYearList={AcademicYearList}
                closeViewPopup={handleClose}
                appURL={appURL}
                userinfo={userinfo}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AcademicHeader;
