import React, { useContext, useEffect, useState } from "react";

import $ from "jquery";
import AuthContext from "./auth-context";
import { useNavigate } from "react-router-dom";
import TimeOutPopUp from "./TimeOutPopUp";
import SessionContext from "./SessionContext";
// import { redirect } from "react-router-dom";

const TimeoutComponent = () => {
  let navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const { isActive, setIsActive } = useContext(SessionContext);
  const timeoutDuration = 30 * 60 * 1000;

  useEffect(() => {
    let timeout;

    const resetTimer = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        setIsActive(false);
      }, timeoutDuration);
    };

    const clearTimer = () => {
      clearTimeout(timeout);
    };

    const handleUserActivity = (event) => {
      // Check if the event is a scroll event
      const isScrollEvent = event.type === "scroll";

      // Check if it's not a scroll event
      if (!isScrollEvent) {
        resetTimer();
      }
    };

    // Attach event listeners for user activity
    document.addEventListener("mousemove", handleUserActivity);
    document.addEventListener("keydown", handleUserActivity);
    document.addEventListener("scroll", handleUserActivity);

    resetTimer();

    // Cleanup function to remove event listeners and clear timer
    return () => {
      document.removeEventListener("mousemove", handleUserActivity);
      document.removeEventListener("keydown", handleUserActivity);
      document.removeEventListener("scroll", handleUserActivity);
      clearTimer();
    };
  }, []);

  useEffect(() => {
    if (!isActive) {
      authCtx.logout();
      sessionStorage.clear();
      localStorage.clear();
      navigate("/login");
    }
  }, [isActive]);

  if (!isActive) {
    // handleLogout()
    return (
      <>
        <TimeOutPopUp />
      </>
    );
  }
};

export default TimeoutComponent;
