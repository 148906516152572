import React from "react";
import {
  MdBusiness,
  MdLocationPin,
  MdLocationOn,
  MdOutlineAccessTimeFilled,
} from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";

const ListView = ({ jobList }) => {
  let navigate = useNavigate();
  return (
    <>
      {jobList.map((jobData) => {
        // to show when was job created
        const createdDate = new Date(jobData.JobAddedDate);

        // Get the current date
        const currentDate = new Date();

        // Calculate the remaining days
        const timeDifference = createdDate.getTime() - currentDate.getTime();

        let daysAgo;

        if (createdDate > currentDate) {
          daysAgo = "";
        } else {
          daysAgo = Math.ceil(timeDifference / (1000 * 3600 * 24));
        }
        return (
          <div>
            <div
              className="jobListsCard"
              key={jobData.JobsID}
              // onClick={() => navigate(`/jobPage/${jobData.JobsID}`)}
            >
              <div className="job__wrapper">
                <div className="com__logo">
                  <img src={jobData.ComLogo} alt="logo" />
                </div>

                <div class="job__content">
                  <h2
                    className="fw-bold m-0 fs-4 lh-5"
                    style={{ color: "#000" }}
                  >
                    {jobData.Designation}
                  </h2>

                  <div className="com__info">{jobData.ComName}</div>

                  <div className="job__txt mt-1">
                    <MdLocationOn
                      color="#0b4881"
                      size="1rem"
                      className="me-1"
                    />{" "}
                    {jobData.ComAddress}
                  </div>

                  {/* <div className="badge__wrapper">
                    <div className="job__badge">{jobData.JobType}</div>
                    <div className="job__badge">{jobData.WorkType}</div>
                    {daysAgo !== "" && (
                      <div className="job__txt">
                        <MdOutlineAccessTimeFilled
                          color="#838897"
                          size="1rem"
                          className="me-1"
                        />{" "}
                        {daysAgo === -1 ? (
                          <span>{Math.abs(daysAgo)} day ago</span>
                        ) : daysAgo === 0 ? (
                          <span>Today</span>
                        ) : (
                          <span>{Math.abs(daysAgo)} days ago</span>
                        )}
                      </div>
                    )}
                  </div>

                  <div className="deadline__wrapper">
                    <div className="job__deadline">
                      Apply before: {jobData.Deadline}
                    </div>
                    <div className="job__deadline">
                      {jobData.DaysRemaining === 0 ? "" : jobData.DaysRemaining}{" "}
                      {jobData.DaysRemaining === "1"
                        ? "day remaining"
                        : jobData.DaysRemaining === 0
                        ? "Last day"
                        : "days remaining"}
                    </div>
                  </div> */}
                </div>
              </div>

              <div
                className="uk-flex uk-flex-between uk-flex-middle mt-3"
                style={{ borderTop: "1px solid #eee" }}
              >
                <div className="job__deadline mt-2">
                  <MdOutlineAccessTimeFilled
                    color="#0b4881"
                    size="1rem"
                    style={{ marginTop: "-2px" }}
                  />{" "}
                  {jobData.DaysRemaining < 0
                    ? "Expired"
                    : jobData.DaysRemaining === 0
                    ? ""
                    : jobData.DaysRemaining}{" "}
                  {jobData.DaysRemaining === "1"
                    ? "day remaining"
                    : jobData.DaysRemaining === 0
                    ? "Last day"
                    : jobData.DaysRemaining < 0
                    ? ""
                    : "days remaining"}
                </div>
                <div className="badge__wrapper">
                  <div className="job__badge">{jobData.JobType}</div>
                  <div className="job__badge">{jobData.WorkType}</div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ListView;
