import React, { useContext, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import { showImgPreview } from "../hooks/ImagePreview";
import SidebarContext from "../sidebarcontext/SidebarContext";
import "../TabbedView/TabbedView.css";
import "../StudentProfile/StudentProfile.css";
import Staff from "./Staff";
import GoogleMap from "./GoogleMap";
import AcademicHeader from "../AcademicHeader";

export default function StudentAbout() {
  const { AcademicYear, todayDate } = useContext(SidebarContext);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const [imgPrv, setImgPrv] = useState(false);
  const [imagePre, setImagePre] = useState("");

  const [activeTab, setActiveTab] = useState({
    tab1: true,
    tab2: false,
  });
  const handleTab1 = () => {
    setActiveTab({
      tab1: true,
      tab2: false,
      tab3: false,
      tab4: false,
    });
  };
  const handleTab2 = () => {
    setActiveTab({
      tab1: false,
      tab2: true,
      tab3: false,
      tab4: false,
    });
  };
  const handleTab3 = () => {
    setActiveTab({
      tab1: false,
      tab2: false,
      tab3: true,
      tab4: false,
    });
  };
  const handleTab4 = () => {
    setActiveTab({
      tab1: false,
      tab2: false,
      tab3: false,
      tab4: true,
    });
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
        <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">About School</div>
            <div className="page-date">
              <div className="sec-content">
                <AcademicHeader
                  todayDate={todayDate}
                  AcademicYear={AcademicYear}
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="sec-dataTable content-section main-content">
          <div className="profile-info">
            <div className="profilecontentLogo tl">
              <div className="profilemgmtImg tl">
                <img
                  src={userinfo.SchLogo}
                  alt="dp"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setImagePre(userinfo.SchLogo);
                    setImgPrv(true);
                  }}
                />
              </div>
            </div>
            <div>
              <h6 style={{ paddingLeft: "10px", textAlign: "left" }}>
                {userinfo.SchName}
              </h6>
              <p
                style={{
                  textAlign: "left",
                  paddingLeft: "10px",
                  margin: "0px",
                }}
              >
                Address:&nbsp;{userinfo.SchAddress}
                <br />
                Contact:&nbsp;{userinfo.SchTel} <br />
                <div className="social-links-about">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={userinfo.SchFb}
                    className="facebook"
                  >
                    <i className="bi bi-facebook"></i>
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={userinfo.SchTwitter}
                    className="twitter"
                  >
                    <i className="bi bi-twitter"></i>
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={userinfo.SchYt}
                    className="instagram"
                  >
                    <i className="bi bi-youtube"></i>
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={userinfo.SchWebsite}
                    className="linkedin"
                  >
                    <i className="bi bi-globe2"></i>
                  </a>
                </div>
                <br />
              </p>
            </div>
          </div>

          <div className="tabview ">
            <div className="insidePopup--div">
              <ul>
                <li
                  className={activeTab.tab1 === true ? "active" : ""}
                  onClick={handleTab1}
                >
                  <i className="fa fa-file-text-o mr-1 icon"></i>About{" "}
                </li>
                <li
                  className={activeTab.tab2 === true ? "active" : ""}
                  onClick={handleTab2}
                >
                  <i className="fa fa-file-text-o mr-1 icon"></i>Message{" "}
                </li>
                <li
                  className={activeTab.tab3 === true ? "active" : ""}
                  onClick={handleTab3}
                >
                  <i className="fa fa-file-text-o mr-1 icon"></i>Staff{" "}
                </li>
                {userinfo.LocLat === ("0" || "-" || null || "") ||
                userinfo.LocLong === ("0" || "-" || null || "") ? (
                  <></>
                ) : (
                  <li
                    className={activeTab.tab4 === true ? "active" : ""}
                    onClick={handleTab4}
                  >
                    <i className="fa fa-file-text-o mr-1 icon"></i> Map
                  </li>
                )}
              </ul>
            </div>
          </div>

          <div>
            {activeTab.tab1 && (
              <div>
                <p
                  style={{
                    marginTop: "10px",
                    fontSize: "14px",
                    textAlign: "justify",
                  }}
                >
                  {userinfo.AboutUs}
                </p>
              </div>
            )}
            {activeTab.tab2 && (
              <div>
                <p
                  style={{
                    marginTop: "10px",
                    fontSize: "14px",
                    textAlign: "justify",
                  }}
                >
                  {userinfo.Msg}
                </p>
              </div>
            )}
            {activeTab.tab3 && <Staff userinfo={userinfo} />}
            {activeTab.tab4 && <GoogleMap userinfo={userinfo} />}
          </div>
        </div>
      </div>
      {imgPrv &&
        showImgPreview({
          img: imagePre,
          setTrigger: setImgPrv,
        })}
    </>
  );
}
