import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import MenuContext from "./MenuContext";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { Fetchdata } from "../../hooks/getData";

function MenuState(props) {
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const { appURL } = useContext(SidebarContext);

  const [menuPopup, setMenuPopup] = useState(false);

  const togglePopup = () => {
    setMenuPopup(!menuPopup);
  };

  const [menuEditPopup, setMenuEditPopup] = useState(false);

  const toggleEditPopup = () => {
    setMenuEditPopup(!menuEditPopup);
  };

  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);
  const [closeChecked, setCloseChecked] = useState(false);

  const menuValue = {
    itemName: "",
    category: "",
    type: "",
    price: "",
    unit: "",
    description: "",
  };
  const [menuFormValue, setMenuFormValue] = useState(menuValue);
  const [menuFormError, setMenuFormError] = useState({});

  const [chooseMenu, setChooseMenu] = useState("-1");
  const [chooseType, setChooseType] = useState("-1");
  const [chooseAvailable, setChooseAvailable] = useState("-1");

  const [isUploaded, setIsUploaded] = useState(false);
  const [typeFile, setTypeFile] = useState();
  const [image, setImage] = useState("");

  const [isAvailable, setIsAvailable] = useState(false);

  /* This is for Menu  Category popup */
  const categoryValue = {
    categoryName: "",
  };

  const [categoryPopup, setCategoryPopup] = useState(false);

  const toggleCategoryPopup = () => {
    setCategoryPopup(!categoryPopup);
  };

  const [categoryFormValue, setCategoryFormValue] = useState(categoryValue);
  const [categoryFormError, setCategoryFormError] = useState({});
  const [categoryIsSubmit, setCategoryIsSubmit] = useState(false);
  const [categoryTopicSearch, setCategoryTopicSearch] = useState(false);

  const addCategory = (e) => {
    e.preventDefault();
    setCategoryPopup(true);
    setCategoryFormValue(categoryValue);
  };

  /* This is for Menu Category popup ends*/

  const [isSubmit, setIsSubmit] = useState(false);
  const [editIsSubmit, setEditIsSubmit] = useState(false);

  /***********************************************Menu category api starts ************************ */

  // For Menu category create
  const categoryData = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "i",
      Name: categoryFormValue.categoryName,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/menu-category`,
      Type: "POST",
    };
    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        setCategoryFormValue(categoryValue);
        getMenuCategoryList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
          theme: "light",
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        });
      }
    });
  };

  // For Menu category create ends

  //API to hit Menu category list

  const [menuCategoryList, setMenuCategoryList] = useState([]);

  useEffect(() => {
    getMenuCategoryList();
  }, []);

  const getMenuCategoryList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "S",
      IsActive: "-1",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/menu-category`,
      Type: "POST",
    };
    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setMenuCategoryList(postResult);
        setCategoryTopicSearch(postResult);
        // setLoading(false);
      } else {
        setCategoryTopicSearch([]);
        setMenuCategoryList([]);
      }
    });
  };

  //For Menu Category list ends

  //For Menu Category list for drop down and others starts

  const [menuCategoryListForOther, setMenuCategoryListForOther] = useState([]);

  useEffect(() => {
    getMenuCategoryListForOther();
  }, []);

  const getMenuCategoryListForOther = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "S",
      IsActive: "A",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/menu-category`,
      Type: "POST",
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setMenuCategoryListForOther(postResult);
      } else {
        setMenuCategoryListForOther([]);
      }
    });
  };

  //For Menu Category list for drop down and others ends

  // For Menu Category edit
  const [disEdit, setDisEdit] = useState(false);
  const [disId, setDisId] = useState("");

  function selectUser(datas) {
    setCategoryFormValue({
      categoryName: datas.Name,
    });
    setDisId(datas.CategoryID);
    setDisEdit(true);
  }

  const editData = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "U",
      Name: categoryFormValue.categoryName,
      CategoryID: disId,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/menu-category`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getMenuCategoryList();
        setCategoryFormValue(categoryValue);
        setDisEdit(false);
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
          theme: "light",
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        });
      }
    });
  };

  // For Menu Category edit ends
  // For Menu Category deactivate/ activate starts

  const deactivateCategory = (ID, IsActives) => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "AI",
      CategoryID: ID,
      IsActive: IsActives,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/menu-category`,
      Type: "POST",
    };
    if (IsActives === "A") {
      dataForm.IsActive = "I";
    } else {
      dataForm.IsActive = "A";
    }
    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getMenuCategoryList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast(result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };

  // For Menu Category deactivate/ activate ends

  /***********************************************Menu Category api ends ********************* */

  //API for Menu create

  const fetchdata = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "I",
      MenuType: menuFormValue.type,
      CategoryID: menuFormValue.category,
      Image: image !== null ? image.split(",")[1] : "",
      Name: menuFormValue.itemName,
      Description: menuFormValue.description,
      Currency: "RS",
      Price: menuFormValue.price,
      PriceUnit: menuFormValue.unit,
      IsAvail: isAvailable === true ? "Y" : "N",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/menu`,
      Type: "POST",
    };
    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getMenuList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
          theme: "light",
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        });
      }
    });
  };

  //API to hit Menu list
  const [menuList, setMenuList] = useState([]);

  useEffect(() => {
    getMenuList();
  }, [chooseAvailable, chooseType, chooseMenu]);

  const getMenuList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "S",
      IsActive: "-1",
      IsAvail: chooseAvailable,
      MenuType: chooseType,
      CategoryID: chooseMenu,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/menu`,
      Type: "POST",
    };
    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setMenuList(postResult);
        setOriginalList(postResult);
        setLoading(false);
      } else {
        setOriginalList([]);
        setMenuList([]);
      }
    });
  };

  const [titleId, setTitleID] = useState();
  const editPop = (datas) => {
    setMenuEditPopup(true);
    setTitleID(datas.MenuID);
    menuInfo();
    setEditIsSubmit(false);
  };

  // For Menu info
  useEffect(() => {
    menuInfo();
  }, [titleId]);

  const menuInfo = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "SI",
      MenuID: titleId,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/menu`,
      Type: "POST",
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const data = result.Values[0] ? result.Values[0] : "";
        setMenuFormValue({
          itemName: data.Name,
          category: data.CategoryID,
          type: data.MenuTypeID,
          price: data.Price,
          unit: data.PriceUnit,
          description: data.Description,
        });
        setImage(data.Image);
        setIsAvailable(data.IsAvail === "Y" ? true : false);
      }
    });
  };

  const editMenudata = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "U",
      MenuID: titleId,
      MenuType: menuFormValue.type,
      CategoryID: menuFormValue.category,
      Image: image !== null ? image.split(",")[1] : "",
      Name: menuFormValue.itemName,
      Description: menuFormValue.description,
      Currency: "RS",
      Price: menuFormValue.price,
      PriceUnit: menuFormValue.unit,
      IsAvail: isAvailable === true ? "Y" : "N",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/menu`,
      Type: "POST",
    };
    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getMenuList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
          theme: "light",
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        });
      }
    });
  };

  //API to hit Menu status

  const deactivateDepart = (ID, IsActives) => {
    const dataForm = {
      // AuthCode: User.AuthCode,
      // ComID: User.CompanyId,
      // UserID: User.UID.toString(),
      // Flag: "AI",
      // IsActive: IsActives,
      // MenuID: ID,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/restro/admin/menu`,
      Type: "POST",
    };
    if (IsActives === "A") {
      dataForm.IsActive = "I";
    } else {
      dataForm.IsActive = "A";
    }
    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getMenuList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
          theme: "light",
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        });
      }
    });
  };

  //Activate popup starts from here
  //Everything to pop up and approve the  list
  const [activatePopup, setActivatePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });
  // To approve the credit List

  const [Acdata, setACData] = useState();

  const handleActivate = async (data) => {
    setACData(data.MenuID);

    setActivatePopup({
      show: true,
      data,
    });
  };

  // This will perform the approve and hide the Confirmation Box

  const handleActivateTrue = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "AI",
      MenuID: Acdata,
      IsAvail: chooseAvailable,
      MenuType: chooseType,
      CategoryID: chooseMenu,
      IsActive: "A",
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/menu`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          getMenuList();
          toast(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
        } else {
          toast(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
    });
    setActivatePopup({
      show: false,
      data: null,
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleActivateFalse = () => {
    setActivatePopup({
      show: false,
      data: null,
    });
  };

  //Everything to pop up and activate  ends

  //Activate popup starts from here
  //Everything to pop up and approve the  list
  const [inActivatePopup, setInActivatePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });
  // To approve the credit List

  const [inactivedata, setInactiveData] = useState();

  const handleInActivate = async (data) => {
    setInactiveData(data.MenuID);

    setInActivatePopup({
      show: true,
      data,
    });
  };

  // This will perform the approve and hide the Confirmation Box

  const handleInActivateTrue = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "AI",
      MenuID: inactivedata,
      IsAvail: chooseAvailable,
      MenuType: chooseType,
      CategoryID: chooseMenu,
      IsActive: "I",
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/menu`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          getMenuList();
          toast(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
        } else {
          toast(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
    });
    setInActivatePopup({
      show: false,
      data: null,
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleInActivateFalse = () => {
    setInActivatePopup({
      show: false,
      data: null,
    });
  };

  //Everything to pop up and approve the credit list ends

  return (
    <MenuContext.Provider
      value={{
        setMenuPopup,
        loading,
        reload,
        setReload,
        menuFormValue,
        menuValue,
        setMenuFormValue,
        menuFormError,
        setMenuFormError,
        menuPopup,
        menuEditPopup,
        editPop,
        originalList,
        menuList,
        deactivateDepart,
        fetchdata,
        isSubmit,
        setIsSubmit,
        editIsSubmit,
        setEditIsSubmit,
        editMenudata,
        addCategory,
        setMenuEditPopup,
        isAvailable,
        setIsAvailable,
        setMenuList,

        categoryPopup,
        setCategoryPopup,
        categoryFormValue,
        setCategoryFormValue,
        categoryFormError,
        setCategoryFormError,
        categoryIsSubmit,
        setCategoryIsSubmit,
        categoryTopicSearch,
        setCategoryTopicSearch,
        categoryData,
        menuCategoryList,
        setMenuCategoryList,
        disEdit,
        setDisEdit,
        selectUser,
        editData,
        categoryValue,
        menuCategoryListForOther,

        isUploaded,
        setIsUploaded,
        typeFile,
        image,
        setTypeFile,
        setImage,
        chooseMenu,
        setChooseMenu,
        chooseType,
        setChooseType,
        chooseAvailable,
        setChooseAvailable,
        closeChecked,
        setCloseChecked,
        togglePopup,
        toggleCategoryPopup,
        toggleEditPopup,

        activatePopup,
        setActivatePopup,
        handleActivate,
        handleActivateTrue,
        handleActivateFalse,
        inActivatePopup,
        setInActivatePopup,
        handleInActivate,
        handleInActivateTrue,
        handleInActivateFalse,
        deactivateCategory,
      }}
    >
      {props.children}
    </MenuContext.Provider>
  );
}
export default MenuState;
