import React, { useContext, useRef, useState, useEffect, } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import 'tippy.js/dist/tippy.css';
import SidebarContext from "../sidebarcontext/SidebarContext";
import Spinner from "../loading/spinner";

import TeacherAttendanceContext from "../contextfolder/TeacherAttendanceContext/TeacherAttendanceContext";
import { Fetchdata } from "../hooks/getData";
import DeviceCodeContext from "../contextfolder/DeviceCodeContext/DeviceCodeContext";





export default function DeviceCode() {
    const { customStyles, userinfo, appURL } = useContext(SidebarContext);
    const { loading, deviceCode,
        setDeviceCode, originalList, setOriginalList } = useContext(DeviceCodeContext)

    // const handleReport = (e) => {
    //     const target = e.target;
    //     const value = target.value;
    //     setReportOption(value);
    // }

    // API for route List



    const searchInput = useRef("");

    const columns = [
        {
            name: "S.N.",
            width: "63px",
            center: true,
            cell: (row, index) => index + 1,
        },

        {
            name: "Full Name",

            selector: (row) => row.FullName,
        },
        {
            name: "Username",
            // width: "400px",
            selector: (row) => row.UserName,
        },
        {
            name: "Device Code",
            // width: "400px",
            cell: (row, index) => {
                return (
                    <>
                        <input
                            type="number"
                            style={{ fontSize: "13px" }}
                            className="form-control form-control-sm "
                            value={row.DeviceCode}
                            onChange={(event) => handleInputChange(event, row, "DeviceCode")}
                            defaultValue={row.DeviceCode}
                            onKeyPress={handleKeyPress}
                        />
                    </>
                );
            },
        },



    ];
    // const handleInputChange = (e, data) => {
    //     e.preventDefault()
    //     setInputValue({
    //         UserName: data.UserName,
    //         DeviceCode: e.target.value
    //     })
    // }

    const handleInputChange = (event, row, field) => {
        const value = (event.target.value).toString();
        const updatedMarksInputList = deviceCode.map((item) => {
            if (item.UserName === row.UserName) {
                if (field === "DeviceCode") {
                    return {
                        ...item,
                        [field]:
                            value
                    };
                }
            } else {
                return item;
            }
        });
        setDeviceCode(updatedMarksInputList);
    };



    const handleKeyPress = (event) => {
        // Check if the key pressed is not a number
        if (isNaN(Number(event.key))) {
            event.preventDefault(); // Prevent the character from being entered
        }
    };



    // const searchHandler = (e) => {
    //     e.preventDefault();

    //     const srchQuery = searchInput.current.value.toLowerCase();
    //     if (srchQuery) {

    //         let srchResult = search.filter((list) => {
    //             return list["Name"].toLowerCase().includes(srchQuery) || list["SSID"].toLowerCase().includes(srchQuery);
    //         });

    //         if (srchResult) {

    //             setAttendanceList(srchResult);
    //         } else {
    //             setAttendanceList({});
    //         }
    //     } else {
    //         setAttendanceList(search);
    //     }
    // };

    const searchHandler = (e) => {
        e.preventDefault();

        const srchQuery = searchInput.current.value.toLowerCase();
        if (srchQuery) {

            let srchResult = originalList.filter((list) => {
                return list["FullName"].toLowerCase().includes(srchQuery);
            });

            if (srchResult) {

                setDeviceCode(srchResult);
            } else {
                setDeviceCode({});
            }
        } else {
            setDeviceCode(originalList);
        }
    };

    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
            />

            <>
                <div className="sec-dataTable">


                    {loading ? (
                        <Spinner />
                    ) : (
                        <DataTable
                            columns={columns}
                            data={deviceCode}
                            customStyles={customStyles}
                            pagination
                            fixedHeader
                            fixedHeaderScrollHeight="280px"
                            highlightOnHover
                            pointerOnHover
                            responsive
                            dense
                            striped
                            subHeader
                            subHeaderAlign="left"
                            subHeaderComponent={
                                <>
                                    <div className="col-lg-2 offset-lg-10 offset-md-10 offset-sm-0 col-md-2 col-sm-2 align-self-end Search" style={{ paddingRight: "1px", marginBottom: "10px" }}>
                                        <div className="d-flex">
                                            <input
                                                placeholder="Search"
                                                ref={searchInput}
                                                type="text"
                                                className="form-control form-control-sm searchField"
                                                onChange={searchHandler}
                                            />
                                        </div>
                                    </div>
                                </>
                            }

                        />
                    )}
                </div>
            </>


            {/* {activatePopup.show && (
                <ActivatePop
                    handleActivateTrue={handleActivateTrue}
                    handleActivateFalse={handleActivateFalse}
                />
            )}
            {inActivatePopup.show && (
                <InactivatePop
                    handleInActivateTrue={handleInActivateTrue}
                    handleInActivateFalse={handleInActivateFalse}
                />
            )} */}
        </>
    );
}
