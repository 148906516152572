import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";
import { showImgPreview } from "../hooks/ImagePreview";
import NotificationContext from "../contextfolder/NotificationContext/NotificationContext";
import NotificationForm from "./NotificationForm";
import DeletePop from "./DeletePop";
import NotificationEdit from "./NotificationEdit";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { GetNepaliDate } from "../hooks/dateConvertor";
import AcademicHeader from "../AcademicHeader";

export default function NotificationList() {
  const { todayDate, customStylesForImage, AcademicYear } =
    useContext(SidebarContext);
  const {
    loading,
    originalList,
    popup,
    togglePopup,
    setIsSubmit,
    setNotificationFormValue,
    setNotificationFormError,
    classList,
    sectionList,
    teacherList,
    studentList,
    notificationValue,
    notificationList,
    setNotificationList,
    reportOption,
    setReportOption,
    deletePopup,
    handleDeleteTrue,
    handleDeleteFalse,
    handleDelete,
    selectClass,
    setSelectClass,
    selectTeacher,
    setSelectTeacher,
    selectStudent,
    setSelectStudent,
    setImage,
    setIsUploaded,
    selectSection,
    setSelectSection,
    toggleEditPopup,
    setEditIsSubmit,
    setCloseChecked,
    editPop,
    handleEdit,
  } = useContext(NotificationContext);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const [imgPrv, setImgPrv] = useState(false);
  const [imagePre, setImagePre] = useState("");

  const searchInput = useRef("");

  const columns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "Date",
      width: "190px",
      selector: (row) => GetNepaliDate(row.NotificationDate),
    },
    {
      name: "Title",
      width: "150px",
      selector: (row) => row.Title,
    },
    {
      name: "Description",
      width: "550px",
      selector: (row) => row.Description,
    },
    {
      name: "Attachment",
      // grow: 0,
      center: true,
      width: "120px",
      selector: (row) => {
        return (
          <>
            <div className="staffContentLogo">
              <div className="staffImg">
                <img
                  src={row.Attachment}
                  alt=""
                  onClick={() => {
                    setImagePre(row.Attachment);
                    setImgPrv(true);
                  }}
                />
              </div>
            </div>
          </>
        );
      },
    },

    {
      name: "Action",
      // grow: 0,
      center: true,
      width: "100px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <Tippy content="Edit">
                <i
                  className="fa fa-pencil solvespan--action"
                  onClick={() => handleEdit(row)}
                ></i>
              </Tippy>
              <Tippy content="Delete">
                <i
                  className="fa fa-trash-o rejectspan--action"
                  onClick={() => handleDelete(row.NotificationID)}
                ></i>
              </Tippy>
            </div>
          </>
        );
      },
    },
  ];

  const closePopup = (e) => {
    e.preventDefault();
    togglePopup();
    setNotificationFormValue(notificationValue);
    setNotificationFormError({});
    setCloseChecked(false);
    setIsSubmit(false);
    setImage("");
    setIsUploaded(false);
  };

  const closeEditPop = (e) => {
    e.preventDefault();
    toggleEditPopup();
    setNotificationFormError({});
    setEditIsSubmit(false);
    setCloseChecked(false);
  };

  const addnew = (e) => {
    e.preventDefault();
    togglePopup();
    setIsSubmit(false);
    setNotificationFormValue(notificationValue);
  };

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["Title"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setNotificationList(srchResult);
      } else {
        setNotificationList({});
      }
    } else {
      setNotificationList(originalList);
    }
  };

  const handleClass = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectClass(value);
    setSelectSection("");
    setSelectStudent("");
    setSelectTeacher("");
  };

  const handleStudent = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectStudent(value);
    setSelectTeacher("");
  };

  const handleTeacher = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectTeacher(value);
  };

  const handleSection = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectSection(value);
    setSelectStudent("");
    setSelectTeacher("");
  };

  const handleReport = (e) => {
    const target = e.target;
    const value = target.value;
    setReportOption(value);
    setSelectClass("");
    setSelectSection("");
    setSelectStudent("");
    setSelectTeacher("");
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
        <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">Notification</div>
            <div className="page-date">
              <div className="sec-content">
                <AcademicHeader
                  todayDate={todayDate}
                  AcademicYear={AcademicYear}
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <>
          <div className="sec-dataTable content-section main-content">
            <div className="upper-dataTbl">
              <div className="btn-addlnote">
                <button
                  type="button"
                  class="btn btn-sm"
                  style={{
                    background: "var(--MainColor)",
                    color: "white",
                  }}
                  onClick={addnew}
                >
                  Add New
                </button>

                {/* <button
                                    type="button"
                                    class="btn btn-sm"
                                    style={{
                                        background: "var(--MainColor)",
                                        color: "white",
                                    }}
                                    onClick={view}
                                >
                                    View
                                </button> */}
              </div>
            </div>
            <hr className="new-hr-new" />
            {loading ? (
              <Spinner />
            ) : (
              <DataTable
                columns={columns}
                data={notificationList}
                customStyles={customStylesForImage}
                pagination
                fixedHeader
                fixedHeaderScrollHeight="280px"
                highlightOnHover
                pointerOnHover
                responsive
                dense
                striped
                subHeader
                subHeaderAlign="left"
                subHeaderComponent={
                  <>
                    <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                      <label className="list-label">Select Option</label>
                      <select
                        style={{ fontSize: "11px" }}
                        name="status"
                        className="form-control form-control-sm"
                        aria-label="Default select example "
                        value={reportOption}
                        onChange={handleReport}
                      >
                        <option
                          selected
                          disabled
                          value=""
                          style={{ fontSize: "11px" }}
                        >
                          Select Option
                        </option>
                        {userinfo.UserType !== "T" && (
                          <option value="-1">All</option>
                        )}
                        <option value="C">Class</option>
                        <option value="S">Section</option>
                        <option value="I">Individual</option>
                        {userinfo.UserType !== "T" && (
                          <>
                            <option value="T">Teacher</option>
                            <option value="A">Admin</option>
                          </>
                        )}
                      </select>
                      <i class="fas fa-angle-down  notificationpositon"></i>
                    </div>

                    {reportOption === "C" ||
                    reportOption === "S" ||
                    reportOption === "I" ? (
                      <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                        <label className="list-label">Class</label>
                        <select
                          style={{ fontSize: "11px" }}
                          name="snotifiaction"
                          value={selectClass}
                          onChange={handleClass}
                          className="form-control form-control-sm"
                          aria-label="Default select example "
                        >
                          <option
                            value=""
                            disabled
                            selected
                            style={{ fontSize: "11px" }}
                          >
                            Select Class
                          </option>

                          {classList.map((item) => (
                            <option key={item.ClassID} value={item.ClassID}>
                              {item.ClassName}
                            </option>
                          ))}
                        </select>
                        <i class="fas fa-angle-down  notificationpositon"></i>
                      </div>
                    ) : (
                      <> </>
                    )}

                    {reportOption === "S" || reportOption === "I" ? (
                      <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                        <label className="list-label">Section</label>
                        <select
                          style={{ fontSize: "11px" }}
                          name="snotifiaction"
                          value={selectSection}
                          onChange={handleSection}
                          className="form-control form-control-sm"
                          aria-label="Default select example "
                        >
                          <option
                            value=""
                            disabled
                            selected
                            style={{ fontSize: "11px" }}
                          >
                            Select Section
                          </option>

                          {sectionList.map((item) => (
                            <option key={item.SectionID} value={item.SectionID}>
                              {item.SectionName}
                            </option>
                          ))}
                        </select>
                        <i class="fas fa-angle-down  notificationpositon"></i>
                      </div>
                    ) : (
                      <></>
                    )}
                    {reportOption === "I" ? (
                      <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                        <label className="list-label">Student</label>
                        <select
                          style={{ fontSize: "11px" }}
                          name="snotifiaction"
                          value={selectStudent}
                          onChange={handleStudent}
                          className="form-control form-control-sm"
                          aria-label="Default select example "
                        >
                          <option
                            value=""
                            disabled
                            selected
                            style={{ fontSize: "11px" }}
                          >
                            Select Student
                          </option>

                          {studentList.map((item) => (
                            <option key={item.StudentID} value={item.StudentID}>
                              {item.StdFullName}
                            </option>
                          ))}
                        </select>
                        <i class="fas fa-angle-down  notificationpositon"></i>
                      </div>
                    ) : (
                      <> </>
                    )}

                    {reportOption === "T" && (
                      <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                        <label className="list-label">Teacher</label>
                        <select
                          style={{ fontSize: "11px" }}
                          name="snotifiaction"
                          value={selectTeacher}
                          onChange={handleTeacher}
                          className="form-control form-control-sm"
                          aria-label="Default select example"
                        >
                          <option
                            value=""
                            disabled
                            selected
                            style={{ fontSize: "11px" }}
                          >
                            Select Teacher
                          </option>

                          <option value="-1">All</option>

                          {teacherList.map((item) => (
                            <option key={item.TeacherID} value={item.TeacherID}>
                              {item.TeacherName}
                            </option>
                          ))}
                        </select>
                        <i class="fas fa-angle-down  notificationpositon"></i>
                      </div>
                    )}

                    {reportOption === "A" && (
                      <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                        <label className="list-label">Admin</label>
                        <select
                          style={{ fontSize: "11px" }}
                          name="snotifiaction"
                          value={selectTeacher}
                          onChange={handleTeacher}
                          className="form-control form-control-sm"
                          aria-label="Default select example"
                        >
                          <option
                            value=""
                            disabled
                            selected
                            style={{ fontSize: "11px" }}
                          >
                            Select Admin
                          </option>

                          <option value="-1">All</option>
                          {teacherList.map((item) => (
                            <option key={item.TeacherID} value={item.TeacherID}>
                              {item.TeacherName}
                            </option>
                          ))}
                        </select>
                        <i class="fas fa-angle-down  notificationpositon"></i>
                      </div>
                    )}

                    <div
                      className={
                        reportOption === "-1"
                          ? "col-lg-2 offset-lg-8 offset-md-6 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
                          : reportOption === "C" ||
                            reportOption === "T" ||
                            reportOption === "A"
                          ? "col-lg-2 offset-lg-6 offset-md-6 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
                          : reportOption === "S"
                          ? "col-lg-2 offset-lg-4 offset-md-4 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
                          : reportOption === "I"
                          ? "col-lg-2 offset-lg-2 offset-md-2 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
                          : reportOption === ""
                          ? "col-lg-2 offset-lg-8 offset-md-8 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
                          : ""
                      }
                      style={{
                        marginTop: "25px",
                        paddingRight: "1px",
                        marginBottom: "10px",
                      }}
                    >
                      <div className="d-flex">
                        <input
                          placeholder="Search"
                          ref={searchInput}
                          type="text"
                          className="form-control form-control-sm searchField"
                          onChange={searchHandler}
                        />
                      </div>
                    </div>
                  </>
                }
              />
            )}
          </div>
        </>
      </div>

      {popup && (
        <div className="modals">
          <div className="overlay"></div>

          <div className="notification-model-content">
            <div className="popUpHeader ">
              <div className="popUpHeaderText ">Notification</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closePopup}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="inside-modal">
              <NotificationForm closePopup={closePopup} />
            </div>
          </div>
        </div>
      )}

      {editPop && (
        <div className="modals">
          <div className="overlay"></div>

          <div className="notification-model-content">
            <div className="popUpHeader ">
              <div className="popUpHeaderText ">Notification Edit</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closeEditPop}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="inside-modal">
              <NotificationEdit closeEditPop={closeEditPop} />
            </div>
          </div>
        </div>
      )}

      {deletePopup.show && (
        <DeletePop
          handleDeleteTrue={handleDeleteTrue}
          handleDeleteFalse={handleDeleteFalse}
        />
      )}

      {imgPrv &&
        showImgPreview({
          img: imagePre,
          setTrigger: setImgPrv,
        })}
    </>
  );
}
