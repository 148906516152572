import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../../css/Commission.css";
import Spinner from "../../loading/spinner";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { showImgPreview } from "../../hooks/ImagePreview";

import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { GetNepaliDate } from "../../hooks/dateConvertor";

import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import LogPhotoForm from "./LogPhotoForm";
import LogPhotoContext from "../../contextfolder/LogPhotoContext/LogPhotoContext";
import DeletePop from "./DeletePop";

export default function LogPhotoList() {
  const { todayDate, customStylesForImage, AcademicYear } =
    useContext(SidebarContext);
  const {
    loading,
    originalList,
    popup,
    togglePopup,
    setIsSubmit,
    setlogPhotoFormValue,
    setlogPhotoFormError,
    teacherList,
    logPhotoValue,
    logPhotoList,
    setLogPhotoList,
    deletePopup,
    handleDeleteTrue,
    handleDeleteFalse,
    handleDelete,
    selectTeacher,
    setSelectTeacher,

    setCloseChecked,
    todaysDate,
    setTodaysDate,
    selectOption,
    setSelectOption,
  } = useContext(LogPhotoContext);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const [imgPrv, setImgPrv] = useState(false);
  const [ImgPreview, setImgPreview] = useState("");

  const searchInput = useRef("");

  const columns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "Full Name",
      selector: (row) => row.FullName,
    },
    {
      name: "Title",
      minWidth: "200px",
      selector: (row) => row.Lesson,
    },

    {
      name: "Attachment",
      width: "100px",
      center: true,
      selector: (row) => {
        return (
          <div className="ln-verition d-flex">
            <Tippy content="View">
              <span
                className="viewspan--action px-2"
                onClick={() => {
                  setImgPreview(row.Photo);
                  setImgPrv(true);
                }}
                style={{ color: "#fff", width: "max-content" }}
              >
                <i className="bi bi-eye"></i>
              </span>
            </Tippy>
          </div>
        );
      },
    },

    {
      name: "Action",
      // grow: 0,
      center: true,
      width: "100px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <Tippy content="Delete">
                <i
                  className="fa fa-trash-o rejectspan--action"
                  onClick={() => handleDelete(row)}
                ></i>
              </Tippy>
            </div>
          </>
        );
      },
    },
  ];

  const handleReport = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectOption(value);
  };

  const closePopup = (e) => {
    e.preventDefault();
    togglePopup();
    setlogPhotoFormValue(logPhotoValue);
    setlogPhotoFormError({});
    setCloseChecked(false);
    setIsSubmit(false);
  };

  const addnew = (e) => {
    e.preventDefault();
    togglePopup();
    setIsSubmit(false);
    setlogPhotoFormValue(logPhotoValue);
  };

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["Title"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setLogPhotoList(srchResult);
      } else {
        setLogPhotoList({});
      }
    } else {
      setLogPhotoList(originalList);
    }
  };

  const handleTeacher = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectTeacher(value);
  };

  const handleTodayDate = ({ adDate }) => {
    setTodaysDate({ ...todaysDate, todayDate: adDate });
  };

  return (
    <>
      {/* <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      /> */}
      <div className="col-lg-12 col-md-12 col-sm-12 ">
        {/* <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">Log Photo</div>
            <div className="page-date">
              <div className="sec-content">
                <AcademicHeader/>
              </div>
            </div>
          </div>
        </div>
        <hr /> */}
        <>
          <div className="sec-dataTable ">
            <div className="upper-dataTbl">
              <div className="btn-addlnote">
                <button
                  type="button"
                  class="btn btn-sm"
                  style={{
                    background: "var(--MainColor)",
                    color: "white",
                  }}
                  onClick={addnew}
                >
                  Add New
                </button>
              </div>
            </div>
            <hr className="new-hr-new" />
            {loading ? (
              <Spinner />
            ) : (
              <DataTable
                columns={columns}
                data={logPhotoList}
                customStyles={customStylesForImage}
                pagination
                fixedHeader
                fixedHeaderScrollHeight="280px"
                highlightOnHover
                pointerOnHover
                responsive
                dense
                striped
                subHeader
                subHeaderAlign="left"
                subHeaderComponent={
                  <>
                    {userinfo.UserType !== "T" && (
                      <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                        <label className="list-label">Select Option</label>
                        <select
                          style={{ fontSize: "11px" }}
                          name="status"
                          className="form-control form-control-sm"
                          aria-label="Default select example "
                          value={selectOption}
                          onChange={handleReport}
                        >
                          <option
                            selected
                            disabled
                            value=""
                            style={{ fontSize: "11px" }}
                          >
                            Select Option
                          </option>
                          <option value="DW">Date Wise</option>
                          <option value="TW">Teacher Wise</option>
                        </select>
                        <i class="fas fa-angle-down  notificationpositon"></i>
                      </div>
                    )}
                    {userinfo.UserType !== "T" && (
                      <>
                        {" "}
                        {selectOption === "DW" && (
                          <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                            <label className="list-label">Today Date</label>
                            <Calendar
                              className="form-control form-control-sm searchField"
                              dateFormat="YYYY/MM/DD"
                              theme="default"
                              language="en"
                              values={todaysDate}
                              onChange={handleTodayDate}
                            />
                          </div>
                        )}
                      </>
                    )}

                    {selectOption === "TW" && (
                      <>
                        {userinfo.UserType !== "T" && (
                          <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                            <label className="list-label">Teacher</label>
                            <select
                              style={{ fontSize: "11px" }}
                              name="snotifiaction"
                              value={selectTeacher}
                              onChange={handleTeacher}
                              className="form-control form-control-sm"
                              aria-label="Default select example"
                            >
                              <option
                                value=""
                                disabled
                                selected
                                style={{ fontSize: "11px" }}
                              >
                                Select Teacher
                              </option>
                              {/* <option value="-1">Select All</option> */}
                              {teacherList.map((item) => (
                                <option
                                  key={item.TeacherID}
                                  value={item.TeacherID}
                                >
                                  {item.TeacherName}
                                </option>
                              ))}
                            </select>
                            <i class="fas fa-angle-down  notificationpositon"></i>
                          </div>
                        )}
                      </>
                    )}
                  </>
                }
              />
            )}
          </div>
        </>
      </div>

      {popup && (
        <div className="modals">
          <div className="overlay"></div>

          <div className="notification-model-content">
            <div className="popUpHeader ">
              <div className="popUpHeaderText ">Log Photo</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closePopup}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="inside-modal">
              <LogPhotoForm closePopup={closePopup} />
            </div>
          </div>
        </div>
      )}

      {imgPrv &&
        showImgPreview({
          img: ImgPreview,
          setTrigger: setImgPrv,
        })}

      {deletePopup.show && (
        <DeletePop
          handleDeleteTrue={handleDeleteTrue}
          handleDeleteFalse={handleDeleteFalse}
        />
      )}
    </>
  );
}
