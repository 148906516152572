import React from "react";
export default function ViewQrPayment({ viewData, closeViewPopup }) {
  const data = [
    {
      id: 1,
      title: "Payment Mode",
      desc: viewData.PayModeName,
    },
    {
      id: 2,
      title: "QR Value",
      desc: viewData.QRValue,
    },
  ];

  return (
    <>
      <div className="container-fluid basicform">
        <div className="">
          <div className="content-section main-content">
            <div className="view__table">
              {data.map((props) => {
                const { id, title, desc } = props;
                return (
                  <div key={id}>
                    <h4 className="the__title">{title}</h4>
                    <p className="the__content">{desc}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="FormFooter">
        <div className="checkedClose"></div>
        <div>
          <button className="btn btn-sm btn-cmpy ml-2" onClick={closeViewPopup}>
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}
