import React, { useContext, useEffect, useState } from "react";
import { Fetchdata } from "../../hooks/getData";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { toast } from "react-toastify";
import TeacherAttendanceContext from "./TeacherAttendanceContext";

function TeacherAttendanceState(props) {
  const { appURL, todayDate } = useContext(SidebarContext);

  const [loading, setLoading] = useState(true);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const [reportOption, setReportOption] = useState("-1");

  // This is variable declaration for attendance report
  const attendanceReportValue = {
    date: todayDate,
    time: "",
    status: "",
    teacher: "",
  };
  const [attendanceReportFormValue, setAttendanceReportFormValue] = useState(
    attendanceReportValue
  );
  const [attendanceReportFormError, setAttendanceReportFormError] = useState(
    {}
  );
  const [reportIsSubmit, setReportIsSubmit] = useState(false);

  const [popup, setPopup] = useState(false);
  const togglePopup = () => {
    setPopup(!popup);
  };

  // This is variable declaration for attendance report ends

  // This is all for route

  const attendanceValue = {
    name: "",
    ssid: "",
  };
  const [attendanceFormValue, setAttendanceFormValue] =
    useState(attendanceValue);
  const [attendanceFormError, setAttendanceFormError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [editIsSubmit, setEditIsSubmit] = useState(false);
  const [search, setSearch] = useState(false);
  const [closeChecked, setCloseChecked] = useState(false);

  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };

  const [editModal, setEditModal] = useState(false);
  const toggleEditModal = () => {
    setEditModal(!editModal);
  };

  const [active, setActive] = useState(false);

  //API to create attendance route
  const fetchAttendanceData = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "i",
      Name: attendanceFormValue.name,
      SSID: attendanceFormValue.ssid,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/atten-route`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getAttendanceList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };

  // API for route List
  const [attendanceList, setAttendanceList] = useState([]);

  useEffect(() => {
    getAttendanceList();
  }, [reportOption]);

  const getAttendanceList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "S",
      IsActive: reportOption,
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/atten-route`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setLoading(false);
        setAttendanceList(postResult);
        setSearch(postResult);
      } else {
        setLoading(false);
        setAttendanceList([]);
        setSearch([]);
      }
    });
  };

  //For Edit
  const [routeId, setRouteId] = useState("");

  const handleEdit = (data) => {
    setEditModal(!editModal);
    setRouteId(data.RouteID);
    setAttendanceFormValue({
      name: data.Name,
      ssid: data.SSID,
    });
    // setActive(data.IsActive === "Y" ? true : false)
    setEditIsSubmit(false);
  };

  const editroutedata = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "U",
      RouteID: routeId.toString(),
      Name: attendanceFormValue.name,
      SSID: attendanceFormValue.ssid,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/atten-route`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getAttendanceList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };

  //Everything to pop up and delete the event list
  const [deletePopup, setDeletePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const [delData, setDelData] = useState();

  const handleDelete = async (data) => {
    setDelData(data);

    setDeletePopup({
      show: true,
      data,
    });
  };

  // This will perform the deletion and hide the Confirmation Box

  const handleDeleteTrue = async () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "R",
      RouteID: delData.toString(),
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/atten-route`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          getAttendanceList();
          toast(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
        } else {
          toast(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
    });
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleDeleteFalse = () => {
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // Ends Here

  //Activate popup starts from here
  //Everything to pop up and approve the  list
  const [activatePopup, setActivatePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });
  // To approve the credit List

  const [Acdata, setACData] = useState();

  const handleActivate = async (data) => {
    setACData(data.RouteID);

    setActivatePopup({
      show: true,
      data,
    });
  };

  // This will perform the approve and hide the Confirmation Box

  const handleActivateTrue = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "AI",
      RouteID: Acdata.toString(),
      IsActive: "A",
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/atten-route`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          getAttendanceList();
          toast(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
        } else {
          toast(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
    });
    setActivatePopup({
      show: false,
      data: null,
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleActivateFalse = () => {
    setActivatePopup({
      show: false,
      data: null,
    });
  };

  //Everything to pop up and activate  ends

  //Activate popup starts from here
  //Everything to pop up and approve the  list
  const [inActivatePopup, setInActivatePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });
  // To approve the credit List

  const [inactivedata, setInactiveData] = useState();

  const handleInActivate = async (data) => {
    setInactiveData(data.RouteID);

    setInActivatePopup({
      show: true,
      data,
    });
  };

  // This will perform the approve and hide the Confirmation Box

  const handleInActivateTrue = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "AI",
      RouteID: inactivedata.toString(),
      IsActive: "I",
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/atten-route`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          getAttendanceList();
          toast(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
        } else {
          toast(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
    });
    setInActivatePopup({
      show: false,
      data: null,
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleInActivateFalse = () => {
    setInActivatePopup({
      show: false,
      data: null,
    });
  };

  //Everything to pop up and approve the credit list ends

  return (
    <TeacherAttendanceContext.Provider
      value={{
        loading,
        deletePopup,
        handleDeleteTrue,
        handleDeleteFalse,
        handleDelete,

        attendanceFormValue,
        setAttendanceFormValue,
        attendanceFormError,
        setAttendanceFormError,
        isSubmit,
        setIsSubmit,
        modal,
        setModal,
        toggleModal,
        attendanceValue,
        search,
        setSearch,
        attendanceList,
        setAttendanceList,
        active,
        setActive,
        editModal,
        setEditModal,
        toggleEditModal,
        editIsSubmit,
        setEditIsSubmit,
        handleEdit,
        fetchAttendanceData,
        editroutedata,
        closeChecked,
        setCloseChecked,
        reportOption,
        setReportOption,

        activatePopup,
        setActivatePopup,
        handleActivate,
        handleActivateTrue,
        handleActivateFalse,
        inActivatePopup,
        setInActivatePopup,
        handleInActivate,
        handleInActivateTrue,
        handleInActivateFalse,

        attendanceReportValue,
        attendanceReportFormValue,
        setAttendanceReportFormValue,
        attendanceReportFormError,
        setAttendanceReportFormError,
        reportIsSubmit,
        setReportIsSubmit,
        togglePopup,
        popup,
      }}
    >
      {props.children}
    </TeacherAttendanceContext.Provider>
  );
}

export default TeacherAttendanceState;
