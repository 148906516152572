import React, { useContext, useState, useEffect } from "react";
import CloseIcon from "../../images/CloseIcon.svg";
import Plus from "../../images/Plus.png";
import AcademicYearContext from "../contextfolder/AcademicYearContext/AcademicYearContext";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";

export default function AcademicYearForm({ closePopup }) {
  const context = useContext(AcademicYearContext);

  const {
    togglePopup,
    formError,
    setFormError,
    isSubmit,
    setIsSubmit,
    AcademicYearValue,
    formValue,
    setFormValue,
    setCloseChecked,
    closeChecked,
    fetchdata,
  } = context;

  const handleOnChange = () => {
    setCloseChecked(!closeChecked);
  };
  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setFormValue({ ...formValue, [name]: value });
  };

  const handleFromDate = ({ adDate }) => {
    setFormValue({ ...formValue, YearFrom: adDate });
  };
  const handleToDate = ({ adDate }) => {
    setFormValue({ ...formValue, YearTo: adDate });
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setFormError(validate(formValue));
    setIsSubmit(true);
  };
  useEffect(() => {
    if (Object.keys(formError).length === 0 && isSubmit) {
      fetchdata(formValue);
      if (closeChecked === true) {
        togglePopup();
      }
      setFormValue(AcademicYearValue);
      setIsSubmit(false);
      setCloseChecked(false);
    }
  }, [formError]);

  const validate = (values) => {
    const errors = {};
    if (!values.AcademicYear) {
      errors.AcademicYear = "Required";
    }
    if (!values.YearFrom) {
      errors.YearFrom = "Required";
    }
    if (!values.YearTo) {
      errors.YearTo = "Required";
    }

    return errors;
  };

  return (
    <>
      <div className="container-fluid basicform">
        <form>
          <div className="form-row">
            <div className="form-group col-md-12">
              <label className="form-label" htmlFor="AcademicYear">
                Academic Year<sup style={{ color: "red" }}>*</sup>
              </label>
              <input
                id="AcademicYear"
                style={{ fontSize: "13px" }}
                type="text"
                className="form-control form-control-sm "
                name="AcademicYear"
                value={formValue.AcademicYear}
                onChange={handleChange}
              />
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {formError.AcademicYear}
              </p>
            </div>

            <div className="form-group col-md-6">
              <label
                className="form-label"
                htmlFor="YearFrom"
                style={{ fontSize: "12px" }}
              >
                Year From<sup style={{ color: "red" }}>*</sup>
              </label>
              <Calendar
                className="form-control form-control-sm mb-1"
                dateFormat="YYYY/MM/DD"
                theme="default"
                language="en"
                values={formValue.YearFrom}
                onChange={handleFromDate}
              />
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {formError.YearFrom}
              </p>
            </div>
            <div className="form-group col-md-6">
              <label
                className="form-label"
                htmlFor="YearTo"
                style={{ fontSize: "12px" }}
              >
                Year To<sup style={{ color: "red" }}>*</sup>
              </label>
              <Calendar
                className="form-control form-control-sm mb-1"
                dateFormat="YYYY/MM/DD"
                theme="default"
                language="en"
                values={formValue.YearTo}
                onChange={handleToDate}
              />
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {formError.YearTo}
              </p>
            </div>
          </div>
        </form>
      </div>
      <div className="FormFooter">
        <div className="checkedClose">
          <input
            type="checkbox"
            className="form-check-input"
            id="exampleCheck2"
            name="closeChecked"
            onChange={handleOnChange}
            checked={closeChecked}
          />
          <label class="form-check-label" for="exampleCheck2">
            Exit after submit
          </label>
        </div>
        <div>
          <button
            className="btn btn-sm btn-cmpy ml-2"
            style={{ background: "green" }}
            onClick={formSubmit}
          >
            Submit
          </button>
          <button className="btn btn-sm btn-cmpy ml-2" onClick={closePopup}>
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}
