import React, { useContext, useRef } from "react";
import DataTable from "react-data-table-component";

import '../../css/Commission.css';

import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";

import StudentContext from "../contextfolder/StudentContext/StudentContext";
import { GetNepaliDate } from "../hooks/dateConvertor";



export default function NotificationTab() {
    const { customStyles } = useContext(SidebarContext);
    const { notificationList, setNotificationList, notificationLoad, notificationSearch } = useContext(StudentContext);


    const searchInput = useRef("");



    const columns = [
        {
            name: "S.N.",
            width: "63px",
            center: true,
            cell: (row, index) => index + 1,
        },
        {
            name: "Date",
            width: "120px",
            selector: (row) => GetNepaliDate(row.NotificationDate.split(" ")[0]),
        },
        {
            name: "Title",
            selector: (row) => row.Title,
        },
        {
            name: "Description",
            selector: (row) => row.Description,
        },
        {
            name: "Attachment",
            center: true,
            width: "130px",
            selector: (row) => {
                return (
                    <>
                        <div className="staffContentLogo"><div className="staffImg"><img src={row.Attachment}
                            alt=""


                        /></div></div>
                    </>
                )
            }
        },

    ];






    const searchHandler = (e) => {
        e.preventDefault();

        const srchQuery = searchInput.current.value.toLowerCase();
        if (srchQuery) {

            let srchResult = notificationSearch.filter((list) => {
                return list["Title"].toLowerCase().includes(srchQuery)
            });

            if (srchResult) {

                setNotificationList(srchResult);
            } else {
                setNotificationList({});
            }
        } else {
            setNotificationList(notificationSearch);
        }
    };





    return (


        <div className="sec-dataTable content-section main-content">

            {notificationLoad ? (
                <Spinner />
            ) : (
                <DataTable
                    columns={columns}
                    data={notificationList}
                    customStyles={customStyles}
                    pagination
                    fixedHeader
                    fixedHeaderScrollHeight="280px"
                    highlightOnHover
                    pointerOnHover
                    responsive
                    dense
                    striped
                    subHeader
                    subHeaderAlign="left"
                    subHeaderComponent={
                        <>


                            <div className="col-lg-3 offset-lg-9 offset-md-9 offset-sm-9 col-md-3 col-sm-3 align-self-end Search" style={{ paddingRight: "1px", marginBottom: "10px" }}>
                                <div className="d-flex">
                                    <input
                                        placeholder="Search"
                                        ref={searchInput}
                                        type="text"
                                        className="form-control form-control-sm searchField"
                                        onChange={searchHandler}
                                    />
                                </div>
                            </div>
                        </>
                    }
                />
            )}
        </div>

    );
}
