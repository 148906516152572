import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";
import StudyMaterialContext from "../contextfolder/StudyMaterialContext/StudyMaterialContext";
import StudyMaterialForm from "./StudyMaterialForm";
import DeletePop from "./DeletePop";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { GetNepaliDate } from "../hooks/dateConvertor";
import AcademicHeader from "../AcademicHeader";

export default function StudyMaterial() {
  const { todayDate, customStyles, AcademicYear } = useContext(SidebarContext);
  const {
    loading,
    originalList,
    popup,
    togglePopup,
    setIsSubmit,
    setStudyFormValue,
    setStudyFormError,
    classList,
    sectionList,
    studentList,
    studyValue,
    studyList,
    setStudyList,
    deletePopup,
    handleDeleteTrue,
    handleDeleteFalse,
    handleDelete,
    selectClass,
    setSelectClass,
    selectStudent,
    setSelectStudent,
    setSelectAlbum,
    selectAlbum,
    albumList,
    setCloseChecked,
    setSelectSection,
    selectSection,
    setImage,
    setIsUploaded,
    reportOption,
    setReportOption,
  } = useContext(StudyMaterialContext);

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const searchInput = useRef("");

  const columns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "Title",
      width: "250px",
      selector: (row) => row.Title,
    },
    {
      name: "Description",
      selector: (row) => row.Description,
    },
    {
      name: "File Type",
      width: "90px",
      selector: (row) =>
        row.FileType === "I"
          ? "Image"
          : row.FileType === "P"
          ? "PDF"
          : row.FileType === "PP"
          ? "PPT"
          : row.FileType === "U"
          ? "URL"
          : "DOC",
    },
    {
      name: "Created Date",
      width: "120px",
      selector: (row) => GetNepaliDate(row.CreatedDate),
    },
    {
      name: "Attachment",
      width: "110px",
      center: "true",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <Tippy content="View">
                <i
                  className="bi bi-eye  solvespan--action"
                  style={{ paddingTop: "3px" }}
                  onClick={() => openInNewTab(row.URL)}
                ></i>
              </Tippy>
            </div>
          </>
        );
      },
    },

    {
      name: "Action",
      center: true,
      width: "100px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <Tippy content="Delete">
                <i
                  className="fa fa-trash-o rejectspan--action"
                  onClick={() => handleDelete(row.MaterialID)}
                ></i>
              </Tippy>
            </div>
          </>
        );
      },
    },
  ];

  const closePopup = (e) => {
    e.preventDefault();
    togglePopup();
    setStudyFormValue(studyValue);
    setStudyFormError({});
    setIsSubmit(false);
    setCloseChecked(false);
    setImage("");
    setIsUploaded(false);
  };

  const addnew = (e) => {
    e.preventDefault();
    togglePopup();
    setIsSubmit(false);
    setStudyFormValue(studyValue);
    setCloseChecked(false);
  };

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["Title"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setStudyList(srchResult);
      } else {
        setStudyList({});
      }
    } else {
      setStudyList(originalList);
    }
  };

  const handleReport = (e) => {
    const target = e.target;
    const value = target.value;
    setReportOption(value);
    setSelectClass("");
    setSelectSection("");
    setSelectStudent("");
    setSelectAlbum("");
  };

  const handleClass = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectClass(value);
    setSelectSection("");
    setSelectStudent("");
    setSelectAlbum("");
  };

  const handleSection = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectSection(value);
    setSelectStudent("");
    setSelectAlbum("");
  };

  const handleStudent = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectStudent(value);
    setSelectAlbum("");
  };

  const handleAlbum = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectAlbum(value);
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
        <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">Study Material</div>
            <div className="page-date">
              <div className="sec-content">
                <AcademicHeader
                  todayDate={todayDate}
                  AcademicYear={AcademicYear}
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <>
          <div className="sec-dataTable content-section main-content">
            <div className="upper-dataTbl">
              <div className="btn-addlnote">
                <button
                  type="button"
                  class="btn btn-sm"
                  style={{
                    background: "var(--MainColor)",
                    color: "white",
                  }}
                  onClick={addnew}
                >
                  Add New
                </button>
              </div>
            </div>
            <hr className="new-hr-new" />
            {loading ? (
              <Spinner />
            ) : (
              <DataTable
                columns={columns}
                data={studyList}
                customStyles={customStyles}
                pagination
                fixedHeader
                fixedHeaderScrollHeight="280px"
                highlightOnHover
                pointerOnHover
                responsive
                dense
                striped
                subHeader
                subHeaderAlign="left"
                subHeaderComponent={
                  <>
                    <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                      <label className="list-label">Select Option</label>
                      <select
                        style={{ fontSize: "11px" }}
                        name="status"
                        className="form-control form-control-sm"
                        aria-label="Default select example "
                        value={reportOption}
                        onChange={handleReport}
                      >
                        <option
                          selected
                          disabled
                          value="0"
                          style={{ fontSize: "11px" }}
                        >
                          Select Option
                        </option>
                        <option value="-1">All</option>
                        <option value="C">Class</option>
                        <option value="S">Section</option>
                        <option value="I">Individual</option>
                      </select>
                      <i class="fas fa-angle-down  notificationpositon"></i>
                    </div>

                    {reportOption === "C" ||
                    reportOption === "S" ||
                    reportOption === "I" ? (
                      <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                        <label className="list-label">Class</label>
                        <select
                          style={{ fontSize: "11px" }}
                          name="snotifiaction"
                          value={selectClass}
                          onChange={handleClass}
                          className="form-control form-control-sm"
                          aria-label="Default control example "
                        >
                          <option
                            value=""
                            disabled
                            selected
                            style={{ fontSize: "11px" }}
                          >
                            Select Class
                          </option>

                          {classList.map((item) => (
                            <option key={item.ClassID} value={item.ClassID}>
                              {item.ClassName}
                            </option>
                          ))}
                        </select>
                        <i class="fas fa-angle-down  notificationpositon"></i>
                      </div>
                    ) : (
                      <> </>
                    )}
                    {reportOption === "S" || reportOption === "I" ? (
                      <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                        <label className="list-label">Section</label>
                        <select
                          style={{ fontSize: "11px" }}
                          name="snotifiaction"
                          value={selectSection}
                          onChange={handleSection}
                          className="form-control form-control-sm"
                          aria-label="Default select example "
                        >
                          <option
                            value=""
                            disabled
                            selected
                            style={{ fontSize: "11px" }}
                          >
                            Select Section
                          </option>

                          {sectionList.map((item) => (
                            <option key={item.SectionID} value={item.SectionID}>
                              {item.SectionName}
                            </option>
                          ))}
                        </select>
                        <i class="fas fa-angle-down  notificationpositon"></i>
                      </div>
                    ) : (
                      <> </>
                    )}

                    {reportOption === "I" ? (
                      <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                        <label className="list-label">Student</label>
                        <select
                          style={{ fontSize: "11px" }}
                          name="snotifiaction"
                          value={selectStudent}
                          onChange={handleStudent}
                          className="form-control form-control-sm"
                          aria-label="Default select example"
                        >
                          <option
                            value=""
                            disabled
                            selected
                            style={{ fontSize: "11px" }}
                          >
                            Select Student
                          </option>

                          {studentList.map((item) => (
                            <option key={item.StudentID} value={item.StudentID}>
                              {item.StdFullName}
                            </option>
                          ))}
                        </select>
                        <i class="fas fa-angle-down  notificationpositon"></i>
                      </div>
                    ) : (
                      <> </>
                    )}

                    <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                      <label className="list-label">Album</label>
                      <select
                        style={{ fontSize: "11px" }}
                        name="snotifiaction"
                        value={selectAlbum}
                        onChange={handleAlbum}
                        className="form-control form-control-sm"
                        aria-label="Default select example"
                      >
                        <option
                          value=""
                          disabled
                          selected
                          style={{ fontSize: "11px" }}
                        >
                          Select Album
                        </option>

                        {albumList.map((item) => (
                          <option
                            key={item.StudyAlbumID}
                            value={item.StudyAlbumID}
                          >
                            {item.Title}
                          </option>
                        ))}
                      </select>
                      <i class="fas fa-angle-down  notificationpositon"></i>
                    </div>

                    <div
                      className={
                        reportOption === "C"
                          ? "col-lg-2 offset-lg-4 col-md-2 offset-md-4 col-sm-2 align-self-end Search"
                          : reportOption === "S"
                          ? "col-lg-2 offset-lg-2 offset-md-2 offset-sm-2 col-md-2 col-sm-2 align-self-end Search"
                          : reportOption === "I"
                          ? "col-lg-2 offset-lg-0 offset-md-0 offset-sm-2 col-md-2 col-sm-2"
                          : "col-lg-2 offset-lg-6 col-md-2 offset-md-6 col-sm-2 align-self-end Search"
                      }
                      style={{
                        marginTop: "25px",
                        padding: "inherit",
                        marginBottom: "10px",
                      }}
                    >
                      <div className="d-flex">
                        <input
                          placeholder="Search"
                          ref={searchInput}
                          type="text"
                          className="form-control form-control-sm searchField"
                          onChange={searchHandler}
                        />
                      </div>
                    </div>
                  </>
                }
              />
            )}
          </div>
        </>
      </div>

      {popup && (
        <div className="modals">
          <div className="overlay"></div>

          <div className="notification-model-content">
            <div className="popUpHeader ">
              <div className="popUpHeaderText ">Study Material</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closePopup}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="inside-modal">
              <StudyMaterialForm closePopup={closePopup} />
            </div>
          </div>
        </div>
      )}

      {deletePopup.show && (
        <DeletePop
          handleDeleteTrue={handleDeleteTrue}
          handleDeleteFalse={handleDeleteFalse}
        />
      )}
    </>
  );
}
