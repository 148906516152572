import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";
import "tippy.js/dist/tippy.css";
import { Fetchdata } from "../hooks/getData";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Tippy from "@tippyjs/react";
import { GetTwelveHourFormatTime } from "../hooks/dateConvertor";

export default function StudentClass() {
  const { today, customStyles, AcademicYear, appURL } =
    useContext(SidebarContext);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);

  const [chooseDay, setChooseDay] = useState(today);

  // API for class routine list
  const [classRoutineList, setClassRoutineList] = useState([]);

  useEffect(() => {
    getClassRoutineList();
  }, [chooseDay]);

  const getClassRoutineList = () => {
    const params = {
      SchID: userinfo.SchID,
      RoutineType: "C",
      ClassID: userinfo.ClassID.toString(),
      SectionID: userinfo.SectionID.toString(),
      WeekID: chooseDay.toString(),
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/routine`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.RoutineLst ? result.RoutineLst : "";

        setLoading(false);
        setClassRoutineList(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        setClassRoutineList([]);
        setOriginalList([]);
      }
    });
  };

  const handleDay = (e) => {
    const target = e.target;
    const value = target.value;
    setChooseDay(value);
  };

  const searchInput = useRef("");

  const columns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "Period",
      width: "90px",
      // grow: 0,
      selector: (row) => row.Period,
    },
    {
      name: "Subject",
      // grow: 0,
      width: "150px",
      selector: (row) => {
        if (row.IsBreak === "E") {
          return "ECA";
        } else if (row.IsBreak === "N") {
          return row.Subject;
        } else if (row.IsBreak === "S") {
          return "Short Break";
        } else if (row.IsBreak === "L") {
          return "Long Break";
        }
      },
    },
    {
      name: "Teacher",
      selector: (row) => {
        if (row.IsBreak === "E") {
          return "ECA";
        } else if (row.IsBreak === "N") {
          return row.SubTeacher;
        } else if (row.IsBreak === "S") {
          return "Short Break";
        } else if (row.IsBreak === "L") {
          return "Long Break";
        }
      },
    },
    {
      name: "Start",
      // grow: 0,
      width: "110px",
      selector: (row) => GetTwelveHourFormatTime(row.StartTime),
    },
    {
      name: "End",
      // grow: 0,
      width: "110px",
      selector: (row) => GetTwelveHourFormatTime(row.EndTime),
    },
    {
      name: "Room No.",
      width: "120px",
      selector: (row) => {
        if (row.IsBreak === "E") {
          return "ECA";
        } else if (row.IsBreak === "N") {
          return row.RoomNo;
        } else if (row.IsBreak === "S") {
          return "Short Break";
        } else if (row.IsBreak === "L") {
          return "Long Break";
        }
      },
    },
    {
      name: "Break",
      width: "120px",
      selector: (row) =>
        row.IsBreak === "L"
          ? "Long Break"
          : row.IsBreak === "S"
          ? "Short Break"
          : row.IsBreak === "E"
          ? "ECA"
          : "Normal",
    },
  ];

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();

    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["SubTeacher"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setClassRoutineList(srchResult);
      } else {
        setClassRoutineList({});
      }
    } else {
      setClassRoutineList(originalList);
    }
  };

  /* This is for export csv */

  const dataToExcel = classRoutineList.map((d, i) => ({
    "S.N.": i + 1,
    Period: d.Period,
    Subject:
      d.IsBreak === "E"
        ? "ECA"
        : d.IsBreak === "S"
        ? "Short Break"
        : d.IsBreak === "L"
        ? "Long Break"
        : d.Subject,
    Teacher:
      d.IsBreak === "E"
        ? "ECA"
        : d.IsBreak === "S"
        ? "Short Break"
        : d.IsBreak === "L"
        ? "Long Break"
        : d.SubTeacher,
    Start: d.StartTime,
    End: d.EndTime,
    "Room No.":
      d.IsBreak === "E"
        ? "ECA"
        : d.IsBreak === "S"
        ? "Short Break"
        : d.IsBreak === "L"
        ? "Long Break"
        : d.RoomNo,
    Break:
      d.IsBreak === "L"
        ? "Long Break"
        : d.IsBreak === "S"
        ? "Short Break"
        : d.IsBreak === "E"
        ? "ECA"
        : "Normal",
  }));

  const toExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(dataToExcel);
      var wscols = [
        { wch: 5 },
        { wch: 20 },
        { wch: 20 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ];
      worksheet["!cols"] = wscols;
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "Class_Routine");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_" + "export" + EXCEL_EXTENSION
        );
      }
    });
  };

  //end

  //This is to export pdf

  const dataWithIndex = classRoutineList.map((d, i) => ({
    ...d,
    Index: i + 1,
    Subject:
      d.IsBreak === "E"
        ? "ECA"
        : d.IsBreak === "S"
        ? "Short Break"
        : d.IsBreak === "L"
        ? "Long Break"
        : d.Subject,
    Teacher:
      d.IsBreak === "E"
        ? "ECA"
        : d.IsBreak === "S"
        ? "Short Break"
        : d.IsBreak === "L"
        ? "Long Break"
        : d.SubTeacher,
    "Room No.":
      d.IsBreak === "E"
        ? "ECA"
        : d.IsBreak === "S"
        ? "Short Break"
        : d.IsBreak === "L"
        ? "Long Break"
        : d.RoomNo,
    Break:
      d.IsBreak === "L"
        ? "Long Break"
        : d.IsBreak === "S"
        ? "Short Break"
        : d.IsBreak === "E"
        ? "ECA"
        : "Normal",
  }));

  const cols = [
    { header: "S.N", field: "Index" },
    { header: "Period", field: "Period" },
    { header: "Subject", field: "Subject" },
    { header: "Teacher", field: "Teacher" },
    { header: "Start", field: "StartTime" },
    { header: "End", field: "EndTime" },
    { header: "Room No.", field: "Room No." },
    { header: "Break.", field: "Break" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const toPdf = () => {
    const doc = new jsPDF("p", "pt", "a4", true);

    var midPage = doc.internal.pageSize.getWidth() / 2;

    // doc.addImage(`${userinfo.SchPhoto}`, "JPG", 40, 40, 70, 60);

    doc.setFontSize(18);
    doc.text(`${userinfo.SchName}`, midPage, 50, null, null, "center");

    doc.setFontSize(15);
    doc.text(`${userinfo.SchAddress}`, midPage, 68, null, null, "center");

    doc.setFontSize(10);
    doc.text(
      `Tel:${userinfo.Phone}, E-mail:${userinfo.Email}`,
      midPage,
      85,
      null,
      null,
      "center"
    );

    doc.autoTable({
      startY: 100,
      theme: "grid",
      columns: exportColumns,
      body: dataWithIndex,
    });
    doc.save("Class_Routine.pdf");
  };
  //end

  // API for class routine list
  const [classPdf, setClassPdf] = useState([]);
  useEffect(() => {
    getClassPdf();
  }, []);

  const getClassPdf = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "S",
      ClassID: userinfo.ClassID.toString(),
      SectionID: userinfo.SectionID.toString(),
      RoutineType: "C",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/attach-routine`,
    };
    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.RoutineLst ? result.RoutineLst : "";
        setClassPdf(postResult);
      } else {
        setClassPdf([]);
        // toast("Error: " + result.Message, {
        //   style: {
        //     color: "red",
        //     fontSize: "13px",
        //   },
        // });
      }
    });
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <div className="sec-dataTable">
        <div className="upper-dataTbl">
          <Tippy content="PDF">
            <button className="exportButtons ml-1" onClick={toPdf}>
              <i className="bi bi-file-earmark-pdf-fill exportButtonIconsPdf"></i>
            </button>
          </Tippy>
          <Tippy content="Excel">
            <button className="exportButtons" onClick={toExcel}>
              <i className="bi bi-file-earmark-excel-fill exportButtonIconsExcel"></i>
            </button>
          </Tippy>
        </div>

        <hr className="new-hr-new" />

        {loading ? (
          <Spinner />
        ) : (
          <>
            <DataTable
              columns={columns}
              data={classRoutineList}
              customStyles={customStyles}
              pagination
              fixedHeader
              fixedHeaderScrollHeight="280px"
              highlightOnHover
              pointerOnHover
              responsive
              dense
              striped
              subHeader
              subHeaderAlign="left"
              subHeaderComponent={
                <>
                  <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                    <label className="list-label">Day</label>
                    <select
                      style={{ fontSize: "11px", marginBottom: "3px" }}
                      name="snotifiaction"
                      value={chooseDay}
                      onChange={handleDay}
                      className="form-control form-control-sm"
                      aria-label="Default select example "
                    >
                      <option
                        value=""
                        disabled
                        selected
                        style={{ fontSize: "11px" }}
                      >
                        Select Day
                      </option>
                      <option value="1">Sunday</option>
                      <option value="2">Monday</option>
                      <option value="3">Tuesday</option>
                      <option value="4">Wednesday</option>
                      <option value="5">Thursday</option>
                      <option value="6">Friday</option>
                      <option value="7">Saturday</option>
                    </select>
                    <i class="fas fa-angle-down  notificationpositon"></i>
                  </div>
                  {/* <div
                    className="col-md-1 col-sm-1 col-lg-1 dropdown"
                    style={{ padding: "0px" }}
                  >
                    {classPdf &&
                      classPdf.map((item) => (
                        <div key={item.RoutineID}>
                          <Tippy content="Routine PDF">
                            <button
                              className="exportButtons ml-1"
                              style={{ marginTop: "15px" }}
                              onClick={() => window.open(item.Attachment)}
                            >
                              <i className="bi bi-file-earmark-pdf-fill exportButtonIconsPdf"></i>
                            </button>
                          </Tippy>
                        </div>
                      ))}
                  </div> */}

                  <div
                    className="col-lg-2 offset-lg-7 offset-md-7 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
                    style={{
                      marginTop: "15px",
                      paddingRight: "1px",
                      marginBottom: "10px",
                    }}
                  >
                    <div className="d-flex">
                      <input
                        placeholder="Search"
                        ref={searchInput}
                        type="text"
                        className="form-control form-control-sm searchField"
                        onChange={searchHandler}
                      />
                    </div>
                  </div>
                </>
              }
            />
          </>
        )}
      </div>
    </>
  );
}
