import React, { useState, useEffect, useContext, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "../loading/spinner";
import { FaUser, FaBriefcase } from "react-icons/fa";
import { FiClock, FiPhone } from "react-icons/fi";
import { BsCalendar2Week, BsPen } from "react-icons/bs";
import { BiSearchAlt2, BiBriefcase } from "react-icons/bi";
import { MdClose, MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import JobContext from "../../component/FilterjobContext";
import {
  MdBusiness,
  MdLocationPin,
  MdLocationOn,
  MdOutlineAccessTimeFilled,
} from "react-icons/md";
import ListView from "./ListView";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { FetchJobdata } from "../hooks/getJobData";
import "./jobListPage.css";
const FilteredJobList = () => {
  const [originalList, setOriginalList] = useState(null);

  const [address, setAddress] = useState("");

  const navigate = useNavigate();

  const [jobType, setJobType] = useState("");
  const [workType, setWorkType] = useState("");
  const [education, setEducation] = useState("");
  const [educationLabel, setEducationLabel] = useState("");
  const [industry, setIndustry] = useState("");
  const [industryLabel, setIndustryLabel] = useState("");

  const [experience, setExperience] = useState("");
  const [district, setDistrict] = useState("");
  const [districtLabel, setDistrictLabel] = useState("");
  const [loading, setLoading] = useState(true);

  const searchInput = useRef("");

  const {
    comID,
    setSearchTerm,
    searchTerm,
    filteredJobList,
    setFilteredJobList,
    appURL,
    categoryList,
    setCategoryList,
    category,
    setCategory,
    categoryLabel,
    setCategoryLabel,
  } = useContext(JobContext);

  useEffect(() => {
    const params = {
      AuthCode: "r1d3r",
      UserID: "1",
      Flag: "CTJ",
      Keyword: "1",

      Type: "POST",
      // FetchURL: "https://esnep.com/aims/api/public/job",
      FetchURL: `${appURL}api/public/job`,
    };

    FetchJobdata(params)
      .then(function (result) {
        if (result.StatusCode === 200) {
          const postResult = result.Values ? result.Values : [];
          setOriginalList(postResult);
          setFilteredJobList(postResult);
          setLoading(false);
        } else {
          setOriginalList([]);
          setFilteredJobList([]);
          setLoading(false);
        }
      })
      .catch((err) => {
        setOriginalList([]);
        setFilteredJobList([]);
        setLoading(false);
      });
  }, [
    searchTerm,
    address,
    district,
    workType,
    education,
    industry,
    category,
    jobType,
    experience,
  ]);

  const handleSearch = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    setSearchTerm(srchQuery);
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["Designation"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setFilteredJobList(srchResult);
      } else {
        setFilteredJobList({});
      }
    } else {
      setFilteredJobList(originalList);
    }
  };

  // Inspired by blueprintjs
  function BpRadio(props) {
    return (
      <Radio
        disableRipple
        color="default"
        checkedIcon={<MdCheckBox color="#0145a2" />}
        icon={<MdCheckBoxOutlineBlank />}
        {...props}
      />
    );
  }
  return (
    <>
      <div className="jobListPage">
        <div className="uk-container mt-5">
          <div>
            <div>
              {loading ? (
                <Spinner />
              ) : (
                <div style={{ flexGrow: "1" }}>
                  <div className="jobListPage mt-5">
                    <div>
                      {filteredJobList.length !== 0 ? (
                        <div className="jobList">
                          <div
                            className="jobListCards mt-0 pb-5 uk-child-width-1-2@m uk-grid-medium uk-grid"
                            data-uk-grid
                          >
                            <ListView jobList={filteredJobList} />
                          </div>
                        </div>
                      ) : (
                        <p className="no-job">No jobs available</p>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FilteredJobList;
