import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";
import { Fetchdata } from "../hooks/getData";

export default function ExtraView() {
  const { customStyles, appURL } = useContext(SidebarContext);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const searchInput = useRef("");
  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);
  const [extraList, setExtraList] = useState([]);

  useEffect(() => {
    getExtraList();
  }, []);

  const getExtraList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      Flag: "SC",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/act-input`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.ExtraActLst ? result.ExtraActLst : "";
        setLoading(false);
        setExtraList(postResult);
        setOriginalList(postResult);
      } else {
        setExtraList([]);
        setLoading(false);
        setOriginalList([]);
      }
    });
  };

  const columns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "Extra Activity",
      selector: (row) => row.ExtraActivity,
    },
  ];

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["ExtraActivity"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setExtraList(srchResult);
      } else {
        setExtraList({});
      }
    } else {
      setExtraList(originalList);
    }
  };

  return (
    <>
      <div className="sec-dataTable">
        {loading ? (
          <Spinner />
        ) : (
          <DataTable
            columns={columns}
            data={extraList}
            customStyles={customStyles}
            pagination
            fixedHeader
            fixedHeaderScrollHeight="280px"
            highlightOnHover
            pointerOnHover
            responsive
            dense
            striped
            subHeader
            subHeaderAlign="left"
            subHeaderComponent={
              <>
                <div
                  className="col-lg-2 offset-lg-10 offset-md-10 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
                  style={{ paddingRight: "1px", marginBottom: "10px" }}
                >
                  <div className="d-flex">
                    <input
                      placeholder="Search"
                      ref={searchInput}
                      type="text"
                      className="form-control form-control-sm searchField"
                      onChange={searchHandler}
                    />
                  </div>
                </div>
              </>
            }
          />
        )}
      </div>
    </>
  );
}
