import { useContext, useEffect, useState } from "react";
import { Fetchdata } from "../../hooks/getData";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import DeviceCodeContext from "./DeviceCodeContext";
import { toast } from "react-toastify";

const DeviceCodeState = (props) => {
  const { appURL } = useContext(SidebarContext);
  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const [deviceCode, setDeviceCode] = useState([]);

  useEffect(() => {
    getDeviceCodeList();
  }, []);

  const getDeviceCodeList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/user-attendance`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.AttenLst ? result.AttenLst : "";
        setLoading(false);
        setDeviceCode(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        setDeviceCode([]);
        setOriginalList([]);
      }
    });
  };

  // The newArr array will now contain the same objects as the deviceCode array,
  // but with the FullName property removed from each object.
  const storeRemove = ["FullName"];
  const newArr = deviceCode.map((obj) => {
    const newObj = { ...obj };
    storeRemove.forEach((key) => delete newObj[key]);
    return newObj;
  });

  const handleSave = () => {
    const dataForm = {
      Flag: "i",
      DFlag: "E",

      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Param: newArr,
      Flag: "I",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/user-attendance`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getDeviceCodeList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };
  return (
    <DeviceCodeContext.Provider
      value={{
        loading,
        setLoading,
        deviceCode,
        setDeviceCode,
        getDeviceCodeList,
        handleSave,
        originalList,
        setOriginalList,
      }}
    >
      {props.children}
    </DeviceCodeContext.Provider>
  );
};

export default DeviceCodeState;
