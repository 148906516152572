import React, { useContext, useEffect, useState } from "react";
import { Fetchdata } from "../../hooks/getData";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { toast } from "react-toastify";
import ReportContext from "./ReportContext";

function ReportState(props) {
  const { appURL } = useContext(SidebarContext);

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const [classList, setClassList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [termList, setTermList] = useState([]);

  const [selectClass, setSelectClass] = useState("-1");
  const [selectSection, setSelectSection] = useState("-1");
  const [selectTerm, setSelectTerm] = useState("1");
  const [selectType, setSelectType] = useState("G");
  const [selectStatus, setSelectStatus] = useState("-1");

  useEffect(() => {
    getClassList();
  }, []);

  const getClassList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      DFlag: "C",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setLoading(false);
        setClassList(postResult);
      } else {
        setClassList([]);
      }
    });
  };

  useEffect(() => {
    getSectionList();
  }, [selectClass]);

  const getSectionList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      ClassID: selectClass,
      DFlag: "S",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";

        setSectionList(postResult);
      } else {
        setSectionList([]);
      }
    });
  };

  useEffect(() => {
    getTermList();
  }, []);

  const getTermList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      DFlag: "T",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";

        setLoading(false);
        setTermList(postResult);
      } else {
        setTermList([]);
      }
    });
  };

  // API for report list
  const [reportList, setReportList] = useState([]);

  useEffect(() => {
    getreportList();
  }, [selectClass, selectSection, selectTerm, selectStatus]);

  const getreportList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      Flag: "S",
      ReportType: userinfo.MarksSheetType,
      ClassID: selectClass,
      SectionID: selectSection,
      TermID: selectTerm,
      IsAllow: selectStatus,
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/report-card`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.ReportCardLst ? result.ReportCardLst : "";
        setLoading(false);

        setReportList(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        setReportList([]);
        setOriginalList([]);
      }
    });
  };

  //API to hit Agree/Disagree status

  const deactivateAgree = (ID, IsActive) => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      ClassID: selectClass,
      SectionID: selectSection,
      TermID: selectTerm,
      Flag: "AI",
      ReportID: ID,
      ReportType: userinfo.MarksSheetType,
      IsAllow: IsActive,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/report-card`,
      Type: "POST",
    };

    if (IsActive === "Y") {
      dataForm.IsAllow = "N";
    } else {
      dataForm.IsAllow = "Y";
    }
    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getreportList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };

  return (
    <ReportContext.Provider
      value={{
        loading,
        originalList,
        reportList,
        setReportList,
        classList,
        sectionList,
        selectClass,
        setSelectClass,
        selectSection,
        setSelectSection,
        selectTerm,
        setSelectTerm,
        termList,
        selectType,
        setSelectType,
        selectStatus,
        setSelectStatus,
        deactivateAgree,
      }}
    >
      {props.children}
    </ReportContext.Provider>
  );
}

export default ReportState;
