import React, { useContext, useEffect, useState } from "react";
import SidebarContext from "../sidebarcontext/SidebarContext";
import { Fetchdata } from "../hooks/getData";
import CloseIcon from "../../images/CloseIcon.svg";
import Select from "react-select";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import { getDisableDate } from "../hooks/dateConvertor";
import QuickAssignmentContext from "../contextfolder/QuickAssignmentContext/QuickAssignmentContext";

export default function AssignmentForm({ closePopup }) {
  const { appURL } = useContext(SidebarContext);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const context = useContext(QuickAssignmentContext);

  const {
    togglePopup,
    assignmentFormError,
    setAssignmentFormError,
    isSubmit,
    setIsSubmit,
    assignmentValue,
    assignmentFormValue,
    setAssignmentFormValue,
    fetchdata,
    closeChecked,
    setCloseChecked,

    section,
    setSection,
    inputFields,
    setInputFields,
  } = context;

  const handleOnChange = () => {
    setCloseChecked(!closeChecked);
  };

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    setAssignmentFormValue({ ...assignmentFormValue, [name]: value });
  };

  const handleAssignmentDate = ({ adDate }) => {
    setAssignmentFormValue({ ...assignmentFormValue, assignmentDate: adDate });
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setAssignmentFormError(validate(assignmentFormValue));
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(assignmentFormError).length === 0 && isSubmit) {
      fetchdata(assignmentFormValue);
      if (closeChecked === true) {
        togglePopup();
      }
      setAssignmentFormValue(assignmentValue);
      setInputFields([]);
      setIsSubmit(false);
    }
  }, [assignmentFormError]);

  const validate = (values) => {
    const errors = {};

    const numv = /[+-]?([0-9]*[.])?[0-9]+/;
    const digits = /\b(0|[1-9][0-9]?|9999)\b/;

    if (!values.class) {
      errors.class = "Required";
    }
    if (userinfo.UserType !== "T") {
      if (!values.teacher) {
        errors.teacher = "Required";
      }
    }
    if (!values.subject) {
      errors.subject = "Required";
    }
    if (!values.assignmentDate) {
      errors.assignmentDate = "Required";
    }

    return errors;
  };

  //API for class list
  const [classList, setClassList] = useState([]);
  useEffect(() => {
    getClassList();
  }, []);

  const getClassList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID.toString() : "-1",
      DFlag: "C",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setClassList(postResult);
      } else {
        setClassList([]);
      }
    });
  };

  //API for Section List
  const [sectionList, setSectionList] = useState([]);
  useEffect(() => {
    getSectionList();
  }, [assignmentFormValue.class]);

  const getSectionList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID.toString() : "-1",
      ClassID: assignmentFormValue.class,
      DFlag: "S",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";

        setSectionList(postResult);
      } else {
        setSectionList([]);
      }
    });
  };

  //API for teacher list
  const [teacherList, setTeacherList] = useState([]);

  useEffect(() => {
    teachList();
  }, [assignmentFormValue.class, assignmentFormValue.section]);

  const teachList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID.toString() : "-1",
      DFlag: "TL",
      Flag: "S",
      ClassID: assignmentFormValue.class,
      SectionID: assignmentFormValue.section,
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setTeacherList(postResult);
      } else {
        setTeacherList([]);
      }
    });
  };

  //API for subject list
  const [subjectList, setSubjectList] = useState([]);

  useEffect(() => {
    getSubjectList();
  }, [
    assignmentFormValue.class,
    assignmentFormValue.teacher,
    assignmentFormValue.section,
    section,
  ]);

  const getSubjectList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID:
        userinfo.UserType === "T"
          ? userinfo.UserID.toString()
          : assignmentFormValue.teacher,
      ClassID: assignmentFormValue.class,
      SectionID: assignmentFormValue.section,
      DFlag: "SB",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    const paramsA = {
      SchID: userinfo.SchID,
      UserID:
        userinfo.UserType === "T"
          ? userinfo.UserID.toString()
          : assignmentFormValue.teacher,
      ClassID: assignmentFormValue.class,
      SectionIDs: section,
      DFlag: "SB",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(userinfo.UserType === "A" ? params : paramsA).then(function (
      result
    ) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";

        setSubjectList(postResult);
      } else {
        setSubjectList([]);
      }
    });
  };

  const [studentList, setStudentList] = useState([]);

  useEffect(() => {
    stdList();
  }, [assignmentFormValue.class, assignmentFormValue.section]);

  const stdList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      Flag: "S",
      ClassID: assignmentFormValue.class,
      SectionID:
        userinfo.UserType === "T"
          ? section
          : [
              {
                SectionID: assignmentFormValue.section,
              },
            ],
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/student`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.StudentLst ? result.StudentLst : "";
        setStudentList(postResult);
      } else {
        setStudentList([]);
      }
    });
  };

  const dropDownValue = sectionList.map((item) => ({
    value: item.SectionID,
    label: item.SectionName,
  }));

  const handleAssign = (e, studentID) => {
    // Destructuring
    const { value, checked } = e.target;

    // Case 1 : The user checks the box
    if (checked) {
      inputFields.push({
        UserID: studentID,
        AssignStatus: "Y",
      });
    }

    // Case 2  : The user unchecks the box
    else {
      setInputFields(
        inputFields.filter((list) => list.UserID !== studentID)
      );
    }
  };
  return (
    <>
      <div className="container-fluid basicform">
        <form>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label className="form-label" htmlFor="class">
                Class<sup style={{ color: "red" }}>*</sup>
              </label>
              <select
                id="class"
                name="class"
                onChange={handleChange}
                value={assignmentFormValue.class}
                className="form-select form-select-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="" disabled selected style={{ fontSize: "11px" }}>
                  Select Class
                </option>
                {classList.map((item) => (
                  <option key={item.ClassID} value={item.ClassID}>
                    {item.ClassName}
                  </option>
                ))}
              </select>
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {assignmentFormError.class}
              </p>
            </div>

            <div className="form-group col-md-6">
              <label className="form-label" htmlFor="section">
                Section<sup style={{ color: "red" }}>*</sup>
              </label>
              {userinfo.UserType === "T" ? (
                <Select
                  className="select"
                  style={{ fontSize: "11px", marginBottom: "3px" }}
                  isMulti
                  options={dropDownValue}
                  onChange={(item) =>
                    setSection(
                      item.map((item) => ({
                        SectionID: item.value,
                      }))
                    )
                  }
                  isSearchable={false}
                />
              ) : (
                <select
                  id="sections"
                  name="section"
                  onChange={handleChange}
                  value={assignmentFormValue.section}
                  className="form-select form-select-sm mb-1"
                  style={{ fontSize: "12px" }}
                >
                  <option value="" selected disabled>
                    Select Option
                  </option>
                  {sectionList.map((item, i) => (
                    <option key={i} value={item.SectionID}>
                      {item.SectionName}
                    </option>
                  ))}
                </select>
              )}
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {assignmentFormError.section}
              </p>
            </div>
          </div>

          <div className="form-row">
            {userinfo.UserType !== "T" && (
              <div className="form-group col-md-6">
                <label className="form-label" htmlFor="teachers">
                  Teacher<sup style={{ color: "red" }}>*</sup>
                </label>
                <select
                  id="teachers"
                  name="teacher"
                  onChange={handleChange}
                  value={assignmentFormValue.teacher}
                  className="form-select form-select-sm mb-1"
                  style={{ fontSize: "12px" }}
                >
                  <option
                    value=""
                    disabled
                    selected
                    style={{ fontSize: "11px" }}
                  >
                    Select Teacher
                  </option>
                  {teacherList.map((item) => (
                    <option key={item.UserID} value={item.UserID}>
                      {item.FullName}
                    </option>
                  ))}
                </select>
                <p className="errormsg " style={{ marginBottom: "0" }}>
                  {assignmentFormError.teacher}
                </p>
              </div>
            )}

            <div
              className={
                userinfo.UserType === "T"
                  ? "form-group col-md-12"
                  : "form-group col-md-6"
              }
            >
              <label className="form-label" htmlFor="subject">
                Subject<sup style={{ color: "red" }}>*</sup>
              </label>
              <select
                id="subject"
                name="subject"
                onChange={handleChange}
                value={assignmentFormValue.subject}
                className="form-select form-select-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="" disabled selected style={{ fontSize: "11px" }}>
                  Select Subject
                </option>
                {subjectList.map((item) => (
                  <option key={item.SubjectID} value={item.SubjectID}>
                    {item.SubjectName}
                  </option>
                ))}
              </select>
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {assignmentFormError.subject}
              </p>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-6">
              <label
                className="form-label"
                htmlFor="assignmentDate"
                style={{ fontSize: "12px" }}
              >
                Assignment Date<sup style={{ color: "red" }}>*</sup>
              </label>
              <Calendar
                className="form-control form-control-sm mb-1"
                dateFormat="YYYY/MM/DD"
                theme="default"
                language="en"
                values={assignmentFormValue.assignmentDate}
                onChange={handleAssignmentDate}
                minDate={getDisableDate()}
              />
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {assignmentFormError.assignmentDate}
              </p>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-6">
              <div className="d-flex">
                <input
                  type="checkbox"
                  // className="form-check-input"
                  id="exampleCheck5"
                  name="notifyUser"
                  onChange={handleChange}
                  checked={assignmentFormValue.notifyUser}
                />
                <label
                  class="form-check-label"
                  for="exampleCheck5"
                  style={{ fontSize: "12px", paddingLeft: "3px" }}
                >
                  Notify User
                </label>
              </div>
            </div>
          </div>
        </form>

        <div className="sec-dataTable" style={{ marginTop: "10px" }}>
          <>
            {studentList.length > 0 ? (
              <div className="table-responsive br-3">
                <table className="table table-bordereds text-nowrap mb-0">
                  <thead>
                    <tr className="tableHead">
                      <td className="tc" style={{ width: "20px" }}>
                        S.N
                      </td>

                      <td className="tl">Full Name</td>
                      <td className="tc" style={{ width: "100px" }}>
                        Status
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {studentList.map((student, i) => (
                      <tr key={i}>
                        <td className="tc" style={{ width: "70px" }}>
                          {i + 1}
                        </td>

                        <td className="tl">{student.StdFullName}</td>

                        <td className="tc">
                          <input
                            type="checkbox"
                            id="assignCheck"
                            name="assignCheck"
                            onChange={(e) => handleAssign(e, student.StudentID)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="table-responsive br-3">
                <div className="text-center mt-4">
                  <p>There are no records to display.</p>
                </div>
              </div>
            )}
          </>
        </div>
      </div>
      <div className="FormFooter">
        <div className="checkedClose">
          <input
            type="checkbox"
            className="form-check-input"
            id="exampleCheck2"
            name="closeChecked"
            onChange={handleOnChange}
            checked={closeChecked}
          />
          <label class="form-check-label" for="exampleCheck2">
            Exit after submit
          </label>
        </div>
        <div>
          <button
            className="btn btn-sm btn-cmpy ml-2"
            style={{ background: "green" }}
            onClick={formSubmit}
          >
            Submit
          </button>
          <button className="btn btn-sm btn-cmpy ml-2" onClick={closePopup}>
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}
