import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";
import OnlineClassContext from "../contextfolder/OnlineClassContext/OnlineClassContext";
import OnlineClassForm from "./OnlineClassForm";
import DeletePop from "./DeletePop";
import ActivatePop from "./ActivatePop";
import InactivatePop from "./InactivatePop";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import AcademicHeader from "../AcademicHeader";

export default function OnlineClass() {
  const { todayDate, customStyles, AcademicYear } = useContext(SidebarContext);
  const {
    loading,
    originalList,
    popup,
    togglePopup,
    setIsSubmit,
    setOnlineFormValue,
    setOnlineFormError,
    classList,
    sectionList,
    teacherList,
    subjectList,
    onlineValue,
    onlineList,
    setOnlineList,
    deletePopup,
    handleDeleteTrue,
    handleDeleteFalse,
    handleDelete,
    selectClass,
    setSelectClass,
    selectTeacher,
    setSelectTeacher,
    selectSubject,
    setSelectSubject,
    selectSection,
    setSelectSection,
    activatePopup,
    handleActivateTrue,
    handleActivateFalse,
    handleActivate,
    inActivatePopup,
    handleInActivateTrue,
    handleInActivateFalse,
    handleInActivate,
  } = useContext(OnlineClassContext);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const searchInput = useRef("");

  const columns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "Tool",
      width: "140px",
      selector: (row) =>
        row.Tool === "G"
          ? "Google Meet"
          : row.Tool === "T"
          ? "Microsoft Team"
          : row.Tool === "M"
          ? "Messenger"
          : row.Tool === "Z"
          ? "Zoom"
          : row.Tool === "J"
          ? "Jitsi"
          : "",
    },
    {
      name: "Subject",
      width: "150px",
      selector: (row) => row.Subject,
    },
    {
      name: "Teacher",
      selector: (row) => row.Teacher,
    },
    {
      name: "Status",
      // grow: 0,
      center: true,
      width: "120px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              {row.IsActive === "N" ? (
                <>
                  <button
                    type="button"
                    class="badge badge-success"
                    onClick={() => handleActivate(row)}
                  >
                    Activate
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    class="badge badge-danger"
                    onClick={() => handleInActivate(row)}
                  >
                    Deactivate
                  </button>
                </>
              )}
            </div>
          </>
        );
      },
    },

    {
      name: "Action",
      // grow: 0,
      center: true,
      width: "100px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex" disabled={row.IsActive === "Y"}>
              {/* <button
                                type="button"
                                class="btn btn-sm editspan"
                            // disabled={row.IsActive === "N"}
                            // onClick={() => handleDelete(row.OnlineClassID)}
                            >
                                Start
                            </button> */}
              <Tippy content="Start">
                <i
                  className={
                    row.IsActive === "N"
                      ? "bi bi-arrow-right-circle solvespan--action--disabled"
                      : "bi bi-arrow-right-circle solvespan--action"
                  }
                  onClick={() => openInNewTab(row.MeetingUrl)}
                  style={{ paddingTop: "3px" }}
                ></i>
              </Tippy>

              {/* <button
                                type="button"
                                class="btn btn-sm rejectspan"
                                disabled={row.IsActive === "N"}
                                onClick={() => handleDelete(row.OnlineClassID)}
                            >
                                Remove
                            </button> */}
              <Tippy content="Delete">
                <i
                  className={
                    row.IsActive === "N"
                      ? "fa fa-trash-o rejectspan--action--disabled"
                      : "fa fa-trash-o rejectspan--action"
                  }
                  onClick={() => handleDelete(row.OnlineClassID)}
                ></i>
              </Tippy>
            </div>
          </>
        );
      },
    },
  ];

  const closePopup = (e) => {
    e.preventDefault();
    togglePopup();
    setOnlineFormValue(onlineValue);
    setOnlineFormError({});
    setIsSubmit(false);
  };

  const addnew = (e) => {
    e.preventDefault();
    togglePopup();
    setIsSubmit(false);
    setOnlineFormValue(onlineValue);
  };

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["Subject"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setOnlineList(srchResult);
      } else {
        setOnlineList({});
      }
    } else {
      setOnlineList(originalList);
    }
  };

  const handleClass = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectClass(value);
    setSelectSection("");
    setSelectSubject("");
    setSelectTeacher("");
  };

  const handleSubject = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectSubject(value);
  };

  const handleTeacher = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectTeacher(value);
    setSelectSubject("");
  };

  const handleSection = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectSection(value);
    setSelectSubject("");
    setSelectTeacher("");
  };

  /* This is for export csv */

  const dataToExcel = onlineList.map((d, i) => ({
    "S.N.": i + 1,
    Tool:
      d.Tool === "G"
        ? "Google Meet"
        : d.Tool === "T"
        ? "Microsoft Team"
        : d.Tool === "M"
        ? "Messenger"
        : d.Tool === "Z"
        ? "Zoom"
        : d.Tool === "J"
        ? "Jitsi"
        : "",
    Subject: d.Subject,
    Teacher: d.Teacher,
  }));

  const toExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(dataToExcel);
      var wscols = [{ wch: 5 }, { wch: 20 }, { wch: 20 }, { wch: 15 }];
      worksheet["!cols"] = wscols;
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "Online_Class");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_" + "export" + EXCEL_EXTENSION
        );
      }
    });
  };

  //end

  //This is to export pdf

  const dataWithIndex = onlineList.map((d, i) => ({
    ...d,
    Index: i + 1,
    Tool:
      d.Tool === "G"
        ? "Google Meet"
        : d.Tool === "T"
        ? "Microsoft Team"
        : d.Tool === "M"
        ? "Messenger"
        : d.Tool === "Z"
        ? "Zoom"
        : d.Tool === "J"
        ? "Jitsi"
        : "",
  }));

  const cols = [
    { header: "S.N", field: "Index" },
    { header: "Tool", field: "Tool" },
    { header: "Subject", field: "Subject" },
    { header: "Teacher", field: "Teacher" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const toPdf = () => {
    const doc = new jsPDF("p", "pt", "a4", true);

    var midPage = doc.internal.pageSize.getWidth() / 2;

    // doc.addImage(`${userinfo.SchPhoto}`, "JPG", 40, 40, 70, 60);

    doc.setFontSize(18);
    doc.text(`${userinfo.SchName}`, midPage, 50, null, null, "center");

    doc.setFontSize(15);
    doc.text(`${userinfo.SchAddress}`, midPage, 68, null, null, "center");

    doc.setFontSize(10);
    doc.text(
      `Tel:${userinfo.Phone}, E-mail:${userinfo.Email}`,
      midPage,
      85,
      null,
      null,
      "center"
    );

    doc.autoTable({
      startY: 100,
      theme: "grid",
      columns: exportColumns,
      body: dataWithIndex,
    });
    doc.save("Online_Class.pdf");
  };
  //end

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
        <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">Online Class</div>
            <div className="page-date">
              <div className="sec-content">
                <AcademicHeader
                  todayDate={todayDate}
                  AcademicYear={AcademicYear}
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <>
          <div className="sec-dataTable content-section main-content">
            <div className="upper-dataTbl">
              <div className="btn-addlnote">
                <button
                  type="button"
                  class="btn btn-sm"
                  style={{
                    background: "var(--MainColor)",
                    color: "white",
                  }}
                  onClick={addnew}
                >
                  Add New
                </button>
                <div className="exportDiv">
                  <Tippy content="Excel">
                    <button className="exportButtons mx-2" onClick={toExcel}>
                      <i className="bi bi-file-earmark-excel-fill exportButtonIconsExcel"></i>
                    </button>
                  </Tippy>

                  <Tippy content="PDF">
                    <button className="exportButtons " onClick={toPdf}>
                      <i className="bi bi-file-earmark-pdf-fill exportButtonIconsPdf"></i>
                    </button>
                  </Tippy>
                </div>
              </div>
            </div>
            <hr className="new-hr-new" />

            {loading ? (
              <Spinner />
            ) : (
              <DataTable
                columns={columns}
                data={onlineList}
                customStyles={customStyles}
                pagination
                fixedHeader
                fixedHeaderScrollHeight="280px"
                highlightOnHover
                pointerOnHover
                responsive
                dense
                striped
                subHeader
                subHeaderAlign="left"
                subHeaderComponent={
                  <>
                    <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                      <label className="list-label">Class</label>
                      <select
                        style={{ fontSize: "11px" }}
                        name="snotifiaction"
                        value={selectClass}
                        onChange={handleClass}
                        className="form-control form-control-sm"
                        aria-label="Default control example "
                      >
                        <option
                          value=""
                          disabled
                          selected
                          style={{ fontSize: "11px" }}
                        >
                          Select Class
                        </option>

                        {classList.map((item) => (
                          <option key={item.ClassID} value={item.ClassID}>
                            {item.ClassName}
                          </option>
                        ))}
                      </select>
                      <i class="fas fa-angle-down  notificationpositon"></i>
                    </div>

                    <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                      <label className="list-label">Section</label>
                      <select
                        style={{ fontSize: "11px" }}
                        name="snotifiaction"
                        value={selectSection}
                        onChange={handleSection}
                        className="form-control form-control-sm"
                        aria-label="Default select example "
                      >
                        <option
                          value=""
                          disabled
                          selected
                          style={{ fontSize: "11px" }}
                        >
                          Select Section
                        </option>

                        {sectionList.map((item) => (
                          <option key={item.SectionID} value={item.SectionID}>
                            {item.SectionName}
                          </option>
                        ))}
                      </select>
                      <i class="fas fa-angle-down  notificationpositon"></i>
                    </div>

                    {userinfo.UserType !== "T" && (
                      <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                        <label className="list-label">Teacher</label>
                        <select
                          style={{ fontSize: "11px" }}
                          name="snotifiaction"
                          value={selectTeacher}
                          onChange={handleTeacher}
                          className="form-control form-control-sm"
                          aria-label="Default select example"
                        >
                          <option
                            value=""
                            disabled
                            selected
                            style={{ fontSize: "11px" }}
                          >
                            Select Teacher
                          </option>

                          {teacherList.map((item) => (
                            <option key={item.UserID} value={item.UserID}>
                              {item.FullName}
                            </option>
                          ))}
                        </select>
                        <i class="fas fa-angle-down  notificationpositon"></i>
                      </div>
                    )}

                    <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                      <label className="list-label">Subject</label>
                      <select
                        style={{ fontSize: "11px" }}
                        name="snotifiaction"
                        value={selectSubject}
                        onChange={handleSubject}
                        className="form-control form-control-sm"
                        aria-label="Default select example"
                      >
                        <option
                          value=""
                          disabled
                          selected
                          style={{ fontSize: "11px" }}
                        >
                          Select Subject
                        </option>

                        {subjectList.map((item) => (
                          <option key={item.SubjectID} value={item.SubjectID}>
                            {item.SubjectName}
                          </option>
                        ))}
                      </select>
                      <i class="fas fa-angle-down  notificationpositon"></i>
                    </div>

                    <div
                      className={
                        userinfo.UserType === "T"
                          ? "col-lg-2 offset-lg-4 offset-md-2 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
                          : "col-lg-2 offset-lg-2 offset-md-2 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
                      }
                      style={{
                        marginTop: "25px",
                        paddingRight: "1px",
                        marginBottom: "10px",
                      }}
                    >
                      <div className="d-flex">
                        <input
                          placeholder="Search"
                          ref={searchInput}
                          type="text"
                          className="form-control form-control-sm searchField"
                          onChange={searchHandler}
                        />
                      </div>
                    </div>
                  </>
                }
              />
            )}
          </div>
        </>
      </div>

      {popup && (
        <div className="modals">
          <div className="overlay"></div>

          <div className="notification-model-content">
            <div className="popUpHeader ">
              <div className="popUpHeaderText ">Online Class</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closePopup}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="inside-modal">
              <OnlineClassForm closePopup={closePopup} />
            </div>
          </div>
        </div>
      )}

      {deletePopup.show && (
        <DeletePop
          handleDeleteTrue={handleDeleteTrue}
          handleDeleteFalse={handleDeleteFalse}
        />
      )}

      {activatePopup.show && (
        <ActivatePop
          handleActivateTrue={handleActivateTrue}
          handleActivateFalse={handleActivateFalse}
        />
      )}
      {inActivatePopup.show && (
        <InactivatePop
          handleInActivateTrue={handleInActivateTrue}
          handleInActivateFalse={handleInActivateFalse}
        />
      )}
    </>
  );
}
