import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import SidebarContext from "../sidebarcontext/SidebarContext";
import Spinner from "../loading/spinner";
import SliderContext from "../contextfolder/SliderContext/SliderContext";
import AcademicForm from "./AcademicForm";
import AcademicEdit from "./AcademicEdit";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import AcademicHeader from "../AcademicHeader";

export default function AcademicList() {
  const { todayDate, customStyles, AcademicYear, hideAction } =
    useContext(SidebarContext);

  const {
    loading,
    academicSearch,
    modal,
    toggleModal,
    setAcademicFormValue,
    setAcademicFormError,
    setAcademicIsSubmit,
    academicValue,
    setAcademicList,
    academicList,
    editModal,
    toggleEditModal,
    setEditIsSubmit,
    handleEdit,
    deactivateAgree,
    setActive,
    setCloseChecked,
  } = useContext(SliderContext);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const closePopup = (e) => {
    e.preventDefault();
    toggleModal();
    setAcademicFormValue(academicValue);
    setAcademicFormError({});
    setAcademicIsSubmit(false);
    setActive(false);
    setCloseChecked(false);
  };
  const closeEditPop = (e) => {
    e.preventDefault();
    toggleEditModal();
    setAcademicFormError({});
    setEditIsSubmit(false);
    setCloseChecked(false);
  };

  const addnew = (e) => {
    e.preventDefault();
    toggleModal();
    setAcademicIsSubmit(false);
    setAcademicFormValue(academicValue);
  };

  const searchInput = useRef("");

  const columns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "Academic Year",
      selector: (row) => row.AcademicYear,
    },
    {
      name: "From",
      width: "90px",
      selector: (row) => row.YearFrom,
    },
    {
      name: "To",
      width: "90px",
      selector: (row) => row.YearTo,
    },
    {
      name: "Status",
      width: "105px",
      selector: (row) => (row.IsActive === "Y" ? "Activated" : "Deactivated"),
    },
    {
      name: "Action",
      center: true,
      width: "100px",
      omit: hideAction,
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <Tippy content="Edit">
                <i
                  data-toggle="tooltip"
                  data-html="true"
                  title="<em>Tooltip</em> <u>with</u> <b>HTML</b>"
                  className="fa fa-pencil solvespan--action"
                  onClick={() => handleEdit(row)}
                ></i>
              </Tippy>

              {/* <button
                                type="button"
                                class="btn btn-sm rejectspan"
                                style={{ width: "70px" }}
                                onClick={() => changeAgree(row.AcademicYear, row.IsActive)}
                            >
                                {checkAgree(row.IsActive)}
                            </button> */}

              {row.IsActive === "Y" ? (
                <Tippy content="Activated">
                  <i
                    className="fa fa-toggle-on toggle--button--action"
                    onClick={() => changeAgree(row.AcademicYear, row.IsActive)}
                  ></i>
                </Tippy>
              ) : (
                <Tippy content="Deactivated">
                  <i
                    className="fa fa-toggle-off toggle--button--action"
                    onClick={() => changeAgree(row.AcademicYear, row.IsActive)}
                  ></i>
                </Tippy>
              )}
            </div>
          </>
        );
      },
    },
  ];

  // For change agree and disagree

  const changeAgree = (repID, IsOK) => {
    deactivateAgree(repID, IsOK);
  };

  const checkAgree = (IsOK) => {
    if (IsOK === "Y") {
      return "Deactive";
    } else if (IsOK === "N") {
      return "Active";
    }
  };

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = academicSearch.filter((list) => {
        return list["AcademicYear"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setAcademicList(srchResult);
      } else {
        setAcademicList({});
      }
    } else {
      setAcademicList(academicSearch);
    }
  };

  /* This is for export csv */

  const dataToExcel = academicList.map((d, i) => ({
    "S.N.": i + 1,
    "Academic Year": d.AcademicYear,
    From: d.YearFrom,
    To: d.YearTo,
    Status: d.IsActive === "Y" ? "Activated" : "Deactivated",
  }));

  const toExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(dataToExcel);
      var wscols = [{ wch: 5 }, { wch: 20 }, { wch: 20 }, { wch: 30 }];
      worksheet["!cols"] = wscols;
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "Academic");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_" + "export" + EXCEL_EXTENSION
        );
      }
    });
  };

  //end

  //This is to export pdf

  const dataWithIndex = academicList.map((d, i) => ({
    ...d,
    Index: i + 1,
    Status: d.IsActive === "Y" ? "Activated" : "Deactivated",
  }));

  const cols = [
    { header: "S.N", field: "Index" },
    { header: "Academic Year", field: "AcademicYear" },
    { header: "From", field: "YearFrom" },
    { header: "To", field: "YearTo" },
    { header: "Status", field: "Status" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const toPdf = () => {
    const doc = new jsPDF("p", "pt", "a4", true);

    var midPage = doc.internal.pageSize.getWidth() / 2;

    // doc.addImage(`${userinfo.SchPhoto}`, "JPG", 40, 40, 70, 60);

    doc.setFontSize(18);
    doc.text(`${userinfo.SchName}`, midPage, 50, null, null, "center");

    doc.setFontSize(15);
    doc.text(`${userinfo.SchAddress}`, midPage, 68, null, null, "center");

    doc.setFontSize(10);
    doc.text(
      `Tel:${userinfo.Phone}, E-mail:${userinfo.Email}`,
      midPage,
      85,
      null,
      null,
      "center"
    );

    doc.autoTable({
      startY: 100,
      theme: "grid",
      columns: exportColumns,
      body: dataWithIndex,
    });
    doc.save("Academic.pdf");
  };
  //end

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
        <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">Academic</div>
            <div className="page-date">
              <div className="sec-content">
                <AcademicHeader
                  todayDate={todayDate}
                  AcademicYear={AcademicYear}
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <>
          <div className="sec-dataTable content-section main-content">
            <div className="upper-dataTbl">
              <div className="btn-addlnote">
                {userinfo.UserType !== "T" && (
                  <button
                    type="button"
                    class="btn btn-sm"
                    style={{
                      background: "var(--MainColor)",
                      color: "white",
                    }}
                    onClick={addnew}
                  >
                    Add New
                  </button>
                )}

                <div className="exportDiv">
                  <Tippy content="Excel">
                    <button className="exportButtons mx-2" onClick={toExcel}>
                      <i className="bi bi-file-earmark-excel-fill exportButtonIconsExcel"></i>
                    </button>
                  </Tippy>

                  <Tippy content="PDF">
                    <button className="exportButtons" onClick={toPdf}>
                      <i className="bi bi-file-earmark-pdf-fill exportButtonIconsPdf"></i>
                    </button>
                  </Tippy>
                </div>
              </div>
            </div>

            <hr className="new-hr" />

            {loading ? (
              <Spinner />
            ) : (
              <DataTable
                columns={columns}
                data={academicList}
                customStyles={customStyles}
                pagination
                fixedHeader
                fixedHeaderScrollHeight="280px"
                highlightOnHover
                pointerOnHover
                responsive
                dense
                striped
                subHeader
                subHeaderAlign="left"
                subHeaderComponent={
                  <>
                    <div
                      className="col-lg-2 offset-lg-10 offset-md-10 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
                      style={{ paddingRight: "1px", marginBottom: "10px" }}
                    >
                      <div className="d-flex">
                        <input
                          placeholder="Search"
                          ref={searchInput}
                          type="text"
                          className="form-control form-control-sm searchField"
                          onChange={searchHandler}
                        />
                      </div>
                    </div>
                  </>
                }
              />
            )}
          </div>
        </>
      </div>
      {modal && (
        <div className="modals">
          <div className="overlay"></div>

          <div className="notification-model-content">
            <div className="popUpHeader ">
              <div className="popUpHeaderText ">Academic Year</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closePopup}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="inside-modal">
              <AcademicForm closePopup={closePopup} />
            </div>
          </div>
        </div>
      )}

      {editModal && (
        <div className="modals">
          <div className="overlay"></div>

          <div className="notification-model-content">
            <div className="popUpHeader ">
              <div className="popUpHeaderText ">Edit Academic</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closeEditPop}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="inside-modal">
              <AcademicEdit closeEditPop={closeEditPop} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
