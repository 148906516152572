import React, { useContext, useRef } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import SuggestionContext from "../contextfolder/SuggestionContext/SuggestionContext";
import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import StudentSuggestionForm from "./StudentSuggestionForm";
import DeletePop from "./DeletePop";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { GetNepaliDate } from "../hooks/dateConvertor";
import AcademicHeader from "../AcademicHeader";

export default function StudentSuggestionList() {
  const { todayDate, customStyles, AcademicYear, userinfo } =
    useContext(SidebarContext);
  const {
    stdLoading,
    stdOriginalList,
    stdModal,
    toggleStdModal,
    setStdIsSubmit,
    setStdSuggestionFormValue,
    setStdSuggestionFormError,
    stdSuggestionValue,
    stdSuggestionList,
    setStdSuggestionList,
    stdDeletePopup,
    handleStdDelete,
    handleStdDeleteTrue,
    handleStdDeleteFalse,
    setStdCloseChecked,
  } = useContext(SuggestionContext);

  const searchInput = useRef("");

  const columns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "Suggestion topic",
      selector: (row) => row.Topic,
    },
    {
      name: "Title",
      selector: (row) => row.Title,
    },
    {
      name: "Description",
      selector: (row) => row.Description,
    },
    {
      name: "Status",
      width: "110px",
      selector: (row) => row.Status,
    },
    {
      name: "Status By",

      selector: (row) => (row.StatusBy === null ? "Pending" : row.StatusBy),
    },
    {
      name: "Created Date",
      width: "170px",
      selector: (row) => GetNepaliDate(row.CreatedDate),
    },
    {
      name: "Action",
      // grow: 0,
      center: true,
      width: "79px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <Tippy content="Delete">
                <i
                  className={
                    row.Status === "Pending"
                      ? "fa fa-trash-o rejectspan--action"
                      : "fa fa-trash-o rejectspan--action--disabled"
                  }
                  onClick={() => handleStdDelete(row.SuggestionID)}
                ></i>
              </Tippy>
            </div>
          </>
        );
      },
    },
  ];

  const closePopup = (e) => {
    e.preventDefault();
    toggleStdModal();
    setStdSuggestionFormValue(stdSuggestionValue);
    setStdSuggestionFormError({});
    setStdIsSubmit(false);
    setStdCloseChecked(false);
  };

  const addnew = (e) => {
    e.preventDefault();
    toggleStdModal();
    setStdIsSubmit(false);
    setStdSuggestionFormValue(stdSuggestionValue);
  };

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = stdOriginalList.filter((list) => {
        return list["Topic"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setStdSuggestionList(srchResult);
      } else {
        setStdSuggestionList({});
      }
    } else {
      setStdSuggestionList(stdOriginalList);
    }
  };

  /* This is for export csv */

  const dataToExcel = stdSuggestionList.map((d, i) => ({
    "S.N.": i + 1,
    "Suggestion Topic": d.Topic,
    Title: d.Title,
    Description: d.Description,
    Status: d.Status,
    "Status By": d.StatusBy === null ? "Pending" : d.StatusBy,
    "Created Date": d.CreatedDate,
  }));

  const toExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(dataToExcel);
      var wscols = [
        { wch: 5 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
      ];
      worksheet["!cols"] = wscols;
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "Suggestion");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_" + "export" + EXCEL_EXTENSION
        );
      }
    });
  };

  //end

  //This is to export pdf

  const dataWithIndex = stdSuggestionList.map((d, i) => ({
    ...d,
    Index: i + 1,
    "Status By": d.StatusBy === null ? "Pending" : d.StatusBy,
  }));

  const cols = [
    { header: "S.N", field: "Index" },
    { header: "Suggestion Topic", field: "Topic" },
    { header: "Title", field: "Title" },
    { header: "Description", field: "Description" },
    { header: "Status", field: "Status" },
    { header: "Status By", field: "Status By" },
    { header: "Created Date", field: "CreatedDate" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const toPdf = () => {
    const doc = new jsPDF("p", "pt", "a4", true);

    var midPage = doc.internal.pageSize.getWidth() / 2;

    // doc.addImage(`${userinfo.SchPhoto}`, "JPG", 40, 40, 70, 60);

    doc.setFontSize(18);
    doc.text(`${userinfo.SchName}`, midPage, 50, null, null, "center");

    doc.setFontSize(15);
    doc.text(`${userinfo.SchAddress}`, midPage, 68, null, null, "center");

    doc.setFontSize(10);
    doc.text(
      `Tel:${userinfo.Phone}, E-mail:${userinfo.Email}`,
      midPage,
      85,
      null,
      null,
      "center"
    );

    doc.autoTable({
      startY: 100,
      theme: "grid",
      columns: exportColumns,
      body: dataWithIndex,
    });
    doc.save("Suggestion.pdf");
  };
  //end

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
        <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">Suggestion</div>
            <div className="page-date">
              <div className="sec-content">
                <AcademicHeader
                  todayDate={todayDate}
                  AcademicYear={AcademicYear}
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <>
          <div className="sec-dataTable content-section main-content">
            <div className="upper-dataTbl">
              <div className="btn-addlnote">
                <button
                  type="button"
                  class="btn btn-sm"
                  style={{
                    background: "var(--MainColor)",
                    color: "white",
                  }}
                  onClick={addnew}
                >
                  Add New
                </button>
                <div className="exportDiv">
                  <Tippy content="Excel">
                    <button className="exportButtons mx-2" onClick={toExcel}>
                      <i className="bi bi-file-earmark-excel-fill exportButtonIconsExcel"></i>
                    </button>
                  </Tippy>

                  <Tippy content="PDF">
                    <button className="exportButtons " onClick={toPdf}>
                      <i className="bi bi-file-earmark-pdf-fill exportButtonIconsPdf"></i>
                    </button>
                  </Tippy>
                </div>
              </div>
            </div>
            <hr className="new-hr" />
            {stdLoading ? (
              <Spinner />
            ) : (
              <DataTable
                columns={columns}
                data={stdSuggestionList}
                customStyles={customStyles}
                pagination
                fixedHeader
                fixedHeaderScrollHeight="280px"
                highlightOnHover
                pointerOnHover
                responsive
                dense
                striped
                subHeader
                subHeaderAlign="left"
                subHeaderComponent={
                  <>
                    <div
                      className="col-lg-2 offset-lg-10 offset-md-10 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
                      style={{ paddingRight: "1px", marginBottom: "10px" }}
                    >
                      <div className="d-flex">
                        <input
                          placeholder="Search"
                          ref={searchInput}
                          type="text"
                          className="form-control form-control-sm searchField"
                          onChange={searchHandler}
                        />
                      </div>
                    </div>
                  </>
                }
              />
            )}
          </div>
        </>
      </div>

      {stdModal && (
        <div className="modals">
          <div className="overlay"></div>

          <div className="commission-model-content">
            <div className="popUpHeader ">
              <div className="popUpHeaderText "> Add Suggestion</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closePopup}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="inside-modal">
              <StudentSuggestionForm closePopup={closePopup} />
            </div>
          </div>
        </div>
      )}

      {stdDeletePopup.show && (
        <DeletePop
          handleDeleteTrue={handleStdDeleteTrue}
          handleDeleteFalse={handleStdDeleteFalse}
        />
      )}
    </>
  );
}
