import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import LeaveApplicationContext from "../contextfolder/LeaveApplicationContext/LeaveApplicationContext";
import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";
import DeletePop from "./DeletePop";
import LeaveApplicationForm from "./LeaveApplicationForm";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { GetNepaliDate } from "../hooks/dateConvertor";
import AcademicHeader from "../AcademicHeader";

export default function LeaveApplicationList() {
  const { todayDate, customStyles, AcademicYear, userinfo } =
    useContext(SidebarContext);
  const {
    loading,
    originalList,
    setLeaveList,
    popup,
    togglePopup,
    setIsSubmit,
    setLeaveFormValue,
    setLeaveFormError,
    leaveValue,
    deletePopup,
    handleDeleteTrue,
    handleDeleteFalse,
    handleDelete,
    leaveList,
  } = useContext(LeaveApplicationContext);

  const searchInput = useRef("");

  const columns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "Title",
      width: "150px",
      selector: (row) => row.Title,
    },
    {
      name: "Description",
      selector: (row) => row.Description,
    },
    {
      name: "From",
      width: "120px",
      selector: (row) => GetNepaliDate(row.From),
    },
    {
      name: "To",
      width: "120px",
      selector: (row) => GetNepaliDate(row.To),
    },

    {
      name: "Status",
      width: "110px",
      selector: (row) =>
        row.IsApprovedID === "P"
          ? "Pending"
          : row.IsApprovedID === "Y"
          ? "Approved"
          : "Rejected",
    },
    {
      name: "Remarks",
      selector: (row) =>
        row.ApprovedRemarks === null || row.IsApprovedID === "P"
          ? "Pending"
          : row.ApprovedRemarks,
    },
    {
      name: "Approved By",
      selector: (row) =>
        row.ApprovedBy === null || row.IsApprovedID === "P"
          ? "Pending"
          : row.ApprovedBy,
    },
    {
      name: "Action",
      center: true,
      width: "100px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <Tippy content="Delete">
                <button
                  style={{ border: "none", padding: "0" }}
                  className={
                    row.IsApprovedID === "Y" || row.IsApprovedID === "N"
                      ? "fa fa-trash-o rejectspan--action--disabled"
                      : "fa fa-trash-o rejectspan--action"
                  }
                  onClick={() => handleDelete(row.LeaveID)}
                ></button>
              </Tippy>
            </div>
          </>
        );
      },
    },
  ];

  const closePopup = (e) => {
    e.preventDefault();
    togglePopup();
    setLeaveFormValue(leaveValue);
    setLeaveFormError({});
    setIsSubmit(false);
  };

  const addnew = (e) => {
    e.preventDefault();
    togglePopup();
    setIsSubmit(false);
    // setNotificationFormValue(notificationvalue)
  };

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["Title"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setLeaveList(srchResult);
      } else {
        setLeaveList({});
      }
    } else {
      setLeaveList(originalList);
    }
  };

  /* This is for export csv */

  const dataToExcel = leaveList.map((d, i) => ({
    "S.N.": i + 1,
    Title: d.Title,
    Description: d.Description,
    Date: d.From,
    Status:
      d.IsApprovedID === "P"
        ? "Pending"
        : d.IsApprovedID === "Y"
        ? "Approved"
        : "Rejected",
    Remarks:
      d.ApprovedRemarks === null || d.IsApprovedID === "P"
        ? "Pending"
        : d.ApprovedRemarks,
    "Approved By":
      d.ApprovedBy === null || d.IsApprovedID === "P"
        ? "Pending"
        : d.ApprovedBy,
  }));

  const toExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(dataToExcel);
      var wscols = [
        { wch: 5 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
      ];
      worksheet["!cols"] = wscols;
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "Leave_Application");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_" + "export" + EXCEL_EXTENSION
        );
      }
    });
  };

  //end

  //This is to export pdf

  const dataWithIndex = leaveList.map((d, i) => ({
    ...d,
    Index: i + 1,
    Status:
      d.IsApprovedID === "P"
        ? "Pending"
        : d.IsApprovedID === "Y"
        ? "Approved"
        : "Rejected",
    Remarks:
      d.ApprovedRemarks === null || d.IsApprovedID === "P"
        ? "Pending"
        : d.ApprovedRemarks,
    "Approved By":
      d.ApprovedBy === null || d.IsApprovedID === "P"
        ? "Pending"
        : d.ApprovedBy,
  }));

  const cols = [
    { header: "S.N", field: "Index" },
    { header: "Title", field: "Title" },
    { header: "Description", field: "Description" },
    { header: "Date", field: "From" },
    { header: "Status", field: "Status" },
    { header: "Remarks", field: "Remarks" },
    { header: "Approved By", field: "Approved By" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const toPdf = () => {
    const doc = new jsPDF("p", "pt", "a4", true);

    var midPage = doc.internal.pageSize.getWidth() / 2;

    // doc.addImage(`${userinfo.SchPhoto}`, "JPG", 40, 40, 70, 60);

    doc.setFontSize(18);
    doc.text(`${userinfo.SchName}`, midPage, 50, null, null, "center");

    doc.setFontSize(15);
    doc.text(`${userinfo.SchAddress}`, midPage, 68, null, null, "center");

    doc.setFontSize(10);
    doc.text(
      `Tel:${userinfo.Phone}, E-mail:${userinfo.Email}`,
      midPage,
      85,
      null,
      null,
      "center"
    );

    doc.autoTable({
      startY: 100,
      theme: "grid",
      columns: exportColumns,
      body: dataWithIndex,
    });
    doc.save("Leave_Application.pdf");
  };
  //end

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
        <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">Leave Application</div>
            <div className="page-date">
              <div className="sec-content">
                <AcademicHeader
                  todayDate={todayDate}
                  AcademicYear={AcademicYear}
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <>
          <div className="sec-dataTable content-section main-content">
            <div className="upper-dataTbl">
              <div className="btn-addlnote">
                <button
                  type="button"
                  class="btn btn-sm"
                  style={{
                    background: "var(--MainColor)",
                    color: "white",
                  }}
                  onClick={addnew}
                >
                  Add New
                </button>
                <div className="exportDiv">
                  <Tippy content="Excel">
                    <button className="exportButtons mx-2" onClick={toExcel}>
                      <i className="bi bi-file-earmark-excel-fill exportButtonIconsExcel"></i>
                    </button>
                  </Tippy>

                  <Tippy content="PDF">
                    <button className="exportButtons " onClick={toPdf}>
                      <i className="bi bi-file-earmark-pdf-fill exportButtonIconsPdf"></i>
                    </button>
                  </Tippy>
                </div>
              </div>
            </div>

            <hr className="new-hr" />
            {loading ? (
              <Spinner />
            ) : (
              <DataTable
                columns={columns}
                data={leaveList}
                customStyles={customStyles}
                pagination
                fixedHeader
                fixedHeaderScrollHeight="280px"
                highlightOnHover
                pointerOnHover
                responsive
                dense
                striped
                subHeader
                subHeaderAlign="left"
                subHeaderComponent={
                  <>
                    {/* <div className="col-md-2 col-sm-2 col-lg-2" style={{ marginRight: "-15px" }}>
                                            <label style={{ fontSize: "14px", textAlign: "left", display: "block" }}>Class</label>
                                            <select
                                                style={{ fontSize: "11px", marginBottom: "3px" }}
                                                name="snotifiaction"
                                                value={selectClass}
                                                onChange={handleClass}
                                                className="form-select form-select-sm"
                                                aria-label="Default select example "
                                            >
                                                <option
                                                    value=""
                                                    disabled
                                                    selected
                                                    style={{ fontSize: "11px" }}
                                                >
                                                    Select Class
                                                </option>
                                                {classList.map((item) => (
                                                    <option
                                                        key={item.ClassID}
                                                        value={item.ClassID}
                                                    >
                                                        {item.ClassName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div> */}

                    <div
                      className="col-lg-2 offset-lg-10 offset-md-10 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
                      style={{ paddingRight: "1px", marginBottom: "10px" }}
                    >
                      <div className="d-flex">
                        <input
                          placeholder="Search"
                          ref={searchInput}
                          type="text"
                          className="form-control form-control-sm searchField"
                          onChange={searchHandler}
                        />
                      </div>
                    </div>
                  </>
                }
              />
            )}
          </div>
        </>
      </div>

      {popup && (
        <div className="modals">
          <div className="overlay"></div>

          <div className="notification-model-content">
            <div className="popUpHeader ">
              <div className="popUpHeaderText ">Leave Application</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closePopup}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="inside-modal">
              <LeaveApplicationForm closePopup={closePopup} />
            </div>
          </div>
        </div>
      )}

      {deletePopup.show && (
        <DeletePop
          handleDeleteTrue={handleDeleteTrue}
          handleDeleteFalse={handleDeleteFalse}
        />
      )}
    </>
  );
}
