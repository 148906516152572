import React, { useContext, useRef } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import SidebarContext from "../sidebarcontext/SidebarContext";
import Spinner from "../loading/spinner";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { GetNepaliDate, GetTwelveHourFormatTime } from "../hooks/dateConvertor";
import RouteForm from "./RouteForm";
import RouteFormEdit from "./RouteFormEdit";
import TeacherAttendanceContext from "../contextfolder/TeacherAttendanceContext/TeacherAttendanceContext";
import DeletePop from "./DeletePop";
import ActivatePop from "./ActivatePop";
import InactivatePop from "./InactivatePop";




export default function Route() {
    const { customStyles, userinfo, hideAction } = useContext(SidebarContext);
    const { loading, search, setAttendanceList, modal, toggleModal, setIsSubmit, setAttendanceFormValue, setAttendanceFormError, attendanceValue, attendanceList, handleEdit, editModal, toggleEditModal, setEditIsSubmit, reportOption, setReportOption,

        deletePopup, handleDeleteTrue, handleDeleteFalse, handleDelete, setCloseChecked, activatePopup, inActivatePopup, handleActivateFalse, handleActivateTrue, handleInActivateFalse, handleInActivateTrue, handleActivate, handleInActivate } = useContext(TeacherAttendanceContext);


    const handleReport = (e) => {
        const target = e.target;
        const value = target.value;
        setReportOption(value);
    }


    const searchInput = useRef("");

    const columns = [
        {
            name: "S.N.",
            width: "63px",
            center: true,
            cell: (row, index) => index + 1,
        },

        {
            name: "Name",

            selector: (row) => row.Name,
        },
        {
            name: "SSID",
            width: "400px",
            selector: (row) => row.SSID,
        },

        {
            name: "Status",
            width: "110px",
            center: true,
            selector: (row) => {
                return (
                    <>
                        <div className="ln-verition d-flex">
                            {row.IsActive === "I" ? (
                                <>
                                    <button
                                        type="button"
                                        class="badge badge-success"
                                        onClick={() => handleActivate(row)}
                                    >
                                        Activate
                                    </button>
                                </>


                            ) : (
                                <>
                                    <button
                                        type="button"
                                        class="badge badge-danger"
                                        onClick={() => handleInActivate(row)}
                                    >
                                        Deactivate
                                    </button>
                                </>
                            )}
                        </div>
                    </>
                );
            },
        },
        {
            name: "Action",
            center: true,
            width: "100px",
            selector: (row) => {
                return (
                    <>
                        <div className="ln-verition d-flex">
                            <Tippy content="Edit">
                                <i
                                    className="fa fa-pencil solvespan--action"
                                    onClick={() => handleEdit(row)}
                                >
                                </i>
                            </Tippy>
                            <Tippy content="Delete">
                                <i
                                    className="fa fa-trash-o rejectspan--action"
                                    onClick={() => handleDelete(row.RouteID)}
                                >
                                </i>
                            </Tippy>
                        </div>
                    </>
                );
            },
            omit: hideAction,
        },

    ];

    const closePopup = (e) => {
        e.preventDefault();
        toggleModal();
        setAttendanceFormValue(attendanceValue)
        setAttendanceFormError({})
        setIsSubmit(false)
        setCloseChecked(false)
    }

    const closeEditPop = (e) => {
        e.preventDefault();
        toggleEditModal();
        setAttendanceFormError({})
        setEditIsSubmit(false)
        setCloseChecked(false)
    }





    const searchHandler = (e) => {
        e.preventDefault();

        const srchQuery = searchInput.current.value.toLowerCase();
        if (srchQuery) {

            let srchResult = search.filter((list) => {
                return list["Name"].toLowerCase().includes(srchQuery) || list["SSID"].toLowerCase().includes(srchQuery);
            });

            if (srchResult) {

                setAttendanceList(srchResult);
            } else {
                setAttendanceList({});
            }
        } else {
            setAttendanceList(search);
        }
    };

    /* This is for export csv */

    const dataToExcel = attendanceList.map((d, i) => ({
        "S.N.": i + 1,
        "Date": d.ExamDate,
        "Room No.": d.RoomNo,
        "Subject": d.Subject,
        "Day": d.Week,
        "Time": d.StartTime,
        "Shift": d.ShiftType,
    }));

    const toExcel = () => {
        import("xlsx").then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(dataToExcel);
            var wscols = [
                { wch: 5 },
                { wch: 20 },
                { wch: 20 },
                { wch: 15 },
                { wch: 15 },
                { wch: 15 },
                { wch: 15 },
            ];
            worksheet["!cols"] = wscols;
            const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: "xlsx",
                type: "array",
            });
            saveAsExcelFile(excelBuffer, "Exam_Routine");
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import("file-saver").then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE =
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
                let EXCEL_EXTENSION = ".xlsx";
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE,
                });

                module.default.saveAs(
                    data,
                    fileName + "_" + "export" + EXCEL_EXTENSION
                );
            }
        });
    };

    //end


    //This is to export pdf 

    const dataWithIndex = attendanceList.map((d, i) => ({
        ...d,
        Index: i + 1,
    }));

    const cols = [
        { header: "S.N", field: "Index" },
        { header: "Date", field: "ExamDate" },
        { header: "Room No.", field: "RoomNo" },
        { header: "Subject", field: "Subject" },
        { header: "Day", field: "Week" },
        { header: "Time", field: "StartTime" },
        { header: "Shift", field: "ShiftType" },
    ];

    const exportColumns = cols.map((col) => ({
        title: col.header,
        dataKey: col.field,
    }));

    const toPdf = () => {
        const doc = new jsPDF("p", "pt", "a4", true);

        var midPage = doc.internal.pageSize.getWidth() / 2;

        // doc.addImage(`${userinfo.SchPhoto}`, "JPG", 40, 40, 70, 60);

        doc.setFontSize(18);
        doc.text(`${userinfo.SchName}`, midPage, 50, null, null, "center");

        doc.setFontSize(15);
        doc.text(
            `${userinfo.SchAddress}`,
            midPage,
            68,
            null,
            null,
            "center"
        );

        doc.setFontSize(10);
        doc.text(`Tel:${userinfo.Phone}, E-mail:${userinfo.Email}`, midPage, 85, null, null, "center");

        doc.autoTable({
            startY: 100,
            theme: "grid",
            columns: exportColumns,
            body: dataWithIndex,
        });
        doc.save("Exam_Routine.pdf");
    };
    //end

    return (
        <>
            {/* <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
            /> */}

            <>
                <div className="sec-dataTable">
                    {/* <div className="upper-dataTbl">

                        <Tippy content="PDF">
                            <button className="exportButtons ml-1"
                                onClick={toPdf}
                            >
                                <i className="bi bi-file-earmark-pdf-fill exportButtonIconsPdf"></i>
                            </button>
                        </Tippy>
                        <Tippy content="Excel">
                            <button className="exportButtons" onClick={toExcel}>
                                <i className="bi bi-file-earmark-excel-fill exportButtonIconsExcel"></i>
                            </button>
                        </Tippy>

                    </div>
                    <hr className="new-hr-new" /> */}

                    {loading ? (
                        <Spinner />
                    ) : (
                        <DataTable
                            columns={columns}
                            data={attendanceList}
                            customStyles={customStyles}
                            pagination
                            fixedHeader
                            fixedHeaderScrollHeight="280px"
                            highlightOnHover
                            pointerOnHover
                            responsive
                            dense
                            striped
                            subHeader
                            subHeaderAlign="left"
                            subHeaderComponent={
                                <>
                                    <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                                        <label className="list-label">Select Option</label>
                                        <select
                                            style={{ fontSize: "11px" }}
                                            name="status"
                                            className="form-control form-control-sm"
                                            aria-label="Default select example "
                                            value={reportOption}
                                            onChange={handleReport}
                                        >
                                            <option selected disabled value="" style={{ fontSize: "11px" }}>
                                                Select Option
                                            </option>
                                            <option value="-1">All</option>
                                            <option value="A">Active</option>
                                            <option value="I">Deactive</option>
                                        </select>
                                        <i class="fas fa-angle-down  notificationpositon"></i>
                                    </div>



                                    <div className="col-lg-2 offset-lg-8 offset-md-8 offset-sm-0 col-md-2 col-sm-2 align-self-end Search" style={{ marginTop: "25px", paddingRight: "1px", marginBottom: "10px" }}>
                                        <div className="d-flex">
                                            <input
                                                placeholder="Search"
                                                ref={searchInput}
                                                type="text"
                                                className="form-control form-control-sm searchField"
                                                onChange={searchHandler}
                                            />
                                        </div>
                                    </div>
                                </>
                            }
                        />
                    )}
                </div>
            </>

            {modal && (
                <div className="modals">
                    <div className="overlay"></div>

                    <div className="teacher-routes-model-content">
                        <div className="popUpHeader ">
                            <div className='popUpHeaderText '>Add Route</div>
                            <div style={{ cursor: "pointer" }}><i className="bi bi-x " onClick={closePopup} style={{ fontSize: "25px" }}></i></div>
                        </div>
                        <div className="inside-modal">
                            <RouteForm closePopup={closePopup} />
                        </div>
                    </div>
                </div>
            )}

            {editModal && (
                <div className="modals">
                    <div className="overlay"></div>

                    <div className="teacher-routes-model-content">
                        <div className="popUpHeader ">
                            <div className='popUpHeaderText '>Edit Route</div>
                            <div style={{ cursor: "pointer" }}><i className="bi bi-x " onClick={closeEditPop} style={{ fontSize: "25px" }}></i></div>
                        </div>
                        
                        <div className="inside-modal">
                            <RouteFormEdit closeEditPop={closeEditPop} />
                        </div>
                    </div>
                </div>
            )}

            {deletePopup.show && (
                <DeletePop
                    handleDeleteTrue={handleDeleteTrue}
                    handleDeleteFalse={handleDeleteFalse}
                />
            )}

            {activatePopup.show && (
                <ActivatePop
                    handleActivateTrue={handleActivateTrue}
                    handleActivateFalse={handleActivateFalse}
                />
            )}
            {inActivatePopup.show && (
                <InactivatePop
                    handleInActivateTrue={handleInActivateTrue}
                    handleInActivateFalse={handleInActivateFalse}
                />
            )}
        </>
    );
}
