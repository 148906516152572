import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import '../../css/Commission.css';
import CalendarEventContext from "../contextfolder/CalendarEventContext/CalendarEventContext";
import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";
import CalendarEventEdit from "./CalendarEventEdit";
import CalendarEventForm from "./CalendarEventForm";
import DeletePop from "./DeletePop";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import jsPDF from "jspdf";
import "jspdf-autotable";


export default function CalendarEventList() {
    const { todayDate, customStyles, AcademicYear, hideAction } = useContext(SidebarContext);
    const { loading, originalList, setEventList, popup, toggleEPopup, setIsESubmit, setEventEFormValue, setEventFormError, eventEValue, eventList, handleEdit, editPop, toggleEditPopup, setEditIsSubmit,
        deletePopup, handleDeleteTrue, handleDeleteFalse, handleDelete, setCloseEChecked } = useContext(CalendarEventContext);

    const userinfo = JSON.parse(localStorage.getItem("userInfo"));

    const searchInput = useRef("");

    const columns = [
        {
            name: "S.N.",
            width: "63px",
            center: true,
            cell: (row, index) => index + 1,
        },
        {
            name: "Date",
            width: "120px",
            selector: (row) => row.NepaliDate,
        },
        {
            name: "Title",
            width: "200px",
            selector: (row) => row.Title,
        },
        {
            name: "Description",
            // grow: 0,
            selector: (row) => row.Description,
        },
        {
            name: "Type",
            width: "100px",
            selector: (row) => row.IsHoliday === "Y" ? "Holiday" : "Event",
        },
        {
            name: "Action",
            // grow: 0,
            center: true,
            width: "100px",
            selector: (row) => {
                return (
                    <>
                        <div className="ln-verition d-flex">
                            <Tippy content="Edit">
                                <i
                                    className="fa fa-pencil solvespan--action"
                                    onClick={() => handleEdit(row)}
                                >
                                
                                </i>
                            </Tippy>
                            <Tippy content="Delete">
                                <i
                                    className="fa fa-trash-o rejectspan--action"
                                    onClick={() => handleDelete(row.EventID)}
                                >
                                </i>
                            </Tippy>
                        </div>
                    </>
                );
            },
            omit: hideAction,
        },

    ];

    const closePopup = (e) => {
        e.preventDefault();
        toggleEPopup();
        setEventEFormValue(eventEValue)
        setEventFormError({})
        setIsESubmit(false)
        setCloseEChecked(false)
    }

    const closeEditPop = (e) => {
        e.preventDefault();
        toggleEditPopup();
        setEventFormError({})
        setEditIsSubmit(false)
        setCloseEChecked(false)
    }

    const addnew = (e) => {
        e.preventDefault();
        toggleEPopup();
        setIsESubmit(false)
        setCloseEChecked(false)
        setEventEFormValue(eventEValue)
    }



    const searchHandler = (e) => {
        e.preventDefault();

        const srchQuery = searchInput.current.value.toLowerCase();
        if (srchQuery) {

            let srchResult = originalList.filter((list) => {
                return list["Title"].toLowerCase().includes(srchQuery);
            });

            if (srchResult) {

                setEventList(srchResult);
            } else {
                setEventList({});
            }
        } else {
            setEventList(originalList);
        }
    };

    /* This is for export csv */

    const dataToExcel = eventList.map((d, i) => ({
        "S.N.": i + 1,
        "Title": d.Title,
        "Description": d.Description,
        "Type": d.IsHoliday === "Y" ? "Holiday" : "Event",
    }));

    const toExcel = () => {
        import("xlsx").then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(dataToExcel);
            var wscols = [
                { wch: 5 },
                { wch: 20 },
                { wch: 20 },
                { wch: 15 },
            ];
            worksheet["!cols"] = wscols;
            const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: "xlsx",
                type: "array",
            });
            saveAsExcelFile(excelBuffer, "Calender_Event");
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import("file-saver").then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE =
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
                let EXCEL_EXTENSION = ".xlsx";
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE,
                });

                module.default.saveAs(
                    data,
                    fileName + "_" + "export" + EXCEL_EXTENSION
                );
            }
        });
    };

    //end

    //This is to export pdf 

    const dataWithIndex = eventList.map((d, i) => ({
        ...d,
        Index: i + 1,
        Type: d.IsHoliday === "Y" ? "Holiday" : "Event",
    }));


    const cols = [
        { header: "S.N", field: "Index" },
        { header: "Title", field: "Title" },
        { header: "Description", field: "Description" },
        { header: "Type", field: "Type" },
    ];


    const exportColumns = cols.map((col) => ({
        title: col.header,
        dataKey: col.field,
    }));

    const toPdf = () => {
        const doc = new jsPDF("p", "pt", "a4", true);

        var midPage = doc.internal.pageSize.getWidth() / 2;

        // doc.addImage(`${userinfo.SchPhoto}`, "JPG", 40, 40, 70, 60);

        doc.setFontSize(18);
        doc.text(`${userinfo.SchName}`, midPage, 50, null, null, "center");

        doc.setFontSize(15);
        doc.text(
            `${userinfo.SchAddress}`,
            midPage,
            68,
            null,
            null,
            "center"
        );

        doc.setFontSize(10);
        doc.text(`Tel:${userinfo.Phone}, E-mail:${userinfo.Email}`, midPage, 85, null, null, "center");

        doc.autoTable({
            startY: 100,
            theme: "grid",
            columns: exportColumns,
            body: dataWithIndex,
        });
        doc.save("Calendar_Event.pdf");
    };
    //end


    return (

        <>
            <div className="sec-dataTable ">

                <div className="upper-dataTbl">
                    <Tippy content="PDF">
                        <button className="exportButtons ml-1"
                            onClick={toPdf}
                        >
                            <i className="bi bi-file-earmark-pdf-fill exportButtonIconsPdf"></i>
                        </button>
                    </Tippy>

                    <Tippy content="Excel">
                        <button className="exportButtons" onClick={toExcel}>
                            <i className="bi bi-file-earmark-excel-fill exportButtonIconsExcel"></i>
                        </button>
                    </Tippy>

                </div>

                <hr className="new-hr" />

                {loading ? (
                    <Spinner />
                ) : (
                    <DataTable
                        columns={columns}
                        data={eventList}
                        customStyles={customStyles}
                        pagination
                        fixedHeader
                        fixedHeaderScrollHeight="280px"
                        highlightOnHover
                        pointerOnHover
                        responsive
                        dense
                        striped
                        subHeader
                        subHeaderAlign="left"
                        subHeaderComponent={
                            <>
                                <div className="col-lg-2 offset-lg-10 offset-md-10 offset-sm-0 col-md-2 col-sm-2 align-self-end Search" style={{ paddingRight: "1px", marginBottom: "10px" }}>
                                    <div className="d-flex">
                                        <input
                                            placeholder="Search"
                                            ref={searchInput}
                                            type="text"
                                            className="form-control form-control-sm searchField"
                                            onChange={searchHandler}
                                        />
                                    </div>
                                </div>
                            </>
                        }
                    />
                )}
            </div>



            {popup && (
                <div className="modals">
                    <div className="overlay"></div>

                    <div className="notification-model-content">
                        <div className="popUpHeader ">
                            <div className='popUpHeaderText '>Add Event</div>
                            <div style={{ cursor: "pointer" }}><i className="bi bi-x " onClick={closePopup} style={{ fontSize: "25px" }}></i></div>
                        </div>
                        <div className="inside-modal">
                            <CalendarEventForm closePopup={closePopup} />
                        </div>
                    </div>
                </div>
            )}

            {editPop && (
                <div className="modals">
                    <div className="overlay"></div>

                    <div className="notification-model-content">
                        <div className="popUpHeader ">
                            <div className='popUpHeaderText '>Edit Event</div>
                            <div style={{ cursor: "pointer" }}><i className="bi bi-x " onClick={closeEditPop} style={{ fontSize: "25px" }}></i></div>
                        </div>
                        <div className="inside-modal">
                            <CalendarEventEdit closeEditPop={closeEditPop} />
                        </div>
                    </div>
                </div>
            )}

            {deletePopup.show && (
                <DeletePop
                    handleDeleteTrue={handleDeleteTrue}
                    handleDeleteFalse={handleDeleteFalse}
                />
            )}
        </>
    );
}
