import React, { useContext, useState, useEffect } from "react";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { Fetchdata } from "../../hooks/getData";
import Select from "react-select";
import LogBookContext from "../../contextfolder/LogBookContext/LogBookContext";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";

export default function LogBookForm({ closePopup }) {
  const context = useContext(LogBookContext);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const { appURL } = useContext(SidebarContext);

  const {
    togglePopup,
    logBookFormError,
    setlogBookFormError,
    isSubmit,
    setIsSubmit,
    logBookValue,
    logBookFormValue,
    setlogBookFormValue,
    loading,
    setCloseChecked,
    closeChecked,
    section,
    setSection,
    setStudent,
    setAdmin,
    setTeacher,
    isUploaded,
    setIsUploaded,
    setTypeFile,
    image,
    setImage,
    fetchdata,
    setCloseTeacherChecked,
    closeTeacherChecked,
    closeAdminChecked,
    setCloseAdminChecked,
  } = context;

  const handleOnChange = () => {
    setCloseChecked(!closeChecked);
  };

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setlogBookFormValue({ ...logBookFormValue, [name]: value });
  };

  const handleDate = ({ adDate }) => {
    setlogBookFormValue({ ...logBookFormValue, date: adDate });
  };
  const formSubmit = (e) => {
    e.preventDefault();
    setlogBookFormError(validate(logBookFormValue));
    setIsSubmit(true);
  };
  useEffect(() => {
    if (Object.keys(logBookFormError).length === 0 && isSubmit) {
      fetchdata(logBookFormValue);
      if (closeChecked === true) {
        togglePopup();
      }
      setlogBookFormValue(logBookValue);
      setSection([]);
      setIsSubmit(false);
      setCloseChecked(false);
    }
  }, [logBookFormError]);

  const validate = (values) => {
    const errors = {};
    if (!values.class) {
      errors.class = "Required";
    }
    if (userinfo.UserType !== "T") {
      if (!values.teacher) {
        errors.teacher = "Required";
      }
    }

    if (!values.subject) {
      errors.subject = "Required";
    }
    if (!values.lesson) {
      errors.lesson = "Required";
    }
    if (!values.page) {
      errors.page = "Required";
    }
    if (!values.lessonTaught) {
      errors.lessonTaught = "Required";
    }
    if (!values.assignmentGiven) {
      errors.assignmentGiven = "Required";
    }
    if (!values.date) {
      errors.date = "Required";
    }

    return errors;
  };

  //API for class list
  const [classList, setClassList] = useState([]);
  useEffect(() => {
    getClassList();
  }, []);

  const getClassList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      DFlag: "C",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setClassList(postResult);
      } else {
        setClassList([]);
      }
    });
  };

  //API for Section List
  const [sectionList, setSectionList] = useState([]);
  useEffect(() => {
    getSectionList();
  }, [logBookFormValue.class]);

  const getSectionList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      ClassID: logBookFormValue.class,
      DFlag: "S",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";

        setSectionList(postResult);
      } else {
        setSectionList([]);
      }
    });
  };

  //API for teacher list
  const [teacherList, setTeacherList] = useState([]);

  useEffect(() => {
    teachList();
  }, [logBookFormValue.class, logBookFormValue.section]);

  const teachList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      DFlag: "TL",
      Flag: "S",
      ClassID: logBookFormValue.class,
      SectionID: logBookFormValue.section,
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setTeacherList(postResult);
      } else {
        setTeacherList([]);
      }
    });
  };

  //API for subject list
  const [subjectList, setSubjectList] = useState([]);

  useEffect(() => {
    getSubjectList();
  }, [
    logBookFormValue.class,
    logBookFormValue.teacher,
    logBookFormValue.section,
  ]);

  const getSubjectList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID:
        userinfo.UserType === "T" ? userinfo.UserID : logBookFormValue.teacher,
      ClassID: logBookFormValue.class,
      SectionID: logBookFormValue.section,
      DFlag: "SB",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";

        setSubjectList(postResult);
      } else {
        setSubjectList([]);
      }
    });
  };

  return (
    <>
      <div className="container-fluid basicform">
        <form>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label className="form-label" htmlFor="class">
                Class<sup style={{ color: "red" }}>*</sup>
              </label>
              <select
                id="class"
                name="class"
                onChange={handleChange}
                value={logBookFormValue.class}
                className="form-select form-select-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="" disabled selected style={{ fontSize: "11px" }}>
                  Select Class
                </option>
                {classList.map((item) => (
                  <option key={item.ClassID} value={item.ClassID}>
                    {item.ClassName}
                  </option>
                ))}
              </select>
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {logBookFormError.class}
              </p>
            </div>

            <div className="form-group col-md-6">
              <label className="form-label" htmlFor="section">
                Section<sup style={{ color: "red" }}>*</sup>
              </label>

              <select
                id="sections"
                name="section"
                onChange={handleChange}
                value={logBookFormValue.section}
                className="form-select form-select-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="" selected disabled>
                  Select Option
                </option>
                {sectionList.map((item, i) => (
                  <option key={i} value={item.SectionID}>
                    {item.SectionName}
                  </option>
                ))}
              </select>
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {logBookFormError.section}
              </p>
            </div>
          </div>

          <div className="form-row">
            {userinfo.UserType !== "T" && (
              <div className="form-group col-md-6">
                <label className="form-label" htmlFor="teachers">
                  Teacher<sup style={{ color: "red" }}>*</sup>
                </label>
                <select
                  id="teachers"
                  name="teacher"
                  onChange={handleChange}
                  value={logBookFormValue.teacher}
                  className="form-select form-select-sm mb-1"
                  style={{ fontSize: "12px" }}
                >
                  <option
                    value=""
                    disabled
                    selected
                    style={{ fontSize: "11px" }}
                  >
                    Select Teacher
                  </option>
                  {teacherList.map((item) => (
                    <option key={item.UserID} value={item.UserID}>
                      {item.FullName}
                    </option>
                  ))}
                </select>
                <p className="errormsg " style={{ marginBottom: "0" }}>
                  {logBookFormError.teacher}
                </p>
              </div>
            )}

            <div
              className={
                userinfo.UserType === "T"
                  ? "form-group col-md-12"
                  : "form-group col-md-6"
              }
            >
              <label className="form-label" htmlFor="subject">
                Subject<sup style={{ color: "red" }}>*</sup>
              </label>
              <select
                id="subject"
                name="subject"
                onChange={handleChange}
                value={logBookFormValue.subject}
                className="form-select form-select-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="" disabled selected style={{ fontSize: "11px" }}>
                  Select Subject
                </option>
                {subjectList.map((item) => (
                  <option key={item.SubjectID} value={item.SubjectID}>
                    {item.SubjectName}
                  </option>
                ))}
              </select>
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {logBookFormError.subject}
              </p>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-6">
              <label
                className="form-label"
                htmlFor="lesson"
                style={{ fontSize: "12px" }}
              >
                Lesson No.<sup style={{ color: "red" }}>*</sup>
              </label>
              <input
                id="lesson"
                style={{ fontSize: "13px" }}
                type="text"
                className="form-control form-control-sm "
                name="lesson"
                value={logBookFormValue.lesson}
                onChange={handleChange}
              />
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {logBookFormError.lesson}
              </p>
            </div>
            <div className="form-group col-md-6">
              <label
                className="form-label"
                htmlFor="page"
                style={{ fontSize: "12px" }}
              >
                Page No.<sup style={{ color: "red" }}>*</sup>
              </label>
              <input
                id="page"
                style={{ fontSize: "13px" }}
                type="text"
                className="form-control form-control-sm "
                name="page"
                value={logBookFormValue.page}
                onChange={handleChange}
              />
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {logBookFormError.page}
              </p>
            </div>
          </div>

          <div className="form-group">
            <label
              className="form-label"
              htmlFor="lessonTaught"
              style={{ fontSize: "12px" }}
            >
              Lesson Taught<sup style={{ color: "red" }}>*</sup>
            </label>
            <textarea
              id="lessonTaught"
              style={{ fontSize: "13px" }}
              type="text"
              className="form-control form-control-sm "
              name="lessonTaught"
              value={logBookFormValue.lessonTaught}
              onChange={handleChange}
              rows="3"
            />
            <p className="errormsg " style={{ marginBottom: "0" }}>
              {logBookFormError.lessonTaught}
            </p>
          </div>

          <div className="form-group">
            <label
              className="form-label"
              htmlFor="assignmentGiven"
              style={{ fontSize: "12px" }}
            >
              Assignment Given<sup style={{ color: "red" }}>*</sup>
            </label>
            <textarea
              id="assignmentGiven"
              style={{ fontSize: "13px" }}
              type="text"
              className="form-control form-control-sm "
              name="assignmentGiven"
              value={logBookFormValue.assignmentGiven}
              onChange={handleChange}
              rows="3"
            />
            <p className="errormsg " style={{ marginBottom: "0" }}>
              {logBookFormError.assignmentGiven}
            </p>
          </div>

          <div className="form-group">
            <label
              className="form-label"
              htmlFor="date"
              style={{ fontSize: "12px" }}
            >
              Date<sup style={{ color: "red" }}>*</sup>
            </label>
            <Calendar
              className="form-control form-control-sm mb-1"
              dateFormat="YYYY/MM/DD"
              theme="default"
              language="en"
              values={logBookFormValue.date}
              onChange={handleDate}
            />
            <p className="errormsg " style={{ marginBottom: "0" }}>
              {logBookFormError.date}
            </p>
          </div>
        </form>
      </div>
      <div className="FormFooter">
        <div className="checkedClose">
          <input
            type="checkbox"
            className="form-check-input"
            id="exampleCheck2"
            name="closeChecked"
            onChange={handleOnChange}
            checked={closeChecked}
          />
          <label class="form-check-label" for="exampleCheck2">
            Exit after submit
          </label>
        </div>
        <div>
          <button
            className="btn btn-sm btn-cmpy ml-2"
            style={{ background: "green" }}
            onClick={formSubmit}
          >
            Submit
          </button>
          <button className="btn btn-sm btn-cmpy ml-2" onClick={closePopup}>
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}
