import React, { useContext, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import SidebarContext from "../sidebarcontext/SidebarContext";
import "../TabbedView/TabbedView.css";
import EventList from "../Event/EventList";
import CalendarEventList from "../CalendarEvent/CalendarEventList";
import CalendarEventContext from "../contextfolder/CalendarEventContext/CalendarEventContext";
import EventContext from "../contextfolder/EventContext/EventContext";
import AcademicHeader from "../AcademicHeader";

export default function EventCombined() {
  const { AcademicYear, todayDate } = useContext(SidebarContext);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const {
    toggleEPopup,
    setIsESubmit,
    setEventEFormValue,
    eventEValue,
    setCloseEChecked,
  } = useContext(CalendarEventContext);

  const {
    togglePopup,
    setIsSubmit,
    setEventFormValue,
    eventValue,
    setCloseChecked,
  } = useContext(EventContext);

  const [activeTab, setActiveTab] = useState({
    tab1: true,
    tab2: false,
  });
  const handleTab1 = () => {
    setActiveTab({
      tab1: true,
      tab2: false,
    });
  };
  const handleTab2 = () => {
    setActiveTab({
      tab1: false,
      tab2: true,
    });
  };

  const addnewEvent = (e) => {
    e.preventDefault();
    toggleEPopup();
    setIsESubmit(false);
    setEventEFormValue(eventEValue);
    setCloseEChecked(false);
  };

  const addnew = (e) => {
    e.preventDefault();
    togglePopup();
    setIsSubmit(false);
    setEventFormValue(eventValue);
    setCloseChecked(false);
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
        <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">
              {activeTab.tab1
                ? "Event"
                : activeTab.tab2
                ? "Calender Event"
                : ""}
            </div>
            <div className="page-date">
              <div className="sec-content">
                <AcademicHeader
                  todayDate={todayDate}
                  AcademicYear={AcademicYear}
                />
              </div>
            </div>
          </div>
        </div>
        <hr />

        <div className="tabview ">
          <div className="insidePopup--div">
            <ul>
              <li
                className={activeTab.tab1 === true ? "active" : ""}
                onClick={handleTab1}
              >
                <i className="fa fa-file-text-o mr-1 icon"></i>Event{" "}
              </li>
              <li
                className={activeTab.tab2 === true ? "active" : ""}
                onClick={handleTab2}
              >
                <i className="fa fa-file-text-o mr-1 icon"></i>Calender Event{" "}
              </li>
            </ul>
          </div>

          {activeTab.tab2 === true ? (
            <div>
              {userinfo.UserType !== "T" && (
                <div
                  className="upper-dataTbl add-btn"
                  style={{ float: "right" }}
                >
                  <div className="btn-addlnote">
                    <button
                      type="button"
                      class="btn btn-sm"
                      style={{
                        background: "var(--MainColor)",
                        color: "white",
                      }}
                      onClick={addnewEvent}
                    >
                      Add New
                    </button>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <></>
          )}
          {activeTab.tab1 === true ? (
            <div>
              {userinfo.UserType !== "T" && (
                <div
                  className="upper-dataTbl add-btn"
                  style={{ float: "right" }}
                >
                  <div className="btn-addlnote">
                    <button
                      type="button"
                      class="btn btn-sm"
                      style={{
                        background: "var(--MainColor)",
                        color: "white",
                      }}
                      onClick={addnew}
                    >
                      Add New
                    </button>
                  </div>
                </div>
              )}{" "}
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className="content-section main-content">
          {activeTab.tab1 && <EventList />}
          {activeTab.tab2 && <CalendarEventList />}
        </div>
      </div>
    </>
  );
}
