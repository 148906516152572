import React, { useContext, useRef } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../../css/Commission.css";
import DeletePop from "./DeletePop";
import ExamRoutineEdit from "./ExamRoutineEdit";
import ExamRoutineForm from "./ExamRoutineForm";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import ExamRoutineContext from "../../contextfolder/ExamRoutineContext/ExamRoutineContext";
import Spinner from "../../loading/spinner";
import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  GetNepaliDate,
  GetTwelveHourFormatTime,
} from "../../hooks/dateConvertor";

export default function ExamRoutineList() {
  const { todayDate, customStyles, userinfo, hideAction } =
    useContext(SidebarContext);
  const {
    loading,
    originalList,
    setExamRoutineList,
    popup,
    toggleEPopup,
    setEIsSubmit,
    setExamFormValue,
    setExamFormError,
    examValue,
    examRoutineList,
    handleEdit,
    editPop,
    toggleEditPopup,
    setEditIsSubmit,

    deletePopup,
    handleDeleteTrue,
    handleDeleteFalse,
    handleDelete,
    classList,
    termList,
    selectClass,
    setSelectClass,
    selectTerm,
    setSelectTerm,
    setECloseChecked,
  } = useContext(ExamRoutineContext);

  const handleClass = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectClass(value);
    setSelectTerm("");
  };

  const handleTerm = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectTerm(value);
  };

  const searchInput = useRef("");

  const columns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "Date",
      width: "130px",
      selector: (row) => GetNepaliDate(row.ExamDate),
    },
    {
      name: "Room No.",
      width: "95px",
      selector: (row) => row.RoomNo,
    },
    {
      name: "Subject",

      selector: (row) => row.Subject,
    },
    {
      name: "Day",
      width: "100px",
      selector: (row) => row.Week,
    },
    {
      name: "Start",
      width: "110px",
      selector: (row) => row.StartTime,
    },
    {
      name: "End",
      width: "110px",
      selector: (row) => row.EndTime,
    },
    {
      name: "Shift",
      width: "90px",
      selector: (row) => row.ShiftType,
    },
    {
      name: "Action",
      center: true,
      width: "100px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <Tippy content="Edit">
                <i
                  className="fa fa-pencil solvespan--action"
                  onClick={() => handleEdit(row)}
                ></i>
              </Tippy>
              <Tippy content="Delete">
                <i
                  className="fa fa-trash-o rejectspan--action"
                  onClick={() => handleDelete(row.RoutineID)}
                ></i>
              </Tippy>
            </div>
          </>
        );
      },
      omit: hideAction,
    },
  ];

  const closePopup = (e) => {
    e.preventDefault();
    toggleEPopup();
    setExamFormValue(examValue);
    setExamFormError({});
    setEIsSubmit(false);
    setECloseChecked(false);
  };

  const closeEditPop = (e) => {
    e.preventDefault();
    toggleEditPopup();
    setExamFormError({});
    setEditIsSubmit(false);
    setECloseChecked(false);
  };

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["Title"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setExamRoutineList(srchResult);
      } else {
        setExamRoutineList({});
      }
    } else {
      setExamRoutineList(originalList);
    }
  };

  /* This is for export csv */

  const dataToExcel = examRoutineList.map((d, i) => ({
    "S.N.": i + 1,
    Date: d.ExamDate,
    "Room No.": d.RoomNo,
    Subject: d.Subject,
    Day: d.Week,
    Time: d.StartTime,
    Shift: d.ShiftType,
  }));

  const toExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(dataToExcel);
      var wscols = [
        { wch: 5 },
        { wch: 20 },
        { wch: 20 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ];
      worksheet["!cols"] = wscols;
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "Exam_Routine");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_" + "export" + EXCEL_EXTENSION
        );
      }
    });
  };

  //end

  //This is to export pdf

  const dataWithIndex = examRoutineList.map((d, i) => ({
    ...d,
    Index: i + 1,
  }));

  const cols = [
    { header: "S.N", field: "Index" },
    { header: "Date", field: "ExamDate" },
    { header: "Room No.", field: "RoomNo" },
    { header: "Subject", field: "Subject" },
    { header: "Day", field: "Week" },
    { header: "Time", field: "StartTime" },
    { header: "Shift", field: "ShiftType" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const toPdf = () => {
    const doc = new jsPDF("p", "pt", "a4", true);

    var midPage = doc.internal.pageSize.getWidth() / 2;

    // doc.addImage(`${userinfo.SchPhoto}`, "JPG", 40, 40, 70, 60);

    doc.setFontSize(18);
    doc.text(`${userinfo.SchName}`, midPage, 50, null, null, "center");

    doc.setFontSize(15);
    doc.text(`${userinfo.SchAddress}`, midPage, 68, null, null, "center");

    doc.setFontSize(10);
    doc.text(
      `Tel:${userinfo.Phone}, E-mail:${userinfo.Email}`,
      midPage,
      85,
      null,
      null,
      "center"
    );

    doc.autoTable({
      startY: 100,
      theme: "grid",
      columns: exportColumns,
      body: dataWithIndex,
    });
    doc.save("Exam_Routine.pdf");
  };
  //end

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />

      <>
        <div className="sec-dataTable">
          <div className="upper-dataTbl">
            <Tippy content="PDF">
              <button className="exportButtons ml-1" onClick={toPdf}>
                <i className="bi bi-file-earmark-pdf-fill exportButtonIconsPdf"></i>
              </button>
            </Tippy>
            <Tippy content="Excel">
              <button className="exportButtons" onClick={toExcel}>
                <i className="bi bi-file-earmark-excel-fill exportButtonIconsExcel"></i>
              </button>
            </Tippy>
          </div>
          <hr className="new-hr-new" />

          {loading ? (
            <Spinner />
          ) : (
            <DataTable
              columns={columns}
              data={examRoutineList}
              customStyles={customStyles}
              pagination
              fixedHeader
              fixedHeaderScrollHeight="280px"
              highlightOnHover
              pointerOnHover
              responsive
              dense
              striped
              subHeader
              subHeaderAlign="left"
              subHeaderComponent={
                <>
                  <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                    <label className="list-label">Class</label>
                    <select
                      style={{ fontSize: "11px", marginBottom: "3px" }}
                      name="snotifiaction"
                      value={selectClass}
                      onChange={handleClass}
                      className="form-control form-control-sm"
                      aria-label="Default select example "
                    >
                      <option
                        value=""
                        disabled
                        selected
                        style={{ fontSize: "11px" }}
                      >
                        Select Class
                      </option>
                      {classList.map((item) => (
                        <option key={item.ClassID} value={item.ClassID}>
                          {item.ClassName}
                        </option>
                      ))}
                    </select>
                    <i class="fas fa-angle-down  notificationpositon"></i>
                  </div>

                  <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                    <label className="list-label">Term</label>
                    <select
                      style={{ fontSize: "11px", marginBottom: "3px" }}
                      name="snotifiaction"
                      value={selectTerm}
                      onChange={handleTerm}
                      className="form-control form-control-sm"
                      aria-label="Default select example "
                    >
                      <option
                        value=""
                        disabled
                        selected
                        style={{ fontSize: "11px" }}
                      >
                        Select Term
                      </option>
                      {termList.map((item) => (
                        <option key={item.TermID} value={item.TermID}>
                          {item.TermName}
                        </option>
                      ))}
                    </select>
                    <i class="fas fa-angle-down  notificationpositon"></i>
                  </div>

                  <div
                    className="col-lg-2 offset-lg-6 offset-md-6 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
                    style={{
                      marginTop: "25px",
                      paddingRight: "1px",
                      marginBottom: "10px",
                    }}
                  >
                    <div className="d-flex">
                      <input
                        placeholder="Search"
                        ref={searchInput}
                        type="text"
                        className="form-control form-control-sm searchField"
                        onChange={searchHandler}
                      />
                    </div>
                  </div>
                </>
              }
            />
          )}
        </div>
      </>

      {popup && (
        <div className="modals">
          <div className="overlay"></div>

          <div className="notification-model-content">
            <div className="popUpHeader ">
              <div className="popUpHeaderText ">Add Exam Routine</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closePopup}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="inside-modal">
              <ExamRoutineForm closePopup={closePopup} />
            </div>
          </div>
        </div>
      )}

      {editPop && (
        <div className="modals">
          <div className="overlay"></div>

          <div className="notification-model-content">
            <div className="popUpHeader ">
              <div className="popUpHeaderText ">Edit Exam Routine</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closeEditPop}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="inside-modal">
              <ExamRoutineEdit closeEditPop={closeEditPop} />
            </div>
          </div>
        </div>
      )}

      {deletePopup.show && (
        <DeletePop
          handleDeleteTrue={handleDeleteTrue}
          handleDeleteFalse={handleDeleteFalse}
        />
      )}
    </>
  );
}
