import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";
import { Fetchdata } from "../hooks/getData";
import { showImgPreview } from "../hooks/ImagePreview";
import AcademicHeader from "../AcademicHeader";

export default function StudentGallery() {
  const { todayDate, AcademicYear, appURL } = useContext(SidebarContext);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const [loading, setLoading] = useState(true);

  const [albumList, setAlbumList] = useState([]);
  const [selectAlbum, setSelectAlbum] = useState("");

  const [photoList, setPhotoList] = useState([]);

  const [imgPrv, setImgPrv] = useState(false);
  const [imagePre, setImagePre] = useState("");

  const handleAlbum = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectAlbum(value);
  };

  useEffect(() => {
    getAlbumList();
  }, []);

  const getAlbumList = () => {
    const params = {
      SchID: userinfo.SchID,
      Flag: "S",
      ClassID: userinfo.ClassID.toString(),
      SectionID: userinfo.SectionID.toString(),
      MemID: userinfo.UserID.toString(),
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/gallery/album`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.AlbumLst ? result.AlbumLst : "";
        setAlbumList(postResult);
      } else {
        setAlbumList([]);
      }
    });
  };

  useEffect(() => {
    getPhoto();
  }, [selectAlbum]);

  const getPhoto = () => {
    const params = {
      SchID: userinfo.SchID,
      AlbumID: selectAlbum,
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/gallery/photo`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.AlbumLst ? result.AlbumLst : "";
        setLoading(false);
        setPhotoList(postResult);
      } else {
        setLoading(false);
        setPhotoList([]);
      }
    });
  };

  if (photoList.length > 0) {
    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
        />
        <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
          <div className="row">
            <div className="page-header">
              <div className="text-start  page-title">Gallery</div>
              <div className="page-date">
                <div className="sec-content">
                  <AcademicHeader
                    todayDate={todayDate}
                    AcademicYear={AcademicYear}
                  />
                </div>
              </div>
            </div>
          </div>
          <hr />
          <>
            <div className="sec-dataTable content-section main-content">
              <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                <label className="list-label">Album</label>
                <select
                  style={{ fontSize: "11px" }}
                  name="snotifiaction"
                  value={selectAlbum}
                  onChange={handleAlbum}
                  className="form-control form-control-sm"
                  aria-label="Default select example"
                >
                  <option
                    value=""
                    disabled
                    selected
                    style={{ fontSize: "11px" }}
                  >
                    Select Album
                  </option>
                  {albumList.map((item) => (
                    <option key={item.AlbumID} value={item.AlbumID}>
                      {item.AlbumName}
                    </option>
                  ))}
                </select>
                <i class="fas fa-angle-down  notificationpositon"></i>
              </div>
              <div className="basic-dyno-gallery">
                {photoList.map((item, i) => {
                  return (
                    <div className="profilecontentLogoGallery tl" key={i}>
                      <div className="profilemgmtImgGallery tl">
                        <img
                          src={item.PhotoUrl}
                          alt="dp"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setImagePre(item.PhotoUrl);
                            setImgPrv(true);
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        </div>
        {imgPrv &&
          showImgPreview({
            img: imagePre,
            setTrigger: setImgPrv,
          })}
      </>
    );
  } else {
    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
        />
        <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
          <div className="row">
            <div className="page-header">
              <div className="text-start  page-title">Gallery</div>
              <div className="page-date">
                <div className="sec-content">
                  <AcademicHeader
                    todayDate={todayDate}
                    AcademicYear={AcademicYear}
                  />
                </div>
              </div>
            </div>
          </div>
          <hr />
          <>
            <div className="sec-dataTable content-section main-content">
              <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                <label className="list-label">Album</label>
                <select
                  style={{ fontSize: "11px" }}
                  name="snotifiaction"
                  value={selectAlbum}
                  onChange={handleAlbum}
                  className="form-control form-control-sm"
                  aria-label="Default select example"
                >
                  <option
                    value=""
                    disabled
                    selected
                    style={{ fontSize: "11px" }}
                  >
                    Select Album
                  </option>

                  {albumList.map((item) => (
                    <option key={item.AlbumID} value={item.AlbumID}>
                      {item.AlbumName}
                    </option>
                  ))}
                </select>
                <i class="fas fa-angle-down  notificationpositon"></i>
              </div>
              <div className="text-center mt-4">
                <p>There are no records to display.</p>
              </div>
            </div>
          </>
        </div>
      </>
    );
  }
}
