import React, { useContext, useEffect, useState } from "react";
import { Fetchdata } from "../../hooks/getData";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { toast } from "react-toastify";
import DesignationContext from "./DesignationContext";

function DesignationState(props) {
  const { appURL } = useContext(SidebarContext);

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);
  const [designationTopicSearch, setDesignationTopicSearch] = useState(null);
  const [createTopicSearch, setCreateTopicSearch] = useState(null);

  const [selectDesignation, setSelectDesignation] = useState("-1");
  const [inputFields, setInputFields] = useState([]);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const [closeChecked, setCloseChecked] = useState(false);

  const designationValue = {
    order: "",
    topic: "",
  };

  const [designationFormValue, setDesignationFormValue] =
    useState(designationValue);
  const [designationFormError, setDesignationFormError] = useState({});
  const [isSubmit, setIsSubmit] = useState({});

  const createValue = {
    topic: "",
  };
  const [createFormValue, setCreateFormValue] = useState(createValue);
  const [createFormError, setCreateFormError] = useState({});
  const [createIsSubmit, setCreateIsSubmit] = useState({});

  // For popup
  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  // For create designation
  const [popup, setpopup] = useState(false);

  const togglePopup = () => {
    setpopup(!popup);
  };

  //Everything to pop up and delete the student list
  const [deletePopup, setDeletePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const [delData, setDelData] = useState();

  const handleDelete = async (data) => {
    setDelData(data);

    setDeletePopup({
      show: true,
      data,
    });
  };

  // This will perform the deletion and hide the Confirmation Box

  const handleDeleteTrue = async () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "R",
      DesignationID: delData.toString(),
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/staff-designation`, requestOptions).then(
      (result) => {
        result.json().then((resp) => {
          if (resp.StatusCode === 200) {
            getDesignationList();
            toast(resp.Message, {
              style: {
                color: "green",
                fontSize: "13px",
              },
            });
            setDeletePopup({
              show: false,
              data: null,
            });
          } else {
            toast(resp.Message, {
              style: {
                color: "red",
                fontSize: "13px",
              },
            });
          }
        });
      }
    );
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleDeleteFalse = () => {
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // Ends Here

  //API to create designation topic
  const fetchdata = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "I",
      Designation: designationFormValue.topic,
      Order: designationFormValue.order,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/designation`,
      Type: "POST",
    };
    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getDesignationTopicList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };

  // API for designation topic list
  const [designationTopicList, setDesignationTopicList] = useState([]);

  useEffect(() => {
    getDesignationTopicList();
  }, []);

  const getDesignationTopicList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/designation`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.DesignationLst ? result.DesignationLst : "";
        setLoading(false);
        setDesignationTopicList(postResult);
        setDesignationTopicSearch(postResult);
      } else {
        setLoading(false);
        setDesignationTopicList([]);
        setDesignationTopicSearch([]);
      }
    });
  };

  // Designation Edit topic
  const [titleId, setTitleId] = useState("");

  const editdata = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "U",
      Designation: designationFormValue.topic,
      DesignationID: titleId,
      Order: designationFormValue.order,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/designation`,
      Type: "POST",
    };
    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getDesignationTopicList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
        if (closeChecked === true) {
          toggleModal();
        }
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };

  //Everything to pop up and delete the topic designation topic list
  const [deleteTopicPopup, setDeleteTopicPopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const [delTopicData, setDelTopicData] = useState();

  const handleTopicDelete = async (data) => {
    setDelTopicData(data);

    setDeleteTopicPopup({
      show: true,
      data,
    });
  };

  // This will perform the deletion and hide the Confirmation Box

  const handleDeleteTopicTrue = async () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "R",
      DesignationID: delTopicData.toString(),
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/designation`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          getDesignationTopicList();
          toast(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
          setDeleteTopicPopup({
            show: false,
            data: null,
          });
        } else {
          toast(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleDeleteTopicFalse = () => {
    setDeleteTopicPopup({
      show: false,
      data: null,
    });
  };

  // Ends Here

  ////////////////////////////////////////API for create Designation start//////////////////////////////////

  //API to hit Teacher list
  const [teacherList, setTeacherList] = useState([]);

  useEffect(() => {
    teachList();
  }, []);

  const teachList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/teacher`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.teachingres ? result.teachingres : "";
        setLoading(false);
        setTeacherList(postResult);
        setCreateTopicSearch(postResult);
      } else {
        setLoading(false);
        setTeacherList([]);
        setCreateTopicSearch([]);
      }
    });
  };

  //API to create teaching subject
  const designationdata = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "I",
      DesignationID: createFormValue.topic,
      Staff: inputFields,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/staff-designation`,
      Type: "POST",
    };
    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getDesignationList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };

  // API for designation list
  const [designationList, setDesignationList] = useState([]);

  useEffect(() => {
    getDesignationList();
  }, [selectDesignation]);

  const getDesignationList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      DesignationID: selectDesignation,
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/staff-designation`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.DesignationLst ? result.DesignationLst : "";
        setLoading(false);
        setDesignationList(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        setDesignationList([]);
        setOriginalList([]);
      }
    });
  };

  return (
    <DesignationContext.Provider
      value={{
        modal,
        setModal,
        toggleModal,
        popup,
        setpopup,
        togglePopup,
        loading,
        originalList,
        deletePopup,
        handleDeleteTrue,
        handleDeleteFalse,
        handleDelete,
        designationFormValue,
        setDesignationFormValue,
        designationFormError,
        setDesignationFormError,
        isSubmit,
        designationValue,
        setIsSubmit,
        designationList,
        setDesignationList,
        designationTopicList,
        fetchdata,
        designationTopicSearch,
        setDesignationTopicList,
        setTitleId,
        editdata,
        deleteTopicPopup,
        handleTopicDelete,
        handleDeleteTopicTrue,
        handleDeleteTopicFalse,
        createFormValue,
        setCreateFormValue,
        createFormError,
        setCreateFormError,
        createIsSubmit,
        setCreateIsSubmit,
        createValue,
        teacherList,
        setTeacherList,
        createTopicSearch,
        selectDesignation,
        setSelectDesignation,
        inputFields,
        setInputFields,
        designationdata,
        closeChecked,
        setCloseChecked,
      }}
    >
      {props.children}
    </DesignationContext.Provider>
  );
}

export default DesignationState;
