import React, { useContext, useEffect, useState } from "react";
import { Fetchdata } from "../../hooks/getData";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { toast } from "react-toastify";
import QuickAssignmentContext from "./QuickAssignmentContext";
import { GetEnglishDate } from "../../hooks/dateConvertor";

function QuickAssignmentState(props) {
  const { appURL, todayDate } = useContext(SidebarContext);

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);
  const [checkOriginalList, setCheckOriginalList] = useState(null);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const [closeChecked, setCloseChecked] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptionsOne, setSelectedOptionsOne] = useState([]);

  const assignmentValue = {
    class: "",
    section: "",
    subject: "",
    teacher: "",
    assignmentDate: "",
    notifyUser: false,
  };
  const [assignmentFormValue, setAssignmentFormValue] =
    useState(assignmentValue);
  const [assignmentFormError, setAssignmentFormError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [editIsSubmit, setEditIsSubmit] = useState(false);

  const [popup, setPopup] = useState(false);
  const togglePopup = () => {
    setPopup(!popup);
  };

  const [checkPop, setCheckPop] = useState(false);
  const toggleCheckPopup = () => {
    setCheckPop(!checkPop);
  };

  const [viewPop, setViewPop] = useState(false);
  const toggleViewPopup = () => {
    setViewPop(!viewPop);
  };

  const [isUploaded, setIsUploaded] = useState(false);
  const [typeFile, setTypeFile] = useState("");
  const [image, setImage] = useState("");

  const [classList, setClassList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [teacherList, setTeacherList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);

  const [selectClass, setSelectClass] = useState("");
  const [selectSection, setSelectSection] = useState("");
  const [selectTeacher, setSelectTeacher] = useState("");
  const [selectSubject, setSelectSubject] = useState("");

  const [section, setSection] = useState([]);
  const [dateValue, setDateValue] = useState(todayDate);

  // For multiple image upload
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedBase64, setSelectedBase64] = useState([]);

  // state for nested arrays in assignment ADMIN

  const [newState, setNewState] = useState([]);
  const [option, setoption] = useState([]);
  // state for nested arrays in assignment ADMIN ENDS

  useEffect(() => {
    getClassList();
  }, []);

  const getClassList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID.toString() : "-1",
      DFlag: "C",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setClassList(postResult);
      } else {
        setClassList([]);
      }
    });
  };

  useEffect(() => {
    getSectionList();
  }, [selectClass]);

  const getSectionList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID.toString() : "-1",
      ClassID: selectClass,
      DFlag: "S",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setSectionList(postResult);
      } else {
        setSectionList([]);
      }
    });
  };

  //API to hit Teacher list

  useEffect(() => {
    teachList();
  }, [selectedOptionsOne, selectClass]);

  const teachList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID.toString() : "-1",
      DFlag: "TL",
      Flag: "S",
      ClassID: selectClass,
      SectionID: selectedOptionsOne,
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setTeacherList(postResult);
      } else {
        setTeacherList([]);
      }
    });
  };

  //API to hit Subject List

  useEffect(() => {
    getSubjectList();
  }, [selectClass, selectTeacher, selectedOptionsOne]);

  const getSubjectList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID:
        userinfo.UserType === "T" ? userinfo.UserID.toString() : selectTeacher,
      ClassID: selectClass,
      SectionID: selectedOptionsOne,
      DFlag: "SB",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };
    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";

        setSubjectList(postResult);
      } else {
        setSubjectList([]);
      }
    });
  };

  //API to create assignment

  // const fileType = typeFile.split(".")
  // const fileName = fileType[0]
  // const dot = "."
  // const fileExt = dot.concat(fileType[1])

  const fetchdata = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "i",
      ClassID: assignmentFormValue.class,
      SectionID:
        userinfo.UserType === "T" ? section : assignmentFormValue.section,
      SubjectID: assignmentFormValue.subject,
      AssignmentDate: assignmentFormValue.assignmentDate,
      IsNotify: assignmentFormValue.notifyUser === true ? "Y" : "N",
      Assignments: inputFields,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/quick-assignment`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getAssignmentList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };

  // API for assignment List
  const [assignmentList, setAssignmentList] = useState([]);

  useEffect(() => {
    getAssignmentList();
  }, [
    selectClass,
    selectedOptions,
    selectSubject,
    selectTeacher,
    selectedOptionsOne,
    dateValue,
  ]);

  const getAssignmentList = () => {
    const params = {
      // TeacherID: userinfo.UserType === "T" ? userinfo.UserID.toString() : selectTeacher,

      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID.toString() : "-1",
      Flag: "S",
      ClassID: selectClass,
      SectionID: selectedOptionsOne,
      SubjectID: selectSubject,
      AssignmentDate: dateValue,
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/quick-assignment`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.AssignmentLst ? result.AssignmentLst : "";
        setLoading(false);
        setAssignmentList(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        setAssignmentList([]);
        setOriginalList([]);
      }
    });
  };

  // const [eventId, setEventId] = useState("");

  // const handleEdit = (data) => {
  //     setCheckPop(!checkPop);

  //     setEventId(data.EventID)
  //     setAssignmentFormValue({
  //         title: data.Title,
  //         description: data.Description,
  //         fromDate: data.EngDateFrom,
  //         toDate: data.EngDateTo,
  //         fromTime: data.TimeStart,
  //         toTime: data.TimeEnd,
  //         venue: data.Venue,
  //         email: data.Email,
  //         contact: data.Contact,
  //         category: data.Category,
  //         organizer: data.OrganizedBy,
  //         latitude: data.Latitude,
  //         longitude: data.Longitude,
  //     })
  //     setImage(data.Banner)
  //     setEditIsSubmit(false);
  // }

  //API to edit event
  // const editdata = () => {
  //     const dataForm = {
  //         SchID: userinfo.SchID,
  //         UserID: userinfo.UserID.toString(),
  //         Flag: "U",
  //         DFlag: "E",
  //         Banner: image !== null ? image.split(',')[1] : "",
  //        Academicyear: userinfo.AcademicYear,
  //  FetchURL: `${appURL}api/admin/event`,
  //         Type: "POST",
  //     };

  //     Fetchdata(dataForm).then(function (result) {

  //         if (result.StatusCode === 200) {
  //             getAssignmentList();
  //             toast(result.Message, {
  //                 style: {
  //                     color: "green",
  //                     fontSize: "13px",
  //                 },
  //             });
  //         } else {
  //             toast("Error: " + result.Message, {
  //                 style: {
  //                     color: "red",
  //                     fontSize: "13px",
  //                 },
  //             });
  //         }
  //     });
  // }

  //Everything to pop up and delete the assignment list
  const [deletePopup, setDeletePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const [delData, setDelData] = useState();

  const handleDelete = async (data) => {
    setDelData(data);

    setDeletePopup({
      show: true,
      data,
    });
  };

  // This will perform the deletion and hide the Confirmation Box

  const handleDeleteTrue = async () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "R",
      AssignmentID: delData.toString(),
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/quick-assignment`, requestOptions).then(
      (result) => {
        result.json().then((resp) => {
          if (resp.StatusCode === 200) {
            getAssignmentList();
            toast(resp.Message, {
              style: {
                color: "green",
                fontSize: "13px",
              },
            });
          } else {
            toast(resp.Message, {
              style: {
                color: "red",
                fontSize: "13px",
              },
            });
          }
        });
      }
    );
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleDeleteFalse = () => {
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // Ends Here

  // API to hit Check Assignment List

  const [inputFields, setInputFields] = useState([]);

  return (
    <QuickAssignmentContext.Provider
      value={{
        originalList,
        loading,
        setLoading,
        deletePopup,
        handleDeleteTrue,
        handleDeleteFalse,
        handleDelete,
        popup,
        setPopup,
        togglePopup,
        assignmentFormValue,
        setAssignmentFormValue,
        assignmentFormError,
        setAssignmentFormError,
        isSubmit,
        assignmentValue,
        setIsSubmit,
        setAssignmentList,
        isUploaded,
        setIsUploaded,
        typeFile,
        setTypeFile,
        image,
        setImage,
        editIsSubmit,
        setEditIsSubmit,
        fetchdata,
        assignmentList,
        checkPop,
        setCheckPop,
        toggleCheckPopup,
        closeChecked,
        setCloseChecked,
        classList,
        sectionList,
        teacherList,
        selectClass,
        setSelectClass,
        selectSection,
        setSelectSection,
        selectTeacher,
        setSelectTeacher,
        subjectList,
        selectSubject,
        setSelectSubject,
        selectedOptions,
        setSelectedOptions,
        section,
        setSection,
        selectedOptionsOne,
        setSelectedOptionsOne,

        checkOriginalList,
        setCheckOriginalList,

        toggleViewPopup,
        viewPop,
        setViewPop,
        selectedImages,
        setSelectedImages,
        selectedBase64,
        setSelectedBase64,

        newState,
        setNewState,
        option,
        setoption,

        inputFields,
        setInputFields,

        dateValue,
        setDateValue,
      }}
    >
      {props.children}
    </QuickAssignmentContext.Provider>
  );
}

export default QuickAssignmentState;
