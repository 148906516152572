import { useContext, useState, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import AboutSchool from "./component/AboutSchool/AboutSchool";
import AcademicList from "./component/Academic/AcademicList";
import AdminLeave from "./component/AdminLeave/AdminLeave";
import AlbumList from "./component/Album/AlbumList";
import AssignmentList from "./component/Assignment/AssignmentList";
import Attendance from "./component/Attendance/Attendance";
import AuthContext from "./component/auth-context";
import BlogList from "./component/Blog/BlogList";
import AccountState from "./component/contextfolder/AccountContext/AccountState";
import AdminLeaveState from "./component/contextfolder/AdminLeaveContext/AdminLeaveState";
import AlbumState from "./component/contextfolder/AlbumContext/AlbumState";
import AssignmentState from "./component/contextfolder/AssignmentContext/AssignmentState";
import AttendanceState from "./component/contextfolder/AttendanceContext/AttendanceState";
import BlogState from "./component/contextfolder/BlogContext/BlogState";
import CalendarEventState from "./component/contextfolder/CalendarEventContext/CalendarEventState";
import ClassRoutineState from "./component/contextfolder/ClassRoutineContext/ClassRoutineState";
import DesignationState from "./component/contextfolder/DesignationContext/DesignationState";
import EventState from "./component/contextfolder/EventContext/EventState";
import ExamRoutineState from "./component/contextfolder/ExamRoutineContext/ExamRoutineState";
import LeaveApplicationState from "./component/contextfolder/LeaveApplicationContext/LeaveApplicationState";
import MarksInputState from "./component/contextfolder/MarksInputContext/MarksInputState";
import NotificationState from "./component/contextfolder/NotificationContext/NotificationState";
import OnlineClassState from "./component/contextfolder/OnlineClassContext/OnlineClassState";
import ReportState from "./component/contextfolder/ReportContext/ReportState";
import RoutineState from "./component/contextfolder/RoutineContext/RoutineState";
import SliderState from "./component/contextfolder/SliderContext/SliderState";
import StudentState from "./component/contextfolder/StudentContext/StudentState";
import StudyMaterialState from "./component/contextfolder/StudyMaterialContext/StudyMaterialState";
import GalleryState from "./component/contextfolder/GalleryContext/GalleryState";
import SubjectState from "./component/contextfolder/SubjectContext/SubjectState";
import SuggestionState from "./component/contextfolder/SuggestionContext/SuggestionState";
import TeacherState from "./component/contextfolder/TeacherContext/TeacherState";
import Dashboard from "./component/DashboardPage/Dashboard";
import DesignationList from "./component/Designation/DesignationList";
import Event from "./component/Event/EventList";
import GalleryList from "./component/GalleryPhoto/GalleryList";
import LandingPage from "./component/Landing/LandingPage";
import Layout from "./component/Layout";
import LeaveApplicationList from "./component/LeaveApplication/LeaveApplicationList";
import Login from "./component/Login";
import NotificationList from "./component/Notification/NotificationList";
import OnlineClass from "./component/OnlineClass/OnlineClass";
import Report from "./component/Report/Report";
import Routine from "./component/Routine/Routine";
import SidebarState from "./component/sidebarcontext/SidebarState";
import SliderList from "./component/Slider/SliderList";
import StudentList from "./component/Student/StudentList";
import StudyMaterial from "./component/StudyMaterial/StudyMaterial";
import SubjectList from "./component/Subject/SubjectList";
import SuggestionList from "./component/Suggestion/SuggestionList";
import TabbedView from "./component/TabbedView/TabbedView";
import TeacherList from "./component/Teacher/TeacherList";
import StudentDashboard from "./component/StudentDashboard/StudentDashboard";
import StudentRoutine from "./component/StudentRoutine/StudentRoutine";
import StudentNotification from "./component/StudentNotification/StudentNotification";
import StudentAccount from "./component/StudentAccount/StudentAccount";
import StudentReport from "./component/StudentReportCard/StudentReport";
import StudentOnlineClass from "./component/StudentOnlineClass/StudentOnlineClass";
import StudentStudyMaterial from "./component/StudentStudyMaterial/StudentStudyMaterial";
import StudentSuggestionList from "./component/StudentSuggestion/StudentSuggestionList";
import StudentProfile from "./component/StudentProfile/StudentProfile";
import StudentAssignmentState from "./component/contextfolder/StudentAssignmentContext/StudentAssignmentState";
import StudentAssignmentList from "./component/StudentAssignment/StudentAssignmentList";
import StudentBlog from "./component/StudentBlog/StudentBlog";
import StudentAbout from "./component/StudentAbout/StudentAbout";
import StudentGallery from "./component/StudentGallery/StudentGallery";
import StudentAttendance from "./component/StudentAttendance/StudentAttendance";
import StudentAttendanceSummary from "./component/StudentAttendance/StudentAttendanceSummary";
import AdminRoutine from "./component/AdminRoutine/AdminRoutine";
import AdminAccount from "./component/Account/AdminAccount";
import NoMatchPage from "./component/NoMatchPage";
import ExtraActivityState from "./component/contextfolder/ExtraActivityContext/ExtraActivityState";
import MarksCombined from "./component/AdminMarks/MarksCombined";
import EventCombined from "./component/EventCombined/EventCombined";
import TeacherAttendance from "./component/TeacherAttendance/TeacherAttendance";
import TeacherAttendanceState from "./component/contextfolder/TeacherAttendanceContext/TeacherAttendanceState";
import Payment from "./component/Payment/Payment";
import PaymentState from "./component/contextfolder/PaymentContext/PaymentState";
import StudentPaymentState from "./component/contextfolder/StudentPaymentContext/StudentPaymentState";
import StudentPayment from "./component/StudentPayment/StudentPayment";
import ExtraActMonthState from "./component/contextfolder/ExtraActMonthContext/ExtraActMonthState";
import MenuState from "./component/contextfolder/MenuContext/MenuState";
import Menu from "./component/Menu/Menu";
import JobState from "./component/contextfolder/JobContext/JobState";
import Job from "./component/Job/Job";
import StudentMenu from "./component/StudentMenu/StudentMenu";
import StudentMenuState from "./component/contextfolder/StudentMenuContext/StudentMenuState";
import StudentOrder from "./component/StudentOrder/Order";
import CanteenDashboard from "./component/CanteenDashboard/CanteenDashboard";
import OrderState from "./component/contextfolder/OrderContext/OrderState";
import AdminOrder from "./component/AdminOrder/AdminOrder";
import OrderPayment from "./component/OrderPayment/OrderPayment";
import ChapterState from "./component/contextfolder/ChapterContext/ChapterState";
import ChapterList from "./component/Chapter/ChapterList";
import CourseState from "./component/contextfolder/CourseContext/CourseState";
import CourseList from "./component/Course/CourseList";
import StudentCourse from "./component/StudentCourse/StudentCourse";
import UploadRoutineState from "./component/contextfolder/UploadRoutineContext/UploadRoutineState";
import AdminState from "./component/contextfolder/AdminContext/AdminState";
import AdminList from "./component/Admin/AdminList";
import LogBookList from "./component/LogBook/Manual/LogBookList";
import LogBookState from "./component/contextfolder/LogBookContext/LogBookState";
import StudentLogBookList from "./component/StudentLogBook/StudentLogBookList";
import StudentLogBookState from "./component/contextfolder/StudentLogBookContext/StudentLogBookState";
import DeviceCodeState from "./component/contextfolder/DeviceCodeContext/DeviceCodeState";
import Absent from "./component/Attendance/Absent";
import AttendanceReport from "./component/Attendance/AttendanceReport";
import MonthlyReport from "./component/Attendance/MonthlyReport";
import EliteJob from "./component/EliteJob/EliteJob";
import EliteJobState from "./component/contextfolder/EliteJobContext/EliteJobState";
import CandidateState from "./component/contextfolder/CandidateContext/CandidateState";
import AdmissionList from "./component/Admission/AdmissionList";
import AdmissionState from "./component/contextfolder/AdmissionContext/AdmissionState";
import PublicAdmission from "./component/PublicAdmission/PublicAdmission";
import AttenInputState from "./component/contextfolder/AttenInputContext/AttenInputState";
import MonthlySummary from "./component/Attendance/MonthlySummary";
import AppUsage from "./component/AppUsage/AppUsage";
import StaffList from "./component/Staff/StaffList";
import HelpDeskState from "./component/contextfolder/HelpDeskContext/HelpDeskState";
import HelpDeskList from "./component/HelpDesk/HelpDeskList";
import EasySchool from "./component/EasySchool/EasySchool";
import LogBook from "./component/LogBook/Logbook";
import LogPhotoState from "./component/contextfolder/LogPhotoContext/LogPhotoState";
import AcademicYearState from "./component/contextfolder/AcademicYearContext/AcademicYearState";
import AcademicYearList from "./component/AcademicYear/AcademicYearList";
import SingleDesign from "./component/Landing/SingleDesign";
import SyllabusPdfList from "./component/SyllabusPdf/SyllabusPdfList";
import SyllabusPdfState from "./component/contextfolder/SyllabusPdfContext/SyllabusPdfState";
import BusList from "./component/GPS/Bus/BusList";
import BusState from "./component/contextfolder/BusContext/BusState";
import QrPaymentList from "./component/QrPayment/QrPaymentList";
import QrPaymentState from "./component/contextfolder/QrPaymentContext/QrPaymentState";
import AssignBusState from "./component/contextfolder/AssignBusContext/AssignBusState";
import AssignBusList from "./component/GPS/AssignBus/AssignBusList";
import QuickAssignmentState from "./component/contextfolder/QuickAssignmentContext/QuickAssignmentState";
import QuickAssignmentList from "./component/QuickAssignment/QuickAssignmentList";

function App() {
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const rolePermission = JSON.parse(localStorage.getItem("rolePermission"));

  const [isPayment, setisPayment] = useState(false);
  const [isReportCard, setIsReportCard] = useState(false);

  // useEffect(() => {
  //   if (authCtx.isLoggedIn) {

  //     if (userinfo.UserType === "A") {

  //       setisPayment(rolePermission.some(
  //         (role) =>
  //           role.UserID === userinfo.UserID &&
  //           role.Permission === "ONLINEPAYMENTREPORT"
  //       ))

  //       setIsReportCard(rolePermission.some(
  //         (role) =>
  //           role.UserID === userinfo.UserID &&
  //           role.Permission === "REPORTCARD"
  //       ))
  //     }
  //   }
  // }, [])

  const auth = localStorage.getItem("userInfo");
  const authCtx = useContext(AuthContext);

  return (
    <>
      <Routes>
        {!authCtx.isLoggedIn && <Route path="/" element={<LandingPage />} />}
        <Route path="/login" element={<Login />} />
        <Route path="/404" element={<NoMatchPage />} />
        {!authCtx.isLoggedIn && (
          <Route path="/admission/:slug" element={<PublicAdmission />} />
        )}
        {!authCtx.isLoggedIn && (
          <Route path="/school/:slug" element={<EasySchool />} />
        )}
        {/* {!authCtx.isLoggedIn && (
          <Route path="/singledesign" element={<SingleDesign />} />
        )} */}
        {!authCtx.isLoggedIn && (
          <Route path="*" element={<Navigate to="/login" replace />} />
        )}

        {!authCtx.isLoggedIn && <Route path="/login" element={<Login />} />}
      </Routes>

      <div className="App">
        {authCtx.isLoggedIn && (
          <SidebarState>
            <AcademicYearState>
              <HelpDeskState>
                <LeaveApplicationState>
                  <AdminLeaveState>
                    <RoutineState>
                      <SubjectState>
                        <EventState>
                          <AdminState>
                            <AdmissionState>
                              <TeacherState>
                                <StudentState>
                                  <AccountState>
                                    <SliderState>
                                      <AttendanceState>
                                        <CalendarEventState>
                                          <UploadRoutineState>
                                            <ClassRoutineState>
                                              <ExamRoutineState>
                                                <ReportState>
                                                  <SuggestionState>
                                                    <DesignationState>
                                                      <BlogState>
                                                        <AlbumState>
                                                          <LogPhotoState>
                                                            <LogBookState>
                                                              <NotificationState>
                                                                <QuickAssignmentState>
                                                                  <AssignmentState>
                                                                    <OnlineClassState>
                                                                      <MarksInputState>
                                                                        <AttenInputState>
                                                                          <StudentLogBookState>
                                                                            <StudyMaterialState>
                                                                              <GalleryState>
                                                                                <StudentAssignmentState>
                                                                                  <EliteJobState>
                                                                                    <CandidateState>
                                                                                      <DeviceCodeState>
                                                                                        <ExtraActivityState>
                                                                                          <ExtraActMonthState>
                                                                                            <TeacherAttendanceState>
                                                                                              <PaymentState>
                                                                                                <StudentPaymentState>
                                                                                                  <MenuState>
                                                                                                    <JobState>
                                                                                                      <StudentMenuState>
                                                                                                        <OrderState>
                                                                                                          <ChapterState>
                                                                                                            <SyllabusPdfState>
                                                                                                              <BusState>
                                                                                                                <AssignBusState>
                                                                                                                  <QrPaymentState>
                                                                                                                    <CourseState>
                                                                                                                      <Layout>
                                                                                                                        <Routes>
                                                                                                                          <Route
                                                                                                                            path="*"
                                                                                                                            element={
                                                                                                                              <NoMatchPage />
                                                                                                                            }
                                                                                                                          />
                                                                                                                          <Route
                                                                                                                            path="/404"
                                                                                                                            element={
                                                                                                                              <NoMatchPage />
                                                                                                                            }
                                                                                                                          />

                                                                                                                          {userinfo &&
                                                                                                                          userinfo.UserType ===
                                                                                                                            "S" ? (
                                                                                                                            <>
                                                                                                                              <Route
                                                                                                                                path="/"
                                                                                                                                element={
                                                                                                                                  <StudentDashboard />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/student-dashboard"
                                                                                                                                element={
                                                                                                                                  <StudentDashboard />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/student-routine"
                                                                                                                                element={
                                                                                                                                  <StudentRoutine />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/student-payment"
                                                                                                                                element={
                                                                                                                                  <StudentPayment />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/student-notification"
                                                                                                                                element={
                                                                                                                                  <StudentNotification />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/student-account"
                                                                                                                                element={
                                                                                                                                  <StudentAccount />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/student-report"
                                                                                                                                element={
                                                                                                                                  <StudentReport />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/student-onlineclass"
                                                                                                                                element={
                                                                                                                                  <StudentOnlineClass />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/student-study-material"
                                                                                                                                element={
                                                                                                                                  <StudentStudyMaterial />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/student-suggestion"
                                                                                                                                element={
                                                                                                                                  <StudentSuggestionList />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/student-profile"
                                                                                                                                element={
                                                                                                                                  <StudentProfile />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/student-about"
                                                                                                                                element={
                                                                                                                                  <StudentAbout />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/student-assignment"
                                                                                                                                element={
                                                                                                                                  <StudentAssignmentList />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/student-blog"
                                                                                                                                element={
                                                                                                                                  <StudentBlog />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/student-leave-application"
                                                                                                                                element={
                                                                                                                                  <LeaveApplicationList />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/student-gallery"
                                                                                                                                element={
                                                                                                                                  <StudentGallery />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/student-attendance-report"
                                                                                                                                element={
                                                                                                                                  <StudentAttendance />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/student-attendance-summary"
                                                                                                                                element={
                                                                                                                                  <StudentAttendanceSummary />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/student-cafeteria"
                                                                                                                                element={
                                                                                                                                  <StudentMenu />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/student-order"
                                                                                                                                element={
                                                                                                                                  <StudentOrder />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/student-course"
                                                                                                                                element={
                                                                                                                                  <StudentCourse />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/student-log-book"
                                                                                                                                element={
                                                                                                                                  <StudentLogBookList />
                                                                                                                                }
                                                                                                                              />
                                                                                                                            </>
                                                                                                                          ) : userinfo.UserType ===
                                                                                                                            "C" ? (
                                                                                                                            <>
                                                                                                                              <Route
                                                                                                                                path="/"
                                                                                                                                element={
                                                                                                                                  <CanteenDashboard />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/canteen-dashboard"
                                                                                                                                element={
                                                                                                                                  <CanteenDashboard />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/canteen-menu"
                                                                                                                                element={
                                                                                                                                  <Menu />
                                                                                                                                }
                                                                                                                              />
                                                                                                                            </>
                                                                                                                          ) : (
                                                                                                                            <>
                                                                                                                              <Route
                                                                                                                                path="/"
                                                                                                                                element={
                                                                                                                                  <Dashboard />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/dashboard"
                                                                                                                                element={
                                                                                                                                  <Dashboard />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/appusage"
                                                                                                                                element={
                                                                                                                                  <AppUsage />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/student-list"
                                                                                                                                element={
                                                                                                                                  <StudentList />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/admin-list"
                                                                                                                                element={
                                                                                                                                  <AdminList />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/teacher-list"
                                                                                                                                element={
                                                                                                                                  <TeacherList />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/menu"
                                                                                                                                element={
                                                                                                                                  <Menu />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/admin-order"
                                                                                                                                element={
                                                                                                                                  <AdminOrder />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/job"
                                                                                                                                element={
                                                                                                                                  <EliteJob />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              {/* <Route
                                                                                                                      path="/singledesign"
                                                                                                                      element={
                                                                                                                        <SingleDesign />
                                                                                                                      }
                                                                                                                    /> */}
                                                                                                                              <Route
                                                                                                                                path="/routine"
                                                                                                                                element={
                                                                                                                                  <Routine />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/qr-payment"
                                                                                                                                element={
                                                                                                                                  <QrPaymentList />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/bus"
                                                                                                                                element={
                                                                                                                                  <BusList />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/assign-bus"
                                                                                                                                element={
                                                                                                                                  <AssignBusList />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/syllabus-pdf"
                                                                                                                                element={
                                                                                                                                  <SyllabusPdfList />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/chapter"
                                                                                                                                element={
                                                                                                                                  <ChapterList />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/course"
                                                                                                                                element={
                                                                                                                                  <CourseList />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/subject"
                                                                                                                                element={
                                                                                                                                  <SubjectList />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/class-section-term-subject"
                                                                                                                                element={
                                                                                                                                  <TabbedView />
                                                                                                                                }
                                                                                                                              />

                                                                                                                              <Route
                                                                                                                                path="/admin-leave"
                                                                                                                                element={
                                                                                                                                  <AdminLeave />
                                                                                                                                }
                                                                                                                              />

                                                                                                                              <Route
                                                                                                                                path="/order-payment"
                                                                                                                                element={
                                                                                                                                  <OrderPayment />
                                                                                                                                }
                                                                                                                              />

                                                                                                                              <Route
                                                                                                                                path="/leave-application"
                                                                                                                                element={
                                                                                                                                  <LeaveApplicationList />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/slider"
                                                                                                                                element={
                                                                                                                                  <SliderList />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/admin--account"
                                                                                                                                element={
                                                                                                                                  <AdminAccount />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/admin--marks"
                                                                                                                                element={
                                                                                                                                  <MarksCombined />
                                                                                                                                }
                                                                                                                              />

                                                                                                                              <Route
                                                                                                                                path="/attendance"
                                                                                                                                element={
                                                                                                                                  <Attendance />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/monthlySummary"
                                                                                                                                element={
                                                                                                                                  <MonthlySummary />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/absent"
                                                                                                                                element={
                                                                                                                                  <Absent />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              {/* <Route
                                                                                                        path="/attendanceReport"
                                                                                                        element={
                                                                                                          <AttendanceReport />
                                                                                                        }
                                                                                                      /> */}
                                                                                                                              <Route
                                                                                                                                path="/monthlyReport"
                                                                                                                                element={
                                                                                                                                  <MonthlyReport />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/teacherAttendance"
                                                                                                                                element={
                                                                                                                                  <TeacherAttendance />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              {!isReportCard && (
                                                                                                                                <Route
                                                                                                                                  path="/report"
                                                                                                                                  element={
                                                                                                                                    <Report />
                                                                                                                                  }
                                                                                                                                />
                                                                                                                              )}

                                                                                                                              <Route
                                                                                                                                path="/admin-routine"
                                                                                                                                element={
                                                                                                                                  <AdminRoutine />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/admin-event"
                                                                                                                                element={
                                                                                                                                  <EventCombined />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              {/* <Route path="/album" element={<AlbumList />} /> */}
                                                                                                                              <Route
                                                                                                                                path="/suggestion"
                                                                                                                                element={
                                                                                                                                  <SuggestionList />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/academic"
                                                                                                                                element={
                                                                                                                                  <AcademicList />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/designation"
                                                                                                                                element={
                                                                                                                                  <DesignationList />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/blog"
                                                                                                                                element={
                                                                                                                                  <BlogList />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              {!isPayment && (
                                                                                                                                <Route
                                                                                                                                  path="/payment"
                                                                                                                                  element={
                                                                                                                                    <Payment />
                                                                                                                                  }
                                                                                                                                />
                                                                                                                              )}
                                                                                                                              <Route
                                                                                                                                path="/notification"
                                                                                                                                element={
                                                                                                                                  <NotificationList />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/online"
                                                                                                                                element={
                                                                                                                                  <OnlineClass />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/teacher-school"
                                                                                                                                element={
                                                                                                                                  <StudentAbout />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/about-school"
                                                                                                                                element={
                                                                                                                                  <AboutSchool />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/assignment"
                                                                                                                                element={
                                                                                                                                  <AssignmentList />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/quick-assignment"
                                                                                                                                element={
                                                                                                                                  <QuickAssignmentList />
                                                                                                                                }
                                                                                                                              />

                                                                                                                              <Route
                                                                                                                                path="/study-material"
                                                                                                                                element={
                                                                                                                                  <StudyMaterial />
                                                                                                                                }
                                                                                                                              />

                                                                                                                              <Route
                                                                                                                                path="/gallery-photo"
                                                                                                                                element={
                                                                                                                                  <GalleryList />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/teacher-suggestion"
                                                                                                                                element={
                                                                                                                                  <StudentSuggestionList />
                                                                                                                                }
                                                                                                                              />

                                                                                                                              <Route
                                                                                                                                path="/log-book"
                                                                                                                                element={
                                                                                                                                  <LogBook />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/sch-admission"
                                                                                                                                element={
                                                                                                                                  <AdmissionList />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/sch-staff"
                                                                                                                                element={
                                                                                                                                  <StaffList />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/help-desk"
                                                                                                                                element={
                                                                                                                                  <HelpDeskList />
                                                                                                                                }
                                                                                                                              />
                                                                                                                              <Route
                                                                                                                                path="/academic-year"
                                                                                                                                element={
                                                                                                                                  <AcademicYearList />
                                                                                                                                }
                                                                                                                              />
                                                                                                                            </>
                                                                                                                          )}
                                                                                                                        </Routes>
                                                                                                                      </Layout>
                                                                                                                    </CourseState>
                                                                                                                  </QrPaymentState>
                                                                                                                </AssignBusState>
                                                                                                              </BusState>
                                                                                                            </SyllabusPdfState>
                                                                                                          </ChapterState>
                                                                                                        </OrderState>
                                                                                                      </StudentMenuState>
                                                                                                    </JobState>
                                                                                                  </MenuState>
                                                                                                </StudentPaymentState>
                                                                                              </PaymentState>
                                                                                            </TeacherAttendanceState>
                                                                                          </ExtraActMonthState>
                                                                                        </ExtraActivityState>
                                                                                      </DeviceCodeState>
                                                                                    </CandidateState>
                                                                                  </EliteJobState>
                                                                                </StudentAssignmentState>
                                                                              </GalleryState>
                                                                            </StudyMaterialState>
                                                                          </StudentLogBookState>
                                                                        </AttenInputState>
                                                                      </MarksInputState>
                                                                    </OnlineClassState>
                                                                  </AssignmentState>
                                                                </QuickAssignmentState>
                                                              </NotificationState>
                                                            </LogBookState>
                                                          </LogPhotoState>
                                                        </AlbumState>
                                                      </BlogState>
                                                    </DesignationState>
                                                  </SuggestionState>
                                                </ReportState>
                                              </ExamRoutineState>
                                            </ClassRoutineState>
                                          </UploadRoutineState>
                                        </CalendarEventState>
                                      </AttendanceState>
                                    </SliderState>
                                  </AccountState>
                                </StudentState>
                              </TeacherState>
                            </AdmissionState>
                          </AdminState>
                        </EventState>
                      </SubjectState>
                    </RoutineState>
                  </AdminLeaveState>
                </LeaveApplicationState>
              </HelpDeskState>
            </AcademicYearState>
          </SidebarState>
        )}
      </div>
    </>
  );
}

export default App;
