import React, { useContext, useRef } from "react";
import '../../css/Commission.css';
import Spinner from "../loading/spinner";
import { formatNumber } from "../hooks/drCr";
import StudentContext from "../contextfolder/StudentContext/StudentContext";
import SidebarContext from "../sidebarcontext/SidebarContext";


export default function PaymentTab() {

    const { currentYear } = useContext(SidebarContext);

    const { paymentLoad, paymentSearch, paymentReportList, setPaymentReportList, selectYear, setSelectYear, selectMonth, setSelectMonth } = useContext(StudentContext);


    let amount = paymentReportList.reduce(function (prev, current) {
        return prev + parseFloat(current.Amount);
    }, 0);

    let date = []
    for (let i = 2075; i <= currentYear; i++) {
        date.push(i);
    }

    const handleYear = (e) => {
        const target = e.target;
        const value = target.value;
        setSelectYear(value);
        setSelectMonth("")
    }

    const handleMonth = (e) => {
        const target = e.target;
        const value = target.value;
        setSelectMonth(value);
    }


    const searchInput = useRef("");


    const searchHandler = (e) => {
        e.preventDefault();

        const srchQuery = searchInput.current.value.toLowerCase();
        if (srchQuery) {

            let srchResult = paymentSearch.filter((list) => {
                return list["Gateway"].toLowerCase().includes(srchQuery);
            });

            if (srchResult) {

                setPaymentReportList(srchResult);
            } else {
                setPaymentReportList({});
            }
        } else {
            setPaymentReportList(paymentSearch);
        }
    };



    return (
        <>

            <div className="sec-dataTable">

                {paymentReportList.length > 0 ? <div className="totalTrans"> {parseFloat(amount).toFixed(2)} <b> &nbsp;Total Amount :&nbsp;</b> </div> : <></>}
                <div className="row" style={{ margin: "0" }}>
                    <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                        <label className="list-label">Select Year</label>
                        <select
                            style={{ fontSize: "11px" }}
                            name="status"
                            className="form-control form-control-sm"
                            aria-label="Default select example "
                            value={selectYear}
                            onChange={handleYear}
                        >
                            <option selected disabled value="" style={{ fontSize: "11px" }}>
                                Select Year
                            </option>
                            {date.map((item) => (
                                <option
                                    key={item}
                                    value={item}
                                >
                                    {item}
                                </option>
                            )).reverse()}
                        </select>
                        <i class="fas fa-angle-down  notificationpositon"></i>
                    </div>

                    <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                        <label className="list-label">Select Month</label>
                        <select
                            style={{ fontSize: "11px" }}
                            name="status"
                            className="form-control form-control-sm"
                            aria-label="Default select example "
                            value={selectMonth}
                            onChange={handleMonth}
                        >
                            <option selected disabled value="" style={{ fontSize: "11px" }}>
                                Select Month
                            </option>
                            <option value="01">Baishakh</option>
                            <option value="02">Jestha</option>
                            <option value="03">Asar</option>
                            <option value="04">Shawan</option>
                            <option value="05">Bhadra</option>
                            <option value="06">Asoj</option>
                            <option value="07">Kartik</option>
                            <option value="08">Mangsir</option>
                            <option value="09">Poush</option>
                            <option value="10">Magh</option>
                            <option value="11">Falgun</option>
                            <option value="12">Chaitra</option>

                        </select>
                        <i class="fas fa-angle-down  notificationpositon"></i>
                    </div>



                    <div className="col-lg-2 offset-lg-6 offset-md-6 offset-sm-6 col-md-2 col-sm-2 align-self-end Search" style={{ marginTop: "4px", paddingRight: "1px", marginBottom: "10px" }}>
                        <div className="d-flex">
                            <input
                                placeholder="Search"
                                ref={searchInput}
                                type="text"
                                className="form-control form-control-sm searchField"
                                onChange={searchHandler}
                            />
                        </div>
                    </div>
                </div>
                {paymentLoad ? (
                    <Spinner />
                ) : (
                    <>
                        {paymentReportList.length > 0 ? (
                            <div className="table-responsive br-3">

                                <table className="table table-striped table-bordereds text-nowrap mb-0">
                                    <thead>
                                        <tr className="tableHead">
                                            <td className="tc" style={{ width: "70px" }}>S.N.</td>
                                            <td className="tl" >Date</td>
                                            <td className="tr" style={{ width: "150px" }}>Gateway</td>
                                            <td className="tr" style={{ width: "150px" }}>Status</td>
                                            <td className="tr" style={{ width: "150px" }}>Amount</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {paymentReportList.map((item, i) => (
                                            <tr key={i}>
                                                <td className="tc" style={{ width: "70px" }}>{i + 1}</td>
                                                <td className="tl">{item.CreatedDate}</td>
                                                <td className="tr">{item.Gateway}</td>
                                                <td className="tr">{item.Status}</td>
                                                <td className="tr">{parseFloat(item.Amount).toFixed(2)}</td>
                                            </tr>
                                        ))}
                                        <tr className="lastt">
                                            <td colspan="4" ><b style={{ paddingLeft: "15px" }}>Total</b></td>
                                            <td className="tr"><b>{parseFloat(amount).toFixed(2)}</b></td>

                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        ) : (
                            <div className="table-responsive br-3">

                                <div className="text-center mt-4">
                                    <p>There are no records to display.</p>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </>

    );


}