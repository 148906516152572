import React, { useEffect } from "react";
import placeholder from "./../../images/school/no-available-image.jpg";
import Aos from "aos";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useState } from "react";
import { Fetchdata } from "../hooks/getData";
import { FaCamera, FaPhotoVideo } from "react-icons/fa";
const Notification = ({ schoolInfo }) => {
  const appURL = process.env.REACT_APP_URL;
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const [notificationList, setNotificationList] = useState([]);
  useEffect(() => {
    getnotificationList();
  }, [schoolInfo]);

  const getnotificationList = () => {
    const params = {
      SchID: schoolInfo?.SchID,
      Flag: "SG",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/notification`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.NotificationLst ? result.NotificationLst : "";
        setNotificationList(postResult);
      } else {
        setNotificationList([]);
      }
    });
  };

  return (
    <>
      {notificationList.length > 0 && (
        <div
          className="section-b notification__section"
          id="notification__section"
        >
          <div className="uk-container">
            <div
              class=" new__title uk-text-left"
              data-aos="fade-up"
              data-aos-anchor-placement="center-bottom"
              data-aos-duration="1000"
            >
              <h2
                className="the__title small"
                style={{
                  color: `${schoolInfo.ScColor}`,
                }}
              >
                Notice
              </h2>
              <p className="sub__heading">Read Latest Notification</p>
            </div>
            <div className="my__carousel">
              <OwlCarousel
                className="owl-theme"
                dots={false}
                // autoWidth
                // loop
                margin={30}
                items={3}
                nav
                responsive={{
                  0: {
                    items: 1,
                  },
                  767: {
                    items: 2,
                  },
                  960: {
                    items: 3,
                  },
                }}
              >
                {notificationList.map((item) => (
                  <div className="notification__card">
                    <div className="notification__header">
                      <img
                        src={item.CreatorImage}
                        alt="Creator"
                        className="notification__creatorImage"
                      />
                      <div>
                        <div className="notification__creatorName">
                          {item.CreatorFullName}
                        </div>
                        <div className="notification__date">
                          {item.NotificationDate}
                        </div>
                      </div>
                    </div>
                    <div className="mt-3 notification__content">
                      <h3 className="uk-text-left the__title big m-0">
                        {item.Title}
                      </h3>
                      <p className="m-0 mt-1">{item.Description}</p>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Notification;
