import React, { useContext, useEffect, useState } from 'react'
import CloseIcon from "../../images/CloseIcon.svg";
import Plus from "../../images/Plus.png";
import urlSlug from "url-slug";
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';
import BlogContext from '../contextfolder/BlogContext/BlogContext';
import BlogCategory from './BlogCategory';
import ReactQuill from 'react-quill';

export default function BlogEdit({ closeEditPopup }) {


    const context = useContext(BlogContext)

    const { toggleEditPopup, blogFormError, setBlogFormError, editIsSubmit, setEditIsSubmit, blogFormValue, setBlogFormValue, isUploaded, setIsUploaded, setTypeFile, image, setImage, closeChecked, setCloseChecked, categorySlug, setCategorySlug, setValue, publish, setPublish, categoryPop, toggleCategoryPopup, setCategoryFormValue, categoryValue, setCategoryFormError, setCatIsSubmit, setPrimary, blogCatList, blogEditData,
        value } = context;


    const handleOnChange = () => {
        setCloseChecked(!closeChecked);
    };

    const handlePublish = () => {
        setPublish(!publish)
    }

    //This is for Blog portion

    const { quill, quillRef } = useQuill();


    React.useEffect(() => {
        if (quill) {
            quill.on('text-change', () => {
                // 

                setValue(quillRef.current.firstChild.innerHTML);
            });
        }
    }, [quill]);


    // Ends


    useEffect(() => {
        setCategorySlug(urlSlug(blogFormValue.title));
    }, [blogFormValue]);


    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;
        setBlogFormValue({ ...blogFormValue, [name]: value });
    };
    function handleImageChange(e) {
        if (e.target.files && e.target.files[0]) {
            setTypeFile(e.target.files[0].type);
            let reader = new FileReader();

            reader.onload = function (e) {
                setImage(e.target.result);
                setIsUploaded(true);
            };

            reader.readAsDataURL(e.target.files[0]);
        }
    }


    const formSubmit = (e) => {
        e.preventDefault();
        setBlogFormError(validate(blogFormValue));
        setEditIsSubmit(true);
    }
    useEffect(() => {
        if (Object.keys(blogFormError).length === 0 && editIsSubmit) {
            blogEditData(blogFormValue)
            if (closeChecked === true) {
                toggleEditPopup();
            }
            setEditIsSubmit(false);
        }

    }, [blogFormError]);

    const validate = (values) => {

        const errors = {};
        if (!values.category) {
            errors.category = "Required";
        }
        if (!values.title) {
            errors.title = "Required";
        }
        if (!values.excerpt) {
            errors.excerpt = "Required";
        }
        return errors;
    };


    const addnew = (e) => {
        e.preventDefault();
        toggleCategoryPopup();
        setCatIsSubmit(false)
        setCategoryFormValue(categoryValue)
        setPrimary(false)
    }

    const closeCatPopup = (e) => {
        e.preventDefault();
        toggleCategoryPopup();
        setCategoryFormValue(categoryValue)
        setCategoryFormError({})
        setCatIsSubmit(false)
        setPrimary(false)
    }


    return (
        <>

            <div className="container-fluid basicform" >

                <form >

                    <div className="form-row">
                        <div className="form-group col-md-11">
                            <label className="form-label" htmlFor="category" style={{ fontSize: "12px" }}>
                                Category<sup style={{ color: "red" }}>*</sup>
                            </label>
                            <select id="category" name="category"
                                onChange={handleChange}
                                value={blogFormValue.category} className="form-select form-select-sm mb-1" style={{ fontSize: '12px' }}>
                                <option value="" selected disabled>Select Option</option>
                                {blogCatList.map((item, i) => <option key={i} value={item.CategoryID}>{item.Category}</option>)}

                            </select>
                            <p className="errormsg " style={{ marginBottom: "0" }}>{blogFormError.category}</p>
                        </div>

                        <div className="form-group col-md-1">
                            <div className="add-title-button">
                                <button
                                    className="btn btn-sm btn-cmpy"
                                    style={{
                                        background: "0079bf",
                                        width: "50px",
                                        marginTop: "4px"
                                    }}
                                    onClick={addnew}
                                >
                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                </button>
                            </div>

                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="title" className="form-label" style={{ fontSize: "12px" }}>
                            Title<sup style={{ color: "red" }}>*</sup>
                        </label>
                        <input
                            id="title"
                            style={{ fontSize: "13px" }}
                            type="text"
                            className="form-control form-control-sm "
                            name="title"
                            value={blogFormValue.title}
                            onChange={handleChange}
                        />
                        <p className="errormsg " style={{ marginBottom: "0" }}>{blogFormError.title}</p>

                    </div>
                    <div className="form-group">
                        <label className="form-label" htmlFor="slug">
                            Slug
                        </label>
                        <input
                            type="text"
                            name="slug"
                            className="form-control form-control-sm mb-1"
                            value={categorySlug}
                            id="slug"
                            placeholder="slug"
                            disabled
                        />
                    </div>

                    <div className="form-group">
                        <label className="form-label" htmlFor="excerpt" style={{ fontSize: "12px" }}>
                            Excerpt<sup style={{ color: "red" }}>*</sup>
                        </label>
                        <textarea
                            id="excerpt"
                            style={{ fontSize: "13px" }}
                            type="text"
                            className="form-control form-control-sm "
                            name="excerpt"
                            value={blogFormValue.excerpt}
                            onChange={handleChange}
                        />
                        <p className="errormsg " style={{ marginBottom: "0" }}>{blogFormError.excerpt}</p>

                    </div>





                    <div className="form-group">
                        <label className="form-label" htmlFor="blog" style={{ fontSize: "12px" }}>
                            Blog
                        </label>
                        {/* <div style={{ height: "300px" }}>
                            <div ref={quillRef} defaultValue={initialValue} />
                            
                        </div> */}
                        <ReactQuill value={value} onChange={setValue} />

                    </div>


                    <div className="image-group">
                        <div
                            className="form-label"
                            htmlFor="text"
                            style={{ fontSize: "12px", textAlign: "left" }}
                        >
                            Upload Image
                        </div>

                        <div className="BoxUpload">
                            <div className="image-upload">
                                {!image ? (
                                    <>
                                        <label htmlFor="upload-input">
                                            <img
                                                src={Plus}
                                                draggable={"false"}
                                                alt="placeholder"
                                                style={{ width: 90, height: 100, paddingTop: "10px" }}
                                            />
                                        </label>

                                        <input
                                            id="upload-input"
                                            type="file"
                                            accept=".jpg,.jpeg,.gif,.png,.mov,.mp4"
                                            onChange={handleImageChange}
                                            name="image"
                                        />
                                    </>
                                ) : (
                                    <div className="ImagePreview">
                                        <img
                                            className="close-icon"
                                            src={CloseIcon}
                                            alt="CloseIcon"
                                            onClick={() => {
                                                setIsUploaded(false);
                                                setImage(null);
                                            }}
                                        />

                                        <img
                                            id="uploaded-image"
                                            src={image}
                                            draggable={false}
                                            alt="uploaded-img"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="form-group">

                        <div style={{ marginTop: "15px" }}>
                            <input
                                type="checkbox"
                                // className="form-check-input"
                                id="exampleCheck3"
                                name="notWorking"
                                onChange={handlePublish}
                                checked={publish}
                            />
                            <label class="form-check-label" for="exampleCheck3" style={{ fontSize: "12px", paddingLeft: "3px" }}>
                                Publish
                            </label>
                        </div>
                    </div>

                </form>


            </div>
            <div className="FormFooter">
                <div className='checkedClose'>
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck2"
                        name="closeChecked"
                        onChange={handleOnChange}
                        checked={closeChecked}
                    />
                    <label class="form-check-label" for="exampleCheck2">
                        Exit after submit
                    </label>
                </div>
                <div>
                    <button className='btn btn-sm btn-cmpy ml-2' style={{ background: "green" }} onClick={formSubmit} >Submit</button>
                    <button className='btn btn-sm btn-cmpy ml-2' onClick={closeEditPopup}>Cancel</button>
                </div>
            </div>


            {categoryPop && (
                <div className="modals">
                    <div className="popup-overlay"></div>

                    <div className="credit-model-content">
                        <div className="popUpHeader ">
                            <div className='popUpHeaderText '>Create Category</div>
                            <div style={{ cursor: "pointer" }}><i className="bi bi-x " onClick={closeCatPopup} style={{ fontSize: "25px" }}></i></div>
                        </div>
                        <div className="inside-modal">
                            <BlogCategory closeCatPopup={closeCatPopup} />
                        </div>
                    </div>
                </div>
            )}

        </>

    )
}
