import React from "react";
import { useState } from "react";
import { showImgPreview } from "../hooks/ImagePreviewPopUpinside";
import noImage from "./../../images/school/no-available-image.jpg";
import placeholder from "./../../images/school/placeholder.png";

export default function ViewBlog({ viewList, schoolInfo }) {
  const [imgPrv, setImgPrv] = useState(false);
  const [imagePre, setImagePre] = useState("");
  return (
    <>
      <div className="uk-flex uk-flex-middle uk-flex-between">
        <h4 className="mt-0 uk-text-left the__title">{viewList.Title}</h4>

        <div
          className="blog__category"
          style={{
            background: `${schoolInfo.ScColor}`,
          }}
        >
          <span>{viewList.CategoryName}</span>
        </div>
      </div>

      <div className="view__blog__img mt-3">
        {viewList.Image ? (
          <img
            src={viewList.Image}
            alt="Blog"
            onClick={() => {
              setImagePre(viewList.Image);
              setImgPrv(true);
            }}
          />
        ) : (
          <img
            src={noImage}
            alt="Blog"
            onClick={() => {
              setImagePre(noImage);
              setImgPrv(true);
            }}
          />
        )}
      </div>

      <div className="profile-info-blog mt-3 ">
        <div className="profilecontentLogoBlog tl">
          <div className="profilemgmtImgBlog tl">
            {viewList.CreatedUserImage ? (
              <img src={viewList.CreatedUserImage} alt="dp" />
            ) : (
              <img src={placeholder} alt="dp" />
            )}
          </div>
        </div>
        <div>
          <h6
            style={{
              paddingLeft: "10px",
              marginTop: "10px",
              fontWeight: "bold",
              textAlign: "left",
              fontSize: "13px",
              marginBottom: "5px",
            }}
          >
            {viewList.CreatedUser}
          </h6>
          {viewList.BlogDate && (
            <p style={{ textAlign: "left", paddingLeft: "10px", margin: "0" }}>
              {viewList.BlogDate}
            </p>
          )}
        </div>
      </div>

      <div className="excerpt mt-3">{viewList.Excerpt}</div>

      <div className="the__content small">
        <div dangerouslySetInnerHTML={{ __html: viewList.Post }} />
      </div>

      {imgPrv &&
        showImgPreview({
          img: imagePre,
          setTrigger: setImgPrv,
        })}
    </>
  );
}
