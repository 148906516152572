import React, { useContext, useEffect, useState } from "react";
import { Fetchdata } from "../../hooks/getData";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { toast } from "react-toastify";
import StudyMaterialContext from "./StudyMaterialContext";

function StudyMaterialState(props) {
  const { appURL } = useContext(SidebarContext);

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const [closeChecked, setCloseChecked] = useState(false);

  const studyValue = {
    selectdrop: "-1",
    class: "",
    section: "",
    student: "",
    album: "",
    title: "",
    description: "",
    fileType: "",
    urls: "",
  };
  const [studyFormValue, setStudyFormValue] = useState(studyValue);
  const [studyFormError, setStudyFormError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const [popup, setPopup] = useState(false);
  const togglePopup = () => {
    setPopup(!popup);
  };

  const [isUploaded, setIsUploaded] = useState(false);
  const [typeFile, setTypeFile] = useState("");
  const [image, setImage] = useState("");

  /* For study album*/

  const [albumPop, setAlbumPop] = useState(false);
  const toggleAlbumPopup = () => {
    setAlbumPop(!albumPop);
  };

  // For Study Album
  const albumValue = {
    selectdrop: "-1",
    class: "",
    section: "",
    student: "",
    title: "",
    description: "",
  };
  const [albumFormValue, setAlbumFormValue] = useState(albumValue);
  const [albumFormError, setAlbumFormError] = useState({});
  const [albumIsSubmit, setAlbumIsSubmit] = useState(false);
  const [albumSearch, setAlbumSearch] = useState(null);

  /* For study album ends*/

  const [reportOption, setReportOption] = useState("");

  const [classList, setClassList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [studentList, setStudentList] = useState([]);
  const [albumList, setAlbumList] = useState([]);

  const [selectClass, setSelectClass] = useState("");
  const [selectSection, setSelectSection] = useState("");
  const [selectStudent, setSelectStudent] = useState("");
  const [selectAlbum, setSelectAlbum] = useState("");

  const [albumClassList, setAlbumClassList] = useState([]);
  const [albumSectionList, setAlbumSectionList] = useState([]);
  const [albumStudentList, setAlbumStudentList] = useState([]);

  const [selectAlbumClass, setSelectAlbumClass] = useState("");
  const [selectAlbumStudent, setSelectAlbumStudent] = useState("");

  useEffect(() => {
    getClassList();
  }, []);

  const getClassList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      DFlag: "C",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setClassList(postResult);
      } else {
        setClassList([]);
      }
    });
  };

  useEffect(() => {
    getSectionList();
  }, [selectClass]);

  const getSectionList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      ClassID: selectClass,
      DFlag: "S",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setSectionList(postResult);
      } else {
        setSectionList([]);
      }
    });
  };

  //Api to hit student list

  useEffect(() => {
    stdList();
  }, [selectClass, selectSection]);

  const stdList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      Flag: "S",
      ClassID: selectClass,
      SectionID: [
        {
          SectionID: selectSection,
        },
      ],
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/student`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.StudentLst ? result.StudentLst : "";
        setStudentList(postResult);
      } else {
        setStudentList([]);
      }
    });
  };

  //Study Album for Study Material list

  useEffect(() => {
    getAlbumList();
  }, [selectClass, selectSection, selectStudent]);

  const getAlbumList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "S",
      ClassID:
        reportOption === "C" || reportOption === "S" || reportOption === "I"
          ? selectClass
          : "0",
      SectionID:
        reportOption === "S" || reportOption === "I" ? selectSection : "0",
      MemID: reportOption === "I" ? selectStudent : "0",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/study-material/album`,
    };
    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        getStudyAlbumList();
        const postResult = result.StudyMaterialLst
          ? result.StudyMaterialLst
          : "";
        setAlbumList(postResult);
      } else {
        setAlbumList([]);
      }
    });
  };

  //Study Album for Study Material list ends

  /* api for study album */

  useEffect(() => {
    getAlbumClassList();
  }, []);

  const getAlbumClassList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      DFlag: "C",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setAlbumClassList(postResult);
      } else {
        setAlbumClassList([]);
      }
    });
  };

  useEffect(() => {
    getAlbumSectionList();
  }, [albumFormValue]);

  const getAlbumSectionList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      ClassID: albumFormValue.class,
      DFlag: "S",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setAlbumSectionList(postResult);
      } else {
        setAlbumSectionList([]);
      }
    });
  };

  useEffect(() => {
    getstdList();
  }, [albumFormValue.class, albumFormValue.section]);

  const getstdList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      Flag: "S",
      ClassID: albumFormValue.class,
      SectionID: [
        {
          SectionID: albumFormValue.section,
        },
      ],
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/student`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.StudentLst ? result.StudentLst : "";
        setAlbumStudentList(postResult);
      } else {
        setAlbumStudentList([]);
      }
    });
  };

  //API to create Study album
  const fetchdata = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "i",
      ClassID:
        albumFormValue.selectdrop === "C" ||
        albumFormValue.selectdrop === "S" ||
        albumFormValue.selectdrop === "I"
          ? albumFormValue.class
          : "",
      SectionID:
        albumFormValue.selectdrop === "S" || albumFormValue.selectdrop === "I"
          ? albumFormValue.section
          : "",
      MemID: albumFormValue.selectdrop === "I" ? albumFormValue.student : "",
      Title: albumFormValue.title,
      Description: albumFormValue.description,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/study-material/album`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getStudyAlbumList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };

  // API for study album List

  const [studyAlbumList, setStudyAlbumList] = useState([]);

  useEffect(() => {
    getStudyAlbumList();
  }, [albumFormValue]);

  const getStudyAlbumList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "S",
      ClassID:
        albumFormValue.selectdrop === "C" ||
        albumFormValue.selectdrop === "S" ||
        albumFormValue.selectdrop === "I"
          ? albumFormValue.class
          : "0",
      SectionID:
        albumFormValue.selectdrop === "S" || albumFormValue.selectdrop === "I"
          ? albumFormValue.section
          : "0",
      MemID: albumFormValue.selectdrop === "I" ? albumFormValue.student : "0",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/study-material/album`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.StudyMaterialLst
          ? result.StudyMaterialLst
          : "";
        setLoading(false);
        setStudyAlbumList(postResult);
        setAlbumSearch(postResult);
      } else {
        setLoading(false);
        setStudyAlbumList([]);
        setAlbumSearch("");
      }
    });
  };

  //API for edit
  const [isEdit, setIsEdit] = useState(false);
  const [catId, setCatId] = useState("");
  function selectUser(datas) {
    setAlbumFormValue({
      title: datas.Title,
      class: datas.ClassID,
      section: datas.SectionID,
      student: datas.StudentID,
      description: datas.Description,
    });
    setCatId(datas.StudyAlbumID);
    setIsEdit(true);
  }

  const editdata = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "U",
      StudyAlbumID: catId,
      Title: albumFormValue.title,
      Description: albumFormValue.description,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/study-material/album`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getStudyAlbumList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
        setIsEdit(false);
        setAlbumFormValue(albumValue);
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };

  //Everything to pop up and delete the blog category list
  const [deleteTopicPopup, setDeleteTopicPopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const [delTopicData, setDelTopicData] = useState();

  const handleTopicDelete = async (data) => {
    setDelTopicData(data);

    setDeleteTopicPopup({
      show: true,
      data,
    });
  };

  // This will perform the deletion and hide the Confirmation Box

  const handleDeleteTopicTrue = async () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "R",
      StudyAlbumID: delTopicData.toString(),
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/study-material/album`, requestOptions).then(
      (result) => {
        result.json().then((resp) => {
          if (resp.StatusCode === 200) {
            getStudyAlbumList();
            toast(resp.Message, {
              style: {
                color: "green",
                fontSize: "13px",
              },
            });
            setDeleteTopicPopup({
              show: false,
              data: null,
            });
          } else {
            toast(resp.Message, {
              style: {
                color: "red",
                fontSize: "13px",
              },
            });
          }
        });
      }
    );
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleDeleteTopicFalse = () => {
    setDeleteTopicPopup({
      show: false,
      data: null,
    });
  };

  // Ends Here

  /* api for study album  ends   *********************************************************************/

  const fileType = typeFile.split(".");
  const fileName = fileType[0];
  const dot = ".";
  const fileExt = dot.concat(fileType[1]);

  /*  API for Study Material Begins */
  const studyFetchData = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "i",
      StudyAlbumID: studyFormValue.album,
      Title: studyFormValue.title,
      Description: studyFormValue.description,
      Name: image !== "" ? fileName : "",
      URL: [
        {
          URL:
            studyFormValue.fileType === "U"
              ? studyFormValue.urls
              : image !== ""
              ? image.split(",")[1]
              : "",
        },
      ],
      Type: studyFormValue.fileType,
      Extension: image !== "" ? fileExt : "",
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };

    fetch(`${appURL}api/admin/study-material/material`, requestOptions).then(
      (result) => {
        result.json().then((resp) => {
          if (resp.StatusCode === 200) {
            getStudyList();
            toast(resp.Message, {
              style: {
                color: "green",
                fontSize: "13px",
              },
            });
          } else {
            toast("Error: " + resp.Message, {
              style: {
                color: "red",
                fontSize: "13px",
              },
            });
          }
        });
      }
    );
  };

  const [studyList, setStudyList] = useState([]);

  useEffect(() => {
    getStudyList();
  }, [selectAlbum]);

  const getStudyList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "S",
      StudyAlbumID: selectAlbum,
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/study-material/material`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.StudyMaterialLst
          ? result.StudyMaterialLst
          : "";
        setLoading(false);
        setStudyList(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        setStudyList([]);
        setOriginalList("");
      }
    });
  };

  // //Everything to pop up and delete the event list
  const [deletePopup, setDeletePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const [delID, setDelID] = useState("");
  const handleDelete = async (datas) => {
    setDelID(datas);
    setDeletePopup({
      show: true,
    });
  };

  // This will perform the deletion and hide the Confirmation Box

  const handleDeleteTrue = async () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "R",
      MaterialID: delID,
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/study-material/material`, requestOptions).then(
      (result) => {
        result.json().then((resp) => {
          if (resp.StatusCode === 200) {
            getStudyList();
            toast(resp.Message, {
              style: {
                color: "green",
                fontSize: "13px",
              },
            });
          } else {
            toast(resp.Message, {
              style: {
                color: "red",
                fontSize: "13px",
              },
            });
          }
        });
      }
    );
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleDeleteFalse = () => {
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // Ends Here

  return (
    <StudyMaterialContext.Provider
      value={{
        loading,
        originalList,
        deletePopup,
        handleDeleteTrue,
        handleDeleteFalse,
        handleDelete,
        popup,
        setPopup,
        togglePopup,
        studyFormValue,
        setStudyFormValue,
        studyFormError,
        setStudyFormError,
        isSubmit,
        studyValue,
        setIsSubmit,
        setStudyList,
        fetchdata,
        studyList,
        classList,
        sectionList,
        selectClass,
        setSelectClass,
        selectSection,
        setSelectSection,
        selectStudent,
        setSelectStudent,
        closeChecked,
        setCloseChecked,
        setSelectAlbum,
        selectAlbum,
        albumList,
        studentList,
        isUploaded,
        setIsUploaded,
        typeFile,
        setTypeFile,
        image,
        setImage,
        studyFetchData,

        reportOption,
        setReportOption,

        albumFormValue,
        setAlbumFormValue,
        albumFormError,
        setAlbumFormError,
        albumIsSubmit,
        setAlbumIsSubmit,
        albumSearch,
        setAlbumSearch,
        albumValue,
        setAlbumList,
        albumClassList,
        setAlbumClassList,
        albumSectionList,
        setAlbumSectionList,
        albumStudentList,
        setAlbumStudentList,
        selectAlbumClass,
        setSelectAlbumClass,
        selectAlbumStudent,
        setSelectAlbumStudent,
        toggleAlbumPopup,
        albumPop,
        setAlbumPop,
        studyAlbumList,
        setStudyAlbumList,
        isEdit,
        setIsEdit,
        selectUser,
        editdata,
        getStudyAlbumList,

        handleTopicDelete,
        deleteTopicPopup,
        handleDeleteTopicTrue,
        handleDeleteTopicFalse,
      }}
    >
      {props.children}
    </StudyMaterialContext.Provider>
  );
}

export default StudyMaterialState;
