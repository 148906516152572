import React, { useContext, useState, useEffect, useRef } from "react";
import DataTable from "react-data-table-component";
import AttendanceContext from "../contextfolder/AttendanceContext/AttendanceContext";
import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";
import { Fetchdata } from "../hooks/getData";

import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import { toast } from "react-toastify";
import Tippy from "@tippyjs/react";
import DeleteRemarkPop from "./DeleteRemarkPop";

export default function RemarksPop({ closePopup, attendId, setAttendId }) {
  const context = useContext(AttendanceContext);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const { appURL, customStyles } = useContext(SidebarContext);

  const {
    togglePopup,
    absentFormError,
    setAbsentFormError,
    isSubmit,
    setIsSubmit,
    absentValue,
    absentFormValue,
    setAbsentFormValue,
    handleRemarkDelete,
    setCloseChecked,
    closeChecked,
    remarkSearch,
    setRemarkList,
    loading,
    remarkList,
    handleDeleteRemarkTrue,
    handleDeleteRemarkFalse,
    deleteRemarkPopup,
    getRemarkList,
    getAbsentList,
  } = context;

  const handleOnChange = () => {
    setCloseChecked(!closeChecked);
  };

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setAbsentFormValue({ ...absentFormValue, [name]: value });
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setAbsentFormError(validate(absentFormValue));
    setIsSubmit(true);
  };
  useEffect(() => {
    if (Object.keys(absentFormError).length === 0 && isSubmit) {
      const dataForm = {
        SchID: userinfo.SchID,
        UserID: userinfo.UserID.toString(),
        Flag: "i",
        AttendID: attendId.toString(),
        FollowRemarks: absentFormValue.remark,
        Academicyear: userinfo.AcademicYear,
        FetchURL: `${appURL}api/admin/attend-absent-follow`,
        Type: "POST",
      };

      Fetchdata(dataForm).then(function (result) {
        if (result.StatusCode === 200) {
          toast(result.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
        } else {
          toast("Error: " + result.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });

      if (closeChecked === true) {
        togglePopup();
      }
      setAbsentFormValue(absentValue);
      setIsSubmit(false);
      getRemarkList();
      getAbsentList();
    }
  }, [absentFormError]);

  const validate = (values) => {
    const errors = {};
    if (!values.remark) {
      errors.remark = "Required";
    }

    return errors;
  };

  const columns = [
    {
      name: "S.N.",
      grow: 0,
      center: true,
      width: "90px",
      cell: (row, index) => index + 1,
    },

    {
      name: "Full Name",
      // center: true,
      // grow: 0,
      minWidth: "200px",
      selector: (row) => row.FullName,
    },
    {
      name: "Follow Remarks",
      // center: true,
      // grow: 0,
      minWidth: "200px",
      selector: (row) => row.FollowRemarks,
    },
    {
      name: "Follow Date",
      // center: true,
      // grow: 0,
      selector: (row) => row.FollowDate,
    },
    {
      name: "Followed By",
      // center: true,
      // grow: 0,
      minWidth: "200px",
      selector: (row) => row.FollowedBy,
    },

    {
      name: "Action",
      center: true,
      width: "80px",
      selector: (row) => {
        return (
          <>
            <>
              <div className="ln-verition d-flex">
                <Tippy content="Delete">
                  <i
                    className="fa fa-trash-o rejectspan--action"
                    onClick={() => handleRemarkDelete(row.AttendID)}
                  ></i>
                </Tippy>
              </div>
            </>
          </>
        );
      },
    },
  ];

  const searchInput = useRef("");
  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = remarkSearch.filter((list) => {
        return list["FullName"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setRemarkList(srchResult);
      } else {
        setRemarkList({});
      }
    } else {
      setRemarkList(remarkSearch);
    }
  };

  return (
    <>
      <div className="container-fluid basicform">
        <form>
          <div className="form-group">
            <label htmlFor="remark" style={{ fontSize: "12px" }}>
              Remarks<sup style={{ color: "red" }}>*</sup>
            </label>
            <input
              id="remark"
              style={{ fontSize: "13px" }}
              type="text"
              className="form-control form-control-sm"
              name="remark"
              value={absentFormValue.remark}
              onChange={handleChange}
            />
            <p className="errormsg " style={{ marginBottom: "0" }}>
              {absentFormError.remark}
            </p>
          </div>
        </form>

        <div className="sec-dataTable">
          {loading ? (
            <Spinner />
          ) : (
            <DataTable
              columns={columns}
              data={remarkList}
              customStyles={customStyles}
              pagination
              fixedHeader
              fixedHeaderScrollHeight="280px"
              highlightOnHover
              pointerOnHover
              responsive
              dense
              striped
              subHeader
              subHeaderComponent={
                <>
                  <div className="upper-dataTbl">
                    <div className="d-flex">
                      <input
                        placeholder="Search"
                        ref={searchInput}
                        type="text"
                        className="form-control form-control-sm searchField"
                        onChange={searchHandler}
                      />
                    </div>
                  </div>
                </>
              }
            />
          )}
        </div>
      </div>
      <div className="FormFooter">
        <div className="checkedClose">
          <input
            type="checkbox"
            className="form-check-input"
            id="exampleCheck2"
            name="closeChecked"
            onChange={handleOnChange}
            checked={closeChecked}
          />
          <label class="form-check-label" for="exampleCheck2">
            Exit after submit
          </label>
        </div>
        <div>
          <button
            className="btn btn-sm btn-cmpy ml-2"
            style={{ background: "green" }}
            onClick={formSubmit}
          >
            Submit
          </button>
          <button className="btn btn-sm btn-cmpy ml-2" onClick={closePopup}>
            Cancel
          </button>
        </div>
      </div>

      {deleteRemarkPopup.show && (
        <DeleteRemarkPop
          handleDeleteRemarkTrue={handleDeleteRemarkTrue}
          handleDeleteRemarkFalse={handleDeleteRemarkFalse}
        />
      )}
    </>
  );
}
