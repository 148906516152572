import React, { useEffect } from 'react'
import { useContext } from 'react';
import $ from "jquery"
import SessionContext from './SessionContext';
function TimeOutPopUp() {
    const { isActive } = useContext(SessionContext);
    const handleOk = () => {
        $('.TimeOutpopupBg').fadeOut(500);
        $('.TimeOutpopUp').fadeOut(500);
    }
    useEffect(() => {
        if (!isActive) {
            $('.TimeOutpopupBg').fadeIn(500);
            $('.TimeOutpopUp').fadeIn(500);


        }
    }, [isActive])

    return (
        <div className='popupBg TimeOutpopupBg'>
            <div className="popUp TimeOutpopUp" style={{ width: "max-content" }}>
                <div className="popup-body">
                    <span>Session Expired. Please Login to Continue</span>
                </div>

                <div className="popUp-footer">
                    <button className="Sessionbtn" onClick={handleOk}><span>Ok</span></button>
                </div>
            </div>

        </div>
    )
}

export default TimeOutPopUp