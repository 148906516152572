import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";
import Select from "react-select";
import AssignmentContext from "../contextfolder/AssignmentContext/AssignmentContext";
import AssignmentForm from "./AssignmentForm";
import DeletePop from "./DeletePop";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import CheckAssignment from "./CheckAssignment";
import { GetNepaliDate } from "../hooks/dateConvertor";
import BulkCheckAssignment from "./BulkCheckAssignment";
import AcademicHeader from "../AcademicHeader";

export default function AssignmentList() {
  const { todayDate, customStyles, AcademicYear } = useContext(SidebarContext);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const {
    loading,
    originalList,
    popup,
    togglePopup,
    setIsSubmit,
    setAssignmentFormValue,
    setAssignmentFormError,
    classList,
    sectionList,
    teacherList,
    subjectList,
    assignmentValue,
    assignmentList,
    setAssignmentList,
    deletePopup,
    handleDeleteTrue,
    handleDeleteFalse,
    handleDelete,
    selectClass,
    setSelectClass,
    selectTeacher,
    setSelectTeacher,
    selectSubject,
    setSelectSubject,
    selectSection,
    setSelectSection,
    setSelectedOptions,
    setImage,
    setIsUploaded,
    setSelectedOptionsOne,
    selectedOptionsOne,
    checkPop,
    toggleCheckPopup,
    assignmentCheck,
    bulkAssignmentCheck,
    setSelectedImages,
    setSelectedBase64,
    bulkCheckPop,
    toggleBulkCheckPopup,
    setCloseBulkChecked,
  } = useContext(AssignmentContext);

  const searchInput = useRef("");

  const columns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "Date",

      width: "130px",
      selector: (row) => GetNepaliDate(row.EngDate),
    },
    {
      name: "Title",
      selector: (row) => row.Title,
    },
    {
      name: "Point",
      width: "90px",
      selector: (row) => (row.Points === "-1" ? "No Point" : row.Points),
    },
    {
      name: "Submitted",
      width: "110px",
      selector: (row) => row.SubmittedCt,
    },
    {
      name: "Approved",
      width: "110px",
      selector: (row) => row.ApprovedCt,
    },
    {
      name: "Pending",
      width: "90px",
      selector: (row) => row.PendingCt,
    },
    {
      name: "Total",
      width: "80px",
      selector: (row) => row.SubmittedCt + row.ApprovedCt + row.PendingCt,
    },
    {
      name: "Submission Date",
      width: "140px",
      selector: (row) => GetNepaliDate(row.DeadLine),
    },

    {
      name: "Action",
      center: true,
      width: "100px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              {/* <button
                                type="button"
                                class="btn btn-sm editspan"
                                disabled={row.IsActive === "N"}
                            // onClick={() => handleDelete(row.OnlineClassID)}
                            >
                                Check
                            </button> */}
              <Tippy content="Check">
                <i
                  className="bi bi-check-square solvespan--action"
                  disabled={row.IsActive === "N"}
                  onClick={() => assignmentCheck(row.AssignmentID)}
                  style={{ paddingTop: "3px", paddingRight: "1px" }}
                ></i>
              </Tippy>
              <Tippy content="Delete">
                <i
                  className="fa fa-trash-o rejectspan--action"
                  onClick={() => handleDelete(row.AssignmentID)}
                ></i>
              </Tippy>
              <Tippy content="Bulk Check">
                <i
                  className="bi bi-check-square solvespan--action"
                  disabled={row.IsActive === "N"}
                  onClick={() => bulkAssignmentCheck(row.AssignmentID)}
                  style={{ paddingTop: "3px", paddingRight: "1px" }}
                ></i>
              </Tippy>
            </div>
          </>
        );
      },
    },
  ];

  const closePopup = (e) => {
    e.preventDefault();
    togglePopup();
    setAssignmentFormValue(assignmentValue);
    setAssignmentFormError({});
    setIsSubmit(false);
    setImage("");
    setIsUploaded(false);
    setSelectedImages([]);
    setSelectedBase64([]);
  };

  const closeCheckPopup = (e) => {
    e.preventDefault();
    toggleCheckPopup();
  };
  const closeBulkCheckPopup = (e) => {
    e.preventDefault();
    toggleBulkCheckPopup();
  };

  const addnew = (e) => {
    e.preventDefault();
    togglePopup();
    setIsSubmit(false);
    setAssignmentFormValue(assignmentValue);
  };

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["Topic"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setAssignmentList(srchResult);
      } else {
        setAssignmentList({});
      }
    } else {
      setAssignmentList(originalList);
    }
  };

  const handleClass = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectClass(value);
    setSelectTeacher("");
    setSelectSubject("");
  };

  const handleSubject = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectSubject(value);
  };

  const handleTeacher = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectTeacher(value);
    setSelectSubject("");
  };

  const handleSection = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectedOptionsOne(value);
  };

  const dropDownValue = sectionList.map((item) => ({
    value: item.SectionID,
    label: item.SectionName,
  }));

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
        <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">Assignment</div>
            <div className="page-date">
              <div className="sec-content">
                <AcademicHeader
                  todayDate={todayDate}
                  AcademicYear={AcademicYear}
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <>
          <div className="sec-dataTable content-section main-content">
            <div className="upper-dataTbl">
              <div className="btn-addlnote">
                <button
                  type="button"
                  class="btn btn-sm"
                  style={{
                    background: "var(--MainColor)",
                    color: "white",
                  }}
                  onClick={addnew}
                >
                  Add New
                </button>
              </div>
            </div>
            <hr className="new-hr-new" />
            {loading ? (
              <Spinner />
            ) : (
              <DataTable
                columns={columns}
                data={assignmentList}
                customStyles={customStyles}
                pagination
                fixedHeader
                fixedHeaderScrollHeight="280px"
                highlightOnHover
                pointerOnHover
                responsive
                dense
                striped
                subHeader
                subHeaderAlign="left"
                subHeaderComponent={
                  <>
                    <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                      <label className="list-label">Class</label>
                      <select
                        style={{ fontSize: "11px" }}
                        name="snotifiaction"
                        value={selectClass}
                        onChange={handleClass}
                        className="form-control form-control-sm"
                        aria-label="Default select example "
                      >
                        <option
                          value=""
                          disabled
                          selected
                          style={{ fontSize: "11px" }}
                        >
                          Select Class
                        </option>

                        {classList.map((item) => (
                          <option key={item.ClassID} value={item.ClassID}>
                            {item.ClassName}
                          </option>
                        ))}
                      </select>
                      <i class="fas fa-angle-down  notificationpositon"></i>
                    </div>

                    <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                      <label className="list-label">Section</label>
                      {/* {userinfo.UserType === "T" ? (
                                                <Select
                                                    className="select"
                                                    style={{ fontSize: "11px", marginBottom: "3px" }}
                                                    isMulti
                                                    options={dropDownValue}
                                                    onChange={(item) => setSelectedOptions(item.map((item) => ({
                                                        "SectionID": item.value,
                                                    })))}
                                                    isSearchable={false}
                                                />
                                            ) : ( */}
                      <select
                        style={{ fontSize: "11px" }}
                        name="status"
                        className="form-control form-control-sm"
                        aria-label="Default select example "
                        value={selectedOptionsOne}
                        onChange={handleSection}
                      >
                        <option
                          selected
                          disabled
                          value=""
                          style={{ fontSize: "11px" }}
                        >
                          Select Option
                        </option>
                        {sectionList.map((item) => (
                          <option key={item.SectionID} value={item.SectionID}>
                            {item.SectionName}
                          </option>
                        ))}
                      </select>
                      {/* )} */}
                      <i className="fas fa-angle-down  notificationpositon"></i>
                    </div>

                    {userinfo.UserType !== "T" && (
                      <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                        <label className="list-label">Teacher</label>
                        <select
                          style={{ fontSize: "11px" }}
                          name="snotifiaction"
                          value={selectTeacher}
                          onChange={handleTeacher}
                          className="form-control form-control-sm"
                          aria-label="Default select example"
                        >
                          <option
                            value=""
                            disabled
                            selected
                            style={{ fontSize: "11px" }}
                          >
                            Select Teacher
                          </option>

                          {teacherList.map((item) => (
                            <option key={item.UserID} value={item.UserID}>
                              {item.FullName}
                            </option>
                          ))}
                        </select>
                        <i class="fas fa-angle-down  notificationpositon"></i>
                      </div>
                    )}

                    <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                      <label className="list-label">Subject</label>
                      <select
                        style={{ fontSize: "11px" }}
                        name="snotifiaction"
                        value={selectSubject}
                        onChange={handleSubject}
                        className="form-control form-control-sm"
                        aria-label="Default select example"
                      >
                        <option
                          value=""
                          disabled
                          selected
                          style={{ fontSize: "11px" }}
                        >
                          Select Subject
                        </option>

                        {subjectList.map((item) => (
                          <option key={item.SubjectID} value={item.SubjectID}>
                            {item.SubjectName}
                          </option>
                        ))}
                      </select>
                      <i class="fas fa-angle-down  notificationpositon"></i>
                    </div>

                    <div
                      className={
                        userinfo.UserType === "T"
                          ? "col-lg-2 offset-lg-4 offset-md-4 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
                          : "col-lg-2 offset-lg-2 offset-md-2 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
                      }
                      style={{
                        marginTop: "25px",
                        paddingRight: "1px",
                        marginBottom: "10px",
                      }}
                    >
                      <div className="d-flex">
                        <input
                          placeholder="Search"
                          ref={searchInput}
                          type="text"
                          className="form-control form-control-sm searchField"
                          onChange={searchHandler}
                        />
                      </div>
                    </div>
                  </>
                }
              />
            )}
          </div>
        </>
      </div>

      {popup && (
        <div className="modals">
          <div className="overlay"></div>

          <div className="notification-model-content">
            <div className="popUpHeader ">
              <div className="popUpHeaderText">Assignment</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closePopup}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="inside-modal">
              <AssignmentForm closePopup={closePopup} />
            </div>
          </div>
        </div>
      )}
      {checkPop && (
        <div className="modals">
          <div className="overlay"></div>

          <div className="check-model-content">
            <div className="popUpHeader ">
              <div className="popUpHeaderText">Check Assignment</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closeCheckPopup}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="check-inside-modal">
              <CheckAssignment closeCheckPopup={closeCheckPopup} />
            </div>
          </div>
        </div>
      )}

      {bulkCheckPop && (
        <div className="modals">
          <div className="overlay"></div>

          <div className="check-model-content">
            <div className="popUpHeader ">
              <div className="popUpHeaderText">Bulk Check Assignment</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closeBulkCheckPopup}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="check-inside-modal">
              <BulkCheckAssignment closeCheckPopup={closeBulkCheckPopup} />
            </div>
          </div>
        </div>
      )}

      {deletePopup.show && (
        <DeletePop
          handleDeleteTrue={handleDeleteTrue}
          handleDeleteFalse={handleDeleteFalse}
        />
      )}
    </>
  );
}
