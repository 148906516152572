import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../../css/Commission.css";
import Spinner from "../../loading/spinner";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import AcademicHeader from "../../AcademicHeader";
import BusContext from "../../contextfolder/BusContext/BusContext";
import AssignBusContext from "../../contextfolder/AssignBusContext/AssignBusContext";
import AssignBusForm from "./AssignBusForm";
import DeletePop from "./DeletePop";

export default function AssignBusList() {
  const { todayDate, customStyles, AcademicYear } = useContext(SidebarContext);
  const { dataList } = useContext(BusContext);
  const {
    loading,
    originalList,
    popup,
    togglePopup,
    setIsSubmit,
    setFormValue,
    setFormError,
    classList,
    subjectList,
    initialValue,
    assignList,
    setAssignList,
    selectClass,
    setSelectClass,
    selectSubject,
    setSelectSubject,
    setNewData,

    activatePopup,
    handleActivateTrue,
    handleActivateFalse,
    handleActivate,
    inActivatePopup,
    handleInActivateTrue,
    handleInActivateFalse,
    handleInActivate,
    deletePopup,
    handleDeleteTrue,
    handleDeleteFalse,
    handleDelete,
    toggleEditPopup,
    editPopup,
    setEditIsSubmit,
    editPop,
    setInputFields,
    selectBus,
    setSelectBus,
  } = useContext(AssignBusContext);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const searchInput = useRef("");

  const columns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "Full Name",
      selector: (row) => row.StudentName,
    },
    {
      name: "Class",
      width: "150px",
      selector: (row) => row.ClassName,
    },
    {
      name: "Section",
      width: "100px",
      selector: (row) => row.SectionName,
    },

    {
      name: "Action",
      // grow: 0,
      center: true,
      width: "100px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <Tippy content="Delete">
                <i
                  className="fa fa-trash-o rejectspan--action"
                  onClick={() => handleDelete(row.AssignID)}
                ></i>
              </Tippy>
            </div>
          </>
        );
      },
    },
  ];

  const closePopup = (e) => {
    e.preventDefault();
    togglePopup();
    setFormValue(initialValue);
    setFormError({});
    setIsSubmit(false);
    setInputFields([]);
  };

  const closeEditPopup = (e) => {
    e.preventDefault();
    toggleEditPopup();
    setFormError({});
    setEditIsSubmit(false);
    setNewData("");
  };

  const addnew = (e) => {
    e.preventDefault();
    togglePopup();
    setIsSubmit(false);
    setFormValue(initialValue);
    setInputFields([]);
  };

  const handleBus = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectBus(value);
  };

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["StudentName"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setAssignList(srchResult);
      } else {
        setAssignList({});
      }
    } else {
      setAssignList(originalList);
    }
  };

  /* This is for export csv */

  const dataToExcel = assignList.map((d, i) => ({
    "S.N.": i + 1,
    "Bus Name": d.BusName,
    "Driver Name": d.DriverName,
    "Driver Contact": d.DriverContact,
  }));

  const toExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(dataToExcel);
      var wscols = [{ wch: 5 }, { wch: 20 }, { wch: 20 }, { wch: 20 }];
      worksheet["!cols"] = wscols;
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "School_Bus");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_" + "export" + EXCEL_EXTENSION
        );
      }
    });
  };

  //end

  //This is to export pdf

  const dataWithIndex = assignList.map((d, i) => ({
    ...d,
    Index: i + 1,
  }));

  const cols = [
    { header: "S.N", field: "Index" },
    { header: "Bus Name", field: "BusName" },
    { header: "Driver Name", field: "DriverName" },
    { header: "Driver Contact", field: "DriverContact" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const toPdf = () => {
    const doc = new jsPDF("p", "pt", "a4", true);

    var midPage = doc.internal.pageSize.getWidth() / 2;

    // doc.addImage(`${userinfo.SchPhoto}`, "JPG", 40, 40, 70, 60);

    doc.setFontSize(18);
    doc.text(`${userinfo.SchName}`, midPage, 50, null, null, "center");

    doc.setFontSize(15);
    doc.text(`${userinfo.SchAddress}`, midPage, 68, null, null, "center");

    doc.setFontSize(10);
    doc.text(
      `Tel:${userinfo.Phone}, E-mail:${userinfo.Email}`,
      midPage,
      85,
      null,
      null,
      "center"
    );

    doc.autoTable({
      startY: 100,
      theme: "grid",
      columns: exportColumns,
      body: dataWithIndex,
    });
    doc.save("School_Bus.pdf");
  };
  //end

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
        <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">Bus</div>
            <div className="page-date">
              <div className="sec-content">
                <AcademicHeader
                  todayDate={todayDate}
                  AcademicYear={AcademicYear}
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <>
          <div className="sec-dataTable content-section main-content">
            <div className="upper-dataTbl">
              <div className="btn-addlnote">
                <button
                  type="button"
                  class="btn btn-sm"
                  style={{
                    background: "var(--MainColor)",
                    color: "white",
                  }}
                  onClick={addnew}
                >
                  Assign Bus
                </button>
                <div className="exportDiv">
                  <Tippy content="Excel">
                    <button className="exportButtons mx-2" onClick={toExcel}>
                      <i className="bi bi-file-earmark-excel-fill exportButtonIconsExcel"></i>
                    </button>
                  </Tippy>

                  <Tippy content="PDF">
                    <button className="exportButtons " onClick={toPdf}>
                      <i className="bi bi-file-earmark-pdf-fill exportButtonIconsPdf"></i>
                    </button>
                  </Tippy>
                </div>
              </div>
            </div>
            <hr className="new-hr-new" />

            {loading ? (
              <Spinner />
            ) : (
              <DataTable
                columns={columns}
                data={assignList}
                customStyles={customStyles}
                pagination
                fixedHeader
                fixedHeaderScrollHeight="280px"
                highlightOnHover
                pointerOnHover
                responsive
                dense
                striped
                subHeader
                subHeaderAlign="left"
                subHeaderComponent={
                  <>
                    <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                      <label className="list-label">Bus</label>
                      <select
                        style={{ fontSize: "11px" }}
                        name="snotifiaction"
                        value={selectBus}
                        onChange={handleBus}
                        className="form-control form-control-sm"
                        aria-label="Default control example "
                      >
                        <option
                          value=""
                          disabled
                          selected
                          style={{ fontSize: "11px" }}
                        >
                          Select Bus
                        </option>

                        {dataList.map((item) => (
                          <option
                            key={item.SchoolBusID}
                            value={item.SchoolBusID}
                          >
                            {item.BusName}
                          </option>
                        ))}
                      </select>
                      <i class="fas fa-angle-down  notificationpositon"></i>
                    </div>

                    <div className="col-md-2 col-sm-2 col-lg-2 dropdown2"></div>

                    <div
                      className="col-lg-2 offset-lg-6 offset-md-6 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
                      style={{
                        marginTop: "25px",
                        paddingRight: "1px",
                        marginBottom: "10px",
                      }}
                    >
                      <div className="d-flex">
                        <input
                          placeholder="Search"
                          ref={searchInput}
                          type="text"
                          className="form-control form-control-sm searchField"
                          onChange={searchHandler}
                        />
                      </div>
                    </div>
                  </>
                }
              />
            )}
          </div>
        </>
      </div>

      {popup && (
        <div className="modals">
          <div className="overlay"></div>

          <div className="notification-model-content">
            <div className="popUpHeader ">
              <div className="popUpHeaderText ">Assign Bus</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closePopup}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="inside-modal">
              <AssignBusForm closePopup={closePopup} />
            </div>
          </div>
        </div>
      )}

      {deletePopup.show && (
        <DeletePop
          handleDeleteTrue={handleDeleteTrue}
          handleDeleteFalse={handleDeleteFalse}
        />
      )}
    </>
  );
}
