import React, { useContext, useEffect } from "react";
import { useState } from "react";
import CloseIcon from "../../images/CloseIcon.svg"
import OnlineClassContext from "../contextfolder/OnlineClassContext/OnlineClassContext";
import './ActivatePop.css';


export default function InactivatePop({ handleInActivateTrue, handleInActivateFalse }) {

    const { inActivateStatusFormError, setInActivateStatusFormError, inActivateIsSubmit, setInActivateIsSubmit, inActivatestatusvalue, inActivateStatusFormValue, setInActivateStatusFormValue } = useContext(OnlineClassContext)

    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.type === "checkbox" ? target.checked : target.value;
        setInActivateStatusFormValue({ ...inActivateStatusFormValue, [name]: value });
    };

    const [state, setState] = useState(false)


    const formNext = (e) => {
        e.preventDefault();
        setInActivateIsSubmit(true);
        setState(true)
    };

    useEffect(() => {

        if (state === true && inActivateIsSubmit) {

            handleInActivateTrue(inActivateStatusFormValue)
        }
        setInActivateIsSubmit(false);
    }, [state]);

    return (
        <>
            <div className="container confirm-popup">
                <div className="confirms-popup-inner ">
                    <div className="popUpHeader ps-0 pe-0">
                        <div className="popUpDelTitle">Easy School</div>
                        <div className="popUpClose">
                            <img style={{ paddingRight: "10px", cursor: "pointer" }}
                                className="popUpCloseIcon"
                                src={CloseIcon}
                                alt="CloseIcon"
                                onClick={handleInActivateFalse}
                            />
                        </div>
                    </div>

                    <div className="confirms-body ps-3 pe-3">
                        <div className="row text-start ">
                            <div className="delText">Are you sure want to Deactivate this?</div>
                        </div>
                        <div className="col-md-12 col-sm-12 col-lg-12" style={{ marginTop: "10px" }}>
                            <div className="form-group">
                                <input
                                    type="checkbox"
                                    // className="form-check-input"
                                    id="exampleCheck3"
                                    name="notify"
                                    onChange={handleChange}
                                    checked={inActivateStatusFormValue.notify}
                                />
                                <label class="form-check-label" for="exampleCheck3" style={{ fontSize: "12px", paddingLeft: "3px" }}>
                                    Notify
                                </label>
                                <p className="errormsg " style={{ textAlign: "left" }}>{inActivateStatusFormError.notify}</p>
                            </div>
                        </div>
                        {/* <div className="col-md-12 col-sm-12 col-lg-12" style={{ marginTop: "10px" }}>
                            <div className="form-group">
                                <input
                                    type="checkbox"
                                    // className="form-check-input"
                                    id="exampleCheck4"
                                    name="autoAttendance"
                                    onChange={handleChange}
                                    checked={inActivateStatusFormValue.autoAttendance}
                                />
                                <label class="form-check-label" for="exampleCheck4" style={{ fontSize: "12px", paddingLeft: "3px" }}>
                                    Auto Attendance
                                </label>
                                <p className="errormsg " style={{ textAlign: "left" }}>{inActivateStatusFormError.autoAttendance}</p>
                            </div>
                        </div> */}
                    </div>

                    <div className="confirm-footer">
                        <div className="row  mt-2 mb-2">
                            <div>
                                <button
                                    type="button"
                                    class="btn btn-sm me-2"
                                    style={{ background: "#0079bf", color: "white" }}
                                    onClick={formNext}
                                >
                                    Okay
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-sm btn-danger me-3"
                                    style={{ color: "white" }}
                                    onClick={handleInActivateFalse}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

