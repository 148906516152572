import React, { useContext, useEffect, useState } from "react";
import SidebarContext from "../sidebarcontext/SidebarContext";
import { Fetchdata } from "../hooks/getData";
import StudyMaterialContext from "../contextfolder/StudyMaterialContext/StudyMaterialContext";
import CloseIcon from "../../images/CloseIcon.svg";
import Plus from "../../images/Plus.png";
import StudyAlbumForm from "./StudyAlbumForm";

export default function StudyMaterialForm({ closePopup }) {
  const { appURL } = useContext(SidebarContext);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const context = useContext(StudyMaterialContext);

  const {
    togglePopup,
    studyFormError,
    setStudyFormError,
    isSubmit,
    setIsSubmit,
    studyValue,
    studyFormValue,
    setStudyFormValue,
    studyFetchData,
    closeChecked,
    setCloseChecked,
    isUploaded,
    setIsUploaded,
    setTypeFile,
    image,
    setImage,
    setIsEdit,
    albumPop,
    toggleAlbumPopup,
    setAlbumFormValue,
    getStudyAlbumList,
    setAlbumFormError,
    albumValue,
    setAlbumIsSubmit,
  } = context;

  const handleOnChange = () => {
    setCloseChecked(!closeChecked);
  };

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setStudyFormValue({ ...studyFormValue, [name]: value });
  };
  function handleImageChange(e) {
    if (e.target.files && e.target.files[0]) {
      setTypeFile(e.target.files[0].name);

      let reader = new FileReader();

      reader.onload = function (e) {
        setImage(e.target.result);
        setIsUploaded(true);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  const formSubmit = (e) => {
    e.preventDefault();
    setStudyFormError(validate(studyFormValue));
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(studyFormError).length === 0 && isSubmit) {
      studyFetchData(studyFormValue);
      if (closeChecked === true) {
        togglePopup();
      }
      setStudyFormValue(studyValue);
      setIsSubmit(false);
      setCloseChecked(false);
      setImage("");
      setIsUploaded(false);
    }
  }, [studyFormError]);

  const validate = (values) => {
    const errors = {};
    const regex = /^(ftp|http|https):\/\/[^ "]+$/;
    if (
      studyFormValue.selectdrop === "C" ||
      studyFormValue.selectdrop === "S" ||
      studyFormValue.selectdrop === "I"
    ) {
      if (!values.class) {
        errors.class = "Required";
      }
    }
    if (
      studyFormValue.selectdrop === "S" ||
      studyFormValue.selectdrop === "I"
    ) {
      if (!values.section) {
        errors.section = "Required";
      }
    }
    if (studyFormValue.selectdrop === "I") {
      if (!values.student) {
        errors.student = "Required";
      }
    }

    if (!values.album) {
      errors.album = "Required";
    }
    if (!values.title) {
      errors.title = "Required";
    }
    if (!values.description) {
      errors.description = "Required";
    }
    if (!values.fileType) {
      errors.fileType = "Required";
    }
    if (studyFormValue.fileType === "U") {
      if (!values.urls) {
        errors.urls = "Required";
      } else if (!regex.test(values.urls)) {
        errors.urls = "This is not a valid url format";
      }
    }
    return errors;
  };

  //API for class list
  const [classList, setClassList] = useState([]);
  useEffect(() => {
    getClassList();
  }, []);

  const getClassList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      DFlag: "C",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setClassList(postResult);
      } else {
        setClassList([]);
      }
    });
  };

  //API for Section List
  const [sectionList, setSectionList] = useState([]);
  useEffect(() => {
    getSectionList();
  }, [studyFormValue.class]);

  const getSectionList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      ClassID: studyFormValue.class,
      DFlag: "S",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setSectionList(postResult);
      } else {
        setSectionList([]);
      }
    });
  };

  //Api to hit student list
  const [studentList, setStudentList] = useState([]);
  useEffect(() => {
    stdList();
  }, [studyFormValue.class, studyFormValue.section]);

  const stdList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      Flag: "S",
      ClassID: studyFormValue.class,
      SectionID: [
        {
          SectionID: studyFormValue.section,
        },
      ],
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/student`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.StudentLst ? result.StudentLst : "";
        setStudentList(postResult);
      } else {
        setStudentList([]);
      }
    });
  };

  //API for album list
  // API for study album List

  const [studyAlbumList, setStudyAlbumList] = useState([]);

  useEffect(() => {
    getAlbumList();
  }, [
    studyFormValue.class,
    studyFormValue.section,
    studyFormValue.student,
    studyFormValue.selectdrop,
  ]);

  const getAlbumList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "S",
      ClassID:
        studyFormValue.selectdrop === "C" ||
        studyFormValue.selectdrop === "S" ||
        studyFormValue.selectdrop === "I"
          ? studyFormValue.class
          : "0",
      SectionID:
        studyFormValue.selectdrop === "S" || studyFormValue.selectdrop === "I"
          ? studyFormValue.section
          : "0",
      MemID: studyFormValue.selectdrop === "I" ? studyFormValue.student : "0",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/study-material/album`,
    };
    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        getStudyAlbumList();
        const postResult = result.StudyMaterialLst
          ? result.StudyMaterialLst
          : "";
        setStudyAlbumList(postResult);
      } else {
        setStudyAlbumList([]);
      }
    });
  };

  const addnew = (e) => {
    e.preventDefault();
    toggleAlbumPopup();
    setAlbumIsSubmit(false);
    setAlbumFormValue(albumValue);
    setStudyFormValue(studyValue);
  };

  const closeAlbumPopup = (e) => {
    e.preventDefault();
    toggleAlbumPopup();
    setCloseChecked(false);
    setAlbumFormValue(albumValue);
    setAlbumFormError({});
    setAlbumIsSubmit(false);
    setIsEdit(false);
    getStudyAlbumList();
  };

  return (
    <>
      <div className="container-fluid basicform">
        <form>
          <div className="form-group">
            <label className="form-label" htmlFor="selectdrop">
              Select Option<sup style={{ color: "red" }}>*</sup>
            </label>
            <select
              id="selectdrop"
              name="selectdrop"
              onChange={handleChange}
              value={studyFormValue.selectdrop}
              className="form-select form-select-sm mb-1"
              style={{ fontSize: "12px" }}
            >
              <option value="" selected disabled>
                Select Option
              </option>
              {/* {userinfo.UserType !== "T" && (
                                <option value="-1">All</option>
                            )} */}
              <option value="-1">All</option>
              <option value="C">Class</option>
              <option value="S">Section</option>
              <option value="I">Individual</option>
            </select>
          </div>

          <div className="form-row">
            {studyFormValue.selectdrop === "C" ||
            studyFormValue.selectdrop === "S" ||
            studyFormValue.selectdrop === "I" ? (
              <div
                className={
                  studyFormValue.selectdrop === "C"
                    ? "form-group col-md-12"
                    : studyFormValue.selectdrop === "S"
                    ? "form-group col-md-6"
                    : "form-group col-md-4"
                }
              >
                <label className="form-label" htmlFor="class">
                  Class<sup style={{ color: "red" }}>*</sup>
                </label>
                <select
                  id="class"
                  name="class"
                  onChange={handleChange}
                  value={studyFormValue.class}
                  className="form-select form-select-sm mb-1"
                  style={{ fontSize: "12px" }}
                >
                  <option
                    value=""
                    disabled
                    selected
                    style={{ fontSize: "11px" }}
                  >
                    Select Class
                  </option>
                  {classList.map((item) => (
                    <option key={item.ClassID} value={item.ClassID}>
                      {item.ClassName}
                    </option>
                  ))}
                </select>
                <p className="errormsg " style={{ marginBottom: "0" }}>
                  {studyFormError.class}
                </p>
              </div>
            ) : (
              <></>
            )}

            {studyFormValue.selectdrop === "S" ||
            studyFormValue.selectdrop === "I" ? (
              <div
                className={
                  studyFormValue.selectdrop === "S"
                    ? "form-group col-md-6"
                    : "form-group col-md-4"
                }
              >
                <label className="form-label" htmlFor="section">
                  Section<sup style={{ color: "red" }}>*</sup>
                </label>
                <select
                  id="section"
                  name="section"
                  onChange={handleChange}
                  value={studyFormValue.section}
                  className="form-select form-select-sm mb-1"
                  style={{ fontSize: "12px" }}
                >
                  <option
                    value=""
                    disabled
                    selected
                    style={{ fontSize: "11px" }}
                  >
                    Select Section
                  </option>
                  {sectionList.map((item) => (
                    <option key={item.SectionID} value={item.SectionID}>
                      {item.SectionName}
                    </option>
                  ))}
                </select>
                <p className="errormsg " style={{ marginBottom: "0" }}>
                  {studyFormError.section}
                </p>
                <i className="fas fa-angle-down  notificationpositon"></i>
              </div>
            ) : (
              <></>
            )}
            {studyFormValue.selectdrop === "I" ? (
              <div className="form-group col-md-4">
                <label className="form-label" htmlFor="ss">
                  Student<sup style={{ color: "red" }}>*</sup>
                </label>
                <select
                  id="ss"
                  name="student"
                  onChange={handleChange}
                  value={studyFormValue.student}
                  className="form-select form-select-sm mb-1"
                  style={{ fontSize: "12px" }}
                >
                  <option
                    value=""
                    disabled
                    selected
                    style={{ fontSize: "11px" }}
                  >
                    Select Student
                  </option>
                  {studentList.map((item) => (
                    <option key={item.StudentID} value={item.StudentID}>
                      {item.StdFullName}
                    </option>
                  ))}
                </select>
                <p className="errormsg " style={{ marginBottom: "0" }}>
                  {studyFormError.student}
                </p>
              </div>
            ) : (
              <></>
            )}
          </div>

          <div className="form-row">
            <div className="form-group col-md-11">
              <label className="form-label" htmlFor="album">
                Album<sup style={{ color: "red" }}>*</sup>
              </label>
              <select
                id="album"
                name="album"
                onChange={handleChange}
                value={studyFormValue.album}
                className="form-select form-select-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="" disabled selected style={{ fontSize: "11px" }}>
                  Select album
                </option>
                {studyAlbumList.map((item) => (
                  <option key={item.StudyAlbumID} value={item.StudyAlbumID}>
                    {item.Title}
                  </option>
                ))}
              </select>
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {studyFormError.album}
              </p>
            </div>

            <div className="form-group col-md-1">
              <div className="add-title-button">
                <button
                  className="btn btn-sm btn-cmpy"
                  style={{
                    background: "0079bf",
                    width: "50px",
                    marginTop: "4px",
                  }}
                  onClick={addnew}
                >
                  <i className="fa fa-plus" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>

          <div className="form-group">
            <label
              className="form-label"
              htmlFor="title"
              style={{ fontSize: "12px" }}
            >
              Title <sup style={{ color: "red" }}>*</sup>
            </label>
            <input
              id="title"
              style={{ fontSize: "13px" }}
              type="text"
              className="form-control form-control-sm"
              name="title"
              value={studyFormValue.title}
              onChange={handleChange}
            />
            <p className="errormsg " style={{ marginBottom: "0" }}>
              {studyFormError.title}
            </p>
          </div>

          <div className="form-group">
            <label
              className="form-label"
              htmlFor="description"
              style={{ fontSize: "12px" }}
            >
              Description <sup style={{ color: "red" }}>*</sup>
            </label>
            <textarea
              id="description"
              style={{ fontSize: "13px" }}
              type="text"
              className="form-control form-control-sm"
              name="description"
              value={studyFormValue.description}
              onChange={handleChange}
            />
            <p className="errormsg " style={{ marginBottom: "0" }}>
              {studyFormError.description}
            </p>
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="fileType">
              File Type<sup style={{ color: "red" }}>*</sup>
            </label>
            <select
              id="fileType"
              name="fileType"
              onChange={handleChange}
              value={studyFormValue.fileType}
              className="form-select form-select-sm mb-1"
              style={{ fontSize: "12px" }}
            >
              <option value="" disabled selected style={{ fontSize: "11px" }}>
                Select file type
              </option>
              <option value="I"> Image </option>
              <option value="P"> PDF </option>
              <option value="U"> URL </option>
            </select>
            <p className="errormsg " style={{ marginBottom: "0" }}>
              {studyFormError.fileType}
            </p>
          </div>

          {studyFormValue.fileType === "U" && (
            <div className="form-group">
              <label
                className="form-label"
                htmlFor="urls"
                style={{ fontSize: "12px" }}
              >
                URL <sup style={{ color: "red" }}>*</sup>
              </label>
              <input
                id="urls"
                style={{ fontSize: "13px" }}
                type="text"
                className="form-control form-control-sm"
                name="urls"
                value={studyFormValue.urls}
                onChange={handleChange}
              />
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {studyFormError.urls}
              </p>
            </div>
          )}
          {studyFormValue.fileType === "I" ||
          studyFormValue.fileType === "P" ||
          studyFormValue.fileType === "PP" ||
          studyFormValue.fileType === "D" ? (
            <div className="form-group  ">
              <div
                className="form-label"
                htmlFor="text"
                style={{ fontSize: "12px", textAlign: "left" }}
              >
                Upload Image
              </div>

              <div className="BoxUpload">
                <div className="image-upload">
                  {!isUploaded ? (
                    <>
                      <label htmlFor="upload-input">
                        <img
                          src={Plus}
                          draggable={"false"}
                          alt="placeholder"
                          style={{ width: 90, height: 100, paddingTop: "10px" }}
                        />
                      </label>

                      <input
                        id="upload-input"
                        type="file"
                        accept=".jpg,.jpeg,.gif,.png,.pdf,.doc,.ppt,.pptx,.docx"
                        onChange={handleImageChange}
                        name="image"
                      />
                    </>
                  ) : (
                    <div className="ImagePreview">
                      <img
                        className="close-icon"
                        src={CloseIcon}
                        alt="CloseIcon"
                        onClick={() => {
                          setIsUploaded(false);
                          setImage(null);
                        }}
                      />

                      <img
                        id="uploaded-image"
                        src={image}
                        draggable={false}
                        alt="uploaded-img"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </form>
      </div>
      <div className="FormFooter">
        <div className="checkedClose">
          <input
            type="checkbox"
            className="form-check-input"
            id="exampleCheck2"
            name="closeChecked"
            onChange={handleOnChange}
            checked={closeChecked}
          />
          <label class="form-check-label" for="exampleCheck2">
            Exit after submit
          </label>
        </div>
        <div>
          <button
            className="btn btn-sm btn-cmpy ml-2"
            style={{ background: "green" }}
            onClick={formSubmit}
          >
            Submit
          </button>
          <button className="btn btn-sm btn-cmpy ml-2" onClick={closePopup}>
            Cancel
          </button>
        </div>
      </div>

      {albumPop && (
        <div className="modals">
          <div className="popup-overlay"></div>

          <div className="credit-model-content">
            <div className="popUpHeader ">
              <div className="popUpHeaderText ">Study Album</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closeAlbumPopup}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="inside-modal">
              <StudyAlbumForm closeAlbumPopup={closeAlbumPopup} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
