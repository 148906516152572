import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";
import "tippy.js/dist/tippy.css";
import { Fetchdata } from "../hooks/getData";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import { convertToNep, getDisableDates } from "../hooks/dateConvertor";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Tippy from "@tippyjs/react";
import TeacherContext from "../contextfolder/TeacherContext/TeacherContext";

import { PDFExport } from "@progress/kendo-react-pdf";
import { useReactToPrint } from "react-to-print";
import TeacherAttendanceContext from "../contextfolder/TeacherAttendanceContext/TeacherAttendanceContext";
import TeacherAttendanceForm from "./TeacherAttendanceForm";

export default function Report(props) {
  const { customStyles, appURL, currentYear, todayDate, monthsBack } =
    useContext(SidebarContext);
  const { teacherList } = useContext(TeacherContext);
  const {
    setAttendanceReportFormValue,
    setAttendanceReportFormError,
    attendanceReportValue,
    togglePopup,
    setCloseChecked,
    setReportIsSubmit,
    popup,
  } = useContext(TeacherAttendanceContext);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);

  const [reportOption, setReportOption] = useState(
    userinfo.UserType === "T" ? "M" : "S"
  );

  const handleReport = (e) => {
    const target = e.target;
    const value = target.value;
    setReportOption(value);
    setSelectYear("");
    setSelectMonth("");
  };

  const reportValue = {
    specificDate: todayDate,
  };

  const [reportDateValue, setReportDateValue] = useState(reportValue);

  const handleSpecificDate = ({ bsDate }) => {
    setReportDateValue({ ...reportDateValue, specificDate: bsDate });
  };

  let date = [];
  for (let i = 2075; i <= currentYear; i++) {
    date.push(i);
  }

  const [selectYear, setSelectYear] = useState("");

  const handleYear = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectYear(value);
    setSelectMonth("");
  };

  const [selectMonth, setSelectMonth] = useState("");

  const handleMonth = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectMonth(value);
  };

  const [selectView, setSelectView] = useState("1");

  const handleView = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectView(value);
  };

  const [selectUser, setSelectUser] = useState("-1");

  const handleUser = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectUser(value);
  };

  const closePopup = (e) => {
    e.preventDefault();
    togglePopup();
    setAttendanceReportFormValue(attendanceReportValue);
    setAttendanceReportFormError({});
    setReportIsSubmit(false);
    setCloseChecked(false);
  };

  const columns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "Name",
      selector: (row) => (reportOption === "S" ? row.StaffName : selectUser),
    },
    {
      name: "Check In",
      selector: (row) => row.CheckIn,
    },
    {
      name: "Check Out",
      selector: (row) => row.CheckOut,
    },
  ];

  const monthColumns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "Date",
      selector: (row) => row.AttenDate,
    },
    {
      name: "Day",
      selector: (row) => row.AttenDay,
    },
    {
      name: "Check In",
      selector: (row) => row.CheckIn,
    },
    {
      name: "Check Out",
      selector: (row) => row.CheckOut,
    },
    {
      name: "Remark",
      selector: (row) => row.IsVerified,
    },
  ];

  // API for attendance list
  const [attendanceList, setAttendanceList] = useState([]);

  useEffect(() => {
    getList();
  }, [reportDateValue, reportOption, selectYear, selectMonth, selectUser]);

  const getList = () => {
    const specificParams = {
      SchID: userinfo.SchID,
      UserID:
        reportOption === "S"
          ? "-1"
          : userinfo.UserType === "T"
          ? userinfo.UserID
          : selectUser,
      Flag: reportOption === "S" ? "D" : "M",
      DFlag: "N",
      // Value: reportOption === "S" ? reportDateValue.specificDate : reportOption === "Y" ? selectYear : reportOption === "M" ? selectYear + "/" + selectMonth : "",
      Value:
        reportOption === "S"
          ? reportDateValue.specificDate
          : selectYear + "/" + selectMonth,
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/atten-report`,
    };

    Fetchdata(specificParams).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.AttenLst ? result.AttenLst : "";

        setLoading(false);
        setAttendanceList(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        setAttendanceList([]);
        setOriginalList([]);
      }
    });
  };

  //This is for search purposes only

  const searchInput = useRef("");

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["StaffName"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setAttendanceList(srchResult);
      } else {
        setAttendanceList({});
      }
    } else {
      setAttendanceList(originalList);
    }
  };

  /* This is for export csv */

  const dataToExcel = attendanceList.map((d, i) => ({
    "S.N.": i + 1,
    Name: d.StaffName,
    "Check In": d.CheckIn,
    "Check Out": d.CheckOut,
  }));

  const dataToExcels = attendanceList.map((d, i) => ({
    "S.N.": i + 1,
    Date: d.AttenDate,
    Day: d.AttenDay,
    "Check In": d.CheckIn,
    "Check Out": d.CheckOut,
    Remark: d.IsVerified,
  }));

  const toExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(
        reportOption === "S" ? dataToExcel : dataToExcels
      );
      var wscols = [{ wch: 5 }, { wch: 20 }, { wch: 20 }, { wch: 20 }];
      var wscolss = [
        { wch: 5 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
      ];
      worksheet["!cols"] = reportOption === "S" ? wscols : wscolss;
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "Attendance_Report");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_" + "export" + EXCEL_EXTENSION
        );
      }
    });
  };

  //end

  //This is to export pdf

  const dataWithIndex = attendanceList.map((d, i) => ({
    ...d,
    Index: i + 1,
  }));

  const cols = [
    { header: "S.N", field: "Index" },
    { header: "Name", field: "StaffName" },
    { header: "Check In", field: "CheckIn" },
    { header: "Check Out", field: "CheckOut" },
  ];
  const colss = [
    { header: "S.N", field: "Index" },
    { header: "Date", field: "AttenDate" },
    { header: "Day", field: "AttenDay" },
    { header: "Remark", field: "IsVerified" },
    { header: "Check In", field: "CheckIn" },
    { header: "Check Out", field: "CheckOut" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));
  const exportColumnss = colss.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const toPdf = () => {
    const doc = new jsPDF("p", "pt", "a4", true);

    var midPage = doc.internal.pageSize.getWidth() / 2;

    // doc.addImage(`${userinfo.SchPhoto}`, "JPG", 40, 40, 70, 60);

    doc.setFontSize(18);
    doc.text(`${userinfo.SchName}`, midPage, 50, null, null, "center");

    doc.setFontSize(15);
    doc.text(`${userinfo.SchAddress}`, midPage, 68, null, null, "center");

    doc.setFontSize(10);
    doc.text(
      `Tel:${userinfo.Phone}, E-mail:${userinfo.Email}`,
      midPage,
      85,
      null,
      null,
      "center"
    );

    doc.autoTable({
      startY: 100,
      theme: "grid",
      columns: reportOption === "S" ? exportColumns : exportColumnss,
      body: dataWithIndex,
    });
    doc.save("Attendance_Report.pdf");
  };
  //end

  // For Monthwise attendance date column wise for all staff list

  // -----new attendance list-----------

  const groupedByName = attendanceList.reduce((acc, curr) => {
    if (acc[curr.StaffName]) {
      acc[curr.StaffName].push(curr);
    } else {
      acc[curr.StaffName] = [curr];
    }
    return acc;
  }, {});

  const newAttendanceList = Object.keys(groupedByName).map((name) => ({
    StaffName: name,
    data: groupedByName[name].reverse(),
  }));

  // ------- list of unique date only -------
  const uniqueDate = [...new Set(attendanceList.map((item) => item.AttenDate))];

  const value = null;
  let uniqueDates = uniqueDate.reverse();
  uniqueDates = uniqueDates.filter(function (item) {
    return item !== value;
  });

  // ----new object to match AttenDate with unique date ----

  // create an object to store the attendance data
  const attendanceData = {};

  // loop through the data and populate the object
  newAttendanceList.forEach((item) => {
    attendanceData[item.StaffName] = {};

    uniqueDates.forEach((date) => {
      const data = item.data.find((d) => d.AttenDate === date);

      if (data) {
        attendanceData[item.StaffName][date] = {
          CheckIn: data.CheckIn,
          CheckOut: data.CheckOut,
          //   DayType: data.DayType,
          LeaveID: data.LeaveID,
          AttenID: data.AttenID,
        };
      } else {
        attendanceData[item.StaffName][date] = null;
      }
    });
  });

  // For Monthwise attendance date column wise for all staff list  ends

  // FOr pdf for complex table in teacher attendance report

  const pdfExportComponent = useRef(null);

  const handleExportWithComponent = (e) => {
    pdfExportComponent.current.save();
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const viewTwo = [];
  for (let i = 0; i < uniqueDates.length; i += 6) {
    viewTwo.push(uniqueDates.slice(i, i + 6));
  }

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      {/* <div>
                <ul>{date.map(name => <li key={name}> {name} </li>)}</ul>
            </div> */}

      {/* onClick={handleExportWithComponent} */}

      <div className="sec-dataTable">
        <div className="upper-dataTbl">
          {reportOption === "M" && selectUser === "-1" ? (
            <Tippy content="PDF">
              <button
                className="exportButtons ml-1"
                onClick={handleExportWithComponent}
              >
                <i className="bi bi-file-earmark-pdf-fill exportButtonIconsPdf"></i>
              </button>
            </Tippy>
          ) : (
            <Tippy content="PDF">
              <button className="exportButtons ml-1" onClick={toPdf}>
                <i className="bi bi-file-earmark-pdf-fill exportButtonIconsPdf"></i>
              </button>
            </Tippy>
          )}

          {reportOption === "M" && selectUser === "-1" ? (
            <ReactHTMLTableToExcel
              id="test-table-xls-button"
              className="exportButtons"
              table="table-to-xls"
              filename="tablexls"
              sheet="tablexls"
              buttonText={
                <i className="bi bi-file-earmark-excel-fill exportButtonIconsExcel"></i>
              }
            />
          ) : (
            <Tippy content="Excel">
              <button className="exportButtons" onClick={toExcel}>
                <i className="bi bi-file-earmark-excel-fill exportButtonIconsExcel"></i>
              </button>
            </Tippy>
          )}
        </div>

        <hr className="new-hr-new" />
        {loading ? (
          <Spinner />
        ) : (
          <>
            <div className="row atten-report">
              {userinfo.UserType === "A" && (
                <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                  <label className="list-label">Select Option</label>
                  <select
                    style={{ fontSize: "11px" }}
                    name="status"
                    className="form-control form-control-sm"
                    aria-label="Default select example "
                    value={reportOption}
                    onChange={handleReport}
                  >
                    <option
                      selected
                      disabled
                      value=""
                      style={{ fontSize: "11px" }}
                    >
                      Select Option
                    </option>
                    <option value="S">Specific Date</option>
                    <option value="M">Select Month</option>
                  </select>
                  <i class="fas fa-angle-down  notificationpositon"></i>
                </div>
              )}

              {reportOption === "S" && (
                <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                  <label className="list-label">Date</label>
                  <Calendar
                    className="form-control form-control-sm"
                    dateFormat="YYYY/MM/DD"
                    language="en"
                    values={reportDateValue.specificDate}
                    onChange={handleSpecificDate}
                    maxDate={getDisableDates()}
                  />
                </div>
              )}

              {reportOption === "M" ? (
                <>
                  <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                    <label className="list-label">Select Year</label>
                    <select
                      style={{ fontSize: "11px", marginBottom: "3px" }}
                      name="snotifiaction"
                      value={selectYear}
                      onChange={handleYear}
                      className="form-control form-control-sm"
                      aria-label="Default select example "
                    >
                      <option
                        value=""
                        disabled
                        selected
                        style={{ fontSize: "11px" }}
                      >
                        Select Year
                      </option>
                      {date
                        .map((item) => (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        ))
                        .reverse()}
                    </select>
                    <i class="fas fa-angle-down  notificationpositon"></i>
                  </div>

                  <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                    <label className="list-label">Select Month</label>
                    <select
                      style={{ fontSize: "11px" }}
                      name="status"
                      className="form-control form-control-sm"
                      aria-label="Default select example "
                      value={selectMonth}
                      onChange={handleMonth}
                    >
                      <option
                        selected
                        disabled
                        value=""
                        style={{ fontSize: "11px" }}
                      >
                        Select Month
                      </option>
                      <option value="01">Baishakh</option>
                      <option value="02">Jestha</option>
                      <option value="03">Asar</option>
                      <option value="04">Shawan</option>
                      <option value="05">Bhadra</option>
                      <option value="06">Asoj</option>
                      <option value="07">Kartik</option>
                      <option value="08">Mangsir</option>
                      <option value="09">Poush</option>
                      <option value="10">Magh</option>
                      <option value="11">Falgun</option>
                      <option value="12">Chaitra</option>
                    </select>
                    <i class="fas fa-angle-down  notificationpositon"></i>
                  </div>
                  {userinfo.UserType === "A" && (
                    <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                      <label className="list-label">Select Teacher</label>
                      <select
                        style={{ fontSize: "11px", marginBottom: "3px" }}
                        name="snotifiaction"
                        value={selectUser}
                        onChange={handleUser}
                        className="form-control form-control-sm"
                        aria-label="Default select example "
                      >
                        <option
                          value=""
                          disabled
                          selected
                          style={{ fontSize: "11px" }}
                        >
                          Select Teacher
                        </option>
                        <option value="-1">All</option>
                        {teacherList.map((item, i) => (
                          <option key={i} value={item.TeacherID}>
                            {item.TeacherName}
                          </option>
                        ))}
                      </select>
                      <i class="fas fa-angle-down  notificationpositon"></i>
                    </div>
                  )}
                  <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                    <label className="list-label">Select View</label>
                    <select
                      style={{ fontSize: "11px" }}
                      name="status"
                      className="form-control form-control-sm"
                      aria-label="Default select example "
                      value={selectView}
                      onChange={handleView}
                    >
                      <option value="1">View 1</option>
                      <option value="2">View 2</option>
                    </select>
                    <i class="fas fa-angle-down  notificationpositon"></i>
                  </div>
                </>
              ) : (
                <></>
              )}

              <div
                className={
                  reportOption === "S" || userinfo.UserType === "T"
                    ? "col-lg-2 offset-lg-6 offset-md-6 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
                    : reportOption === "F" || reportOption === "M"
                    ? "col-lg-2 offset-lg-0 offset-md-0 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
                    : "col-lg-2 offset-md-8 offset-lg-8 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
                }
                style={{ paddingRight: "1px", marginBottom: "10px" }}
              >
                <div className="d-flex">
                  <input
                    placeholder="Search"
                    ref={searchInput}
                    type="text"
                    className="form-control form-control-sm searchField"
                    onChange={searchHandler}
                  />
                </div>
              </div>
            </div>
            <>
              {reportOption === "M" && (
                <>
                  {selectUser === "-1" &&
                    selectView === "1" &&
                    (attendanceList.length > 0 ? (
                      <PDFExport ref={pdfExportComponent}>
                        <div className="table-responsive br-3">
                          <table
                            className="table table-striped table-bordereds text-nowrap mb-0"
                            id="table-to-xls"
                          >
                            {/* head */}

                            <tr className="tableHead">
                              <td
                                rowSpan="2"
                                className="rdt_TableHeadRow tableHead text-center"
                                width="70px"
                                style={{
                                  verticalAlign: "middle",
                                  borderBottom: "0px",
                                  textAlign: "center",
                                  fontWeight: "bold",
                                }}
                              >
                                S.N.
                              </td>
                              <td
                                rowSpan="2"
                                className="rdt_TableHeadRow tableHead text-start"
                                width="200px"
                                style={{
                                  verticalAlign: "middle",
                                  borderBottom: "0px",
                                  fontWeight: "bold",
                                }}
                              >
                                Full Name
                              </td>

                              {uniqueDates.map((date) => {
                                return (
                                  <td
                                    colSpan="2"
                                    className="rdt_TableHeadRow tableHead text-center"
                                    width="200px"
                                    style={{
                                      fontWeight: "bold",
                                      borderBottom: "0px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {date}
                                  </td>
                                );
                              })}
                            </tr>

                            <tr className="head-bottom">
                              {uniqueDates.map((props) => {
                                return (
                                  <>
                                    <td
                                      className="rdt_TableHeadRow tableHead text-center"
                                      width="150px"
                                      style={{
                                        fontWeight: "bold",
                                        borderBottom: "0px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Check In
                                    </td>
                                    <td
                                      className="rdt_TableHeadRow tableHead text-center"
                                      width="150px"
                                      style={{
                                        fontWeight: "bold",
                                        borderBottom: "0px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Check Out
                                    </td>
                                  </>
                                );
                              })}
                            </tr>

                            {/* head */}

                            {/* cell */}
                            {Object.keys(attendanceData).map((fullName, i) => {
                              return (
                                <>
                                  <tr>
                                    <td
                                      // rowSpan="2"
                                      style={{ textAlign: "center" }}
                                      className="rdt_TableCell tablecell  text-center"
                                    >
                                      {i + 1}
                                    </td>

                                    <td
                                      // rowSpan="2"
                                      className="rdt_TableCell tablecell text-start"
                                    >
                                      {fullName}
                                    </td>
                                    {uniqueDates.map((date) => {
                                      const data =
                                        attendanceData[fullName][date];

                                      if (data) {
                                        return (
                                          <React.Fragment key={date}>
                                            <td
                                              className="rdt_TableCell tablecell text-center"
                                              style={{ textAlign: "center" }}
                                            >
                                              {data.AttenID === null ? (
                                                <span>No Check In</span>
                                              ) : data.CheckIn === null ? (
                                                <span>No Check In</span>
                                              ) : (
                                                data.CheckIn
                                              )}
                                            </td>
                                            <td
                                              className="rdt_TableCell tablecell text-center"
                                              style={{ textAlign: "center" }}
                                            >
                                              {data.AttenID === null ? (
                                                <span>No Check Out</span>
                                              ) : data.CheckOut === null ? (
                                                <span>No Check Out</span>
                                              ) : (
                                                data.CheckOut
                                              )}
                                            </td>
                                          </React.Fragment>
                                        );
                                      } else {
                                        return (
                                          <React.Fragment key={date}>
                                            <td
                                              className="rdt_TableCell tablecell text-center"
                                              style={{ textAlign: "center" }}
                                            >
                                              No Check In
                                            </td>
                                            <td
                                              className="rdt_TableCell tablecell text-center"
                                              style={{ textAlign: "center" }}
                                            >
                                              No Check Out
                                            </td>
                                          </React.Fragment>
                                        );
                                      }
                                    })}
                                  </tr>
                                </>
                              );
                            })}
                          </table>
                        </div>
                      </PDFExport>
                    ) : (
                      <div className="table-responsive br-3">
                        <div className="text-center mt-4">
                          <p>There are no records to display.</p>
                        </div>
                      </div>
                    ))}

                  {selectUser !== "-1" && (
                    <DataTable
                      columns={monthColumns}
                      data={attendanceList}
                      customStyles={customStyles}
                      pagination
                      fixedHeader
                      fixedHeaderScrollHeight="280px"
                      highlightOnHover
                      pointerOnHover
                      responsive
                      dense
                      striped
                    />
                  )}

                  {selectUser === "-1" &&
                    selectView === "2" &&
                    (attendanceList.length > 0 ? (
                      <PDFExport ref={pdfExportComponent}>
                        <div className="table-responsive br-3">
                          <table
                            className="table new__table table-striped table-bordereds text-nowrap mb-0"
                            id="table-to-xls"
                            // style={{ width: "max-content" }}
                          >
                            {/* head */}

                            <tr className="tableHead">
                              <td
                                className="rdt_TableHeadRow tableHead text-center"
                                width="70px"
                                style={{
                                  textAlign: "center",
                                  verticalAlign: "middle",
                                  borderBottom: "0px",
                                  fontWeight: "bold",
                                }}
                              >
                                S.N.
                              </td>

                              <td
                                className="rdt_TableHeadRow tableHead text-center"
                                // width="200px"
                                colSpan={
                                  uniqueDates.length < 6
                                    ? uniqueDates.length
                                    : 6
                                }
                                style={{
                                  borderBottom: "0px",
                                  textAlign: "center",
                                }}
                              ></td>
                            </tr>

                            {/* head */}

                            {/* cell */}
                            {Object.keys(attendanceData).map((fullName, i) => {
                              return (
                                <>
                                  <tr>
                                    <td
                                      rowSpan={viewTwo.length + 1}
                                      className="rdt_TableCell tablecell  text-center"
                                      style={{ textAlign: "center",fontWeight: "bold", }}
                                    >
                                      {i + 1}
                                    </td>

                                    <td
                                      colSpan={
                                        uniqueDates.length < 6
                                          ? uniqueDates.length
                                          : 6
                                      }
                                      style={{
                                        textAlign: "center",
                                        backgroundColor: "#eee",
                                        fontWeight: "bold",
                                      }}
                                      className="rdt_TableCell tablecell font-weight-bold text-center"
                                    >
                                      {fullName}
                                    </td>
                                  </tr>

                                  {viewTwo.map((row, rowIndex) => {
                                    return (
                                      <tr
                                        className="new__table__row"
                                        key={rowIndex}
                                      >
                                        {row.map((item, colIndex) => {
                                          const data =
                                            attendanceData[fullName][item];
                                          return (
                                            <td
                                              className="rdt_TableHeadRow tableHead text-center"
                                              //   width="200px"
                                              style={{ borderBottom: "0px", textAlign: "center", }}
                                            >
                                              <div className="table__date">
                                                {item}
                                              </div>
                                              <div className="table__time">
                                                {data ? (
                                                  <React.Fragment>
                                                    {data.AttenID === null ? (
                                                      <span>No Check In</span>
                                                    ) : data.CheckIn ===
                                                      null ? (
                                                      <span>No Check In</span>
                                                    ) : (
                                                      data.CheckIn
                                                    )}{" "}
                                                    -{" "}
                                                    {data.AttenID === null ? (
                                                      <span>No Check Out</span>
                                                    ) : data.CheckOut ===
                                                      null ? (
                                                      <span>No Check Out</span>
                                                    ) : (
                                                      data.CheckOut
                                                    )}
                                                  </React.Fragment>
                                                ) : (
                                                  <React.Fragment>
                                                    No Check In/Out
                                                  </React.Fragment>
                                                )}
                                              </div>
                                            </td>
                                          );
                                        })}
                                        {/* {row.length < 6 &&
                                          Array.from({
                                            length: 6 - row.length,
                                          }).map((_, colIndex) => (
                                            <td key={`empty-${colIndex}`}></td>
                                          ))} */}
                                      </tr>
                                    );
                                  })}

                                 
                                </>
                              );
                            })}
                          </table>
                        </div>
                      </PDFExport>
                    ) : (
                      <div className="table-responsive br-3">
                        <div className="text-center mt-4">
                          <p>There are no records to display.</p>
                        </div>
                      </div>
                    ))}
                </>
              )}

              {reportOption === "S" && (
                <DataTable
                  columns={columns}
                  data={attendanceList}
                  customStyles={customStyles}
                  pagination
                  fixedHeader
                  fixedHeaderScrollHeight="280px"
                  highlightOnHover
                  pointerOnHover
                  responsive
                  dense
                  striped
                />
              )}
            </>
          </>
        )}
      </div>

      {popup && (
        <div className="modals">
          <div className="overlay"></div>

          <div className="teacher-routes-model-content">
            <div className="popUpHeader ">
              <div className="popUpHeaderText">Add Attendance</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closePopup}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="inside-modal">
              <TeacherAttendanceForm
                closePopup={closePopup}
                getList={getList}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
