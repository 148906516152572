import React, { useContext, useEffect, useState } from "react";
import { Fetchdata } from "../../hooks/getData";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { toast } from "react-toastify";
import AdminLeaveContext from "./AdminLeaveContext";
import LeaveApplicationContext from "../LeaveApplicationContext/LeaveApplicationContext";

function AdminLeaveState(props) {
  const { appURL } = useContext(SidebarContext);
  const { getLeaveApplicationList } = useContext(LeaveApplicationContext);

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);

  const [closeChecked, setCloseChecked] = useState(false);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const [reportOption, setReportOption] = useState("");
  const [selectStudentStatus, setSelectStudentStatus] = useState("-1");
  const [selectTeacherStatus, setSelectTeacherStatus] = useState("-1");
  const [selectAdminStatus, setSelectAdminStatus] = useState("-1");
  const [selectAll, setSelectAll] = useState("-1");

  const [dateForStudent, setDateForStudent] = useState("");
  const [dateForTeacher, setDateForTeacher] = useState("");
  const [dateForAdmin, setDateForAdmin] = useState("");
  const [nepaliDate, setNepaliDate] = useState("");

  const [selectClass, setSelectClass] = useState("");
  const [selectSection, setSelectSection] = useState("");
  const [selectStudent, setSelectStudent] = useState("");
  const [selectTeacher, setSelectTeacher] = useState("");
  const [selectAdmin, setSelectAdmin] = useState("");

  const remarksvalue = {
    status: "",
    remarks: "",
  };
  const [remarksFormValue, setRemarksFormValue] = useState(remarksvalue);

  //Initializing initial value for view  adminLeave Popup

  const [remarksIsSubmit, setRemarksIsSubmit] = useState(false);

  const [pushNotice, setPushNotice] = useState(false);

  // For popup
  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  const [classList, setClassList] = useState([]);
  const [sectionList, setSectionList] = useState([]);

  //API to hit class list
  useEffect(() => {
    getClassList();
  }, []);

  const getClassList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      DFlag: "C",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setClassList(postResult);
      } else {
        setClassList([]);
      }
    });
  };

  //API to hit section list
  useEffect(() => {
    getSectionList();
  }, [selectClass]);

  const getSectionList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      ClassID: selectClass,
      DFlag: "S",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";

        setSectionList(postResult);
      } else {
        setSectionList([]);
      }
    });
  };

  //API to hit Student list
  const [studentList, setStudentList] = useState([]);

  useEffect(() => {
    stdList();
  }, [selectClass, selectSection]);

  const stdList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      Flag: "S",
      ClassID: selectClass,
      SectionID: [
        {
          SectionID: selectSection,
        },
      ],
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/student`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.StudentLst ? result.StudentLst : "";
        setStudentList(postResult);
      } else {
        setStudentList([]);
      }
    });
  };

  //API to hit Teacher list
  const [teacherList, setTeacherList] = useState([]);

  useEffect(() => {
    teachList();
  }, []);

  const teachList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/teacher`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.teachingres ? result.teachingres : "";
        setTeacherList(postResult);
      } else {
        setTeacherList([]);
      }
    });
  };

  //API to hit Leave list
  const [leaveList, setLeaveList] = useState([]);

  useEffect(() => {
    getLeaveList();
  }, [
    reportOption,
    selectClass,
    selectSection,
    selectStudent,
    selectTeacher,
    selectAdmin,
    nepaliDate,
    dateForStudent,
    dateForTeacher,
    dateForAdmin,
    selectAll,
    selectStudentStatus,
    selectTeacherStatus,
    selectAdminStatus,
  ]);

  const getLeaveList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      Flag: "S",
      DFlag: "E",
      UserType: "-1",
      ClassID: reportOption === "S" ? selectClass : "-1",
      SectionID: reportOption === "S" ? selectSection : "-1",
      MemID:
        reportOption === "S"
          ? selectStudent
          : reportOption === "T"
          ? selectTeacher
          : reportOption === "A"
          ? selectAdmin
          : "-1",
      Date:
        reportOption === "all"
          ? nepaliDate.fromDate
          : reportOption === "S"
          ? dateForStudent.studentDate
          : reportOption === "T"
          ? dateForTeacher.teacherDate
          : reportOption === "A"
          ? dateForAdmin.adminDate
          : "",
      IsApproved:
        reportOption === "all"
          ? selectAll
          : reportOption === "S"
          ? selectStudentStatus
          : reportOption === "T"
          ? selectTeacherStatus
          : reportOption === "A"
          ? selectAdminStatus
          : "-1",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/leave`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.LeaveLst ? result.LeaveLst : "";

        setLoading(false);
        setLeaveList(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        setLeaveList([]);
        setOriginalList([]);
      }
    });
  };

  // Remarks value for approve and reject

  const leaveValue = {
    approveRemarks: "",
    rejectRemarks: "",
  };

  const [leaveFormValue, setLeaveFormValue] = useState(leaveValue);
  const [leaveFormError, setLeaveFormError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  //Everything to pop up and approve the leave list

  //State for notify user

  const [approveNotify, setApproveNotify] = useState(false);
  // Stae for notify user ends

  const [approvePopup, setApprovePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const [Acdata, setACData] = useState();

  const handleApprove = async (data) => {
    setACData(data);
    setApprovePopup({
      show: true,
      data,
    });
  };

  // This will perform the deletion and hide the Confirmation Box

  const handleApproveTrue = async () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "US",
      LeaveID: Acdata,
      IsApproved: "Y",
      ApprovedRemarks: leaveFormValue.approveRemarks,
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/leave`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          getLeaveList();
          getLeaveApplicationList();
          toast(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });

          setApprovePopup({
            show: false,
          });
        } else {
          toast(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleApproveFalse = () => {
    setLeaveFormError({});
    setApprovePopup({
      show: false,
      data: null,
    });
    setIsSubmit(false);
  };

  // Ends Here

  //Everything to pop up and reject the leave list

  //State for notify user

  const [rejectNotify, setRejectNotify] = useState(false);
  // Stae for notify user ends

  const [rejectPopup, setRejectPopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const [rdata, setRData] = useState();

  const handleReject = async (data) => {
    setRData(data);

    setRejectPopup({
      show: true,
      data,
    });
  };

  // This will perform the deletion and hide the Confirmation Box

  const handleRejectTrue = async () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "US",
      LeaveID: rdata,
      IsApproved: "N",
      ApprovedRemarks: leaveFormValue.rejectRemarks,
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/leave`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          getLeaveList();
          getLeaveApplicationList();
          toast(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
          setRejectPopup({
            show: false,
          });
        } else {
          toast(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleRejectFalse = () => {
    setLeaveFormError({});
    setRejectPopup({
      show: false,
      data: null,
    });
    setIsSubmit(false);
  };

  // Ends Here

  // For Admin Leave View

  const [viewId, setViewId] = useState("");
  const [state, setState] = useState();
  const view = (data) => {
    setState(data);
    toggleModal();
    setViewId(data.LeaveID);
    setRemarksFormValue({
      status: data.IsApprovedID,
      remarks: data.ApprovedRemarks,
    });
  };

  const viewdata = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "US",
      LeaveID: viewId,
      IsApproved: remarksFormValue.status,
      ApprovedRemarks: remarksFormValue.remarks,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/leave`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getLeaveList();
        getLeaveApplicationList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };

  return (
    <AdminLeaveContext.Provider
      value={{
        modal,
        setModal,
        toggleModal,
        loading,
        originalList,
        approvePopup,
        handleApproveFalse,
        handleApproveTrue,
        handleApprove,
        rejectPopup,
        handleRejectTrue,
        handleRejectFalse,
        handleReject,
        setLoading,
        setOriginalList,
        classList,
        sectionList,
        selectClass,
        setSelectClass,
        selectSection,
        setSelectSection,
        studentList,
        selectStudent,
        setSelectStudent,
        teacherList,
        selectTeacher,
        setSelectTeacher,
        reportOption,
        setReportOption,
        selectStudentStatus,
        setSelectStudentStatus,
        selectTeacherStatus,
        setSelectTeacherStatus,
        selectAdminStatus,
        setSelectAdminStatus,
        dateForStudent,
        setDateForStudent,
        dateForTeacher,
        setDateForTeacher,
        dateForAdmin,
        setDateForAdmin,
        selectAdmin,
        setSelectAdmin,
        nepaliDate,
        setNepaliDate,
        selectAll,
        setSelectAll,
        leaveList,
        approveNotify,
        setApproveNotify,
        rejectNotify,
        setRejectNotify,
        leaveFormValue,
        setLeaveFormValue,
        leaveFormError,
        setLeaveFormError,
        isSubmit,
        setIsSubmit,
        leaveValue,
        remarksFormValue,
        setRemarksFormValue,
        remarksIsSubmit,
        setRemarksIsSubmit,
        remarksvalue,
        pushNotice,
        setPushNotice,
        setLeaveList,
        closeChecked,
        setCloseChecked,
        viewdata,
        state,
        view,
      }}
    >
      {props.children}
    </AdminLeaveContext.Provider>
  );
}

export default AdminLeaveState;
