import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { showImgPreview } from "../hooks/ImagePreview";
import ViewBlog from "./ViewBlog";
import { GetNepaliDate } from "../hooks/dateConvertor";
import AcademicHeader from "../AcademicHeader";

export default function StudentBlog() {
  const { todayDate, customStylesForImage, AcademicYear, appURL } =
    useContext(SidebarContext);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);
  const [closeChecked, setCloseChecked] = useState(false);

  const [imgPrv, setImgPrv] = useState(false);
  const [imagePre, setImagePre] = useState("");

  const [popup, setPopup] = useState(false);

  const togglePopup = () => {
    setPopup(!popup);
  };

  const [categoryList, setCategoryList] = useState([]);
  const [selectCategory, setSelectCategory] = useState("");

  useEffect(() => {
    const user = `${appURL}api/blog-category?SchID=${userinfo.SchID}&Primary=-1`;
    fetch(user)
      .then((res) => res.json())
      .then((result) => {
        if (result.StatusCode === 200) {
          const postResult = result.BlogCatLst ? result.BlogCatLst : "";
          setCategoryList(postResult);
        } else {
          setCategoryList([]);
        }
      });
  }, []);

  const handleCategory = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectCategory(value);
  };

  const [blogList, setBlogList] = useState([]);
  useEffect(() => {
    const user = `${appURL}api/blogs?SchID=${userinfo.SchID}&CategoryID=${selectCategory}&Count=-1&Via=1`;

    fetch(user)
      .then((res) => res.json())
      .then((result) => {
        if (result.StatusCode === 200) {
          const postResult = result.BlogLst ? result.BlogLst : "";
          setLoading(false);
          setBlogList(postResult);
          setOriginalList(postResult);
        } else {
          setBlogList([]);
          setLoading(false);
          setOriginalList("");
        }
      });
  }, [selectCategory]);

  const searchInput = useRef("");

  const columns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "Title",
      selector: (row) => row.Title,
    },
    {
      name: "Excerpt",
      selector: (row) => row.Excerpt,
    },
    {
      name: "Feat. Image",
      width: "140px",
      center: true,
      selector: (row) => {
        return (
          <>
            <div className="staffContentLogo">
              <div className="staffImg">
                <img
                  src={row.FeatImg}
                  alt=""
                  onClick={() => {
                    setImagePre(row.FeatImg);
                    setImgPrv(true);
                  }}
                />
              </div>
            </div>
          </>
        );
      },
    },
    {
      name: "Created Date",
      width: "180px",
      selector: (row) => GetNepaliDate(row.CreatedDate.split(" ")[0]),
    },
    {
      name: "Action",
      // grow: 0,
      center: true,
      width: "100px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <Tippy content="View">
                <i
                  className="bi bi-eye solvespan--action"
                  onClick={() => view(row)}
                  style={{ paddingTop: "3px" }}
                ></i>
              </Tippy>
            </div>
          </>
        );
      },
    },
  ];

  const closeViewPopup = (e) => {
    e.preventDefault();
    togglePopup();
    setCloseChecked(false);
  };

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["Title"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setBlogList(srchResult);
      } else {
        setBlogList({});
      }
    } else {
      setBlogList(originalList);
    }
  };

  // for view blog
  const [blogID, setBlogID] = useState("");

  const view = (data) => {
    togglePopup();
    setBlogID(data.BlogID);
  };

  const [bloginfo, setBlogInfo] = useState([]);

  useEffect(() => {
    const user = `${appURL}api/blog-info?SchID=${userinfo.SchID}&BlogID=${blogID}&Via=1`;

    fetch(user)
      .then((res) => res.json())
      .then((result) => {
        if (result.StatusCode === 200) {
          const postResult = result.BlogLst[0] ? result.BlogLst[0] : "";

          setBlogInfo(postResult);
        } else {
          setBlogInfo([]);
        }
      });
  }, [blogID]);

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
        <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">Blog</div>
            <div className="page-date">
              <div className="sec-content">
                <AcademicHeader
                  todayDate={todayDate}
                  AcademicYear={AcademicYear}
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <>
          <div className="sec-dataTable content-section main-content">
            {loading ? (
              <Spinner />
            ) : (
              <DataTable
                columns={columns}
                data={blogList}
                customStyles={customStylesForImage}
                pagination
                fixedHeader
                fixedHeaderScrollHeight="280px"
                highlightOnHover
                pointerOnHover
                responsive
                dense
                striped
                subHeader
                subHeaderAlign="left"
                subHeaderComponent={
                  <>
                    <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                      <label className="list-label">Blog Category</label>
                      <select
                        style={{ fontSize: "11px", marginBottom: "3px" }}
                        name="snotifiaction"
                        value={selectCategory}
                        onChange={handleCategory}
                        className="form-control form-control-sm"
                        aria-label="Default select example "
                      >
                        <option
                          value=""
                          disabled
                          selected
                          style={{ fontSize: "11px" }}
                        >
                          Select Blog Category
                        </option>

                        {categoryList.map((item) => (
                          <option key={item.CategoryID} value={item.CategoryID}>
                            {item.Category}
                          </option>
                        ))}
                      </select>
                      <i class="fas fa-angle-down  notificationpositon"></i>
                    </div>

                    <div
                      className="col-lg-2 offset-lg-8 offset-md-8 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
                      style={{
                        marginTop: "25px",
                        paddingRight: "1px",
                        marginBottom: "10px",
                      }}
                    >
                      <div className="d-flex">
                        <input
                          placeholder="Search"
                          ref={searchInput}
                          type="text"
                          className="form-control form-control-sm searchField"
                          onChange={searchHandler}
                        />
                      </div>
                    </div>
                  </>
                }
              />
            )}
          </div>
        </>
      </div>
      {popup && (
        <div className="modals">
          <div className="overlay">
            <div className="notification-model-content">
              <div className="popUpHeader ">
                <div className="popUpHeaderText ">View Blog</div>
                <div style={{ cursor: "pointer" }}>
                  <i
                    className="bi bi-x "
                    onClick={closeViewPopup}
                    style={{ fontSize: "25px" }}
                  ></i>
                </div>
              </div>
              <div className="inside-modal">
                <ViewBlog
                  closeViewPopup={closeViewPopup}
                  closeChecked={closeChecked}
                  setCloseChecked={setCloseChecked}
                  bloginfo={bloginfo}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {imgPrv &&
        showImgPreview({
          img: imagePre,
          setTrigger: setImgPrv,
        })}
    </>
  );
}
