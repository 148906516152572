import React from 'react'
import AOS from 'aos';
const About = () => {
    AOS.init();
    return (
        <>
            <section id="about" className="about" style={{ backgroundColor: "#F1F7FF" }}>
                <div className="container">

                    <div className="row no-gutters">
                        <div className="content col-xl-5 d-flex align-items-stretch" data-aos="fade-right" data-aos-duration="3000">
                            <div className="content" >
                                <h3 style={{ color: "#349" }}><font style={{ color: "red" }}>Easy</font> Software for Better Performance</h3>
                                <p style={{ color: "#349" }}>
                                    Easy Software Pvt ltd aims to provide creative and easy software for companies and other association. We believe in becoming a leading organization by providing the best software.
                                </p>
                            </div>
                        </div>
                        <div className="col-xl-7 d-flex align-items-stretch" data-aos="fade-up" data-aos-duration="3000">
                            <div className="icon-boxes d-flex flex-column justify-content-center">
                                <div className="row">
                                    <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-duration="3000">
                                        <i className="bi bi-box"></i>
                                        <h4 style={{ color: "#349" }}>Easy Cooperative Software</h4>
                                        <p style={{ color: "#349" }}>Financial Solution To Advance Your Cooperative.</p>
                                    </div>
                                    <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-duration="3000">
                                        <i className="bi bi-box"></i>
                                        <h4 style={{ color: "#349" }}>Easy Accounting Software</h4>
                                        <p style={{ color: "#349" }}>Calculating The Best Opportunities For You.</p>
                                    </div>
                                    <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-duration="4000">
                                        <i className="bi bi-box"></i>
                                        <h4 style={{ color: "#349" }}>Easy School & College Software</h4>
                                        <p style={{ color: "#349" }}>For Better Management Of School & College.</p>
                                    </div>
                                    <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-duration="4000">
                                        <i className="bi bi-box"></i>
                                        <h4 style={{ color: "#349" }}>Easy Khanepani Software</h4>
                                        <p style={{ color: "#349" }}>Connecting Khanepani With IT.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default About