import React, { useEffect, useState } from 'react'
import AboutTab from './AboutTab';
import AccountTab from './AccountTab';
import LeaveTab from './LeaveTab';
import ReportTab from './ReportTab';
import SuggestionTab from './SuggestionTab';
import NotificationTab from './NotificationTab';
import PaymentTab from './PaymentTab';

export default function ViewStudent({ closePopup }) {
    const userinfo = JSON.parse(localStorage.getItem("userInfo"));
    const rolePermission = JSON.parse(localStorage.getItem("rolePermission"));

    const [activeTab, setActiveTab] = useState({
        tab1: true,
        tab2: false,
        tab3: false,
        tab4: false,
        tab5: false,
        tab6: false,
        tab7: false,
    });
    const handleTab1 = () => {
        setActiveTab({
            tab1: true,
            tab2: false,
            tab3: false,
            tab4: false,
            tab5: false,
            tab6: false,
            tab7: false,
        });
    };
    const handleTab2 = () => {
        setActiveTab({
            tab1: false,
            tab2: true,
            tab3: false,
            tab4: false,
            tab5: false,
            tab6: false,
            tab7: false,
        });
    };
    const handleTab3 = () => {
        setActiveTab({
            tab1: false,
            tab2: false,
            tab3: true,
            tab4: false,
            tab5: false,
            tab6: false,
            tab7: false,
        });
    };
    const handleTab4 = () => {
        setActiveTab({
            tab1: false,
            tab2: false,
            tab3: false,
            tab4: true,
            tab5: false,
            tab6: false,
            tab7: false,
        });
    };
    const handleTab5 = () => {
        setActiveTab({
            tab1: false,
            tab2: false,
            tab3: false,
            tab4: false,
            tab5: true,
            tab6: false,
            tab7: false,
        });
    };
    const handleTab6 = () => {
        setActiveTab({
            tab1: false,
            tab2: false,
            tab3: false,
            tab4: false,
            tab5: false,
            tab6: true,
            tab7: false,
        });
    };
    const handleTab7 = () => {
        setActiveTab({
            tab1: false,
            tab2: false,
            tab3: false,
            tab4: false,
            tab5: false,
            tab6: false,
            tab7: true,
        });
    };
    const [isAccount, setisAccount] = useState()
    const [isPayment, setisPayment] = useState()
    const [isReportCard, setIsReportCard] = useState()
    // useEffect(() => {
    //     if (userinfo.UserType === "A") {

    //         setisAccount(rolePermission.some(
    //             (role) =>
    //                 role.UserID === userinfo.UserID &&
    //                 role.Permission === "ACCOUNT"
    //         ))

    //         setisPayment(rolePermission.some(
    //             (role) =>
    //                 role.UserID === userinfo.UserID &&
    //                 role.Permission === "ONLINEPAYMENTREPORT"
    //         ))

    //         setIsReportCard(rolePermission.some(
    //             (role) =>
    //                 role.UserID === userinfo.UserID &&
    //                 role.Permission === "REPORTCARD"
    //         ))
    //     }
    // }, [])



    return (
        <>
            <div className="container-fluid">

                <div className="tabview ">
                    <div className="order--div">
                        <ul>
                            <li
                                className={activeTab.tab1 === true ? "active" : ""}
                                onClick={handleTab1}
                            >
                                <i className="fa fa-file-text-o mr-1 icon"></i>About{" "}
                            </li>
                            {userinfo.UserType === "A" &&
                                !isAccount &&
                                <li
                                    className={activeTab.tab2 === true ? "active" : ""}
                                    onClick={handleTab2}
                                >
                                    <i className="fa fa-file-text-o mr-1 icon"></i>Account{" "}
                                </li>}
                            <li
                                className={activeTab.tab3 === true ? "active" : ""}
                                onClick={handleTab3}
                            >
                                <i className="fa fa-file-text-o mr-1 icon"></i>Leave{" "}
                            </li>
                            {userinfo.UserType === "A" && (
                                <>
                                    {!isReportCard && (
                                        <li
                                            className={activeTab.tab4 === true ? "active" : ""}
                                            onClick={handleTab4}
                                        >
                                            <i className="fa fa-file-text-o mr-1 icon"></i>Report Card{" "}
                                        </li>
                                    )}
                                    <li
                                        className={activeTab.tab5 === true ? "active" : ""}
                                        onClick={handleTab5}
                                    >
                                        <i className="fa fa-file-text-o mr-1 icon"></i>Suggestion{" "}
                                    </li>
                                    <li
                                        className={activeTab.tab6 === true ? "active" : ""}
                                        onClick={handleTab6}
                                    >
                                        <i className="fa fa-file-text-o mr-1 icon"></i>Notification{" "}
                                    </li>
                                    {!isPayment && (

                                        <li
                                            className={activeTab.tab7 === true ? "active" : ""}
                                            onClick={handleTab7}
                                        >
                                            <i className="fa fa-file-text-o mr-1 icon"></i>Payment{" "}
                                        </li>
                                    )}
                                </>
                            )}

                        </ul>
                    </div>

                </div>

                <div>
                    {activeTab.tab1 && <AboutTab />}
                    {activeTab.tab2 && <AccountTab />}
                    {activeTab.tab3 && <LeaveTab />}
                    {activeTab.tab4 && <ReportTab />}
                    {activeTab.tab5 && <SuggestionTab />}
                    {activeTab.tab6 && <NotificationTab />}
                    {activeTab.tab7 && <PaymentTab />}

                </div>
            </div>

            <div className="FormFooter">
                <div className='checkedClose'>

                </div>
                <div>

                    <button className='btn btn-sm btn-cmpy ml-2' onClick={closePopup}>Cancel</button>
                </div>
            </div>


        </>

    )
}
