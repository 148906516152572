import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";

import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import MenuContext from "../contextfolder/MenuContext/MenuContext";
import MenuPopup from "./MenuPopup";
import MenuPopupEdit from "./MenuPopupEdit";
import MenuActivate from "./MenuActivate";
import MenuDeactivate from "./MenuDeactivate";
import AcademicHeader from "../AcademicHeader";

export default function Menu() {
  const { todayDate, customStyles, AcademicYear } = useContext(SidebarContext);
  const {
    setMenuPopup,
    setMenuFormValue,
    menuValue,
    deactivateDepart,
    originalList,
    editPop,
    setMenuList,
    menuList,
    loading,
    menuCategoryList,
    chooseMenu,
    setChooseMenu,
    chooseType,
    setChooseType,
    chooseAvailable,
    setChooseAvailable,
    togglePopup,
    setMenuFormError,
    menuFormError,
    isSubmit,
    setIsSubmit,
    setCloseChecked,
    setImage,
    setIsUploaded,
    menuPopup,
    menuEditPopup,
    toggleEditPopup,
    setEditIsSubmit,

    activatePopup,
    handleActivateTrue,
    handleActivateFalse,
    handleActivate,
    inActivatePopup,
    handleInActivateTrue,
    handleInActivateFalse,
    handleInActivate,
  } = useContext(MenuContext);

  const searchInput = useRef("");

  const changeStatus = (ID, IsActive) => {
    deactivateDepart(ID, IsActive);
  };

  const checkStatus = (IsActive) => {
    if (IsActive === "A") {
      return "Deactivate";
    } else if (IsActive === "I") {
      return "Activate";
    }
  };

  const columns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "Name",

      selector: (row) => row.Name,
    },
    {
      name: "Category",

      selector: (row) => row.Category,
    },
    {
      name: "Type",
      width: "110px",
      selector: (row) => (row.MenuType === "B" ? "Beverages" : "Food"),
    },
    {
      name: "Price",
      width: "100px",
      selector: (row) => row.Price,
    },
    {
      name: "Unit",
      width: "100px",
      // grow: 0,
      selector: (row) => row.PriceUnit,
    },
    {
      name: "Available",
      // center: true,
      width: "120px",
      // grow: 0,
      selector: (row) => (row.IsAvail === "Y" ? "Available" : "Unavailable"),
    },

    {
      name: "Status",
      // grow: 0,
      center: true,
      width: "120px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              {row.IsActive === "I" ? (
                <>
                  <button
                    type="button"
                    class="badge badge-success"
                    onClick={() => handleActivate(row)}
                  >
                    Activate
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    class="badge badge-danger"
                    onClick={() => handleInActivate(row)}
                  >
                    Deactivate
                  </button>
                </>
              )}
            </div>
          </>
        );
      },
    },

    {
      name: "Action",
      center: true,
      width: "90px",
      selector: (row) => {
        return (
          <>
            <>
              <div className="ln-verition d-flex">
                <Tippy content="Edit">
                  <i
                    className="fa fa-pencil solvespan--action"
                    onClick={() => editPop(row)}
                  ></i>
                </Tippy>
              </div>
            </>
          </>
        );
      },
    },
  ];

  const closePopup = (e) => {
    e.preventDefault();
    togglePopup();
    setMenuFormValue(menuValue);
    setMenuFormError({});
    setIsSubmit(false);
    setCloseChecked(false);
    setImage("");
    setIsUploaded(false);
  };
  const closeEditPopup = (e) => {
    e.preventDefault();
    toggleEditPopup();
    setMenuFormValue(menuValue);
    setMenuFormError({});
    setEditIsSubmit(false);
    setCloseChecked(false);
    setImage("");
    setIsUploaded(false);
  };

  const addnew = (e) => {
    e.preventDefault();
    togglePopup();
    setIsSubmit(false);
    setMenuFormValue(menuValue);
    setCloseChecked(false);
  };

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["Name"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setMenuList(srchResult);
      } else {
        setMenuList({});
      }
    } else {
      setMenuList(originalList);
    }
  };

  const handleMenu = (e) => {
    const target = e.target;
    const value = target.value;
    setChooseMenu(value);
  };

  const handleType = (e) => {
    const target = e.target;
    const value = target.value;
    setChooseType(value);
  };

  const handleAvailable = (e) => {
    const target = e.target;
    const value = target.value;
    setChooseAvailable(value);
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
        <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">Menu</div>
            <div className="page-date">
              <div className="sec-content">
                <AcademicHeader
                  todayDate={todayDate}
                  AcademicYear={AcademicYear}
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <>
          <div className="sec-dataTable content-section main-content">
            <div className="upper-dataTbl">
              <div className="btn-addlnote">
                <button
                  type="button"
                  class="btn btn-sm"
                  style={{
                    background: "var(--MainColor)",
                    color: "white",
                  }}
                  onClick={addnew}
                >
                  Add New
                </button>
              </div>
            </div>
            <hr className="new-hr-new" />
            {loading ? (
              <Spinner />
            ) : (
              <DataTable
                columns={columns}
                data={menuList}
                customStyles={customStyles}
                pagination
                fixedHeader
                fixedHeaderScrollHeight="280px"
                highlightOnHover
                pointerOnHover
                responsive
                dense
                striped
                subHeader
                subHeaderAlign="left"
                subHeaderComponent={
                  <>
                    <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                      <label className="list-label">Select Menu</label>
                      <select
                        style={{ fontSize: "11px" }}
                        name="status"
                        className="form-control form-control-sm"
                        aria-label="Default select example "
                        value={chooseMenu}
                        onChange={handleMenu}
                      >
                        <option
                          selected
                          disabled
                          value="0"
                          style={{ fontSize: "11px" }}
                        >
                          Select Option
                        </option>
                        <option value="-1">All</option>
                        {menuCategoryList.map((item) => (
                          <option key={item.CategoryID} value={item.CategoryID}>
                            {item.Name}
                          </option>
                        ))}
                      </select>
                      <i class="fas fa-angle-down  notificationpositon"></i>
                    </div>

                    <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                      <label className="list-label">Type</label>
                      <select
                        style={{ fontSize: "11px" }}
                        name="snotifiaction"
                        value={chooseType}
                        onChange={handleType}
                        className="form-control form-control-sm"
                        aria-label="Default control example "
                      >
                        <option
                          value=""
                          disabled
                          selected
                          style={{ fontSize: "11px" }}
                        >
                          Select Type
                        </option>
                        <option value="-1">All</option>

                        <option value="F">Food</option>
                        <option value="B">Beverages</option>
                      </select>
                      <i class="fas fa-angle-down  notificationpositon"></i>
                    </div>

                    <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                      <label className="list-label">Available</label>
                      <select
                        style={{ fontSize: "11px" }}
                        name="snotifiaction"
                        value={chooseAvailable}
                        onChange={handleAvailable}
                        className="form-control form-control-sm"
                        aria-label="Default select example "
                      >
                        <option
                          value=""
                          disabled
                          selected
                          style={{ fontSize: "11px" }}
                        >
                          Select Section
                        </option>
                        <option value="-1">All</option>

                        <option value="Y">Yes</option>
                        <option value="N">No</option>
                      </select>
                      <i class="fas fa-angle-down  notificationpositon"></i>
                    </div>

                    <div
                      className="col-lg-2 offset-lg-4 col-md-2 offset-md-4 col-sm-2 align-self-end Search"
                      style={{
                        marginTop: "25px",
                        padding: "inherit",
                        marginBottom: "10px",
                      }}
                    >
                      <div className="d-flex">
                        <input
                          placeholder="Search"
                          ref={searchInput}
                          type="text"
                          className="form-control form-control-sm searchField"
                          onChange={searchHandler}
                        />
                      </div>
                    </div>
                  </>
                }
              />
            )}
          </div>
        </>
      </div>

      {menuPopup && (
        <div className="modals">
          <div className="overlay"></div>

          <div className="notification-model-content">
            <div className="popUpHeader ">
              <div className="popUpHeaderText ">Menu</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closePopup}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="inside-modal">
              <MenuPopup closePopup={closePopup} />
            </div>
          </div>
        </div>
      )}
      {menuEditPopup && (
        <div className="modals">
          <div className="overlay"></div>

          <div className="notification-model-content">
            <div className="popUpHeader ">
              <div className="popUpHeaderText ">Menu Edit</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closeEditPopup}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="inside-modal">
              <MenuPopupEdit closePopup={closeEditPopup} />
            </div>
          </div>
        </div>
      )}

      {activatePopup.show && (
        <MenuActivate
          handleActivateTrue={handleActivateTrue}
          handleActivateFalse={handleActivateFalse}
        />
      )}
      {inActivatePopup.show && (
        <MenuDeactivate
          handleInActivateTrue={handleInActivateTrue}
          handleInActivateFalse={handleInActivateFalse}
        />
      )}
    </>
  );
}
