import React, { useContext, useEffect, useState } from "react";
import { Fetchdata } from "../../hooks/getData";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { toast } from "react-toastify";
import SliderContext from "./SliderContext";

function SliderState(props) {
  const { appURL } = useContext(SidebarContext);

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const sliderValue = {
    title: "",
    url: "",
    order: "",
  };
  const [sliderFormValue, setSliderFormValue] = useState(sliderValue);
  const [sliderFormError, setSliderFormError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const [popup, setPopup] = useState(false);
  const togglePopup = () => {
    setPopup(!popup);
  };

  const [isUploaded, setIsUploaded] = useState(false);
  const [typeFile, setTypeFile] = useState("");
  const [image, setImage] = useState("");

  //API to create event
  const fetchdata = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "i",
      Title: sliderFormValue.title,
      RedUrl: sliderFormValue.url,
      Order: sliderFormValue.order,
      Image: image !== null ? image.split(",")[1] : "",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/slider`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getsliderList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };

  // API for slider List
  const [sliderList, seSliderList] = useState([]);

  useEffect(() => {
    getsliderList();
  }, []);

  const getsliderList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/slider`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.SliderLst ? result.SliderLst : "";
        setLoading(false);
        seSliderList(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        seSliderList([]);
        setOriginalList([]);
      }
    });
  };

  //Everything to pop up and delete the event list
  const [deletePopup, setDeletePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const [delData, setDelData] = useState();

  const handleDelete = async (data) => {
    setDelData(data);

    setDeletePopup({
      show: true,
      data,
    });
  };

  // This will perform the deletion and hide the Confirmation Box

  const handleDeleteTrue = async () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "R",
      SliderID: delData,
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/slider`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          getsliderList();
          toast(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
        } else {
          toast(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
    });
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleDeleteFalse = () => {
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // Ends Here

  // This is all for Academic Year

  const academicValue = {
    year: "",
    from: "",
    to: "",
  };
  const [academicFormValue, setAcademicFormValue] = useState(academicValue);
  const [academicFormError, setAcademicFormError] = useState({});
  const [academicIsSubmit, setAcademicIsSubmit] = useState(false);
  const [editIsSubmit, setEditIsSubmit] = useState(false);
  const [academicSearch, setAcademicSearch] = useState(false);
  const [closeChecked, setCloseChecked] = useState(false);

  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };

  const [editModal, setEditModal] = useState(false);
  const toggleEditModal = () => {
    setEditModal(!editModal);
  };

  const [active, setActive] = useState(false);

  //API to create academic
  const fetchacademicdata = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "i",
      AcademicYear: academicFormValue.year,
      YearFrom: academicFormValue.from,
      YearTo: academicFormValue.to,
      IsActive: active === true ? "Y" : "N",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/academic-year`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getAcademicList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };

  // API for academic List
  const [academicList, setAcademicList] = useState([]);

  useEffect(() => {
    getAcademicList();
  }, []);

  const getAcademicList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "1",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/academic-year`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.AcademicYearLst ? result.AcademicYearLst : "";
        setLoading(false);
        setAcademicList(postResult);
        setAcademicSearch(postResult);
      } else {
        setLoading(false);
        setAcademicList([]);
        setAcademicSearch([]);
      }
    });
  };

  //For Edit
  const [academicId, setAcademicId] = useState("");

  const handleEdit = (data) => {
    setEditModal(!editModal);
    setAcademicId(data.AcademicYear);
    setAcademicFormValue({
      year: data.AcademicYear,
      from: data.YearFrom,
      to: data.YearTo,
    });
    setActive(data.IsActive === "Y" ? true : false);
    setEditIsSubmit(false);
  };

  const editacademicdata = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "U",
      AcademicYear: academicId,
      YearFrom: academicFormValue.from,
      YearTo: academicFormValue.to,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/academic-year`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getAcademicList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };

  //API to hit Agree/Disagree status

  const deactivateAgree = (ID, IsOK) => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "US",
      AcademicYear: ID,
      IsActive: IsOK,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/academic-year`,
      Type: "POST",
    };
    if (IsOK === "Y") {
      dataForm.IsActive = "N";
    } else {
      dataForm.IsActive = "Y";
    }
    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getAcademicList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };
  //Academic year state ends

  return (
    <SliderContext.Provider
      value={{
        loading,
        originalList,
        sliderList,
        seSliderList,
        popup,
        setPopup,
        togglePopup,
        sliderFormValue,
        setSliderFormValue,
        sliderFormError,
        setSliderFormError,
        isSubmit,
        setIsSubmit,
        sliderValue,
        setImage,
        setIsUploaded,
        isUploaded,
        typeFile,
        setTypeFile,
        image,
        fetchdata,
        deletePopup,
        handleDeleteTrue,
        handleDeleteFalse,
        handleDelete,

        academicFormValue,
        setAcademicFormValue,
        academicFormError,
        setAcademicFormError,
        academicIsSubmit,
        setAcademicIsSubmit,
        modal,
        setModal,
        toggleModal,
        academicValue,
        academicSearch,
        setAcademicSearch,
        academicList,
        setAcademicList,
        active,
        setActive,
        editModal,
        setEditModal,
        toggleEditModal,
        editIsSubmit,
        setEditIsSubmit,
        handleEdit,
        fetchacademicdata,
        editacademicdata,
        deactivateAgree,
        closeChecked,
        setCloseChecked,
      }}
    >
      {props.children}
    </SliderContext.Provider>
  );
}

export default SliderState;
