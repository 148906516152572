import React, { useContext, useRef } from "react";
import DataTable from "react-data-table-component";

import '../../css/Commission.css';

import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";

import StudentContext from "../contextfolder/StudentContext/StudentContext";
import { GetNepaliDate } from "../hooks/dateConvertor";



export default function SuggestionTab() {
    const { customStyles } = useContext(SidebarContext);
    const { stdSuggestionList, setStdSuggestionList, suggestionLoad, suggestionSearch } = useContext(StudentContext);


    const searchInput = useRef("");

    

    const columns = [
        {
            name: "S.N.",
            width: "63px",
            center: true,
            cell: (row, index) => index + 1,
        },
        {
            name: "Suggestion topic",
            selector: (row) => row.Topic,
        },
        {
            name: "Title",
            selector: (row) => row.Title,
        },
        {
            name: "Description",
            selector: (row) => row.Description,
        },
        {
            name: "Status",
            width: "110px",
            selector: (row) => row.Status,
        },
        {
            name: "Status By",

            selector: (row) => row.StatusBy === null ? "Pending" : row.StatusBy,
        },
        {
            name: "Created Date",
            width: "170px",
            selector: (row) => GetNepaliDate(row.CreatedDate),
        },
        

    ];






    const searchHandler = (e) => {
        e.preventDefault();

        const srchQuery = searchInput.current.value.toLowerCase();
        if (srchQuery) {

            let srchResult = suggestionSearch.filter((list) => {
                return list["Topic"].toLowerCase().includes(srchQuery);
            });

            if (srchResult) {

                setStdSuggestionList(srchResult);
            } else {
                setStdSuggestionList({});
            }
        } else {
            setStdSuggestionList(suggestionSearch);
        }
    };





    return (


        <div className="sec-dataTable content-section main-content">

            {suggestionLoad ? (
                <Spinner />
            ) : (
                <DataTable
                    columns={columns}
                    data={stdSuggestionList}
                    customStyles={customStyles}
                    pagination
                    fixedHeader
                    fixedHeaderScrollHeight="280px"
                    highlightOnHover
                    pointerOnHover
                    responsive
                    dense
                    striped
                    subHeader
                    subHeaderAlign="left"
                    subHeaderComponent={
                        <>


                            <div className="col-lg-3 offset-lg-9 offset-md-9 offset-sm-9 col-md-3 col-sm-3 align-self-end Search" style={{ paddingRight: "1px", marginBottom: "10px" }}>
                                <div className="d-flex">
                                    <input
                                        placeholder="Search"
                                        ref={searchInput}
                                        type="text"
                                        className="form-control form-control-sm searchField"
                                        onChange={searchHandler}
                                    />
                                </div>
                            </div>
                        </>
                    }
                />
            )}
        </div>

    );
}
