import React, { useContext, useEffect, useState } from "react";
import { Fetchdata } from "../../hooks/getData";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { toast } from "react-toastify";
import NotificationContext from "./NotificationContext";

function NotificationState(props) {
  const { appURL } = useContext(SidebarContext);

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const [closeChecked, setCloseChecked] = useState(false);
  const [closeTeacherChecked, setCloseTeacherChecked] = useState(false);
  const [closeAdminChecked, setCloseAdminChecked] = useState(false);

  const notificationValue = {
    selectdrop: "-1",
    class: "",
    student: "",
    teacher: "",
    admin: "",
    title: "",
    description: "",
  };
  const [section, setSection] = useState([]);
  const [teacher, setTeacher] = useState([]);
  const [student, setStudent] = useState([]);
  const [admin, setAdmin] = useState([]);
  const [notificationFormValue, setNotificationFormValue] =
    useState(notificationValue);
  const [notificationFormError, setNotificationFormError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [editIsSubmit, setEditIsSubmit] = useState(false);

  const [popup, setPopup] = useState(false);
  const togglePopup = () => {
    setPopup(!popup);
  };

  const [editPop, setEditPop] = useState(false);
  const toggleEditPopup = () => {
    setEditPop(!editPop);
  };

  const [isUploaded, setIsUploaded] = useState(false);
  const [typeFile, setTypeFile] = useState("");
  const [image, setImage] = useState("");

  const [reportOption, setReportOption] = useState("-1");

  const [classList, setClassList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [teacherList, setTeacherList] = useState([]);
  const [studentList, setStudentList] = useState([]);

  const [selectClass, setSelectClass] = useState("");
  const [selectSection, setSelectSection] = useState("");
  const [selectTeacher, setSelectTeacher] = useState("");
  const [selectStudent, setSelectStudent] = useState("");

  useEffect(() => {
    getClassList();
  }, []);

  const getClassList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      DFlag: "C",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setClassList(postResult);
      } else {
        setClassList([]);
      }
    });
  };

  useEffect(() => {
    getSectionList();
  }, [selectClass]);

  const getSectionList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      ClassID: selectClass,
      DFlag: "S",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setSectionList(postResult);
      } else {
        setSectionList([]);
      }
    });
  };

  //API to hit Teacher list

  useEffect(() => {
    teachList();
  }, []);

  const teachList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/teacher`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.teachingres ? result.teachingres : "";
        setTeacherList(postResult);
      } else {
        setTeacherList([]);
      }
    });
  };

  //API to hit Student list

  useEffect(() => {
    stdList();
  }, [selectClass, selectSection]);

  const stdList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      Flag: "S",
      ClassID: selectClass,
      SectionID: [
        {
          SectionID: selectSection,
        },
      ],
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/student`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.StudentLst ? result.StudentLst : "";
        setLoading(false);
        setStudentList(postResult);
      } else {
        setStudentList([]);
        setLoading(false);
      }
    });
  };

  //API to create Notification

  const fileType = typeFile.split(".");
  // const fileName = fileType[0]
  const dot = ".";
  const fileExt = dot.concat(fileType[1]);

  const fetchdata = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "i",
      ClassID:
        notificationFormValue.selectdrop === "C" ||
        notificationFormValue.selectdrop === "S" ||
        notificationFormValue.selectdrop === "I"
          ? notificationFormValue.class
          : "0",
      SectionIDs:
        notificationFormValue.selectdrop === "S" ||
        notificationFormValue.selectdrop === "I"
          ? section
          : null,
      MemIDs:
        notificationFormValue.selectdrop === "I"
          ? student
          : notificationFormValue.selectdrop === "T"
          ? notificationFormValue.teacher.toString()
          : notificationFormValue.selectdrop === "A"
          ? admin
          : null,
      Title: notificationFormValue.title,
      Description: notificationFormValue.description,
      Attachment: image !== "" ? image.split(",")[1] : "",
      AttachmentType: image !== "" ? fileExt : "",
      UserType:
        notificationFormValue.selectdrop === "I" ||
        notificationFormValue.selectdrop === "C" ||
        notificationFormValue.selectdrop === "S"
          ? "S"
          : notificationFormValue.selectdrop === "T"
          ? "T"
          : notificationFormValue.selectdrop === "A"
          ? "A"
          : "-1",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/notification`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getNotificationList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };

  // API for notifiaction List
  const [notificationList, setNotificationList] = useState([]);

  useEffect(() => {
    getNotificationList();
  }, [reportOption, selectTeacher, selectClass, selectSection, selectStudent]);

  const getNotificationList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      Flag: "S",
      ClassID:
        reportOption === "C" || reportOption === "S" ? selectClass : "-1",
      SectionID: reportOption === "S" ? selectSection : "-1",
      MemID:
        reportOption === "I"
          ? selectStudent
          : reportOption === "T"
          ? selectTeacher
          : reportOption === "A"
          ? selectTeacher
          : "-1",
      UserType:
        reportOption === "I" || reportOption === "C" || reportOption === "S"
          ? "S"
          : reportOption === "T"
          ? "T"
          : reportOption === "A"
          ? "A"
          : "-1",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/notification`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.NotificationLst ? result.NotificationLst : "";
        setLoading(false);
        setNotificationList(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        setNotificationList([]);
        setOriginalList([]);
      }
    });
  };

  ///// FOR EDIT

  const [notiId, setNotiId] = useState("");

  const handleEdit = (data) => {
    setEditPop(!editPop);

    setNotiId(data.NotificationID);
    setNotificationFormValue({
      title: data.Title,
      description: data.Description,
    });
    setEditIsSubmit(false);
  };

  const editdata = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "U",
      NotificationID: notiId,
      Title: notificationFormValue.title,
      Description: notificationFormValue.description,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/notification`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getNotificationList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };

  // //Everything to pop up and delete the notification list
  const [deletePopup, setDeletePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const [delId, setDelId] = useState("");

  const handleDelete = async (datas) => {
    setDeletePopup({
      show: true,
    });
    setDelId(datas);
  };

  // This will perform the deletion and hide the Confirmation Box

  const handleDeleteTrue = async () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "R",
      NotificationID: delId,
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/notification`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          getNotificationList();
          toast(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
        } else {
          toast(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
    });
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleDeleteFalse = () => {
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // Ends Here

  return (
    <NotificationContext.Provider
      value={{
        loading,
        originalList,
        deletePopup,
        handleDeleteTrue,
        handleDeleteFalse,
        handleDelete,
        popup,
        setPopup,
        togglePopup,
        notificationList,
        setNotificationList,
        notificationFormValue,
        setNotificationFormValue,
        notificationFormError,
        setNotificationFormError,
        isSubmit,
        notificationValue,
        setIsSubmit,
        classList,
        sectionList,
        teacherList,
        selectClass,
        setSelectClass,
        selectSection,
        setSelectSection,
        selectTeacher,
        setSelectTeacher,
        closeChecked,
        setCloseChecked,
        reportOption,
        setReportOption,
        section,
        setSection,
        teacher,
        setTeacher,
        admin,
        setAdmin,
        student,
        setStudent,
        studentList,
        selectStudent,
        setSelectStudent,
        isUploaded,
        setIsUploaded,
        typeFile,
        setTypeFile,
        image,
        setImage,
        fetchdata,
        editPop,
        setEditPop,
        toggleEditPopup,
        editIsSubmit,
        setEditIsSubmit,
        handleEdit,
        editdata,
        closeTeacherChecked,
        setCloseTeacherChecked,
        closeAdminChecked,
        setCloseAdminChecked,
      }}
    >
      {props.children}
    </NotificationContext.Provider>
  );
}

export default NotificationState;
