import React, { useContext, useEffect, useState } from "react";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { toast } from "react-toastify";
import HelpDeskContext from "./HelpDeskContext";
import { GetEnglishDate, GetNepaliDate } from "../../hooks/dateConvertor";
import { FetchJobdata } from "../../hooks/getJobData";

function HelpDeskState(props) {
  const { jobURL } = useContext(SidebarContext);

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const [closeChecked, setCloseChecked] = useState(false);

  const helpDeskValue = {
    category: "",
    subject: "",
    message: "",
    priority: "M",
    dueDate: "",
  };

  const [formValue, setFormValue] = useState(helpDeskValue);
  const [formError, setFormError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const [isUploaded, setIsUploaded] = useState(false);
  const [typeFile, setTypeFile] = useState("");
  const [image, setImage] = useState("");

  const [popup, setPopup] = useState(false);
  const togglePopup = () => {
    setPopup(!popup);
  };

  //API to create admission
  const fetchdata = () => {
    const dataForm = {
      AuthCode: "r1d3r",
      ComID: "ES45",
      UserID: userinfo.UserID.toString(),
      Flag: "i",
      CatID: formValue.category,
      Email: userinfo.UserName,
      DueDate: formValue.dueDate,
      Tags: "Easy School",
      Subject: formValue.subject,
      Description: formValue.message,
      Priority: formValue.priority,
      Attachment: image !== "" ? image.split(",")[1] : "",
      AssignedTo: "0",

      FetchURL: `${jobURL}api/help-desk/client`,
      Type: "POST",
    };

    FetchJobdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getHelpDeskList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };
  // API for category List
  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    getCategoryList();
  }, []);

  const getCategoryList = () => {
    const params = {
      AuthCode: "r1d3r",
      ComID: "ES45",
      Flag: "TC",
      Type: "POST",
      FetchURL: `${jobURL}api/help-desk/client`,
    };

    FetchJobdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setCategoryList(postResult);
      } else {
        setCategoryList([]);
      }
    });
  };
  // API for admission List
  const [helpDeskList, setHelpDeskList] = useState([]);

  useEffect(() => {
    getHelpDeskList();
  }, []);

  const getHelpDeskList = () => {
    const params = {
      AuthCode: "r1d3r",
      ComID: "ES45",
      Flag: "S",
      Email: userinfo.UserName,
      Type: "POST",
      FetchURL: `${jobURL}api/help-desk/client`,
    };

    FetchJobdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setLoading(false);
        setHelpDeskList(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        setHelpDeskList([]);
        setOriginalList([]);
      }
    });
  };

  ///// FOR View
  const [viewPop, setViewPop] = useState(false);
  const toggleViewPopup = () => {
    setViewPop(!viewPop);
  };
  const [viewId, setViewId] = useState("");
  const handleView = (data) => {
    toggleViewPopup();
    setViewId(data.TicketNo);
    viewHelpDeskList();
  };

  const [viewHelpDesk, setViewHelpDesk] = useState([]);

  useEffect(() => {
    viewHelpDeskList();
  }, [viewId]);

  const viewHelpDeskList = () => {
    const params = {
      AuthCode: "r1d3r",
      ComID: "ES45",
      Flag: "SI",
      TicketNo: viewId.replace("#", ""),
      Email: userinfo.UserName,
      Type: "POST",
      FetchURL: `${jobURL}api/help-desk/client`,
    };

    FetchJobdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values[0] : "";
        setViewHelpDesk(postResult);

        // setImage(postResult.Image);
      } else {
        setViewHelpDesk([]);
      }
    });
  };

  // //Everything to pop up and delete the notification list
  const [deletePopup, setDeletePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const [delId, setDelId] = useState("");

  const handleDelete = async (datas) => {
    setDeletePopup({
      show: true,
    });
    setDelId(datas.TicketNo);
  };

  // This will perform the deletion and hide the Confirmation Box

  const handleDeleteTrue = async () => {
    const dataForm = {
      AuthCode: "r1d3r",
      ComID: "ES45",
      TicketNo: viewId.replace("#", ""),
      Email: userinfo.UserName,
      Flag: "R",
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", Signature: "p0m76" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${jobURL}api/help-desk/client`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          getHelpDeskList();
          toast(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
        } else {
          toast(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
    });
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleDeleteFalse = () => {
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // Ends Here

  return (
    <HelpDeskContext.Provider
      value={{
        loading,
        originalList,
        deletePopup,
        handleDeleteTrue,
        handleDeleteFalse,
        handleDelete,
        popup,
        setPopup,
        togglePopup,
        helpDeskList,
        setHelpDeskList,
        formValue,
        setFormValue,
        formError,
        setFormError,
        isSubmit,
        helpDeskValue,
        setIsSubmit,
        closeChecked,
        setCloseChecked,
        fetchdata,
        toggleViewPopup,
        handleView,
        viewHelpDesk,
        viewPop,
        setViewPop,
        isUploaded,
        setIsUploaded,
        typeFile,
        setTypeFile,
        image,
        setImage,
        categoryList,
      }}
    >
      {props.children}
    </HelpDeskContext.Provider>
  );
}

export default HelpDeskState;
