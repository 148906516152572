import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";
import "tippy.js/dist/tippy.css";
import { Fetchdata } from "../hooks/getData";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import { convertToNep } from "../hooks/dateConvertor";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { formatNumber } from "../hooks/drCr";

export default function AccountPdf() {
  const { todayDate, customStyles, currentYear, appURL, userinfo } =
    useContext(SidebarContext);

  const [selectYear, setSelectYear] = useState("");

  const handleYear = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectYear(value);
  };

  let date = [];
  for (let i = 2075; i <= currentYear; i++) {
    date.push(i);
  }

  // API for account report list
  const [accountPdfList, setAccountPdfList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);

  useEffect(() => {
    getreportList();
  }, [selectYear]);

  const getreportList = () => {
    const params = {
      SchID: userinfo.SchID,
      Flag: "S",
      AcademicYear: selectYear,
      MemID: userinfo.UserID.toString(),
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/attach-account`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.ReportCardLst ? result.ReportCardLst : "";
        setLoading(false);
        setAccountPdfList(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        setAccountPdfList([]);
        setOriginalList([]);
      }
    });
  };

  const searchInput = useRef("");

  const columns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "Month",
      selector: (row) => row.MonthName,
    },

    {
      name: "Action",
      center: true,
      width: "70px",
      // selector: (row) => row.CV + "/" + row.Cover,
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <Tippy content="View">
                <a
                  className="viewspan--action px-2"
                  style={{ color: "#fff", width: "max-content" }}
                  href={row.AccountUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="bi bi-eye"></i>
                </a>
              </Tippy>
            </div>
          </>
        );
      },
    },
  ];

  //   const searchHandler = (e) => {
  //     e.preventDefault();

  //     const srchQuery = searchInput.current.value.toLowerCase();
  //     if (srchQuery) {
  //       let srchResult = pdfSearch.filter((list) => {
  //         return list["FullName"].toLowerCase().includes(srchQuery);
  //       });

  //       if (srchResult) {
  //         setAccountPdfList(srchResult);
  //       } else {
  //         setAccountPdfList({});
  //       }
  //     } else {
  //       setAccountPdfList(pdfSearch);
  //     }
  //   };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />

      <div className="sec-dataTable">
        <div className="upper-dataTbl"></div>
        <hr className="new-hr-new" />

        <div className="row" style={{ margin: "0" }}>
          <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
            <label className="list-label">Select Year</label>
            <select
              style={{ fontSize: "11px", marginBottom: "3px" }}
              name="snotifiaction"
              value={selectYear}
              onChange={handleYear}
              className="form-control form-control-sm"
              aria-label="Default select example "
            >
              <option value="" disabled selected style={{ fontSize: "11px" }}>
                Select Year
              </option>
              {date
                .map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))
                .reverse()}
            </select>
            <i class="fas fa-angle-down  notificationpositon"></i>
          </div>
        </div>

        {loading ? (
          <Spinner />
        ) : (
          <DataTable
            columns={columns}
            data={accountPdfList}
            customStyles={customStyles}
            pagination
            fixedHeader
            fixedHeaderScrollHeight="280px"
            highlightOnHover
            pointerOnHover
            responsive
            dense
            striped
          />
        )}
      </div>
    </>
  );
}
