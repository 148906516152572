import React, { useContext, useState, useEffect } from "react";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { Fetchdata } from "../../hooks/getData";
import Select from "react-select";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import LogPhotoContext from "../../contextfolder/LogPhotoContext/LogPhotoContext";
import CloseIcon from "../../../images/CloseIcon.svg";
import Plus from "../../../images/Plus.png";

export default function LogPhotoForm({ closePopup }) {
  const context = useContext(LogPhotoContext);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const { appURL } = useContext(SidebarContext);

  const {
    togglePopup,
    logPhotoFormError,
    setlogPhotoFormError,
    isSubmit,
    setIsSubmit,
    logPhotoValue,
    logPhotoFormValue,
    setlogPhotoFormValue,
    loading,
    setCloseChecked,
    closeChecked,
    section,
    setSection,
    setStudent,
    setAdmin,
    setTeacher,
    isUploaded,
    setIsUploaded,
    setTypeFile,
    image,
    setImage,
    fetchdata,
    setCloseTeacherChecked,
    closeTeacherChecked,
    closeAdminChecked,
    setCloseAdminChecked,
  } = context;

  const handleOnChange = () => {
    setCloseChecked(!closeChecked);
  };

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setlogPhotoFormValue({ ...logPhotoFormValue, [name]: value });
  };

  function handleImageChange(e) {
    if (e.target.files && e.target.files[0]) {
      setTypeFile(e.target.files[0].name);
      let reader = new FileReader();

      reader.onload = function (e) {
        setImage(e.target.result);
        setIsUploaded(true);
      };

      reader.readAsDataURL(e.target.files[0]);
    }
  }

  const handleDate = ({ adDate }) => {
    setlogPhotoFormValue({ ...logPhotoFormValue, date: adDate });
  };
  const formSubmit = (e) => {
    e.preventDefault();
    setlogPhotoFormError(validate(logPhotoFormValue));
    setIsSubmit(true);
  };
  useEffect(() => {
    if (Object.keys(logPhotoFormError).length === 0 && isSubmit) {
      fetchdata(logPhotoFormValue);
      if (closeChecked === true) {
        togglePopup();
      }
      setlogPhotoFormValue(logPhotoValue);
      setIsSubmit(false);
      setCloseChecked(false);
    }
  }, [logPhotoFormError]);

  const validate = (values) => {
    const errors = {};
    if (!values.title) {
      errors.title = "Required";
    }
    if (userinfo.UserType !== "T") {
      if (!values.teacher) {
        errors.teacher = "Required";
      }
    }

    if (!values.date) {
      errors.date = "Required";
    }

    return errors;
  };

  //API for teacher list
  const [teacherList, setTeacherList] = useState([]);

  useEffect(() => {
    teachList();
  }, []);

  const teachList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      Flag: "ST",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/teacher`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.teachingres ? result.teachingres : "";
        setTeacherList(postResult);
      } else {
        setTeacherList([]);
      }
    });
  };

  return (
    <>
      <div className="container-fluid basicform">
        <form>
          <div className="form-row">
            <div className="form-group col-md-12">
              <label
                className="form-label"
                htmlFor="title"
                style={{ fontSize: "12px" }}
              >
                Title<sup style={{ color: "red" }}>*</sup>
              </label>
              <input
                id="title"
                style={{ fontSize: "13px" }}
                type="text"
                className="form-control form-control-sm "
                name="title"
                value={logPhotoFormValue.title}
                onChange={handleChange}
              />
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {logPhotoFormError.title}
              </p>
            </div>
          </div>

          <div className="form-row">
            {userinfo.UserType !== "T" && (
              <div className="form-group col-md-6">
                <label className="form-label" htmlFor="teachers">
                  Teacher<sup style={{ color: "red" }}>*</sup>
                </label>
                <select
                  id="teachers"
                  name="teacher"
                  onChange={handleChange}
                  value={logPhotoFormValue.teacher}
                  className="form-select form-select-sm mb-1"
                  style={{ fontSize: "12px" }}
                >
                  <option
                    value=""
                    disabled
                    selected
                    style={{ fontSize: "11px" }}
                  >
                    Select Teacher
                  </option>
                  {teacherList.map((item) => (
                    <option key={item.TeacherID} value={item.TeacherID}>
                      {item.TeacherName}
                    </option>
                  ))}
                </select>
                <p className="errormsg " style={{ marginBottom: "0" }}>
                  {logPhotoFormError.teacher}
                </p>
              </div>
            )}

            <div className="form-group col-md-6">
              <label
                className="form-label"
                htmlFor="date"
                style={{ fontSize: "12px" }}
              >
                Date<sup style={{ color: "red" }}>*</sup>
              </label>
              <Calendar
                className="form-control form-control-sm mb-1"
                dateFormat="YYYY/MM/DD"
                theme="default"
                language="en"
                values={logPhotoFormValue.date}
                onChange={handleDate}
              />
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {logPhotoFormError.date}
              </p>
            </div>
          </div>

          <div className="form-group  ">
            <div
              className="form-label"
              htmlFor="text"
              style={{ fontSize: "12px", textAlign: "left" }}
            >
              Upload Image
            </div>

            <div className="BoxUpload">
              <div className="image-upload">
                {!isUploaded ? (
                  <>
                    <label htmlFor="upload-input">
                      <img
                        src={Plus}
                        draggable={"false"}
                        alt="placeholder"
                        style={{ width: 90, height: 100, paddingTop: "10px" }}
                      />
                    </label>

                    <input
                      id="upload-input"
                      type="file"
                      accept=".jpg,.jpeg,.gif,.png,.mov,.mp4,.pdf"
                      onChange={handleImageChange}
                      name="image"
                    />
                  </>
                ) : (
                  <div className="ImagePreview">
                    <img
                      className="close-icon"
                      src={CloseIcon}
                      alt="CloseIcon"
                      onClick={() => {
                        setIsUploaded(false);
                        setImage(null);
                      }}
                    />

                    <img
                      id="uploaded-image"
                      src={image}
                      draggable={false}
                      alt="uploaded-img"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="FormFooter">
        <div className="checkedClose">
          <input
            type="checkbox"
            className="form-check-input"
            id="exampleCheck2"
            name="closeChecked"
            onChange={handleOnChange}
            checked={closeChecked}
          />
          <label class="form-check-label" for="exampleCheck2">
            Exit after submit
          </label>
        </div>
        <div>
          <button
            className="btn btn-sm btn-cmpy ml-2"
            style={{ background: "green" }}
            onClick={formSubmit}
          >
            Submit
          </button>
          <button className="btn btn-sm btn-cmpy ml-2" onClick={closePopup}>
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}
