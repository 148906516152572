import React, { useEffect, useState } from "react";
import Header from "./Header";
import About from "./About";
import Team from "./Team";
import Album from "./Album";
import Blog from "./Blog";
import Event from "./Event";
import { useNavigate, useParams } from "react-router-dom";
import { Fetchdata } from "../hooks/getData";
import Spinner from "../loading/spinner";
import Footer from "./Footer";
import Notification from "./Notification";

const EasySchool = () => {
  const [schoolInfo, setSchoolInfo] = useState([]);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const [loading, setLoading] = useState(true);
  const appURL = process.env.REACT_APP_URL;
  const { slug } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const dataForm = {
      SchID: "ES25",
      Flag: "SDS",
      Remarks: slug,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admission`,
      Type: "POST",
    };

    // Make the API request to fetch user data
    Fetchdata(dataForm)
      .then(function (result) {
        if (result.StatusCode === 200) {
          const postResult = result.StudentLst || [];
          setSchoolInfo(postResult[0]);
          setLoading(false);
        } else {
          navigate("/404");
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div className="easy__school">
          <Header schoolInfo={schoolInfo} slug={slug} />
          <About schoolInfo={schoolInfo} />
          <Team schoolInfo={schoolInfo} />
          <Event schoolInfo={schoolInfo} />
          <Album schoolInfo={schoolInfo} />
          <Notification schoolInfo={schoolInfo} />
          <Blog schoolInfo={schoolInfo} />
          <Footer schoolInfo={schoolInfo} />
        </div>
      )}
    </>
  );
};

export default EasySchool;
