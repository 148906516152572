import React, { useContext, useEffect, useState } from "react";
import SidebarContext from "../sidebarcontext/SidebarContext";
import { Fetchdata } from "../hooks/getData";
import QrPaymentContext from "../contextfolder/QrPaymentContext/QrPaymentContext";

export default function QrPaymentEdit({ closeEditPopup }) {
  const { appURL } = useContext(SidebarContext);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const context = useContext(QrPaymentContext);

  const {
    toggleEditPopup,
    formError,
    setFormError,
    editIsSubmit,
    setEditIsSubmit,
    initialValue,
    formValue,
    setFormValue,
    fetchdata,
    closeChecked,
    setCloseChecked,
    editdata,
    paymodeList,
  } = context;

  const handleOnChange = () => {
    setCloseChecked(!closeChecked);
  };

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setFormValue({ ...formValue, [name]: value });
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setFormError(validate(formValue));
    setEditIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(formError).length === 0 && editIsSubmit) {
      editdata(formValue);
      if (closeChecked === true) {
        toggleEditPopup();
      }
      setFormValue(initialValue);
      setEditIsSubmit(false);
    }
  }, [formError]);

  const validate = (values) => {
    const errors = {};
    if (!values.PayMode) {
      errors.PayMode = "Required";
    }
    if (!values.QRValue) {
      errors.QRValue = "Required";
    }

    return errors;
  };

  return (
    <>
      <div className="container-fluid basicform">
        <form>
          <div className="form-row">
            <div className="form-group col-md-12">
              <label
                className="form-label"
                htmlFor="PayMode"
                style={{ fontSize: "12px" }}
              >
                Payment Mode<sup style={{ color: "red" }}>*</sup>
              </label>
              <select
                id="PayMode"
                name="PayMode"
                onChange={handleChange}
                value={formValue.PayMode}
                className="form-select form-select-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="" disabled selected style={{ fontSize: "11px" }}>
                  Select Payment Mode
                </option>
                {paymodeList.map((item) => (
                  <option value={item.ID} key={item.ID}>
                    {item.Value}
                  </option>
                ))}
              </select>
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {formError.PayMode}
              </p>
            </div>

            <div className="form-group col-md-12">
              <label
                className="form-label"
                htmlFor="QRValue"
                style={{ fontSize: "12px" }}
              >
                QR Value<sup style={{ color: "red" }}>*</sup>
              </label>
              <textarea
                id="QRValue"
                style={{ fontSize: "13px" }}
                type="text"
                className="form-control form-control-sm "
                name="QRValue"
                value={formValue.QRValue}
                onChange={handleChange}
                rows="3"
              />
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {formError.QRValue}
              </p>
            </div>
          </div>
        </form>
      </div>
      <div className="FormFooter">
        <div className="checkedClose">
          <input
            type="checkbox"
            className="form-check-input"
            id="exampleCheck2"
            name="closeChecked"
            onChange={handleOnChange}
            checked={closeChecked}
          />
          <label class="form-check-label" for="exampleCheck2">
            Exit after submit
          </label>
        </div>
        <div>
          <button
            className="btn btn-sm btn-cmpy ml-2"
            style={{ background: "green" }}
            onClick={formSubmit}
          >
            Submit
          </button>
          <button className="btn btn-sm btn-cmpy ml-2" onClick={closeEditPopup}>
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}
