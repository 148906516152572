import React, { useContext, useState, useEffect } from "react";
import CloseIcon from "../../images/CloseIcon.svg";
import Plus from "../../images/Plus.png";
import HelpDeskContext from "../contextfolder/HelpDeskContext/HelpDeskContext";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";

export default function HelpDeskForm({ closePopup }) {
  const context = useContext(HelpDeskContext);

  const {
    togglePopup,
    formError,
    setFormError,
    isSubmit,
    setIsSubmit,
    helpDeskValue,
    formValue,
    setFormValue,
    setCloseChecked,
    closeChecked,
    categoryList,
    isUploaded,
    setIsUploaded,
    setTypeFile,
    image,
    setImage,
    fetchdata,
  } = context;

  const handleOnChange = () => {
    setCloseChecked(!closeChecked);
  };
  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setFormValue({ ...formValue, [name]: value });
  };

  const handleDate = ({ adDate }) => {
    setFormValue({ ...formValue, dueDate: adDate });
  };

  function handleImageChange(e) {
    if (e.target.files && e.target.files[0]) {
      setTypeFile(e.target.files[0].name);
      let reader = new FileReader();

      reader.onload = function (e) {
        setImage(e.target.result);
        setIsUploaded(true);
      };

      reader.readAsDataURL(e.target.files[0]);
    }
  }

  const formSubmit = (e) => {
    e.preventDefault();
    setFormError(validate(formValue));
    setIsSubmit(true);
  };
  useEffect(() => {
    if (Object.keys(formError).length === 0 && isSubmit) {
      fetchdata(formValue);
      if (closeChecked === true) {
        togglePopup();
      }
      setFormValue(helpDeskValue);
      setIsSubmit(false);
      setCloseChecked(false);
      setImage("");
      setIsUploaded(false);
    }
  }, [formError]);

  const validate = (values) => {
    const errors = {};
    if (!values.category) {
      errors.category = "Required";
    }
    if (!values.subject) {
      errors.subject = "Required";
    }
    if (!values.message) {
      errors.message = "Required";
    }

    if (!values.priority) {
      errors.priority = "Required";
    }
    if (!values.dueDate) {
      errors.dueDate = "Required";
    }

    return errors;
  };

  return (
    <>
      <div className="container-fluid basicform">
        <form>
          <div className="form-group">
            <label className="form-label" htmlFor="category">
              Category<sup style={{ color: "red" }}>*</sup>
            </label>
            <select
              id="category"
              name="category"
              onChange={handleChange}
              value={formValue.category}
              className="form-select form-select-sm mb-1"
              style={{ fontSize: "12px" }}
            >
              <option value="" disabled selected style={{ fontSize: "11px" }}>
                Select Category
              </option>
              {categoryList.map((item) => (
                <option key={item.CatID} value={item.CatID}>
                  {item.CatName}
                </option>
              ))}
            </select>
            <p className="errormsg " style={{ marginBottom: "0" }}>
              {formError.category}
            </p>
          </div>

          <div className="form-group">
            <label
              className="form-label"
              htmlFor="subject"
              style={{ fontSize: "12px" }}
            >
              Subject<sup style={{ color: "red" }}>*</sup>
            </label>
            <input
              id="subject"
              style={{ fontSize: "13px" }}
              type="text"
              className="form-control form-control-sm "
              name="subject"
              value={formValue.subject}
              onChange={handleChange}
            />
            <p className="errormsg " style={{ marginBottom: "0" }}>
              {formError.subject}
            </p>
          </div>
          <div className="form-group">
            <label
              className="form-label"
              htmlFor="dueDate"
              style={{ fontSize: "12px" }}
            >
              Due Date<sup style={{ color: "red" }}>*</sup>
            </label>
            <Calendar
              className="form-control form-control-sm mb-1"
              dateFormat="YYYY/MM/DD"
              theme="default"
              language="en"
              values={formValue.examDate}
              onChange={handleDate}
            />
            <p className="errormsg " style={{ marginBottom: "0" }}>
              {formError.dueDate}
            </p>
          </div>

          <div className="form-group">
            <label
              className="form-label"
              htmlFor="message"
              style={{ fontSize: "12px" }}
            >
              Message <sup style={{ color: "red" }}>*</sup>
            </label>
            <textarea
              id="message"
              style={{ fontSize: "13px" }}
              type="text"
              className="form-control form-control-sm "
              name="message"
              value={formValue.message}
              onChange={handleChange}
              rows="3"
            />
            <p className="errormsg " style={{ marginBottom: "0" }}>
              {formError.message}
            </p>
          </div>

          <div className="form-group">
            <div>
              <label className="form-label" htmlFor="purpose">
                Priority <sup style={{ color: "red" }}>*</sup>
              </label>
            </div>
            <div className="d-flex">
              <div>
                <input
                  type="radio"
                  className="radio--button"
                  name="priority"
                  id="low"
                  onChange={handleChange}
                  value="L"
                  checked={formValue.priority === "L"}
                />
                <label
                  className="label-radio-routine px-3"
                  htmlFor="low"
                  style={{ marginRight: "10px" }}
                >
                  Low
                </label>
              </div>

              <div>
                <input
                  type="radio"
                  className="radio--button"
                  name="priority"
                  id="medium"
                  onChange={handleChange}
                  value="M"
                  checked={formValue.priority === "M"}
                />
                <label
                  className="label-radio-routine px-3"
                  htmlFor="medium"
                  style={{ marginRight: "10px" }}
                >
                  Medium
                </label>
              </div>

              <div>
                <input
                  type="radio"
                  className="radio--button"
                  name="priority"
                  id="high"
                  onChange={handleChange}
                  value="H"
                  checked={formValue.priority === "H"}
                />
                <label
                  className="label-radio-routine px-3"
                  htmlFor="high"
                  style={{ marginRight: "10px" }}
                >
                  High
                </label>
              </div>

              <div>
                <input
                  type="radio"
                  className="radio--button"
                  name="priority"
                  id="urgent"
                  onChange={handleChange}
                  value="U"
                  checked={formValue.priority === "U"}
                />
                <label
                  className="label-radio-routine px-3"
                  htmlFor="urgent"
                  style={{ marginRight: "10px" }}
                >
                  Urgent
                </label>
              </div>
            </div>
            <p className="errormsg " style={{ marginBottom: "0" }}>
              {formError.priority}
            </p>
          </div>

          <div className="form-group  ">
            <div
              className="form-label"
              htmlFor="text"
              style={{ fontSize: "12px", textAlign: "left" }}
            >
              Upload Image
            </div>

            <div className="BoxUpload" style={{ marginTop: "10px" }}>
              <div className="image-upload">
                {!isUploaded ? (
                  <>
                    <label htmlFor="upload-input">
                      <img
                        src={Plus}
                        draggable={"false"}
                        alt="placeholder"
                        style={{ width: 90, height: 100, paddingTop: "10px" }}
                      />
                    </label>

                    <input
                      id="upload-input"
                      type="file"
                      accept=".jpg,.jpeg,.gif,.png,.mov,.mp4,.pdf"
                      onChange={handleImageChange}
                      name="image"
                    />
                  </>
                ) : (
                  <div className="ImagePreview">
                    <img
                      className="close-icon"
                      src={CloseIcon}
                      alt="CloseIcon"
                      onClick={() => {
                        setIsUploaded(false);
                        setImage(null);
                      }}
                    />

                    <img
                      id="uploaded-image"
                      src={image}
                      draggable={false}
                      alt="uploaded-img"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="FormFooter">
        <div className="checkedClose">
          <input
            type="checkbox"
            className="form-check-input"
            id="exampleCheck2"
            name="closeChecked"
            onChange={handleOnChange}
            checked={closeChecked}
          />
          <label class="form-check-label" for="exampleCheck2">
            Exit after submit
          </label>
        </div>
        <div>
          <button
            className="btn btn-sm btn-cmpy ml-2"
            style={{ background: "green" }}
            onClick={formSubmit}
          >
            Submit
          </button>
          <button className="btn btn-sm btn-cmpy ml-2" onClick={closePopup}>
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}
