import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import SubjectContext from "../contextfolder/SubjectContext/SubjectContext";
import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";
import DeletePop from "../Subject/DeletePop";
import SubjectForm from "./SubjectForm";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import AcademicHeader from "../AcademicHeader";

export default function SubjectList() {
  const { todayDate, customStyles, AcademicYear, hideAction } =
    useContext(SidebarContext);
  const {
    loading,
    setTeachingSubjectList,
    handleDelete,
    popup,
    togglePopup,
    setIsSubmit,
    teachingSubjectList,
    subjectValue,
    setSubjectFormError,
    setSubjectFormValue,
    searchTeachingSubject,
    deletePopup,
    handleDeleteTrue,
    handleDeleteFalse,
    setCloseChecked,
    teacherList,
    selectTeacher,
    setSelectTeacher,
  } = useContext(SubjectContext);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const handleTeacher = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectTeacher(value);
  };

  const searchInput = useRef("");

  const columns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "Class",
      // center: true,
      // grow: 0,
      width: "200px",
      selector: (row) => row.ClassName,
    },
    {
      name: "Section",
      width: "200px",
      selector: (row) => row.SectionName,
    },
    {
      name: "Subject",
      selector: (row) => row.SubjectName,
    },
    {
      name: "Action",
      // grow: 0,
      center: true,
      width: "100px",
      omit: hideAction,
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <Tippy content="Delete">
                <i
                  className="fa fa-trash-o rejectspan--action"
                  onClick={() => handleDelete(row.TeachingID)}
                ></i>
              </Tippy>
            </div>
          </>
        );
      },
    },
  ];

  const closePopup = (e) => {
    e.preventDefault();
    togglePopup();
    setSubjectFormValue(subjectValue);
    setSubjectFormError({});
    setIsSubmit(false);
    setCloseChecked(false);
  };

  const addnew = (e) => {
    e.preventDefault();
    togglePopup();
    setIsSubmit(false);
    setCloseChecked(false);
  };

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = searchTeachingSubject.filter((list) => {
        return list["SubjectName"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setTeachingSubjectList(srchResult);
      } else {
        setTeachingSubjectList({});
      }
    } else {
      setTeachingSubjectList(searchTeachingSubject);
    }
  };

  /* This is for export csv */

  const dataToExcel = teachingSubjectList.map((d, i) => ({
    "S.N.": i + 1,
    Class: d.ClassName,
    Section: d.SectionName,
    Subject: d.SubjectName,
  }));

  const toExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(dataToExcel);
      var wscols = [
        { wch: 5 },
        { wch: 20 },
        { wch: 20 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ];
      worksheet["!cols"] = wscols;
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "Subject");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_" + "export" + EXCEL_EXTENSION
        );
      }
    });
  };

  //end

  //This is to export pdf

  const dataWithIndex = teachingSubjectList.map((d, i) => ({
    ...d,
    Index: i + 1,
  }));

  const cols = [
    { header: "S.N", field: "Index" },
    { header: "Class", field: "ClassName" },
    { header: "Section", field: "SectionName" },
    { header: "Subject", field: "SubjectName" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const toPdf = () => {
    const doc = new jsPDF("p", "pt", "a4", true);

    var midPage = doc.internal.pageSize.getWidth() / 2;

    // doc.addImage(`${userinfo.SchPhoto}`, "JPG", 40, 40, 70, 60);

    doc.setFontSize(18);
    doc.text(`${userinfo.SchName}`, midPage, 50, null, null, "center");

    doc.setFontSize(15);
    doc.text(`${userinfo.SchAddress}`, midPage, 68, null, null, "center");

    doc.setFontSize(10);
    doc.text(
      `Tel:${userinfo.Phone}, E-mail:${userinfo.Email}`,
      midPage,
      85,
      null,
      null,
      "center"
    );

    doc.autoTable({
      startY: 100,
      theme: "grid",
      columns: exportColumns,
      body: dataWithIndex,
    });
    doc.save("Subject.pdf");
  };
  //end

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
        <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">Subject List</div>
            <div className="page-date">
              <div className="sec-content">
                <AcademicHeader
                  todayDate={todayDate}
                  AcademicYear={AcademicYear}
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <>
          <div className="sec-dataTable content-section main-content">
            <div className="upper-dataTbl">
              <div className="btn-addlnote">
                {userinfo.UserType !== "T" && (
                  <button
                    type="button"
                    class="btn btn-sm"
                    style={{
                      background: "var(--MainColor)",
                      color: "white",
                    }}
                    onClick={addnew}
                  >
                    Add New
                  </button>
                )}
                <div className="exportDiv">
                  <Tippy content="Excel">
                    <button className="exportButtons mx-2" onClick={toExcel}>
                      <i className="bi bi-file-earmark-excel-fill exportButtonIconsExcel"></i>
                    </button>
                  </Tippy>

                  <Tippy content="PDF">
                    <button className="exportButtons" onClick={toPdf}>
                      <i className="bi bi-file-earmark-pdf-fill exportButtonIconsPdf"></i>
                    </button>
                  </Tippy>
                </div>
              </div>
            </div>
            {userinfo.UserType === "A" ? (
              <hr className="new-hr-new" />
            ) : (
              <hr className="new-hr" />
            )}
            {loading ? (
              <Spinner />
            ) : (
              <DataTable
                columns={columns}
                data={teachingSubjectList}
                customStyles={customStyles}
                pagination
                fixedHeader
                fixedHeaderScrollHeight="280px"
                highlightOnHover
                pointerOnHover
                responsive
                dense
                striped
                subHeader
                subHeaderAlign="left"
                subHeaderComponent={
                  <>
                    {userinfo.UserType !== "T" ? (
                      <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                        <label className="list-label">Teacher</label>
                        <select
                          style={{ fontSize: "11px", marginBottom: "3px" }}
                          name="snotifiaction"
                          value={selectTeacher}
                          onChange={handleTeacher}
                          className="form-control form-control-sm"
                          aria-label="Default select example "
                        >
                          <option
                            value=""
                            disabled
                            selected
                            style={{ fontSize: "11px" }}
                          >
                            Select Teacher
                          </option>
                          {teacherList.map((item) => (
                            <option key={item.TeacherID} value={item.TeacherID}>
                              {item.TeacherName}
                            </option>
                          ))}
                        </select>
                        <i class="fas fa-angle-down  notificationpositon"></i>
                      </div>
                    ) : (
                      <></>
                    )}

                    <div
                      className={
                        userinfo.UserType === "T"
                          ? "col-lg-2 offset-lg-10 offset-md-10 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
                          : "col-lg-2 offset-lg-8 offset-md-8 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
                      }
                      style={{ paddingRight: "1px", marginBottom: "10px" }}
                    >
                      <div className="d-flex">
                        <input
                          placeholder="Search"
                          ref={searchInput}
                          type="text"
                          className="form-control form-control-sm searchField"
                          onChange={searchHandler}
                        />
                      </div>
                    </div>
                  </>
                }
              />
            )}
          </div>
        </>
      </div>

      {popup && (
        <div className="modals">
          <div className="overlay"></div>

          <div className="notification-model-content">
            <div className="popUpHeader ">
              <div className="popUpHeaderText ">Add Subject</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closePopup}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="inside-modal">
              <SubjectForm />
            </div>
          </div>
        </div>
      )}

      {deletePopup.show && (
        <DeletePop
          handleDeleteTrue={handleDeleteTrue}
          handleDeleteFalse={handleDeleteFalse}
        />
      )}
    </>
  );
}
