import React, { useContext, useState, useEffect } from 'react'
import LeaveApplicationContext from '../contextfolder/LeaveApplicationContext/LeaveApplicationContext';
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";



export default function LeaveApplicationForm({ closePopup }) {

    const context = useContext(LeaveApplicationContext)

    const { togglePopup, leaveFormError, setLeaveFormError, isSubmit, setIsSubmit, leaveValue, leaveFormValue, setLeaveFormValue, fetchdata } = context;

    const [closeChecked, setCloseChecked] = useState(false);
    const handleOnChange = () => {
        setCloseChecked(!closeChecked);
    };


    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;
        setLeaveFormValue({ ...leaveFormValue, [name]: value });

    };

    const handleFromDate = ({ adDate }) => {
        setLeaveFormValue({ ...leaveFormValue, fromDate: adDate });
    };

    const handleToDate = ({ adDate }) => {
        setLeaveFormValue({ ...leaveFormValue, toDate: adDate });
    };


    const formSubmit = (e) => {
        e.preventDefault();
        setLeaveFormError(validate(leaveFormValue));
        setIsSubmit(true);

    }

    useEffect(() => {
        if (Object.keys(leaveFormError).length === 0 && isSubmit) {
            fetchdata(leaveFormValue)
            if (closeChecked === true) {
                togglePopup();
            }
            setLeaveFormValue(leaveValue)
            setIsSubmit(false);
        }

    }, [leaveFormError]);

    const validate = (values) => {

        const errors = {};
        if (!values.title) {
            errors.title = "Required";
        }
        if (!values.description) {
            errors.description = "Required";
        }
        if (!values.fromDate) {
            errors.fromDate = "Required";
        }
        if (!values.toDate) {
            errors.toDate = "Required";
        }

        return errors;
    };



    return (
        <>

            <div className="container-fluid basicform" >

                <form >

                    <div className="form-group">
                        <label className="form-label" htmlFor="title" style={{ fontSize: "12px" }}>
                            Title<sup style={{ color: "red" }}>*</sup>
                        </label>
                        <input
                            id="title"
                            style={{ fontSize: "13px" }}
                            type="text"
                            className="form-control form-control-sm "
                            name="title"
                            value={leaveFormValue.title}
                            onChange={handleChange}
                        />
                        <p className="errormsg " style={{ marginBottom: "0" }}>{leaveFormError.title}</p>

                    </div>

                    <div className="form-group">
                        <label className="form-label" htmlFor="description" style={{ fontSize: "12px" }}>
                            Description<sup style={{ color: "red" }}>*</sup>
                        </label>
                        <textarea
                            id="description"
                            style={{ fontSize: "13px" }}
                            type="text"
                            className="form-control form-control-sm "
                            name="description"
                            value={leaveFormValue.description}
                            onChange={handleChange}
                        />
                        <p className="errormsg " style={{ marginBottom: "0" }}>{leaveFormError.description}</p>

                    </div>

                    <div className="form-row">

                        <div className="form-group col-md-6">
                            <label className="form-label" htmlFor="fromDate" style={{ fontSize: "12px" }}>
                                From Date<sup style={{ color: "red" }}>*</sup>
                            </label>
                            <Calendar
                                className="form-control form-control-sm mb-1"
                                dateFormat="YYYY/MM/DD"
                                theme="default"
                                language="en"
                                values={leaveFormValue.fromDate}
                                onChange={handleFromDate}
                            />
                            <p className="errormsg " style={{ marginBottom: "0" }}>{leaveFormError.fromDate}</p>
                        </div>

                        <div className="form-group col-md-6">
                            <label className="form-label" htmlFor="toDate" style={{ fontSize: "12px" }}>
                                To Date<sup style={{ color: "red" }}>*</sup>
                            </label>
                            <Calendar
                                className="form-control form-control-sm mb-1"
                                dateFormat="YYYY/MM/DD"
                                theme="default"
                                language="en"
                                values={leaveFormValue.toDate}
                                onChange={handleToDate}
                            />
                            <p className="errormsg " style={{ marginBottom: "0" }}>{leaveFormError.toDate}</p>
                        </div>

                    </div>

                </form>


            </div>
            <div className="FormFooter">
                <div className='checkedClose'>
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck2"
                        name="closeChecked"
                        onChange={handleOnChange}
                        checked={closeChecked}
                    />
                    <label class="form-check-label" for="exampleCheck2">
                        Exit after submit
                    </label>
                </div>
                <div>
                    <button className='btn btn-sm btn-cmpy ml-2' style={{ background: "green" }} onClick={formSubmit} >Submit</button>
                    <button className='btn btn-sm btn-cmpy ml-2' onClick={closePopup}>Cancel</button>
                </div>
            </div>

        </>

    )
}
