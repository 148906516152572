import React, { useContext, useEffect, useState } from "react";
import { Fetchdata } from "../../hooks/getData";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { toast } from "react-toastify";
import SuggestionContext from "./SuggestionContext";

function SuggestionState(props) {
  const { appURL } = useContext(SidebarContext);

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);
  const [suggestionTopicSearch, setSuggestionTopicSearch] = useState(null);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const [closeChecked, setCloseChecked] = useState(false);

  const [selectSuggTopic, setSelectSuggTopic] = useState("");

  const suggestionValue = {
    topic: "",
  };

  const [suggestionFormValue, setSuggestionFormValue] =
    useState(suggestionValue);
  const [suggestionFormError, setSuggestionFormError] = useState({});
  const [isSubmit, setIsSubmit] = useState({});

  // For popup
  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  const remarksvalue = {
    status: "P",
    remarks: "",
  };
  const [remarksFormValue, setRemarksFormValue] = useState(remarksvalue);

  //Initializing initial value for view  adminLeave Popup

  const [remarksFormError, setRemarksFormError] = useState({});
  const [remarksIsSubmit, setRemarksIsSubmit] = useState(false);
  const [pushNotice, setPushNotice] = useState(false);

  // For popup
  const [popup, setPopup] = useState(false);

  const togglePopup = () => {
    setPopup(!popup);
  };

  //Everything to pop up and delete the suggestion list
  const [deletePopup, setDeletePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const [delData, setDelData] = useState();

  const handleDelete = async (data) => {
    setDelData(data);

    setDeletePopup({
      show: true,
      data,
    });
  };

  // This will perform the deletion and hide the Confirmation Box

  const handleDeleteTrue = async () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "R",
      SuggestionID: delData.toString(),
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/suggestion`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          getSuggestionList();
          toast(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
          setDeletePopup({
            show: false,
            data: null,
          });
        } else {
          toast(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleDeleteFalse = () => {
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // Ends Here

  //API to create suggestion topic list
  const fetchdata = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "I",
      Topic: suggestionFormValue.topic,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/suggestion-topic`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getSuggestionTopicList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };

  // API for suggestion topic list
  const [suggestionTopicList, setSuggestionTopicList] = useState([]);

  useEffect(() => {
    getSuggestionTopicList();
  }, []);

  const getSuggestionTopicList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/suggestion-topic`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.SuggestionLst ? result.SuggestionLst : "";
        setLoading(false);
        setSuggestionTopicList(postResult);
        setSuggestionTopicSearch(postResult);
      } else {
        setLoading(false);
        setSuggestionTopicList([]);
        setSuggestionTopicSearch([]);
      }
    });
  };

  // Suggestion Edit topic
  const [titleId, setTitleId] = useState("");

  const editdata = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "U",
      TopicID: titleId,
      Topic: suggestionFormValue.topic,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/suggestion-topic`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getSuggestionTopicList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };

  // API for suggestion list
  const [suggestionList, setSuggestionList] = useState([]);

  useEffect(() => {
    getSuggestionList();
  }, [selectSuggTopic]);

  const getSuggestionList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      TopicID: selectSuggTopic,
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/suggestion`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.SuggestionLst ? result.SuggestionLst : "";

        setLoading(false);
        setSuggestionList(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        setSuggestionList([]);
        setOriginalList([]);
      }
    });
  };

  //For suggestion view
  const [viewId, setViewId] = useState("");
  const [state, setState] = useState();
  const view = (data) => {
    setState(data);
    togglePopup();
    setViewId(data.SuggestionID);
    setRemarksFormValue({
      status: data.SuggestionStatusID,
      remarks: data.Remarks,
    });
  };

  const viewdata = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "US",
      SuggestionID: viewId,
      Status: remarksFormValue.status,
      Remarks: remarksFormValue.remarks,
      Notify: pushNotice === true ? "Y" : "N",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/suggestion`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getSuggestionList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };

  //Everything to pop up and delete the topic suggestion topic list
  const [deleteTopicPopup, setDeleteTopicPopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const [delTopicData, setDelTopicData] = useState();

  const handleTopicDelete = async (data) => {
    setDelTopicData(data);

    setDeleteTopicPopup({
      show: true,
      data,
    });
  };

  // This will perform the deletion and hide the Confirmation Box

  const handleDeleteTopicTrue = async () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "R",
      TopicID: delTopicData.toString(),
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/suggestion-topic`, requestOptions).then(
      (result) => {
        result.json().then((resp) => {
          if (resp.StatusCode === 200) {
            getSuggestionTopicList();
            toast(resp.Message, {
              style: {
                color: "green",
                fontSize: "13px",
              },
            });
            setDeleteTopicPopup({
              show: false,
              data: null,
            });
          } else {
            toast(resp.Message, {
              style: {
                color: "red",
                fontSize: "13px",
              },
            });
          }
        });
      }
    );
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleDeleteTopicFalse = () => {
    setDeleteTopicPopup({
      show: false,
      data: null,
    });
  };

  // Ends Here

  // This is all about Student State Section

  const [stdLoading, setStdLoading] = useState(true);
  const [stdOriginalList, setStdOriginalList] = useState(null);

  const [stdCloseChecked, setStdCloseChecked] = useState(false);

  const stdSuggestionValue = {
    topic: "",
    title: "",
    description: "",
  };

  const [stdSuggestionFormValue, setStdSuggestionFormValue] =
    useState(suggestionValue);
  const [stdSuggestionFormError, setStdSuggestionFormError] = useState({});
  const [stdIsSubmit, setStdIsSubmit] = useState({});

  const [stdModal, setStdModal] = useState(false);

  const toggleStdModal = () => {
    setStdModal(!stdModal);
  };

  //API to hit suggestion topic for student
  const [stdTopicList, setStdTopicList] = useState([]);

  useEffect(() => {
    getTopicList();
  }, []);

  const getTopicList = () => {
    const params = {
      SchID: userinfo.SchID,
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/suggestion-topic`,
    };
    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.SuggestionLst ? result.SuggestionLst : "";
        setStdTopicList(postResult);
      } else {
        setStdTopicList([]);
      }
    });
  };

  //To submit Suggestion Value
  const stdfetchdata = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      MemID: userinfo.UserID.toString(),
      Flag: "i",
      TopicID: stdSuggestionFormValue.topic,
      Title: stdSuggestionFormValue.title,
      Description: stdSuggestionFormValue.description,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/suggestion`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getStudentSuggestionList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };

  // API for Student suggestion list
  const [stdSuggestionList, setStdSuggestionList] = useState([]);

  useEffect(() => {
    getStudentSuggestionList();
  }, []);

  const getStudentSuggestionList = () => {
    const params = {
      SchID: userinfo.SchID,
      MemID: userinfo.UserID.toString(),
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/suggestion`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.SuggestionLst ? result.SuggestionLst : "";

        setStdLoading(false);
        setStdSuggestionList(postResult);
        setStdOriginalList(postResult);
      } else {
        setStdLoading(false);
        setStdSuggestionList([]);
        setStdOriginalList([]);
      }
    });
  };

  //Everything to pop up and delete the student suggestion list
  const [stdDeletePopup, setStdDeletePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const [delStdData, setDelStdData] = useState();

  const handleStdDelete = async (data) => {
    setDelStdData(data);

    setStdDeletePopup({
      show: true,
      data,
    });
  };

  // This will perform the deletion and hide the Confirmation Box

  const handleStdDeleteTrue = async () => {
    const dataForm = {
      SchID: userinfo.SchID,
      MemID: userinfo.UserID.toString(),
      Flag: "R",
      SuggestionID: delStdData.toString(),
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/suggestion`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          getStudentSuggestionList();
          toast(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
          setStdDeletePopup({
            show: false,
            data: null,
          });
        } else {
          toast(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleStdDeleteFalse = () => {
    setStdDeletePopup({
      show: false,
      data: null,
    });
  };

  // Ends Here

  //Everything to pop up and approve the suggestionList list

  const popValue = {
    approveRemarks: "",
    rejectRemarks: "",
  };

  const [popFormValue, setPopFormValue] = useState(popValue);
  const [popFormError, setPopFormError] = useState({});
  const [isPopSubmit, setIsPopSubmit] = useState(false);

  //State for notify user

  const [approveNotify, setApproveNotify] = useState(false);
  // Stae for notify user ends

  const [approvePopup, setApprovePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const [Acdata, setACData] = useState();

  const handleApprove = async (data) => {
    setACData(data);
    setApprovePopup({
      show: true,
      data,
    });
  };

  // This will perform the deletion and hide the Confirmation Box

  const handleApproveTrue = async () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "US",
      SuggestionID: Acdata,
      TopicID: "1",
      Status: "A",
      Remarks: popFormValue.approveRemarks,
      Notify: approveNotify === true ? "Y" : "N",
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/suggestion`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          getSuggestionList();
          toast(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
          setApprovePopup({
            show: false,
            data: null,
          });
        } else {
          toast(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleApproveFalse = () => {
    setPopFormError({});
    setApprovePopup({
      show: false,
      data: null,
    });
  };

  // Ends Here

  //Everything to pop up and reject the leave list

  //State for notify user

  const [rejectNotify, setRejectNotify] = useState(false);
  // Stae for notify user ends

  const [rejectPopup, setRejectPopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const [rdata, setRData] = useState();

  const handleReject = async (data) => {
    setRData(data);

    setRejectPopup({
      show: true,
      data,
    });
  };

  // This will perform the deletion and hide the Confirmation Box

  const handleRejectTrue = async () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "US",
      SuggestionID: rdata,
      TopicID: "1",
      Status: "R",
      Remarks: popFormValue.rejectRemarks,
      Notify: rejectNotify === true ? "Y" : "N",
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/suggestion`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          getSuggestionList();
          toast(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
          setRejectPopup({
            show: false,
            data: null,
          });
        } else {
          toast(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleRejectFalse = () => {
    setPopFormError({});
    setRejectPopup({
      show: false,
      data: null,
    });
  };

  // Ends Here

  return (
    <SuggestionContext.Provider
      value={{
        modal,
        setModal,
        toggleModal,
        loading,
        originalList,
        deletePopup,
        handleDeleteTrue,
        handleDeleteFalse,
        handleDelete,
        suggestionFormValue,
        setSuggestionFormValue,
        suggestionFormError,
        setSuggestionFormError,
        isSubmit,
        suggestionValue,
        setIsSubmit,
        suggestionList,
        setSuggestionList,
        suggestionTopicList,
        togglePopup,
        remarksFormValue,
        setRemarksFormValue,
        remarksFormError,
        setRemarksFormError,
        remarksIsSubmit,
        setRemarksIsSubmit,
        remarksvalue,
        popup,
        fetchdata,
        titleId,
        setTitleId,
        editdata,
        suggestionTopicSearch,
        setSuggestionTopicList,
        selectSuggTopic,
        setSelectSuggTopic,
        view,
        state,
        pushNotice,
        setPushNotice,
        viewdata,
        deleteTopicPopup,
        handleTopicDelete,
        handleDeleteTopicTrue,
        handleDeleteTopicFalse,
        closeChecked,
        setCloseChecked,

        stdLoading,
        stdOriginalList,
        stdCloseChecked,
        setStdCloseChecked,
        stdSuggestionValue,
        stdSuggestionFormValue,
        setStdSuggestionFormValue,
        stdSuggestionFormError,
        setStdSuggestionFormError,
        stdIsSubmit,
        setStdIsSubmit,
        stdModal,
        setStdModal,
        toggleStdModal,
        stdSuggestionList,
        setStdSuggestionList,
        stdTopicList,
        setStdTopicList,
        stdfetchdata,
        stdDeletePopup,
        setStdDeletePopup,
        handleStdDelete,
        handleStdDeleteTrue,
        handleStdDeleteFalse,

        approveNotify,
        setApproveNotify,
        rejectNotify,
        setRejectNotify,
        popFormValue,
        setPopFormValue,
        popFormError,
        setPopFormError,
        isPopSubmit,
        setIsPopSubmit,
        popValue,

        approvePopup,
        handleApproveFalse,
        handleApproveTrue,
        handleApprove,
        rejectPopup,
        handleRejectTrue,
        handleRejectFalse,
        handleReject,
      }}
    >
      {props.children}
    </SuggestionContext.Provider>
  );
}

export default SuggestionState;
