import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { Fetchdata } from "../hooks/getData";
import { showImgPreview } from "../hooks/ImagePreview";
import AdminContext from "../contextfolder/AdminContext/AdminContext";
import ResetPassword from "../Admin/ResetPassword";
import DeletePop from "../Admin/DeletePop";
import AcademicHeader from "../AcademicHeader";

export default function StaffList() {
  const { todayDate, appURL, customStylesForImage, AcademicYear } =
    useContext(SidebarContext);

  const {
    resetPopup,
    handleResetFalse,
    handleResetTrue,
    handleReset,
    deletePopup,
    handleDeleteTrue,
    handleDeleteFalse,
    handleDelete,
    changephoto,
  } = useContext(AdminContext);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const searchInput = useRef("");

  const [imgPrv, setImgPrv] = useState(false);
  const [imagePre, setImagePre] = useState("");

  const [staffList, setStaffList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);

  useEffect(() => {
    getStaff();
  }, []);

  const getStaff = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      Flag: "SS",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/teacher`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.teachingres ? result.teachingres : "";
        setStaffList(postResult);
        setOriginalList(postResult);
        setLoading(false);
      } else {
        setStaffList([]);
        setOriginalList([]);
        setLoading(false);
      }
    });
  };

  function handleChange(e, studentID) {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        changephoto(studentID, e.target.result);
      };

      reader.readAsDataURL(e.target.files[0]);
    }
  }

  const columns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "Image",
      center: true,
      width: "90px",
      selector: (row) => {
        return (
          <>
            <div className="staffContentLogo">
              <div className="staffImg">
                <img
                  src={row.Image}
                  alt=""
                  onClick={() => {
                    setImagePre(row.Image);
                    setImgPrv(true);
                  }}
                />
              </div>
            </div>
          </>
        );
      },
    },

    {
      name: "User Name",
      width: "100px",
      selector: (row) => row.StaffUsername,
    },
    {
      name: "Full Name",
      selector: (row) => row.StaffName,
    },
    {
      name: "Gender",
      width: "80px",
      selector: (row) => row.Gender,
    },
    {
      name: "Address",
      width: "150px",
      selector: (row) => row.Address,
    },

    {
      name: "Phone",
      width: "150px",
      selector: (row) => row.Phone,
    },
    {
      name: "Action",
      center: true,
      width: "150px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex" style={{ marginTop: "6px" }}>
              <input
                type="file"
                id={`file-input-${row.StaffUsername}`}
                onChange={(e) => handleChange(e, row.StaffUsername)}
              />
              <Tippy content="Change Photo">
                <label htmlFor={`file-input-${row.StaffUsername}`}>
                  {" "}
                  <i className="fa fa-user-circle-o editspan--action"> </i>{" "}
                </label>
              </Tippy>

              <Tippy content="Reset Password">
                <i
                  className="fa fa-repeat solvespan--action"
                  onClick={() =>
                    handleReset([row.StaffUsername, row.StaffUsername])
                  }
                ></i>
              </Tippy>
              <Tippy content="Delete">
                <i
                  className="fa fa-trash-o rejectspan--action"
                  onClick={() => handleDelete(row.StaffUsername)}
                ></i>
              </Tippy>
            </div>
          </>
        );
      },
    },
  ];

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["StaffName"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setStaffList(srchResult);
      } else {
        setStaffList({});
      }
    } else {
      setStaffList(originalList);
    }
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
        <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">Non Teaching Staff</div>
            <div className="page-date">
              <div className="sec-content">
                <AcademicHeader
                  todayDate={todayDate}
                  AcademicYear={AcademicYear}
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <>
          <div className="sec-dataTable content-section main-content">
            <hr className="new-hr-new" />
            {loading ? (
              <Spinner />
            ) : (
              <DataTable
                columns={columns}
                data={staffList}
                customStyles={customStylesForImage}
                pagination
                fixedHeader
                fixedHeaderScrollHeight="280px"
                highlightOnHover
                pointerOnHover
                responsive
                dense
                striped
                subHeader
                subHeaderComponent={
                  <>
                    <div
                      className="col-lg-2 offset-lg-10 offset-md-10 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
                      style={{ paddingRight: "1px", marginBottom: "10px" }}
                    >
                      <div className="d-flex">
                        <input
                          placeholder="Search"
                          ref={searchInput}
                          type="text"
                          className="form-control form-control-sm searchField"
                          onChange={searchHandler}
                        />
                      </div>
                    </div>
                  </>
                }
              />
            )}
          </div>
        </>
      </div>

      {imgPrv &&
        showImgPreview({
          img: imagePre,
          setTrigger: setImgPrv,
        })}

      {resetPopup.show && (
        <ResetPassword
          handleResetTrue={handleResetTrue}
          handleResetFalse={handleResetFalse}
        />
      )}
      {deletePopup.show && (
        <DeletePop
          handleDeleteTrue={handleDeleteTrue}
          handleDeleteFalse={handleDeleteFalse}
        />
      )}
    </>
  );
}
