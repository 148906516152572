
import React, { useContext, useState } from "react";
const SessionContext = React.createContext();

export const SessionContextProvider = (props) => {

    const [isActive, setIsActive] = useState(true);

    return (
        <SessionContext.Provider
            value={{

                isActive,
                setIsActive,
            }}
        >
            {props.children}
        </SessionContext.Provider>
    );
};

export default SessionContext;
