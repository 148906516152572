import React, { useContext, useEffect, useState } from "react";
import { Fetchdata } from "../../hooks/getData";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { toast } from "react-toastify";
import AttenInputContext from "./AttenInputContext";

function AttenInputState(props) {
  const { appURL } = useContext(SidebarContext);

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const [attenList, setAttenList] = useState([]);

  const [isAttenSubmit, setIsAttenSubmit] = useState(false);

  const [classList, setClassList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [termList, setTermList] = useState([]);

  const [selectClass, setSelectClass] = useState("");
  const [selectSection, setSelectSection] = useState("");
  const [selectTerm, setSelectTerm] = useState("");

  useEffect(() => {
    getClassList();
  }, []);

  const getClassList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      DFlag: "C",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setClassList(postResult);
      } else {
        setClassList([]);
      }
    });
  };

  useEffect(() => {
    getSectionList();
  }, [selectClass]);

  const getSectionList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      ClassID: selectClass,
      DFlag: "S",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setSectionList(postResult);
      } else {
        setSectionList([]);
      }
    });
  };

  //API to hit Terminal list

  useEffect(() => {
    getTermList();
  }, []);

  const getTermList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      DFlag: "T",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";

        setLoading(false);
        setTermList(postResult);
      } else {
        setTermList([]);
      }
    });
  };

  // API for Atteninput List
  const [attenInputList, setAttenInputList] = useState([]);

  useEffect(() => {
    getAttenInputList();
  }, [selectTerm, selectClass, selectSection]);

  const getAttenInputList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      Flag: "S",
      ClassID: selectClass,
      SectionID: selectSection,
      TermID: selectTerm,
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/atten-input`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.MarksInputLst ? result.MarksInputLst : "";

        setLoading(false);
        setAttenInputList(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        setAttenInputList([]);
        setOriginalList([]);
      }
    });
  };

  // to remove keys

  const keysToRemove = ["FullName", "RollNo"];

  const filteredAttenInput = attenInputList.map((obj) => {
    const newObj = { ...obj };
    keysToRemove.forEach((key) => delete newObj[key]);
    return newObj;
  });

  // Convert PresentDays to string in each object
  const finalAttenInput = filteredAttenInput.map((item) => ({
    ...item,
    PresentDays: item.PresentDays.toString(),
  }));

  //API to create Atten Input
  const fetchAttendata = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "i",
      ClassID: selectClass,
      SectionID: selectSection,
      TermID: selectTerm,
      Param: finalAttenInput,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/atten-input`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getAttenInputList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };

  return (
    <AttenInputContext.Provider
      value={{
        loading,
        originalList,
        setIsAttenSubmit,
        isAttenSubmit,
        classList,
        sectionList,
        selectClass,
        setSelectClass,
        selectSection,
        setSelectSection,
        fetchAttendata,
        attenInputList,
        setAttenInputList,
        termList,
        setTermList,
        selectTerm,
        setSelectTerm,
        attenList,
        setAttenList,
        setLoading,
      }}
    >
      {props.children}
    </AttenInputContext.Provider>
  );
}

export default AttenInputState;
