import React, { useContext, useEffect, useState } from "react";
import OnlineClassContext from "../contextfolder/OnlineClassContext/OnlineClassContext";
import SidebarContext from "../sidebarcontext/SidebarContext";
import { Fetchdata } from "../hooks/getData";

export default function OnlineClassForm({ closePopup }) {
  const { appURL } = useContext(SidebarContext);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const context = useContext(OnlineClassContext);

  const {
    togglePopup,
    onlineFormError,
    setOnlineFormError,
    isSubmit,
    setIsSubmit,
    onlineValue,
    onlineFormValue,
    setOnlineFormValue,
    fetchdata,
    closeChecked,
    setCloseChecked,
  } = context;

  const handleOnChange = () => {
    setCloseChecked(!closeChecked);
  };

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setOnlineFormValue({ ...onlineFormValue, [name]: value });
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setOnlineFormError(validate(onlineFormValue));
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(onlineFormError).length === 0 && isSubmit) {
      fetchdata(onlineFormValue);
      if (closeChecked === true) {
        togglePopup();
      }
      setOnlineFormValue(onlineValue);
      setIsSubmit(false);
    }
  }, [onlineFormError]);

  const validate = (values) => {
    const errors = {};
    const regex = /^(ftp|http|https):\/\/[^ "]+$/;

    if (!values.class) {
      errors.class = "Required";
    }
    if (!values.section) {
      errors.section = "Required";
    }
    if (userinfo.UserType !== "T") {
      if (!values.teacher) {
        errors.teacher = "Required";
      }
    }
    if (!values.subject) {
      errors.subject = "Required";
    }
    if (!values.tool) {
      errors.tool = "Required";
    }
    if (onlineFormValue.tool === "Z" || onlineFormValue.tool === "J") {
      if (!values.meetingID) {
        errors.meetingID = "Required";
      }
      if (!values.meetingPassword) {
        errors.meetingPassword = "Required";
      }
    }
    if (!values.meetingUrl) {
      errors.meetingUrl = "Required";
    } else if (!regex.test(values.meetingUrl)) {
      errors.meetingUrl = "This is not a valid url format";
    }
    return errors;
  };

  //API for class list
  const [classList, setClassList] = useState([]);
  useEffect(() => {
    getClassList();
  }, []);

  const getClassList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      DFlag: "C",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setClassList(postResult);
      } else {
        setClassList([]);
      }
    });
  };

  //API for Section List
  const [sectionList, setSectionList] = useState([]);
  useEffect(() => {
    getSectionList();
  }, [onlineFormValue.class]);

  const getSectionList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      ClassID: onlineFormValue.class,
      DFlag: "S",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setSectionList(postResult);
      } else {
        setSectionList([]);
      }
    });
  };

  //API for teacher list
  const [teacherList, setTeacherList] = useState([]);

  useEffect(() => {
    teachList();
  }, [onlineFormValue.class, onlineFormValue.section]);

  const teachList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      DFlag: "TL",
      Flag: "S",
      ClassID: onlineFormValue.class,
      SectionID: onlineFormValue.section,
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setTeacherList(postResult);
      } else {
        setTeacherList([]);
      }
    });
  };

  //API for subject list
  const [subjectList, setSubjectList] = useState([]);

  useEffect(() => {
    getSubjectList();
  }, [onlineFormValue.class, onlineFormValue.teacher, onlineFormValue.section]);

  const getSubjectList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID:
        userinfo.UserType === "T" ? userinfo.UserID : onlineFormValue.teacher,
      ClassID: onlineFormValue.class,
      SectionID: onlineFormValue.section,
      DFlag: "SB",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";

        setSubjectList(postResult);
      } else {
        setSubjectList([]);
      }
    });
  };

  return (
    <>
      <div className="container-fluid basicform">
        <form>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label className="form-label" htmlFor="class">
                Class<sup style={{ color: "red" }}>*</sup>
              </label>
              <select
                id="class"
                name="class"
                onChange={handleChange}
                value={onlineFormValue.class}
                className="form-select form-select-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="" disabled selected style={{ fontSize: "11px" }}>
                  Select Class
                </option>
                {classList.map((item) => (
                  <option key={item.ClassID} value={item.ClassID}>
                    {item.ClassName}
                  </option>
                ))}
              </select>
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {onlineFormError.class}
              </p>
            </div>

            <div className="form-group col-md-6">
              <label className="form-label" htmlFor="section">
                Section<sup style={{ color: "red" }}>*</sup>
              </label>
              <select
                id="section"
                name="section"
                onChange={handleChange}
                value={onlineFormValue.section}
                className="form-select form-select-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="" disabled selected style={{ fontSize: "11px" }}>
                  Select Section
                </option>
                {sectionList.map((item) => (
                  <option key={item.SectionID} value={item.SectionID}>
                    {item.SectionName}
                  </option>
                ))}
              </select>
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {onlineFormError.section}
              </p>
            </div>
          </div>

          <div className="form-row">
            {userinfo.UserType !== "T" && (
              <div className="form-group col-md-6">
                <label className="form-label" htmlFor="teachera">
                  Teacher<sup style={{ color: "red" }}>*</sup>
                </label>
                <select
                  id="teachera"
                  name="teacher"
                  onChange={handleChange}
                  value={onlineFormValue.teacher}
                  className="form-select form-select-sm mb-1"
                  style={{ fontSize: "12px" }}
                >
                  <option
                    value=""
                    disabled
                    selected
                    style={{ fontSize: "11px" }}
                  >
                    Select Teacher
                  </option>
                  {teacherList.map((item) => (
                    <option key={item.UserID} value={item.UserID}>
                      {item.FullName}
                    </option>
                  ))}
                </select>
                <p className="errormsg " style={{ marginBottom: "0" }}>
                  {onlineFormError.teacher}
                </p>
              </div>
            )}

            <div
              className={
                userinfo.UserType === "T"
                  ? "form-group col-md-12"
                  : "form-group col-md-6"
              }
            >
              <label className="form-label" htmlFor="subject">
                Subject<sup style={{ color: "red" }}>*</sup>
              </label>
              <select
                id="subject"
                name="subject"
                onChange={handleChange}
                value={onlineFormValue.subject}
                className="form-select form-select-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="" disabled selected style={{ fontSize: "11px" }}>
                  Select Subject
                </option>
                {subjectList.map((item) => (
                  <option key={item.SubjectID} value={item.SubjectID}>
                    {item.SubjectName}
                  </option>
                ))}
              </select>
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {onlineFormError.subject}
              </p>
            </div>
          </div>

          <div className="form-group">
            <label
              className="form-label"
              htmlFor="tool"
              style={{ fontSize: "12px" }}
            >
              Tool<sup style={{ color: "red" }}>*</sup>
            </label>
            <select
              id="tool"
              name="tool"
              onChange={handleChange}
              value={onlineFormValue.tool}
              className="form-select form-select-sm mb-1"
              style={{ fontSize: "12px" }}
            >
              <option value="" disabled selected style={{ fontSize: "11px" }}>
                Select Tool
              </option>
              <option value="Z">Zoom</option>
              <option value="G">Google Meet</option>
              <option value="T">Team</option>
              <option value="M">Messenger</option>
              <option value="J">Jitsi</option>
            </select>
            <p className="errormsg " style={{ marginBottom: "0" }}>
              {onlineFormError.tool}
            </p>
          </div>

          {onlineFormValue.tool === "Z" || onlineFormValue.tool === "J" ? (
            <div className="form-row">
              <div className="form-group col-md-6">
                <label
                  className="form-label"
                  htmlFor="meetingID"
                  style={{ fontSize: "12px" }}
                >
                  Meeting ID <sup style={{ color: "red" }}>*</sup>
                </label>
                <input
                  id="meetingID"
                  style={{ fontSize: "13px" }}
                  type="text"
                  className="form-control form-control-sm"
                  name="meetingID"
                  value={onlineFormValue.meetingID}
                  onChange={handleChange}
                />
                <p className="errormsg " style={{ marginBottom: "0" }}>
                  {onlineFormError.meetingID}
                </p>
              </div>

              <div className="form-group col-md-6">
                <label
                  className="form-label"
                  htmlFor="meetingPassword"
                  style={{ fontSize: "12px" }}
                >
                  Meeting Password<sup style={{ color: "red" }}>*</sup>
                </label>
                <input
                  id="meetingPassword"
                  style={{ fontSize: "13px" }}
                  type="text"
                  className="form-control form-control-sm "
                  name="meetingPassword"
                  value={onlineFormValue.meetingPassword}
                  onChange={handleChange}
                />
                <p className="errormsg " style={{ marginBottom: "0" }}>
                  {onlineFormError.meetingPassword}
                </p>
              </div>
            </div>
          ) : (
            <> </>
          )}

          <div className="form-group">
            <label
              className="form-label"
              htmlFor="meetingUrl"
              style={{ fontSize: "12px" }}
            >
              Meeting URL<sup style={{ color: "red" }}>*</sup>
            </label>
            <input
              id="meetingUrl"
              style={{ fontSize: "13px" }}
              type="text"
              className="form-control form-control-sm "
              name="meetingUrl"
              value={onlineFormValue.meetingUrl}
              onChange={handleChange}
            />
            <p className="errormsg " style={{ marginBottom: "0" }}>
              {onlineFormError.meetingUrl}
            </p>
          </div>
        </form>
      </div>
      <div className="FormFooter">
        <div className="checkedClose">
          <input
            type="checkbox"
            className="form-check-input"
            id="exampleCheck2"
            name="closeChecked"
            onChange={handleOnChange}
            checked={closeChecked}
          />
          <label class="form-check-label" for="exampleCheck2">
            Exit after submit
          </label>
        </div>
        <div>
          <button
            className="btn btn-sm btn-cmpy ml-2"
            style={{ background: "green" }}
            onClick={formSubmit}
          >
            Submit
          </button>
          <button className="btn btn-sm btn-cmpy ml-2" onClick={closePopup}>
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}
