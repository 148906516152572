import React, { useContext, useEffect, useState } from "react";
import { Fetchdata } from "../../hooks/getData";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { toast } from "react-toastify";
import LogBookContext from "./LogBookContext";
import { GetEnglishDate } from "../../hooks/dateConvertor";

function LogBookState(props) {
  const { appURL } = useContext(SidebarContext);

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const [closeChecked, setCloseChecked] = useState(false);
  var d = new Date();
  const logBookValue = {
    class: "",
    section: "",
    subject: "",
    teacher: "",
    lesson: "",
    page: "",
    lessonTaught: "",
    assignmentGiven: "",
    date: GetEnglishDate(d),
  };

  const [logBookFormValue, setlogBookFormValue] = useState(logBookValue);
  const [logBookFormError, setlogBookFormError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [editIsSubmit, setEditIsSubmit] = useState(false);

  const [popup, setPopup] = useState(false);
  const togglePopup = () => {
    setPopup(!popup);
  };

  const [editPop, setEditPop] = useState(false);
  const toggleEditPopup = () => {
    setEditPop(!editPop);
  };

  const [classList, setClassList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [teacherList, setTeacherList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);

  const [selectClass, setSelectClass] = useState("");
  const [selectSection, setSelectSection] = useState("");
  const [selectTeacher, setSelectTeacher] = useState("");
  const [selectSubject, setSelectSubject] = useState("");

  const [section, setSection] = useState([]);
  const [selectedOptionsOne, setSelectedOptionsOne] = useState([]);

  const [todaysDate, setTodaysDate] = useState("");

  useEffect(() => {
    getClassList();
  }, []);

  const getClassList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      DFlag: "C",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setClassList(postResult);
      } else {
        setClassList([]);
      }
    });
  };

  useEffect(() => {
    getSectionList();
  }, [selectClass]);

  const getSectionList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      ClassID: selectClass,
      DFlag: "S",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setSectionList(postResult);
      } else {
        setSectionList([]);
      }
    });
  };

  //API to hit Teacher list

  useEffect(() => {
    teachList();
  }, [selectSection, selectClass]);

  const teachList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      DFlag: "TL",
      Flag: "S",
      ClassID: selectClass,
      SectionID: selectSection,
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setTeacherList(postResult);
      } else {
        setTeacherList([]);
      }
    });
  };

  //API to hit Subject List

  useEffect(() => {
    getSubjectList();
  }, [selectClass, selectTeacher, selectSection]);

  const getSubjectList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : selectTeacher,
      ClassID: selectClass,
      SectionID: selectSection,
      DFlag: "SB",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };
    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";

        setSubjectList(postResult);
      } else {
        setSubjectList([]);
      }
    });
  };

  //API to create log book

  const fetchdata = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "i",
      ClassID: logBookFormValue.class,
      SectionID: logBookFormValue.section,
      SubjectID: logBookFormValue.subject,
      MemID:
        userinfo.UserType === "T"
          ? userinfo.UserID.toString()
          : logBookFormValue.teacher,
      LessonNo: logBookFormValue.lesson,
      PageNo: logBookFormValue.page,
      Lesson: logBookFormValue.lessonTaught,
      AssignmentGiven: logBookFormValue.assignmentGiven,
      Date: logBookFormValue.date,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/log-book`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getLogBookList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };
  // API for log book List
  const [logBookList, setLogBookList] = useState([]);

  useEffect(() => {
    getLogBookList();
  }, [selectClass, selectSubject, selectTeacher, selectSection, todaysDate]);

  const getLogBookList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID.toString() : "-1",
      Flag: "S",
      ClassID: selectClass,
      SectionID: selectSection,
      SubjectID: selectSubject,
      MemID:
        userinfo.UserType === "T" ? userinfo.UserID.toString() : selectTeacher,
      Date: todaysDate.todayDate,
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/log-book`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.AttenLst ? result.AttenLst : "";
        setLoading(false);
        setLogBookList(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        setLogBookList([]);
        setOriginalList([]);
      }
    });
  };

  ///// FOR EDIT

  const [logId, setLogId] = useState("");

  const handleEdit = (data) => {
    setEditPop(!editPop);

    setLogId(data.LessonID);
    setlogBookFormValue({
      class: data.ClassID,
      section: data.SectionID,
      subject: data.SubjectID,
      teacher: data.MemID,
      lesson: data.LessonNo,
      page: data.PageNo,
      lessonTaught: data.Lesson,
      assignmentGiven: data.AssignmentGiven,
      date: data.CreatedDate,
    });
    setEditIsSubmit(false);
  };

  const editdata = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "U",
      ClassID: logBookFormValue.class.toString(),
      SectionID: logBookFormValue.section.toString(),
      SubjectID: logBookFormValue.subject.toString(),
      MemID:
        userinfo.UserType === "T"
          ? userinfo.UserID.toString()
          : logBookFormValue.teacher.toString(),
      LessonID: logId.toString(),
      LessonNo: logBookFormValue.lesson.toString(),
      PageNo: logBookFormValue.page.toString(),
      Lesson: logBookFormValue.lessonTaught,
      AssignmentGiven: logBookFormValue.assignmentGiven,
      Date: logBookFormValue.date,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/log-book`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getLogBookList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };

  // //Everything to pop up and delete the notification list
  const [deletePopup, setDeletePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const [delId, setDelId] = useState("");
  const [classID, setClassID] = useState("");
  const [sectionID, setSectionID] = useState("");
  const [subjectID, setSubjectID] = useState("");
  const [memID, setMemID] = useState("");
  const [date, setDate] = useState("");

  const handleDelete = async (datas) => {
    setDeletePopup({
      show: true,
    });
    setDelId(datas.LessonID);
    setClassID(datas.ClassID);
    setSectionID(datas.SectionID);
    setSubjectID(datas.SubjectID);
    setMemID(datas.MemID);
    setDate(datas.CreatedDate);
  };

  // This will perform the deletion and hide the Confirmation Box

  const handleDeleteTrue = async () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "R",
      ClassID: classID.toString(),
      SectionID: sectionID.toString(),
      SubjectID: subjectID.toString(),
      MemID: memID.toString(),
      LessonID: delId.toString(),
      Date: date,
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/log-book`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          getLogBookList();
          toast(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
        } else {
          toast(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
    });
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleDeleteFalse = () => {
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // Ends Here

  return (
    <LogBookContext.Provider
      value={{
        loading,
        originalList,
        deletePopup,
        handleDeleteTrue,
        handleDeleteFalse,
        handleDelete,
        popup,
        setPopup,
        togglePopup,
        logBookList,
        setLogBookList,
        logBookFormValue,
        setlogBookFormValue,
        logBookFormError,
        setlogBookFormError,
        isSubmit,
        logBookValue,
        setIsSubmit,
        classList,
        subjectList,
        sectionList,
        teacherList,
        selectClass,
        setSelectClass,
        selectSection,
        setSelectSection,
        selectTeacher,
        setSelectTeacher,
        selectSubject,
        setSelectSubject,

        closeChecked,
        setCloseChecked,

        fetchdata,
        editPop,
        setEditPop,
        toggleEditPopup,
        editIsSubmit,
        setEditIsSubmit,
        handleEdit,
        editdata,

        selectedOptionsOne,
        setSelectedOptionsOne,
        section,
        setSection,

        todaysDate,
        setTodaysDate,
      }}
    >
      {props.children}
    </LogBookContext.Provider>
  );
}

export default LogBookState;
