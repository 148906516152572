import React, { useContext, useEffect, useState } from "react";
import { Fetchdata } from "../../hooks/getData";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { toast } from "react-toastify";
import ExtraActMonthContext from "./ExtraActMonthContext";

function ExtraActMonthState(props) {
  const { appURL, currentYear } = useContext(SidebarContext);

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState("");

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptionsOne, setSelectedOptionsOne] = useState([]);
  const [isMsubmit, setMisSubmit] = useState(false);

  const [classList, setClassList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [teacherList, setTeacherList] = useState([]);
  const [extraList, setExtraList] = useState([]);

  const [selectClass, setSelectClass] = useState("");
  const [selectTeacher, setSelectTeacher] = useState("");
  const [selectMonth, setSelectMonth] = useState("");
  const [selectExtra, setSelectExtra] = useState("");

  const [markList, setMarkList] = useState([]);

  useEffect(() => {
    getClassList();
  }, []);

  const getClassList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      DFlag: "C",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setClassList(postResult);
      } else {
        setClassList([]);
      }
    });
  };

  useEffect(() => {
    getSectionList();
  }, [selectClass]);

  const getSectionList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      ClassID: selectClass,
      DFlag: "S",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setSectionList(postResult);
      } else {
        setSectionList([]);
      }
    });
  };

  //API to hit Teacher list

  useEffect(() => {
    teachList();
  }, [selectClass, selectedOptionsOne]);

  const teachList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      DFlag: "TL",
      Flag: "S",
      ClassID: selectClass,
      SectionID: selectedOptionsOne,
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setTeacherList(postResult);
      } else {
        setTeacherList([]);
      }
    });
  };

  //API to hit extra activity  for dropdown list

  useEffect(() => {
    getExtraList();
  }, []);

  const getExtraList = () => {
    const params = {
      SchID: userinfo.SchID,
      Flag: "SC",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/act-input`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.ExtraActLst ? result.ExtraActLst : "";

        setLoading(false);
        setExtraList(postResult);
      } else {
        setExtraList([]);
      }
    });
  };

  const [mList, setMlist] = useState([]);

  /* API to list extra activity */

  const getExtraActivityList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : selectTeacher,
      Flag: "S",
      ClassID: selectClass,

      SectionID: [
        {
          SectionID: selectedOptionsOne,
        },
      ],
      MonthID: currentYear + "/" + selectMonth,
      ExtraActID: selectExtra,
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/monthly-act-input`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.ExtraActLst ? result.ExtraActLst : "";
        setLoading(false);
        setMlist(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        setMlist([]);
        setOriginalList("");
      }
    });
  };

  useEffect(() => {
    getExtraActivityList();
  }, [
    selectClass,
    selectedOptions,
    selectExtra,
    selectMonth,
    selectTeacher,
    selectedOptionsOne,
  ]);

  /*API to hit extra activity list */

  const fetchMExtradata = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "U",
      MonthID: currentYear + "/" + selectMonth,
      Param: markList,
      ExtraActID: selectExtra,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/monthly-act-input`,
      Type: "POST",
    };
    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getExtraActivityList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };

  /*API to hit extra activity list ENDS*/

  return (
    <ExtraActMonthContext.Provider
      value={{
        loading,
        setLoading,
        originalList,
        setOriginalList,

        selectedOptions,
        setSelectedOptions,
        selectedOptionsOne,
        setSelectedOptionsOne,
        isMsubmit,
        setMisSubmit,

        classList,
        setClassList,
        sectionList,
        setSectionList,
        teacherList,
        setTeacherList,
        extraList,
        setExtraList,

        selectClass,
        setSelectClass,
        selectTeacher,
        setSelectTeacher,
        selectMonth,
        setSelectMonth,
        selectExtra,
        setSelectExtra,
        fetchMExtradata,
        mList,
        setMlist,
        markList,
        setMarkList,
        getExtraActivityList,
      }}
    >
      {props.children}
    </ExtraActMonthContext.Provider>
  );
}

export default ExtraActMonthState;
