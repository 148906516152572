import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";
import { showImgPreview } from "../hooks/ImagePreview";
import DeletePop from "./DeletePop";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { GetNepaliDate } from "../hooks/dateConvertor";
import ViewHelpDesk from "./ViewHelpDesk";
import HelpDeskForm from "./HelpDeskForm";
import HelpDeskContext from "../contextfolder/HelpDeskContext/HelpDeskContext";
import AcademicHeader from "../AcademicHeader";

export default function HelpDeskList() {
  const { todayDate, customStylesForImage, AcademicYear } =
    useContext(SidebarContext);
  const {
    loading,
    originalList,
    popup,
    togglePopup,
    setIsSubmit,
    setFormValue,
    setFormError,
    helpDeskValue,
    helpDeskList,
    setHelpDeskList,
    deletePopup,
    handleDeleteTrue,
    handleDeleteFalse,
    handleDelete,
    setCloseChecked,
    toggleViewPopup,
    handleView,
    viewHelpDesk,
    viewPop,
    setImage,
    setIsUploaded,
  } = useContext(HelpDeskContext);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const searchInput = useRef("");

  const columns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "Ticket No",
      width: "110px",
      selector: (row) => row.TicketNo,
    },
    {
      name: "Subject",
      minWidth: "300px",
      selector: (row) => row.Subject,
    },
    {
      name: "Priority",
      width: "110px",
      selector: (row) =>
        row.Priority === "M"
          ? "Medium"
          : row.Priority === "H"
          ? "High"
          : row.Priority === "L"
          ? "Low"
          : "Urgent",
    },
    {
      name: "Status",
      width: "110px",
      selector: (row) =>
        row.Status === "P"
          ? "Pending"
          : row.Status === "A"
          ? "Approved"
          : row.Status === "R"
          ? "Rejected"
          : row.Status,
    },
    {
      name: "Due Date",
      width: "120px",
      selector: (row) => row.DueDate,
    },
    {
      name: "Created Date",
      width: "180px",
      selector: (row) => row.CreatedDate,
    },

    {
      name: "Action",
      // grow: 0,
      center: true,
      width: "80px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <Tippy content="View">
                <i
                  className="bi bi-eye  viewspan--action"
                  onClick={() => handleView(row)}
                ></i>
              </Tippy>

              {/* <Tippy content="Delete">
                <i
                  className="fa fa-trash-o rejectspan--action"
                  onClick={() => handleDelete(row)}
                ></i>
              </Tippy> */}
            </div>
          </>
        );
      },
    },
  ];

  const closePopup = (e) => {
    e.preventDefault();
    togglePopup();
    setFormValue(helpDeskValue);
    setFormError({});
    setCloseChecked(false);
    setIsSubmit(false);
    setImage("");
    setIsUploaded(false);
  };

  const addnew = (e) => {
    e.preventDefault();
    togglePopup();
    setIsSubmit(false);
    setFormValue(helpDeskValue);
  };

  const closeViewPopup = (e) => {
    e.preventDefault();
    toggleViewPopup();
  };

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["FullName"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setHelpDeskList(srchResult);
      } else {
        setHelpDeskList({});
      }
    } else {
      setHelpDeskList(originalList);
    }
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
        <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">Help Desk</div>
            <div className="page-date">
              <div className="sec-content">
                <AcademicHeader
                  todayDate={todayDate}
                  AcademicYear={AcademicYear}
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <>
          <div className="sec-dataTable content-section main-content">
            <div className="upper-dataTbl">
              <div className="btn-addlnote">
                <button
                  type="button"
                  class="btn btn-sm"
                  style={{
                    background: "var(--MainColor)",
                    color: "white",
                  }}
                  onClick={addnew}
                >
                  Issue Ticket
                </button>
              </div>
            </div>
            <hr className="new-hr-new" />
            {loading ? (
              <Spinner />
            ) : (
              <DataTable
                columns={columns}
                data={helpDeskList}
                customStyles={customStylesForImage}
                pagination
                fixedHeader
                fixedHeaderScrollHeight="280px"
                highlightOnHover
                pointerOnHover
                responsive
                dense
                striped
                // subHeader
                // subHeaderComponent={
                //   <>
                //     <div
                //       className="col-lg-2 offset-lg-10 offset-md-10 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
                //       style={{ paddingRight: "1px", marginBottom: "10px" }}
                //     >
                //       <div className="d-flex">
                //         <input
                //           placeholder="Search"
                //           ref={searchInput}
                //           type="text"
                //           className="form-control form-control-sm searchField"
                //           onChange={searchHandler}
                //         />
                //       </div>
                //     </div>
                //   </>
                // }
              />
            )}
          </div>
        </>
      </div>

      {popup && (
        <div className="modals">
          <div className="overlay"></div>

          <div className="notification-model-content">
            <div className="popUpHeader ">
              <div className="popUpHeaderText ">Help Desk</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closePopup}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="inside-modal">
              <HelpDeskForm closePopup={closePopup} />
            </div>
          </div>
        </div>
      )}

      {viewPop && (
        <div className="modals">
          <div className="overlay"></div>

          <div className="notification-model-content helpdesk__content">
            <div className="popUpHeader ">
              <div className="popUpHeaderText ">View Help Desk</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closeViewPopup}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="inside-modal">
              <ViewHelpDesk
                viewHelpDesk={viewHelpDesk}
                closeViewPopup={closeViewPopup}
              />
            </div>
          </div>
        </div>
      )}

      {deletePopup.show && (
        <DeletePop
          handleDeleteTrue={handleDeleteTrue}
          handleDeleteFalse={handleDeleteFalse}
        />
      )}
    </>
  );
}
