import React, { useContext, useEffect, useState } from "react";
import { Fetchdata } from "../../hooks/getData";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { toast } from "react-toastify";
import { GetEnglishDate } from "../../hooks/dateConvertor";
import StudentAssignmentContext from "./StudentAssignmentContext";

function StudentAssignmentState(props) {
  const { appURL, todayDate } = useContext(SidebarContext);

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const [closeChecked, setCloseChecked] = useState(false);

  const [viewPop, setViewPop] = useState(false);
  const toggleViewPopup = () => {
    setViewPop(!viewPop);
  };

  const [isUploaded, setIsUploaded] = useState(false);
  const [typeFile, setTypeFile] = useState("");
  const [image, setImage] = useState("");

  const [subjectList, setSubjectList] = useState([]);
  const [selectSubject, setSelectSubject] = useState("");

  const [chooseStatus, setChooseStatus] = useState("-1");

  // For multiple image upload
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedBase64, setSelectedBase64] = useState([]);

  //States for view assignmentsubmit student file
  const [newState, setNewState] = useState([]);
  const [option, setoption] = useState([]);

  //States for view assignmentsubmit student file ends

  //API to hit Subject List

  useEffect(() => {
    getSubjectList();
  }, []);

  const getSubjectList = () => {
    const params = {
      SchID: userinfo.SchID,
      ClassID: userinfo.ClassID.toString(),
      DFlag: "SUBJECT",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/ref-values`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";

        setSubjectList(postResult);
      } else {
        setSubjectList([]);
      }
    });
  };

  // API for assignment List
  const [assignmentList, setAssignmentList] = useState([]);

  useEffect(() => {
    getAssignmentList();
  }, [selectSubject, chooseStatus]);

  const getAssignmentList = () => {
    const params = {
      SchID: userinfo.SchID,
      MemID: userinfo.UserID.toString(),
      Flag: "S",
      SubjectID: selectSubject,
      AssignStatus: chooseStatus,
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/assignment`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.AssignmentLst ? result.AssignmentLst : "";
        setLoading(false);
        setAssignmentList(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        setAssignmentList([]);
        setOriginalList([]);
      }
    });
  };

  // For View Assignments

  const remarksvalue = {
    comment: "",
  };
  const [remarksFormValue, setRemarksFormValue] = useState(remarksvalue);
  const [remarksFormError, setRemarksFormError] = useState({});
  const [remarksIsSubmit, setRemarksIsSubmit] = useState(false);
  const [pushNotice, setPushNotice] = useState(false);

  const [state, setState] = useState([]);
  const [aID, setAID] = useState("");

  const [reload, setReload] = useState();

  const viewAssignment = (data) => {
    toggleViewPopup();
    setAID(data);
    viewdata();
    // setRemarksFormValue({
    //     comment: data.MemStatusID,
    // })
    setReload(Math.random());
  };
  useEffect(() => {
    viewdata();
  }, [aID]);

  const viewdata = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      Flag: "SI",
      MemID: userinfo.UserID,
      AssignmentID: aID,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/assignment`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.AssignmentLst[0]
          ? result.AssignmentLst[0]
          : "";

        setState(postResult);
      } else {
        setState([]);
      }
    });
  };

  const viewSubmitData = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      Flag: "SA",
      MemID: userinfo.UserID.toString(),
      AssignmentID: aID,
      Attachment: selectedBase64,
      Comment: remarksFormValue.comment,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/assignment`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getAssignmentList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };

  return (
    <StudentAssignmentContext.Provider
      value={{
        loading,
        originalList,
        setAssignmentList,
        setLoading,
        isUploaded,
        setIsUploaded,
        typeFile,
        setTypeFile,
        image,
        setImage,
        assignmentList,
        closeChecked,
        setCloseChecked,
        subjectList,
        selectSubject,
        setSelectSubject,
        chooseStatus,
        setChooseStatus,

        toggleViewPopup,
        viewPop,
        setViewPop,
        remarksFormValue,
        setRemarksFormValue,
        remarksIsSubmit,
        setRemarksIsSubmit,
        pushNotice,
        setPushNotice,
        remarksvalue,
        state,
        remarksFormError,
        setRemarksFormError,
        viewAssignment,
        viewdata,
        viewSubmitData,

        selectedImages,
        setSelectedImages,
        selectedBase64,
        setSelectedBase64,

        newState,
        setNewState,
        option,
        setoption,
      }}
    >
      {props.children}
    </StudentAssignmentContext.Provider>
  );
}

export default StudentAssignmentState;
