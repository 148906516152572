import React, { useContext, useEffect, useState } from "react";
import ClassRoutineContext from "../../contextfolder/ClassRoutineContext/ClassRoutineContext";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { Fetchdata } from "../../hooks/getData";
import Select from "react-select";

export default function ClassRoutineForm({ closePopup }) {
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const { appURL } = useContext(SidebarContext);

  const context = useContext(ClassRoutineContext);

  const {
    togglePopup,
    classFormError,
    setClassFormError,
    isSubmit,
    setIsSubmit,
    classValue,
    classFormValue,
    setClassFormValue,
    fetchdata,
    closeChecked,
    setCloseChecked,
    day,
    setDay,
  } = context;

  const handleOnChange = () => {
    setCloseChecked(!closeChecked);
  };

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setClassFormValue({ ...classFormValue, [name]: value });
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setClassFormError(validate(classFormValue));
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(classFormError).length === 0 && isSubmit) {
      fetchdata(classFormValue);
      if (closeChecked === true) {
        togglePopup();
      }
      setClassFormValue(classValue);
      setIsSubmit(false);
    }
  }, [classFormError]);

  const validate = (values) => {
    const errors = {};
    if (!values.class) {
      errors.class = "Required";
    }
    if (!values.section) {
      errors.section = "Required";
    }
    // if (!values.day) {
    //     errors.day = "Required";
    // }

    if (!values.fromTime) {
      errors.fromTime = "Required";
    }
    if (!values.period) {
      errors.period = "Required";
    }
    if (!values.toTime) {
      errors.toTime = "Required";
    }
    if (classFormValue.breakTime === "N") {
      if (!values.subject) {
        errors.subject = "Required";
      }
      if (!values.teacher) {
        errors.teacher = "Required";
      }
      // if (!values.roomNo) {
      //     errors.roomNo = "Required";
      // }
    }

    return errors;
  };

  const [classList, setClassList] = useState([]);
  const [sectionList, setSectionList] = useState([]);

  //API to hit class list
  useEffect(() => {
    getClassList();
  }, []);

  const getClassList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      DFlag: "C",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setClassList(postResult);
      } else {
        setClassList([]);
      }
    });
  };
  //API to hit section list
  useEffect(() => {
    getSectionList();
  }, [classFormValue.class]);

  const getSectionList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      ClassID: classFormValue.class,
      DFlag: "S",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";

        setSectionList(postResult);
      } else {
        setSectionList([]);
      }
    });
  };

  //API to hit Teacher list
  const [teacherList, setTeacherList] = useState([]);

  useEffect(() => {
    teachList();
  }, [classFormValue.class, classFormValue.section]);

  const teachList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      DFlag: "TL",
      Flag: "S",
      ClassID: classFormValue.class,
      SectionID: classFormValue.section,
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setTeacherList(postResult);
      } else {
        setTeacherList([]);
      }
    });
  };

  //API to hit subject List
  const [subjectList, setSubjectList] = useState([]);

  useEffect(() => {
    getSubjectList();
  }, [classFormValue.class, classFormValue.teacher]);

  const getSubjectList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID:
        userinfo.UserType === "T" ? userinfo.UserID : classFormValue.teacher,
      ClassID: classFormValue.class,
      SectionID: classFormValue.section,
      DFlag: "SB",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";

        setSubjectList(postResult);
      } else {
        setSubjectList([]);
      }
    });
  };
  const dayList = [
    {
      Day: "Sun",
      DayID: "1",
    },
    {
      Day: "Mon",
      DayID: "2",
    },
    {
      Day: "Tue",
      DayID: "3",
    },
    {
      Day: "Wed",
      DayID: "4",
    },
    {
      Day: "Thu",
      DayID: "5",
    },
    {
      Day: "Fri",
      DayID: "6",
    },
    {
      Day: "Sat",
      DayID: "7",
    },
  ];

  const dropDownValue = dayList.map((item) => ({
    value: item.DayID,
    label: item.Day,
  }));

  return (
    <>
      <div className="container-fluid basicform">
        <form>
          <div className="form-row">
            {/* class*/}
            <div className="form-group col-md-6">
              <label className="form-label" htmlFor="class">
                Class <sup style={{ color: "red" }}>*</sup>
              </label>
              <select
                id="class"
                name="class"
                onChange={handleChange}
                value={classFormValue.class}
                className="form-select form-select-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="" selected disabled>
                  Select Option
                </option>
                {classList.map((item, i) => (
                  <option key={i} value={item.ClassID}>
                    {item.ClassName}
                  </option>
                ))}
              </select>
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {classFormError.class}
              </p>
            </div>
            {/* section */}
            <div className="form-group col-md-6">
              <label className="form-label" htmlFor="section">
                Section <sup style={{ color: "red" }}>*</sup>
              </label>
              <select
                id="section"
                name="section"
                onChange={handleChange}
                value={classFormValue.section}
                className="form-select form-select-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="" selected disabled>
                  Select Option
                </option>
                {sectionList.map((item, i) => (
                  <option key={i} value={item.SectionID}>
                    {item.SectionName}
                  </option>
                ))}
              </select>
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {classFormError.section}
              </p>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-6">
              <label className="form-label" htmlFor="day">
                Day <sup style={{ color: "red" }}>*</sup>
              </label>
              {/* <select id="day" name="day"
                                onChange={handleChange}
                                value={classFormValue.day} className="form-select form-select-sm mb-1" style={{ fontSize: '12px' }}>
                                <option value="" selected disabled>Select Option</option>
                                <option value="1">Sunday</option>
                                <option value="2">Monday</option>
                                <option value="3">Tuesday</option>
                                <option value="4">Wednesday</option>
                                <option value="5">Thursday</option>
                                <option value="6">Friday</option>
                                <option value="7">Saturday</option>
                            </select>
                            <p className="errormsg " style={{ marginBottom: "0" }}>{classFormError.day}</p> */}
              <Select
                className="select"
                style={{ fontSize: "11px", marginBottom: "3px" }}
                isMulti
                options={dropDownValue}
                onChange={(item) =>
                  setDay(
                    item.map((item) => ({
                      Day: item.value,
                    }))
                  )
                }
                isSearchable={false}
              />
            </div>

            <div className="form-group col-md-6">
              <label className="form-label" htmlFor="period">
                Period <sup style={{ color: "red" }}>*</sup>
              </label>
              <select
                id="period"
                name="period"
                onChange={handleChange}
                value={classFormValue.period}
                className="form-select form-select-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="" selected disabled>
                  Select Option
                </option>
                <option value="1st">1st</option>
                <option value="2nd">2nd</option>
                <option value="3rd">3rd</option>
                <option value="4th">4th</option>
                <option value="5th">5th</option>
                <option value="6th">6th</option>
                <option value="7th">7th</option>
                <option value="8th">8th</option>
                <option value="9th">9th</option>
                <option value="10th">10th</option>
              </select>
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {classFormError.period}
              </p>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-6">
              <label
                className="form-label"
                htmlFor="fromTime"
                style={{ fontSize: "12px" }}
              >
                From Time<sup style={{ color: "red" }}>*</sup>
              </label>
              <input
                id="fromTime"
                style={{ fontSize: "13px" }}
                type="time"
                className="form-control form-control-sm "
                name="fromTime"
                value={classFormValue.fromTime}
                onChange={handleChange}
              />
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {classFormError.fromTime}
              </p>
            </div>

            <div className="form-group col-md-6">
              <label
                className="form-label"
                htmlFor="toTime"
                style={{ fontSize: "12px" }}
              >
                To Time<sup style={{ color: "red" }}>*</sup>
              </label>
              <input
                id="toTime"
                style={{ fontSize: "13px" }}
                type="time"
                className="form-control form-control-sm "
                name="toTime"
                value={classFormValue.toTime}
                onChange={handleChange}
              />
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {classFormError.toTime}
              </p>
            </div>
          </div>

          <div className="form-group">
            <div>
              <label className="form-label" htmlFor="purpose">
                Period
              </label>
            </div>
            <div>
              <input
                type="radio"
                className="radio--button"
                name="breakTime"
                id="normal"
                onChange={handleChange}
                value="N"
                checked={classFormValue.breakTime === "N"}
              />
              <label
                className="label-radio-routine"
                htmlFor="normal"
                style={{ marginRight: "10px" }}
              >
                Normal Period
              </label>

              <input
                type="radio"
                className="radio--button"
                name="breakTime"
                id="long"
                onChange={handleChange}
                value="L"
                checked={classFormValue.breakTime === "L"}
              />
              <label
                className="label-radio-routine"
                htmlFor="long"
                style={{ marginRight: "10px" }}
              >
                Long Break
              </label>

              <input
                type="radio"
                className="radio--button"
                name="breakTime"
                id="short"
                onChange={handleChange}
                value="S"
                checked={classFormValue.breakTime === "S"}
              />
              <label
                className="label-radio-routine"
                htmlFor="short"
                style={{ marginRight: "10px" }}
              >
                Short Break
              </label>

              <input
                type="radio"
                className="radio--button"
                name="breakTime"
                id="eca"
                onChange={handleChange}
                value="E"
                checked={classFormValue.breakTime === "E"}
              />
              <label className="label-radio-routine" htmlFor="eca">
                ECA
              </label>
            </div>
            {/* <p className="errormsg ">{remarksFormError.complainStatus}</p> */}
          </div>

          {classFormValue.breakTime === "N" && (
            <div className="form-row">
              <div className="form-group col-md-2">
                <label
                  className="form-label"
                  htmlFor="roomNo"
                  style={{ fontSize: "12px" }}
                >
                  Room No.
                </label>
                <input
                  id="roomNo"
                  style={{ fontSize: "13px" }}
                  type="text"
                  className="form-control form-control-sm "
                  name="roomNo"
                  value={classFormValue.roomNo}
                  onChange={handleChange}
                />
                <p className="errormsg " style={{ marginBottom: "0" }}>
                  {classFormError.roomNo}
                </p>
              </div>

              <div className="form-group col-md-5">
                <label className="form-label" htmlFor="teach">
                  Teacher<sup style={{ color: "red" }}>*</sup>
                </label>
                <select
                  id="teach"
                  name="teacher"
                  onChange={handleChange}
                  value={classFormValue.teacher}
                  className="form-select form-select-sm mb-1"
                  style={{ fontSize: "12px" }}
                >
                  <option value="" selected disabled>
                    Select Option
                  </option>
                  {teacherList.map((item) => (
                    <option key={item.UserID} value={item.UserID}>
                      {item.FullName}
                    </option>
                  ))}
                </select>
                <p className="errormsg " style={{ marginBottom: "0" }}>
                  {classFormError.teacher}
                </p>
              </div>

              <div className="form-group col-md-5">
                <label className="form-label" htmlFor="subject">
                  Subject<sup style={{ color: "red" }}>*</sup>
                </label>
                <select
                  id="subject"
                  name="subject"
                  onChange={handleChange}
                  value={classFormValue.subject}
                  className="form-select form-select-sm mb-1"
                  style={{ fontSize: "12px" }}
                >
                  <option value="" selected disabled>
                    Select Option
                  </option>
                  {subjectList.map((item, i) => (
                    <option key={i} value={item.SubjectID}>
                      {item.SubjectName}
                    </option>
                  ))}
                </select>
                <p className="errormsg" style={{ marginBottom: "0" }}>
                  {classFormError.subject}
                </p>
              </div>
            </div>
          )}
        </form>
      </div>
      <div className="FormFooter">
        <div className="checkedClose">
          <input
            type="checkbox"
            className="form-check-input"
            id="exampleCheck2"
            name="closeChecked"
            onChange={handleOnChange}
            checked={closeChecked}
          />
          <label class="form-check-label" for="exampleCheck2">
            Exit after submit
          </label>
        </div>
        <div>
          <button
            className="btn btn-sm btn-cmpy ml-2"
            style={{ background: "green" }}
            onClick={formSubmit}
          >
            Submit
          </button>
          <button className="btn btn-sm btn-cmpy ml-2" onClick={closePopup}>
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}
