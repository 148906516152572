import React, { useContext, useEffect, useState } from "react";
import { FetchJobdata } from "../../hooks/getJobData";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { toast } from "react-toastify";
import CandidateContext from "./CandidateContext";
import EliteJobContext from "../EliteJobContext/EliteJobContext";

function CandidateState(props) {
  const { jobURL } = useContext(SidebarContext);

  const {getJobList} =useContext(EliteJobContext)

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);

  const [chooseJob, setChooseJob] = useState("-1");
  const [chooseStatus, setChooseStatus] = useState("-1");
  const [chooseVia, setChooseVia] = useState("-1");

  // for CV
  const [isCVUploaded, setIsCVUploaded] = useState(false);
  const [typeCVFile, setTypeCVFile] = useState();
  const [cvFile, setCVFile] = useState("");
  const cvExt = cvFile.split(".").pop();

  // for COVER LETTER
  const [isCoverUploaded, setIsCoverUploaded] = useState(false);
  const [typeCoverFile, setTypeCoverFile] = useState();
  const [coverFile, setCoverFile] = useState("");
  const coverExt = coverFile.split(".").pop();
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const [closeChecked, setCloseChecked] = useState(false);

  const candidateValue = {
    name: "",
    contactno: "",
    email: "",
    message: "",
    cvtype: "",
    cv: "",
    covertype: "",
    cover: "",
    via: "",
    job: "",
    jobStatus: "",
    cvRadio: "cvFile",
    coverRadio: "coverFile",
    via: "",
  };

  const [candidateFormValue, setCandidateFormValue] = useState(candidateValue);
  const [candidateFormError, setCandidateFormError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const [popup, setPopup] = useState(false);
  const togglePopup = () => {
    setPopup(!popup);
  };

  const [viewPop, setViewPop] = useState(false);
  const toggleViewPopup = () => {
    setViewPop(!viewPop);
  };
  //API to create log book

  const fetchdata = () => {
    const dataForm = {
      ComID: userinfo.SchID,
      Flag: "i",
      UserID: userinfo.UserID.toString(),
      MemID: userinfo.UserID.toString(),
      JobID: tableData.JobID ? tableData.JobID.toString() : "",
      // JobID: candidateFormValue.job,
      // JobStatus: candidateFormValue.jobStatus,
      Name: candidateFormValue.name,
      Contact: candidateFormValue.contactno,
      Email: candidateFormValue.email,
      Message: candidateFormValue.message,
      CVType: candidateFormValue.cvRadio === "cvUrl" ? "U" : "F",
      CV:
        candidateFormValue.cvRadio === "cvFile" && typeCVFile !== null
          ? typeCVFile.split(",")[1]
          : candidateFormValue.cv,

      Cover:
        candidateFormValue.coverRadio === "coverFile" && typeCoverFile !== null
          ? typeCoverFile.split(",")[1]
          : candidateFormValue.cover,

      CoverType: coverFile ? "F" : "",
      // CoverType: candidateFormValue.coverRadio === "coverUrl" ? "U" : "F",
      Via: "M",
      FetchURL: `${jobURL}api/admin/job-candidate`,
      Type: "POST",
    };

    FetchJobdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getCandidateList();
        getJobList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };
  // API for log book List
  const [candidateList, setCandidateList] = useState([]);
  const [tableData, setTableData] = useState("");
  useEffect(() => {
    getCandidateList();
  }, [tableData]);

  const getCandidateList = () => {
    const params = {
      ComID: userinfo.SchID,
      Flag: "S",
      UserID: userinfo.UserID.toString(),
      MemID: "-1",
      JobID: tableData.JobID && tableData.JobID.toString(),
      // JobID: chooseJob.toString(),
      // JobID: "-1",
      JobStatus: "-1",
      Via: "-1",
      // JobStatus: chooseStatus,
      // Via: chooseVia,
      Type: "POST",
      FetchURL: `${jobURL}api/admin/job-candidate`,
    };

    FetchJobdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setLoading(false);
        setCandidateList(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        setCandidateList([]);
        setOriginalList([]);
      }
    });
  };

  ///// FOR View
  const [viewId, setViewId] = useState("");
  const handleView = (data) => {
    toggleViewPopup();
    setViewId(data.CandidateID);
  };

  const [viewCandidate, setViewCandidate] = useState([]);

  useEffect(() => {
    viewCandidateList();
  }, [viewId]);

  const viewCandidateList = () => {
    const params = {
      ComID: userinfo.SchID,
      Flag: "SI",
      UserID: userinfo.UserID.toString(),
      CandidateID: viewId.toString(),
      Type: "POST",
      FetchURL: `${jobURL}api/admin/job-candidate`,
    };

    FetchJobdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values[0] : "";
        setViewCandidate(postResult);
      } else {
        setViewCandidate([]);
      }
    });
  };

  // //Everything to pop up and delete the notification list
  const [deletePopup, setDeletePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const [delId, setDelId] = useState("");

  const handleDelete = async (datas) => {
    setDeletePopup({
      show: true,
    });
    setDelId(datas.CandidateID);
  };

  // This will perform the deletion and hide the Confirmation Box

  const handleDeleteTrue = async () => {
    const dataForm = {
      ComID: userinfo.SchID,
      Flag: "R",
      UserID: userinfo.UserID.toString(),
      CandidateID: delId.toString(),
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", Signature: "p0m76" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${jobURL}api/admin/job-candidate`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          getCandidateList();
          getJobList();
          toast(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
        } else {
          toast(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
    });
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleDeleteFalse = () => {
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // Ends Here

  return (
    <CandidateContext.Provider
      value={{
        loading,
        originalList,
        deletePopup,
        handleDeleteTrue,
        handleDeleteFalse,
        handleDelete,
        popup,
        setPopup,
        togglePopup,
        candidateList,
        setCandidateList,
        candidateFormValue,
        setCandidateFormValue,
        candidateFormError,
        setCandidateFormError,
        isSubmit,
        candidateValue,
        setIsSubmit,

        closeChecked,
        setCloseChecked,

        fetchdata,
        viewPop,
        setViewPop,
        toggleViewPopup,

        isCVUploaded,
        setIsCVUploaded,
        setTypeCVFile,
        typeCVFile,
        cvFile,
        setCVFile,

        isCoverUploaded,
        setIsCoverUploaded,
        setTypeCoverFile,
        typeCoverFile,
        coverFile,
        setCoverFile,
        handleView,

        viewCandidate,
        setViewCandidate,

        chooseJob,
        chooseStatus,
        chooseVia,
        setChooseJob,
        setChooseStatus,
        setChooseVia,
        tableData,
        setTableData,
      }}
    >
      {props.children}
    </CandidateContext.Provider>
  );
}

export default CandidateState;
