import React from "react";
import landinglogo from "./../../images/landinglogo.png";
import { FaLocationArrow, FaPhoneAlt } from "react-icons/fa";
import { MdLocationPin } from "react-icons/md";
import { useState } from "react";
import SchLogo from "./../../images/logoOfSchool.png";

const Footer = ({ schoolInfo }) => {
  const [imageExists, setImageExists] = useState(true);
  const handleImageError = () => {
    setImageExists(false);
  };
  return (
    <footer
      id="footer"
      className="uk-position-relative"
      style={{ background: "#fff" }}
    >
      <div
        class="footer-top"
        style={{ background: "#fff", position: "relative", zIndex: "1" }}
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-md-6 footer-contact" data-aos="fade-up">
              <div className="uk-flex uk-flex-middle" style={{ flexGrow: "1" }}>
                <a href="#" className="logo me-2">
                  {imageExists ? (
                    <img
                      style={{ objectFit: "contain", width: "50px" }}
                      src={schoolInfo.SchLogo}
                      onError={handleImageError}
                      className="img-fluid"
                      alt="Logo"
                    />
                  ) : (
                    <img
                      style={{
                        background: `${schoolInfo.ScColor}`,
                        objectFit: "contain",
                        width: "50px",
                      }}
                      src={SchLogo}
                      alt="Fallback Logo"
                    />
                  )}
                </a>
                <div>
                  <h4 className="fs-5 m-0">{schoolInfo.SchName}</h4>
                </div>
              </div>
              <p
                style={{
                  color: "#555",
                }}
              >
                {schoolInfo.AboutUs}
              </p>
              <div class="social-links mt-3">
                <a
                  target="_blank"
                  style={{
                    background: `${schoolInfo.ScColor}`,
                    color: "#fff",
                  }}
                  href={schoolInfo.SchTwitter}
                  class="twitter"
                >
                  <i class="bi bi-twitter"></i>
                </a>
                <a
                  target="_blank"
                  style={{
                    background: `${schoolInfo.ScColor}`,
                    color: "#fff",
                  }}
                  href={schoolInfo.SchFb}
                  class="facebook"
                >
                  <i class="bi bi-facebook"></i>
                </a>
                <a
                  target="_blank"
                  style={{
                    background: `${schoolInfo.ScColor}`,
                    color: "#fff",
                  }}
                  href={schoolInfo.SchYt}
                  class="instagram"
                >
                  <i class="bi bi-youtube"></i>
                </a>
              </div>
            </div>

            <div
              class="col-lg-4 col-md-6 footer-links"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <h4>Get In Touch</h4>
              <div className="footer__contact">
                {schoolInfo.SchAddress && (
                  <div>
                    <div className="footer__icon">
                      <MdLocationPin
                        style={{
                          color: `${schoolInfo.ScColor}`,
                        }}
                      />
                    </div>
                    <div className="footer__txt">
                      <strong>Location</strong>
                      <div>
                        <span>{schoolInfo.SchAddress}</span>
                      </div>
                    </div>
                  </div>
                )}

                {schoolInfo.SchTel && (
                  <div>
                    <div className="footer__icon">
                      <FaPhoneAlt
                        style={{
                          color: `${schoolInfo.ScColor}`,
                        }}
                      />
                    </div>
                    <div className="footer__txt">
                      <strong>Phone</strong>
                      <div>
                        <span>
                          <a href={`tel:${schoolInfo.SchTel}`}>
                            {schoolInfo.SchTel}
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div
              class="col-lg-4 col-md-6 footer-links"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <h4>Quick Links</h4>
              <ul>
                <li>
                  <i
                    style={{
                      color: `${schoolInfo.ScColor}`,
                    }}
                    class="bi bi-chevron-right"
                  ></i>{" "}
                  <a
                    style={{
                      color: "#444",
                    }}
                    href="#about__section"
                  >
                    About
                  </a>
                </li>
                <li>
                  <i
                    style={{
                      color: `${schoolInfo.ScColor}`,
                    }}
                    class="bi bi-chevron-right"
                  ></i>
                  <a
                    style={{
                      color: "#444",
                    }}
                    href="#blog__section"
                  >
                    Blog
                  </a>
                </li>
                <li>
                  <i
                    style={{
                      color: `${schoolInfo.ScColor}`,
                    }}
                    class="bi bi-chevron-right"
                  ></i>{" "}
                  <a
                    style={{
                      color: "#444",
                    }}
                    href="#event__section"
                  >
                    Events
                  </a>
                </li>
                <li>
                  <i
                    style={{
                      color: `${schoolInfo.ScColor}`,
                    }}
                    class="bi bi-chevron-right"
                  ></i>{" "}
                  <a
                    style={{
                      color: "#444",
                    }}
                    href="#album__section"
                  >
                    Gallery
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="container py-4 uk-flex uk-flex-between uk-flex-wrap uk-flex-middle">
        <div class="copyright">
          &copy; Copyright{" "}
          <strong>
            <span> {schoolInfo.SchName}</span>
          </strong>
          . All Rights Reserved
        </div>

        <div>
          Designed by{" "}
          <strong>
            <a target="_blank" href="https://easysoftware.com.np/">
              {" "}
              <span
                style={{
                  color: `${schoolInfo.ScColor}`,
                }}
              >
                Easy Software
              </span>
            </a>
          </strong>
          .
        </div>
      </div>
      <div
        className="my__new__overlay"
        style={{
          background: `${schoolInfo.ScColor}`,
          opacity: 0.07,
        }}
      ></div>
    </footer>
  );
};

export default Footer;
