import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import BlogContext from "../contextfolder/BlogContext/BlogContext";
import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";
import BlogEdit from "./BlogEdit";
import BlogForm from "./BlogForm";
import DeletePop from "./DeletePop";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import AcademicHeader from "../AcademicHeader";

export default function BlogList() {
  const { todayDate, customStyles, AcademicYear } = useContext(SidebarContext);
  const {
    loading,
    originalList,
    popup,
    togglePopup,
    setIsSubmit,
    setBlogFormValue,
    setBlogFormError,
    blogValue,
    blogList,
    setBlogList,
    setValue,
    setPublish,
    setImage,
    setIsUploaded,
    handleEdit,
    editPop,
    toggleEditPopup,
    setEditIsSubmit,
    setCloseChecked,
    deletePopup,
    handleDeleteTrue,
    handleDeleteFalse,
    handleDelete,
  } = useContext(BlogContext);

  const searchInput = useRef("");

  const columns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "Title",
      selector: (row) => row.Title,
    },
    {
      name: "Excerpt",
      selector: (row) => row.Excerpt,
    },
    {
      name: "Is Published",
      width: "120px",
      selector: (row) =>
        row.IsPublished === 1 ? "Published" : "Not Published",
    },
    {
      name: "Post View",
      width: "100px",
      selector: (row) => row.PostView,
    },
    {
      name: "Action",
      // grow: 0,
      center: true,
      width: "100px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <Tippy content="Edit">
                <i
                  className="fa fa-pencil solvespan--action"
                  onClick={() => handleEdit(row)}
                ></i>
              </Tippy>
              <Tippy content="Delete">
                <i
                  className="fa fa-trash-o rejectspan--action"
                  onClick={() => handleDelete(row.BlogID)}
                ></i>
              </Tippy>
            </div>
          </>
        );
      },
    },
  ];

  const closePopup = (e) => {
    e.preventDefault();
    togglePopup();
    setBlogFormValue(blogValue);
    setBlogFormError({});
    setIsSubmit(false);
    setValue("");
    setImage("");
    setIsUploaded(false);
  };

  const closeEditPopup = (e) => {
    e.preventDefault();
    toggleEditPopup();
    setBlogFormError({});
    setEditIsSubmit(false);
    setValue("");
    setImage("");
    setIsUploaded(false);
  };

  const addnew = (e) => {
    e.preventDefault();
    togglePopup();
    setIsSubmit(false);
    setBlogFormValue(blogValue);
    setValue("");
    setImage("");
    setIsUploaded(false);
    setCloseChecked(false);
  };

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["Title"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setBlogList(srchResult);
      } else {
        setBlogList({});
      }
    } else {
      setBlogList(originalList);
    }
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
        <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">Blog</div>
            <div className="page-date">
              <div className="sec-content">
                <AcademicHeader
                  todayDate={todayDate}
                  AcademicYear={AcademicYear}
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <>
          <div className="sec-dataTable content-section main-content">
            <div className="upper-dataTbl">
              <div className="btn-addlnote">
                <button
                  type="button"
                  class="btn btn-sm"
                  style={{
                    background: "var(--MainColor)",
                    color: "white",
                  }}
                  onClick={addnew}
                >
                  Add New
                </button>

                {/* <button
                                    type="button"
                                    class="btn btn-sm"
                                    style={{
                                        background: "var(--MainColor)",
                                        color: "white",
                                    }}
                                    onClick={view}
                                >
                                    View
                                </button> */}
              </div>
            </div>
            <hr className="new-hr" />
            {loading ? (
              <Spinner />
            ) : (
              <DataTable
                columns={columns}
                data={blogList}
                customStyles={customStyles}
                pagination
                fixedHeader
                fixedHeaderScrollHeight="280px"
                highlightOnHover
                pointerOnHover
                responsive
                dense
                striped
                subHeader
                subHeaderAlign="left"
                subHeaderComponent={
                  <>
                    {/* <div className="col-md-2 col-sm-2 col-lg-2">
                                            <label style={{ fontSize: "14px", textAlign: "left", display: "block" }}>Designation</label>
                                            <select
                                                style={{ fontSize: "11px", marginBottom: "3px" }}
                                                name="snotifiaction"
                                                // value={selectSection}
                                                // onChange={handleSection}
                                                className="form-select form-select-sm"
                                                aria-label="Default select example "
                                            >
                                                <option
                                                    value=""
                                                    disabled
                                                    selected
                                                    style={{ fontSize: "11px" }}
                                                >
                                                    Select Designation
                                                </option>

                                               {sectionList.map((item) => (
                                                    <option
                                                        key={item.SectionID}
                                                        value={item.SectionID}
                                                    >
                                                        {item.SectionName}
                                                    </option>
                                                ))} 
                                            </select>
                                        </div> */}

                    <div
                      className="col-lg-2 offset-lg-10 offset-md-10 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
                      style={{ paddingRight: "1px", marginBottom: "10px" }}
                    >
                      <div className="d-flex">
                        <input
                          placeholder="Search"
                          ref={searchInput}
                          type="text"
                          className="form-control form-control-sm searchField"
                          onChange={searchHandler}
                        />
                      </div>
                    </div>
                  </>
                }
              />
            )}
          </div>
        </>
      </div>

      {popup && (
        <div className="modals">
          <div className="overlay"></div>

          <div className="notification-model-content">
            <div className="popUpHeader ">
              <div className="popUpHeaderText ">Blog</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closePopup}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="inside-modal">
              <BlogForm closePopup={closePopup} />
            </div>
          </div>
        </div>
      )}

      {editPop && (
        <div className="modals">
          <div className="overlay"></div>

          <div className="notification-model-content">
            <div className="popUpHeader ">
              <div className="popUpHeaderText ">Blog Edit</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closeEditPopup}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="inside-modal">
              <BlogEdit closeEditPopup={closeEditPopup} />
            </div>
          </div>
        </div>
      )}

      {deletePopup.show && (
        <DeletePop
          handleDeleteTrue={handleDeleteTrue}
          handleDeleteFalse={handleDeleteFalse}
        />
      )}
    </>
  );
}
