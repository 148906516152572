import React, { useContext, useEffect, useState } from 'react'
import SidebarContext from '../sidebarcontext/SidebarContext';
import CloseIcon from "../../images/CloseIcon.svg";
import Plus from "../../images/Plus.png";
import MenuContext from '../contextfolder/MenuContext/MenuContext';
import MenuCategory from './MenuCategory';

export default function MenuPopup({ closePopup }) {

    const { appURL } = useContext(SidebarContext);
    const userinfo = JSON.parse(localStorage.getItem("userInfo"));

    const context = useContext(MenuContext)

    const { menuPopup,
        menuFormValue,
        setMenuFormValue,
        menuFormError,
        setMenuFormError,
        isUploaded,
        setIsUploaded,
        image,
        setTypeFile,
        setImage,
        isAvailable, fetchdata,
        setIsAvailable, setIsSubmit, isSubmit, addCategory, menuCategoryListForOther, setCloseChecked, closeChecked, togglePopup, menuValue, toggleCategoryPopup, setCategoryIsSubmit, categoryValue, setCategoryFormValue, setCategoryFormError, categoryPopup, setDisEdit } = context;

    const handleOnChange = () => {
        setCloseChecked(!closeChecked);
    };

    const handleAvailableChange = (e) => {
        setIsAvailable(!isAvailable);
    };


    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;
        setMenuFormValue({ ...menuFormValue, [name]: value });
    };
    function handleImageChange(e) {
        if (e.target.files && e.target.files[0]) {

            setTypeFile(e.target.files[0].name);

            let reader = new FileReader();

            reader.onload = function (e) {
                setImage(e.target.result);
                setIsUploaded(true);
            };
            reader.readAsDataURL(e.target.files[0]);
        }
    }


    const formSubmit = (e) => {
        e.preventDefault();
        setMenuFormError(validate(menuFormValue));
        setIsSubmit(true);
    }

    useEffect(() => {
        if (Object.keys(menuFormError).length === 0 && isSubmit) {
            fetchdata(menuFormValue)
            if (closeChecked === true) {
                togglePopup();
            }
            setMenuFormValue(menuValue)
            setIsSubmit(false);
            setCloseChecked(false);
            setImage("")
            setIsUploaded(false);
        }

    }, [menuFormError]);

    const validate = (values) => {
        const errors = {};

        if (!values.itemName) {
            errors.itemName = "Required";
        }
        if (!values.category) {
            errors.category = "Required";
        }
        if (!values.type) {
            errors.type = "Required";
        }
        if (!values.price) {
            errors.price = "Required";
        }
        if (!values.unit) {
            errors.unit = "Required";
        }
        if (!values.description) {
            errors.description = "Required";
        }
        return errors;
    };





    const addnew = (e) => {
        e.preventDefault();
        toggleCategoryPopup();
        setCategoryIsSubmit(false)
        setCategoryFormValue(categoryValue)
        setMenuFormValue(menuValue)
    }

    const closeAlbumPopup = (e) => {
        e.preventDefault();
        toggleCategoryPopup();
        setCloseChecked(false);
        setCategoryFormValue(categoryValue)
        setCategoryFormError({})
        setCategoryIsSubmit(false)
        setDisEdit(false);
    }


    return (
        <>

            <div className="container-fluid basicform" >

                <form >

                    <div className="form-group">
                        <label className="form-label" htmlFor="itemName">Item Name<sup style={{ color: "red" }}>*</sup></label>
                        <input
                            id="itemName"

                            type="text"
                            className="form-control form-control-sm"
                            name="itemName"
                            value={menuFormValue.itemName}
                            onChange={handleChange}
                        />
                        {menuFormError.itemName && (
                            <p className="errormsg">{menuFormError.itemName}</p>
                        )}
                    </div>

                    <div className="form-row">

                        <div className="form-group col-md-6">
                            <label className="form-label" htmlFor="category">Menu Category<sup style={{ color: "red" }}>*</sup></label>
                            <select
                                className="form-select form-select-sm mb-1"
                                aria-label="Default select example"
                                name="category"
                                value={menuFormValue.category}
                                onChange={handleChange}
                            >
                                <option disabled value="" selected>
                                    Select Category

                                </option>
                                {menuCategoryListForOther.map((item) => (<option key={item.CategoryID} value={item.CategoryID}>{item.Name}</option>))}
                            </select>
                            {menuFormError.category && (
                                <p className="errormsg">{menuFormError.category}</p>
                            )}
                        </div>



                        <div className="form-group col-md-5">

                            <label className="form-label" htmlFor="type">Type<sup style={{ color: "red" }}>*</sup></label>
                            <select
                                className="form-select form-select-sm mb-1"
                                aria-label="Default select example"
                                name="type"
                                value={menuFormValue.type}
                                onChange={handleChange}
                            >
                                <option disabled value="" selected>
                                    Select Type

                                </option>
                                <option value="F">Food</option>
                                <option value="B">Beverages</option>
                            </select>
                            {menuFormError.type && (
                                <p className="errormsg">{menuFormError.type}</p>
                            )}
                        </div>


                        <div className="form-group col-md-1">
                            <div className="add-title-button">
                                <button
                                    className="btn btn-sm btn-cmpy"
                                    style={{
                                        background: "0079bf",
                                        width: "50px",
                                        marginTop: "4px"
                                    }}
                                    onClick={addnew}
                                >
                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>



                    </div>


                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <label className="form-label" htmlFor="price">Price<sup style={{ color: "red" }}>*</sup></label>
                            <input
                                id="price"
                                style={{ fontSize: "13px" }}
                                type="text"
                                className="form-control form-control-sm"
                                name="price"
                                value={menuFormValue.price}
                                onChange={handleChange}
                            />
                            {menuFormError.price && (
                                <p className="errormsg">{menuFormError.price}</p>
                            )}
                        </div>


                        <div className="form-group col-md-6">
                            <label className="form-label" htmlFor="unit">Unit<sup style={{ color: "red" }}>*</sup></label>
                            <select
                                className="form-select form-select-sm mb-1"
                                aria-label="Default select example"
                                name="unit"
                                value={menuFormValue.unit}
                                onChange={handleChange}
                            >
                                <option disabled value="" selected>
                                    Select unit

                                </option>

                                <option value="Pcs">Pcs</option>
                                <option value="Plate">Plate</option>
                                <option value="Glass">Glass</option>
                            </select>
                            {menuFormError.unit && (
                                <p className="errormsg">{menuFormError.unit}</p>
                            )}

                        </div>
                    </div>

                    <div className="form-group">

                        <label className="form-label" htmlFor="description">Description<sup style={{ color: "red" }}>*</sup></label>
                        <input
                            id="description"
                            style={{ fontSize: "13px" }}
                            type="textarea"
                            className="form-control form-control-sm"
                            name="description"
                            value={menuFormValue.description}
                            onChange={handleChange}
                        />
                        {menuFormError.description && (
                            <p className="errormsg">{menuFormError.description}</p>
                        )}

                    </div>


                    <div className="form-group">

                        <div style={{ marginTop: "5px" }}>
                            <input
                                type="checkbox"
                                // className="form-check-input"
                                id="exampleCheck3"
                                name="notWorking"
                                onChange={handleAvailableChange}
                                checked={isAvailable}
                            />
                            <label class="form-check-label" for="exampleCheck3" style={{ fontSize: "12px", paddingLeft: "3px" }}>
                                Available
                            </label>
                        </div>
                    </div>




                    <div className="form-group  ">
                        <div
                            className="form-label"
                            htmlFor="text"
                            style={{ fontSize: "12px", textAlign: "left" }}
                        >
                            Upload Image
                        </div>

                        <div className="BoxUpload">
                            <div className="image-upload">
                                {!isUploaded ? (
                                    <>
                                        <label htmlFor="upload-input">
                                            <img
                                                src={Plus}
                                                draggable={"false"}
                                                alt="placeholder"
                                                style={{ width: 90, height: 100, paddingTop: "10px" }}
                                            />
                                        </label>

                                        <input
                                            id="upload-input"
                                            type="file"
                                            accept=".jpg,.jpeg,.gif,.png,.pdf,.doc,.ppt,.pptx,.docx"
                                            onChange={handleImageChange}
                                            name="image"
                                        />
                                    </>
                                ) : (
                                    <div className="ImagePreview">
                                        <img
                                            className="close-icon"
                                            src={CloseIcon}
                                            alt="CloseIcon"
                                            onClick={() => {
                                                setIsUploaded(false);
                                                setImage(null);
                                            }}
                                        />

                                        <img
                                            id="uploaded-image"
                                            src={image}
                                            draggable={false}
                                            alt="uploaded-img"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                </form>


            </div>
            <div className="FormFooter">
                <div className='checkedClose'>
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck2"
                        name="closeChecked"
                        onChange={handleOnChange}
                        checked={closeChecked}
                    />
                    <label class="form-check-label" for="exampleCheck2">
                        Exit after submit
                    </label>
                </div>
                <div>
                    <button className='btn btn-sm btn-cmpy ml-2' style={{ background: "green" }} onClick={formSubmit} >Submit</button>
                    <button className='btn btn-sm btn-cmpy ml-2' onClick={closePopup}>Cancel</button>
                </div>
            </div>

            {categoryPopup && (
                <div className="modals">
                    <div className="popup-overlay"></div>

                    <div className="credit-model-content">
                        <div className="popUpHeader ">
                            <div className='popUpHeaderText '>Add Category</div>
                            <div style={{ cursor: "pointer" }}><i className="bi bi-x " onClick={closeAlbumPopup} style={{ fontSize: "25px" }}></i></div>
                        </div>
                        <div className="inside-modal">
                            <MenuCategory closeAlbumPopup={closeAlbumPopup} />
                        </div>
                    </div>
                </div>
            )}

        </>

    )
}
