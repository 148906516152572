import React, { useContext, useEffect, useState } from "react";
import { Fetchdata } from "../../hooks/getData";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { toast } from "react-toastify";
import StudentLogBookContext from "./StudentLogBookContext";

function StudentLogBookState(props) {
  const { appURL } = useContext(SidebarContext);

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const [popup, setPopup] = useState(false);
  const togglePopup = () => {
    setPopup(!popup);
  };

  const [infoID, setInfoID] = useState("");

  const handleView = (data) => {
    setInfoID(data);
    togglePopup();
  };

  const [todaysDate, setTodaysDate] = useState("");

  // API for log book List
  const [logBookList, setLogBookList] = useState([]);

  useEffect(() => {
    getLogBookList();
  }, [todaysDate]);

  const getLogBookList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      Flag: "S",
      ClassID: userinfo.ClassID.toString(),
      SectionID: userinfo.SectionID.toString(),
      SubjectID: "-1",
      Date: todaysDate.todayDate,
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/log-book`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.AttenLst ? result.AttenLst : "";
        setLoading(false);
        setLogBookList(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        setLogBookList([]);
        setOriginalList([]);
      }
    });
  };

  return (
    <StudentLogBookContext.Provider
      value={{
        loading,
        originalList,
        popup,
        setPopup,
        togglePopup,
        logBookList,
        setLogBookList,
        todaysDate,
        setTodaysDate,
        infoID,
        setInfoID,
        handleView,
      }}
    >
      {props.children}
    </StudentLogBookContext.Provider>
  );
}

export default StudentLogBookState;
