import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import SidebarContext from "../sidebarcontext/SidebarContext";
import Spinner from "../loading/spinner";
import AccountContext from "../contextfolder/AccountContext/AccountContext";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import { convertToNep } from "../hooks/dateConvertor";
import Tippy from "@tippyjs/react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { formatNumber } from "../hooks/drCr";

export default function AccountReport() {
  const { todayDate, customStyles, newMonth, userinfo } =
    useContext(SidebarContext);

  const {
    loading,
    originalList,
    classList,
    sectionList,
    selectClass,
    setSelectClass,
    selectSection,
    setSelectSection,
    selectStudent,
    setSelectStudent,
    studentList,
    accountReportList,
    setAccountReportList,
    reportDateValue,
    setReportDateValue,
    totalList,
  } = useContext(AccountContext);

  const handleClass = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectClass(value);
    setSelectSection("");
    setSelectStudent("");
  };

  const handleSection = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectSection(value);
    setSelectStudent("");
  };

  const handleStudent = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectStudent(value);
  };

  const handleFormDate = ({ bsDate }) => {
    setReportDateValue({ ...reportDateValue, fromDate: bsDate });
  };

  const handleToDate = ({ bsDate }) => {
    setReportDateValue({ ...reportDateValue, toDate: bsDate });
  };

  const searchInput = useRef("");

  let credit = accountReportList.reduce(function (prev, current) {
    return prev + parseFloat(current.Credit);
  }, 0);

  let debit = accountReportList.reduce(function (prev, current) {
    return prev + parseFloat(current.Debit);
  }, 0);

  const columns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "Date",
      // center: true,
      // grow: 0,
      width: "110px",
      selector: (row) => row.Date,
    },
    {
      name: "Particulers",

      // grow: 0,
      selector: (row) => row.Particulars,
    },
    {
      name: "Debit",
      width: "160px",
      right: true,
      selector: (row) => parseFloat(row.Debit).toFixed(2),
    },
    {
      name: "Credit",
      width: "160px",
      right: true,
      selector: (row) => parseFloat(row.Credit).toFixed(2),
    },
  ];

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["Particulars"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setAccountReportList(srchResult);
      } else {
        setAccountReportList({});
      }
    } else {
      setAccountReportList(originalList);
    }
  };

  /* This is for export csv */

  const dataToExcel = accountReportList.map((d, i) => ({
    "S.N.": i + 1,
    Date: d.Date,
    Particulars: d.Particulars,
    Debit: d.Debit,
    Credit: d.Credit,
  }));

  const toExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(dataToExcel);
      var wscols = [
        { wch: 5 },
        { wch: 20 },
        { wch: 50 },
        { wch: 15 },
        { wch: 15 },
      ];
      worksheet["!cols"] = wscols;
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "Account_Report");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_" + "export" + EXCEL_EXTENSION
        );
      }
    });
  };

  //end

  //This is to export pdf

  const dataWithIndex = accountReportList.map((d, i) => ({
    ...d,
    Index: i + 1,
  }));

  const cols = [
    { header: "S.N", field: "Index" },
    { header: "Date", field: "Date" },
    { header: "Particulars", field: "Particulars" },
    { header: "Debit", field: "Debit" },
    { header: "Credit", field: "Credit" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const toPdf = () => {
    const doc = new jsPDF("p", "pt", "a4", true);

    var midPage = doc.internal.pageSize.getWidth() / 2;

    // doc.addImage(`${userinfo.SchPhoto}`, "JPG", 40, 40, 70, 60);

    doc.setFontSize(18);
    doc.text(`${userinfo.SchName}`, midPage, 50, null, null, "center");

    doc.setFontSize(15);
    doc.text(`${userinfo.SchAddress}`, midPage, 68, null, null, "center");

    doc.setFontSize(10);
    doc.text(
      `Tel:${userinfo.Phone}, E-mail:${userinfo.Email}`,
      midPage,
      85,
      null,
      null,
      "center"
    );

    doc.autoTable({
      startY: 100,
      theme: "grid",
      columns: exportColumns,
      body: dataWithIndex,
    });
    doc.save("Account_Report.pdf");
  };
  //end

  //Total debit and credit transactions

  if (totalList.length > 0) {
    var debitT = parseFloat(totalList[0].Debit).toFixed(2);
    var creditT = parseFloat(totalList[0].Credit).toFixed(2);
    var balanceT = formatNumber(
      parseFloat(totalList[0].Debit - totalList[0].Credit).toFixed(2)
    );
  }
  //Total debit and credit transactions ends

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <div className="sec-dataTable">
        <div className="upper-dataTbl">
          <Tippy content="PDF">
            <button className="exportButtons ml-1" onClick={toPdf}>
              <i className="bi bi-file-earmark-pdf-fill exportButtonIconsPdf"></i>
            </button>
          </Tippy>
          <Tippy content="Excel">
            <button className="exportButtons" onClick={toExcel}>
              <i className="bi bi-file-earmark-excel-fill exportButtonIconsExcel"></i>
            </button>
          </Tippy>
        </div>

        <hr className="new-hr-new" />
        {totalList.length > 0 ? (
          <div className="totalTrans">
            {" "}
            {balanceT} <b> &nbsp;Balance :&nbsp;</b>
            {creditT} | <b> &nbsp;Total Credit :&nbsp;</b>
            {debitT} | <b>&nbsp;Total Debit :&nbsp;</b>
          </div>
        ) : (
          <></>
        )}

        <div className="row" style={{ margin: "0" }}>
          <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
            <label className="list-label">Class</label>
            <select
              style={{ fontSize: "11px", marginBottom: "3px" }}
              name="snotifiaction"
              value={selectClass}
              onChange={handleClass}
              className="form-control form-control-sm"
              aria-label="Default select example "
            >
              <option value="" disabled selected style={{ fontSize: "11px" }}>
                Select Class
              </option>
              {classList.map((item) => (
                <option key={item.ClassID} value={item.ClassID}>
                  {item.ClassName}
                </option>
              ))}
            </select>
            <i class="fas fa-angle-down  notificationpositon"></i>
          </div>

          <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
            <label className="list-label">Section</label>
            <select
              style={{ fontSize: "11px", marginBottom: "3px" }}
              name="snotifiaction"
              value={selectSection}
              onChange={handleSection}
              className="form-control form-control-sm"
              aria-label="Default select example "
            >
              <option value="" disabled selected style={{ fontSize: "11px" }}>
                Select Section
              </option>
              {sectionList.map((item) => (
                <option key={item.SectionID} value={item.SectionID}>
                  {item.SectionName}
                </option>
              ))}
            </select>
            <i class="fas fa-angle-down  notificationpositon"></i>
          </div>

          <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
            <label className="list-label">Student</label>
            <select
              style={{ fontSize: "11px", marginBottom: "3px" }}
              name="snotifiaction"
              value={selectStudent}
              onChange={handleStudent}
              className="form-control form-control-sm"
              aria-label="Default select example "
            >
              <option value="" disabled selected style={{ fontSize: "11px" }}>
                Select Student
              </option>
              {studentList.map((item) => (
                <option key={item.StudentID} value={item.StudentID}>
                  {item.StdFullName}
                </option>
              ))}
            </select>
            <i class="fas fa-angle-down  notificationpositon"></i>
          </div>

          <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
            <label className="list-label">From Date</label>
            <Calendar
              className="form-control form-control-sm mb-1"
              dateFormat="YYYY/MM/DD"
              theme="default"
              hideDefaultValue={true}
              language="en"
              placeholder={newMonth}
              values={reportDateValue.fromDate}
              onChange={handleFormDate}
            />
          </div>

          <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
            <label className="list-label">To Date</label>
            <Calendar
              className="form-control form-control-sm mb-1"
              dateFormat="YYYY/MM/DD"
              theme="default"
              language="en"
              values={reportDateValue.toDate}
              onChange={handleToDate}
            />
          </div>

          <div
            className="col-lg-2 col-md-2 col-sm-2 dropdown2"
            style={{
              marginTop: "28px",
              paddingRight: "1px",
              marginBottom: "10px",
            }}
          >
            <div className="d-flex">
              <input
                placeholder="Search"
                ref={searchInput}
                type="text"
                className="form-control form-control-sm searchField"
                onChange={searchHandler}
              />
            </div>
          </div>
        </div>

        {loading ? (
          <Spinner />
        ) : (
          <>
            {accountReportList.length > 0 ? (
              <div className="table-responsive br-3">
                <table className="table table-striped table-bordereds text-nowrap mb-0">
                  <thead>
                    <tr className="tableHead">
                      <td className="tc" style={{ width: "70px" }}>
                        S.N.
                      </td>
                      <td className="tl" style={{ width: "145px" }}>
                        Date
                      </td>
                      <td className="tl">Particulars</td>
                      <td className="tr" style={{ width: "150px" }}>
                        Debit
                      </td>
                      <td className="tr" style={{ width: "150px" }}>
                        Credit
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {accountReportList.map((item, i) => (
                      <tr key={i}>
                        <td className="tc" style={{ width: "70px" }}>
                          {i + 1}
                        </td>
                        <td className="tl">{item.Date}</td>
                        <td className="tl">{item.Particulars}</td>
                        <td className="tr">
                          {parseFloat(item.Debit).toFixed(2)}
                        </td>
                        <td className="tr">
                          {parseFloat(item.Credit).toFixed(2)}
                        </td>
                      </tr>
                    ))}
                    <tr className="lastt">
                      <td colspan="3">
                        <b style={{ paddingLeft: "15px" }}>Total</b>
                      </td>
                      <td className="tr">
                        <b>{debit.toFixed(2)}</b>
                      </td>
                      <td className="tr ">
                        <b>{credit.toFixed(2)}</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="table-responsive br-3">
                <div className="text-center mt-4">
                  <p>There are no records to display.</p>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}
