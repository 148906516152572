import React, { useContext, useEffect, useState } from "react";
import { Fetchdata } from "../../hooks/getData";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { toast } from "react-toastify";
import AdmissionContext from "./AdmissionContext";
import { GetEnglishDate, GetNepaliDate } from "../../hooks/dateConvertor";

function AdmissionState(props) {
  const { appURL } = useContext(SidebarContext);

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const [closeChecked, setCloseChecked] = useState(false);

  const admissionValue = {
    firstName: "",
    lastName: "",

    fatherName: "",
    fatherPhone: "",
    fatherMobile: "",

    motherName: "",
    motherPhone: "",
    motherMobile: "",

    userMobile: "",
    dateOfBirth: "",
    gender: "",

    class: "",
    section: "",

    tempAddress: "",
    permAddress: "",

    emergFullName: "",
    emergMobile: "",
    emergRelation: "",

    email: "",
    dalitOrJanjati: "",
    nationality: "",

    bloodGroup: "",
    religion: "",

    previousSchool: "",
    hobbies: "",

    remarks: "",
  };

  const [formValue, setFormValue] = useState(admissionValue);
  const [formError, setFormError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [editIsSubmit, setEditIsSubmit] = useState(false);

  const [isUploaded, setIsUploaded] = useState(false);
  const [typeFile, setTypeFile] = useState("");
  const [image, setImage] = useState("");

  const [popup, setPopup] = useState(false);
  const togglePopup = () => {
    setPopup(!popup);
  };

  const [editPop, setEditPop] = useState(false);
  const toggleEditPopup = () => {
    setEditPop(!editPop);
  };

  const [classList, setClassList] = useState([]);
  const [sectionList, setSectionList] = useState([]);

  useEffect(() => {
    getClassList();
  }, []);

  const getClassList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      DFlag: "C",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setClassList(postResult);
      } else {
        setClassList([]);
      }
    });
  };

  useEffect(() => {
    getSectionList();
  }, [formValue.class]);

  const getSectionList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      ClassID: formValue.class,
      DFlag: "S",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setSectionList(postResult);
      } else {
        setSectionList([]);
      }
    });
  };

  //API to create admission

  const fetchdata = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "i",
      FirstName: formValue.firstName,
      LastName: formValue.lastName,
      Image: image !== "" ? image.split(",")[1] : "",
      FatherName: formValue.fatherName,
      FatherPhone: formValue.fatherPhone,
      FatherMobile: formValue.fatherMobile,
      MotherName: formValue.motherName,
      MotherPhone: formValue.motherPhone,
      MotherMobile: formValue.motherMobile,
      UserMobile: formValue.userMobile,
      DateOfBirth: formValue.dateOfBirth,
      Gender: formValue.gender,
      Class: formValue.class,
      Section: formValue.section,
      TempAddress: formValue.tempAddress,
      PermAddress: formValue.permAddress,
      EmergFullName: formValue.emergFullName,
      EmergMobile: formValue.emergMobile,
      EmergRelation: formValue.emergRelation,
      Email: formValue.email,
      DalitOrJanjati: formValue.dalitOrJanjati,
      Nationality: formValue.nationality,
      BloodGroup: formValue.bloodGroup,
      Religion: formValue.religion,
      PreviousSchool: formValue.previousSchool,
      Hobbies: formValue.hobbies,
      Remarks: formValue.remarks,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/admission`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getAdmissionList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };
  // API for admission List
  const [admissionList, setAdmissionList] = useState([]);
  useEffect(() => {
    getAdmissionList();
  }, []);

  const getAdmissionList = () => {
    const params = {
      SchID: userinfo.SchID,
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/admission`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.StudentLst ? result.StudentLst : "";
        setLoading(false);
        setAdmissionList(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        setAdmissionList([]);
        setOriginalList([]);
      }
    });
  };

  ///// FOR View
  const [viewPop, setViewPop] = useState(false);
  const toggleViewPopup = () => {
    setViewPop(!viewPop);
  };
  const [viewId, setViewId] = useState("");
  const handleView = (data) => {
    toggleViewPopup();
    setViewId(data.AdmissionID);
    viewAdmissionList();
  };

  const [viewAdmission, setViewAdmission] = useState([]);

  useEffect(() => {
    viewAdmissionList();
  }, [viewId]);

  const viewAdmissionList = () => {
    const params = {
      SchID: userinfo.SchID,
      Flag: "SI",
      AdmissionID: viewId.toString(),
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/admission`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.StudentLst ? result.StudentLst[0] : "";
        setViewAdmission(postResult);
        setFormValue({
          firstName: postResult.FirstName,
          lastName: postResult.LastName,

          fatherName: postResult.FatherName,
          fatherPhone: postResult.FatherPhone,
          fatherMobile: postResult.FatherMobile,

          motherName: postResult.MotherName,
          motherPhone: postResult.MotherPhone,
          motherMobile: postResult.MotherMobile,

          userMobile: postResult.UserMobile,
          dateOfBirth: GetNepaliDate(postResult.DateOfBirth),
          gender: postResult.Gender,

          class: postResult.ClassID.toString(),
          section: postResult.SectionID.toString(),

          tempAddress: postResult.TempAddress,
          permAddress: postResult.PermAddress,

          emergFullName: postResult.EmergFullName,
          emergMobile: postResult.EmergMobile,
          emergRelation: postResult.EmergRelation,

          email: postResult.Email,
          dalitOrJanjati: postResult.DalitOrJanjati,
          nationality: postResult.Nationality,

          bloodGroup: postResult.BloodGroup,
          religion: postResult.Religion,

          previousSchool: postResult.PreviousSchool,
          hobbies: postResult.Hobbies,

          remarks: postResult.Remarks,
        });
        // setImage(postResult.Image);
      } else {
        setViewAdmission([]);
      }
    });
  };
  ///// FOR EDIT

  const handleEdit = (data) => {
    setEditPop(!editPop);
    setViewId(data.AdmissionID);
    setEditIsSubmit(false);
  };

  const editdata = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "U",
      AdmissionID: viewId.toString(),
      FirstName: formValue.firstName,
      LastName: formValue.lastName,
      Image: image !== "" ? image.split(",")[1] : "",
      FatherName: formValue.fatherName,
      FatherPhone: formValue.fatherPhone,
      FatherMobile: formValue.fatherMobile,
      MotherName: formValue.motherName,
      MotherPhone: formValue.motherPhone,
      MotherMobile: formValue.motherMobile,
      UserMobile: formValue.userMobile,
      DateOfBirth: GetEnglishDate(formValue.dateOfBirth),
      Gender: formValue.gender,
      Class: formValue.class.toString(),
      Section: formValue.section.toString(),
      TempAddress: formValue.tempAddress,
      PermAddress: formValue.permAddress,
      EmergFullName: formValue.emergFullName,
      EmergMobile: formValue.emergMobile,
      EmergRelation: formValue.emergRelation,
      Email: formValue.email,
      DalitOrJanjati: formValue.dalitOrJanjati,
      Nationality: formValue.nationality,
      BloodGroup: formValue.bloodGroup,
      Religion: formValue.religion,
      PreviousSchool: formValue.previousSchool,
      Hobbies: formValue.hobbies,
      Remarks: formValue.remarks,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/admission`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getAdmissionList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };

  // //Everything to pop up and delete the notification list
  const [deletePopup, setDeletePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const [delId, setDelId] = useState("");

  const handleDelete = async (datas) => {
    setDeletePopup({
      show: true,
    });
    setDelId(datas.AdmissionID);
  };

  // This will perform the deletion and hide the Confirmation Box

  const handleDeleteTrue = async () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "R",
      AdmissionID: delId.toString(),
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/admission`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          getAdmissionList();
          toast(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
        } else {
          toast(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
    });
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleDeleteFalse = () => {
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // Ends Here

  return (
    <AdmissionContext.Provider
      value={{
        loading,
        originalList,
        deletePopup,
        handleDeleteTrue,
        handleDeleteFalse,
        handleDelete,
        popup,
        setPopup,
        togglePopup,
        admissionList,
        setAdmissionList,
        formValue,
        setFormValue,
        formError,
        setFormError,
        isSubmit,
        admissionValue,
        setIsSubmit,
        closeChecked,
        setCloseChecked,
        fetchdata,
        editPop,
        setEditPop,
        toggleEditPopup,
        editIsSubmit,
        setEditIsSubmit,
        handleEdit,
        editdata,
        toggleViewPopup,
        handleView,
        viewAdmission,
        viewPop,
        setViewPop,
        isUploaded,
        setIsUploaded,
        typeFile,
        setTypeFile,
        image,
        setImage,
        classList,
        sectionList,
      }}
    >
      {props.children}
    </AdmissionContext.Provider>
  );
}

export default AdmissionState;
