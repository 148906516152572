import React, { useContext, useEffect, useRef, useState } from "react";
import { Fetchdata } from "../hooks/getData";
import SidebarContext from "../sidebarcontext/SidebarContext";
import AlbumContext from "../contextfolder/AlbumContext/AlbumContext";
import Tippy from "@tippyjs/react";
import Spinner from "../loading/spinner";
import DataTable from "react-data-table-component";
import DeletePop from "./DeletePop";

export default function AlbumForm({ closePopupA }) {
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const { appURL, customStyles } = useContext(SidebarContext);

  const context = useContext(AlbumContext);

  const {
    loading,
    toggleAPopup,
    albumFormError,
    setAlbumFormError,
    isASubmit,
    setIsASubmit,
    albumValue,
    albumFormValue,
    setAlbumFormValue,
    fetchdata,
    closeChecked,
    setCloseChecked,
    setSection,
    section,
    setAlbumList,
    originalList,
    handleDelete,
    handleEdit,
    albumList,
    isEdit,
    editdata,
    deletePopup,
    handleDeleteTrue,
    handleDeleteFalse,
  } = context;

  const handleOnChange = () => {
    setCloseChecked(!closeChecked);
  };

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setAlbumFormValue({ ...albumFormValue, [name]: value });
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setAlbumFormError(validate(albumFormValue));
    setIsASubmit(true);
  };

  useEffect(() => {
    if (Object.keys(albumFormError).length === 0 && isASubmit) {
      fetchdata(albumFormValue);
      if (closeChecked === true) {
        toggleAPopup();
      }
      setAlbumFormValue(albumValue);
      setIsASubmit(false);
    }
  }, [albumFormError]);

  const validate = (values) => {
    const errors = {};
    if (
      albumFormValue.selectdrop === "C" ||
      albumFormValue.selectdrop === "S" ||
      albumFormValue.selectdrop === "I"
    ) {
      if (!values.class) {
        errors.class = "Required";
      }
    }
    if (
      albumFormValue.selectdrop === "S" ||
      albumFormValue.selectdrop === "I"
    ) {
      if (!values.section) {
        errors.section = "Required";
      }
    }
    if (albumFormValue.selectdrop === "I") {
      if (!values.student) {
        errors.student = "Required";
      }
    }
    if (!values.albumName) {
      errors.albumName = "Required";
    }
    if (!values.selectdrop) {
      errors.selectdrop = "Required";
    }
    return errors;
  };

  const [classList, setClassList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [studentList, setStudentList] = useState([]);

  //API to hit class list
  useEffect(() => {
    getClassList();
  }, []);

  const getClassList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      DFlag: "C",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setClassList(postResult);
      } else {
        setClassList([]);
      }
    });
  };

  //API to hit section list
  useEffect(() => {
    getSectionList();
  }, [albumFormValue.class]);

  const getSectionList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      ClassID: albumFormValue.class,
      DFlag: "S",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";

        setSectionList(postResult);
      } else {
        setSectionList([]);
      }
    });
  };

  useEffect(() => {
    stdList();
  }, [albumFormValue.class, albumFormValue.section]);

  const stdList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      Flag: "S",
      ClassID: albumFormValue.class,
      SectionID: [
        {
          SectionID: albumFormValue.section,
        },
      ],
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/student`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.StudentLst ? result.StudentLst : "";
        setStudentList(postResult);
      } else {
        setStudentList([]);
      }
    });
  };

  const searchInput = useRef("");

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["AlbumName"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setAlbumList(srchResult);
      } else {
        setAlbumList({});
      }
    } else {
      setAlbumList(originalList);
    }
  };

  const columns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "Album",
      selector: (row) => row.AlbumName,
    },
    {
      name: "No. of Image",
      width: "110px",
      selector: (row) => row.NoOfPhoto,
    },
    {
      name: "Action",
      center: true,
      width: "100px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <Tippy content="Edit">
                <i
                  className="fa fa-pencil solvespan--action"
                  onClick={() => handleEdit(row)}
                ></i>
              </Tippy>
              <Tippy content="Delete">
                <i
                  className="fa fa-trash-o rejectspan--action"
                  onClick={() => handleDelete(row.AlbumID)}
                ></i>
              </Tippy>
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      <div className="container-fluid basicform">
        <form>
          <div className="form-group">
            <label className="form-label" htmlFor="selectdrop">
              Select Option<sup style={{ color: "red" }}>*</sup>
            </label>
            <select
              id="selectdrop"
              name="selectdrop"
              onChange={handleChange}
              value={albumFormValue.selectdrop}
              className="form-select form-select-sm mb-1"
              style={{ fontSize: "12px" }}
              disabled={isEdit === true}
            >
              <option value="" selected disabled>
                Select Option
              </option>
              {/* {userinfo.UserType !== "T" && (
                                <option value="-1">All</option>
                            )} */}
              <option value="-1">All</option>
              <option value="C">Class</option>
              <option value="S">Section</option>
              <option value="I">Individual</option>
            </select>
            <p className="errormsg " style={{ marginBottom: "0" }}>
              {albumFormError.selectdrop}
            </p>
          </div>
          <div className="form-row">
            {albumFormValue.selectdrop === "C" ||
            albumFormValue.selectdrop === "S" ||
            albumFormValue.selectdrop === "I" ? (
              <div
                className={
                  albumFormValue.selectdrop === "C"
                    ? "form-group col-md-12"
                    : albumFormValue.selectdrop === "S"
                    ? "form-group col-md-6"
                    : "form-group col-md-4"
                }
              >
                <label className="form-label" htmlFor="class">
                  Class<sup style={{ color: "red" }}>*</sup>
                </label>
                <select
                  id="class"
                  name="class"
                  onChange={handleChange}
                  value={albumFormValue.class}
                  className="form-select form-select-sm mb-1"
                  style={{ fontSize: "12px" }}
                  disabled={isEdit === true}
                >
                  <option value="" selected disabled>
                    Select Option
                  </option>
                  {classList.map((item, i) => (
                    <option key={i} value={item.ClassID}>
                      {item.ClassName}
                    </option>
                  ))}
                </select>
                <p className="errormsg " style={{ marginBottom: "0" }}>
                  {albumFormError.class}
                </p>
              </div>
            ) : (
              <></>
            )}

            {albumFormValue.selectdrop === "S" ||
            albumFormValue.selectdrop === "I" ? (
              <div
                className={
                  albumFormValue.selectdrop === "S"
                    ? "form-group col-md-6"
                    : "form-group col-md-4"
                }
              >
                <label className="form-label" htmlFor="section">
                  Section<sup style={{ color: "red" }}>*</sup>
                </label>
                <select
                  id="section"
                  name="section"
                  onChange={handleChange}
                  value={albumFormValue.section}
                  className="form-select form-select-sm mb-1"
                  style={{ fontSize: "12px" }}
                  disabled={isEdit === true}
                >
                  <option
                    value=""
                    disabled
                    selected
                    style={{ fontSize: "11px" }}
                  >
                    Select Section
                  </option>
                  {sectionList.map((item) => (
                    <option key={item.SectionID} value={item.SectionID}>
                      {item.SectionName}
                    </option>
                  ))}
                </select>
                <p className="errormsg " style={{ marginBottom: "0" }}>
                  {albumFormError.section}
                </p>
              </div>
            ) : (
              <></>
            )}

            {albumFormValue.selectdrop === "I" ? (
              <div className="form-group col-md-4">
                <label className="form-label" htmlFor="ss">
                  Student<sup style={{ color: "red" }}>*</sup>
                </label>
                <select
                  id="ss"
                  name="student"
                  onChange={handleChange}
                  value={albumFormValue.student}
                  className="form-select form-select-sm mb-1"
                  style={{ fontSize: "12px" }}
                  disabled={isEdit === true}
                >
                  <option value="" selected disabled>
                    Select Option
                  </option>
                  {studentList.map((item, i) => (
                    <option key={i} value={item.StudentID}>
                      {item.StdFullName}
                    </option>
                  ))}
                </select>
                <p className="errormsg " style={{ marginBottom: "0" }}>
                  {albumFormError.student}
                </p>
              </div>
            ) : (
              <></>
            )}
          </div>

          <div className="form-group">
            <label
              className="form-label"
              htmlFor="albumName"
              style={{ fontSize: "12px" }}
            >
              Album Name<sup style={{ color: "red" }}>*</sup>
            </label>
            <input
              id="albumName"
              style={{ fontSize: "13px" }}
              type="text"
              className="form-control form-control-sm "
              name="albumName"
              value={albumFormValue.albumName}
              onChange={handleChange}
            />
            <p className="errormsg " style={{ marginBottom: "0" }}>
              {albumFormError.albumName}
            </p>
          </div>
        </form>

        <div className="sec-dataTable">
          {loading ? (
            <Spinner />
          ) : (
            <DataTable
              columns={columns}
              data={albumList}
              customStyles={customStyles}
              pagination
              fixedHeader
              fixedHeaderScrollHeight="280px"
              highlightOnHover
              pointerOnHover
              responsive
              dense
              striped
              subHeader
              subHeaderAlign="right"
              subHeaderComponent={
                <>
                  <div className="upper-dataTbl">
                    <div className="d-flex">
                      <input
                        placeholder="Search"
                        ref={searchInput}
                        type="text"
                        className="form-control form-control-sm searchField"
                        onChange={searchHandler}
                      />
                    </div>
                  </div>
                </>
              }
            />
          )}
        </div>
      </div>
      <div className="FormFooter">
        <div className="checkedClose">
          <input
            type="checkbox"
            className="form-check-input"
            id="exampleCheck2"
            name="closeChecked"
            onChange={handleOnChange}
            checked={closeChecked}
          />
          <label class="form-check-label" for="exampleCheck2">
            Exit after {!isEdit ? "submit" : "update"}
          </label>
        </div>
        <div>
          {!isEdit ? (
            <button
              className="btn btn-sm btn-cmpy ml-2"
              style={{ background: "green" }}
              onClick={formSubmit}
            >
              Submit
            </button>
          ) : (
            <button
              className="btn btn-sm btn-cmpy ml-2"
              style={{ background: "green" }}
              onClick={editdata}
            >
              Update
            </button>
          )}
          <button className="btn btn-sm btn-cmpy ml-2" onClick={closePopupA}>
            Cancel
          </button>
        </div>
      </div>

      {deletePopup.show && (
        <DeletePop
          handleDeleteTrue={handleDeleteTrue}
          handleDeleteFalse={handleDeleteFalse}
        />
      )}
    </>
  );
}
