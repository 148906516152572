import React, { useContext, useEffect, useState } from "react";
import { Fetchdata } from "../../hooks/getData";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { toast } from "react-toastify";
import ClassRoutineContext from "./ClassRoutineContext";

function ClassRoutineState(props) {
  const { appURL, today } = useContext(SidebarContext);

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const [closeChecked, setCloseChecked] = useState(false);

  const classValue = {
    class: "",
    section: "",
    day: "",
    teacher: "",
    subject: "",
    period: "",
    fromTime: "",
    toTime: "",
    roomNo: "",
    breakTime: "N",
  };

  const [day, setDay] = useState([]);

  const [classFormValue, setClassFormValue] = useState(classValue);
  const [classFormError, setClassFormError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [editIsSubmit, setEditIsSubmit] = useState(false);

  const [popup, setPopup] = useState(false);
  const togglePopup = () => {
    setPopup(!popup);
  };

  const [editPop, setEditPop] = useState(false);
  const toggleEditPopup = () => {
    setEditPop(!editPop);
  };

  const [classList, setClassList] = useState([]);
  const [sectionList, setSectionList] = useState([]);

  const [selectClass, setSelectClass] = useState("");
  const [selectSection, setSelectSection] = useState("");

  const [chooseDay, setChooseDay] = useState(today);

  useEffect(() => {
    getClassList();
  }, []);

  const getClassList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      DFlag: "C",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setLoading(false);
        setClassList(postResult);
      } else {
        setClassList([]);
      }
    });
  };

  useEffect(() => {
    getSectionList();
  }, [selectClass]);

  const getSectionList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      ClassID: selectClass,
      DFlag: "S",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";

        setSectionList(postResult);
      } else {
        setSectionList([]);
      }
    });
  };

  //API to create class routine
  const fetchdata = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "I",
      ClassID: classFormValue.class,
      SectionID: classFormValue.section,
      SubjectID: classFormValue.subject === "" ? "0" : classFormValue.subject,
      // WeekID: classFormValue.day,
      WeekID: "0",
      WeekIDs: day,
      SubTeacher: classFormValue.teacher === "" ? "0" : classFormValue.teacher,
      StartTime: classFormValue.fromTime,
      EndTime: classFormValue.toTime,
      PeriodOrder: classFormValue.period,
      RoomNo: classFormValue.roomNo === "" ? "0" : classFormValue.roomNo,
      IsBreak: classFormValue.breakTime,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/class-routine`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getClassRoutineList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };

  // API for class routine list
  const [classRoutineList, setClassRoutineList] = useState([]);

  useEffect(() => {
    getClassRoutineList();
  }, [selectClass, selectSection, chooseDay]);

  const getClassRoutineList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      Flag: "S",
      ClassID: selectClass.toString(),
      SectionID: selectSection.toString(),
      WeekID: chooseDay.toString(),
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/class-routine`,
    };

    const paramsT = {
      SchID: userinfo.SchID,
      RoutineType: "TC",
      MemID: userinfo.UserID.toString(),
      WeekID: chooseDay.toString(),
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/routine`,
    };

    Fetchdata(userinfo.UserType === "T" ? paramsT : params).then(function (
      result
    ) {
      if (result.StatusCode === 200) {
        const postResult = result.RoutineLst ? result.RoutineLst : "";
        setLoading(false);
        setClassRoutineList(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        setClassRoutineList([]);
        setOriginalList([]);
      }
    });
  };

  //API for class Rouitne teacher list

  // API for class routine list
  // const [classRoutineTeacherList, setClassRoutineTeacherList] = useState([]);

  // useEffect(() => {
  //     getClassRoutineList();
  // }, [selectClass, selectSection, chooseDay]);

  // const getClassRoutineTeacherList = () => {
  //     const params = {

  //     };

  //     Fetchdata(params).then(function (result) {

  //         if (result.StatusCode === 200) {

  //             const postResult = result.RoutineLst ? result.RoutineLst : "";
  //             setLoading(false);
  //             setClassRoutineList(postResult);
  //             setOriginalList(postResult);
  //         } else {
  //             setLoading(false);
  //             setClassRoutineList([]);
  //             setOriginalList([]);
  //         }
  //     });
  // };

  const [classId, setClassId] = useState("");

  const handleEdit = (data) => {
    setEditPop(!editPop);

    setClassId(data.RoutineID);
    setClassFormValue({
      class: data.ClassID.toString(),
      section: data.SectionID.toString(),
      day: data.WeekID,
      teacher: data.SubTeacherID,
      subject: data.SubjectID,
      period: data.Period,
      fromTime: data.StartTime,
      toTime: data.EndTime,
      roomNo: data.RoomNo,
      breakTime: data.IsBreakID,
    });
    setEditIsSubmit(false);
  };

  //API to edit event
  const editdata = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "U",
      RoutineID: classId,
      ClassID: classFormValue.class,
      SectionID: classFormValue.section,
      SubjectID: classFormValue.subject,
      WeekID: classFormValue.day,
      SubTeacher: classFormValue.teacher,
      StartTime: classFormValue.fromTime,
      EndTime: classFormValue.toTime,
      PeriodOrder: classFormValue.period,
      RoomNo: classFormValue.roomNo,
      IsBreak: classFormValue.breakTime,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/class-routine`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getClassRoutineList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };

  //Everything to pop up and delete the event list
  const [deletePopup, setDeletePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const [delData, setDelData] = useState();

  const handleDelete = async (data) => {
    setDelData(data);

    setDeletePopup({
      show: true,
      data,
    });
  };

  // This will perform the deletion and hide the Confirmation Box

  const handleDeleteTrue = async () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "R",
      RoutineID: delData.toString(),
      ClassID: selectClass.toString(),
      SectionID: selectSection.toString(),
      WeekID: chooseDay.toString(),
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/class-routine`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          getClassRoutineList();
          toast(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
        } else {
          toast(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
    });
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleDeleteFalse = () => {
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // Ends Here

  return (
    <ClassRoutineContext.Provider
      value={{
        loading,
        originalList,
        deletePopup,
        handleDeleteTrue,
        handleDeleteFalse,
        handleDelete,
        popup,
        setPopup,
        togglePopup,
        classFormValue,
        setClassFormValue,
        classFormError,
        setClassFormError,
        isSubmit,
        classValue,
        setIsSubmit,
        setClassRoutineList,
        editIsSubmit,
        setEditIsSubmit,
        fetchdata,
        classRoutineList,
        editPop,
        setEditPop,
        toggleEditPopup,
        handleEdit,
        editdata,
        classList,
        setClassList,
        sectionList,
        setSectionList,
        selectClass,
        setSelectClass,
        selectSection,
        setSelectSection,
        chooseDay,
        setChooseDay,
        closeChecked,
        setCloseChecked,
        day,
        setDay,
      }}
    >
      {props.children}
    </ClassRoutineContext.Provider>
  );
}

export default ClassRoutineState;
