import React, { useContext, useState, useEffect } from "react";
import CloseIcon from "../../images/CloseIcon.svg";
import Plus from "../../images/Plus.png";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import AdmissionContext from "../contextfolder/AdmissionContext/AdmissionContext";

export default function AdmissionForm({ closePopup }) {
  const context = useContext(AdmissionContext);

  const {
    togglePopup,
    formError,
    setFormError,
    isSubmit,
    setIsSubmit,
    admissionValue,
    formValue,
    setFormValue,
    setCloseChecked,
    closeChecked,
    classList,
    sectionList,
    isUploaded,
    setIsUploaded,
    setTypeFile,
    image,
    setImage,
    fetchdata,
  } = context;

  const handleOnChange = () => {
    setCloseChecked(!closeChecked);
  };

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setFormValue({ ...formValue, [name]: value });
  };

  const handleDate = ({ adDate }) => {
    setFormValue({ ...formValue, dateOfBirth: adDate });
  };

  function handleImageChange(e) {
    if (e.target.files && e.target.files[0]) {
      setTypeFile(e.target.files[0].name);
      let reader = new FileReader();

      reader.onload = function (e) {
        setImage(e.target.result);
        setIsUploaded(true);
      };

      reader.readAsDataURL(e.target.files[0]);
    }
  }

  const formSubmit = (e) => {
    e.preventDefault();
    setFormError(validate(formValue));
    setIsSubmit(true);
  };
  useEffect(() => {
    if (Object.keys(formError).length === 0 && isSubmit) {
      fetchdata(formValue);
      if (closeChecked === true) {
        togglePopup();
      }
      setFormValue(admissionValue);
      setIsSubmit(false);
      setCloseChecked(false);
      setImage("");
      setIsUploaded(false);
    }
  }, [formError]);

  const validate = (values) => {
    const errors = {};
    if (!values.firstName) {
      errors.firstName = "Required";
    }
    if (!values.lastName) {
      errors.lastName = "Required";
    }
    if (!values.fatherName) {
      errors.fatherName = "Required";
    }
    // if (!values.fatherPhone) {
    //   errors.fatherPhone = "Required";
    // }
    // if (!values.fatherMobile) {
    //   errors.fatherMobile = "Required";
    // }
    // if (!values.motherName) {
    //   errors.motherName = "Required";
    // }
    // if (!values.motherPhone) {
    //   errors.motherPhone = "Required";
    // }
    // if (!values.motherMobile) {
    //   errors.motherMobile = "Required";
    // }
    // if (!values.userMobile) {
    //   errors.userMobile = "Required";
    // }
    if (!values.dateOfBirth) {
      errors.dateOfBirth = "Required";
    }
    if (!values.gender) {
      errors.gender = "Required";
    }
    if (!values.class) {
      errors.class = "Required";
    }
    if (!values.section) {
      errors.section = "Required";
    }
    // if (!values.tempAddress) {
    //   errors.tempAddress = "Required";
    // }
    // if (!values.permAddress) {
    //   errors.permAddress = "Required";
    // }
    // if (!values.emergFullName) {
    //   errors.emergFullName = "Required";
    // }
    // if (!values.emergMobile) {
    //   errors.emergMobile = "Required";
    // }
    // if (!values.emergRelation) {
    //   errors.emergRelation = "Required";
    // }
    // if (!values.email) {
    //   errors.email = "Required";
    // }
    // if (!values.dalitOrJanjati) {
    //   errors.dalitOrJanjati = "Required";
    // }
    // if (!values.nationality) {
    //   errors.nationality = "Required";
    // }
    // if (!values.bloodGroup) {
    //   errors.bloodGroup = "Required";
    // }
    // if (!values.religion) {
    //   errors.religion = "Required";
    // }
    // if (!values.previousSchool) {
    //   errors.previousSchool = "Required";
    // }
    // if (!values.hobbies) {
    //   errors.hobbies = "Required";
    // }
    // if (!values.remarks) {
    //   errors.remarks = "Required";
    // }

    return errors;
  };

  return (
    <>
      <div className="container-fluid basicform">
        <form>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label
                className="form-label"
                htmlFor="firstName"
                style={{ fontSize: "12px" }}
              >
                First Name<sup style={{ color: "red" }}>*</sup>
              </label>
              <input
                id="firstName"
                style={{ fontSize: "13px" }}
                type="text"
                className="form-control form-control-sm "
                name="firstName"
                value={formValue.firstName}
                onChange={handleChange}
              />
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {formError.firstName}
              </p>
            </div>
            <div className="form-group col-md-6">
              <label
                className="form-label"
                htmlFor="lastName"
                style={{ fontSize: "12px" }}
              >
                Last Name<sup style={{ color: "red" }}>*</sup>
              </label>
              <input
                id="lastName"
                style={{ fontSize: "13px" }}
                type="text"
                className="form-control form-control-sm "
                name="lastName"
                value={formValue.lastName}
                onChange={handleChange}
              />
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {formError.lastName}
              </p>
            </div>

            <div className="form-group col-md-4">
              <label
                className="form-label"
                htmlFor="fatherName"
                style={{ fontSize: "12px" }}
              >
                Father Name<sup style={{ color: "red" }}>*</sup>
              </label>
              <input
                id="fatherName"
                style={{ fontSize: "13px" }}
                type="text"
                className="form-control form-control-sm "
                name="fatherName"
                value={formValue.fatherName}
                onChange={handleChange}
              />
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {formError.fatherName}
              </p>
            </div>
            <div className="form-group col-md-4">
              <label
                className="form-label"
                htmlFor="fatherPhone"
                style={{ fontSize: "12px" }}
              >
                Father Phone
              </label>
              <input
                id="fatherPhone"
                style={{ fontSize: "13px" }}
                type="text"
                className="form-control form-control-sm "
                name="fatherPhone"
                value={formValue.fatherPhone}
                onChange={handleChange}
              />
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {formError.fatherPhone}
              </p>
            </div>
            <div className="form-group col-md-4">
              <label
                className="form-label"
                htmlFor="fatherMobile"
                style={{ fontSize: "12px" }}
              >
                Father Mobile
              </label>
              <input
                id="fatherMobile"
                style={{ fontSize: "13px" }}
                type="text"
                className="form-control form-control-sm "
                name="fatherMobile"
                value={formValue.fatherMobile}
                onChange={handleChange}
              />
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {formError.fatherMobile}
              </p>
            </div>

            <div className="form-group col-md-4">
              <label
                className="form-label"
                htmlFor="motherName"
                style={{ fontSize: "12px" }}
              >
                Mother Name
              </label>
              <input
                id="motherName"
                style={{ fontSize: "13px" }}
                type="text"
                className="form-control form-control-sm "
                name="motherName"
                value={formValue.motherName}
                onChange={handleChange}
              />
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {formError.motherName}
              </p>
            </div>
            <div className="form-group col-md-4">
              <label
                className="form-label"
                htmlFor="motherPhone"
                style={{ fontSize: "12px" }}
              >
                Mother Phone
              </label>
              <input
                id="motherPhone"
                style={{ fontSize: "13px" }}
                type="text"
                className="form-control form-control-sm "
                name="motherPhone"
                value={formValue.motherPhone}
                onChange={handleChange}
              />
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {formError.motherPhone}
              </p>
            </div>
            <div className="form-group col-md-4">
              <label
                className="form-label"
                htmlFor="motherMobile"
                style={{ fontSize: "12px" }}
              >
                Mother Mobile
              </label>
              <input
                id="motherMobile"
                style={{ fontSize: "13px" }}
                type="text"
                className="form-control form-control-sm "
                name="motherMobile"
                value={formValue.motherMobile}
                onChange={handleChange}
              />
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {formError.motherMobile}
              </p>
            </div>

            <div className="form-group col-md-4">
              <label
                className="form-label"
                htmlFor="userMobile"
                style={{ fontSize: "12px" }}
              >
                User Mobile
              </label>
              <input
                id="userMobile"
                style={{ fontSize: "13px" }}
                type="text"
                className="form-control form-control-sm "
                name="userMobile"
                value={formValue.userMobile}
                onChange={handleChange}
              />
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {formError.userMobile}
              </p>
            </div>
            <div className="form-group col-md-4">
              <label
                className="form-label"
                htmlFor="dateOfBirth"
                style={{ fontSize: "12px" }}
              >
                Date of birth<sup style={{ color: "red" }}>*</sup>
              </label>
              <Calendar
                className="form-control form-control-sm mb-1"
                dateFormat="YYYY/MM/DD"
                theme="default"
                language="en"
                values={formValue.dateOfBirth}
                onChange={handleDate}
                hideDefaultValue={true}
              />
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {formError.dateOfBirth}
              </p>
            </div>
            <div className="form-group col-md-4">
              <label
                className="form-label"
                htmlFor="gender"
                style={{ fontSize: "12px" }}
              >
                Gender<sup style={{ color: "red" }}>*</sup>
              </label>
              <select
                id="gender"
                name="gender"
                onChange={handleChange}
                value={formValue.gender}
                className="form-select form-select-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="" disabled selected style={{ fontSize: "11px" }}>
                  Select Gender
                </option>

                <option value="Male">Male</option>
                <option value="Femal">Female</option>
              </select>
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {formError.gender}
              </p>
            </div>

            <div className="form-group col-md-6">
              <label className="form-label" htmlFor="class">
                Class<sup style={{ color: "red" }}>*</sup>
              </label>
              <select
                id="class"
                name="class"
                onChange={handleChange}
                value={formValue.class}
                className="form-select form-select-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="" disabled selected style={{ fontSize: "11px" }}>
                  Select Class
                </option>
                {classList.map((item) => (
                  <option key={item.ClassID} value={item.ClassID}>
                    {item.ClassName}
                  </option>
                ))}
              </select>
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {formError.class}
              </p>
            </div>
            <div className="form-group col-md-6">
              <label className="form-label" htmlFor="section">
                Section<sup style={{ color: "red" }}>*</sup>
              </label>

              <select
                id="sections"
                name="section"
                onChange={handleChange}
                value={formValue.section}
                className="form-select form-select-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="" selected disabled>
                  Select Option
                </option>
                {sectionList.map((item, i) => (
                  <option key={i} value={item.SectionID}>
                    {item.SectionName}
                  </option>
                ))}
              </select>
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {formError.section}
              </p>
            </div>

            <div className="form-group col-md-6">
              <label className="form-label" htmlFor="tempAddress">
                Temporary Address
              </label>
              <input
                id="tempAddress"
                style={{ fontSize: "13px" }}
                type="text"
                className="form-control form-control-sm "
                name="tempAddress"
                value={formValue.tempAddress}
                onChange={handleChange}
              />
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {formError.tempAddress}
              </p>
            </div>
            <div className="form-group col-md-6">
              <label className="form-label" htmlFor="permAddress">
                Permanent Address
              </label>
              <input
                id="permAddress"
                style={{ fontSize: "13px" }}
                type="text"
                className="form-control form-control-sm "
                name="permAddress"
                value={formValue.permAddress}
                onChange={handleChange}
              />
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {formError.permAddress}
              </p>
            </div>

            <div className="form-group col-md-4">
              <label
                className="form-label"
                htmlFor="emergFullName"
                style={{ fontSize: "12px" }}
              >
                Emergency Full Name
              </label>
              <input
                id="emergFullName"
                style={{ fontSize: "13px" }}
                type="text"
                className="form-control form-control-sm "
                name="emergFullName"
                value={formValue.emergFullName}
                onChange={handleChange}
              />
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {formError.emergFullName}
              </p>
            </div>
            <div className="form-group col-md-4">
              <label
                className="form-label"
                htmlFor="emergMobile"
                style={{ fontSize: "12px" }}
              >
                Emergency Mobile
              </label>
              <input
                id="emergMobile"
                style={{ fontSize: "13px" }}
                type="text"
                className="form-control form-control-sm "
                name="emergMobile"
                value={formValue.emergMobile}
                onChange={handleChange}
              />
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {formError.emergMobile}
              </p>
            </div>
            <div className="form-group col-md-4">
              <label
                className="form-label"
                htmlFor="emergRelation"
                style={{ fontSize: "12px" }}
              >
                Emergency Relation
              </label>
              <input
                id="emergRelation"
                style={{ fontSize: "13px" }}
                type="text"
                className="form-control form-control-sm "
                name="emergRelation"
                value={formValue.emergRelation}
                onChange={handleChange}
              />
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {formError.emergRelation}
              </p>
            </div>

            <div className="form-group col-md-4">
              <label
                className="form-label"
                htmlFor="email"
                style={{ fontSize: "12px" }}
              >
                Email
              </label>
              <input
                id="email"
                style={{ fontSize: "13px" }}
                type="text"
                className="form-control form-control-sm "
                name="email"
                value={formValue.email}
                onChange={handleChange}
              />
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {formError.email}
              </p>
            </div>
            <div className="form-group col-md-4">
              <label
                className="form-label"
                htmlFor="dalitOrJanjati"
                style={{ fontSize: "12px" }}
              >
                Dalit or Janjati
              </label>
              <select
                id="dalitOrJanjati"
                name="dalitOrJanjati"
                onChange={handleChange}
                value={formValue.dalitOrJanjati}
                className="form-select form-select-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="" disabled selected style={{ fontSize: "11px" }}>
                  Select Dalit or Janjati
                </option>

                <option value="Brahaman/Chhetri">Brahaman/Chhetri</option>
                <option value="Dalit">Dalit</option>
                <option value="Janjati">Janjati</option>
                <option value="Other">Other</option>
              </select>
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {formError.dalitOrJanjati}
              </p>
            </div>
            <div className="form-group col-md-4">
              <label
                className="form-label"
                htmlFor="nationality"
                style={{ fontSize: "12px" }}
              >
                Nationality
              </label>
              <select
                id="nationality"
                name="nationality"
                onChange={handleChange}
                value={formValue.nationality}
                className="form-select form-select-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="" disabled selected style={{ fontSize: "11px" }}>
                  Select nationality
                </option>

                <option value="Nepali">Nepali</option>
              </select>
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {formError.nationality}
              </p>
            </div>

            <div className="form-group col-md-6">
              <label
                className="form-label"
                htmlFor="bloodGroup"
                style={{ fontSize: "12px" }}
              >
                Blood Group
              </label>
              <select
                id="bloodGroup"
                name="bloodGroup"
                onChange={handleChange}
                value={formValue.bloodGroup}
                className="form-select form-select-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="" disabled selected style={{ fontSize: "11px" }}>
                  Select Blood Group
                </option>

                <option value="A+">A+</option>
                <option value="A-">A-</option>
                <option value="B+">B+</option>
                <option value="B-">B-</option>
                <option value="O+">O+</option>
                <option value="O-">O-</option>
                <option value="AB+">AB+</option>
                <option value="AB-">AB-</option>
              </select>
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {formError.bloodGroup}
              </p>
            </div>
            <div className="form-group col-md-6">
              <label
                className="form-label"
                htmlFor="religion"
                style={{ fontSize: "12px" }}
              >
                Religion
              </label>
              <select
                id="religion"
                name="religion"
                onChange={handleChange}
                value={formValue.religion}
                className="form-select form-select-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="" disabled selected style={{ fontSize: "11px" }}>
                  Select Religion
                </option>

                <option value="Hindu">Hindu</option>
                <option value="Buddhist">Buddhist</option>
                <option value="Christian">Christian</option>
                <option value="Muslim">Muslim</option>
                <option value="Kirat">Kirat</option>
                <option value="Other">Other</option>
              </select>
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {formError.religion}
              </p>
            </div>

            <div className="form-group col-md-6">
              <label
                className="form-label"
                htmlFor="previousSchool"
                style={{ fontSize: "12px" }}
              >
                Previous School
              </label>
              <input
                id="previousSchool"
                style={{ fontSize: "13px" }}
                type="text"
                className="form-control form-control-sm "
                name="previousSchool"
                value={formValue.previousSchool}
                onChange={handleChange}
              />
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {formError.previousSchool}
              </p>
            </div>

            <div className="form-group col-md-6">
              <label
                className="form-label"
                htmlFor="hobbies"
                style={{ fontSize: "12px" }}
              >
                Hobbies
              </label>
              <input
                id="hobbies"
                style={{ fontSize: "13px" }}
                type="text"
                className="form-control form-control-sm "
                name="hobbies"
                value={formValue.hobbies}
                onChange={handleChange}
              />
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {formError.hobbies}
              </p>
            </div>
          </div>

          <div className="form-group">
            <label
              className="form-label"
              htmlFor="remarks"
              style={{ fontSize: "12px" }}
            >
              Remarks
            </label>
            <textarea
              id="remarks"
              style={{ fontSize: "13px" }}
              type="text"
              className="form-control form-control-sm "
              name="remarks"
              value={formValue.remarks}
              onChange={handleChange}
              rows="3"
            />
            <p className="errormsg " style={{ marginBottom: "0" }}>
              {formError.remarks}
            </p>
          </div>

          <div className="form-group  ">
            <div
              className="form-label"
              htmlFor="text"
              style={{ fontSize: "12px", textAlign: "left" }}
            >
              Upload Image
            </div>

            <div className="BoxUpload">
              <div className="image-upload">
                {!isUploaded ? (
                  <>
                    <label htmlFor="upload-input">
                      <img
                        src={Plus}
                        draggable={"false"}
                        alt="placeholder"
                        style={{ width: 90, height: 100, paddingTop: "10px" }}
                      />
                    </label>

                    <input
                      id="upload-input"
                      type="file"
                      accept=".jpg,.jpeg,.gif,.png,.mov,.mp4,.pdf"
                      onChange={handleImageChange}
                      name="image"
                    />
                  </>
                ) : (
                  <div className="ImagePreview">
                    <img
                      className="close-icon"
                      src={CloseIcon}
                      alt="CloseIcon"
                      onClick={() => {
                        setIsUploaded(false);
                        setImage(null);
                      }}
                    />

                    <img
                      id="uploaded-image"
                      src={image}
                      draggable={false}
                      alt="uploaded-img"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="FormFooter">
        <div className="checkedClose">
          <input
            type="checkbox"
            className="form-check-input"
            id="exampleCheck2"
            name="closeChecked"
            onChange={handleOnChange}
            checked={closeChecked}
          />
          <label class="form-check-label" for="exampleCheck2">
            Exit after submit
          </label>
        </div>
        <div>
          <button
            className="btn btn-sm btn-cmpy ml-2"
            style={{ background: "green" }}
            onClick={formSubmit}
          >
            Submit
          </button>
          <button className="btn btn-sm btn-cmpy ml-2" onClick={closePopup}>
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}
