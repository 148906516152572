import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import SidebarContext from "../sidebarcontext/SidebarContext";
import Spinner from "../loading/spinner";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import { convertToNep } from "../hooks/dateConvertor";
import Tippy from "@tippyjs/react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import PaymentContext from "../contextfolder/PaymentContext/PaymentContext";

export default function PaymentReport() {
  const { todayDate, newMonth, userinfo } = useContext(SidebarContext);

  const {
    loading,
    originalList,
    classList,
    sectionList,
    selectClass,
    setSelectClass,
    selectSection,
    setSelectSection,
    selectStudent,
    setSelectStudent,
    studentList,
    paymentReportList,
    setPaymentReportList,
    reportDateValue,
    setReportDateValue,
    reportOption,
    setReportOption,
    paymentGateway,
    setPaymentGateway,
    paymentStatus,
    setPaymentStatus,
  } = useContext(PaymentContext);

  const handleReport = (e) => {
    const target = e.target;
    const value = target.value;
    setReportOption(value);
  };

  const handleClass = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectClass(value);
    setSelectSection("");
    setSelectStudent("");
  };

  const handleSection = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectSection(value);
    setSelectStudent("");
  };

  const handleStudent = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectStudent(value);
  };

  const handleGateway = (e) => {
    const target = e.target;
    const value = target.value;
    setPaymentGateway(value);
  };

  const handleStatus = (e) => {
    const target = e.target;
    const value = target.value;
    setPaymentStatus(value);
  };

  const handleFormDate = ({ adDate }) => {
    setReportDateValue({ ...reportDateValue, fromDate: adDate });
  };

  const handleToDate = ({ adDate }) => {
    setReportDateValue({ ...reportDateValue, toDate: adDate });
  };

  const handleSpecificDate = ({ adDate }) => {
    setReportDateValue({ ...reportDateValue, specificDate: adDate });
  };

  let amount = paymentReportList.reduce(function (prev, current) {
    return prev + parseFloat(current.Amount);
  }, 0);

  const searchInput = useRef("");

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["FullName"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setPaymentReportList(srchResult);
      } else {
        setPaymentReportList({});
      }
    } else {
      setPaymentReportList(originalList);
    }
  };

  /* This is for export csv */

  const dataToExcel = paymentReportList.map((d, i) => ({
    "S.N.": i + 1,
    Date: d.CreatedDate,
    Name: d.FullName,
    Gateway: d.Gateway,
    Status: d.Status,
    Amount: parseFloat(d.Amount).toFixed(2),
  }));

  const toExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(dataToExcel);
      var wscols = [
        { wch: 5 },
        { wch: 25 },
        { wch: 25 },
        { wch: 25 },
        { wch: 25 },
        { wch: 25 },
      ];
      worksheet["!cols"] = wscols;
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "Payment_Report");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_" + "export" + EXCEL_EXTENSION
        );
      }
    });
  };

  //end

  //This is to export pdf

  const dataWithIndex = paymentReportList.map((d, i) => ({
    ...d,
    Index: i + 1,
    Amount: parseFloat(d.Amount).toFixed(2),
  }));

  const cols = [
    { header: "S.N", field: "Index" },
    { header: "Date", field: "CreatedDate" },
    { header: "Name", field: "FullName" },
    { header: "Gateway", field: "Gateway" },
    { header: "Status", field: "Status" },
    { header: "Amount", field: "Amount" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const toPdf = () => {
    const doc = new jsPDF("p", "pt", "a4", true);

    var midPage = doc.internal.pageSize.getWidth() / 2;

    // doc.addImage(`${userinfo.SchPhoto}`, "JPG", 40, 40, 70, 60);

    doc.setFontSize(18);
    doc.text(`${userinfo.SchName}`, midPage, 50, null, null, "center");

    doc.setFontSize(15);
    doc.text(`${userinfo.SchAddress}`, midPage, 68, null, null, "center");

    doc.setFontSize(10);
    doc.text(
      `Tel:${userinfo.Phone}, E-mail:${userinfo.Email}`,
      midPage,
      85,
      null,
      null,
      "center"
    );

    doc.autoTable({
      startY: 100,
      theme: "grid",
      columns: exportColumns,
      body: dataWithIndex,
    });
    doc.save("Payment_Report.pdf");
  };
  //end

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <div className="sec-dataTable">
        <div className="upper-dataTbl">
          <Tippy content="PDF">
            <button className="exportButtons ml-1" onClick={toPdf}>
              <i className="bi bi-file-earmark-pdf-fill exportButtonIconsPdf"></i>
            </button>
          </Tippy>
          <Tippy content="Excel">
            <button className="exportButtons" onClick={toExcel}>
              <i className="bi bi-file-earmark-excel-fill exportButtonIconsExcel"></i>
            </button>
          </Tippy>
        </div>

        <hr className="new-hr-new" />
        {paymentReportList.length > 0 ? (
          <div className="totalTrans">
            {" "}
            {parseFloat(amount).toFixed(2)} <b> &nbsp;Total Amount :&nbsp;</b>{" "}
          </div>
        ) : (
          <></>
        )}

        <div className="row" style={{ margin: "0" }}>
          <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
            <label className="list-label">Class</label>
            <select
              style={{ fontSize: "11px", marginBottom: "3px" }}
              name="snotifiaction"
              value={selectClass}
              onChange={handleClass}
              className="form-control form-control-sm"
              aria-label="Default select example "
            >
              <option value="" disabled selected style={{ fontSize: "11px" }}>
                Select Class
              </option>
              {classList.map((item) => (
                <option key={item.ClassID} value={item.ClassID}>
                  {item.ClassName}
                </option>
              ))}
            </select>
            <i class="fas fa-angle-down  notificationpositon"></i>
          </div>

          <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
            <label className="list-label">Section</label>
            <select
              style={{ fontSize: "11px", marginBottom: "3px" }}
              name="snotifiaction"
              value={selectSection}
              onChange={handleSection}
              className="form-control form-control-sm"
              aria-label="Default select example "
            >
              <option value="" disabled selected style={{ fontSize: "11px" }}>
                Select Section
              </option>
              {sectionList.map((item) => (
                <option key={item.SectionID} value={item.SectionID}>
                  {item.SectionName}
                </option>
              ))}
            </select>
            <i class="fas fa-angle-down  notificationpositon"></i>
          </div>

          <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
            <label className="list-label">Student</label>
            <select
              style={{ fontSize: "11px", marginBottom: "3px" }}
              name="snotifiaction"
              value={selectStudent}
              onChange={handleStudent}
              className="form-control form-control-sm"
              aria-label="Default select example "
            >
              <option value="" disabled selected style={{ fontSize: "11px" }}>
                Select Student
              </option>
              {studentList.map((item) => (
                <option key={item.StudentID} value={item.StudentID}>
                  {item.StdFullName}
                </option>
              ))}
            </select>
            <i class="fas fa-angle-down  notificationpositon"></i>
          </div>

          <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
            <label className="list-label">Select Gateway</label>
            <select
              style={{ fontSize: "11px" }}
              name="status"
              className="form-control form-control-sm"
              aria-label="Default select example "
              value={paymentGateway}
              onChange={handleGateway}
            >
              <option selected disabled value="" style={{ fontSize: "11px" }}>
                Select Gateway
              </option>
              <option value="-1">All</option>
              <option value="KHALTI">Khalti</option>
              <option value="ESEWA">Esewa</option>
              <option value="IMEPAY">IME Pay</option>
            </select>
            <i class="fas fa-angle-down  notificationpositon"></i>
          </div>

          <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
            <label className="list-label">Select Status</label>
            <select
              style={{ fontSize: "11px" }}
              name="status"
              className="form-control form-control-sm"
              aria-label="Default select example "
              value={paymentStatus}
              onChange={handleStatus}
            >
              <option selected disabled value="" style={{ fontSize: "11px" }}>
                Select Status
              </option>
              <option value="-1">All</option>
              <option value="PENDING">Pending</option>
              <option value="FAILED">Failed</option>
              <option value="SUCCESS">Success</option>
            </select>
            <i class="fas fa-angle-down  notificationpositon"></i>
          </div>

          <div
            className="col-lg-2 col-md-2 col-sm-2 dropdown2"
            style={{
              marginTop: "28px",
              paddingRight: "1px",
              marginBottom: "10px",
            }}
          >
            <div className="d-flex">
              <input
                placeholder="Search"
                ref={searchInput}
                type="text"
                className="form-control form-control-sm searchField"
                onChange={searchHandler}
              />
            </div>
          </div>

          <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
            <label className="list-label">Select Option</label>
            <select
              style={{ fontSize: "11px" }}
              name="status"
              className="form-control form-control-sm"
              aria-label="Default select example "
              value={reportOption}
              onChange={handleReport}
            >
              <option selected disabled value="" style={{ fontSize: "11px" }}>
                Select Option
              </option>
              <option value="S">Specific Date</option>
              <option value="F">From & To Date</option>
            </select>
            <i class="fas fa-angle-down  notificationpositon"></i>
          </div>
          {reportOption === "F" && (
            <>
              <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                <label className="list-label">From Date</label>
                <Calendar
                  className="form-control form-control-sm mb-1"
                  dateFormat="YYYY/MM/DD"
                  theme="default"
                  hideDefaultValue={true}
                  language="en"
                  placeholder={newMonth}
                  values={reportDateValue.fromDate}
                  onChange={handleFormDate}
                />
              </div>

              <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                <label className="list-label">To Date</label>
                <Calendar
                  className="form-control form-control-sm mb-1"
                  dateFormat="YYYY/MM/DD"
                  theme="default"
                  language="en"
                  values={reportDateValue.toDate}
                  onChange={handleToDate}
                />
              </div>
            </>
          )}
          {reportOption === "S" && (
            <>
              <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                <label className="list-label">Specific Date</label>
                <Calendar
                  className="form-control form-control-sm mb-1"
                  dateFormat="YYYY/MM/DD"
                  theme="default"
                  language="en"
                  values={reportDateValue.specificDate}
                  onChange={handleSpecificDate}
                />
              </div>
            </>
          )}
        </div>

        {loading ? (
          <Spinner />
        ) : (
          <>
            {paymentReportList.length > 0 ? (
              <div className="table-responsive br-3">
                <table className="table table-striped table-bordereds text-nowrap mb-0">
                  <thead>
                    <tr className="tableHead">
                      <td className="tc" style={{ width: "70px" }}>
                        S.N.
                      </td>
                      <td className="tl" style={{ width: "145px" }}>
                        Date
                      </td>
                      <td className="tl">Name</td>
                      <td className="tr" style={{ width: "150px" }}>
                        Gateway
                      </td>
                      <td className="tr" style={{ width: "150px" }}>
                        Status
                      </td>
                      <td className="tr" style={{ width: "150px" }}>
                        Amount
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {paymentReportList.map((item, i) => (
                      <tr key={i}>
                        <td className="tc" style={{ width: "70px" }}>
                          {i + 1}
                        </td>
                        <td className="tl">{item.CreatedDate}</td>
                        <td className="tl">{item.FullName}</td>
                        <td className="tr">{item.Gateway}</td>
                        <td className="tr">{item.Status}</td>
                        <td className="tr">
                          {parseFloat(item.Amount).toFixed(2)}
                        </td>
                      </tr>
                    ))}
                    <tr className="lastt">
                      <td colspan="5">
                        <b style={{ paddingLeft: "15px" }}>Total</b>
                      </td>
                      <td className="tr">
                        <b>{parseFloat(amount).toFixed(2)}</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="table-responsive br-3">
                <div className="text-center mt-4">
                  <p>There are no records to display.</p>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}
