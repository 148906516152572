import React, { useContext, useRef } from "react";
import DataTable from "react-data-table-component";
import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import '../../css/Commission.css';
import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";
import ExtraActivityContext from "../contextfolder/ExtraActivityContext/ExtraActivityContext";


export default function ExtraActivity() {
    const {customStyles } = useContext(SidebarContext);

    const userinfo = JSON.parse(localStorage.getItem("userInfo"));


    const { loading,
        originalList,
        selectedOptionsOne, setSelectedOptionsOne,
        classList,
        sectionList,
        teacherList,
        terminalList,
        extraList,

        selectClass, setSelectClass,
        selectTeacher, setSelectTeacher,
        selectTerminal, setSelectTerminal,
        selectExtra, setSelectExtra,
        eList, setElist, markList, setMarkList, setLoading } = useContext(ExtraActivityContext)

    const searchInput = useRef("");

    const columns = [
        {
            name: "R.N.",
            width: "63px",
            center: true,
            cell: (row) => row.RollNo,
        },
        {
            name: "Student",
            selector: (row) => row.FullName,
        },
        {
            name: "Extra Activity",
            selector: (row) => row.Subject,
        },
        {
            name: "Value",
            width: "150px",
            center: true,
            selector: (row) => {
                let stdName = row.FullName;
                return (
                    <>
                        <div className="form-group">
                            <input
                                name={stdName}
                                style={{ fontSize: "13px" }}
                                type="text"
                                className="form-control form-control-sm "
                                onChange={(e) =>
                                    handleText([row, e.target.value, e])
                                }
                                defaultValue={row.ExtraActMarks}
                            />
                        </div>
                    </>
                );
            },
        },

    ];


    const regexp = /^[A-Za-z]+$/;

    const markValidation = (mark) => {
        if (mark <= 100 || regexp) {
            return true;
        } else {
            return false;
        }
    }

    const handleText = (data) => {

        let getId = data[0].StudentID
        let stdID = getId;

        let valResult = markValidation(data[1]);
        if (valResult) {
            let indexList = markList
                ? markList.findIndex((list) => list.MemID === stdID)
                : -1;

            if (indexList < 0) {
                setMarkList([
                    ...markList,
                    {
                        MemID: stdID,
                        // SectionID: userinfo.UserType === "T" ? selectedOptions[0].SectionID : selectedOptionsOne,
                        ExtraMarks: data[1],
                    },
                ]);
            } else {
                markList[indexList].ExtraMarks = data[1];
            }
        } else {
            let tagId = data[2].target.name;
            let tag = document.getElementsByName(tagId);

            toast("Error! value exceeded", {
                duration: 2000,
                position: "top-center",
                style: {
                    backgroundColor: "red",
                    borderRadius: "5px",
                    color: "white",
                    fontSize: "13px",
                },
                className: "",
            });
            tag[0].value = "";
        }



    };







    /* API to list for extra activity ends*/

    const searchHandler = (e) => {
        e.preventDefault();

        const srchQuery = searchInput.current.value.toLowerCase();
        if (srchQuery) {

            let srchResult = originalList.filter((list) => {
                return list["FullName"].toLowerCase().includes(srchQuery)
            });

            if (srchResult) {

                setElist(srchResult);
            } else {
                setElist({});
            }
        } else {
            setElist(originalList);
        }
    };

    const handleClass = (e) => {
        const target = e.target;
        const value = target.value;
        setSelectClass(value);
        setSelectTeacher("");
        setSelectTerminal("");
        setSelectExtra("");
    };

    const handleSection = (e) => {
        const target = e.target;
        const value = target.value;
        setSelectedOptionsOne(value);
    }


    const handleTeacher = (e) => {
        const target = e.target;
        const value = target.value;
        setSelectTeacher(value);
        setSelectTerminal("");
        setSelectExtra("");
    };

    const handleTerminal = (e) => {
        const target = e.target;
        const value = target.value;
        setSelectTerminal(value);
        setSelectExtra("");

    };

    const handleExtra = (e) => {
        const target = e.target;
        const value = target.value;
        setSelectExtra(value);
        setLoading(true);
    };


    // const dropDownValue = sectionList.map((item) => ({
    //     "value": item.SectionID,
    //     "label": item.SectionName
    // }))

    return (

        <>
            <div className="sec-dataTable">


                {loading ? (
                    <Spinner />
                ) : (
                    <DataTable
                        columns={columns}
                        data={eList}
                        customStyles={customStyles}
                        pagination
                        fixedHeader
                        fixedHeaderScrollHeight="280px"
                        highlightOnHover
                        pointerOnHover
                        responsive
                        dense
                        striped
                        subHeader
                        subHeaderAlign="left"
                        subHeaderComponent={
                            <>
                                <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                                    <label className="list-label">Class</label>
                                    <select
                                        style={{ fontSize: "11px" }}
                                        name="snotifiaction"
                                        value={selectClass}
                                        onChange={handleClass}
                                        className="form-control form-control-sm"
                                        aria-label="Default select example "
                                    >
                                        <option
                                            value=""
                                            disabled
                                            selected
                                            style={{ fontSize: "11px" }}
                                        >
                                            Select Class
                                        </option>


                                        {classList.map((item) => (
                                            <option
                                                key={item.ClassID}
                                                value={item.ClassID}
                                            >
                                                {item.ClassName}
                                            </option>
                                        ))}
                                    </select>
                                    <i class="fas fa-angle-down  notificationpositon"></i>
                                </div>

                                <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                                    <label className="list-label">Section</label>
                                    {/* {userinfo.UserType === "T" ? (
                                        <Select
                                            className="select"
                                            style={{ fontSize: "11px", marginBottom: "3px" }}
                                            isMulti
                                            options={dropDownValue}
                                            onChange={(item) => setSelectedOptions(item.map((item) => ({
                                                "SectionID": item.value,
                                            })))}
                                            isSearchable={false}
                                        />
                                    ) : ( */}
                                    <select
                                        style={{ fontSize: "11px" }}
                                        name="status"
                                        className="form-control form-control-sm"
                                        aria-label="Default select example "
                                        value={selectedOptionsOne}
                                        onChange={handleSection}
                                    >
                                        <option selected disabled value="" style={{ fontSize: "11px" }}>
                                            Select Option
                                        </option>
                                        {sectionList.map((item) => (
                                            <option
                                                key={item.SectionID}
                                                value={item.SectionID}
                                            >
                                                {item.SectionName}
                                            </option>
                                        ))}
                                    </select>
                                    {/* )} */}
                                    <i className="fas fa-angle-down  notificationpositon"></i>
                                </div>

                                {userinfo.UserType !== "T" && (

                                    <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                                        <label className="list-label">Teacher</label>
                                        <select
                                            style={{ fontSize: "11px" }}
                                            name="snotifiaction"
                                            value={selectTeacher}
                                            onChange={handleTeacher}
                                            className="form-control form-control-sm"
                                            aria-label="Default select example"
                                        >
                                            <option
                                                value=""
                                                disabled
                                                selected
                                                style={{ fontSize: "11px" }}
                                            >
                                                Select Teacher
                                            </option>

                                            {teacherList.map((item) => (
                                                <option
                                                    key={item.UserID}
                                                    value={item.UserID}
                                                >
                                                    {item.FullName}
                                                </option>
                                            ))}
                                        </select>
                                        <i class="fas fa-angle-down  notificationpositon"></i>
                                    </div>
                                )}

                                <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                                    <label className="list-label">Terminal</label>
                                    <select
                                        style={{ fontSize: "11px" }}
                                        name="snotifiaction"
                                        value={selectTerminal}
                                        onChange={handleTerminal}
                                        className="form-control form-control-sm"
                                        aria-label="Default select example"
                                    >
                                        <option
                                            value=""
                                            disabled
                                            selected
                                            style={{ fontSize: "11px" }}
                                        >
                                            Select Terminal
                                        </option>

                                        {terminalList.map((item) => (
                                            <option
                                                key={item.TermID}
                                                value={item.TermID}
                                            >
                                                {item.TermName}
                                            </option>
                                        ))}
                                    </select>
                                    <i class="fas fa-angle-down  notificationpositon"></i>
                                </div>


                                <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                                    <label className="list-label">Extra Activity</label>
                                    <select
                                        style={{ fontSize: "11px" }}
                                        name="snotifiaction"
                                        value={selectExtra}
                                        onChange={handleExtra}
                                        className="form-control form-control-sm"
                                        aria-label="Default select example"
                                    >
                                        <option
                                            value=""
                                            disabled
                                            selected
                                            style={{ fontSize: "11px" }}
                                        >
                                            Select Extra Activity
                                        </option>

                                        {extraList.map((item) => (
                                            <option
                                                key={item.ExtraID}
                                                value={item.ExtraID}
                                            >
                                                {item.ExtraActivity}
                                            </option>
                                        ))}
                                    </select>
                                    <i class="fas fa-angle-down  notificationpositon"></i>
                                </div>


                                <div className={userinfo.UserType === "T" ? "col-lg-2 offset-lg-2 offset-md-2 offset-sm- 0 col-md-2 col-sm-2 dropdown2" : "col-lg-2 col-md-2 col-sm-2 dropdown2"} style={{ marginTop: "27px", paddingRight: "1px" }}>
                                    <div className="d-flex">
                                        <input
                                            placeholder="Search"
                                            ref={searchInput}
                                            type="text"
                                            className="form-control form-control-sm searchField"
                                            onChange={searchHandler}
                                        />
                                    </div>
                                </div>
                            </>
                        }
                    />
                )}
            </div>
        </>

    );
}
