import React, { useContext, useRef, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../../css/Commission.css";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import Spinner from "../../loading/spinner";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Fetchdata } from "../../hooks/getData";

export default function TeacherRoutinePDF() {
  const { appURL, customStyles, userinfo, hideAction } =
    useContext(SidebarContext);

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);

  const [classList, setClassList] = useState([]);
  const [sectionList, setSectionList] = useState([]);

  const [selectClass, setSelectClass] = useState("");
  const [selectSection, setSelectSection] = useState("");

  //API to get class list

  useEffect(() => {
    getClassList();
  }, []);

  const getClassList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      DFlag: "C",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";

        setClassList(postResult);
      } else {
        setClassList([]);
      }
    });
  };

  useEffect(() => {
    getSectionList();
  }, [selectClass]);

  const getSectionList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      ClassID: selectClass,
      DFlag: "S",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setSectionList(postResult);
      } else {
        setSectionList([]);
      }
    });
  };

  // API for exam routine list
  const [routineList, setRoutineList] = useState([]);

  useEffect(() => {
    getRoutineList();
  }, [selectClass, selectSection]);

  const getRoutineList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      Flag: "S",
      ClassID: selectClass,
      SectionID: selectSection,
      RoutineType: "B",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/attach-routine`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.RoutineLst ? result.RoutineLst : "";
        setLoading(false);
        setRoutineList(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        setRoutineList([]);
        setOriginalList([]);
      }
    });
  };

  const handleClass = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectClass(value);
    setSelectSection("");
  };

  const handleSection = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectSection(value);
  };

  const searchInput = useRef("");

  const columns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },

    {
      name: "Routine",
      selector: (row) => row.Title,
    },

    {
      name: "Action",
      center: true,
      width: "100px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <Tippy content="View">
                <i
                  className="bi bi-eye  solvespan--action"
                  style={{ paddingTop: "3px" }}
                  onClick={() => openInNewTab(row.Attachment)}
                ></i>
              </Tippy>
            </div>
          </>
        );
      },
    },
  ];

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["Title"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setRoutineList(srchResult);
      } else {
        setRoutineList({});
      }
    } else {
      setRoutineList(originalList);
    }
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />

      <>
        <div className="sec-dataTable">
          {/* <div className="upper-dataTbl">
            <Tippy content="PDF">
              <button className="exportButtons ml-1" onClick={toPdf}>
                <i className="bi bi-file-earmark-pdf-fill exportButtonIconsPdf"></i>
              </button>
            </Tippy>
            <Tippy content="Excel">
              <button className="exportButtons" onClick={toExcel}>
                <i className="bi bi-file-earmark-excel-fill exportButtonIconsExcel"></i>
              </button>
            </Tippy>
          </div> */}
          {/* <hr className="new-hr-new" /> */}

          {loading ? (
            <Spinner />
          ) : (
            <DataTable
              columns={columns}
              data={routineList}
              customStyles={customStyles}
              pagination
              fixedHeader
              fixedHeaderScrollHeight="280px"
              highlightOnHover
              pointerOnHover
              responsive
              dense
              striped
              subHeader
              subHeaderAlign="left"
              subHeaderComponent={
                <>
                  <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                    <label className="list-label">Class</label>
                    <select
                      style={{ fontSize: "11px", marginBottom: "3px" }}
                      name="snotifiaction"
                      value={selectClass}
                      onChange={handleClass}
                      className="form-control form-control-sm"
                      aria-label="Default select example "
                    >
                      <option
                        value=""
                        disabled
                        selected
                        style={{ fontSize: "11px" }}
                      >
                        Select Class
                      </option>
                      {classList.map((item) => (
                        <option key={item.ClassID} value={item.ClassID}>
                          {item.ClassName}
                        </option>
                      ))}
                    </select>
                    <i class="fas fa-angle-down  notificationpositon"></i>
                  </div>

                  <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                    <label className="list-label">Section</label>
                    <select
                      style={{ fontSize: "11px", marginBottom: "3px" }}
                      name="snotifiaction"
                      value={selectSection}
                      onChange={handleSection}
                      className="form-control form-control-sm"
                      aria-label="Default select example "
                    >
                      <option
                        value=""
                        disabled
                        selected
                        style={{ fontSize: "11px" }}
                      >
                        Select Section
                      </option>
                      {sectionList.map((item) => (
                        <option key={item.SectionID} value={item.SectionID}>
                          {item.SectionName}
                        </option>
                      ))}
                    </select>
                    <i class="fas fa-angle-down  notificationpositon"></i>
                  </div>

                  <div
                    className="col-lg-2 offset-lg-6 offset-md-6 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
                    style={{
                      marginTop: "25px",
                      paddingRight: "1px",
                      marginBottom: "10px",
                    }}
                  >
                    <div className="d-flex">
                      <input
                        placeholder="Search"
                        ref={searchInput}
                        type="text"
                        className="form-control form-control-sm searchField"
                        onChange={searchHandler}
                      />
                    </div>
                  </div>
                </>
              }
            />
          )}
        </div>
      </>
    </>
  );
}
