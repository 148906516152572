import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import MarksInputContext from "../contextfolder/MarksInputContext/MarksInputContext";
import SubjectContext from "../contextfolder/SubjectContext/SubjectContext";
import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";

export default function MarksInput() {
  const { todayDate, customStyles, AcademicYear } = useContext(SidebarContext);
  const {
    loading,
    originalList,
    classList,
    sectionList,
    teacherList,
    subjectList,
    setMarksInputList,
    selectClass,
    setSelectClass,
    selectTeacher,
    setSelectTeacher,
    selectSubject,
    setSelectSubject,
    selectSection,
    setSelectSection,
    termList,
    selectTerm,
    setSelectTerm,
    marksInputList,
    markList,
    setMarkList,
    setIsSubmit,
    isSubmit,
    fetchdata,
    getMarksInputList,

    // marks, setMarks,
    setLoading,
  } = useContext(MarksInputContext);

  const { teachingList } = useContext(SubjectContext);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const searchInput = useRef("");

  const columns = [
    {
      name: "R.N.",
      width: "63px",
      center: true,
      selector: (row) => row.RollNo,
    },
    {
      name: "Student",
      selector: (row) => row.FullName,
    },
    {
      name: "Subject",
      selector: (row) => row.Subject,
    },
    // {
    //     name: "Th",
    //     center: true,
    //     width: "100px",
    //     cell: (row) => {
    //         let stdName = row.FullName;
    //         return (
    //             <>
    //                 <div className="form-group">
    //                     <input
    //                         name={stdName}
    //                         style={{ fontSize: "13px" }}
    //                         type="number"
    //                         max={60}
    //                         defaultValue={row.ThObtain}
    //                         className="form-control form-control-sm "
    //                         onChange={(e) =>
    //                             handleMarks([row, e.target.value, "theory", e])
    //                         }
    //                     />
    //                 </div>
    //             </>
    //         );
    //     },
    // },
    // {
    //     name: "Pr",
    //     center: true,
    //     width: "100px",
    //     cell: (row) => {
    //         let stdName = row.FullName;
    //         return (
    //             <>
    //                 <div className="form-group">
    //                     <input
    //                         id="practical"
    //                         name={stdName}
    //                         style={{ fontSize: "13px" }}
    //                         type="number"
    //                         max="40"
    //                         className="form-control form-control-sm "
    //                         // value={formValues.title}
    //                         onChange={(e) =>
    //                             handleMarks([row, e.target.value, "practical", e])
    //                         }
    //                         defaultValue={row.PrObtain}
    //                     />
    //                 </div>
    //             </>
    //         );
    //     },
    // },
    {
      name: "Total Theory",
      center: true,
      width: "130px",
      selector: (row) => row.ToThObtain,
    },
    {
      name: "Total Practical",
      center: true,
      width: "130px",
      selector: (row) => row.ToPrObtain,
    },

    {
      name: "Th",
      center: true,
      width: "100px",
      cell: (row, index) => {
        return (
          <>
            <input
              type="number"
              style={{ fontSize: "13px" }}
              className="form-control form-control-sm "
              value={row.ThObtain}
              onChange={(event) => handleInputChange(event, row, "ThObtain")}
              defaultValue={row.ThObtain}
            />
          </>
        );
      },
    },
    {
      name: "Pr",
      center: true,
      width: "100px",
      cell: (row, index) => {
        return (
          <>
            <input
              type="number"
              style={{ fontSize: "13px" }}
              className="form-control form-control-sm "
              value={row.PrObtain}
              onChange={(event) => handleInputChange(event, row, "PrObtain")}
              defaultValue={row.PrObtain}
            />
          </>
        );
      },
    },
  ];

  const handleInputChange = (event, row, field) => {
    const value = parseFloat(event.target.value).toString();
    const updatedMarksInputList = marksInputList.map((item) => {
      if (item.MemID === row.MemID) {
        if (field === "ThObtain") {
          return {
            ...item,
            [field]:
              value > parseFloat(row.ToThObtain) || value < 0 ? "0" : value, // limit input value to 100
          };
        } else {
          return {
            ...item,
            [field]:
              value > parseFloat(row.ToPrObtain) || value < 0 ? "0" : value, // limit input value to 100
          };
        }
      } else {
        return item;
      }
    });
    setMarksInputList(updatedMarksInputList);
  };


  const markValidation = (mark) => {
    if (mark[1] === "theory") {
      if (mark[0] <= 100) {
        return true;
      } else {
        return false;
      }
    }
    if (mark[1] === "practical") {
      if (mark[0] <= 100) {
        return true;
      } else {
        return false;
      }
    }
  };

  const handleMarks = (data) => {
    let getId = data[0].MemID;
    let stdID = getId;

    let valResult = markValidation([data[1], data[2]]);
    if (valResult) {
      if (data[2] === "theory") {
        let indexList = markList
          ? markList.findIndex((list) => list.MemID === stdID)
          : -1;

        if (indexList < 0) {
          setMarkList([
            ...markList,
            {
              MemID: stdID,
              ThObtain: data[1],
              PrObtain: "",
            },
          ]);
        } else {
          markList[indexList].ThObtain = data[1];
        }
      }
      if (data[2] === "practical") {
        let indexList = markList
          ? markList.findIndex((list) => list.MemID === stdID)
          : -1;

        if (indexList < 0) {
          setMarkList([
            ...markList,
            {
              MemID: stdID,
              ThObtain: "",
              PrObtain: data[1],
            },
          ]);
        } else {
          markList[indexList].PrObtain = data[1];
        }
      }
    } else {
      if (data[2] === "theory") {
        let tagId = data[3].target.name;
        let tag = document.getElementsByName(tagId);

        toast("Error! value exceeded", {
          duration: 2000,
          position: "top-center",
          style: {
            backgroundColor: "red",
            borderRadius: "5px",
            color: "white",
            fontSize: "13px",
          },
          className: "",
        });
        tag[0].value = "";
      }

      if (data[2] === "practical") {
        let tagId = data[3].target.name;
        let tag = document.getElementsByName(tagId);

        toast("Error! value exceeded", {
          duration: 2000,
          position: "top-center",
          style: {
            backgroundColor: "red",
            borderRadius: "5px",
            color: "white",
            fontSize: "13px",
          },
          className: "",
        });
        tag[1].value = "";
      }
    }
  };

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["FullName"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setMarksInputList(srchResult);
      } else {
        setMarksInputList({});
      }
    } else {
      setMarksInputList(originalList);
    }
  };

  const handleClass = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectClass(value);
    setSelectSection("");
    setSelectTeacher("");
    setSelectSubject("");
    setSelectTerm("");
  };

  const handleSection = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectSection(value);
    setSelectTeacher("");
    setSelectSubject("");
    setSelectTerm("");
  };

  const handleTeacher = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectTeacher(value);
    setSelectSubject("");
    setSelectTerm("");
  };

  const handleSubject = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectSubject(value);
    setSelectTerm("");
    teachingList();
  };

  const handleTerm = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectTerm(value);
    setLoading(true);
  };

  return (
    <>
      <div className="sec-dataTable">
        {loading ? (
          <Spinner />
        ) : (
          <DataTable
            columns={columns}
            data={marksInputList}
            customStyles={customStyles}
            pagination
            fixedHeader
            fixedHeaderScrollHeight="280px"
            highlightOnHover
            pointerOnHover
            responsive
            dense
            striped
            subHeader
            subHeaderAlign="left"
            subHeaderComponent={
              <>
                <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                  <label className="list-label">Class</label>
                  <select
                    style={{ fontSize: "11px" }}
                    name="snotifiaction"
                    value={selectClass}
                    onChange={handleClass}
                    className="form-control form-control-sm"
                    aria-label="Default control example "
                  >
                    <option
                      value=""
                      disabled
                      selected
                      style={{ fontSize: "11px" }}
                    >
                      Select Class
                    </option>

                    {classList.map((item) => (
                      <option key={item.ClassID} value={item.ClassID}>
                        {item.ClassName}
                      </option>
                    ))}
                  </select>
                  <i class="fas fa-angle-down  notificationpositon"></i>
                </div>

                <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                  <label className="list-label">Section</label>
                  <select
                    style={{ fontSize: "11px" }}
                    name="snotifiaction"
                    value={selectSection}
                    onChange={handleSection}
                    className="form-control form-control-sm"
                    aria-label="Default select example "
                  >
                    <option
                      value=""
                      disabled
                      selected
                      style={{ fontSize: "11px" }}
                    >
                      Select Section
                    </option>

                    {sectionList.map((item) => (
                      <option key={item.SectionID} value={item.SectionID}>
                        {item.SectionName}
                      </option>
                    ))}
                  </select>
                  <i class="fas fa-angle-down  notificationpositon"></i>
                </div>

                {userinfo.UserType !== "T" && (
                  <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                    <label className="list-label">Teacher</label>
                    <select
                      style={{ fontSize: "11px" }}
                      name="snotifiaction"
                      value={selectTeacher}
                      onChange={handleTeacher}
                      className="form-control form-control-sm"
                      aria-label="Default select example"
                    >
                      <option
                        value=""
                        disabled
                        selected
                        style={{ fontSize: "11px" }}
                      >
                        Select Teacher
                      </option>

                      {teacherList.map((item) => (
                        <option key={item.UserID} value={item.UserID}>
                          {item.FullName}
                        </option>
                      ))}
                    </select>
                    <i class="fas fa-angle-down  notificationpositon"></i>
                  </div>
                )}

                <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                  <label className="list-label">Subject</label>
                  <select
                    style={{ fontSize: "11px" }}
                    name="snotifiaction"
                    value={selectSubject}
                    onChange={handleSubject}
                    className="form-control form-control-sm"
                    aria-label="Default select example"
                  >
                    <option
                      value=""
                      disabled
                      selected
                      style={{ fontSize: "11px" }}
                    >
                      Select Subject
                    </option>

                    {subjectList.map((item) => (
                      <option key={item.SubjectID} value={item.SubjectID}>
                        {item.SubjectName}
                      </option>
                    ))}
                  </select>
                  <i class="fas fa-angle-down  notificationpositon"></i>
                </div>

                <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                  <label className="list-label">Term</label>
                  <select
                    style={{ fontSize: "11px" }}
                    name="snotifiaction"
                    value={selectTerm}
                    onChange={handleTerm}
                    className="form-control form-control-sm"
                    aria-label="Default select example"
                  >
                    <option
                      value=""
                      disabled
                      selected
                      style={{ fontSize: "11px" }}
                    >
                      Select Term
                    </option>

                    {termList.map((item) => (
                      <option key={item.TermID} value={item.TermID}>
                        {item.TermName}
                      </option>
                    ))}
                  </select>
                  <i class="fas fa-angle-down  notificationpositon"></i>
                </div>

                <div
                  className={
                    userinfo.UserType === "T"
                      ? "col-lg-2 offset-lg-2 col-md-2 offset-md-2 col-sm-2 dropdown2"
                      : "col-lg-2 col-md-2 col-sm-2 dropdown2"
                  }
                  style={{
                    marginTop: "27px",
                    paddingRight: "1px",
                    marginBottom: "10px",
                  }}
                >
                  <div className="d-flex">
                    <input
                      placeholder="Search"
                      ref={searchInput}
                      type="text"
                      className="form-control form-control-sm searchField"
                      onChange={searchHandler}
                    />
                  </div>
                </div>
              </>
            }
          />
        )}
      </div>
    </>
  );
}
