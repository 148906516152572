import React, { useContext, useEffect, useState } from "react";
import UploadRoutineContext from "../contextfolder/UploadRoutineContext/UploadRoutineContext";
import { Fetchdata } from "../hooks/getData";
import SidebarContext from "../sidebarcontext/SidebarContext";

export default function UploadRoutineForm({ closePopup }) {
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const { appURL } = useContext(SidebarContext);

  const {
    toggleUploadPopup,
    uploadFormError,
    setUploadFormError,
    uploadIsSubmit,
    setUploadIsSubmit,
    uploadValue,
    uploadFormValue,
    setUploadFormValue,
    closeCheckeds,
    setCloseCheckeds,
    setPdfBase64,
    fetchdata,
  } = useContext(UploadRoutineContext);

  const handleOnChange = () => {
    setCloseCheckeds(!closeCheckeds);
  };

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setUploadFormValue({ ...uploadFormValue, [name]: value });
  };

  const handlePdfUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setPdfBase64(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setUploadFormError(validate(uploadFormValue));
    setUploadIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(uploadFormError).length === 0 && uploadIsSubmit) {
      fetchdata(uploadFormValue);
      if (closeCheckeds === true) {
        toggleUploadPopup();
      }
      setUploadFormValue(uploadValue);
      setUploadIsSubmit(false);
      setPdfBase64("");
    }
  }, [uploadFormError]);

  const validate = (values) => {
    const errors = {};
    if (!values.classDetails) {
      errors.classDetails = "Required";
    }

    if (uploadFormValue.routineType === "C") {
      if (!values.section) {
        errors.section = "Required";
      }
    }
    if (uploadFormValue.routineType === "E") {
      if (!values.term) {
        errors.term = "Required";
      }
    }
    return errors;
  };

  const [classList, setClassList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [termList, setTermList] = useState([]);

  //API to hit class list
  useEffect(() => {
    getClassList();
  }, []);

  const getClassList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      DFlag: "C",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setClassList(postResult);
      } else {
        setClassList([]);
      }
    });
  };
  //API to hit section list
  useEffect(() => {
    getSectionList();
  }, [uploadFormValue.classDetails]);

  const getSectionList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      ClassID: uploadFormValue.classDetails,
      DFlag: "S",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";

        setSectionList(postResult);
      } else {
        setSectionList([]);
      }
    });
  };

  //API to get term list
  useEffect(() => {
    getTermList();
  }, []);

  const getTermList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      DFlag: "T",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";

        setTermList(postResult);
      } else {
        setTermList([]);
      }
    });
  };

  return (
    <>
      <div className="container-fluid basicform">
        <form>
          <div className="form-group">
            <label className="form-label" htmlFor="routineType">
              Select Routine Type<sup style={{ color: "red" }}>*</sup>
            </label>
            <select
              id="routineType"
              name="routineType"
              onChange={handleChange}
              value={uploadFormValue.routineType}
              className="form-select form-select-sm mb-1"
              style={{ fontSize: "12px" }}
            >
              <option value="" selected disabled>
                Select Option
              </option>
              <option value="C">Class Routine</option>
              <option value="E">Exam Routine</option>
            </select>
          </div>

          <div className="form-row">
            <div className="form-group col-md-6">
              <label className="form-label" htmlFor="classDetails">
                Class <sup style={{ color: "red" }}>*</sup>
              </label>
              <select
                id="classDetails"
                name="classDetails"
                onChange={handleChange}
                value={uploadFormValue.classDetails}
                className="form-select form-select-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="" selected disabled>
                  Select Option
                </option>
                {classList.map((item, i) => (
                  <option key={i} value={item.ClassID}>
                    {item.ClassName}
                  </option>
                ))}
              </select>
              <p className="errormsg" style={{ marginBottom: "0" }}>
                {uploadFormError.classDetails}
              </p>
            </div>
            {uploadFormValue.routineType === "C" ? (
              <div className="form-group col-md-6">
                <label className="form-label" htmlFor="section">
                  Section <sup style={{ color: "red" }}>*</sup>
                </label>
                <select
                  id="section"
                  name="section"
                  onChange={handleChange}
                  value={uploadFormValue.section}
                  className="form-select form-select-sm mb-1"
                  style={{ fontSize: "12px" }}
                >
                  <option value="" selected disabled>
                    Select Option
                  </option>
                  {sectionList.map((item, i) => (
                    <option key={i} value={item.SectionID}>
                      {item.SectionName}
                    </option>
                  ))}
                </select>
                <p className="errormsg" style={{ marginBottom: "0" }}>
                  {uploadFormError.section}
                </p>
              </div>
            ) : (
              <>
                <div className="form-group col-md-6">
                  <label className="form-label" htmlFor="section">
                    Section <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <select
                    id="section"
                    name="section"
                    onChange={handleChange}
                    value={uploadFormValue.section}
                    className="form-select form-select-sm mb-1"
                    style={{ fontSize: "12px" }}
                  >
                    <option value="" selected disabled>
                      Select Option
                    </option>
                    <option value="-1">Select All</option>
                    {sectionList.map((item, i) => (
                      <option key={i} value={item.SectionID}>
                        {item.SectionName}
                      </option>
                    ))}
                  </select>
                  <p className="errormsg" style={{ marginBottom: "0" }}>
                    {uploadFormError.section}
                  </p>
                </div>
                <div className="form-group col-12">
                  <label className="form-label" htmlFor="term">
                    Term<sup style={{ color: "red" }}>*</sup>
                  </label>
                  <select
                    id="term"
                    name="term"
                    onChange={handleChange}
                    value={uploadFormValue.term}
                    className="form-select form-select-sm mb-1"
                    style={{ fontSize: "12px" }}
                  >
                    <option value="" selected disabled>
                      Select Option
                    </option>
                    {termList.map((item, i) => (
                      <option key={i} value={item.TermID}>
                        {item.TermName}
                      </option>
                    ))}
                  </select>
                  <p className="errormsg" style={{ marginBottom: "0" }}>
                    {uploadFormError.term}
                  </p>
                </div>
              </>
            )}
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label
                htmlFor="pdf-upload"
                className="form-label"
                style={{ display: "block", marginBottom: "10px" }}
              >
                Upload PDF
              </label>
              <input
                id="pdf-upload"
                type="file"
                accept=".pdf"
                onChange={handlePdfUpload}
              />
            </div>
          </div>
        </form>
      </div>
      <div className="FormFooter">
        <div className="checkedClose">
          <input
            type="checkbox"
            className="form-check-input"
            id="exampleCheck2"
            name="closeChecked"
            onChange={handleOnChange}
            checked={closeCheckeds}
          />
          <label class="form-check-label" for="exampleCheck2">
            Exit after submit
          </label>
        </div>
        <div>
          <button
            className="btn btn-sm btn-cmpy ml-2"
            style={{ background: "green" }}
            onClick={formSubmit}
          >
            Submit
          </button>
          <button className="btn btn-sm btn-cmpy ml-2" onClick={closePopup}>
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}
