import React, { useState } from "react";
import landinglogo from "./../../images/landinglogo.png";
import { MdLocationPin, MdMail, MdOutlineMenu } from "react-icons/md";
import { FaPhoneAlt, FaFacebookF } from "react-icons/fa";
import SchLogo from "./../../images/logoOfSchool.png";
const Header = ({ schoolInfo }) => {
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const [imageExists, setImageExists] = useState(true);
  const handleImageError = () => {
    setImageExists(false);
  };

  return (
    <>
      <div className="header__social">
        <div className="uk-container">
          <div className="header__wrapper uk-flex uk-flex-between uk-flex-middle">
            <div className="social-links mt-0">
              <a target="_blank" href={schoolInfo.SchFb} className="facebook">
                <i class="fab fa-facebook-f"></i>
              </a>
              <a
                target="_blank"
                href={schoolInfo.SchTwitter}
                className="twitter"
              >
                <i class="fab fa-twitter"></i>
              </a>

              <a target="_blank" href={schoolInfo.SchYt} className="instagram">
                <i class="fab fa-youtube"></i>
              </a>
            </div>
            <div className="uk-flex gap-4 uk-flex-middle">
              <div>
                <span>
                  <MdLocationPin />
                  {schoolInfo.SchAddress}
                </span>
              </div>
              <div>
                <a href={`tel:${schoolInfo.SchTel}`}>
                  <FaPhoneAlt />
                  {schoolInfo.SchTel}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="top__header "
        uk-sticky="top:300; animation: uk-animation-slide-top;"
      >
        <div className="uk-container d-flex align-items-center justify-content-between">
          <div className="uk-flex uk-flex-middle" style={{ flexGrow: "1" }}>
            <a href="#" className="logo">
              {imageExists ? (
                <img
                  src={schoolInfo.SchLogo}
                  onError={handleImageError}
                  alt="Logo"
                />
              ) : (
                <img
                  style={{
                    background: `${schoolInfo.ScColor}`,
                  }}
                  src={SchLogo}
                  alt="Fallback Logo"
                />
              )}
            </a>
            <div>
              <span
                className="uk-margin-small-left fs-6"
                style={{
                  fontWeight: "bold",
                  color: "#000",
                  maxWidth: "200px",
                  display: "inline-block",
                }}
              >
                {schoolInfo.SchName}
              </span>
            </div>
          </div>

          <nav id="navvbarr" className="navvbarr">
            <ul>
              <li>
                <a className="nav-link scrollto" href="#about__section">
                  About
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#blog__section">
                  Blog
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#event__section">
                  Events
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#album__section">
                  Gallery
                </a>
              </li>
              <li className="dropdown">
                <a>
                  <span>Resource</span> <i className="bi bi-chevron-down"></i>
                </a>
                <ul>
                  <li>
                    <a className="scrollto" href="#">
                      Routine
                    </a>
                  </li>
                  <li>
                    <a className="scrollto" href="#">
                      Report Card
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <button
                  style={{
                    border: "none",
                    background: `${schoolInfo.ScColor}`,
                  }}
                  className="getstarted"
                  // onClick={() => openInNewTab("/login")}
                >
                  Admission
                </button>
              </li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle"></i>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Header;
