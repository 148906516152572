import React, { useContext, useRef } from "react";
import DataTable from "react-data-table-component";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import AttenInputContext from "../contextfolder/AttenInputContext/AttenInputContext";
import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";

export default function AttenInput() {
  const { customStyles } = useContext(SidebarContext);
  const {
    loading,
    originalList,
    classList,
    sectionList,
    setAttenInputList,
    selectClass,
    setSelectClass,
    selectSection,
    setSelectSection,
    termList,
    selectTerm,
    setSelectTerm,
    attenInputList,
    setLoading,
  } = useContext(AttenInputContext);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const searchInput = useRef("");

  const columns = [
    {
      name: "R.N.",
      width: "63px",
      center: true,
      selector: (row) => row.RollNo,
    },
    {
      name: "Full Name",
      selector: (row) => row.FullName,
    },

    {
      name: "Present Days",
      center: true,
      width: "150px",
      cell: (row, index) => {
        return (
          <>
            <input
              type="number"
              style={{ fontSize: "13px" }}
              className="form-control form-control-sm "
              value={row.PresentDays}
              onChange={(event) => handleInputChange(event, row, "PresentDays")}
              defaultValue={row.PresentDays}
            />
          </>
        );
      },
    },
  ];

  const handleInputChange = (event, row, field) => {
    const value = parseFloat(event.target.value).toString();
    const updatedAttenInputList = attenInputList.map((item) => {
      if (item.MemID === row.MemID) {
        return {
          ...item,
          [field]: value,
        };
      } else {
        return item;
      }
    });
    setAttenInputList(updatedAttenInputList);
  };

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["FullName"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setAttenInputList(srchResult);
      } else {
        setAttenInputList({});
      }
    } else {
      setAttenInputList(originalList);
    }
  };

  const handleClass = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectClass(value);
    setSelectSection("");
    setSelectTerm("");
  };

  const handleSection = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectSection(value);
    setSelectTerm("");
  };

  const handleTerm = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectTerm(value);
    setLoading(true);
  };

  return (
    <>
      <div className="sec-dataTable">
        {loading ? (
          <Spinner />
        ) : (
          <DataTable
            columns={columns}
            data={attenInputList}
            customStyles={customStyles}
            pagination
            fixedHeader
            fixedHeaderScrollHeight="280px"
            highlightOnHover
            pointerOnHover
            responsive
            dense
            striped
            subHeader
            subHeaderAlign="left"
            subHeaderComponent={
              <>
                <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                  <label className="list-label">Class</label>
                  <select
                    style={{ fontSize: "11px" }}
                    name="snotifiaction"
                    value={selectClass}
                    onChange={handleClass}
                    className="form-control form-control-sm"
                    aria-label="Default control example "
                  >
                    <option
                      value=""
                      disabled
                      selected
                      style={{ fontSize: "11px" }}
                    >
                      Select Class
                    </option>

                    {classList.map((item) => (
                      <option key={item.ClassID} value={item.ClassID}>
                        {item.ClassName}
                      </option>
                    ))}
                  </select>
                  <i class="fas fa-angle-down  notificationpositon"></i>
                </div>

                <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                  <label className="list-label">Section</label>
                  <select
                    style={{ fontSize: "11px" }}
                    name="snotifiaction"
                    value={selectSection}
                    onChange={handleSection}
                    className="form-control form-control-sm"
                    aria-label="Default select example "
                  >
                    <option
                      value=""
                      disabled
                      selected
                      style={{ fontSize: "11px" }}
                    >
                      Select Section
                    </option>

                    {sectionList.map((item) => (
                      <option key={item.SectionID} value={item.SectionID}>
                        {item.SectionName}
                      </option>
                    ))}
                  </select>
                  <i class="fas fa-angle-down  notificationpositon"></i>
                </div>

                <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                  <label className="list-label">Term</label>
                  <select
                    style={{ fontSize: "11px" }}
                    name="snotifiaction"
                    value={selectTerm}
                    onChange={handleTerm}
                    className="form-control form-control-sm"
                    aria-label="Default select example"
                  >
                    <option
                      value=""
                      disabled
                      selected
                      style={{ fontSize: "11px" }}
                    >
                      Select Term
                    </option>

                    {termList.map((item) => (
                      <option key={item.TermID} value={item.TermID}>
                        {item.TermName}
                      </option>
                    ))}
                  </select>
                  <i class="fas fa-angle-down  notificationpositon"></i>
                </div>

                <div
                  className={
                    userinfo.UserType === "T"
                      ? "col-lg-2 offset-lg-2 col-md-2 offset-md-2 col-sm-2 dropdown2"
                      : "col-lg-2 col-md-2 col-sm-2 dropdown2 offset-lg-4 offset-md-4"
                  }
                  style={{
                    marginTop: "27px",
                    paddingRight: "1px",
                    marginBottom: "10px",
                  }}
                >
                  <div className="d-flex">
                    <input
                      placeholder="Search"
                      ref={searchInput}
                      type="text"
                      className="form-control form-control-sm searchField"
                      onChange={searchHandler}
                    />
                  </div>
                </div>
              </>
            }
          />
        )}
      </div>
    </>
  );
}
