import React, { useContext, useEffect, useState } from "react";
import { Fetchdata } from "../../hooks/getData";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { toast } from "react-toastify";
import SyllabusPdfContext from "./SyllabusPdfContext";

function SyllabusPdfState(props) {
  const { appURL } = useContext(SidebarContext);

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const [closeChecked, setCloseChecked] = useState(false);

  const chapterValue = {
    class: "",
    section: "",
    subject: "",
    chapterNumber: "",
    title: "",
  };
  const [FormValue, setFormValue] = useState(chapterValue);
  const [FormError, setFormError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const [popup, setPopup] = useState(false);
  const togglePopup = () => {
    setPopup(!popup);
  };

  const [isCoverUploaded, setIsCoverUploaded] = useState(false);
  const [typeCoverFile, setTypeCoverFile] = useState();
  const [coverFile, setCoverFile] = useState("");


  const [inputFields, setInputFields] = useState([{ value: "", id: 1 }]);
  const [newData, setNewData] = useState("");

  const [classList, setClassList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);

  const [selectClass, setSelectClass] = useState("");
  const [selectSection, setSelectSection] = useState("");
  const [selectSubject, setSelectSubject] = useState("");

  useEffect(() => {
    getClassList();
  }, []);

  const getClassList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      DFlag: "C",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setClassList(postResult);
      } else {
        setClassList([]);
      }
    });
  };

  useEffect(() => {
    getSectionList();
  }, [selectClass]);

  const getSectionList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      ClassID: selectClass,
      DFlag: "S",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setSectionList(postResult);
      } else {
        setSectionList([]);
      }
    });
  };

  //API to hit Subject List

  useEffect(() => {
    getSubjectList();
  }, [selectClass, selectSection]);

  const getSubjectList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      ClassID: selectClass,
      SectionID:userinfo.UserType === "T"  ? selectSection :"",
      DFlag: "SB",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";

        setSubjectList(postResult);
      } else {
        setSubjectList([]);
      }
    });
  };

  //API to create Chapter
  const fetchdata = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      MemID: userinfo.UserID.toString(),
      UserID: userinfo.UserID.toString(),
      Flag: "i",
      ClassID: FormValue.class,
      SectionID: userinfo.UserType === "T" ? FormValue.section :"",
      SubjectID: FormValue.subject,

      FilePath: typeCoverFile !== null
      ? typeCoverFile.split(",")[1] :"",
     
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/attach-syllabus`,
      Type: "POST",
    };
    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getdataList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };

  // API for Chapter List
  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    getdataList();
  }, [selectClass, selectSubject, selectSection]);

  const getdataList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      MemID: userinfo.UserID.toString(),
      Flag: "S",
      ClassID: selectClass,
      SectionID: userinfo.UserType === "T" ? selectSection :"",
      SubjectID: selectSubject,
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/attach-syllabus`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.AttenLst ? result.AttenLst : "";
        setLoading(false);
        setDataList(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        setDataList([]);
        setOriginalList([]);
      }
    });
  };

  // //Everything to pop up and delete the chapter list
  const [deletePopup, setDeletePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const [delData, setDelData] = useState();

  const handleDelete = async (data) => {
    setDelData(data);
    setDeletePopup({
      show: true,
      data,
    });
  };

  // This will perform the deletion and hide the Confirmation Box

  const handleDeleteTrue = async () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      MemID: userinfo.UserID.toString(),
      Flag: "R",
      SyllabusID: delData.toString(),
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/attach-syllabus`, requestOptions).then(
      (result) => {
        result.json().then((resp) => {
          if (resp.StatusCode === 200) {
            getdataList();
            toast(resp.Message, {
              style: {
                color: "green",
                fontSize: "13px",
              },
            });
          } else {
            toast(resp.Message, {
              style: {
                color: "red",
                fontSize: "13px",
              },
            });
          }
        });
      }
    );
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleDeleteFalse = () => {
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // Ends Here

  return (
    <SyllabusPdfContext.Provider
      value={{
        loading,
        originalList,
        deletePopup,
        handleDeleteTrue,
        handleDeleteFalse,
        handleDelete,
        popup,
        setPopup,
        togglePopup,
        dataList,
        setDataList,
        FormValue,
        setFormValue,
        FormError,
        setFormError,
        isSubmit,
        chapterValue,
        setIsSubmit,
        classList,
        selectClass,
        setSelectClass,
        closeChecked,
        setCloseChecked,
        subjectList,
        selectSubject,
        setSelectSubject,
        sectionList,
        selectSection,
        setSelectSection,
        fetchdata,
        inputFields,
        setInputFields,
        setNewData,
        isCoverUploaded,
        setIsCoverUploaded,
        setTypeCoverFile,
        typeCoverFile,
        coverFile,
        setCoverFile,
      }}
    >
      {props.children}
    </SyllabusPdfContext.Provider>
  );
}

export default SyllabusPdfState;
