import React, { useContext, useEffect, useState } from "react";
import "../css/Alert.css";
import "../App.css";
import { Fetchdata } from "./hooks/getData";
import SidebarContext from "./sidebarcontext/SidebarContext";
import { ToastContainer, toast } from "react-toastify";

export const Alert = (props) => {
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const context = useContext(SidebarContext);
  const { setAlert, setLogout, alert, setresetPassword, appURL } = context;

  const initalvalue = { OldPassword: "", NewPassword: "" };
  const [formValues, setFormValues] = useState(initalvalue);
  const [formErrors, setformErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };
  //
  // const [last, setLast] = useState(false)

  const validate = (values) => {
    const errors = {};

    if (!values.NewPassword) {
      errors.NewPassword = "Required";
    }
    if (!values.OldPassword) {
      errors.OldPassword = "Required";
    }
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setformErrors(validate(formValues));
    setIsSubmit(true);
  };
  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      const dataForm = {
        SchID: userinfo.SchID,
        UserID: userinfo.UserID,
        OldPassword: formValues.OldPassword,
        NewPassword: formValues.NewPassword,
        AuthCode: "A",
        Academicyear: userinfo.AcademicYear,
        FetchURL: `${appURL}api/change-password`,
        Type: "POST",
      };

      Fetchdata(dataForm).then(function (result) {
        if (result.StatusCode === 200) {
          toast(result.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
        } else {
          toast("Error: " + result.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });

      setIsSubmit(false);
    }
  }, [formErrors]);

  const handleClose = () => {
    setFormValues(initalvalue);
    setIsSubmit(false);
    setformErrors({});
    setAlert({
      fade: "fade-default",
      msg: "",
      type: "",
    });
  };

  const logoutConform = () => {
    setLogout(true);
  };
  const ConformReset = () => {
    setresetPassword(true);
  };

  const alertType = () => {
    if (alert.type === "logout") {
      return (
        <div className="popUP container-fluid col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="popup-inner-alert container col-lg-12 col-md-12 col-sm-12 col-xs-12  ">
            <div className="popUp-alert-header ">
              <div className="">Easy Software</div>
              <div>
                <button className="btn alertclosebtn" onClick={handleClose}>
                  <i className="bi bi-x"></i>
                </button>
              </div>
            </div>
            <div className=" col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
              <p className="alertText"> {alert.msg}</p>
              <div className="col-lg-12 flexy">
                <div onClick={logoutConform} className="logoutbtn badge ">
                  Logout
                </div>
                <div onClick={handleClose} className="cancelbtn badge mx-2">
                  Cancel
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (alert.type === "reset") {
      return (
        <div className="popUP container-fluid col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="popup-inner-alert container col-lg-12 col-md-12 col-sm-12 col-xs-12  ">
            <div className="popUp-alert-header ">
              <div className="">Easy Software</div>
              <div>
                <button className="btn alertclosebtn" onClick={handleClose}>
                  <i className="bi bi-x"></i>
                </button>
              </div>
            </div>
            <div className=" col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
              <p className="alertText"> {alert.msg}</p>
              <div className="col-lg-12 flexy">
                <div onClick={ConformReset} className="logoutbtn badge ">
                  Reset Password
                </div>
                <div onClick={handleClose} className="cancelbtn badge ">
                  Cancel
                </div>
              </div>{" "}
            </div>
          </div>
        </div>
      );
    } else if (alert.type === "change") {
      return (
        <>
          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
          />
          <div className="popUP container-fluid col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div
              className="popup-inner-alert container col-lg-12 col-md-12 col-sm-12 col-xs-12 "
              style={{ width: "500px" }}
            >
              <div className="popUp-alert-header ">
                <div className="">Change Password</div>
                <div>
                  <button className="btn alertclosebtn" onClick={handleClose}>
                    <i className="bi bi-x"></i>
                  </button>
                </div>
              </div>
              <div className=" col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                {/* <p className='alertText'> {alert.msg}</p> */}

                <div className="form-group" style={{ paddingTop: "10px" }}>
                  <p className="errormsg"> {formErrors.errorv}</p>
                  <label className="form-label" htmlFor="oldPassword">
                    Old Password <sup className="sup-col">*</sup>
                  </label>
                  <input
                    type="password"
                    value={formValues.OldPassword}
                    onChange={handleChange}
                    name="OldPassword"
                    className="form-control form-control-sm mb-1"
                  />
                  <p className="errormsg ">{formErrors.OldPassword}</p>
                </div>
                <div className="form-group" style={{ paddingBottom: "20px" }}>
                  <label className="form-label" htmlFor="newPassword">
                    New Password <sup className="sup-col">*</sup>
                  </label>
                  <input
                    type="password"
                    value={formValues.NewPassword}
                    onChange={handleChange}
                    name="NewPassword"
                    className="form-control form-control-sm mb-1"
                  />
                  <p className="errormsg ">{formErrors.NewPassword}</p>
                </div>
                <div className="col-lg-12 changePasswordFooter">
                  <button
                    className="btn btn-sm btn-cmpy ml-2"
                    style={{ background: "green" }}
                    onClick={handleSubmit}
                  >
                    Change Password
                  </button>
                  <button
                    className="btn btn-sm btn-cmpy ml-2"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  };

  return (
    // <div className="popUP container-fluid col-lg-12 col-md-12 col-sm-12 col-xs-12">
    //   <div className="popup-inner-alert container col-lg-12 col-md-12 col-sm-12 col-xs-12  ">
    //     <div className="popUp-alert-header ">
    //       <div className="">Easy Software</div>
    //       <div><button className='btn alertclosebtn' onClick={handleClose} ><i className="bi bi-x"></i></button></div>
    //     </div>
    //     <div className=' col-lg-12 col-md-12 col-sm-12 col-xs-12 '>
    //       <p className='alertText'> {alert.msg}</p>
    <div>{alertType()}</div>
  );
  //     </div>
  //   </div>
  // </div>
};
