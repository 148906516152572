import React, { useContext, useEffect, useState } from "react";
import { Fetchdata } from "../../hooks/getData";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import StudentContext from "./StudentContext";
import { toast } from "react-toastify";

function StudentState(props) {
  const { appURL } = useContext(SidebarContext);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const [selectedOptions, setSelectedOptions] = useState([]);

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);

  // const [userFormError, setUserFormError] = useState({});
  // const [isSubmit, setIsSubmit] = useState(false);

  const [classList, setClassList] = useState([]);
  const [sectionList, setSectionList] = useState([]);

  const [selectClass, setSelectClass] = useState("");
  const [selectSection, setSelectSection] = useState("");
  useEffect(() => {
    getClassList();
  }, []);

  const getClassList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      DFlag: "C",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setClassList(postResult);
      } else {
        setClassList([]);
      }
    });
  };

  useEffect(() => {
    getSectionList();
  }, [selectClass]);

  const getSectionList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      ClassID: selectClass,
      DFlag: "S",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setSectionList(postResult);
      } else {
        setSectionList([]);
      }
    });
  };

  //API to hit Student list
  const [studentList, setStudentList] = useState([]);

  useEffect(() => {
    stdList();
  }, [selectClass, selectedOptions]);

  const stdList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      Flag: "S",
      ClassID: selectClass,
      SectionID: selectedOptions,
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/student`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.StudentLst ? result.StudentLst : "";
        setLoading(false);
        setStudentList(postResult);
        setOriginalList(postResult);
      } else {
        setStudentList([]);
        setOriginalList([]);
        setLoading(false);
      }
    });
  };

  //Everything to pop up and reset the password list
  const [resetPopup, setResetPopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const [Acdata, setACData] = useState();
  const [resetPassword, setResetPassword] = useState("");

  const handleReset = async (data) => {
    setACData(data[0]);
    setResetPassword(data[1]);

    setResetPopup({
      show: true,
      data,
    });
  };

  // This will perform the deletion and hide the Confirmation Box

  const handleResetTrue = async () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "RP",
      UserName: Acdata,
      Password: resetPassword,
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/student`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          stdList();
          toast(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
          setResetPopup({
            show: false,
            data: null,
          });
        } else {
          toast(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleResetFalse = () => {
    setResetPopup({
      show: false,
      data: null,
    });
  };

  // Ends Here

  //Everything to pop up and delete the student list
  const [deletePopup, setDeletePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const [delData, setDelData] = useState();

  const handleDelete = async (data) => {
    setDelData(data);

    setDeletePopup({
      show: true,
      data,
    });
  };

  // This will perform the deletion and hide the Confirmation Box

  const handleDeleteTrue = async () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "DS",
      UserName: delData,
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/student`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          stdList();
          toast(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
          setDeletePopup({
            show: false,
            data: null,
          });
        } else {
          toast(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleDeleteFalse = () => {
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // Ends Here

  //To Edit User

  // const [comId, setComId] = useState("")

  // const handleEdit = (datas) => {
  //     editToggleModal();
  //     setComId(datas.userCode)
  //     setUserFormValue({
  //         userName: datas.userName,
  //         fullName: datas.fullName,
  //         address: datas.userAddress,
  //     })

  // }

  //API to hit change photo

  const changePhoto = (id, image) => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "IMG",
      UserName: id,
      Image: image !== "" ? image.split(",")[1] : "",
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/student`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          stdList();
          toast(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
        } else {
          toast(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
    });
  };

  // For student view
  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  const [stdUsername, setStdUsername] = useState("");
  const [stdID, setStdID] = useState("");
  const [stdClass, setStdClass] = useState("");
  const [stdSection, setStdSection] = useState("");

  const addnew = (data) => {
    data[0].preventDefault();
    toggleModal();
    setStdUsername(data[1]);
    setStdID(data[2]);
    setStdClass(data[3]);
    setStdSection(data[4]);
  };

  //For About tab

  const [aboutList, setAboutList] = useState([]);

  useEffect(() => {
    abtList();
  }, [stdUsername]);

  const abtList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserName: stdUsername,
      NotificationToken: "S",
      AuthCode: "1",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/check-session`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.sessionRes[0] ? result.sessionRes[0] : "";

        setAboutList(postResult);
      } else {
        setAboutList([]);
      }
    });
  };

  // From Account Tab

  const [accountLoad, setAccountLoad] = useState(true);
  const [accountSearch, setAccountSearch] = useState(null);

  // API for account report list
  const [accountReportList, setAccountReportList] = useState([]);

  useEffect(() => {
    getreportList();
  }, [stdID]);

  const getreportList = () => {
    const params = {
      SchID: userinfo.SchID,
      Flag: "R",
      MemID: stdID.toString(),
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/account`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.AccLst ? result.AccLst : "";
        setAccountLoad(false);
        setAccountReportList(postResult);
        setAccountSearch(postResult);
      } else {
        setAccountLoad(false);
        setAccountReportList([]);
        setAccountSearch([]);
      }
    });
  };

  // From Leave Tab

  const [leaveLoad, setLeaveLoad] = useState(true);
  const [leaveSearch, setLeaveSearch] = useState(null);

  // API for account report list
  const [leaveList, setLeaveList] = useState([]);

  useEffect(() => {
    getLeaveApplicationList();
  }, [stdID]);

  const getLeaveApplicationList = () => {
    const params = {
      SchID: userinfo.SchID,
      MemID: stdID.toString(),
      Flag: "S",
      DFlag: "E",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/leave`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.LeaveLst ? result.LeaveLst : "";

        setLeaveLoad(false);
        setLeaveList(postResult);
        setLeaveSearch(postResult);
      } else {
        setLeaveLoad(false);
        setLeaveList([]);
        setLeaveSearch([]);
      }
    });
  };

  // From Report Card Tab

  const [reportLoad, setReportLoad] = useState(true);
  const [reportSearch, setReportSearch] = useState(null);

  const [reportList, setReportList] = useState([]);

  useEffect(() => {
    getReportList();
  }, [stdID]);

  const getReportList = () => {
    const params = {
      SchID: userinfo.SchID,
      ReportType: "M",
      MemID: stdID.toString(),
      TermID: "-1",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/report-card`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.ReportCardLst ? result.ReportCardLst : "";
        setReportLoad(false);
        setReportList(postResult);
        setReportSearch(postResult);
      } else {
        setReportLoad(false);
        setReportList([]);
        setReportSearch([]);
      }
    });
  };

  // For Suggestion Tab
  const [suggestionLoad, setSuggestionLoad] = useState(true);
  const [suggestionSearch, setSuggestionSearch] = useState(null);

  const [stdSuggestionList, setStdSuggestionList] = useState([]);

  useEffect(() => {
    getStudentSuggestionList();
  }, [stdID]);

  const getStudentSuggestionList = () => {
    const params = {
      SchID: userinfo.SchID,
      MemID: stdID.toString(),
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/suggestion`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.SuggestionLst ? result.SuggestionLst : "";

        setSuggestionLoad(false);
        setStdSuggestionList(postResult);
        setSuggestionSearch(postResult);
      } else {
        setSuggestionLoad(false);
        setStdSuggestionList([]);
        setSuggestionSearch([]);
      }
    });
  };

  // For notification Tab
  const [notificationLoad, setNotificationLoad] = useState(true);
  const [notificationSearch, setNotificationSearch] = useState(null);

  const [notificationList, setNotificationList] = useState([]);

  useEffect(() => {
    getNotificationList();
  }, [stdID, stdClass, stdSection]);

  const getNotificationList = () => {
    const params = {
      SchID: userinfo.SchID,
      Flag: "SP",
      ClassID: stdClass.toString(),
      SectionID: stdSection.toString(),
      MemID: stdID.toString(),
      UserType: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/notification`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.NotificationLst ? result.NotificationLst : "";
        setNotificationLoad(false);
        setNotificationList(postResult);
        setNotificationSearch(postResult);
      } else {
        setNotificationLoad(false);
        setNotificationList([]);
        setNotificationSearch([]);
      }
    });
  };

  // For payment Tab
  const [paymentLoad, setPaymentLoad] = useState(true);
  const [paymentSearch, setPaymentSearch] = useState(null);

  const [selectYear, setSelectYear] = useState("");
  const [selectMonth, setSelectMonth] = useState("");

  const [paymentReportList, setPaymentReportList] = useState([]);

  useEffect(() => {
    getPaymentList();
  }, [selectYear, selectMonth, stdID]);

  const getPaymentList = () => {
    const params = {
      SchID: userinfo.SchID,
      Flag: "R",
      DtFlag: "S",
      Values: selectYear + "/" + selectMonth,
      PayStatus: "-1",
      Gateway: "-1",
      MemID: stdID.toString(),
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/payment-report`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.AccLst ? result.AccLst : "";
        setPaymentLoad(false);
        setPaymentReportList(postResult);
        setPaymentSearch(postResult);
      } else {
        setPaymentLoad(false);
        setPaymentReportList([]);
        setPaymentSearch([]);
      }
    });
  };

  return (
    <StudentContext.Provider
      value={{
        modal,
        setModal,
        toggleModal,
        studentList,
        loading,
        originalList,
        setStudentList,
        resetPopup,
        handleResetFalse,
        handleResetTrue,
        handleReset,
        deletePopup,
        handleDeleteTrue,
        handleDeleteFalse,
        handleDelete,
        selectedOptions,
        setSelectedOptions,
        changePhoto,
        classList,
        sectionList,
        setSelectClass,
        setSelectSection,
        stdList,
        addnew,
        aboutList,
        accountReportList,
        accountLoad,
        accountSearch,
        setAccountReportList,
        leaveList,
        setLeaveList,
        leaveLoad,
        leaveSearch,
        reportList,
        setReportList,
        reportSearch,
        reportLoad,
        stdSuggestionList,
        setStdSuggestionList,
        suggestionLoad,
        suggestionSearch,
        notificationList,
        setNotificationList,
        notificationLoad,
        notificationSearch,
        selectYear,
        setSelectYear,
        selectMonth,
        setSelectMonth,
        paymentLoad,
        paymentSearch,
        paymentReportList,
        setPaymentReportList,
      }}
    >
      {props.children}
    </StudentContext.Provider>
  );
}

export default StudentState;
