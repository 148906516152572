import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import DesignationContext from "../contextfolder/DesignationContext/DesignationContext";
import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";
import CreateDesignation from "./CreateDesignation";
import DeletePop from "./DeletePop";
import DesignationForm from "./DesignationForm";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import AcademicHeader from "../AcademicHeader";

export default function DesignationList() {
  const { todayDate, customStyles, AcademicYear, userinfo } =
    useContext(SidebarContext);
  const {
    loading,
    originalList,
    modal,
    toggleModal,
    setIsSubmit,
    setDesignationFormValue,
    setDesignationFormError,
    designationValue,
    designationList,
    setDesignationList,
    popup,
    togglePopup,
    deletePopup,
    handleDeleteTrue,
    handleDeleteFalse,
    handleDelete,
    setCreateFormValue,
    setCreateIsSubmit,
    createValue,
    setCreateFormError,
    selectDesignation,
    setSelectDesignation,
    designationTopicList,
    setCloseChecked,
  } = useContext(DesignationContext);

  const searchInput = useRef("");

  const columns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "Full Name",

      selector: (row) => row.FullName,
    },
    {
      name: "Designation",
      width: "200px",
      selector: (row) => row.Designation,
    },
    {
      name: "Action",
      // grow: 0,
      center: true,
      width: "100px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <Tippy content="Delete">
                <i
                  className="fa fa-trash-o rejectspan--action"
                  onClick={() => handleDelete(row.DesignationID)}
                ></i>
              </Tippy>
            </div>
          </>
        );
      },
    },
  ];

  const closePopup = (e) => {
    e.preventDefault();
    toggleModal();
    setDesignationFormValue(designationValue);
    setDesignationFormError({});
    setIsSubmit(false);
    setCloseChecked(false);
  };

  const closeCreatePopup = (e) => {
    e.preventDefault();
    togglePopup();
    setCreateFormValue(createValue);
    setCreateFormError({});
    setCreateIsSubmit(false);
    setCloseChecked(false);
  };

  const addnew = (e) => {
    e.preventDefault();
    toggleModal();
    setIsSubmit(false);
    setDesignationFormValue(designationValue);
    setCloseChecked(false);
  };

  const createnew = (e) => {
    e.preventDefault();
    togglePopup();
    setCreateIsSubmit(false);
    setCreateFormValue(createValue);
    setCloseChecked(false);
  };

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["Designation"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setDesignationList(srchResult);
      } else {
        setDesignationList({});
      }
    } else {
      setDesignationList(originalList);
    }
  };

  const handleDesignation = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectDesignation(value);
  };

  /* This is for export csv */

  const dataToExcel = designationList.map((d, i) => ({
    "S.N.": i + 1,
    "Full Name": d.FullName,
    Designation: d.Designation,
  }));

  const toExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(dataToExcel);
      var wscols = [{ wch: 5 }, { wch: 20 }, { wch: 20 }];
      worksheet["!cols"] = wscols;
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "Designation");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_" + "export" + EXCEL_EXTENSION
        );
      }
    });
  };

  //end

  //This is to export pdf

  const dataWithIndex = designationList.map((d, i) => ({
    ...d,
    Index: i + 1,
  }));

  const cols = [
    { header: "S.N", field: "Index" },
    { header: "Full Name", field: "FullName" },
    { header: "Designation", field: "Designation" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const toPdf = () => {
    const doc = new jsPDF("p", "pt", "a4", true);

    var midPage = doc.internal.pageSize.getWidth() / 2;

    // doc.addImage(`${userinfo.SchPhoto}`, "JPG", 40, 40, 70, 60);

    doc.setFontSize(18);
    doc.text(`${userinfo.SchName}`, midPage, 50, null, null, "center");

    doc.setFontSize(15);
    doc.text(`${userinfo.SchAddress}`, midPage, 68, null, null, "center");

    doc.setFontSize(10);
    doc.text(
      `Tel:${userinfo.Phone}, E-mail:${userinfo.Email}`,
      midPage,
      85,
      null,
      null,
      "center"
    );

    doc.autoTable({
      startY: 100,
      theme: "grid",
      columns: exportColumns,
      body: dataWithIndex,
    });
    doc.save("Designation.pdf");
  };
  //end

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
        <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">Designation</div>
            <div className="page-date">
              <div className="sec-content">
                <AcademicHeader
                  todayDate={todayDate}
                  AcademicYear={AcademicYear}
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <>
          <div className="sec-dataTable content-section main-content">
            <div className="upper-dataTbl">
              <div className="btn-addlnote">
                <button
                  type="button"
                  class="btn btn-sm"
                  style={{
                    background: "var(--MainColor)",
                    color: "white",
                    marginLeft: "6px",
                  }}
                  onClick={createnew}
                >
                  Assign Designation
                </button>

                <div className="exportDiv">
                  <Tippy content="Excel">
                    <button className="exportButtons mx-2" onClick={toExcel}>
                      <i className="bi bi-file-earmark-excel-fill exportButtonIconsExcel"></i>
                    </button>
                  </Tippy>

                  <Tippy content="PDF">
                    <button className="exportButtons " onClick={toPdf}>
                      <i className="bi bi-file-earmark-pdf-fill exportButtonIconsPdf"></i>
                    </button>
                  </Tippy>
                </div>
              </div>
            </div>
            <hr className="new-hr-new" />
            {loading ? (
              <Spinner />
            ) : (
              <>
                <DataTable
                  columns={columns}
                  data={designationList}
                  customStyles={customStyles}
                  pagination
                  fixedHeader
                  fixedHeaderScrollHeight="280px"
                  highlightOnHover
                  pointerOnHover
                  responsive
                  dense
                  striped
                  subHeader
                  subHeaderAlign="left"
                  subHeaderComponent={
                    <>
                      <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                        <label className="list-label">Designation</label>
                        <select
                          style={{ fontSize: "11px", marginBottom: "3px" }}
                          name="snotifiaction"
                          value={selectDesignation}
                          onChange={handleDesignation}
                          className="form-control form-control-sm"
                          aria-label="Default select example "
                        >
                          <option
                            value=""
                            disabled
                            selected
                            style={{ fontSize: "11px" }}
                          >
                            Select Designation
                          </option>
                          <option value="-1">All</option>

                          {designationTopicList.map((item) => (
                            <option
                              key={item.DesignationID}
                              value={item.DesignationID}
                            >
                              {item.Designation}
                            </option>
                          ))}
                        </select>
                        <i class="fas fa-angle-down  notificationpositon"></i>
                      </div>

                      <div
                        className="col-lg-2 offset-lg-8 offset-md-8 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
                        style={{
                          marginTop: "25px",
                          paddingRight: "1px",
                          marginBottom: "10px",
                        }}
                      >
                        <div className="d-flex">
                          <input
                            placeholder="Search"
                            ref={searchInput}
                            type="text"
                            className="form-control form-control-sm searchField"
                            onChange={searchHandler}
                          />
                        </div>
                      </div>
                    </>
                  }
                />
              </>
            )}
          </div>
        </>
      </div>

      {popup && (
        <div className="modals">
          <div className="overlay"></div>

          <div className="notification-model-content">
            <div className="popUpHeader ">
              <div className="popUpHeaderText ">Create Designation</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closeCreatePopup}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="inside-modal">
              <CreateDesignation
                closeCreatePopup={closeCreatePopup}
                closePopup={closePopup}
                addnew={addnew}
              />
            </div>
          </div>
        </div>
      )}

      {deletePopup.show && (
        <DeletePop
          handleDeleteTrue={handleDeleteTrue}
          handleDeleteFalse={handleDeleteFalse}
        />
      )}
    </>
  );
}
