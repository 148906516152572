import React, { useContext, useState, useEffect } from 'react'
import AssignmentContext from '../contextfolder/AssignmentContext/AssignmentContext';
import StudentAssignmentContext from '../contextfolder/StudentAssignmentContext/StudentAssignmentContext';
import { showImgPreview } from '../hooks/ImagePreviewPopUpinside';
import CloseIcon from "../../images/CloseIcon.svg";
import NepaliDate from 'nepali-date-converter';
import Spinner from "../loading/spinner";


export default function StudentViewAssignment({ closePopup }) {

    const context = useContext(StudentAssignmentContext)

    const { toggleViewPopup, remarksFormValue, setRemarksFormValue, remarksIsSubmit, setRemarksIsSubmit, remarksvalue, state, closeChecked, setCloseChecked, remarksFormError, setRemarksFormError, viewSubmitData, selectedImages, setSelectedImages, selectedBase64, setSelectedBase64, setTypeFile, typeFile, newState, setNewState, option, setoption, loading, setLoading } = context;

    const [state1, setState1] = useState(false)




    const handleOnChange = () => {
        setCloseChecked(!closeChecked);
    };

    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;
        setRemarksFormValue({ ...remarksFormValue, [name]: value });
    };

    const handleImageChange = (e) => {
        if (e.target.files) {
            const selected_files = Object.values(e.target.files);
            const extension = e.target.files[0].name;
            const fileType = extension.split(".")
            // const fileName = fileType[0]
            const dot = "."
            const fileExt = dot.concat(fileType[1])

            setTypeFile(fileExt);

            selected_files.map((file) => {

                let reader = new FileReader();

                reader.onload = function (e) {

                    const result = reader.result;
                    setSelectedImages((prevImages) =>
                        prevImages.concat({ File: result })
                    );
                    setSelectedBase64((prevImages) => prevImages.concat({ Type: typeFile, File: result !== null ? result.split(',')[1] : "" }));
                };

                reader.readAsDataURL(file);
            });
        }
    };

    const handleImageRemove = (data) => {
        data[0].preventDefault();
        setSelectedImages(
            selectedImages.filter((file) => file.File !== data[1].File)
        );
        setSelectedBase64(
            selectedBase64.filter((file) => file.File !== data[1].File)
        );

    };

    const [imgPrv, setImgPrv] = useState(false);
    const [imagePre, setImagePre] = useState("");



    const formSubmit = (e) => {
        e.preventDefault();
        setRemarksIsSubmit(true);
        setRemarksFormError(validate(remarksFormValue));
        setState1(true)
    }

    useEffect(() => {
        if (Object.keys(remarksFormError).length === 0 && remarksIsSubmit) {
            viewSubmitData(remarksFormValue)
            if (closeChecked === true) {
                toggleViewPopup();
            }

        }
        setRemarksFormValue(remarksvalue);
        setRemarksIsSubmit(false);

    }, [remarksFormError]);

    const validate = (values) => {
        const errors = {};
        if (!values.comment) {
            errors.comment = "Required";
        }
        return errors;
    };




    // code for Assignsubmit list


    useEffect(() => {

        const arr = [state.AssignSubmit]
        if (arr.length > 0) {
            if (arr === undefined) {
                setNewState()
            }
            else {
                setNewState(state.AssignSubmit)
            }
        }
        else {

        }
    }, [state.AssignSubmit])



    // code for Assignsubmit list end


    // useEffect(() => {
    //     if (newState?.length > 0) {
    //         newState.map((data) => {
    //             let opt = {
    //                 Attachment: data.Attachment,
    //                 AssignStatus: data.AssignStatus,
    //                 Point: data.Point,
    //                 Comment: data.Comment,
    //                 SubDate: data.SubDate
    //             }
    //             option.push(opt)
    //             setoption(option)
    //             setLoading(false)
    //         })
    //     }
    // }, [newState])

    useEffect(() => {
        if (newState?.length > 0) {
            newState.forEach((data) => {
                let opt = {
                    Attachment: data.Attachment,
                    AssignStatus: data.AssignStatus,
                    Point: data.Point,
                    Comment: data.Comment,
                    SubDate: data.SubDate
                };
                if (!option.some(o => o.SubDate === opt.SubDate)) {
                    setoption(prevOption => [...prevOption, opt]);
                }
            });
            setLoading(false);
        }
    }, [newState, option]);


    // code for Attachment

    const [attachState, setAttachState] = useState([])

    useEffect(() => {
        const arr = (state.Attachment)

        if (arr && arr === undefined) {
            setAttachState([])
        }
        else {
            setAttachState(state.Attachment)
        }
    }, [state.Attachment])

    // code for Attachment ends

    // code for Attachment inside Assignsubmit list

    const [attachmentState, setAttachmentState] = useState([])

    useEffect(() => {

        if (newState === undefined) {
            setAttachmentState([])
        }
        else {
            setAttachmentState(newState.Attachment)
        }
    }, [newState])


    // code for Attachment inside Assignsubmit list ends







    /*to open link in new tab *///////////////////////////////////////////////////////////////////


    // const link = (links) => {
    //     if (links === links.startsWith("https://") || links === links.startsWith("http://")) {
    //         return links;
    //     }
    //     else {
    //         const leading = "https://"
    //         const combine = leading.concat(links)
    //         return combine;
    //     }
    // }


    //Nepali date for created date *////////////////////////////////////////////////////////////////

    const [createdDate, setCreatedDate] = useState(null);

    useEffect(() => {
        // Convert Gregorian date to Nepali date
        if (state.StEnglishDate) {
            const gregorianDate = new Date(state.StEnglishDate);
            const convertedDate = new NepaliDate(gregorianDate);
            setCreatedDate(`${convertedDate.format('YYYY/MM/DD')}`);
        }
    }, [state.StEnglishDate]);

    //Nepali date for deadline *////////////////////////////////////////////////////////////////

    const [deadline, setDeadline] = useState(null);

    useEffect(() => {
        // Convert Gregorian date to Nepali date
        if (state.DeadLine) {
            const gregorianDate = new Date(state.DeadLine);
            const convertedDate = new NepaliDate(gregorianDate);
            setDeadline(`${convertedDate.format('YYYY/MM/DD')}`);
        }
    }, [state.DeadLine]);

    //Nepali date for submitted date *////////////////////////////////////////////////////////////////

    // const [subDate, setSubDate] = useState(null);

    // useEffect(() => {
    //     // Convert Gregorian date to Nepali date
    //     if (newState.SubDate) {
    //         const gregorianDate = new Date(newState.SubDate);
    //         const convertedDate = new NepaliDate(gregorianDate);
    //         setSubDate(`${convertedDate.format('YYYY/MM/DD')}`);
    //     }
    //     else {
    //         setSubDate(newState.SubDate)
    //     }
    // }, [newState.SubDate]);








    return (
        <>

            <div className="container-fluid basicform" >



                <div>

                    {state &&

                        <>
                            <div style={{ padding: "0px 5px" }} >
                                <label className="form-label" htmlFor="purpose">Assignment Title</label>
                                <p style={{ fontSize: "12px", color: "#212529" }}>
                                    {state.Title === "" ? "Pending" : state.Title}

                                </p>
                            </div>

                            <div style={{ padding: "0px 5px" }} >
                                <label className="form-label" htmlFor="purpose">Images</label>
                                <div className="scrollonAssignment">
                                    {attachState &&
                                        attachState.map((i) => (
                                            <div className="viewStaffContentLogo">
                                                <div className="viewStaffImg">
                                                    <img src={i.File} alt=""
                                                        onClick={() => { setImagePre(i.File); setImgPrv(true) }}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                </div>

                            </div>
                        </>
                    }


                    <table className="complain-table" style={{ width: "100%" }}>

                        <thead>
                            <tr className='tableHead viewHead'>
                                <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", position: "relative" }}>Subject</td>
                                <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", position: "relative" }}>Created Date</td>
                                <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", position: "relative" }}>Submission Date</td>
                            </tr>
                        </thead>
                        <tbody>




                            {state &&
                                <tr className="viewHead">
                                    <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", paddingTop: "0px" }} >
                                        {state.Subject === "" ? "Pending" : state.Subject}
                                    </td>
                                    <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", paddingTop: "0px" }}>
                                        {createdDate === "" ? "Pending" : createdDate}
                                    </td>
                                    <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", paddingTop: "0px" }}>
                                        {deadline === "" ? "Pending" : deadline}
                                    </td>
                                </tr>
                            }




                        </tbody>

                        <thead>
                            <tr className='tableHead viewHead'>
                                <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", position: "relative" }}>Points</td>
                                {/* <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", position: "relative" }}>Submitted Date</td> */}
                                {/* <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", position: "relative" }}>Assignment Status</td> */}
                            </tr>
                        </thead>

                        <tbody>


                            <tr className="viewHead">
                                <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", paddingTop: "0px" }} >
                                    {state.Point === "" ? "Pending" : state.Point === "-1" ? "No Point" : state.Point}
                                </td>
                                {/* <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", paddingTop: "0px" }}>
                                    {newState === undefined ? "Pending" : newState.SubDate}
                                </td> */}
                                {/* <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", paddingTop: "0px" }}>
                                    {newState === undefined ? "Pending" : state.AssignStatus === "S" ? "Submitted" : state.AssignStatus === "R" ? "Redo" : state.AssignStatus === "A" ? "Approved" : "Pending"}
                                </td> */}
                            </tr>

                        </tbody>

                    </table>
                    {state &&
                        <div style={{ padding: "5px 5px" }} >
                            <label className="form-label">Details</label> <br />
                            <h4 style={{ fontSize: "12px", color: "#212529" }}>{state.Detail}</h4>

                        </div>}

                </div>
                <div>
                    <hr style={{ color: "blue" }} />


                </div>
                {state.AssignStatus === "R" || state.AssignStatus === "S" || state.AssignStatus === "A" ?
                    (<div style={{ padding: "5px 5px" }} >
                        <label className="form-label">Assignment Details</label> <br />


                    </div>) : <></>}


                {option && option.map((data) => {
                    const { Attachment, AssignStatus, Comment, Point, SubDate } = data;
                    return (
                        loading ? (
                            <Spinner />
                        ) : (
                            state.AssignStatus === "R" || state.AssignStatus === "S" || state.AssignStatus === "A" ?
                                <>
                                    <table className="complain-table" style={{ width: "100%" }}>

                                        <thead>
                                            <tr className='tableHead viewHead'>
                                                <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", position: "relative" }}>Status</td>
                                                <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", position: "relative" }}>Point</td>
                                                <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", position: "relative" }}>Submitted Date</td>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr className="viewHead">
                                                <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", paddingTop: "0px" }} >
                                                    {AssignStatus === "R" ? "Redo" : AssignStatus === "A" ? "Approved" : "Submitted"}
                                                </td>
                                                <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", paddingTop: "0px" }}>
                                                    {Point === null ? "Not Mentioned" : Point}
                                                </td>
                                                <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", paddingTop: "0px" }}>
                                                    {SubDate}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div style={{ padding: "5px 5px" }} >
                                        <label className="form-label">Comment</label>
                                        <h4 style={{ fontSize: "12px", color: "#212529" }}>{Comment}</h4>
                                    </div>

                                    <div style={{ padding: "0px 5px" }} >
                                        <label className="form-label" htmlFor="purpose">Submitted Attachment</label>
                                        <div className="scrollonAssignment">
                                            {
                                                Attachment.map((data) => {
                                                    return (
                                                        <div className="viewStaffContentLogo">
                                                            <div className="viewStaffImg">
                                                                <img src={data.File}
                                                                    alt=""
                                                                    onClick={() => { setImagePre(data.File); setImgPrv(true) }}
                                                                />
                                                            </div></div>
                                                    )
                                                })
                                            }

                                        </div>
                                        <div>

                                        </div>



                                    </div>

                                    <hr style={{ color: "blue" }} />

                                </> : <></>)
                    )

                })}


                {state.AssignStatus === "A" || state.AssignStatus === "S" || (state.AssignStatus === "P" && state.IsSubReq === "N") ? (<></>) : (
                    <>
                        {state && state.Link === "-1" ? (<></>) : (
                            <div style={{ padding: "5px 5px" }} >
                                <label className="form-label" htmlFor="purpose">Link</label> <br />
                                <a style={{ cursor: "pointer" }} href={state.Link} target="_blank" rel="noreferrer"> {state === undefined ? "Pending" : state.Link} </a>
                            </div>
                        )}

                        <div style={{ padding: "5px 5px", marginTop: "2px" }} >
                            <label className="form-label" htmlFor="purpose">Upload Attachment</label>
                            <section>
                                <label className="multi-label">
                                    + Add Images
                                    <br />
                                    <span>up to 10 images</span>
                                    <input className="multi-input"
                                        type="file"
                                        name="images"
                                        onChange={handleImageChange}
                                        multiple
                                        accept="image/png , image/jpeg, image/webp"
                                    />
                                </label>
                                <br />

                                <input className="multi-input" type="file" multiple />

                                {selectedImages.length > 0 &&
                                    (selectedImages.length > 10 ? (
                                        <p className="error">
                                            You can't upload more than 10 images! <br />
                                            <span>
                                                Please delete <b> {selectedImages.length - 10} </b> of them{" "}
                                            </span>
                                        </p>
                                    ) : (
                                        <span
                                            className="upload-btn"
                                        // onClick={() => {
                                        //     
                                        // }}
                                        >
                                            {selectedImages.length} Image Selected
                                            {/* {selectedImages.length === 1 ? "" : "S"} */}
                                        </span>
                                    ))}

                                <div className="images">
                                    {selectedImages &&
                                        selectedImages.map((image, i) => {
                                            return (
                                                <div key={i} className="image">
                                                    <img className='multi-img' src={image.File} height="200" alt="upload" />
                                                    <button onClick={(e) => handleImageRemove([e, image])}>
                                                        <img src={CloseIcon} alt="cross" />
                                                    </button>
                                                </div>
                                            );
                                        })}
                                </div>
                            </section>

                        </div>

                        <form >
                            {/* {state.Point === "-1" ? (<></>) : ( */}
                            <div className="form-group">
                                <label className="form-label" htmlFor="comment" style={{ fontSize: "12px" }}>
                                    Comment<sup style={{ color: "red" }}>*</sup>
                                </label>
                                <textarea
                                    id="comment"
                                    style={{ fontSize: "13px" }}
                                    type="text"
                                    className="form-control form-control-sm "
                                    name="comment"
                                    value={remarksFormValue.comment}
                                    onChange={handleChange}
                                />
                                <p className="errormsg " style={{ marginBottom: "0" }}>{remarksFormError.comment}</p>
                            </div>
                            {/* )} */}


                        </form>
                    </>
                )}

            </div>


            <div className="FormFooter">
                <div className='checkedClose'>
                    {state.AssignStatus === "A" || state.AssignStatus === "S" || (state.AssignStatus === "P" && state.IsSubReq === "N") ? (<></>) : (
                        <>
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="exampleCheck2"
                                name="closeChecked"
                                onChange={handleOnChange}
                                checked={closeChecked}
                            />
                            <label class="form-check-label" for="exampleCheck2">
                                Exit after submit
                            </label>
                        </>
                    )}
                </div>
                <div>
                    {state.AssignStatus === "A" || state.AssignStatus === "S" || (state.AssignStatus === "P" && state.IsSubReq === "N") ? (<></>) : (
                        <button className='btn btn-sm btn-cmpy ml-2' style={{ background: "green" }} onClick={formSubmit} >Submit</button>
                    )}
                    <button className='btn btn-sm btn-cmpy ml-2' onClick={closePopup}>Cancel</button>
                </div>
            </div>


            {imgPrv &&
                showImgPreview({
                    img: imagePre,
                    setTrigger: setImgPrv,
                })}

        </>

    )
}
