import React, { useContext, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import TeacherAttendanceContext from "../contextfolder/TeacherAttendanceContext/TeacherAttendanceContext";
import SidebarContext from "../sidebarcontext/SidebarContext";
import "../TabbedView/TabbedView.css";
import Report from "./Report";
import Route from "./Route";
import DeviceCode from "./DeviceCode";

import DeviceCodeContext from "../contextfolder/DeviceCodeContext/DeviceCodeContext";
import AcademicHeader from "../AcademicHeader";

export default function TeacherAttendance() {
  const { AcademicYear, todayDate } = useContext(SidebarContext);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const {
    toggleModal,
    setIsSubmit,
    setAttendanceFormValue,
    attendanceValue,
    attendanceList,
    setCloseChecked,
    setAttendanceReportFormValue,
    attendanceReportValue,
    togglePopup,
    setReportIsSubmit,
    popup,
  } = useContext(TeacherAttendanceContext);

  const { handleSave } = useContext(DeviceCodeContext);

  const [activeTab, setActiveTab] = useState({
    tab1: true,
    tab2: false,
    tab3: false,
  });
  const handleTab1 = () => {
    setActiveTab({
      tab1: true,
      tab2: false,
      tab3: false,
    });
  };
  const handleTab2 = () => {
    setActiveTab({
      tab1: false,
      tab2: true,
      tab3: false,
    });
  };
  const handleTab3 = () => {
    setActiveTab({
      tab1: false,
      tab2: false,
      tab3: true,
    });
  };

  const addnew = (e) => {
    e.preventDefault();
    toggleModal();
    setIsSubmit(false);
    setAttendanceFormValue(attendanceValue);
    setCloseChecked(false);
  };

  const addAttendance = (e) => {
    e.preventDefault();
    togglePopup();
    setReportIsSubmit(false);
    setAttendanceReportFormValue(attendanceReportValue);
    setCloseChecked(false);
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
        <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">
              {activeTab.tab1
                ? "Teacher Attendance Report"
                : activeTab.tab2
                ? "Teacher Attendance Route"
                : activeTab.tab3
                ? "Teacher Attendance Device Code"
                : ""}
            </div>
            <div className="page-date">
              <div className="sec-content">
                <AcademicHeader
                  todayDate={todayDate}
                  AcademicYear={AcademicYear}
                />
              </div>
            </div>
          </div>
        </div>
        <hr />

        <div className="tabview ">
          <div className="insidePopup--div">
            <ul>
              <li
                className={activeTab.tab1 === true ? "active" : ""}
                onClick={handleTab1}
              >
                <i className="fa fa-file-text-o mr-1 icon"></i>Report{" "}
              </li>
              {userinfo.UserType === "A" && (
                <li
                  className={activeTab.tab2 === true ? "active" : ""}
                  onClick={handleTab2}
                >
                  <i className="fa fa-file-text-o mr-1 icon"></i>Route{" "}
                </li>
              )}

              {userinfo.UserType === "A" && (
                <li
                  className={activeTab.tab3 === true ? "active" : ""}
                  onClick={handleTab3}
                >
                  <i className="fa fa-file-text-o mr-1 icon"></i>Device Code{" "}
                </li>
              )}
            </ul>
          </div>
          {userinfo.UserType === "A" && (
            <>
              {activeTab.tab2 === true ? (
                <div>
                  {userinfo.UserType !== "T" && (
                    <div
                      className="upper-dataTbl add-btn"
                      style={{ float: "right" }}
                    >
                      <div className="btn-addlnote">
                        <button
                          type="button"
                          class="btn btn-sm"
                          style={{
                            background: "var(--MainColor)",
                            color: "white",
                          }}
                          onClick={addnew}
                        >
                          Add New
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <></>
              )}

              {activeTab.tab1 === true ? (
                <div>
                  {userinfo.UserType !== "T" && (
                    <div
                      className="upper-dataTbl add-btn"
                      style={{ float: "right" }}
                    >
                      <div className="btn-addlnote">
                        <button
                          type="button"
                          class="btn btn-sm"
                          style={{
                            background: "var(--MainColor)",
                            color: "white",
                          }}
                          onClick={addAttendance}
                        >
                          Add New
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <></>
              )}

              {activeTab.tab3 === true ? (
                <div>
                  {userinfo.UserType !== "T" && (
                    <div
                      className="upper-dataTbl add-btn"
                      style={{ float: "right" }}
                    >
                      <div className="btn-addlnote">
                        <button
                          type="button"
                          class="btn btn-sm"
                          style={{
                            background: "var(--MainColor)",
                            color: "white",
                          }}
                          onClick={handleSave}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
        <div className="content-section main-content">
          {activeTab.tab1 && <Report />}
          {activeTab.tab2 && <Route />}
          {activeTab.tab3 && <DeviceCode />}
        </div>
      </div>
    </>
  );
}
