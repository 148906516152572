import React, { useContext, useEffect, useState } from 'react'
import EventContext from '../contextfolder/EventContext/EventContext';
import CloseIcon from "../../images/CloseIcon.svg";
import Plus from "../../images/Plus.png";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";

export default function EventForm({ closePopup }) {

    const context = useContext(EventContext)

    const { togglePopup, eventFormError, setEventFormError, isSubmit, setIsSubmit, eventValue, eventFormValue, setEventFormValue, isUploaded, setIsUploaded, setTypeFile, image, setImage, fetchdata, closeChecked, setCloseChecked } = context;
    
    
    const handleOnChange = () => {
        setCloseChecked(!closeChecked);
    };


    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;
        setEventFormValue({ ...eventFormValue, [name]: value });
    };

    const handleFromDate = ({ adDate }) => {
        setEventFormValue({ ...eventFormValue, fromDate: adDate });
    };

    const handleToDate = ({ adDate }) => {
        setEventFormValue({ ...eventFormValue, toDate: adDate });
    };



    function handleImageChange(e) {
        if (e.target.files && e.target.files[0]) {
            setTypeFile(e.target.files[0].type);
            let reader = new FileReader();

            reader.onload = function (e) {
                setImage(e.target.result);
                setIsUploaded(true);
            };

            reader.readAsDataURL(e.target.files[0]);
        }
    }


    const formSubmit = (e) => {
        e.preventDefault();
        setEventFormError(validate(eventFormValue));
        setIsSubmit(true);

    }

    useEffect(() => {
        if (Object.keys(eventFormError).length === 0 && isSubmit) {
            fetchdata(eventFormValue)
            if (closeChecked === true) {
                togglePopup();
            }
            setEventFormValue(eventValue)
            setIsSubmit(false);
            setIsUploaded(false)
            setImage("");
        }

    }, [eventFormError]);

    const validate = (values) => {

        const errors = {};
        const numv = /^[0-9]+$/i;
        const digits = /^\d{10}$/;
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (!values.title) {
            errors.title = "Required";
        }
        if (!values.description) {
            errors.description = "Required";
        }
        if (!values.fromDate) {
            errors.fromDate = "Required";
        }
        if (!values.toDate) {
            errors.toDate = "Required";
        }
        if (!values.fromTime) {
            errors.fromTime = "Required";
        }
        if (!values.toTime) {
            errors.toTime = "Required";
        }
        if (!values.venue) {
            errors.venue = "Required";
        }
        if (!values.contact) {
            errors.contact = "Required";
        } else if (!numv.test(values.contact)) {
            errors.contact = "Must be digits";
        } else if (!digits.test(values.contact)) {
            errors.contact = "Must be 10 digits";
        }
        if (values.email.length === 0) {
            setIsSubmit(true);
        } else if (!regex.test(values.email)) {
            errors.email = "This is not a valid email format";
        }
        if (!values.category) {
            errors.category = "Required";
        }
        if (!values.organizer) {
            errors.organizer = "Required";
        }

        return errors;
    };


    return (
        <>

            <div className="container-fluid basicform" >

                <form >

                    <div className="form-group">
                        <label className="form-label" htmlFor="title" style={{ fontSize: "12px" }}>
                            Title<sup style={{ color: "red" }}>*</sup>
                        </label>
                        <input
                            id="title"
                            style={{ fontSize: "13px" }}
                            type="text"
                            className="form-control form-control-sm "
                            name="title"
                            value={eventFormValue.title}
                            onChange={handleChange}
                        />
                        <p className="errormsg" style={{ marginBottom: "0" }}>{eventFormError.title}</p>

                    </div>

                    <div className="form-group">
                        <label className="form-label" htmlFor="description" style={{ fontSize: "12px" }}>
                            Description<sup style={{ color: "red" }}>*</sup>
                        </label>
                        <textarea
                            id="description"
                            style={{ fontSize: "13px" }}
                            type="text"
                            className="form-control form-control-sm "
                            name="description"
                            value={eventFormValue.description}
                            onChange={handleChange}
                        />
                        <p className="errormsg " style={{ marginBottom: "0" }}>{eventFormError.description}</p>

                    </div>

                    <div className="form-row">

                        <div className="form-group col-md-6">
                            <label className="form-label" htmlFor="fromDate" style={{ fontSize: "12px" }}>
                                From Date<sup style={{ color: "red" }}>*</sup>
                            </label>
                            <Calendar
                                className="form-control form-control-sm mb-1"
                                dateFormat="YYYY/MM/DD"
                                theme="default"
                                language="en"
                                values={eventFormValue.fromDate}
                                onChange={handleFromDate}
                            />
                            <p className="errormsg " style={{ marginBottom: "0" }}>{eventFormError.fromDate}</p>
                        </div>

                        <div className="form-group col-md-6">
                            <label className="form-label" htmlFor="toDate" style={{ fontSize: "12px" }}>
                                To Date<sup style={{ color: "red" }}>*</sup>
                            </label>
                            <Calendar
                                className="form-control form-control-sm mb-1"
                                dateFormat="YYYY/MM/DD"
                                theme="default"
                                language="en"
                                values={eventFormValue.toDate}
                                onChange={handleToDate}
                            />
                            <p className="errormsg " style={{ marginBottom: "0" }}>{eventFormError.toDate}</p>
                        </div>

                    </div>


                    <div className="form-row">

                        <div className="form-group col-md-6">
                            <label className="form-label" htmlFor="fromTime" style={{ fontSize: "12px" }}>
                                From Time<sup style={{ color: "red" }}>*</sup>
                            </label>
                            <input
                                id="fromTime"
                                style={{ fontSize: "13px" }}
                                type="time"
                                className="form-control form-control-sm "
                                name="fromTime"
                                value={eventFormValue.fromTime}
                                onChange={handleChange}
                            />
                            <p className="errormsg " style={{ marginBottom: "0" }}>{eventFormError.fromTime}</p>
                        </div>

                        <div className="form-group col-md-6">
                            <label className="form-label" htmlFor="toTime" style={{ fontSize: "12px" }}>
                                To Time<sup style={{ color: "red" }}>*</sup>
                            </label>
                            <input
                                id="toTime"
                                style={{ fontSize: "13px" }}
                                type="time"
                                className="form-control form-control-sm "
                                name="toTime"
                                value={eventFormValue.toTime}
                                onChange={handleChange}
                            />
                            <p className="errormsg " style={{ marginBottom: "0" }}>{eventFormError.toTime}</p>
                        </div>

                    </div>

                    <div className="form-group">
                        <label className="form-label" htmlFor="venue" style={{ fontSize: "12px" }}>
                            Venue<sup style={{ color: "red" }}>*</sup>
                        </label>
                        <input
                            id="venue"
                            style={{ fontSize: "13px" }}
                            type="text"
                            className="form-control form-control-sm "
                            name="venue"
                            value={eventFormValue.venue}
                            onChange={handleChange}
                        />
                        <p className="errormsg " style={{ marginBottom: "0" }}>{eventFormError.venue}</p>

                    </div>


                    <div className="form-row">

                        <div className="form-group col-md-6">
                            <label className="form-label" htmlFor="email" style={{ fontSize: "12px" }}>
                                Email
                            </label>
                            <input
                                id="email"
                                style={{ fontSize: "13px" }}
                                type="text"
                                className="form-control form-control-sm "
                                name="email"
                                value={eventFormValue.email}
                                onChange={handleChange}
                            />
                            <p className="errormsg " style={{ marginBottom: "0" }}>{eventFormError.email}</p>
                        </div>

                        <div className="form-group col-md-6">
                            <label className="form-label" htmlFor="contacts" style={{ fontSize: "12px" }}>
                                Contact<sup style={{ color: "red" }}>*</sup>
                            </label>
                            <input
                                id="contacts"
                                style={{ fontSize: "13px" }}
                                type="text"
                                className="form-control form-control-sm "
                                name="contact"
                                value={eventFormValue.contact}
                                onChange={handleChange}
                            />
                            <p className="errormsg " style={{ marginBottom: "0" }}>{eventFormError.contact}</p>
                        </div>

                    </div>


                    <div className="form-row">

                        <div className="form-group col-md-6">
                            <label className="form-label" htmlFor="category" style={{ fontSize: "12px" }}>
                                Category<sup style={{ color: "red" }}>*</sup>
                            </label>
                            <input
                                id="category"
                                style={{ fontSize: "13px" }}
                                type="text"
                                className="form-control form-control-sm "
                                name="category"
                                value={eventFormValue.category}
                                onChange={handleChange}
                            />
                            <p className="errormsg " style={{ marginBottom: "0" }}>{eventFormError.category}</p>
                        </div>

                        <div className="form-group col-md-6">
                            <label className="form-label" htmlFor="organizer" style={{ fontSize: "12px" }}>
                                Organizer<sup style={{ color: "red" }}>*</sup>
                            </label>
                            <input
                                id="organizer"
                                style={{ fontSize: "13px" }}
                                type="text"
                                className="form-control form-control-sm "
                                name="organizer"
                                value={eventFormValue.organizer}
                                onChange={handleChange}
                            />
                            <p className="errormsg " style={{ marginBottom: "0" }}>{eventFormError.organizer}</p>
                        </div>

                    </div>

                    <div className="form-row">

                        <div className="form-group col-md-6">
                            <label className="form-label" htmlFor="latitude" style={{ fontSize: "12px" }}>
                                Latitude
                            </label>
                            <input
                                id="latitude"
                                style={{ fontSize: "13px" }}
                                type="text"
                                className="form-control form-control-sm "
                                name="latitude"
                                value={eventFormValue.latitude}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="form-group col-md-6">
                            <label className="form-label" htmlFor="longitude" style={{ fontSize: "12px" }}>
                                Longitude
                            </label>
                            <input
                                id="longitude"
                                style={{ fontSize: "13px" }}
                                type="text"
                                className="form-control form-control-sm "
                                name="longitude"
                                value={eventFormValue.longitude}
                                onChange={handleChange}
                            />
                        </div>

                    </div>
                    <div className="form-group  ">
                        <div
                            className="form-label"
                            htmlFor="text"
                            style={{ fontSize: "12px", textAlign: "left" }}
                        >
                            Upload Image
                        </div>

                        <div className="BoxUpload">
                            <div className="image-upload">
                                {!isUploaded ? (
                                    <>
                                        <label htmlFor="upload-input">
                                            <img
                                                src={Plus}
                                                draggable={"false"}
                                                alt="placeholder"
                                                style={{ width: 90, height: 100, paddingTop: "10px" }}
                                            />
                                        </label>

                                        <input
                                            id="upload-input"
                                            type="file"
                                            accept=".jpg,.jpeg,.gif,.png,.mov,.mp4"
                                            onChange={handleImageChange}
                                            name="image"
                                        />
                                    </>
                                ) : (
                                    <div className="ImagePreview">
                                        <img
                                            className="close-icon"
                                            src={CloseIcon}
                                            alt="CloseIcon"
                                            onClick={() => {
                                                setIsUploaded(false);
                                                setImage(null);
                                            }}
                                        />

                                        <img
                                            id="uploaded-image"
                                            src={image}
                                            draggable={false}
                                            alt="uploaded-img"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                </form>


            </div>
            <div className="FormFooter">
                <div className='checkedClose'>
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck2"
                        name="closeChecked"
                        onChange={handleOnChange}
                        checked={closeChecked}
                    />
                    <label class="form-check-label" for="exampleCheck2">
                        Exit after submit
                    </label>
                </div>
                <div>
                    <button className='btn btn-sm btn-cmpy ml-2' style={{ background: "green" }} onClick={formSubmit} >Submit</button>
                    <button className='btn btn-sm btn-cmpy ml-2' onClick={closePopup}>Cancel</button>
                </div>
            </div>
        </>

    )
}
