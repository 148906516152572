import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import TeacherContext from "../contextfolder/TeacherContext/TeacherContext";
import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";
import ClassView from "./ClassView";
import SectionView from "./SectionView";
import SubjectView from "./SubjectView";
import "./TabbedView.css";
import TermView from "./TermView";
import ExtraView from "./ExtraView";
import AcademicHeader from "../AcademicHeader";

export default function TabbedView() {
  const { AcademicYear, todayDate, customStyles, appURL } =
    useContext(SidebarContext);
  const { loading, originalList, studentList, setTeacherList } =
    useContext(TeacherContext);

  const [activeTab, setActiveTab] = useState({
    tab1: true,
    tab2: false,
    tab3: false,
    tab4: false,
    tab5: false,
  });
  const handleTab1 = () => {
    setActiveTab({
      tab1: true,
      tab2: false,
      tab3: false,
      tab4: false,
      tab5: false,
    });
  };
  const handleTab2 = () => {
    setActiveTab({
      tab1: false,
      tab2: true,
      tab3: false,
      tab4: false,
      tab5: false,
    });
  };
  const handleTab3 = () => {
    setActiveTab({
      tab1: false,
      tab2: false,
      tab3: true,
      tab4: false,
      tab5: false,
    });
  };
  const handleTab4 = () => {
    setActiveTab({
      tab1: false,
      tab2: false,
      tab3: false,
      tab4: true,
      tab5: false,
    });
  };
  const handleTab5 = () => {
    setActiveTab({
      tab1: false,
      tab2: false,
      tab3: false,
      tab4: false,
      tab5: true,
    });
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
        <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">
              {activeTab.tab1
                ? "Class"
                : activeTab.tab2
                ? "Section"
                : activeTab.tab3
                ? "Term"
                : activeTab.tab4
                ? "Subject"
                : "Extra Activity"}
            </div>
            <div className="page-date">
              <div className="sec-content">
                <AcademicHeader
                  todayDate={todayDate}
                  AcademicYear={AcademicYear}
                />
              </div>
            </div>
          </div>
        </div>
        <hr />

        <div className="tabview ">
          <div className="insidePopup--div">
            <ul>
              <li
                className={activeTab.tab1 === true ? "active" : ""}
                onClick={handleTab1}
              >
                <i className="fa fa-file-text-o mr-1 icon"></i>Class{" "}
              </li>
              <li
                className={activeTab.tab2 === true ? "active" : ""}
                onClick={handleTab2}
              >
                <i className="fa fa-file-text-o mr-1 icon"></i>Section{" "}
              </li>
              <li
                className={activeTab.tab3 === true ? "active" : ""}
                onClick={handleTab3}
              >
                <i className="fa fa-file-text-o mr-1 icon"></i>Term{" "}
              </li>
              <li
                className={activeTab.tab4 === true ? "active" : ""}
                onClick={handleTab4}
              >
                <i className="fa fa-file-text-o mr-1 icon"></i>Subject{" "}
              </li>
              <li
                className={activeTab.tab5 === true ? "active" : ""}
                onClick={handleTab5}
              >
                <i className="fa fa-file-text-o mr-1 icon"></i>Extra Activity{" "}
              </li>
            </ul>
          </div>
        </div>

        <div className="content-section main-content">
          {activeTab.tab1 && <ClassView />}
          {activeTab.tab2 && <SectionView />}
          {activeTab.tab3 && <TermView />}
          {activeTab.tab4 && <SubjectView />}
          {activeTab.tab5 && <ExtraView />}
        </div>
      </div>
    </>
  );
}
