import React, { useContext, useEffect } from 'react'
import { useRef } from 'react';
import DataTable from 'react-data-table-component';
import DesignationContext from '../contextfolder/DesignationContext/DesignationContext';
import SidebarContext from '../sidebarcontext/SidebarContext';
import Spinner from '../loading/spinner';
import { useState } from 'react';
import DeleteTopicPop from './DeleteTopicPop';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';


export default function DesignationForm({ closePopup }) {

    const context = useContext(DesignationContext)

    const { designationFormError, setDesignationFormError, isSubmit, setIsSubmit, designationValue, designationFormValue, setDesignationFormValue, designationTopicList, fetchdata, designationTopicSearch, setDesignationTopicList, loading, setTitleId, editdata, deleteTopicPopup, handleDeleteTopicTrue, handleDeleteTopicFalse, handleTopicDelete, closeChecked, setCloseChecked, toggleModal, setModal } = context;

    const { customStyles } = useContext(SidebarContext)

    const handleOnChange = () => {
        setCloseChecked(!closeChecked);
    };

    const [isEdit, setIsEdit] = useState(false);

    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;
        setDesignationFormValue({ ...designationFormValue, [name]: value });
    };

    const formSubmit = (e) => {
        e.preventDefault();
        setDesignationFormError(validate(designationFormValue));
        setIsSubmit(true);
    }
    useEffect(() => {
        if (Object.keys(designationFormError).length === 0 && isSubmit) {
            fetchdata(designationFormValue)
            if (closeChecked === true) {
                toggleModal();
            }
            setDesignationFormValue(designationValue)
            setIsSubmit(false);
        }

    }, [designationFormError]);





    const validate = (values) => {
        const errors = {};
        if (!values.topic) {
            errors.topic = "Required";
        }
        if (!values.order) {
            errors.order = "Required";
        }
        return errors;
    };
    const searchInput = useRef("");
    const searchHandler = (e) => {
        e.preventDefault();

        const srchQuery = searchInput.current.value.toLowerCase();
        if (srchQuery) {

            let srchResult = designationTopicSearch.filter((list) => {
                return list["Designation"].toLowerCase().includes(srchQuery)
            });

            if (srchResult) {

                setDesignationTopicList(srchResult);
            } else {
                setDesignationTopicList({});
            }
        } else {
            setDesignationTopicList(designationTopicSearch);
        }
    };


    const columns = [
        {
            name: "S.N.",
            grow: 0,
            center: true,
            width: "59px",
            cell: (row, index) => index + 1,
        },

        {
            name: "Designation",
            center: true,
            // grow: 0,
            selector: (row) => row.Designation,
        },

        {
            name: "Order",
            center: true,
            grow: 0,
            width: "80px",
            selector: (row) => row.Order,
        },
        {
            name: "No. of Staff",
            center: true,
            // grow: 0,
            width: "120px",
            selector: (row) => row.NoOfStaff,
        },
        {
            name: "Action",
            center: true,
            selector: (row) => {
                return (
                    <>
                        <>
                            <div className="ln-verition d-flex">
                                <Tippy content="Edit">
                                    <i
                                        className="fa fa-pencil solvespan--action"
                                        onClick={() => selectUser(row)}
                                    >
                                    </i>
                                </Tippy>
                                <Tippy content="Delete">
                                    <i
                                        className="fa fa-trash-o rejectspan--action"
                                        onClick={() => handleTopicDelete(row.DesignationID)}
                                    >
                                    </i>
                                </Tippy>

                            </div>
                        </>

                    </>
                );
            },
        },

    ];


    function selectUser(datas) {

        setDesignationFormValue({
            order: datas.Order,
            topic: datas.Designation
        });
        setTitleId(datas.DesignationID);
        setIsEdit(true);
    }


    return (
        <>

            <div className="container-fluid basicform" >

                <form >
                    <div className="form-row">
                        <div className="form-group col-md-3">
                            <label className="form-label" htmlFor="order" style={{ fontSize: "12px" }}>
                                Order<sup style={{ color: "red" }}>*</sup>
                            </label>
                            <select id="order" name="order"
                            onChange={handleChange}
                            value={designationFormValue.order} className="form-select form-select-sm mb-1" style={{ fontSize: '12px' }}>
                            <option value="" selected disabled>Select Option</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                        </select>
                            <p className="errormsg " style={{ marginBottom: "0" }}>{designationFormError.order}</p>
                        </div>

                        <div className="form-group col-md-9">
                            <label className="form-label" htmlFor="topic" style={{ fontSize: "12px" }}>
                                Topic<sup style={{ color: "red" }}>*</sup>
                            </label>
                            <input
                                id="topic"
                                style={{ fontSize: "13px" }}
                                type="text"
                                className="form-control form-control-sm "
                                name="topic"
                                value={designationFormValue.topic}
                                onChange={handleChange}
                            />
                            <p className="errormsg " style={{ marginBottom: "0" }}>{designationFormError.topic}</p>
                        </div>
                    </div>
                </form>
                <div className="sec-dataTable">

                    {loading ? (
                        <Spinner />
                    ) : (
                        <DataTable
                            columns={columns}
                            data={designationTopicList}
                            customStyles={customStyles}
                            pagination
                            fixedHeader
                            fixedHeaderScrollHeight="280px"
                            highlightOnHover
                            pointerOnHover
                            responsive
                            dense
                            striped
                            subHeader
                            subHeaderComponent={
                                <>
                                    <div className="upper-dataTbl">
                                        <div className="d-flex">
                                            <input
                                                placeholder="Search"
                                                ref={searchInput}
                                                type="text"
                                                className="form-control form-control-sm searchField"
                                                onChange={searchHandler}
                                            />
                                        </div>
                                    </div>
                                </>
                            }
                        />
                    )}
                </div>
            </div>
            <div className="FormFooter">
                <div className='checkedClose'>
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck2"
                        name="closeChecked"
                        onChange={handleOnChange}
                        checked={closeChecked}
                    />
                    <label class="form-check-label" for="exampleCheck2">
                        Exit after {!isEdit ? "submit" : "update"}
                    </label>
                </div>
                <div>
                    {!isEdit ?
                        <button className='btn btn-sm btn-cmpy ml-2' style={{ background: "green" }} onClick={formSubmit} >Submit</button> :
                        <button className='btn btn-sm btn-cmpy ml-2' style={{ background: "green" }}
                            onClick={editdata}
                        >
                            Update</button>
                    }
                    <button className='btn btn-sm btn-cmpy ml-2' onClick={closePopup}>Cancel</button>
                </div>
            </div>


            {deleteTopicPopup.show && (
                <DeleteTopicPop
                    handleDeleteTopicTrue={handleDeleteTopicTrue}
                    handleDeleteTopicFalse={handleDeleteTopicFalse}
                />
            )}
        </>

    )
}
