import React from "react";
import { useState } from "react";
import Comment from "./Comment";
import { showImgPreview } from "../hooks/ImagePreviewPopUpinside";

export default function ViewHelpDesk({ viewHelpDesk, closeViewPopup }) {
  const [activeTab, setActiveTab] = useState({
    tab1: true,
    tab2: false,
  });
  const handleTab1 = () => {
    setActiveTab({
      tab1: true,
      tab2: false,
    });
  };
  const handleTab2 = () => {
    setActiveTab({
      tab1: false,
      tab2: true,
    });
  };

  const data = [
    {
      id: 1,
      title: "Ticket No",
      desc: viewHelpDesk.TicketNo,
    },
    {
      id: 2,
      title: "Category",
      desc: viewHelpDesk.Category,
    },
    {
      id: 3,
      title: "Subject",
      desc: viewHelpDesk.Subject,
    },
    {
      id: 4,
      title: "Message",
      desc: viewHelpDesk.Description,
    },
    {
      id: 5,
      title: "Priority",
      desc:
        viewHelpDesk.Priority === "M"
          ? "Medium"
          : viewHelpDesk.Priority === "H"
          ? "High"
          : viewHelpDesk.Priority === "L"
          ? "Low"
          : "Urgent",
    },
    {
      id: 6,
      title: "Assigned To",
      desc: viewHelpDesk.AssignedTo,
    },
    {
      id: 7,
      title: "Status",
      desc:
        viewHelpDesk.Status === "P"
          ? "Pending"
          : viewHelpDesk.Status === "A"
          ? "Approved"
          : viewHelpDesk.Status === "R"
          ? "Rejected"
          : viewHelpDesk.Status,
    },
    {
      id: 8,
      title: "Last Updated Date",
      desc: viewHelpDesk.LastUpdateDate,
    },
  ];
  const initialValue = {
    comment: "",
  };
  const [formValue, setFormValue] = useState(initialValue);
  const [formError, setFormError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const validate = (values) => {
    const errors = {};
    if (!values.comment) {
      errors.comment = "Required";
    }

    return errors;
  };
  const formSubmit = (e) => {
    e.preventDefault();
    setFormError(validate(formValue));
    setIsSubmit(true);
  };

  const [imgPrv, setImgPrv] = useState(false);
  const [imagePre, setImagePre] = useState("");
  return (
    <>
      <div className="container-fluid basicform">
        <div className="tabview comment__tab">
          <div className="insidePopup--div">
            <ul>
              <li
                className={activeTab.tab1 === true ? "active" : ""}
                onClick={handleTab1}
              >
                Ticket{" "}
              </li>
              <li
                className={activeTab.tab2 === true ? "active" : ""}
                onClick={handleTab2}
              >
                Comment{" "}
              </li>
            </ul>
          </div>
        </div>

        <div className="">
          {activeTab.tab1 && (
            <>
              <div className="content-section main-content">
                <div className="view__table">
                  {data.map((props) => {
                    const { id, title, desc } = props;
                    return (
                      <div key={id}>
                        <h4 className="the__title">{title}</h4>
                        <p className="the__content">{desc}</p>
                      </div>
                    );
                  })}
                </div>
                {viewHelpDesk.Attachment && (
                  <div className="mt-4 view__table__img">
                    <img
                      src={viewHelpDesk.Attachment}
                      alt="Helpdesk image"
                      onClick={() => {
                        setImagePre(viewHelpDesk.Attachment);
                        setImgPrv(true);
                      }}
                    />
                  </div>
                )}
              </div>
            </>
          )}
          {activeTab.tab2 && (
            <Comment
              initialValue={initialValue}
              formValue={formValue}
              setFormValue={setFormValue}
              formError={formError}
              isSubmit={isSubmit}
              setIsSubmit={setIsSubmit}
              viewHelpDesk={viewHelpDesk}
            />
          )}
        </div>
      </div>
      <div className="FormFooter">
        <div className="checkedClose"></div>
        <div>
          {activeTab.tab2 && (
            <button
              className="btn btn-sm btn-cmpy ml-2"
              style={{ background: "green" }}
              onClick={formSubmit}
            >
              Submit
            </button>
          )}
          <button className="btn btn-sm btn-cmpy ml-2" onClick={closeViewPopup}>
            Cancel
          </button>
        </div>
      </div>

      {imgPrv &&
        showImgPreview({
          img: imagePre,
          setTrigger: setImgPrv,
        })}
    </>
  );
}
