import React, { useContext, useEffect, useState } from "react";
import { Fetchdata } from "../hooks/getData";
import SidebarContext from "../sidebarcontext/SidebarContext";
import GalleryContext from "../contextfolder/GalleryContext/GalleryContext";
import "./GalleryForm.css";
import CloseIcon from "../../images/CloseIcon.svg";
import Plus from "../../images/Plus.png";
import AlbumContext from "../contextfolder/AlbumContext/AlbumContext";
import AlbumForm from "../Album/AlbumForm";

export default function GalleryForm({ closePopup }) {
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const { appURL } = useContext(SidebarContext);

  const context = useContext(GalleryContext);

  const {
    togglePopup,
    photoFormError,
    setPhotoFormError,
    isSubmit,
    setIsSubmit,
    photoValue,
    photoFormValue,
    setPhotoFormValue,
    fetchdata,
    closeChecked,
    setCloseChecked,
    isUploaded,
    setIsUploaded,
    setTypeFile,
    image,
    setImage,
    selectedImages,
    setSelectedImages,
    selectedBase64,
    setSelectedBase64,
  } = context;

  const {
    setIsASubmit,
    toggleAPopup,
    setAlbumFormValue,
    albumValue,
    popupA,
    setAlbumFormError,
    setIsEdit,
  } = useContext(AlbumContext);

  const handleOnChange = () => {
    setCloseChecked(!closeChecked);
  };

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setPhotoFormValue({ ...photoFormValue, [name]: value });
  };

  // function handleImageChange(e) {
  //     if (e.target.files && e.target.files[0]) {
  //         setTypeFile(e.target.files[0].type);
  //         let reader = new FileReader();

  //         reader.onload = function (e) {
  //             setImage(e.target.result);
  //             setIsUploaded(true);
  //         };

  //         reader.readAsDataURL(e.target.files[0]);
  //     }
  // }

  const addnew = (e) => {
    e.preventDefault();
    setIsASubmit(false);
    toggleAPopup(true);
    setAlbumFormValue(albumValue);
    setCloseChecked(false);
    setIsEdit(false);
  };

  const closePopupA = (e) => {
    e.preventDefault();
    toggleAPopup();
    setAlbumFormValue(albumValue);
    setAlbumFormError({});
    setIsASubmit(false);
    setCloseChecked(false);
    setIsEdit(false);
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setPhotoFormError(validate(photoFormValue));
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(photoFormError).length === 0 && isSubmit) {
      fetchdata(photoFormValue);
      if (closeChecked === true) {
        togglePopup();
      }
      setPhotoFormValue(photoValue);
      setIsUploaded(false);
      setImage("");
      setIsSubmit(false);
    }
  }, [photoFormError]);

  const validate = (values) => {
    const errors = {};
    if (
      photoFormValue.selectdrop === "C" ||
      photoFormValue.selectdrop === "S" ||
      photoFormValue.selectdrop === "I"
    ) {
      if (!values.class) {
        errors.class = "Required";
      }
    }
    if (
      photoFormValue.selectdrop === "S" ||
      photoFormValue.selectdrop === "I"
    ) {
      if (!values.section) {
        errors.section = "Required";
      }
    }
    if (photoFormValue.selectdrop === "I") {
      if (!values.student) {
        errors.student = "Required";
      }
    }
    if (!values.album) {
      errors.album = "Required";
    }
    if (!values.title) {
      errors.title = "Required";
    }
    if (!values.selectdrop) {
      errors.selectdrop = "Required";
    }
    return errors;
  };

  const [classList, setClassList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [studentList, setStudentList] = useState([]);
  const [albumList, setAlbumList] = useState([]);

  //API to hit class list
  useEffect(() => {
    getClassList();
  }, []);

  const getClassList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      DFlag: "C",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setClassList(postResult);
      } else {
        setClassList([]);
      }
    });
  };

  //API to hit section list
  useEffect(() => {
    getSectionList();
  }, [photoFormValue.class]);

  const getSectionList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      ClassID: photoFormValue.class,
      DFlag: "S",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";

        setSectionList(postResult);
      } else {
        setSectionList([]);
      }
    });
  };

  //API to hit student list
  useEffect(() => {
    stdList();
  }, [photoFormValue.class, photoFormValue.section]);

  const stdList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      Flag: "S",
      ClassID: photoFormValue.class,
      SectionID: [
        {
          SectionID: photoFormValue.section,
        },
      ],
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/student`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.StudentLst ? result.StudentLst : "";
        setStudentList(postResult);
      } else {
        setStudentList([]);
      }
    });
  };

  //API to hit album list
  useEffect(() => {
    getAlbumList();
  }, [photoFormValue.class, photoFormValue.section, photoFormValue.student]);

  const getAlbumList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "S",
      ClassID: photoFormValue.class,
      SectionID: photoFormValue.section,
      MemID: photoFormValue.student,
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/gallery/album`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.AlbumLst ? result.AlbumLst : "";
        setAlbumList(postResult);
      } else {
        setAlbumList([]);
      }
    });
  };

  const handleImageChange = (e) => {
    if (e.target.files) {
      const selected_files = Object.values(e.target.files);

      selected_files.map((file) => {
        let reader = new FileReader();

        reader.onload = function (e) {
          const result = reader.result;
          setSelectedImages((prevImages) => prevImages.concat({ Img: result }));
          setSelectedBase64((prevImages) =>
            prevImages.concat({
              Img: result !== null ? result.split(",")[1] : "",
            })
          );
        };

        reader.readAsDataURL(file);
      });
    }
  };

  const handleImageRemove = (data) => {
    data[0].preventDefault();
    setSelectedImages(
      selectedImages.filter((file) => file.Img !== data[1].Img)
    );
  };

  return (
    <>
      <div className="container-fluid basicform">
        <form>
          <div className="form-group">
            <label className="form-label" htmlFor="selectdrop">
              Select Option<sup style={{ color: "red" }}>*</sup>
            </label>
            <select
              id="selectdrop"
              name="selectdrop"
              onChange={handleChange}
              value={photoFormValue.selectdrop}
              className="form-select form-select-sm mb-1"
              style={{ fontSize: "12px" }}
            >
              <option value="" selected disabled>
                Select Option
              </option>
              {/* {userinfo.UserType !== "T" && (
                                <option value="-1">All</option>
                            )} */}
              <option value="-1">All</option>
              <option value="C">Class</option>
              <option value="S">Section</option>
              <option value="I">Individual</option>
            </select>
            <p className="errormsg " style={{ marginBottom: "0" }}>
              {photoFormError.selectdrop}
            </p>
          </div>

          <div className="form-row">
            {photoFormValue.selectdrop === "C" ||
            photoFormValue.selectdrop === "S" ||
            photoFormValue.selectdrop === "I" ? (
              <div
                className={
                  photoFormValue.selectdrop === "C"
                    ? "form-group col-md-12"
                    : photoFormValue.selectdrop === "S"
                    ? "form-group col-md-6"
                    : "form-group col-md-4"
                }
              >
                <label className="form-label" htmlFor="class">
                  Class<sup style={{ color: "red" }}>*</sup>
                </label>
                <select
                  id="class"
                  name="class"
                  onChange={handleChange}
                  value={photoFormValue.class}
                  className="form-select form-select-sm mb-1"
                  style={{ fontSize: "12px" }}
                >
                  <option value="" selected disabled>
                    Select Option
                  </option>
                  {classList.map((item, i) => (
                    <option key={i} value={item.ClassID}>
                      {item.ClassName}
                    </option>
                  ))}
                </select>
                <p className="errormsg " style={{ marginBottom: "0" }}>
                  {photoFormError.class}
                </p>
              </div>
            ) : (
              <></>
            )}

            {photoFormValue.selectdrop === "S" ||
            photoFormValue.selectdrop === "I" ? (
              <div
                className={
                  photoFormValue.selectdrop === "S"
                    ? "form-group col-md-6"
                    : "form-group col-md-4"
                }
              >
                <label className="form-label" htmlFor="section">
                  Section<sup style={{ color: "red" }}>*</sup>
                </label>
                <select
                  id="section"
                  name="section"
                  onChange={handleChange}
                  value={photoFormValue.section}
                  className="form-select form-select-sm mb-1"
                  style={{ fontSize: "12px" }}
                >
                  <option
                    value=""
                    disabled
                    selected
                    style={{ fontSize: "11px" }}
                  >
                    Select Section
                  </option>
                  {sectionList.map((item) => (
                    <option key={item.SectionID} value={item.SectionID}>
                      {item.SectionName}
                    </option>
                  ))}
                </select>
                <p className="errormsg " style={{ marginBottom: "0" }}>
                  {photoFormError.section}
                </p>
              </div>
            ) : (
              <></>
            )}
            {photoFormValue.selectdrop === "I" ? (
              <div className="form-group col-md-4">
                <label className="form-label" htmlFor="ss">
                  Student<sup style={{ color: "red" }}>*</sup>
                </label>
                <select
                  id="ss"
                  name="student"
                  onChange={handleChange}
                  value={photoFormValue.student}
                  className="form-select form-select-sm mb-1"
                  style={{ fontSize: "12px" }}
                >
                  <option value="" selected disabled>
                    Select Option
                  </option>
                  {studentList.map((item, i) => (
                    <option key={i} value={item.StudentID}>
                      {item.StdFullName}
                    </option>
                  ))}
                </select>
                <p className="errormsg " style={{ marginBottom: "0" }}>
                  {photoFormError.student}
                </p>
              </div>
            ) : (
              <></>
            )}
          </div>

          <div className="form-row">
            <div className="form-group col-md-11">
              <label className="form-label" htmlFor="album">
                Album<sup style={{ color: "red" }}>*</sup>
              </label>
              <select
                id="album"
                name="album"
                onChange={handleChange}
                value={photoFormValue.album}
                className="form-select form-select-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="" selected disabled>
                  Select Option
                </option>
                {albumList.map((item, i) => (
                  <option key={i} value={item.AlbumID}>
                    {item.AlbumName}
                  </option>
                ))}
              </select>
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {photoFormError.album}
              </p>
            </div>
            <div className="form-group col-md-1">
              <div className="add-title-button">
                <button
                  className="btn btn-sm btn-cmpy"
                  style={{
                    background: "0079bf",
                    width: "50px",
                    marginTop: "4px",
                  }}
                  onClick={addnew}
                >
                  <i className="fa fa-plus" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>

          <div className="form-group">
            <label
              className="form-label"
              htmlFor="title"
              style={{ fontSize: "12px" }}
            >
              Title<sup style={{ color: "red" }}>*</sup>
            </label>
            <input
              id="title"
              style={{ fontSize: "13px" }}
              type="text"
              className="form-control form-control-sm "
              name="title"
              value={photoFormValue.title}
              onChange={handleChange}
            />
            <p className="errormsg " style={{ marginBottom: "0" }}>
              {photoFormError.title}
            </p>
          </div>

          <div className="form-group  ">
            <div
              className="form-label"
              htmlFor="text"
              style={{ fontSize: "12px", textAlign: "left" }}
            >
              Upload Image
            </div>

            <section>
              <label className="multi-label">
                + Add Images
                <br />
                <span>up to 10 images</span>
                <input
                  className="multi-input"
                  type="file"
                  name="images"
                  onChange={handleImageChange}
                  multiple
                  accept="image/png , image/jpeg, image/webp"
                />
              </label>
              <br />

              <input className="multi-input" type="file" multiple />

              {selectedImages.length > 0 &&
                (selectedImages.length > 10 ? (
                  <p className="error">
                    You can't upload more than 10 images! <br />
                    <span>
                      Please delete <b> {selectedImages.length - 10} </b> of
                      them{" "}
                    </span>
                  </p>
                ) : (
                  <span
                    className="upload-btn"
                    // onClick={() => {
                    //
                    // }}
                  >
                    {selectedImages.length} Image Selected
                    {/* {selectedImages.length === 1 ? "" : "S"} */}
                  </span>
                ))}

              <div className="images">
                {selectedImages &&
                  selectedImages.map((image, i) => {
                    return (
                      <div key={i} className="image">
                        <img
                          className="multi-img"
                          src={image.Img}
                          height="200"
                          alt="upload"
                        />
                        <button onClick={(e) => handleImageRemove([e, image])}>
                          <img src={CloseIcon} alt="cross" />
                        </button>
                      </div>
                    );
                  })}
              </div>
            </section>
          </div>
        </form>
      </div>
      <div className="FormFooter">
        <div className="checkedClose">
          <input
            type="checkbox"
            className="form-check-input"
            id="exampleCheck2"
            name="closeChecked"
            onChange={handleOnChange}
            checked={closeChecked}
          />
          <label class="form-check-label" for="exampleCheck2">
            Exit after submit
          </label>
        </div>
        <div>
          <button
            className="btn btn-sm btn-cmpy ml-2"
            style={{ background: "green" }}
            onClick={formSubmit}
          >
            Submit
          </button>
          <button className="btn btn-sm btn-cmpy ml-2" onClick={closePopup}>
            Cancel
          </button>
        </div>
      </div>

      {popupA && (
        <div className="modals">
          <div className="popup-overlay"></div>

          <div className="credit-model-content">
            <div className="popUpHeader ">
              <div className="popUpHeaderText ">Create Album</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closePopupA}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="inside-modal">
              <AlbumForm closePopupA={closePopupA} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
