import React, { useContext, useEffect, useState } from "react";
import { Fetchdata } from "../../hooks/getData";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { toast } from "react-toastify";
import TeacherContext from "./TeacherContext";

function TeacherState(props) {
  const { appURL } = useContext(SidebarContext);

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const [isUploaded, setIsUploaded] = useState(false);
  const [typeFile, setTypeFile] = useState("");
  const [image, setImage] = useState("");

  // For popup
  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  //API to hit Teacher list
  const [teacherList, setTeacherList] = useState([]);

  useEffect(() => {
    teachList();
  }, []);

  const teachList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/teacher`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.teachingres ? result.teachingres : "";
        setLoading(false);
        setTeacherList(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        setTeacherList([]);
        setOriginalList([]);
      }
    });
  };

  //API to hit Teacher list
  const [newLoading, setNewLoading] = useState(true);
  const [newOriginalList, setNewOriginalList] = useState(null);
  const [newTeacherList, setNewTeacherList] = useState([]);

  useEffect(() => {
    newteachList();
  }, []);

  const newteachList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      Flag: "ST",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/teacher`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.teachingres ? result.teachingres : "";
        setNewLoading(false);
        setNewTeacherList(postResult);
        setNewOriginalList(postResult);
      } else {
        setNewLoading(false);
        setNewTeacherList([]);
        setNewOriginalList([]);
      }
    });
  };

  //Everything to pop up and reset the password list
  const [resetPopup, setResetPopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const [Acdata, setACData] = useState();
  const [resetPassword, setResetPassword] = useState("");

  const handleReset = async (data) => {
    setACData(data[0]);
    setResetPassword(data[1]);

    setResetPopup({
      show: true,
      data,
    });
  };

  // This will perform the deletion and hide the Confirmation Box

  const handleResetTrue = async () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "RP",
      UserName: Acdata,
      Password: resetPassword,
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/student`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          toast(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
          setResetPopup({
            show: false,
            data: null,
          });
        } else {
          toast(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleResetFalse = () => {
    setResetPopup({
      show: false,
      data: null,
    });
  };

  // Ends Here

  //Everything to pop up and delete the student list
  const [deletePopup, setDeletePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const [delData, setDelData] = useState();

  const handleDelete = async (data) => {
    setDelData(data);

    setDeletePopup({
      show: true,
      data,
    });
  };

  // This will perform the deletion and hide the Confirmation Box

  const handleDeleteTrue = async () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "DS",
      UserName: delData,
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/student`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          teachList();
          toast(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
          setDeletePopup({
            show: false,
            data: null,
          });
        } else {
          toast(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleDeleteFalse = () => {
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // Ends Here

  // APi to change photo

  const changephoto = (id, image) => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "IMG",
      Image: image !== "" ? image.split(",")[1] : "",
      UserName: id,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/student`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        teachList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };

  return (
    <TeacherContext.Provider
      value={{
        modal,
        setModal,
        toggleModal,
        teacherList,
        loading,
        originalList,
        setTeacherList,
        resetPopup,
        handleResetFalse,
        handleResetTrue,
        handleReset,
        deletePopup,
        handleDeleteTrue,
        handleDeleteFalse,
        handleDelete,
        isUploaded,
        setIsUploaded,
        typeFile,
        setTypeFile,
        image,
        setImage,
        changephoto,

        newLoading,
        setNewLoading,
        newOriginalList,
        setNewOriginalList,
        newTeacherList,
        setNewTeacherList,
      }}
    >
      {props.children}
    </TeacherContext.Provider>
  );
}

export default TeacherState;
