import React, { useEffect, useState } from "react";
import Aos from "aos";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Fetchdata } from "../hooks/getData";
import noImage from "./../../images/school/no-available-image.jpg";
import placeholder from "./../../images/school/placeholder.png";
import Spinner from "../loading/spinner";
import { FaCalendarAlt, FaEye } from "react-icons/fa";
import ViewBlog from "./ViewBlog";

const Blog = ({ schoolInfo }) => {
  const appURL = process.env.REACT_APP_URL;
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  useEffect(() => {
    Aos.init();
  }, []);

  const [viewPop, setViewPop] = useState(false);
  const toggleViewPopup = () => {
    setViewPop(true);
  };
  const [viewId, setViewId] = useState("");
  const handleView = (data) => {
    toggleViewPopup();
    setViewId(data.BlogID);
    getBlogList();
  };

  const closeViewPopup = (e) => {
    e.preventDefault();
    setViewPop(false);
  };

  const [viewList, setViewList] = useState([]);
  useEffect(() => {
    getViewList();
  }, [schoolInfo, viewId]);

  const getViewList = () => {
    const params = {
      Type: "GET",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/blog-info?SchID=${schoolInfo?.SchID}&BlogID=${viewId}&Via=1`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.BlogLst ? result.BlogLst[0] : "";
        setViewList(postResult);
      } else {
        setViewList([]);
      }
    });
  };

  const [category, setCategory] = useState("-1");

  const handleClick = (data) => {
    setCategory(data.CategoryID);
  };

  const [categoryList, setCategoryList] = useState([]);
  useEffect(() => {
    getCategoryList();
  }, [schoolInfo]);

  const getCategoryList = () => {
    const params = {
      Type: "GET",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/blog-category?SchID=${schoolInfo?.SchID}&Primary=-1`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.BlogCatLst ? result.BlogCatLst : "";
        setCategoryList(postResult);
      } else {
        setCategoryList([]);
      }
    });
  };

  const [blogList, setBlogList] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getBlogList();
  }, [schoolInfo, category]);

  const getBlogList = () => {
    const params = {
      Type: "GET",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/blogs?SchID=${schoolInfo?.SchID}&CategoryID=${category}&Count=-1&Via=1`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.BlogLst ? result.BlogLst : "";
        setBlogList(postResult);
        setLoading(false);
      } else {
        setBlogList([]);
        setLoading(false);
      }
    });
  };

  return (
    <>
      <div className="section-b blog__section" id="blog__section">
        <div className="uk-container">
          <div
            className=" new__title uk-text-left"
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
            data-aos-duration="1000"
          >
            <h2
              className="the__title small"
              style={{
                color: `${schoolInfo.ScColor}`,
              }}
            >
              Our Blogs
            </h2>
            <p className="sub__heading">See our Latest Blogs</p>
          </div>
          <div className="uk-grid" data-uk-grid>
            <div className="uk-width-expand">
              {blogList.length > 0 ? (
                <>
                  {loading ? (
                    <Spinner />
                  ) : (
                    <div className="my__carousel">
                      <OwlCarousel
                        className="owl-theme"
                        dots={false}
                        // autoWidth
                        // loop
                        margin={30}
                        items={2}
                        nav
                        responsive={{
                          0: {
                            items: 1,
                          },
                          767: {
                            items: 2,
                          },
                        }}
                      >
                        {blogList.map((item) => (
                          <div key={item.BlogID} className="new__blog__wrapper">
                            <div className="my__img">
                              {item.FeatImg ? (
                                <img src={item.FeatImg} alt="Blog" />
                              ) : (
                                <img src={noImage} alt="Blog" />
                              )}
                            </div>
                            <div
                              class="blog__category"
                              style={{
                                background: `${schoolInfo.ScColor}`,
                              }}
                            >
                              <span>{item.CategoryName}</span>
                            </div>
                            <div className="project__head flex">
                              <div className="date">
                                <FaCalendarAlt />
                                <span>{item.CreatedDate}</span>
                              </div>
                              <div className="date uk-flex uk-flex-middle">
                                <span className="blog__user">
                                  {item.CreatedUserImage ? (
                                    <img src={item.CreatedUserImage} alt="dp" />
                                  ) : (
                                    <img src={placeholder} alt="dp" />
                                  )}
                                </span>
                                <span>{item.CreatedUser}</span>
                              </div>
                            </div>
                            <div className="project__content">
                              <h2
                                style={{
                                  color: `${schoolInfo.ScColor}`,
                                }}
                                className="the__title mt-0 uk-text-left"
                              >
                                {item.Title}
                              </h2>
                              <div className="the__content small mt-10">
                                <p>{item.Excerpt}</p>
                              </div>
                            </div>
                            <div className="project__footer">
                              <div className="navvbarr">
                                <button
                                  style={{
                                    border: "none",
                                    background: `${schoolInfo.ScColor}`,
                                  }}
                                  className="getstarted ms-0"
                                  onClick={() => handleView(item)}
                                >
                                  Read more
                                </button>
                              </div>
                              <div className="comments">
                                <FaEye />
                                <span>{item.PostView}</span>
                              </div>
                            </div>
                          </div>
                        ))}
                      </OwlCarousel>
                    </div>
                  )}
                </>
              ) : (
                <div>No data</div>
              )}
            </div>

            <div className="uk-width-1-3@m">
              <div className="my__sidebar">
                <h3 className="the__title mb-3 uk-text-left mt-0 ">
                  Categories
                </h3>
                {categoryList.length > 0 ? (
                  <>
                    {categoryList.map((item) => (
                      <span
                        onClick={() => handleClick(item)}
                        key={item.CategoryID}
                        style={{
                          color: `${schoolInfo.ScColor}`,
                        }}
                      >
                        {item.Category}
                      </span>
                    ))}
                  </>
                ) : (
                  <div>No category available</div>
                )}
                <div
                  className="my__new__overlay"
                  style={{
                    background: `${schoolInfo.ScColor}`,
                    opacity: 0.1,
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {viewPop && (
        <div className="custom__modal">
          <div className="custom__overlay"></div>

          <div className={`custom__modal__content ${viewPop && "active"}`}>
            <div className="custom__modal__header ">
              <div className="the__title small mt-0 ">View Blog</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closeViewPopup}
                  style={{ fontSize: "20px" }}
                ></i>
              </div>
            </div>
            <div className="custom__modal__body">
              <ViewBlog schoolInfo={schoolInfo} viewList={viewList} />
            </div>
            <div className="custom__modal__footer">
              <div className="navvbarr">
                <button
                  style={{
                    border: "none",
                    background: `${schoolInfo.ScColor}`,
                    color: "white",
                  }}
                  className="getstarted ms-0"
                  onClick={closeViewPopup}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Blog;
