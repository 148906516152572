import React, { useContext, useEffect, useState } from "react";
import { Fetchdata } from "../../hooks/getData";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { toast } from "react-toastify";
import RoutineContext from "./RoutineContext";

function RoutineState(props) {
  const { appURL } = useContext(SidebarContext);

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const [classList, setClassList] = useState([]);
  const [sectionList, setSectionList] = useState([]);

  const [selectClass, setSelectClass] = useState("");
  const [selectSection, setSelectSection] = useState("");

  useEffect(() => {
    getClassList();
  }, []);

  const getClassList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      DFlag: "C",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setLoading(false);
        setClassList(postResult);
      } else {
        setClassList([]);
      }
    });
  };

  useEffect(() => {
    getSectionList();
  }, [selectClass]);

  const getSectionList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      ClassID: selectClass,
      DFlag: "S",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";

        setSectionList(postResult);
      } else {
        setSectionList([]);
      }
    });
  };

  // API for routinelist
  const [routineList, setRoutineList] = useState([]);

  useEffect(() => {
    getroutineList();
  }, []);

  const getroutineList = () => {
    const params = {
      SchID: userinfo.SchID,
      MemID: userinfo.UserID,
      Flag: "S",
      DFlag: "E",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/leave`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.LeaveLst ? result.LeaveLst : "";
        setLoading(false);
        setRoutineList(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        setRoutineList([]);
      }
    });
  };

  //Everything to pop up and delete the event list
  const [deletePopup, setDeletePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const [delData, setDelData] = useState();

  const handleDelete = async (data) => {
    setDelData(data);

    setDeletePopup({
      show: true,
      data,
    });
  };

  // This will perform the deletion and hide the Confirmation Box

  const handleDeleteTrue = async () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "R",
      SliderID: delData,
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/slider`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          getroutineList();
          toast(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
        } else {
          toast(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
    });
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleDeleteFalse = () => {
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // Ends Here

  return (
    <RoutineContext.Provider
      value={{
        loading,
        originalList,
        routineList,
        setRoutineList,
        classList,
        sectionList,
        selectClass,
        setSelectClass,
        selectSection,
        setSelectSection,
        deletePopup,
        handleDeleteTrue,
        handleDeleteFalse,
        handleDelete,
      }}
    >
      {props.children}
    </RoutineContext.Provider>
  );
}

export default RoutineState;
