import React, { useEffect, useContext, useState } from "react";
import { Fetchdata } from "../hooks/getData";
import { showImgPreview } from "../hooks/ImagePreview";
import SidebarContext from "../sidebarcontext/SidebarContext";

const Staff = () => {
  const { appURL } = useContext(SidebarContext);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const [imgPrv, setImgPrv] = useState(false);
  const [imagePre, setImagePre] = useState("");

  const [staffList, setStaffList] = useState([]);

  useEffect(() => {
    getstaffList();
  }, []);

  const getstaffList = () => {
    const params = {
      SchID: userinfo.SchID,
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/staff`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.DesignationLst ? result.DesignationLst : "";
        setStaffList(postResult);
      } else {
        setStaffList([]);
      }
    });
  };

  return (
    <>
      {staffList.map((item, i) => {
        return (
          <div className="col-lg-12 col-md-12 col-sm-6 student-staff" key={i}>
            <div>
              <div
                style={{
                  paddingLeft: "5px",
                  fontWeight: "bold",
                  textAlign: "left",
                  fontSize: "13px",
                  marginBottom: "0px",
                  textTransform: "capitalize",
                }}
              >
                {item.Designation}
              </div>
              <div className="profile-info-blog">
                <div className="profilecontentLogoBlog tl">
                  <div className="profilemgmtImgBlog tl">
                    <img
                      src={item.MemImage}
                      alt="dp"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setImagePre(item.MemImage);
                        setImgPrv(true);
                      }}
                    />
                  </div>
                </div>
                <div>
                  <h6
                    style={{
                      paddingLeft: "10px",
                      marginTop: "8px",
                      fontWeight: "bold",
                      textAlign: "left",
                      fontSize: "13px",
                      marginBottom: "0px",
                    }}
                  >
                    {item.MemName}
                  </h6>
                  <p style={{ textAlign: "left", paddingLeft: "10px" }}>
                    {item.MemContact}
                  </p>
                </div>
              </div>
            </div>

            <div className="phone--icon">
              <i className="bi bi-telephone-fill"></i>
            </div>
          </div>
        );
      })}

      {imgPrv &&
        showImgPreview({
          img: imagePre,
          setTrigger: setImgPrv,
        })}
    </>
  );
};

export default Staff;
