import React, { useContext, useState, useEffect } from "react";
import { Fetchdata } from "../hooks/getData";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../loading/spinner";
import { IoIosCloseCircle } from "react-icons/io";
import "./PublicAdmission.css";

export default function PublicAdmission() {
  const [schoolInfo, setSchoolInfo] = useState([]);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const [loading, setLoading] = useState(true);
  const appURL = process.env.REACT_APP_URL;
  const { slug } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const dataForm = {
      SchID: "ES25",
      Flag: "SDS",
      Remarks: slug,
      // Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admission`,
      Type: "POST",
    };

    // Make the API request to fetch user data
    Fetchdata(dataForm)
      .then(function (result) {
        if (result.StatusCode === 200) {
          const postResult = result.StudentLst || [];
          setSchoolInfo(postResult[0]);
          setLoading(false);
        } else {
          navigate("/404");
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const initialAdmissionState = {
    FirstName: "",
    LastName: "",
    Image: "",
    FatherName: "",
    FatherPhone: "",
    FatherMobile: "",
    MotherName: "",
    MotherPhone: "",
    MotherMobile: "",
    UserMobile: "",
    DateOfBirth: "",
    Gender: "Male",
    Class: "",
    Section: "",
    TempAddress: "",
    PermAddress: "",
    EmergFullName: "",
    EmergMobile: "",
    EmergRelation: "",
    Email: "",
    DalitOrJanjati: "",
    Nationality: "",
    BloodGroup: "",
    Religion: "",
    PreviousSchool: "",
    Hobbies: "",
    Remarks: "",
  };
  const [admission, setAdmission] = useState(initialAdmissionState);
  const [validErros, setValidErros] = useState({});
  const [hasValidationError, setHasValidationError] = useState(false);

  const validationErros = () => {
    const err = {};
    const numv = /^[0-9]+$/i;
    const digits = /^\d{10}$/;
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (admission.FirstName === "") {
      err.FirstName = "Please enter your firstname";
    }
    if (admission.LastName === "") {
      err.LastName = "Please enter your lastname";
    }
    if (admission.DateOfBirth === "") {
      err.DateOfBirth = "Please enter your date of birth";
    }

    if (admission.Email.length !== 0) {
      if (!regex.test(admission.Email)) {
        err.Email = "This is not a valid email format";
      }
    }
    if (admission.UserMobile.length !== 0) {
      if (!numv.test(admission.UserMobile)) {
        err.UserMobile = "Must be digits";
      } else if (!digits.test(admission.UserMobile)) {
        err.UserMobile = "Must be 10 digits";
      }
    }

    if (admission.Class === "") {
      err.Class = "Please enter your class";
    }

    if (admission.Section === "") {
      err.Section = "Please enter your section";
    }

    if (admission.FatherName === "") {
      err.FatherName = "Please enter your father name";
    }

    setValidErros({ ...err });

    return Object.keys(err).length < 1;
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Handle checkbox input separately
    if (type === "checkbox") {
      setAdmission({
        ...admission,
        [name]: checked ? value : "", // Set the value only if checked
      });
    } else {
      // For other input types, update normally
      setAdmission({
        ...admission,
        [name]: value,
      });
    }

    // To clear section value while changing class
    if (name === "Class") {
      setAdmission({
        ...admission,
        [name]: value,
        Section: "",
      });
    }

    // Remove validation error when the input is filled
    setValidErros({
      ...validErros,
      [name]: "",
    });

    // Set hasValidationError to false when any input changes
    setHasValidationError(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const validInputs = validationErros();

    if (validInputs) {
      const dataForm = {
        ...admission, // Spread formData into the dataForm object
        SchID: schoolInfo?.SchID,
        Flag: "i",
        // Academicyear: userinfo.AcademicYear,
        FetchURL: `${appURL}api/admission`,
        Type: "POST",
      };

      Fetchdata(dataForm)
        .then(function (result) {
          console.log("API request completed");
          if (result.StatusCode === 200) {
            toast("Form Submit Sucessfuly");
            setAdmission(initialAdmissionState);
            setImagePreviewUrl("");
            setUploaded(false);
          } else {
            toast("Some things wrong");
          }
        })
        .catch((result) => {
          console.error("API request failed", result);
        });
    } else {
      toast("Fill in all the input fields.");
    }
  };

  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);

  const [uploaded, setUploaded] = useState(false);
  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();

      reader.onloadend = () => {
        // Extract only the Base64 part
        const base64String = reader.result.split(",")[1];
        console.log("Base64 String:", base64String);

        setAdmission({ ...admission, Image: base64String });
        setImagePreviewUrl(reader.result);
        setUploaded(true);
      };

      reader.onerror = (error) => {
        console.error("FileReader Error:", error);
        // Handle error or provide feedback to the user
      };

      reader.readAsDataURL(file);
    } else {
      console.error("Invalid file type or no file selected.");
      // Handle error or provide feedback to the user
    }
  };
  const handleRemoveImage = () => {
    // Your logic to remove the image
    // Update the state to clear the image URL
    setImagePreviewUrl("");
    setUploaded(false);
  };

  //API for class list
  const [classList, setClassList] = useState([]);
  useEffect(() => {
    getClassList();
  }, [schoolInfo]);

  const getClassList = () => {
    const params = {
      SchID: schoolInfo?.SchID,
      UserID: "-1",
      DFlag: "C",
      Flag: "S",
      Type: "POST",
      // Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setClassList(postResult);
      } else {
        setClassList([]);
      }
    });
  };

  //API for Section List
  const [sectionList, setSectionList] = useState([]);
  useEffect(() => {
    getSectionList();
  }, [admission.Class, schoolInfo]);

  const getSectionList = () => {
    const params = {
      SchID: schoolInfo?.SchID,
      UserID: "-1",
      ClassID: admission.Class,
      DFlag: "S",
      Flag: "S",
      Type: "POST",
      // Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";

        setSectionList(postResult);
      } else {
        setSectionList([]);
      }
    });
  };
  const handleKeyPress = (event) => {
    // Check if the key pressed is not a number

    if (isNaN(Number(event.key))) {
      event.preventDefault(); // Prevent the character from being entered
    } // Prevent the character from being entered
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />

      <>
        {loading ? (
          <Spinner />
        ) : (
          <div className="onlineAdmissionForm">
            <form className="form-data" onSubmit={handleSubmit}>
              <div
                className="schoolHeaderInfo"
                style={{
                  backgroundColor: `${schoolInfo.ScColor}`,
                }}
              >
                <div className="header-flex">
                  <div className="schoolLogo">
                    <img src={schoolInfo.SchLogo} alt="logo" />
                    {/* <img src={logo} alt="" /> */}
                  </div>
                  <div className="schoolInfo">
                    <h2>{schoolInfo.SchName}</h2>
                    <h3>{schoolInfo.SchAddress}</h3>
                    <p className="admission-txt">Admission Form</p>
                  </div>
                  <div></div>
                </div>
              </div>

              <div className="formSection personalInformation">
                <h2 className="heading">Student's Name (IN BLOCK LETTERS) </h2>
                <div>
                  <div className="formInput">
                    <label htmlFor="FirstName" className="req-label">
                      First Name :<sup>*</sup>
                    </label>
                    <div className="">
                      <input
                        type="text"
                        name="FirstName"
                        id="FirstName"
                        value={admission.FirstName}
                        onChange={handleChange}
                      />
                      {/* <span className='validationMsg'>{validErros.MotherPhone}</span> */}
                      <span className="validationMsg">
                        {validErros.FirstName}
                      </span>
                    </div>
                  </div>

                  <div className="formInput">
                    <label htmlFor="LastName" className="req-label">
                      Last Name :<sup>*</sup>
                    </label>
                    <div className="">
                      <input
                        type="text"
                        name="LastName"
                        id="LastName"
                        value={admission.LastName}
                        onChange={handleChange}
                      />
                      <span className="validationMsg">
                        {validErros.LastName}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="formSection">
                <h2 className="heading">Student Information</h2>
                <div>
                  <div className="formInput">
                    <label htmlFor="DateOfBirth" className="req-label">
                      Date of birth:<sup>*</sup>
                    </label>
                    <div className="">
                      <input
                        type="date"
                        name="DateOfBirth"
                        id="DateOfBirth"
                        value={admission.DateOfBirth}
                        onChange={handleChange}
                      />
                      <span className="validationMsg">
                        {validErros.DateOfBirth}
                      </span>
                    </div>
                  </div>

                  <div className="formInput">
                    <label htmlFor="Gender" className="req-label">
                      Gender :
                    </label>
                    <div>
                      <div className="gender">
                        <span>
                          <input
                            type="radio"
                            name="Gender"
                            id="male"
                            value="Male"
                            checked={admission.Gender === "Male"}
                            onChange={handleChange}
                          />
                          <label htmlFor="male">Male</label>
                        </span>
                        <span>
                          <input
                            type="radio"
                            name="Gender"
                            id="female"
                            value="Female"
                            checked={admission.Gender === "Female"}
                            onChange={handleChange}
                          />
                          <label htmlFor="female">Female</label>
                        </span>
                        <span>
                          <input
                            type="radio"
                            name="Gender"
                            id="other"
                            value="Other"
                            checked={admission.Gender === "Other"}
                            onChange={handleChange}
                          />
                          <label htmlFor="other">Other</label>
                        </span>
                      </div>
                      {/* <span className='validationMsg'>{validErros.Gender}</span> */}
                    </div>
                  </div>
                  <div className="formInput">
                    <label htmlFor="UserMobile" className="req-label">
                      Mobile No. :
                    </label>
                    <div className="">
                      <input
                        type="number"
                        name="UserMobile"
                        id="UserMobile"
                        value={admission.UserMobile}
                        onChange={handleChange}
                        onKeyPress={handleKeyPress}
                      />
                      <span className="validationMsg">
                        {validErros.UserMobile}
                      </span>
                    </div>
                  </div>
                  <div className="formInput">
                    <label htmlFor="Email" className="req-label">
                      Email :
                    </label>
                    <div className="">
                      <input
                        type="text"
                        name="Email"
                        id="Email"
                        value={admission.Email}
                        onChange={handleChange}
                      />
                      <span className="validationMsg">{validErros.Email}</span>
                    </div>
                  </div>

                  <div className="formInput">
                    <label htmlFor="Class" className="req-label">
                      Class :<sup>*</sup>
                    </label>
                    <div className="">
                      {/* <input type="text" name="Class" id="Class" value={admission.Class}
                                      onChange={handleChange} /> */}

                      <select
                        name="Class"
                        id="Class"
                        value={admission.Class}
                        onChange={handleChange}
                        className="form-control form-control-sm"
                        aria-label="Default select example "
                      >
                        <option value="" disabled selected>
                          Select Class
                        </option>
                        {classList.map((item) => (
                          <option key={item.ClassID} value={item.ClassID}>
                            {item.ClassName}
                          </option>
                        ))}
                      </select>

                      <span className="validationMsg">{validErros.Class}</span>
                    </div>
                  </div>
                  <div className="formInput">
                    <label htmlFor="Section" className="req-label">
                      Section :<sup>*</sup>
                    </label>
                    <div className="">
                      {/* <input
                    type="text"
                    name="Section"
                    id="Section"
                    value={admission.Section}
                    onChange={handleChange}
                  /> */}

                      <select
                        name="Section"
                        id="Section"
                        value={admission.Section}
                        onChange={handleChange}
                        className="form-control form-control-sm"
                        aria-label="Default select example "
                      >
                        <option value="" disabled selected>
                          Select Section
                        </option>
                        {sectionList.map((item) => (
                          <option key={item.SectionID} value={item.SectionID}>
                            {item.SectionName}
                          </option>
                        ))}
                      </select>

                      <span className="validationMsg">
                        {validErros.Section}
                      </span>
                    </div>
                  </div>

                  <div className="formInput">
                    <label htmlFor="TempAddress" className="req-label">
                      Temporary Address :
                    </label>
                    <div className="">
                      <input
                        type="test"
                        name="TempAddress"
                        id="TempAddress"
                        value={admission.TempAddress}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="formInput">
                    <label htmlFor="PermAddress" className="req-label">
                      Permanent Address :
                    </label>
                    <div className="">
                      <input
                        type="test"
                        name="PermAddress"
                        id="PermAddress"
                        value={admission.PermAddress}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="formInput">
                    <label htmlFor="BloodGroup" className="req-label">
                      BloodGroup :
                    </label>
                    <div className="">
                      {/* <input
                    type="text"
                    name="BloodGroup"
                    id="BloodGroup"
                    value={admission.BloodGroup}
                    onChange={handleChange}
                  /> */}
                      <select
                        name="BloodGroup"
                        id="BloodGroup"
                        value={admission.BloodGroup}
                        onChange={handleChange}
                        className="form-control form-control-sm"
                        aria-label="Default select example "
                      >
                        <option value="" disabled selected>
                          Select Blood Group
                        </option>
                        <option value="A+">A+</option>
                        <option value="A-">A-</option>
                        <option value="B+">B+</option>
                        <option value="B-">B-</option>
                        <option value="O+">O+</option>
                        <option value="O-">O-</option>
                        <option value="AB+">AB+</option>
                        <option value="AB-">AB-</option>
                      </select>
                      {/* <span className='validationMsg'>{validErros.BloodGroup}</span> */}
                    </div>
                  </div>

                  <div className="formInput">
                    <label htmlFor="Nationality" className="req-label">
                      Nationality :
                    </label>
                    <div className="">
                      {/* <input
                    type="text"
                    name="Nationality"
                    id="Nationality"
                    value={admission.Nationality}
                    onChange={handleChange}
                  /> */}
                      <select
                        name="Nationality"
                        id="Nationality"
                        value={admission.Nationality}
                        onChange={handleChange}
                        className="form-control form-control-sm"
                        aria-label="Default select example "
                      >
                        <option value="" disabled selected>
                          Select Nationality
                        </option>
                        <option value="Nepali">Nepali</option>
                      </select>
                      {/* <span className='validationMsg'>{validErros.Nationality}</span> */}
                    </div>
                  </div>

                  <div className="formInput">
                    <label htmlFor="Religion" className="req-label">
                      Religion :
                    </label>
                    <div className="">
                      {/* <input
                    type="text"
                    name="Religion"
                    id="Religion"
                    value={admission.Religion}
                    onChange={handleChange}
                  /> */}
                      <select
                        name="Religion"
                        id="Religion"
                        value={admission.Religion}
                        onChange={handleChange}
                        className="form-control form-control-sm"
                        aria-label="Default select example "
                      >
                        <option value="" disabled selected>
                          Select Religion
                        </option>
                        <option value="Hindu">Hindu</option>
                        <option value="Buddhist">Buddhist</option>
                        <option value="Christian">Christian</option>
                        <option value="Muslim">Muslim</option>
                        <option value="Kirati">Kirati</option>
                        <option value="Sikhs">Sikhs</option>
                        <option value="Jains">Jains</option>
                      </select>
                      {/* <span className='validationMsg'>{validErros.Religion}</span> */}
                    </div>
                  </div>
                  <div className="formInput">
                    <label htmlFor="DalitOrJanjati" className="req-label">
                      Dalit / Janjati :
                    </label>
                    <div className="">
                      {/* <input
                    type="text"
                    name="DalitOrJanjati"
                    id="DalitOrJanjati"
                    value={admission.DalitOrJanjati}
                    onChange={handleChange}
                  /> */}
                      <select
                        name="DalitOrJanjati"
                        id="DalitOrJanjati"
                        value={admission.DalitOrJanjati}
                        onChange={handleChange}
                        className="form-control form-control-sm"
                        aria-label="Default select example "
                      >
                        <option value="" disabled selected>
                          Select Dalit/Janjati
                        </option>
                        <option value="Brahaman/Chhetri">
                          Brahaman/Chhetri
                        </option>
                        <option value="Dalit">Dalit</option>
                        <option value="Janjati">Janjati</option>
                        <option value="Other">Other</option>
                      </select>
                      {/* <span className='validationMsg'>{validErros.DalitOrJanjati}</span> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="formSection ">
                <h2 className="heading">Father's Information </h2>
                <div>
                  <div className="formInput  full-width">
                    <label
                      htmlFor="FatherName"
                      className="req-label"
                      style={{ width: "200px" }}
                    >
                      Father's Name :<sup>*</sup>
                    </label>
                    <div className="">
                      <input
                        type="text"
                        name="FatherName"
                        id="FatherName"
                        value={admission.FatherName}
                        onChange={handleChange}
                      />
                      <span className="validationMsg">
                        {validErros.FatherName}
                      </span>
                    </div>
                  </div>
                  <div className="formInput">
                    <label htmlFor="FatherMobile" className="req-label">
                      Mobile No. :
                    </label>
                    <div className="">
                      <input
                        type="text"
                        name="FatherMobile"
                        id="FatherMobile"
                        value={admission.FatherMobile}
                        onChange={handleChange}
                      />
                      {/* <span className='validationMsg'>{validErros.FatherMobile}</span> */}
                    </div>
                  </div>
                  <div className="formInput">
                    <label htmlFor="FatherPhone">Phone No. :</label>
                    <div className="">
                      <input
                        type="text"
                        name="FatherPhone"
                        id="FatherPhone"
                        value={admission.FatherPhone}
                        onChange={handleChange}
                      />
                      {/* <span className='validationMsg'>{validErros.FatherPhone}</span> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="formSection ">
                <h3 className="heading">Mother's Information</h3>
                <div>
                  <div className="formInput  full-width">
                    <label
                      htmlFor="MotherName"
                      className="req-label"
                      style={{ width: "200px" }}
                    >
                      Mother's Name. :
                    </label>
                    <div className="">
                      <input
                        type="text"
                        name="MotherName"
                        id="MotherName"
                        value={admission.MotherName}
                        onChange={handleChange}
                      />
                      {/* <span className='validationMsg'>{validErros.MotherName}</span> */}
                    </div>
                  </div>
                  <div className="formInput">
                    <label htmlFor="MotherMobile" className="req-label">
                      Mobile No. :
                    </label>
                    <div className="">
                      <input
                        type="text"
                        name="MotherMobile"
                        id="MotherMobile"
                        value={admission.MotherMobile}
                        onChange={handleChange}
                      />
                      {/* <span className='validationMsg'>{validErros.MotherMobile}</span> */}
                    </div>
                  </div>
                  <div className="formInput">
                    <label htmlFor="MotherPhone">Phone No. :</label>
                    <div className="">
                      <input
                        type="text"
                        name="MotherPhone"
                        id="MotherPhone"
                        value={admission.MotherPhone}
                        onChange={handleChange}
                      />
                      {/* <span className='validationMsg'>{validErros.MotherPhone}</span> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="formSection academicDetail">
                <h2 className="heading">Academic Detail</h2>
                <div>
                  <div className="formInput full-width">
                    <label
                      htmlFor="PreviousSchool"
                      className="req-label"
                      style={{ width: "300px" }}
                    >
                      Name of Previous School :
                    </label>
                    <div className="">
                      <input
                        type="text"
                        name="PreviousSchool"
                        id="PreviousSchool"
                        value={admission.PreviousSchool}
                        onChange={handleChange}
                      />
                      {/* <span className='validationMsg'>{validErros.PreviousSchool}</span> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="formSection">
                <h2 className="heading">Emergency Information</h2>
                <div>
                  <div className="formInput full-width">
                    <label htmlFor="EmergFullName" className="req-label">
                      Full Name :
                    </label>
                    <div className="">
                      <input
                        type="text"
                        name="EmergFullName"
                        id="EmergFullName"
                        value={admission.EmergFullName}
                        onChange={handleChange}
                      />
                      {/* <span className='validationMsg'>{validErros.EmergFullName}</span> */}
                    </div>
                  </div>

                  <div className="formInput">
                    <label htmlFor="EmergRelation" className="req-label">
                      Relation :
                    </label>
                    <div className="">
                      <input
                        type="text"
                        name="EmergRelation"
                        id="EmergRelation"
                        value={admission.EmergRelation}
                        onChange={handleChange}
                      />
                      {/* <span className='validationMsg'>{validErros.EmergRelation}</span> */}
                    </div>
                  </div>

                  <div className="formInput">
                    <label htmlFor="EmergMobile" className="req-label">
                      Mobile No. :
                    </label>
                    <div className="">
                      <input
                        type="text"
                        name="EmergMobile"
                        id="EmergMobile"
                        value={admission.EmergMobile}
                        onChange={handleChange}
                      />
                      {/* <span className='validationMsg'>{validErros.EmergMobile}</span> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="formSection">
                <div className="activities">
                  <h2 className="heading">E. Extracurricular Activities</h2>
                  <div className="grid-1">
                    <div>
                      <label htmlFor="Hobbies" className="req-label">
                        Hobby (Please Mention) :
                      </label>
                      <textarea
                        name="Hobbies"
                        id="Hobbies"
                        value={admission.Hobbies}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                    <div>
                      <label htmlFor="Remarks" className="req-label">
                        Remarks :
                      </label>
                      <textarea
                        name="Remarks"
                        id="Remarks"
                        value={admission.Remarks}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>

              <div className="formSection boximage">
                <label className="form-label" htmlFor="text">
                  Choose Image
                </label>

                <div className="userPhoto">
                  <label
                    className="picture__input"
                    tabIndex="0"
                    style={{ padding: "0" }}
                  >
                    <span className="picture__image">
                      {imagePreviewUrl ? (
                        <>
                          <img
                            src={imagePreviewUrl}
                            alt="Uploaded Image"
                            className="picture__img"
                          />
                          <IoIosCloseCircle
                            className="remove-icon"
                            onClick={handleRemoveImage}
                          />
                        </>
                      ) : (
                        <small>
                          'PP size recent color photo (Click here to add)'{" "}
                        </small>
                      )}
                    </span>
                    {!uploaded && (
                      <input
                        type="file"
                        name="picture__input"
                        id="picture__input"
                        accept="image/*"
                        onChange={handleImageChange}
                        style={{ display: "none" }}
                      />
                    )}
                  </label>
                </div>
              </div>

              <div className="formSection agrement">
                <p>
                  By checking the checkbox below you have confirmed the
                  eligibility criteria to apply and you are ready for further
                  application process.
                </p>
                <div>
                  <input type="checkbox" id="agree" />
                  <label htmlFor="agree">I agree</label>
                </div>
              </div>
              <div className="formSection">
                <button
                  className="submitBtn"
                  type="submit"
                  style={{
                    backgroundColor: `${schoolInfo.ScColor}`,
                  }}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        )}
      </>
    </>
  );
}
