import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";
import "tippy.js/dist/tippy.css";
import { Fetchdata } from "../hooks/getData";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import jsPDF from "jspdf";
import "jspdf-autotable";

export default function AccountSummary() {
  const { customStyles, appURL } = useContext(SidebarContext);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);

  // API for account report list
  const [accountReportList, setAccountReportList] = useState([]);

  useEffect(() => {
    getreportList();
  }, []);

  const getreportList = () => {
    const params = {
      SchID: userinfo.SchID,
      Flag: "S",
      MemID: userinfo.UserID.toString(),
      DFlag: "N",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/account`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.AccLst ? result.AccLst : "";
        setLoading(false);
        setAccountReportList(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        setAccountReportList([]);
        setOriginalList([]);
      }
    });
  };

  let credit = accountReportList.reduce(function (prev, current) {
    return prev + parseFloat(current.Credit);
  }, 0);

  let debit = accountReportList.reduce(function (prev, current) {
    return prev + parseFloat(current.Debit);
  }, 0);

  const searchInput = useRef("");

  const columns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "Month",
      selector: (row) => row.Month,
    },

    {
      name: "Debit",
      width: "150px",
      right: true,
      selector: (row) => parseFloat(row.Debit).toFixed(2),
    },
    {
      name: "Credit",
      width: "150px",
      right: true,
      selector: (row) => parseFloat(row.Credit).toFixed(2),
    },
    {
      name: "Balance",
      width: "150px",
      right: true,
      selector: (row) => parseFloat(row.Balance).toFixed(2),
    },
  ];

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["Balance"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setAccountReportList(srchResult);
      } else {
        setAccountReportList({});
      }
    } else {
      setAccountReportList(originalList);
    }
  };

  /* This is for export csv */

  const dataToExcel = accountReportList.map((d, i) => ({
    "S.N.": i + 1,
    Month: d.Month,
    Balance: d.Balance,
    Debit: d.Debit,
    Credit: d.Credit,
  }));

  const toExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(dataToExcel);
      var wscols = [
        { wch: 5 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
      ];
      worksheet["!cols"] = wscols;
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "accountSummaryList");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_" + "export" + EXCEL_EXTENSION
        );
      }
    });
  };

  //end

  //This is to export pdf

  const dataWithIndex = accountReportList.map((d, i) => ({
    ...d,
    Index: i + 1,
  }));

  const cols = [
    { header: "S.N", field: "Index" },
    { header: "Month", field: "Month" },
    { header: "Balance", field: "Balance" },
    { header: "Debit", field: "Debit" },
    { header: "Credit", field: "Credit" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const toPdf = () => {
    const doc = new jsPDF("p", "pt", "a4", true);

    var midPage = doc.internal.pageSize.getWidth() / 2;

    // doc.addImage(`${userinfo.SchPhoto}`, "JPG", 40, 40, 70, 60);

    doc.setFontSize(18);
    doc.text(`${userinfo.SchName}`, midPage, 50, null, null, "center");

    doc.setFontSize(15);
    doc.text(`${userinfo.SchAddress}`, midPage, 68, null, null, "center");

    doc.setFontSize(10);
    doc.text(
      `Tel:${userinfo.Phone}, E-mail:${userinfo.Email}`,
      midPage,
      85,
      null,
      null,
      "center"
    );

    doc.autoTable({
      startY: 100,
      theme: "grid",
      columns: exportColumns,
      body: dataWithIndex,
    });
    doc.save("accountSummaryList.pdf");
  };
  //end

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <div className="sec-dataTable">
        <div className="upper-dataTbl">
          <Tippy content="PDF">
            <button className="exportButtons mx-2" onClick={toPdf}>
              <i className="bi bi-file-earmark-pdf-fill exportButtonIconsPdf"></i>
            </button>
          </Tippy>

          <Tippy content="Excel">
            <button className="exportButtons" onClick={toExcel}>
              <i className="bi bi-file-earmark-excel-fill exportButtonIconsExcel"></i>
            </button>
          </Tippy>
        </div>
        <hr className="new-hr" />

        <div
          className="col-lg-2 offset-lg-10 offset-md-10 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
          style={{ paddingRight: "1px", marginBottom: "10px" }}
        >
          <div className="d-flex">
            <input
              placeholder="Search"
              ref={searchInput}
              type="text"
              className="form-control form-control-sm searchField"
              onChange={searchHandler}
            />
          </div>
        </div>
        {loading ? (
          <Spinner />
        ) : (
          <>
            {accountReportList.length > 0 ? (
              <div className="table-responsive br-3">
                <table className="table table-bordereds text-nowrap mb-0">
                  <thead>
                    <tr className="tableHead">
                      <td className="tc" style={{ width: "70px" }}>
                        S.N.
                      </td>
                      <td className="tl">Month</td>
                      <td className="tr" style={{ width: "150px" }}>
                        Debit
                      </td>
                      <td className="tr" style={{ width: "150px" }}>
                        Credit
                      </td>
                      <td className="tr" style={{ width: "150px" }}>
                        Balance
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {accountReportList.map((item, i) => (
                      <tr key={i}>
                        <td className="tc" style={{ width: "70px" }}>
                          {i + 1}
                        </td>
                        <td className="tl">{item.Month}</td>
                        <td className="tr">
                          {parseFloat(item.Debit).toFixed(2)}{" "}
                        </td>
                        <td className="tr">
                          {parseFloat(item.Credit).toFixed(2)}
                        </td>
                        <td className="tr">
                          {parseFloat(item.Balance).toFixed(2)}
                        </td>
                      </tr>
                    ))}
                    <tr className="lastt">
                      <td colspan="2">
                        <b style={{ paddingLeft: "15px" }}>Total</b>
                      </td>
                      <td className="tr">
                        <b>{debit.toFixed(2)}</b>
                      </td>
                      <td className="tr ">
                        <b>{credit.toFixed(2)}</b>
                      </td>
                      <td className="tr ">
                        <b>{(debit - credit).toFixed(2)} </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="table-responsive br-3">
                <div className="text-center mt-4">
                  <p>There are no records to display.</p>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}
