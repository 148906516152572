import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthContextProvider } from "./component/auth-context";
import { BrowserRouter } from "react-router-dom";
import "aos/dist/aos.css";
import { SessionContextProvider } from "./component/SessionContext";
import { FilterJobContextProvider } from "./component/FilterjobContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <SessionContextProvider>
    <FilterJobContextProvider>
      <AuthContextProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AuthContextProvider>
    </FilterJobContextProvider>
  </SessionContextProvider>
);

reportWebVitals();
