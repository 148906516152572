import React, { useContext, useEffect, useState } from "react";
import { Fetchdata } from "../../hooks/getData";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { toast } from "react-toastify";
import { convertToNep } from "../../hooks/dateConvertor";
import PaymentContext from "./PaymentContext";

function PaymentState(props) {
  const { appURL, newMonth, todayDate } = useContext(SidebarContext);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const [reportOption, setReportOption] = useState("");
  const [paymentGateway, setPaymentGateway] = useState("-1");
  const [paymentStatus, setPaymentStatus] = useState("-1");

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);
  const [summarySearch, setSummarySearch] = useState(null);
  const [todaySearch, setTodaySearch] = useState(null);

  const [classList, setClassList] = useState([]);
  const [sectionList, setSectionList] = useState([]);

  const [selectClass, setSelectClass] = useState("");
  const [selectSection, setSelectSection] = useState("");
  const [selectStudent, setSelectStudent] = useState("");

  const reportValue = {
    fromDate: newMonth,
    toDate: "",
    specificDate: "",
  };

  const [reportDateValue, setReportDateValue] = useState(reportValue);

  const todayValue = {
    specificDate: todayDate,
  };

  const [todayDateValue, setTodayDateValue] = useState(todayValue);

  const [classLists, setClassLists] = useState([]);
  const [sectionLists, setSectionLists] = useState([]);

  const [selectClasses, setSelectClasses] = useState("");
  const [selectSections, setSelectSections] = useState("");

  useEffect(() => {
    getClassList();
  }, []);

  const getClassList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      DFlag: "C",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setLoading(false);
        setClassList(postResult);
      } else {
        setClassList([]);
      }
    });
  };

  useEffect(() => {
    getSectionList();
  }, [selectClass]);

  const getSectionList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      ClassID: selectClass,
      DFlag: "S",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";

        setSectionList(postResult);
      } else {
        setSectionList([]);
      }
    });
  };

  //API to hit Student list
  const [studentList, setStudentList] = useState([]);

  useEffect(() => {
    stdList();
  }, [selectClass, selectSection]);

  const stdList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      Flag: "S",
      ClassID: selectClass,
      SectionID: [
        {
          SectionID: selectSection,
        },
      ],
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/student`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.StudentLst ? result.StudentLst : "";
        setStudentList(postResult);
      } else {
        setStudentList([]);
      }
    });
  };

  // API for payment report list
  const [paymentReportList, setPaymentReportList] = useState([]);

  useEffect(() => {
    getPaymentList();
  }, [selectStudent, reportDateValue, paymentStatus, paymentGateway]);

  const getPaymentList = () => {
    const params = {
      SchID: userinfo.SchID,
      Flag: "R",
      DtFlag: "S",
      Values: reportDateValue.specificDate,
      PayStatus: paymentStatus,
      Gateway: paymentGateway,
      MemID: selectStudent,
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/payment-report`,
    };

    const formParams = {
      SchID: userinfo.SchID,
      Flag: "R",
      DtFlag: "F",
      PayStatus: paymentStatus,
      Gateway: paymentGateway,
      MemID: selectStudent,
      FromDate: reportDateValue.fromDate,
      ToDate: reportDateValue.toDate,
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/payment-report`,
    };

    Fetchdata(reportOption === "S" ? params : formParams).then(function (
      result
    ) {
      if (result.StatusCode === 200) {
        const postResult = result.AccLst ? result.AccLst : "";
        setLoading(false);
        setPaymentReportList(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        setPaymentReportList([]);
        setOriginalList([]);
      }
    });
  };

  // API for account summary list

  useEffect(() => {
    getClassLists();
  }, []);

  const getClassLists = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      DFlag: "C",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setLoading(false);
        setClassLists(postResult);
      } else {
        setClassLists([]);
      }
    });
  };

  useEffect(() => {
    getSectionLists();
  }, [selectClasses]);

  const getSectionLists = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      ClassID: selectClasses,
      DFlag: "S",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";

        setSectionLists(postResult);
      } else {
        setSectionLists([]);
      }
    });
  };

  const [paymentSummaryList, setPaymentSummaryList] = useState([]);

  useEffect(() => {
    getsummaryList();
  }, [selectClasses, selectSections]);

  const getsummaryList = () => {
    const params = {
      SchID: userinfo.SchID,
      Flag: "S",
      DtFlag: "S",
      ClassID: selectClasses,
      SectionID: selectSections,
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/payment-report`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.AccLst ? result.AccLst : "";
        setLoading(false);
        setPaymentSummaryList(postResult);
        setSummarySearch(postResult);
      } else {
        setLoading(false);
        setPaymentSummaryList([]);
        setSummarySearch([]);
      }
    });
  };

  // Api for today date value
  const [todayList, setTodayList] = useState([]);

  useEffect(() => {
    getTodayList();
  }, [todayDateValue]);

  const getTodayList = () => {
    const params = {
      SchID: userinfo.SchID,
      Flag: "R",
      DtFlag: "S",
      Values: todayDateValue.specificDate,
      PayStatus: "-1",
      Gateway: "-1",
      MemID: "-1",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/payment-report`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.AccLst ? result.AccLst : "";
        setLoading(false);
        setTodayList(postResult);
        setTodaySearch(postResult);
      } else {
        setTodayList([]);
        setTodaySearch([]);
      }
    });
  };

  return (
    <PaymentContext.Provider
      value={{
        loading,
        originalList,
        paymentReportList,
        setPaymentReportList,
        classList,
        sectionList,
        selectClass,
        setSelectClass,
        selectSection,
        setSelectSection,
        studentList,
        selectStudent,
        setSelectStudent,
        reportDateValue,
        setReportDateValue,
        paymentSummaryList,
        setPaymentSummaryList,
        summarySearch,

        classLists,
        setClassLists,
        sectionLists,
        setSectionLists,
        selectClasses,
        setSelectClasses,
        selectSections,
        setSelectSections,

        todayDateValue,
        setTodayDateValue,
        todaySearch,
        todayList,
        setTodayList,
        reportOption,
        setReportOption,
        paymentGateway,
        setPaymentGateway,
        paymentStatus,
        setPaymentStatus,
      }}
    >
      {props.children}
    </PaymentContext.Provider>
  );
}

export default PaymentState;
