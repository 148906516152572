import React, { useContext, useEffect, useState } from "react";
import SidebarContext from "../sidebarcontext/SidebarContext";
import { Fetchdata } from "../hooks/getData";
import SyllabusPdfContext from "../contextfolder/SyllabusPdfContext/SyllabusPdfContext";
import CloseIcon from "../../images/CloseIcon.svg";

export default function SyllabusPdfForm({ closePopup }) {
  const { appURL } = useContext(SidebarContext);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const context = useContext(SyllabusPdfContext);

  const {
    togglePopup,
    FormError,
    setFormError,
    isSubmit,
    setIsSubmit,
    chapterValue,
    FormValue,
    setFormValue,
    fetchdata,
    closeChecked,
    setCloseChecked,
    inputFields,
    setInputFields,
    setNewData,
    isCoverUploaded,
    setIsCoverUploaded,
    setTypeCoverFile,
    coverFile,
    setCoverFile,
  } = context;

  const [error, setError] = useState("");

  const handleOnChange = () => {
    setCloseChecked(!closeChecked);
  };

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setFormValue({ ...FormValue, [name]: value });
  };

  function handleCoverChange(e) {
    if (e.target.files && e.target.files[0]) {
      setTypeCoverFile(e.target.files[0].type);
      setCoverFile(e.target.files[0].name);
      let reader = new FileReader();

      reader.onload = function (e) {
        setTypeCoverFile(e.target.result);
        setIsCoverUploaded(true);
      };

      reader.readAsDataURL(e.target.files[0]);
    }
  }

  /* for dynamic input fields ends*/

  const formSubmit = (e) => {
    e.preventDefault();
    setFormError(validate(FormValue));
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(FormError).length === 0 && isSubmit) {
      fetchdata(FormValue);
      if (closeChecked === true) {
        togglePopup();
      }
      setFormValue(chapterValue);
      setIsSubmit(false);
    }
  }, [FormError]);

  const validate = (values) => {
    const errors = {};
    if (!values.class) {
      errors.class = "Required";
    }
    if (userinfo.UserType === "T") {
      if (!values.section) {
        errors.section = "Required";
      }
    }

    if (!values.subject) {
      errors.subject = "Required";
    }

    return errors;
  };

  //API for class list
  const [classList, setClassList] = useState([]);
  useEffect(() => {
    getClassList();
  }, []);

  const getClassList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      DFlag: "C",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setClassList(postResult);
      } else {
        setClassList([]);
      }
    });
  };

  const [sectionList, setSectionList] = useState([]);

  useEffect(() => {
    getSectionList();
  }, [FormValue.class]);

  const getSectionList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      ClassID: FormValue.class,
      DFlag: "S",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setSectionList(postResult);
      } else {
        setSectionList([]);
      }
    });
  };
  //API for subject list
  const [subjectList, setSubjectList] = useState([]);

  useEffect(() => {
    getSubjectList();
  }, [FormValue.class, FormValue.section]);

  const getSubjectList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      ClassID: FormValue.class,
      SectionID:userinfo.UserType === "T"  ? FormValue.section :"",
      DFlag: "SB",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";

        setSubjectList(postResult);
      } else {
        setSubjectList([]);
      }
    });
  };

  return (
    <>
      <div className="container-fluid basicform">
        <form>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label className="form-label" htmlFor="class">
                Class<sup style={{ color: "red" }}>*</sup>
              </label>
              <select
                id="class"
                name="class"
                onChange={handleChange}
                value={FormValue.class}
                className="form-select form-select-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="" disabled selected style={{ fontSize: "11px" }}>
                  Select Class
                </option>
                {classList.map((item) => (
                  <option key={item.ClassID} value={item.ClassID}>
                    {item.ClassName}
                  </option>
                ))}
              </select>
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {FormError.class}
              </p>
            </div>

            {userinfo.UserType === "T" && (
              <div className="form-group col-md-6">
                <label className="form-label" htmlFor="section">
                  Section<sup style={{ color: "red" }}>*</sup>
                </label>
                <select
                  id="section"
                  name="section"
                  onChange={handleChange}
                  value={FormValue.section}
                  className="form-select form-select-sm mb-1"
                  style={{ fontSize: "12px" }}
                >
                  <option
                    value=""
                    disabled
                    selected
                    style={{ fontSize: "11px" }}
                  >
                    Select section
                  </option>
                  {sectionList.map((item) => (
                    <option key={item.SectionID} value={item.SectionID}>
                      {item.SectionName}
                    </option>
                  ))}
                </select>
                <p className="errormsg " style={{ marginBottom: "0" }}>
                  {FormError.section}
                </p>
              </div>
            )}
            <div className="form-group col-md-6">
              <label className="form-label" htmlFor="subject">
                Subject<sup style={{ color: "red" }}>*</sup>
              </label>
              <select
                id="subject"
                name="subject"
                onChange={handleChange}
                value={FormValue.subject}
                className="form-select form-select-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="" disabled selected style={{ fontSize: "11px" }}>
                  Select Subject
                </option>
                {subjectList.map((item) => (
                  <option key={item.SubjectID} value={item.SubjectID}>
                    {item.SubjectName}
                  </option>
                ))}
              </select>
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {FormError.subject}
              </p>
            </div>
          </div>

          <div className="row text-start ">
            <div className="form-group">
              {!isCoverUploaded ? (
                <>
                  <div className="pdfFile w-100">
                    <label htmlFor="coverData">
                      <div
                        // type="button"
                        class="btn btn-sm btn-cmpy"
                      >
                        Upload File
                      </div>
                    </label>

                    <input
                      id="coverData"
                      type="file"
                      accept=".pdf"
                      onChange={handleCoverChange}
                      className="d-none"
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="w-100 ">
                    <div className="uk-position-relative">
                      <input
                        style={{ fontSize: "13px" }}
                        type="text"
                        className="form-control form-control-sm "
                        disabled
                        value={coverFile}
                      />
                      <img
                        className="close-icon"
                        src={CloseIcon}
                        alt="CloseIcon"
                        onClick={() => {
                          setIsCoverUploaded(false);
                          setTypeCoverFile(null);
                          setCoverFile("");
                        }}
                        style={{
                          position: "absolute",
                          top: "50%",
                          transform: "translateY(-50%)",
                          height: " 15px",
                        }}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </form>
      </div>
      <div className="FormFooter">
        <div className="checkedClose">
          <input
            type="checkbox"
            className="form-check-input"
            id="exampleCheck2"
            name="closeChecked"
            onChange={handleOnChange}
            checked={closeChecked}
          />
          <label class="form-check-label" for="exampleCheck2">
            Exit after submit
          </label>
        </div>
        <div>
          <button
            className="btn btn-sm btn-cmpy ml-2"
            style={{ background: "green" }}
            onClick={formSubmit}
          >
            Submit
          </button>
          <button className="btn btn-sm btn-cmpy ml-2" onClick={closePopup}>
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}
