import React, { useContext, useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import '../../css/Commission.css';
import SidebarContext from "../sidebarcontext/SidebarContext";
import Spinner from "../loading/spinner";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import Tippy from "@tippyjs/react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import PaymentContext from "../contextfolder/PaymentContext/PaymentContext";

export default function TodayPayment() {
    const { todayDate, userinfo } = useContext(SidebarContext);


    const { loading, todaySearch, todayDateValue, setTodayDateValue, todayList, setTodayList } = useContext(PaymentContext);





    const handleToDate = ({ adDate }) => {
        setTodayDateValue({ ...todayDateValue, specificDate: adDate });
    };




    let amount = todayList.reduce(function (prev, current) {
        return prev + parseFloat(current.Amount);
    }, 0);

    const searchInput = useRef("");

    const searchHandler = (e) => {
        e.preventDefault();

        const srchQuery = searchInput.current.value.toLowerCase();
        if (srchQuery) {

            let srchResult = todaySearch.filter((list) => {
                return list["FullName"].toLowerCase().includes(srchQuery);
            });
            if (srchResult) {

                setTodayList(srchResult);
            } else {
                setTodayList({});
            }
        } else {
            setTodayList(todaySearch);
        }
    };


    /* This is for export csv */

    const dataToExcel = todayList.map((d, i) => ({
        "S.N.": i + 1,
        "Date": d.CreatedDate,
        "Name": d.FullName,
        "Gateway": d.Gateway,
        Status: d.Status,
        Amount: parseFloat(d.Amount).toFixed(2),

    }));

    const toExcel = () => {
        import("xlsx").then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(dataToExcel);
            var wscols = [
                { wch: 5 },
                { wch: 50 },
                { wch: 50 },
                { wch: 50 },
                { wch: 50 },
                { wch: 50 },
            ];
            worksheet["!cols"] = wscols;
            const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: "xlsx",
                type: "array",
            });
            saveAsExcelFile(excelBuffer, `Today Payment ${todayDate}`);
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import("file-saver").then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE =
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
                let EXCEL_EXTENSION = ".xlsx";
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE,
                });

                module.default.saveAs(
                    data,
                    fileName + "_" + "export" + EXCEL_EXTENSION
                );
            }
        });
    };

    //end


    //This is to export pdf 

    const dataWithIndex = todayList.map((d, i) => ({
        ...d,
        Index: i + 1,
        Amount: parseFloat(d.Amount).toFixed(2),
    }));


    const cols = [
        { header: "S.N", field: "Index" },
        { header: "Date", field: "CreatedDate" },
        { header: "Name", field: "FullName" },
        { header: "Gateway", field: "Gateway" },
        { header: "Status", field: "Status" },
        { header: "Amount", field: "Amount" },
    ];

    const exportColumns = cols.map((col) => ({
        title: col.header,
        dataKey: col.field,
    }));

    const toPdf = () => {
        const doc = new jsPDF("p", "pt", "a4", true);

        var midPage = doc.internal.pageSize.getWidth() / 2;

        // doc.addImage(`${userinfo.SchPhoto}`, "JPG", 40, 40, 70, 60);

        doc.setFontSize(18);
        doc.text(`${userinfo.SchName}`, midPage, 50, null, null, "center");

        doc.setFontSize(15);
        doc.text(
            `${userinfo.SchAddress}`,
            midPage,
            68,
            null,
            null,
            "center"
        );

        doc.setFontSize(10);
        doc.text(`Tel:${userinfo.Phone}, E-mail:${userinfo.Email}`, midPage, 85, null, null, "center");

        doc.autoTable({
            startY: 100,
            theme: "grid",
            columns: exportColumns,
            body: dataWithIndex,
        });
        doc.save(`Today Payment ${todayDate}`);
    };
    //end


    //Total debit and credit transactions


    if (todayList.length > 0) {
        var creditT = amount.toFixed(2);
    }

    //Total debit and credit transactions ends


    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
            />
            <div className="sec-dataTable">

                <div className="upper-dataTbl">
                    <Tippy content="PDF">
                        <button className="exportButtons ml-1"
                            onClick={toPdf}
                        >
                            <i className="bi bi-file-earmark-pdf-fill exportButtonIconsPdf"></i>
                        </button>
                    </Tippy>
                    <Tippy content="Excel">
                        <button className="exportButtons" onClick={toExcel}>
                            <i className="bi bi-file-earmark-excel-fill exportButtonIconsExcel"></i>
                        </button>
                    </Tippy>



                </div>

                <hr className="new-hr-new" />
                {todayList.length > 0 ? <div className="totalTrans"> {creditT} <b> &nbsp;Total Credit :&nbsp;</b>
                </div> : <></>}


                <div className="row" style={{ margin: "0" }}>

                    <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                        <label className="list-label">Today Date</label>
                        <Calendar
                            className="form-control form-control-sm mb-1"
                            dateFormat="YYYY/MM/DD"
                            theme="default"
                            language="en"
                            values={todayDateValue.specificDate}
                            onChange={handleToDate}
                        />
                    </div>


                    <div className="col-lg-2 offset-lg-8 offset-md-8 col-md-2 col-sm-2 dropdown2" style={{ marginTop: "28px", paddingRight: "1px", marginBottom: "10px" }}>
                        <div className="d-flex">
                            <input
                                placeholder="Search"
                                ref={searchInput}
                                type="text"
                                className="form-control form-control-sm searchField"
                                onChange={searchHandler}
                            />
                        </div>
                    </div>
                </div>


                {loading ? (
                    <Spinner />
                ) : (
                    <>
                        {todayList.length > 0 ? (
                            <div className="table-responsive br-3">

                                <table className="table table-striped table-bordereds text-nowrap mb-0">
                                    <thead>
                                        <tr className="tableHead">
                                            <td className="tc" style={{ width: "70px" }}>S.N.</td>
                                            <td className="tl" style={{ width: "145px" }}>Date</td>
                                            <td className="tl" >Name</td>
                                            <td className="tl" style={{ width: "150px" }}>Gateway</td>
                                            <td className="tl" style={{ width: "150px" }}>Status</td>
                                            <td className="tr" style={{ width: "150px" }}>Amount</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {todayList.map((item, i) => (
                                            <tr key={i}>
                                                <td className="tc" style={{ width: "70px" }}>{i + 1}</td>
                                                <td className="tl">{item.CreatedDate}</td>
                                                <td className="tl">{item.FullName}</td>
                                                <td className="tl">{item.Gateway}</td>
                                                <td className="tl">{item.Status}</td>
                                                <td className="tr">{parseFloat(item.Amount).toFixed(2)}</td>
                                            </tr>
                                        ))}
                                        <tr className="lastt">
                                            <td colspan="5" ><b style={{ paddingLeft: "15px" }}>Total</b></td>
                                            <td className="tr "><b>{amount.toFixed(2)}</b></td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        ) : (
                            <div className="table-responsive br-3">

                                <div className="text-center mt-4">
                                    <p>There are no records to display.</p>
                                </div>
                            </div>
                        )}


                    </>
                )}


            </div>
        </>
    );
}
