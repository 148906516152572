import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";
import { GetNepaliDate } from "../hooks/dateConvertor";
import StudentMenuContext from "../contextfolder/StudentMenuContext/StudentMenuContext";
import { showImgPreview } from "../hooks/ImagePreview";
import AcademicHeader from "../AcademicHeader";

export default function StudentMenu() {
  const { todayDate, customStylesForImage, AcademicYear } =
    useContext(SidebarContext);

  const {
    menuList,
    setMenuList,
    categoryList,
    originalList,
    setSelectCategory,
    loading,
    selectCategory,
    selectMenu,
    setSelectMenu,
  } = useContext(StudentMenuContext);

  const [imgPrv, setImgPrv] = useState(false);
  const [imagePre, setImagePre] = useState("");

  const searchInput = useRef("");

  const columns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "Image",
      width: "79px",
      selector: (row) => {
        return (
          <>
            <div className="staffContentLogo">
              <div className="staffImg">
                <img
                  src={row.Image}
                  alt=""
                  onClick={() => {
                    setImagePre(row.Image);
                    setImgPrv(true);
                  }}
                />
              </div>
            </div>
          </>
        );
      },
    },
    {
      name: "Name",
      selector: (row) => row.Name,
    },
    {
      name: "Category",

      selector: (row) => row.Category,
    },
    {
      name: "Price",
      width: "130px",
      selector: (row) => row.Price,
    },
    {
      name: "Unit",
      width: "130px",
      selector: (row) => row.PriceUnit,
    },
  ];

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["Name"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setMenuList(srchResult);
      } else {
        setMenuList({});
      }
    } else {
      setMenuList(originalList);
    }
  };

  const handleCategory = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectCategory(value);
  };

  const handleMenu = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectMenu(value);
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
        <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">Menu</div>
            <div className="page-date">
              <div className="sec-content">
                <AcademicHeader
                  todayDate={todayDate}
                  AcademicYear={AcademicYear}
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <>
          <div className="sec-dataTable content-section main-content">
            {loading ? (
              <Spinner />
            ) : (
              <DataTable
                columns={columns}
                data={menuList}
                customStyles={customStylesForImage}
                pagination
                fixedHeader
                fixedHeaderScrollHeight="350px"
                highlightOnHover
                pointerOnHover
                responsive
                dense
                striped
                subHeader
                subHeaderAlign="left"
                subHeaderComponent={
                  <>
                    <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                      <label className="list-label">Category</label>
                      <select
                        style={{ fontSize: "11px" }}
                        name="snotifiaction"
                        value={selectCategory}
                        onChange={handleCategory}
                        className="form-control form-control-sm"
                        aria-label="Default select example"
                      >
                        <option
                          value=""
                          disabled
                          selected
                          style={{ fontSize: "11px" }}
                        >
                          Select Menu Category
                        </option>
                        <option value="-1">All</option>
                        {categoryList.map((item) => (
                          <option key={item.CategoryID} value={item.CategoryID}>
                            {item.Category}
                          </option>
                        ))}
                      </select>
                      <i class="fas fa-angle-down  notificationpositon"></i>
                    </div>

                    <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                      <label className="list-label">Type</label>
                      <select
                        style={{ fontSize: "11px" }}
                        name="snotifiaction"
                        value={selectMenu}
                        onChange={handleMenu}
                        className="form-control form-control-sm"
                        aria-label="Default control example "
                      >
                        <option
                          value=""
                          disabled
                          selected
                          style={{ fontSize: "11px" }}
                        >
                          Select Menu Type
                        </option>
                        <option value="-1">All</option>
                        <option value="F">Food</option>
                        <option value="B">Beverages</option>
                      </select>
                      <i class="fas fa-angle-down  notificationpositon"></i>
                    </div>

                    <div
                      className="col-lg-2 offset-lg-6 col-md-2 offset-md-6 col-sm-2 align-self-end Search"
                      style={{
                        marginTop: "25px",
                        padding: "inherit",
                        marginBottom: "10px",
                      }}
                    >
                      <div className="d-flex">
                        <input
                          placeholder="Search"
                          ref={searchInput}
                          type="text"
                          className="form-control form-control-sm searchField"
                          onChange={searchHandler}
                        />
                      </div>
                    </div>
                  </>
                }
              />
            )}
          </div>
        </>
      </div>

      {imgPrv &&
        showImgPreview({
          img: imagePre,
          setTrigger: setImgPrv,
        })}
    </>
  );
}
