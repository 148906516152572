import React, { useContext, useEffect } from 'react'
import NotificationContext from '../contextfolder/NotificationContext/NotificationContext';


export default function NotificationEdit({ closeEditPop }) {

    const context = useContext(NotificationContext)

    const { toggleEditPopup, notificationFormError, setNotificationFormError, editIsSubmit, setEditIsSubmit, notificationFormValue, setNotificationFormValue, setCloseChecked, closeChecked, editdata } = context;

    const handleOnChange = () => {
        setCloseChecked(!closeChecked);
    };

    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;
        setNotificationFormValue({ ...notificationFormValue, [name]: value });
    };



    const formSubmit = (e) => {
        e.preventDefault();
        setNotificationFormError(validate(notificationFormValue));
        setEditIsSubmit(true);

    }
    useEffect(() => {

        if (Object.keys(notificationFormError).length === 0 && editIsSubmit) {
            editdata(notificationFormValue)
            if (closeChecked === true) {
                toggleEditPopup();
            }
            setEditIsSubmit(false);
            setCloseChecked(false);
        }

    }, [notificationFormError]);


    const validate = (values) => {
        const errors = {};
        if (!values.title) {
            errors.title = "Required";
        }
        if (!values.description) {
            errors.description = "Required";
        }
        return errors;
    };



    return (
        <>

            <div className="container-fluid basicform" >

                <form >
                    <div className="form-group">
                        <label className="form-label" htmlFor="title" style={{ fontSize: "12px" }}>
                            Title<sup style={{ color: "red" }}>*</sup>
                        </label>
                        <input
                            id="title"
                            style={{ fontSize: "13px" }}
                            type="text"
                            className="form-control form-control-sm "
                            name="title"
                            value={notificationFormValue.title}
                            onChange={handleChange}
                        />
                        <p className="errormsg " style={{ marginBottom: "0" }}>{notificationFormError.title}</p>
                    </div>

                    <div className="form-group">
                        <label className="form-label" htmlFor="description" style={{ fontSize: "12px" }}>
                            Description<sup style={{ color: "red" }}>*</sup>
                        </label>
                        <textarea
                            id="description"
                            style={{ fontSize: "13px" }}
                            type="text"
                            className="form-control form-control-sm "
                            name="description"
                            value={notificationFormValue.description}
                            onChange={handleChange}
                        />
                        <p className="errormsg " style={{ marginBottom: "0" }}>{notificationFormError.description}</p>
                    </div>

                </form>
            </div>
            <div className="FormFooter">
                <div className='checkedClose'>
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck2"
                        name="closeChecked"
                        onChange={handleOnChange}
                        checked={closeChecked}
                    />
                    <label class="form-check-label" for="exampleCheck2">
                        Exit after submit
                    </label>
                </div>
                <div>
                    <button className='btn btn-sm btn-cmpy ml-2' style={{ background: "green" }} onClick={formSubmit} >Submit</button>
                    <button className='btn btn-sm btn-cmpy ml-2' onClick={closeEditPop}>Cancel</button>
                </div>
            </div>
        </>

    )
}
