import React, { useContext, useEffect, useState } from "react";
import { Fetchdata } from "../../hooks/getData";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { toast } from "react-toastify";
import { convertToNep } from "../../hooks/dateConvertor";
import StudentPaymentContext from "./StudentPaymentContext";

function StudentPaymentState(props) {
  const { appURL, newMonth, todayDate } = useContext(SidebarContext);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const [reportOption, setReportOption] = useState("");
  const [paymentGateway, setPaymentGateway] = useState("-1");
  const [paymentStatus, setPaymentStatus] = useState("-1");

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);

  const [todaySearch, setTodaySearch] = useState(null);

  const reportValue = {
    fromDate: newMonth,
    toDate: "",
    specificDate: "",
  };

  const [reportDateValue, setReportDateValue] = useState(reportValue);

  const todayValue = {
    specificDate: todayDate,
  };

  const [todayDateValue, setTodayDateValue] = useState(todayValue);

  // API for payment report list
  const [paymentReportList, setPaymentReportList] = useState([]);

  useEffect(() => {
    getPaymentList();
  }, [reportDateValue, paymentStatus, paymentGateway]);

  const getPaymentList = () => {
    const params = {
      SchID: userinfo.SchID,
      Flag: "R",
      DtFlag: "S",
      Values: reportDateValue.specificDate,
      PayStatus: paymentStatus,
      Gateway: paymentGateway,
      MemID: userinfo.UserID.toString(),
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/payment-report`,
    };

    const formParams = {
      SchID: userinfo.SchID,
      Flag: "R",
      DtFlag: "F",
      PayStatus: paymentStatus,
      Gateway: paymentGateway,
      MemID: userinfo.UserID.toString(),
      FromDate: reportDateValue.fromDate,
      ToDate: reportDateValue.toDate,
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/payment-report`,
    };

    Fetchdata(reportOption === "S" ? params : formParams).then(function (
      result
    ) {
      if (result.StatusCode === 200) {
        const postResult = result.AccLst ? result.AccLst : "";
        setLoading(false);
        setPaymentReportList(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        setPaymentReportList([]);
        setOriginalList([]);
      }
    });
  };

  // Api for today date value
  const [todayList, setTodayList] = useState([]);

  useEffect(() => {
    getTodayList();
  }, [todayDateValue]);

  const getTodayList = () => {
    const params = {
      SchID: userinfo.SchID,
      Flag: "R",
      DtFlag: "S",
      Values: todayDateValue.specificDate,
      PayStatus: "-1",
      Gateway: "-1",
      MemID: "-1",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/payment-report`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.AccLst ? result.AccLst : "";
        setLoading(false);
        setTodayList(postResult);
        setTodaySearch(postResult);
      } else {
        setTodayList([]);
        setTodaySearch([]);
      }
    });
  };

  return (
    <StudentPaymentContext.Provider
      value={{
        loading,
        originalList,
        paymentReportList,
        setPaymentReportList,
        reportDateValue,
        setReportDateValue,

        todayDateValue,
        setTodayDateValue,
        todaySearch,
        todayList,
        setTodayList,
        reportOption,
        setReportOption,
        paymentGateway,
        setPaymentGateway,
        paymentStatus,
        setPaymentStatus,
      }}
    >
      {props.children}
    </StudentPaymentContext.Provider>
  );
}

export default StudentPaymentState;
