import React, { useContext, useEffect, useState } from "react";
import { Fetchdata } from "../../hooks/getData";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { toast } from "react-toastify";
import AlbumContext from "./AlbumContext";

function AlbumState(props) {
  const { appURL } = useContext(SidebarContext);

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const [closeChecked, setCloseChecked] = useState(false);

  const albumValue = {
    selectdrop: "-1",
    class: "",
    section: "",
    student: "",
    albumName: "",
  };
  const [albumFormValue, setAlbumFormValue] = useState(albumValue);
  const [albumFormError, setAlbumFormError] = useState({});
  const [isASubmit, setIsASubmit] = useState(false);
  const [editIsSubmit, setEditIsSubmit] = useState(false);

  const [reportOption, setReportOption] = useState("-1");

  const [popupA, setPopupA] = useState(false);
  const toggleAPopup = () => {
    setPopupA(!popupA);
  };

  const [editPop, setEditPop] = useState(false);
  const toggleEditPopup = () => {
    // setEditPop(!editPop)
  };

  const [classList, setClassList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [studentList, setStudentList] = useState([]);

  const [selectClass, setSelectClass] = useState("");
  const [selectSection, setSelectSection] = useState("");
  const [selectStudent, setSelectStudent] = useState("");

  useEffect(() => {
    getClassList();
  }, []);

  const getClassList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      DFlag: "C",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setLoading(false);
        setClassList(postResult);
        toggleAPopup();
      } else {
        setClassList([]);
      }
    });
  };

  useEffect(() => {
    getSectionList();
  }, [selectClass]);

  const getSectionList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      ClassID: selectClass,
      DFlag: "S",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";

        setSectionList(postResult);
      } else {
        setSectionList([]);
      }
    });
  };

  //API to hit student list

  useEffect(() => {
    stdList();
  }, [selectClass, selectSection]);

  const stdList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      Flag: "S",
      ClassID: selectClass,
      SectionID: [
        {
          SectionID: selectSection,
        },
      ],
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/student`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.StudentLst ? result.StudentLst : "";
        setStudentList(postResult);
      } else {
        setStudentList([]);
      }
    });
  };

  //API to create album
  const fetchdata = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "i",
      ClassID:
        albumFormValue.selectdrop === "C" ||
        albumFormValue.selectdrop === "S" ||
        albumFormValue.selectdrop === "I"
          ? albumFormValue.class
          : "",
      SectionID:
        albumFormValue.selectdrop === "S" || albumFormValue.selectdrop === "I"
          ? albumFormValue.section
          : "",
      MemID: albumFormValue.selectdrop === "I" ? albumFormValue.student : "",
      AlbumName: albumFormValue.albumName,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/gallery/album`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getAlbumList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };

  // API for albumlist
  const [albumList, setAlbumList] = useState([]);

  useEffect(() => {
    getAlbumList();
  }, [albumFormValue]);

  const getAlbumList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "S",
      ClassID:
        albumFormValue.selectdrop === "C" ||
        albumFormValue.selectdrop === "S" ||
        albumFormValue.selectdrop === "I"
          ? albumFormValue.class
          : "",
      SectionID:
        albumFormValue.selectdrop === "S" || albumFormValue.selectdrop === "I"
          ? albumFormValue.section
          : "",
      MemID: albumFormValue.selectdrop === "I" ? albumFormValue.student : "",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/gallery/album`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.AlbumLst ? result.AlbumLst : "";
        setLoading(false);
        setAlbumList(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        setAlbumList([]);
        setOriginalList([]);
      }
    });
  };

  const [isEdit, setIsEdit] = useState(false);
  const [albumId, setAlbumId] = useState("");

  const handleEdit = (data) => {
    setAlbumId(data.AlbumID);
    setAlbumFormValue({
      albumName: data.AlbumName,
    });
    setEditIsSubmit(false);
    setIsEdit(true);
  };

  //API to edit event
  const editdata = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "U",
      AlbumID: albumId.toString(),
      AlbumName: albumFormValue.albumName,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/gallery/album`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getAlbumList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
        setIsEdit(false);
        setAlbumFormValue(albumValue);
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };

  //Everything to pop up and delete the event list
  const [deletePopup, setDeletePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const [delData, setDelData] = useState();

  const handleDelete = async (data) => {
    setDelData(data);

    setDeletePopup({
      show: true,
      data,
    });
  };

  // This will perform the deletion and hide the Confirmation Box

  const handleDeleteTrue = async () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "R",
      AlbumID: delData.toString(),
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/gallery/album`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          getAlbumList();
          toast(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
        } else {
          toast(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
    });
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleDeleteFalse = () => {
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // Ends Here

  return (
    <AlbumContext.Provider
      value={{
        loading,
        originalList,
        deletePopup,
        handleDeleteTrue,
        handleDeleteFalse,
        handleDelete,
        popupA,
        setPopupA,
        toggleAPopup,
        albumFormValue,
        setAlbumFormValue,
        albumFormError,
        setAlbumFormError,
        isASubmit,
        albumValue,
        setIsASubmit,
        setAlbumList,
        editIsSubmit,
        setEditIsSubmit,
        fetchdata,
        albumList,
        editPop,
        setEditPop,
        toggleEditPopup,
        handleEdit,
        editdata,
        classList,
        sectionList,
        studentList,
        selectClass,
        setSelectClass,
        selectSection,
        setSelectSection,
        selectStudent,
        setSelectStudent,
        closeChecked,
        setCloseChecked,
        reportOption,
        setReportOption,
        isEdit,
        setIsEdit,
      }}
    >
      {props.children}
    </AlbumContext.Provider>
  );
}

export default AlbumState;
