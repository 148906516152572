import React, { useContext, useEffect, useState } from "react";
import { Fetchdata } from "../../hooks/getData";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { toast } from "react-toastify";
import BlogContext from "./BlogContext";

function BlogState(props) {
  const { appURL } = useContext(SidebarContext);

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const [closeChecked, setCloseChecked] = useState(false);

  const blogValue = {
    category: "",
    title: "",
    excerpt: "",
  };
  const [blogFormValue, setBlogFormValue] = useState(blogValue);
  const [blogFormError, setBlogFormError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [editIsSubmit, setEditIsSubmit] = useState(false);
  const [categorySlug, setCategorySlug] = useState("");
  const [publish, setPublish] = useState(true);
  const [value, setValue] = useState("");

  const [popup, setPopup] = useState(false);
  const togglePopup = () => {
    setPopup(!popup);
  };

  const [editPop, setEditPop] = useState(false);
  const toggleEditPopup = () => {
    setEditPop(!editPop);
  };

  const [categoryPop, setCategoryPop] = useState(false);
  const toggleCategoryPopup = () => {
    setCategoryPop(!categoryPop);
  };

  const [isUploaded, setIsUploaded] = useState(false);
  const [typeFile, setTypeFile] = useState("");
  const [image, setImage] = useState("");

  // For Blog category
  const categoryValue = {
    category: "",
  };
  const [categoryFormValue, setCategoryFormValue] = useState(categoryValue);
  const [categoryFormError, setCategoryFormError] = useState({});
  const [catIsSubmit, setCatIsSubmit] = useState(false);
  const [catTopicSearch, setCatTopicSearch] = useState(false);
  const [sluga, setSluga] = useState("");
  const [primary, setPrimary] = useState(false);

  //API to create category
  const fetchdata = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "I",
      Category: categoryFormValue.category,
      IsPrimary: primary === true ? "1" : "0",
      Slug: sluga,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/blog-category`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getBlogCatList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };

  // API for blog category list
  const [blogCatList, setBlogCatList] = useState([]);

  useEffect(() => {
    getBlogCatList();
  }, []);

  const getBlogCatList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/blog-category`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.BlogCatLst ? result.BlogCatLst : "";
        setLoading(false);
        setBlogCatList(postResult);
        setCatTopicSearch(postResult);
      } else {
        setLoading(false);
        setBlogCatList([]);
        setCatTopicSearch([]);
      }
    });
  };

  // for blog category edit
  const [catId, setCatId] = useState("");

  const editdata = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "U",
      CategoryID: catId,
      Category: categoryFormValue.category,
      IsPrimary: primary === true ? "1" : "0",
      Slug: sluga,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/blog-category`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getBlogCatList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };

  //Everything to pop up and delete the blog category list
  const [deleteTopicPopup, setDeleteTopicPopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const [delTopicData, setDelTopicData] = useState();

  const handleTopicDelete = async (data) => {
    setDelTopicData(data);

    setDeleteTopicPopup({
      show: true,
      data,
    });
  };

  // This will perform the deletion and hide the Confirmation Box

  const handleDeleteTopicTrue = async () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "R",
      CategoryID: delTopicData.toString(),
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/blog-category`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          getBlogCatList();
          toast(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
          setDeleteTopicPopup({
            show: false,
            data: null,
          });
        } else {
          toast(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleDeleteTopicFalse = () => {
    setDeleteTopicPopup({
      show: false,
      data: null,
    });
  };

  // Ends Here

  ////////////////////////////API for BLog ///////////////////////////////////////////////

  //API to create blog
  const blogdata = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "i",
      CategoryID: blogFormValue.category,
      Title: blogFormValue.title,
      Slug: categorySlug,
      Excerpt: blogFormValue.excerpt,
      Meta: blogFormValue.excerpt,
      Post: value,
      FeatImg: image !== null ? image.split(",")[1] : "",
      IsPublished: publish === true ? "1" : "0",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/blog-posts`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getBlogList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };

  // API for blog  list
  const [blogList, setBlogList] = useState([]);

  useEffect(() => {
    getBlogList();
  }, []);

  const getBlogList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/blog-posts`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.BlogLst ? result.BlogLst : "";

        setLoading(false);
        setBlogList(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        setBlogList([]);
        setOriginalList([]);
      }
    });
  };

  // for blog Edit

  const [blogId, setBlogId] = useState("");

  const handleEdit = (data) => {
    toggleEditPopup();
    setBlogId(data.BlogID);
    blogInfo();
    setEditIsSubmit(false);
    setCloseChecked(false);
  };

  const parser = new DOMParser();

  // For Blog info
  useEffect(() => {
    blogInfo();
  }, [blogId]);

  const blogInfo = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      BlogID: blogId.toString(),
      Via: "1",
      Flag: "SI",
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    };
    fetch(`${appURL}api/admin/blog-posts`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          const data = resp.BlogLst[0] ? resp.BlogLst[0] : "";

          setBlogFormValue({
            category: data.CategoryID,
            title: data.Title,
            excerpt: data.Excerpt,
          });
          setImage(data.FeatImg);
          setValue(data.Post);
          setCategorySlug(data.Slug);
          setPublish(data.IsPublished === 1 ? true : false);
        }
      });
    });
  };

  //API to edit blog
  const blogEditData = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "U",
      CategoryID: blogFormValue.category,
      BlogID: blogId.toString(),
      Title: blogFormValue.title,
      Slug: categorySlug,
      Excerpt: blogFormValue.excerpt,
      Meta: blogFormValue.excerpt,
      Post: value,
      FeatImg: image !== null ? image.split(",")[1] : "",
      IsPublished: publish === true ? "1" : "0",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/blog-posts`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getBlogList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };

  //Everything to pop up and delete the event list
  const [deletePopup, setDeletePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const [delData, setDelData] = useState();

  const handleDelete = async (data) => {
    setDelData(data);

    setDeletePopup({
      show: true,
      data,
    });
  };

  // This will perform the deletion and hide the Confirmation Box

  const handleDeleteTrue = async () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "R",
      BlogID: delData.toString(),
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/blog-posts`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          getBlogList();
          toast(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
        } else {
          toast(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
    });
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleDeleteFalse = () => {
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // Ends Here

  return (
    <BlogContext.Provider
      value={{
        loading,
        originalList,
        deletePopup,
        handleDeleteTrue,
        handleDeleteFalse,
        handleDelete,
        popup,
        setPopup,
        togglePopup,
        blogFormValue,
        setBlogFormValue,
        blogFormError,
        setBlogFormError,
        isSubmit,
        blogValue,
        setIsSubmit,
        setBlogList,
        isUploaded,
        setIsUploaded,
        typeFile,
        setTypeFile,
        image,
        setImage,
        editIsSubmit,
        setEditIsSubmit,
        blogList,
        editPop,
        setEditPop,
        toggleEditPopup,
        handleEdit,
        categorySlug,
        setCategorySlug,
        publish,
        setPublish,
        value,
        setValue,
        categoryPop,
        setCategoryPop,
        toggleCategoryPopup,
        categoryValue,
        categoryFormValue,
        setCategoryFormValue,
        categoryFormError,
        setCategoryFormError,
        catIsSubmit,
        setCatIsSubmit,
        catTopicSearch,
        setCatTopicSearch,
        sluga,
        setSluga,
        primary,
        setPrimary,
        fetchdata,
        blogCatList,
        setBlogCatList,
        setCatId,
        editdata,
        deleteTopicPopup,
        handleTopicDelete,
        handleDeleteTopicTrue,
        handleDeleteTopicFalse,
        blogdata,
        blogEditData,
        closeChecked,
        setCloseChecked,
      }}
    >
      {props.children}
    </BlogContext.Provider>
  );
}

export default BlogState;
