import React, { useContext, useEffect, useState } from "react";
import { Fetchdata } from "../../hooks/getData";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { toast } from "react-toastify";
import CalendarEventContext from "./CalendarEventContext";

function CalendarEventState(props) {
  const { appURL } = useContext(SidebarContext);

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const eventEValue = {
    title: "",
    description: "",
    eventDate: "",
    holiday: "",
  };
  const [eventEFormValue, setEventEFormValue] = useState(eventEValue);
  const [eventFormError, setEventFormError] = useState({});
  const [isESubmit, setIsESubmit] = useState(false);
  const [editIsSubmit, setEditIsSubmit] = useState(false);

  const [closeEChecked, setCloseEChecked] = useState(false);

  const [popup, setPopup] = useState(false);
  const toggleEPopup = () => {
    setPopup(!popup);
  };

  const [editPop, setEditPop] = useState(false);
  const toggleEditPopup = () => {
    setEditPop(!editPop);
  };

  const [isUploaded, setIsUploaded] = useState(false);
  const [typeFile, setTypeFile] = useState("");
  const [image, setImage] = useState("");

  //API to create event
  const fetchdata = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "I",
      Title: eventEFormValue.title,
      Description: eventEFormValue.description,
      IsHoliday: eventEFormValue.holiday === "H" ? "Y" : "N",
      EngDate: eventEFormValue.eventDate,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/cal-event`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getEventList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };

  // API for eventlist
  const [eventList, setEventList] = useState([]);

  useEffect(() => {
    getEventList();
  }, []);

  const getEventList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/cal-event`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.CalendarEventLst
          ? result.CalendarEventLst
          : "";
        setLoading(false);
        setEventList(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        setEventList([]);
        setOriginalList([]);
      }
    });
  };

  const [eventId, setEventId] = useState("");

  const handleEdit = (data) => {
    setEditPop(!editPop);

    setEventId(data.EventID);
    setEventEFormValue({
      title: data.Title,
      description: data.Description,
      eventDate: data.EnglishDate,
      holiday: data.IsHoliday === "Y" ? "H" : "E",
    });
    setEditIsSubmit(false);
  };

  //API to edit event
  const editdata = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "U",
      EventID: eventId.toString(),
      Title: eventEFormValue.title,
      Description: eventEFormValue.description,
      IsHoliday: eventEFormValue.holiday === "H" ? "Y" : "N",
      EngDate: eventEFormValue.eventDate,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/cal-event`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getEventList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };

  //Everything to pop up and delete the event list
  const [deletePopup, setDeletePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const [delData, setDelData] = useState();

  const handleDelete = async (data) => {
    setDelData(data);

    setDeletePopup({
      show: true,
      data,
    });
  };

  // This will perform the deletion and hide the Confirmation Box

  const handleDeleteTrue = async () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "R",
      EventID: delData.toString(),
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/cal-event`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          getEventList();
          toast(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
        } else {
          toast(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
    });
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleDeleteFalse = () => {
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // Ends Here

  return (
    <CalendarEventContext.Provider
      value={{
        loading,
        originalList,
        deletePopup,
        handleDeleteTrue,
        handleDeleteFalse,
        handleDelete,
        popup,
        setPopup,
        toggleEPopup,
        eventEFormValue,
        setEventEFormValue,
        eventFormError,
        setEventFormError,
        isESubmit,
        eventEValue,
        setIsESubmit,
        setEventList,
        isUploaded,
        setIsUploaded,
        typeFile,
        setTypeFile,
        image,
        setImage,
        editIsSubmit,
        setEditIsSubmit,
        fetchdata,
        eventList,
        editPop,
        setEditPop,
        toggleEditPopup,
        handleEdit,
        editdata,
        closeEChecked,
        setCloseEChecked,
      }}
    >
      {props.children}
    </CalendarEventContext.Provider>
  );
}

export default CalendarEventState;
