import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import TeacherContext from "../contextfolder/TeacherContext/TeacherContext";
import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";
import { showImgPreview } from "../hooks/ImagePreview";
import ResetPassword from "./ResetPassword";
import DeletePop from "./DeletePop";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import AcademicHeader from "../AcademicHeader";

export default function TeacherList() {
  const { AcademicYear, todayDate, customStylesForImage, appURL, userinfo } =
    useContext(SidebarContext);
  const {
    // loading,
    // originalList,
    // teacherList,
    // setTeacherList,
    newLoading,
    newOriginalList,
    newTeacherList,
    setNewTeacherList,

    resetPopup,
    handleResetFalse,
    handleResetTrue,
    handleReset,
    deletePopup,
    handleDeleteTrue,
    handleDeleteFalse,
    handleDelete,
    isUploaded,
    setIsUploaded,
    typeFile,
    setTypeFile,
    image,
    setImage,
    changephoto,
  } = useContext(TeacherContext);

  const [imgPrv, setImgPrv] = useState(false);
  const [imagePre, setImagePre] = useState("");

  function handleChange(e, studentID) {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        changephoto(studentID, e.target.result);
      };

      reader.readAsDataURL(e.target.files[0]);
    }
  }

  const columns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "Image",
      center: true,
      width: "90px",
      selector: (row) => {
        return (
          <>
            <div className="staffContentLogo">
              <div className="staffImg">
                <img
                  src={row.Image}
                  alt=""
                  onClick={() => {
                    setImagePre(row.Image);
                    setImgPrv(true);
                  }}
                />
              </div>
            </div>
          </>
        );
      },
    },

    {
      name: "User Name",
      width: "100px",
      selector: (row) => row.TeacherUsername,
    },
    {
      name: "Full Name",
      selector: (row) => row.TeacherName,
    },
    {
      name: "Address",
      width: "150px",
      selector: (row) => row.Address,
    },
    {
      name: "Gender",
      width: "80px",
      selector: (row) => row.Gender,
    },

    {
      name: "Phone",
      width: "150px",
      selector: (row) => row.Phone,
    },
    {
      name: "Action",
      center: true,
      width: "150px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex" style={{ marginTop: "6px" }}>
              <input
                type="file"
                id={`file-input-${row.TeacherUsername}`}
                onChange={(e) => handleChange(e, row.TeacherUsername)}
              />
              <Tippy content="Change Photo">
                <label htmlFor={`file-input-${row.TeacherUsername}`}>
                  {" "}
                  <i className="fa fa-user-circle-o editspan--action"> </i>{" "}
                </label>
              </Tippy>

              <Tippy content="Reset Password">
                <i
                  className="fa fa-repeat solvespan--action"
                  onClick={() =>
                    handleReset([row.TeacherUsername, row.TeacherUsername])
                  }
                ></i>
              </Tippy>
              <Tippy content="Delete">
                <i
                  className="fa fa-trash-o rejectspan--action"
                  onClick={() => handleDelete(row.TeacherUsername)}
                ></i>
              </Tippy>
            </div>
          </>
        );
      },
    },
  ];

  const searchInput = useRef("");

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = newOriginalList.filter((list) => {
        return list["TeacherName"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setNewTeacherList(srchResult);
      } else {
        setNewTeacherList({});
      }
    } else {
      setNewTeacherList(newOriginalList);
    }
  };

  /* This is for export csv */

  const dataToExcel = newTeacherList.map((d, i) => ({
    "S.N.": i + 1,
    "User Name": d.TeacherUsername,
    "Full Name": d.TeacherName,
    Address: d.Address,
    Gender: d.Gender,
    Phone: d.Phone,
  }));

  const toExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(dataToExcel);
      var wscols = [
        { wch: 5 },
        { wch: 20 },
        { wch: 20 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ];
      worksheet["!cols"] = wscols;
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "Teacher");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_" + "export" + EXCEL_EXTENSION
        );
      }
    });
  };

  //end

  //This is to export pdf

  const dataWithIndex = newTeacherList.map((d, i) => ({
    ...d,
    Index: i + 1,
  }));

  const cols = [
    { header: "S.N", field: "Index" },
    { header: "User Name", field: "TeacherUsername" },
    { header: "Full Name", field: "TeacherName" },
    { header: "Address", field: "Address" },
    { header: "Gender", field: "Gender" },
    { header: "Phone", field: "Phone" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const toPdf = () => {
    const doc = new jsPDF("p", "pt", "a4", true);

    var midPage = doc.internal.pageSize.getWidth() / 2;

    // doc.addImage(`${userinfo.SchPhoto}`, "JPG", 40, 40, 70, 60);

    doc.setFontSize(18);
    doc.text(`${userinfo.SchName}`, midPage, 50, null, null, "center");

    doc.setFontSize(15);
    doc.text(`${userinfo.SchAddress}`, midPage, 68, null, null, "center");

    doc.setFontSize(10);
    doc.text(
      `Tel:${userinfo.Phone}, E-mail:${userinfo.Email}`,
      midPage,
      85,
      null,
      null,
      "center"
    );

    doc.autoTable({
      startY: 100,
      theme: "grid",
      columns: exportColumns,
      body: dataWithIndex,
    });
    doc.save("Teacher.pdf");
  };
  //end

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
        <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">Teacher List</div>
            <div className="page-date">
              <div className="sec-content">
                <AcademicHeader
                  todayDate={todayDate}
                  AcademicYear={AcademicYear}
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <>
          <div className="sec-dataTable content-section main-content">
            <div className="upper-dataTbl">
              <Tippy content="PDF">
                <button className="exportButtons ml-1" onClick={toPdf}>
                  <i className="bi bi-file-earmark-pdf-fill exportButtonIconsPdf"></i>
                </button>
              </Tippy>

              <Tippy content="Excel">
                <button className="exportButtons" onClick={toExcel}>
                  <i className="bi bi-file-earmark-excel-fill exportButtonIconsExcel"></i>
                </button>
              </Tippy>
            </div>
            <hr className="new-hr" />
            {newLoading ? (
              <Spinner />
            ) : (
              <>
                <DataTable
                  columns={columns}
                  data={newTeacherList}
                  customStyles={customStylesForImage}
                  pagination
                  fixedHeader
                  fixedHeaderScrollHeight="280px"
                  highlightOnHover
                  pointerOnHover
                  responsive
                  dense
                  striped
                  subHeader
                  subHeaderAlign="left"
                  subHeaderComponent={
                    <>
                      <div
                        className="col-lg-2 offset-lg-10 offset-md-10 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
                        style={{ paddingRight: "1px", marginBottom: "10px" }}
                      >
                        <div className="d-flex">
                          <input
                            placeholder="Search"
                            ref={searchInput}
                            type="text"
                            className="form-control form-control-sm searchField"
                            onChange={searchHandler}
                          />
                        </div>
                      </div>
                    </>
                  }
                />
              </>
            )}
          </div>
        </>
      </div>

      {imgPrv &&
        showImgPreview({
          img: imagePre,
          setTrigger: setImgPrv,
        })}

      {resetPopup.show && (
        <ResetPassword
          handleResetTrue={handleResetTrue}
          handleResetFalse={handleResetFalse}
        />
      )}
      {deletePopup.show && (
        <DeletePop
          handleDeleteTrue={handleDeleteTrue}
          handleDeleteFalse={handleDeleteFalse}
        />
      )}
    </>
  );
}
