import React, { useContext, useEffect, useState } from "react";
import { Fetchdata } from "../../hooks/getData";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { toast } from "react-toastify";
import AttendanceContext from "./AttendanceContext";

function AttendanceState(props) {
  const { appURL, todayDate } = useContext(SidebarContext);

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const [closeChecked, setCloseChecked] = useState(false);
  const [inputFields, setInputFields] = useState([]);

  const attendanceValue = {
    class: "",
    section: "",
    teacher: "",
    attendanceDate: "",
    subject: "",
  };
  const [section, setSection] = useState([]);
  const [attendanceFormValue, setAttendanceFormValue] =
    useState(attendanceValue);
  const [attendanceFormError, setAttendanceFormError] = useState({});

  const [isSubmit, setIsSubmit] = useState(false);
  // const [editIsSubmit, setEditIsSubmit] = useState(false);
  const [subjectWise, setSubjectWise] = useState(false);

  const [popup, setPopup] = useState(false);
  const togglePopup = () => {
    setPopup(!popup);
  };

  // const [editPop, setEditPop] = useState(false);
  // const toggleEditPopup = () => {
  //     setEditPop(!editPop)
  // }
  const [reportOption, setReportOption] = useState("");

  const [classList, setClassList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);

  const [selectClass, setSelectClass] = useState("");
  const [selectSection, setSelectSection] = useState("");
  const [selectTeacher, setSelectTeacher] = useState("");
  const [selectSubject, setSelectSubject] = useState("");
  const [todaysDate, setTodaysDate] = useState("");

  useEffect(() => {
    getClassList();
  }, []);

  const getClassList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      DFlag: "C",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setClassList(postResult);
      } else {
        setClassList([]);
      }
    });
  };

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptionsOne, setSelectedOptionsOne] = useState([]);

  useEffect(() => {
    getSectionList();
  }, [selectClass]);

  const getSectionList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      ClassID: selectClass,
      DFlag: "S",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setSectionList(postResult);
      } else {
        setSectionList([]);
      }
    });
  };

  //Api to hit subject list

  useEffect(() => {
    getSubjectList();
  }, [selectClass.at, selectTeacher, selectedOptionsOne]);

  const getSubjectList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : selectTeacher,
      ClassID: selectClass,
      SectionID: selectedOptionsOne,
      DFlag: "SB",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";

        setSubjectList(postResult);
      } else {
        setSubjectList([]);
      }
    });
  };

  //API to hit Teacher list
  const [teacherList, setTeacherList] = useState([]);

  useEffect(() => {
    teachList();
  }, [selectClass, selectedOptionsOne]);

  const teachList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      DFlag: "TL",
      Flag: "S",
      ClassID: selectClass,
      SectionID: selectedOptionsOne,
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setTeacherList(postResult);
      } else {
        setTeacherList([]);
      }
    });
  };

  //API to create Attendance
  const fetchdata = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID:
        userinfo.UserType === "T"
          ? userinfo.UserID
          : attendanceFormValue.teacher,
      Flag: "i",
      DFlag: "E",
      ClassID: attendanceFormValue.class,
      SectionID:
        userinfo.UserType === "T"
          ? section
          : [
              {
                SectionID: attendanceFormValue.section,
              },
            ],
      IsSubjective: attendanceFormValue.subject === "0" ? "N" : "Y",
      SubjectID:
        attendanceFormValue.subject === "" ? "0" : attendanceFormValue.subject,
      AttenDate: attendanceFormValue.attendanceDate,
      AttenParam: inputFields,
      AttenVia: "M",
      IsNotify: "Y",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/attendance`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getAttendanceList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };

  // API for attendanceList
  const [attendanceList, setAttendanceList] = useState([]);

  useEffect(() => {
    getAttendanceList();
  }, [
    todaysDate,
    selectTeacher,
    selectSubject,
    selectClass,
    selectedOptionsOne,
  ]);

  const getAttendanceList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : selectTeacher,
      Flag: "S",
      ClassID: selectClass,
      // SectionID: userinfo.UserType === "T" ? selectedOptions : [{
      //     "SectionID": selectedOptionsOne
      // }],
      SectionID: [
        {
          SectionID: selectedOptionsOne,
        },
      ],
      IsSubjective: reportOption === "S" ? "Y" : "N",
      SubjectID: reportOption === "S" ? selectSubject : "1",
      DFlag: "E",
      AttenDate: todaysDate.todayDate,
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/attendance`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.AttenLst ? result.AttenLst : "";

        setLoading(false);
        setAttendanceList(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        setAttendanceList([]);
        setOriginalList([]);
      }
    });
  };

  // //Everything to pop up and delete the event list
  const [deletePopup, setDeletePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const handleDelete = async () => {
    setDeletePopup({
      show: true,
    });
  };

  // This will perform the deletion and hide the Confirmation Box

  const handleDeleteTrue = async () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "R",
      DFlag: "E",
      ClassID: selectClass,
      SectionID: [
        {
          SectionID: selectedOptionsOne,
        },
      ],
      IsSubjective: reportOption === "S" ? "Y" : "N",
      SubjectID: reportOption === "S" ? selectSubject : "1",
      AttenDate: todaysDate.todayDate,
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/attendance`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          getAttendanceList();
          toast(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
        } else {
          toast(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
    });
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleDeleteFalse = () => {
    setDeletePopup({
      show: false,
      data: null,
    });
  };
  // Ends Here

  // for absent
  const absentValue = {
    remark: "",
  };
  const [absentFormValue, setAbsentFormValue] = useState(absentValue);
  const [absentFormError, setAbsentFormError] = useState({});
  const [remarkSearch, setRemarkSearch] = useState(null);
  // API for absentList
  const [absentList, setAbsentList] = useState([]);

  useEffect(() => {
    getAbsentList();
  }, [todaysDate, selectSubject, selectClass, selectedOptionsOne]);

  const getAbsentList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "AB",
      ClassID: selectClass,
      SectionID: [
        {
          SectionID: selectedOptionsOne,
        },
      ],
      IsSubjective: reportOption === "S" ? "Y" : "N",
      SubjectID: reportOption === "S" ? selectSubject : "1",
      DFlag: "E",
      AttenDate: todaysDate.todayDate,
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/attendance`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.AttenLst ? result.AttenLst : "";

        setLoading(false);
        setAbsentList(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        setAbsentList([]);
        setOriginalList([]);
      }
    });
  };

  // API for Remark list
  const [remarkList, setRemarkList] = useState([]);
  useEffect(() => {
    getRemarkList();
  }, []);

  const getRemarkList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      ClassID: "-1",
      SectionID: "-1",
      FollowedDate: "-1",
      Type: "POST",
      Flag: "S",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/attend-absent-follow`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.AttenLst ? result.AttenLst : "";
        setLoading(false);
        setRemarkList(postResult);
        setRemarkSearch(postResult);
      } else {
        setLoading(false);
        setRemarkList([]);
        setRemarkSearch([]);
      }
    });
  };

  //Everything to pop up and delete the topic suggestion topic list
  const [deleteRemarkPopup, setDeleteRemarkPopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const [delRemarkData, setDelRemarkData] = useState();

  const handleRemarkDelete = async (data) => {
    setDelRemarkData(data);

    setDeleteRemarkPopup({
      show: true,
      data,
    });
  };

  // This will perform the deletion and hide the Confirmation Box
  const [attendId, setAttendId] = useState("");
  const handleDeleteRemarkTrue = async () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "R",
      AttendID: attendId.toString(),
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/attend-absent-follow`, requestOptions).then(
      (result) => {
        result.json().then((resp) => {
          if (resp.StatusCode === 200) {
            getRemarkList();
            getAbsentList();
            toast(resp.Message, {
              style: {
                color: "green",
                fontSize: "13px",
              },
            });
            setDeleteRemarkPopup({
              show: false,
              data: null,
            });
          } else {
            toast(resp.Message, {
              style: {
                color: "red",
                fontSize: "13px",
              },
            });
          }
        });
      }
    );
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleDeleteRemarkFalse = () => {
    setDeleteRemarkPopup({
      show: false,
      data: null,
    });
  };

  // Ends Here
  return (
    <AttendanceContext.Provider
      value={{
        loading,
        setLoading,
        setOriginalList,
        originalList,
        deletePopup,
        handleDeleteTrue,
        handleDeleteFalse,
        handleDelete,
        popup,
        setPopup,
        togglePopup,
        attendanceFormValue,
        setAttendanceFormValue,
        attendanceFormError,
        setAttendanceFormError,
        isSubmit,
        attendanceValue,
        setIsSubmit,
        setAttendanceList,
        fetchdata,
        absentList,
        setAbsentList,
        attendanceList,
        classList,
        sectionList,
        teacherList,
        selectClass,
        setSelectClass,
        selectSection,
        setSelectSection,
        selectTeacher,
        setSelectTeacher,
        todaysDate,
        setTodaysDate,
        closeChecked,
        setCloseChecked,
        selectedOptions,
        setSelectedOptions,
        setSelectSubject,
        selectSubject,
        subjectList,
        reportOption,
        setReportOption,
        section,
        setSection,
        inputFields,
        setInputFields,
        setSelectedOptionsOne,
        selectedOptionsOne,
        subjectWise,
        setSubjectWise,
        absentFormValue,
        setAbsentFormValue,
        absentValue,
        absentFormError,
        setAbsentFormError,
        remarkSearch,
        setRemarkSearch,
        remarkList,
        setRemarkList,
        getRemarkList,
        deleteRemarkPopup,
        setDeleteRemarkPopup,
        handleRemarkDelete,
        delRemarkData,
        setDelRemarkData,
        handleDeleteRemarkTrue,
        handleDeleteRemarkFalse,
        attendId,
        setAttendId,
        getAbsentList,
      }}
    >
      {props.children}
    </AttendanceContext.Provider>
  );
}

export default AttendanceState;
