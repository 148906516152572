import React, { useContext, useState, useEffect } from "react";
import SidebarContext from "../sidebarcontext/SidebarContext";
import { Fetchdata } from "../hooks/getData";
import Select from "react-select";
import NotificationContext from "../contextfolder/NotificationContext/NotificationContext";
import CloseIcon from "../../images/CloseIcon.svg";
import Plus from "../../images/Plus.png";

export default function NotificationForm({ closePopup }) {
  const context = useContext(NotificationContext);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const { appURL } = useContext(SidebarContext);

  const {
    togglePopup,
    notificationFormError,
    setNotificationFormError,
    isSubmit,
    setIsSubmit,
    notificationValue,
    notificationFormValue,
    setNotificationFormValue,
    loading,
    setCloseChecked,
    closeChecked,
    section,
    setSection,
    setStudent,
    setAdmin,
    setTeacher,
    isUploaded,
    setIsUploaded,
    setTypeFile,
    image,
    setImage,
    fetchdata,
    setCloseTeacherChecked,
    closeTeacherChecked,
    closeAdminChecked,
    setCloseAdminChecked,
  } = context;
  const handleOnChange = () => {
    setCloseChecked(!closeChecked);
  };

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setNotificationFormValue({ ...notificationFormValue, [name]: value });
    setCloseAdminChecked(false);
    setCloseTeacherChecked(false);
  };
  const handleSelectTeacher = (e) => {
    let name = "teacher";
    setNotificationFormValue({
      ...notificationFormValue,
      [name]: e.value,
    });
  };
  function handleImageChange(e) {
    if (e.target.files && e.target.files[0]) {
      setTypeFile(e.target.files[0].name);
      let reader = new FileReader();

      reader.onload = function (e) {
        setImage(e.target.result);
        setIsUploaded(true);
      };

      reader.readAsDataURL(e.target.files[0]);
    }
  }

  const formSubmit = (e) => {
    e.preventDefault();
    setNotificationFormError(validate(notificationFormValue));
    setIsSubmit(true);
  };
  useEffect(() => {
    if (Object.keys(notificationFormError).length === 0 && isSubmit) {
      fetchdata(notificationFormValue);
      if (closeChecked === true) {
        togglePopup();
      }
      setNotificationFormValue(notificationValue);
      setSection([]);
      setIsSubmit(false);
      setImage("");
      setIsUploaded(false);
      setCloseChecked(false);
    }
  }, [notificationFormError]);

  const validate = (values) => {
    const errors = {};
    if (!values.title) {
      errors.title = "Required";
    }
    if (!values.description) {
      errors.description = "Required";
    }
    if (!values.selectdrop) {
      errors.selectdrop = "Required";
    }

    if (
      notificationFormValue.selectdrop === "C" ||
      notificationFormValue.selectdrop === "S" ||
      notificationFormValue.selectdrop === "I"
    ) {
      if (!values.class) {
        errors.class = "Required";
      }
    }

    // if (notificationFormValue.selectdrop === "T") {
    //     if (!values.teacher) {
    //         errors.teacher = "Required";
    //     }
    // }

    // if (notificationFormValue.selectdrop === "A") {
    //     if (!values.admin) {
    //         errors.admin = "Required";
    //     }
    // }

    // if (notificationFormValue.selectdrop === "S" || notificationFormValue.selectdrop === "I") {
    //     if (!values.section) {
    //         errors.section = "Required";
    //     }
    // }

    // if (notificationFormValue.selectdrop === "I") {
    //     if (!values.student) {
    //         errors.student = "Required";
    //     }
    // }

    return errors;
  };

  const [classList, setClassList] = useState([]);
  const [sectionList, setSectionList] = useState([]);

  //API to hit class list
  useEffect(() => {
    getClassList();
  }, []);

  const getClassList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      DFlag: "C",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setClassList(postResult);
      } else {
        setClassList([]);
      }
    });
  };

  //API to hit section list
  useEffect(() => {
    getSectionList();
  }, [notificationFormValue.class]);

  const getSectionList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      ClassID: notificationFormValue.class,
      DFlag: "S",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";

        setSectionList(postResult);
      } else {
        setSectionList([]);
      }
    });
  };

  //API to hit Teacher list
  const [teacherList, setTeacherList] = useState([]);

  useEffect(() => {
    teachList();
  }, []);

  const teachList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/teacher`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.teachingres ? result.teachingres : "";
        setTeacherList(postResult);
      } else {
        setTeacherList([]);
      }
    });
  };

  //API to hit Student list
  const [studentList, setStudentList] = useState([]);

  useEffect(() => {
    stdList();
  }, [notificationFormValue.class, section]);

  const stdList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      Flag: "S",
      ClassID: notificationFormValue.class,
      SectionID: section,
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/student`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.StudentLst ? result.StudentLst : "";
        setStudentList(postResult);
      } else {
        setStudentList([]);
      }
    });
  };

  const dropDownValue = sectionList.map((item) => ({
    value: item.SectionID,
    label: item.SectionName,
  }));

  const dropDownStudent = studentList.map((item) => ({
    value: item.StudentID,
    label: item.StdFullName,
  }));

  const dropDownTeacher = teacherList.map((item) => ({
    value: item.TeacherID,
    label: item.TeacherName,
  }));

  return (
    <>
      <div className="container-fluid basicform">
        <form>
          <div className="form-group">
            <label className="form-label" htmlFor="selectdrop">
              Select Option<sup style={{ color: "red" }}>*</sup>
            </label>
            <select
              id="selectdrop"
              name="selectdrop"
              onChange={handleChange}
              value={notificationFormValue.selectdrop}
              className="form-select form-select-sm mb-1"
              style={{ fontSize: "12px" }}
            >
              <option value="" selected disabled>
                Select Option
              </option>
              {userinfo.UserType !== "T" && <option value="-1">All</option>}
              <option value="C">Class</option>
              <option value="S">Section</option>
              <option value="I">Individual</option>
              {userinfo.UserType !== "T" && (
                <>
                  <option value="T">Teacher</option>
                  <option value="A">Admin</option>
                </>
              )}
            </select>
            <p className="errormsg " style={{ marginBottom: "0" }}>
              {notificationFormError.selectdrop}
            </p>
          </div>

          <div className="form-row">
            {notificationFormValue.selectdrop === "C" ||
            notificationFormValue.selectdrop === "S" ||
            notificationFormValue.selectdrop === "I" ? (
              <div
                className={
                  notificationFormValue.selectdrop === "C"
                    ? "form-group col-md-12"
                    : "form-group col-md-6"
                }
              >
                <label className="form-label" htmlFor="class">
                  Class<sup style={{ color: "red" }}>*</sup>
                </label>
                <select
                  id="class"
                  name="class"
                  onChange={handleChange}
                  value={notificationFormValue.class}
                  className="form-select form-select-sm mb-1"
                  style={{ fontSize: "12px" }}
                >
                  <option value="" selected disabled>
                    Select Option
                  </option>
                  {classList.map((item, i) => (
                    <option key={i} value={item.ClassID}>
                      {item.ClassName}
                    </option>
                  ))}
                </select>
                <p className="errormsg " style={{ marginBottom: "0" }}>
                  {notificationFormError.class}
                </p>
              </div>
            ) : (
              <></>
            )}
            {notificationFormValue.selectdrop === "S" ||
            notificationFormValue.selectdrop === "I" ? (
              <div className="form-group col-md-6">
                <label className="form-label" htmlFor="section">
                  Section<sup style={{ color: "red" }}>*</sup>
                </label>
                <Select
                  className="select"
                  style={{ fontSize: "11px", marginBottom: "3px" }}
                  isMulti
                  options={dropDownValue}
                  onChange={(item) =>
                    setSection(
                      item.map((item) => ({
                        SectionID: item.value,
                      }))
                    )
                  }
                  isSearchable={false}
                />
                {/* <i className="fas fa-angle-down  notificationpositon"></i> */}
              </div>
            ) : (
              <></>
            )}
          </div>

          <div className="form-row">
            {notificationFormValue.selectdrop === "I" ? (
              <div className="form-group col-md-12">
                <label className="form-label" htmlFor="student">
                  Student<sup style={{ color: "red" }}>*</sup>
                </label>
                {/* <select id="student" name="student"
                                    onChange={handleChange}
                                    value={notificationFormValue.student} className="form-control form-control-sm mb-1" style={{ fontSize: '12px' }}>
                                    <option value="" selected disabled>Select Option</option>
                                    {studentList.map((item, i) => <option key={i} value={item.StudentID}>{item.StdFullName}</option>)}
                                </select>
                                <p className="errormsg " style={{ marginBottom: "0" }}>{notificationFormError.student}</p> */}
                <Select
                  className="select"
                  style={{ fontSize: "11px", marginBottom: "3px" }}
                  isMulti
                  options={dropDownStudent}
                  onChange={(item) =>
                    setStudent(
                      item.map((item) => ({
                        MemID: item.value,
                      }))
                    )
                  }
                  isSearchable={false}
                />
              </div>
            ) : (
              <></>
            )}

            {notificationFormValue.selectdrop === "T" && (
              <div className="form-group col-md-12">
                <div className="uk-flex uk-flex-between">
                  <label className="form-label" htmlFor="teacher">
                    Teacher<sup style={{ color: "red" }}>*</sup>
                  </label>

                  <div className="checkedClose">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck3"
                      name="closeChecked"
                      onChange={() =>
                        setCloseTeacherChecked(!closeTeacherChecked)
                      }
                      checked={closeTeacherChecked}
                    />
                    <label class="form-check-label" htmlFor="exampleCheck3">
                      Send Notification To All
                    </label>
                  </div>
                </div>
                {/* <select id="teacher" name="teacher"
                                    onChange={handleChange}
                                    value={notificationFormValue.teacher} className="form-control form-control-sm mb-1" style={{ fontSize: '12px' }}>
                                    <option value="" selected disabled>Select Option</option>
                                    {teacherList.map((item, i) => <option key={i} value={item.TeacherID}>{item.TeacherName}</option>)}
                                </select>
                                <p className="errormsg " style={{ marginBottom: "0" }}>{notificationFormError.teacher}</p> */}

                <Select
                  className="select"
                  style={{ fontSize: "11px", marginBottom: "3px" }}
                  // isMulti
                  options={!closeTeacherChecked && dropDownTeacher}
                  // onChange={(item) =>
                  //   setTeacher(
                  //     item.map((item) => ({
                  //       MemID: item.value,
                  //     }))
                  //   )
                  // }
                  onChange={(item) => handleSelectTeacher(item)}
                  isSearchable={!closeTeacherChecked ? true : false}
                />
              </div>
            )}
          </div>

          <div className="form-row">
            {notificationFormValue.selectdrop === "A" && (
              <div className="form-group col-md-12">
                <div className="uk-flex uk-flex-between">
                  <label className="form-label" htmlFor="admin">
                    Admin<sup style={{ color: "red" }}>*</sup>
                  </label>

                  <div className="checkedClose">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck2"
                      name="closeChecked"
                      onChange={() => setCloseAdminChecked(!closeAdminChecked)}
                      checked={closeAdminChecked}
                    />
                    <label class="form-check-label" for="exampleCheck2">
                      Send Notification To All
                    </label>
                  </div>
                </div>
                {/* <select id="admin" name="admin"
                                    onChange={handleChange}
                                    value={notificationFormValue.admin} className="form-control form-control-sm mb-1" style={{ fontSize: '12px' }}>
                                    <option value="" selected disabled>Select Option</option>
                                    {teacherList.map((item, i) => <option key={i} value={item.TeacherID}>{item.TeacherName}</option>)}
                                </select>
                                <p className="errormsg " style={{ marginBottom: "0" }}>{notificationFormError.admin}</p> */}
                <Select
                  className="select"
                  style={{ fontSize: "11px", marginBottom: "3px" }}
                  isMulti
                  options={!closeAdminChecked && dropDownTeacher}
                  onChange={(item) =>
                    setAdmin(
                      item.map((item) => ({
                        MemID: item.value,
                      }))
                    )
                  }
                  isSearchable={false}
                />
              </div>
            )}
          </div>

          <div className="form-group">
            <label
              className="form-label"
              htmlFor="title"
              style={{ fontSize: "12px" }}
            >
              Title<sup style={{ color: "red" }}>*</sup>
            </label>
            <input
              id="title"
              style={{ fontSize: "13px" }}
              type="text"
              className="form-control form-control-sm "
              name="title"
              value={notificationFormValue.title}
              onChange={handleChange}
            />
            <p className="errormsg " style={{ marginBottom: "0" }}>
              {notificationFormError.title}
            </p>
          </div>

          <div className="form-group">
            <label
              className="form-label"
              htmlFor="description"
              style={{ fontSize: "12px" }}
            >
              Description<sup style={{ color: "red" }}>*</sup>
            </label>
            <textarea
              id="description"
              style={{ fontSize: "13px" }}
              type="text"
              className="form-control form-control-sm "
              name="description"
              value={notificationFormValue.description}
              onChange={handleChange}
            />
            <p className="errormsg " style={{ marginBottom: "0" }}>
              {notificationFormError.description}
            </p>
          </div>
          <div className="form-group  ">
            <div
              className="form-label"
              htmlFor="text"
              style={{ fontSize: "12px", textAlign: "left" }}
            >
              Upload Image
            </div>

            <div className="BoxUpload">
              <div className="image-upload">
                {!isUploaded ? (
                  <>
                    <label htmlFor="upload-input">
                      <img
                        src={Plus}
                        draggable={"false"}
                        alt="placeholder"
                        style={{ width: 90, height: 100, paddingTop: "10px" }}
                      />
                    </label>

                    <input
                      id="upload-input"
                      type="file"
                      accept=".jpg,.jpeg,.gif,.png,.mov,.mp4,.pdf"
                      onChange={handleImageChange}
                      name="image"
                    />
                  </>
                ) : (
                  <div className="ImagePreview">
                    <img
                      className="close-icon"
                      src={CloseIcon}
                      alt="CloseIcon"
                      onClick={() => {
                        setIsUploaded(false);
                        setImage(null);
                      }}
                    />

                    <img
                      id="uploaded-image"
                      src={image}
                      draggable={false}
                      alt="uploaded-img"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="FormFooter">
        <div className="checkedClose">
          <input
            type="checkbox"
            className="form-check-input"
            id="exit"
            name="closeChecked"
            onChange={handleOnChange}
            checked={closeChecked}
          />
          <label class="form-check-label" htmlFor="exit">
            Exit after submit
          </label>
        </div>
        <div>
          <button
            className="btn btn-sm btn-cmpy ml-2"
            style={{ background: "green" }}
            onClick={formSubmit}
          >
            Submit
          </button>
          <button className="btn btn-sm btn-cmpy ml-2" onClick={closePopup}>
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}
