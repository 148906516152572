import React, { useContext, useState, useEffect } from 'react'
import { useRef } from 'react';
import DataTable from 'react-data-table-component';
import BlogContext from '../contextfolder/BlogContext/BlogContext';
import urlSlug from "url-slug";
import Spinner from '../loading/spinner';

import SidebarContext from '../sidebarcontext/SidebarContext';
import DeleteTopicPop from './DeleteTopicPop';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';


export default function BlogCategory({ closeCatPopup }) {

    const context = useContext(BlogContext)

    const { categoryFormError, setCategoryFormError, catIsSubmit, setCatIsSubmit, categoryValue, categoryFormValue, setCategoryFormValue, catTopicSearch, setCatTopicSearch, loading, deleteTopicPopup, handleDeleteTopicTrue, handleDeleteTopicFalse, handleTopicDelete, sluga, setSluga, primary, setPrimary, fetchdata, blogCatList, setBlogCatList, setCatId, editdata, closeChecked, setCloseChecked, toggleCategoryPopup } = context;

    const { customStyles } = useContext(SidebarContext)

    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        setSluga(urlSlug(categoryFormValue.category));
    }, [categoryFormValue]);

    const handlePrimary = () => {
        setPrimary(!primary)
    }

    const handleOnChange = () => {
        setCloseChecked(!closeChecked);
    };

    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;
        setCategoryFormValue({ ...categoryFormValue, [name]: value });
    };

    const formSubmit = (e) => {
        e.preventDefault();
        setCategoryFormError(validate(categoryFormValue));
        setCatIsSubmit(true);
    }
    useEffect(() => {
        if (Object.keys(categoryFormError).length === 0 && catIsSubmit) {
            fetchdata(categoryFormValue)
            if (closeChecked === true) {
                toggleCategoryPopup();
            }
            setCategoryFormValue(categoryValue)
            setCatIsSubmit(false);
            setPrimary(false)
        }

    }, [categoryFormError]);





    const validate = (values) => {
        const errors = {};
        if (!values.category) {
            errors.category = "Required";
        }
        return errors;
    };



    function selectUser(datas) {

        setCategoryFormValue({
            category: datas.Category,
        });
        setSluga(datas.Slug)
        setPrimary(datas.IsPrimary === 1 ? true : false)
        setCatId(datas.CategoryID);
        setIsEdit(true);
    }



    const columns = [
        {
            name: "S.N.",
            width: "59px",
            cell: (row, index) => index + 1,
        },

        {
            name: "Category",
            center: true,
            // grow: 0,
            selector: (row) => row.Category,
        },

        {
            name: "No. of Blog",
            center: true,
            // grow: 0,
            selector: (row) => row.NoOfBlog,
        },

        {
            name: "Is Primary",
            center: true,
            // grow: 0,
            selector: (row) => row.IsPrimary === 1 ? "Primary" : "Secondary",
        },
        {
            name: "Action",
            center: true,
            selector: (row) => {
                return (
                    <>
                        <>

                            <div className="ln-verition d-flex">
                                <Tippy content="Edit">
                                    <i
                                        className="fa fa-pencil solvespan--action"
                                        onClick={() => selectUser(row)}
                                    >
                                    </i>
                                </Tippy>
                                <Tippy content="Delete">
                                    <i
                                        className="fa fa-trash-o rejectspan--action"
                                        onClick={() => handleTopicDelete(row.CategoryID)}
                                    >
                                    </i>
                                </Tippy>
                            </div>
                        </>

                    </>
                );
            },
        },

    ];

    const searchInput = useRef("");
    const searchHandler = (e) => {
        e.preventDefault();

        const srchQuery = searchInput.current.value.toLowerCase();
        if (srchQuery) {

            let srchResult = catTopicSearch.filter((list) => {
                return list["Category"].toLowerCase().includes(srchQuery)
            });

            if (srchResult) {

                setBlogCatList(srchResult);
            } else {
                setBlogCatList({});
            }
        } else {
            setBlogCatList(catTopicSearch);
        }
    };


    return (
        <>

            <div className="container-fluid basicform" >

                <form >

                    <div className="form-group">
                        <label className="form-label" htmlFor="category" style={{ fontSize: "12px" }}>
                            Category<sup style={{ color: "red" }}>*</sup>
                        </label>
                        <input
                            id="category"
                            style={{ fontSize: "13px" }}
                            type="text"
                            className="form-control form-control-sm "
                            name="category"
                            value={categoryFormValue.category}
                            onChange={handleChange}
                        />
                        <p className="errormsg " style={{ marginBottom: "0" }}>{categoryFormError.category}</p>
                    </div>
                    <div className="form-group">
                        <label className="form-label" htmlFor="slug">
                            Slug
                        </label>
                        <input
                            type="text"
                            name="slug"
                            className="form-control form-control-sm mb-1"
                            value={sluga}
                            id="slug"
                            placeholder="slug"
                            disabled
                        />
                    </div>
                    <div className="form-group">

                        <div style={{ marginTop: "15px" }}>
                            <input
                                type="checkbox"
                                id="exampleCheck3"
                                name="exampleCheck3"
                                onChange={handlePrimary}
                                checked={primary}
                            />
                            <label class="form-check-label" for="exampleCheck3" style={{ fontSize: "12px", paddingLeft: "3px" }}>
                                Primary
                            </label>
                        </div>
                    </div>
                </form>

                <div className="sec-dataTable">

                    {loading ? (
                        <Spinner />
                    ) : (
                        <DataTable
                            columns={columns}
                            data={blogCatList}
                            customStyles={customStyles}
                            pagination
                            fixedHeader
                            fixedHeaderScrollHeight="280px"
                            highlightOnHover
                            pointerOnHover
                            responsive
                            dense
                            striped
                            subHeader
                            subHeaderComponent={
                                <>
                                    <div className="upper-dataTbl">
                                        <div className="d-flex">
                                            <input
                                                placeholder="Search"
                                                ref={searchInput}
                                                type="text"
                                                className="form-control form-control-sm searchField"
                                                onChange={searchHandler}
                                            />
                                        </div>
                                    </div>
                                </>
                            }
                        />
                    )}
                </div>
            </div>
            <div className="FormFooter">
                <div className='checkedClose'>
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck2"
                        name="closeChecked"
                        onChange={handleOnChange}
                        checked={closeChecked}
                    />
                    <label class="form-check-label" for="exampleCheck2">
                        Exit after {!isEdit ? "submit" : "update"}
                    </label>
                </div>
                <div>
                    {!isEdit ?
                        <button className='btn btn-sm btn-cmpy ml-2' style={{ background: "green" }} onClick={formSubmit} >Submit</button> :
                        <button className='btn btn-sm btn-cmpy ml-2' style={{ background: "green" }} onClick={editdata} >Update</button>
                    }
                    <button className='btn btn-sm btn-cmpy ml-2' onClick={closeCatPopup}>Cancel</button>
                </div>
            </div>

            {deleteTopicPopup.show && (
                <DeleteTopicPop
                    handleDeleteTopicTrue={handleDeleteTopicTrue}
                    handleDeleteTopicFalse={handleDeleteTopicFalse}
                />
            )}
        </>

    )
}
