import React from "react";
import landinglogo from "./../../images/landinglogo.png";
import OwlCarousel from "react-owl-carousel";
import { useContext } from "react";
import JobContext from "../../component/FilterjobContext";

const Footer = () => {
  const { companyList } = useContext(JobContext);
  console.log("companyList", companyList);
  return (
    <footer id="footer">
      <div class="footer-top">
        <div class="container">
          <div>
            <div className="section-title">
              <h2 className="m-0">Trusted by companies</h2>
            </div>

            <p
              className="mt-0 mx-auto text-center"
              style={{ maxWidth: "600px", lineHeight: "25px" }}
            >
              Rapidiously morph transparent internal or "organic" sources
              whereas resource sucking e-business. Conveniently innovate
              compelling internal.
            </p>

            <div className="footer-school pb-5">
              <OwlCarousel
                nav={false}
                loop
                margin={10}
                dots
                autoplay
                autoplayHoverPause
                autoplayTimeout={3000}
                responsive={{
                  0: {
                    items: 2,
                  },
                  640: {
                    items: 3,
                  },
                  960: {
                    items: 4,
                  },
                  1200: {
                    items: 5,
                  },
                }}
              >
                {companyList.map((item) => (
                  <div key={item.Name}>
                    <img src={item.Logo} alt="" />
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3 col-md-6 footer-contact">
              <a href="index.html" class="logo">
                <img
                  src={landinglogo}
                  style={{ height: "100px", width: "100px" }}
                  alt="Easy Software"
                  class="img-fluid"
                />
              </a>
              <p>
                Kalimati,Kathmandu,Nepal
                <br />
                <strong>Phone:</strong> 9840921520 ,9808577620
                <br />
                <strong>Email:</strong> easysoftwarenepal@gmail.com
                <br />
              </p>
            </div>

            <div class="col-lg-3 col-md-6 footer-links" data-aos-delay="100">
              <h4>Our Featured Product</h4>
              <ul>
                <li>
                  <i class="bi bi-chevron-right"></i>{" "}
                  <a>Easy Cooperative Software</a>
                </li>
                <li>
                  <i class="bi bi-chevron-right"></i>{" "}
                  <a>Easy Accounting Software</a>
                </li>
                <li>
                  <i class="bi bi-chevron-right"></i>{" "}
                  <a>Easy School & College Software</a>
                </li>
                <li>
                  <i class="bi bi-chevron-right"></i>{" "}
                  <a>Easy Khanepani Software</a>
                </li>
              </ul>
            </div>

            <div class="col-lg-3 col-md-6 footer-links" data-aos-delay="200">
              <h4>Our Services</h4>
              <ul>
                <li>
                  <i class="bi bi-chevron-right"></i>{" "}
                  <a>Software Development</a>
                </li>
                <li>
                  <i class="bi bi-chevron-right"></i> <a>Web Development</a>
                </li>
                <li>
                  <i class="bi bi-chevron-right"></i>{" "}
                  <a>Mobile App Development</a>
                </li>
                <li>
                  <i class="bi bi-chevron-right"></i> <a>Digital Marketing</a>
                </li>
                <li>
                  <i class="bi bi-chevron-right"></i> <a>Graphic Design</a>
                </li>
              </ul>
            </div>

            <div class="col-lg-3 col-md-6 footer-links" data-aos-delay="300">
              <h4>Join Us</h4>
              <p>
                Easy Software Pvt ltd aims to provide creative and easy software
                for companies and other association.
              </p>
              <div class="social-links mt-3">
                <a
                  target="_blank"
                  href="https://twitter.com/easysoftwarenp"
                  class="twitter"
                >
                  <i class="bi bi-twitter"></i>
                </a>
                <a
                  target="_blank"
                  href="https://www.facebook.com/easysoftwarenp/"
                  class="facebook"
                >
                  <i class="bi bi-facebook"></i>
                </a>
                <a
                  target="_blank"
                  href="https://www.instagram.com/easysoftwarenp/"
                  class="instagram"
                >
                  <i class="bi bi-instagram"></i>
                </a>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/easysoftwarenp"
                  class="linkedin"
                >
                  <i class="bi bi-linkedin"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container py-4">
        <div class="copyright">
          &copy; Copyright{" "}
          <strong>
            <a target="_blank" href="https://easysoftware.com.np/">
              {" "}
              <span>Easy Software</span>
            </a>
          </strong>
          . All Rights Reserved
        </div>
      </div>
    </footer>
  );
};

export default Footer;
