import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";
import Select from "react-select";
import AssignmentContext from "../contextfolder/AssignmentContext/AssignmentContext";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import ViewAssignment from "./ViewAssignment";

export default function CheckAssignment() {
  const { customStyles } = useContext(SidebarContext);
  const {
    loading,
    originalList,
    popup,
    togglePopup,
    setIsSubmit,
    setAssignmentFormValue,
    setAssignmentFormError,
    classList,
    sectionList,
    teacherList,
    subjectList,
    assignmentValue,
    checkAssignmentList,
    setCheckAssignmentList,
    checkOriginalList,
    toggleViewPopup,
    viewPop,
    viewAssignment,
    setRemarksFormValue,
    setRemarksFormError,
    remarksvalue,
    setPushNotice,
    setNewState,
    setoption,
  } = useContext(AssignmentContext);

  const searchInput = useRef("");

  const closeViewPopup = (e) => {
    e.preventDefault();
    toggleViewPopup();
    setRemarksFormError({});
    setPushNotice(false);
    setNewState([]);
    setoption([]);
  };

  const columns = [
    {
      name: "S.N.",
      width: "58px",
      cell: (row, index) => index + 1,
    },
    {
      name: "Full Name",
      center: true,
      selector: (row) => row.FullName,
    },
    {
      name: "Point",
      // grow: 0,
      center: true,
      width: "80px",
      selector: (row) => row.Point,
    },
    {
      name: "Submitted Date",
      // grow: 0,
      width: "130px",
      center: true,
      selector: (row) => row.SubDate,
    },
    {
      name: "Status",
      width: "110px",
      center: true,
      selector: (row) => row.MemStatus,
    },

    {
      name: "Action",
      // grow: 0,
      center: true,
      width: "100px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <Tippy content="View">
                <i
                  className="bi bi-eye solvespan--action"
                  onClick={() => viewAssignment(row)}
                  style={{ paddingTop: "3px", paddingLeft: "1px" }}
                ></i>
              </Tippy>
            </div>
          </>
        );
      },
    },
  ];

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = checkOriginalList.filter((list) => {
        return list["FullName"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setCheckAssignmentList(srchResult);
      } else {
        setCheckAssignmentList({});
      }
    } else {
      setCheckAssignmentList(checkOriginalList);
    }
  };

  return (
    <>
      <div className="sec-dataTable" style={{ margin: "0px 10px 10px 10px" }}>
        {loading ? (
          <Spinner />
        ) : (
          <DataTable
            columns={columns}
            data={checkAssignmentList}
            customStyles={customStyles}
            pagination
            fixedHeader
            fixedHeaderScrollHeight="340px"
            highlightOnHover
            pointerOnHover
            responsive
            dense
            striped
            subHeader
            subHeaderAlign="right"
            subHeaderComponent={
              <>
                <div>
                  <div>
                    <input
                      placeholder="Search"
                      ref={searchInput}
                      type="text"
                      className="form-control form-control-sm searchField"
                      onChange={searchHandler}
                    />
                  </div>
                </div>
              </>
            }
          />
        )}
      </div>
      {viewPop && (
        <div className="modals">
          <div className="popup-overlay"></div>

          <div className="view-assignment-content">
            <div className="popUpHeader ">
              <div className="popUpHeaderText">View Assignment</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closeViewPopup}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="view-inside-modal ">
              <ViewAssignment closeViewPopup={closeViewPopup} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
