import React from "react";
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

export default function GoogleMap({ userinfo }) {


    const defaultProps = {
        center: {
            lat: userinfo.LocLat,
            lng: userinfo.LocLong
        },
        zoom: 11
    };

    return (
        // Important! Always set the container height explicitly
        <div style={{ height: '40vh', width: '100%' }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: "AIzaSyBI-nD4W0kBTAFVJYndsinvcVOPiORRiYE" }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
            >
                <AnyReactComponent
                    lat={userinfo.LocLat}
                    lng={userinfo.LocLong}
                    text="My Marker"
                />
            </GoogleMapReact>
        </div>
    );
}