import React, { useContext, useRef } from "react";
import '../../css/Commission.css';
import Spinner from "../loading/spinner";
import { formatNumber } from "../hooks/drCr";
import StudentContext from "../contextfolder/StudentContext/StudentContext";


export default function AccountTab() {
    const { accountReportList, accountLoad, accountSearch, setAccountReportList } = useContext(StudentContext);


    let credit = accountReportList.reduce(function (prev, current) {
        return prev + parseFloat(current.Credit);
    }, 0);

    let debit = accountReportList.reduce(function (prev, current) {
        return prev + parseFloat(current.Debit);
    }, 0);


    const searchInput = useRef("");


    const searchHandler = (e) => {
        e.preventDefault();

        const srchQuery = searchInput.current.value.toLowerCase();
        if (srchQuery) {

            let srchResult = accountSearch.filter((list) => {
                return list["Particulars"].toLowerCase().includes(srchQuery);
            });

            if (srchResult) {

                setAccountReportList(srchResult);
            } else {
                setAccountReportList({});
            }
        } else {
            setAccountReportList(accountSearch);
        }
    };

    

    return (
        <>

            <div className="sec-dataTable">
            
                <div className="totalTrans"> {formatNumber((debit-credit).toFixed(2))} <b> &nbsp;Balance :&nbsp;</b>{credit.toFixed(2)} | <b> &nbsp;Total Credit :&nbsp;</b>{debit.toFixed(2)} | <b>&nbsp;Total Debit :&nbsp;</b>
                </div>
                <div className="row" style={{ margin: "0" }}>

                    

                    <div className="col-lg-3 offset-lg-9 offset-md-9 offset-sm-9 col-md-3 col-sm-3 align-self-end Search" style={{ marginTop: "4px", paddingRight: "1px", marginBottom: "10px" }}>
                        <div className="d-flex">
                            <input
                                placeholder="Search"
                                ref={searchInput}
                                type="text"
                                className="form-control form-control-sm searchField"
                                onChange={searchHandler}
                            />
                        </div>
                    </div>
                </div>
                {accountLoad ? (
                    <Spinner />
                ) : (
                    <>
                        {accountReportList.length > 0 ? (
                            <div className="table-responsive br-3">

                                <table className="table table-bordereds text-nowrap mb-0">
                                    <thead>
                                        <tr className="tableHead">
                                            <td className="tc" style={{ width: "70px" }}>S.N.</td>
                                            <td className="tl" style={{ width: "145px" }}>Date</td>
                                            <td className="tl" >Particulars</td>
                                            <td className="tr" style={{ width: "150px" }}>Debit</td>
                                            <td className="tr" style={{ width: "150px" }}>Credit</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {accountReportList.map((item, i) => (
                                            <tr key={i}>
                                                <td className="tc" style={{ width: "70px" }}>{i + 1}</td>
                                                <td className="tl">{item.Date}</td>
                                                <td className="tl">{item.Particulars}</td>
                                                <td className="tr">{parseFloat(item.Debit).toFixed(2)}</td>
                                                <td className="tr">{parseFloat(item.Credit).toFixed(2)}</td>
                                            </tr>
                                        ))}
                                        <tr className="lastt">
                                            <td colspan="3" ><b style={{ paddingLeft: "15px" }}>Total</b></td>
                                            <td className="tr"><b>{debit.toFixed(2)}</b></td>
                                            <td className="tr "><b>{credit.toFixed(2)}</b></td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        ) : (
                            <div className="table-responsive br-3">

                                <div className="text-center mt-4">
                                    <p>There are no records to display.</p>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </>

    );


}