import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";
import { Fetchdata } from "../hooks/getData";
import { GetNepaliDate } from "../hooks/dateConvertor";
import AcademicHeader from "../AcademicHeader";

export default function StudentStudyMaterial() {
  const { todayDate, customStyles, AcademicYear, appURL } =
    useContext(SidebarContext);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);

  const [albumList, setAlbumList] = useState([]);
  const [selectAlbum, setSelectAlbum] = useState("");

  const [studyList, setStudyList] = useState([]);

  const searchInput = useRef("");

  const columns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "Title",
      selector: (row) => row.Title,
    },
    {
      name: "Description",

      selector: (row) => row.Description,
    },
    {
      name: "File Type",
      width: "130px",
      selector: (row) =>
        row.FileType === "I"
          ? "Image"
          : row.FileType === "P"
          ? "PDF"
          : row.FileType === "PP"
          ? "PPT"
          : row.FileType === "U"
          ? "URL"
          : "DOC",
    },
    {
      name: "Created Date",
      selector: (row) => GetNepaliDate(row.CreatedDate.split(" ")[0]),
      width: "180px",
    },
    {
      name: "Action",
      // grow: 0,
      center: true,
      width: "100px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <button
                className="badge badge-primary"
                onClick={() => openInNewTab(row.URL)}
              >
                View
              </button>
            </div>
          </>
        );
      },
    },
  ];

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["Title"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setStudyList(srchResult);
      } else {
        setStudyList({});
      }
    } else {
      setStudyList(originalList);
    }
  };

  const handleAlbum = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectAlbum(value);
  };

  useEffect(() => {
    getAlbumList();
  }, []);

  const getAlbumList = () => {
    const params = {
      SchID: userinfo.SchID,
      Flag: "SA",
      ClassID: userinfo.ClassID.toString(),
      SectionID: userinfo.SectionID.toString(),
      MemID: userinfo.UserID.toString(),
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/study-material`,
    };
    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.StudyMaterialLst
          ? result.StudyMaterialLst
          : "";
        setAlbumList(postResult);
      } else {
        setAlbumList([]);
      }
    });
  };

  useEffect(() => {
    getStudyAlbumList();
  }, [selectAlbum]);

  const getStudyAlbumList = () => {
    const params = {
      SchID: userinfo.SchID,
      StudyAlbumID: selectAlbum,
      Flag: "SM",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/study-material`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.StudyMaterialLst
          ? result.StudyMaterialLst
          : "";
        setLoading(false);
        setStudyList(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        setStudyList([]);
        setOriginalList("");
      }
    });
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
        <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">Study Material</div>
            <div className="page-date">
              <div className="sec-content">
                <AcademicHeader
                  todayDate={todayDate}
                  AcademicYear={AcademicYear}
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <>
          <div className="sec-dataTable content-section main-content">
            {loading ? (
              <Spinner />
            ) : (
              <DataTable
                columns={columns}
                data={studyList}
                customStyles={customStyles}
                pagination
                fixedHeader
                fixedHeaderScrollHeight="280px"
                highlightOnHover
                pointerOnHover
                responsive
                dense
                striped
                subHeader
                subHeaderAlign="left"
                subHeaderComponent={
                  <>
                    <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                      <label className="list-label">Album</label>
                      <select
                        style={{ fontSize: "11px" }}
                        name="snotifiaction"
                        value={selectAlbum}
                        onChange={handleAlbum}
                        className="form-control form-control-sm"
                        aria-label="Default select example"
                      >
                        <option
                          value=""
                          disabled
                          selected
                          style={{ fontSize: "11px" }}
                        >
                          Select Album
                        </option>

                        {albumList.map((item) => (
                          <option
                            key={item.StudyAlbumID}
                            value={item.StudyAlbumID}
                          >
                            {item.Title}
                          </option>
                        ))}
                      </select>
                      <i class="fas fa-angle-down  notificationpositon"></i>
                    </div>

                    <div
                      className="col-lg-2 offset-lg-8 col-md-2 offset-md-8 col-sm-2 align-self-end Search"
                      style={{
                        marginTop: "25px",
                        padding: "inherit",
                        marginBottom: "10px",
                      }}
                    >
                      <div className="d-flex">
                        <input
                          placeholder="Search"
                          ref={searchInput}
                          type="text"
                          className="form-control form-control-sm searchField"
                          onChange={searchHandler}
                        />
                      </div>
                    </div>
                  </>
                }
              />
            )}
          </div>
        </>
      </div>
    </>
  );
}
