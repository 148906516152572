import React, { useContext, useEffect, useState } from "react";
import CloseIcon from "../../images/CloseIcon.svg";
import OrderContext from "../contextfolder/OrderContext/OrderContext";



export default function PaymentPayOrder({ handlePayFalses, handlePayTrues }) {

    const { payFormValues, setPayFormValues, payFormErrors, setPayFormErrors, isSubmits, setIsSubmits, payValues,  tAmounts } = useContext(OrderContext)

    const remainingAmount = payFormValues.payAmount - tAmounts;

    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;
        setPayFormValues({ ...payFormValues, [name]: value });
    };


    const validate = (values) => {

        const errors = {};
        if (!values.payAmount) {
            errors.payAmount = "Required";
        }

        return errors;
    };


    const formSubmit = (e) => {
        e.preventDefault();
        setPayFormErrors(validate(payFormValues));
        setIsSubmits(true);
    }


    useEffect(() => {
        if (Object.keys(payFormErrors).length === 0 && isSubmits) {
            handlePayTrues(payFormValues)
            setPayFormValues(payValues)
            setIsSubmits(false);
        }

    }, [payFormErrors]);



    return (
        <>
            <div className="container confirm-leave-popup">
                <div className="confirm-leave-popup-inner ">
                    <div className="popUpHeader ps-0 pe-0">
                        <div className="popUpDelTitle">Easy School</div>
                        <div className="popUpClose">
                            <img style={{ paddingRight: "10px", cursor: "pointer" }}
                                className="popUpCloseIcon"
                                src={CloseIcon}
                                alt="CloseIcon"
                                onClick={handlePayFalses}
                            />
                        </div>
                    </div>

                    <div className="confirm-pay-body  ps-3 pe-3">
                        <div className="row text-start ">
                            <div className="delText">Are you sure want to Pay this?</div>
                        </div>
                    </div>
                    <div className="container-fluid basicform">
                        <div className="form-group">
                            <div>
                                <b> Total Amount: {parseFloat(tAmounts).toFixed(2)} </b>
                            </div>
                        </div>
                        <div className="form-row" style={{ marginTop: "10px" }}>

                            <div className="form-group col-md-6">
                                <label className="form-label" htmlFor="payAmount" style={{ fontSize: "12px" }}>
                                    Pay Amount<sup style={{ color: "red" }}>*</sup>
                                </label>
                                <input
                                    id="payAmount"
                                    style={{ fontSize: "13px" }}
                                    type="text"
                                    className="form-control form-control-sm"
                                    name="payAmount"
                                    value={payFormValues.payAmount}
                                    onChange={handleChange}
                                />
                                <p className="errormsg " style={{ marginBottom: "0" }}>{payFormErrors.payAmount}</p>

                            </div>
                            <div className="form-group col-md-6">
                                <label className="form-label" style={{ fontSize: "12px" }}>
                                    Remaining Amount
                                </label>
                                <div>
                                    <input
                                        id="remainingAmount"
                                        style={{ fontSize: "13px" }}
                                        type="text"
                                        className="form-control form-control-sm "
                                        value={payFormValues.payAmount === "" ? 0 : remainingAmount}
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    

                    <div className="confirm-leave-footer">
                        <div className="row  mt-2 mb-2">
                            <div>
                                <button
                                    type="button"
                                    class="btn btn-sm me-2"
                                    style={{ background: "#0079bf", color: "white" }}
                                    onClick={formSubmit}
                                >
                                    Okay
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-sm btn-danger me-3"
                                    style={{ color: "white" }}
                                    onClick={handlePayFalses}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

