import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import SidebarContext from "../sidebarcontext/SidebarContext";
import Spinner from "../loading/spinner";
import RoutineContext from "../contextfolder/RoutineContext/RoutineContext";
import DeletePop from "./DeletePop";

export default function Routine() {
  const { todayDate, customStyles } = useContext(SidebarContext);

  const {
    loading,
    originalList,
    classList,
    sectionList,
    selectClass,
    setSelectClass,
    selectSection,
    setSelectSection,
    routineList,
    setRoutineList,
    deletePopup,
    handleDeleteTrue,
    handleDeleteFalse,
    handleDelete,
  } = useContext(RoutineContext);

  const handleClass = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectClass(value);
  };

  const handleSection = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectSection(value);
  };

  const [chooseDay, setChooseDay] = useState("");
  const handleDay = (e) => {
    const target = e.target;
    const value = target.value;
    setChooseDay(value);
  };

  const searchInput = useRef("");

  const columns = [
    {
      name: "S.N.",
      width: "59px",
      cell: (row, index) => index + 1,
    },
    {
      name: "Period",
      // center: true,
      // grow: 0,
      width: "200px",
      selector: (row) => row.StdFullName,
    },
    {
      name: "Subject",
      center: true,
      // grow: 0,
      selector: (row) => row.StdUserName,
    },
    {
      name: "Teacher",
      // grow: 0,
      center: true,
      selector: (row) => row.Gender,
    },
    {
      name: "Start",
      // grow: 0,
      center: true,
      selector: (row) => row.Mobile,
    },
    {
      name: "End",
      // grow: 0,
      center: true,
      selector: (row) => row.RollNo,
    },
    {
      name: "Action",
      center: true,
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <button
                type="button"
                class="btn btn-sm rejectspan"
                onClick={() => handleDelete(row.StudentID)}
              >
                Delete
              </button>
            </div>
          </>
        );
      },
    },
  ];

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["StdUserName"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setRoutineList(srchResult);
      } else {
        setRoutineList({});
      }
    } else {
      setRoutineList(originalList);
    }
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <div className="container-fluid classatten-wrapper  mt-3 ps-4 pe-4">
        <div className="row mt-3">
          <div className="page-header">
            <div className="text-start  page-title">Routine</div>
            <div className="page-date">
              <div className="sec-content">
                Today's Date : {todayDate}
                {/* <span>|</span> Fiscal Year :{" "}
                                {fiscalYear.StartDate}
                                <span>-</span>
                                {fiscalYear.EndDate} */}
              </div>
            </div>
          </div>
          <hr className="title-hr" />
        </div>
        <>
          <div className="sec-dataTable">
            {/* <div className="upper-dataTbl">
                            <div className="btn-addlnote mb-3">
                                <button
                                    type="button"
                                    class="btn btn-sm"
                                    style={{
                                        background: "var(--button-color)",
                                        color: "white",
                                    }}
                                    onClick={addLeaveNote}
                                >
                                    Add New
                                </button>
                            </div>
                        </div> */}

            {loading ? (
              <Spinner />
            ) : (
              <DataTable
                columns={columns}
                data={routineList}
                customStyles={customStyles}
                pagination
                fixedHeader
                fixedHeaderScrollHeight="280px"
                highlightOnHover
                pointerOnHover
                responsive
                dense
                striped
                subHeader
                subHeaderComponent={
                  <>
                    <div
                      className="col-md-2 col-sm-2 col-lg-2"
                      style={{ marginRight: "-15px" }}
                    >
                      <label
                        style={{
                          fontSize: "14px",
                          textAlign: "left",
                          display: "block",
                        }}
                      >
                        Class
                      </label>
                      <select
                        style={{ fontSize: "11px", marginBottom: "3px" }}
                        name="snotifiaction"
                        value={selectClass}
                        onChange={handleClass}
                        className="form-select form-select-sm"
                        aria-label="Default select example "
                      >
                        <option
                          value=""
                          disabled
                          selected
                          style={{ fontSize: "11px" }}
                        >
                          Select Class
                        </option>
                        {classList.map((item) => (
                          <option key={item.ClassID} value={item.ClassID}>
                            {item.ClassName}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-md-2 col-sm-2 col-lg-2">
                      <label
                        style={{
                          fontSize: "14px",
                          textAlign: "left",
                          display: "block",
                        }}
                      >
                        Section
                      </label>
                      <select
                        style={{ fontSize: "11px", marginBottom: "3px" }}
                        name="snotifiaction"
                        value={selectSection}
                        onChange={handleSection}
                        className="form-select form-select-sm"
                        aria-label="Default select example "
                      >
                        <option
                          value=""
                          disabled
                          selected
                          style={{ fontSize: "11px" }}
                        >
                          Select Section
                        </option>
                        {sectionList.map((item) => (
                          <option key={item.SectionID} value={item.SectionID}>
                            {item.SectionName}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-md-2 col-sm-2 col-lg-2">
                      <label
                        style={{
                          fontSize: "14px",
                          textAlign: "left",
                          display: "block",
                        }}
                      >
                        Day
                      </label>
                      <select
                        style={{ fontSize: "11px", marginBottom: "3px" }}
                        name="snotifiaction"
                        value={chooseDay}
                        onChange={handleDay}
                        className="form-select form-select-sm"
                        aria-label="Default select example "
                      >
                        <option
                          value=""
                          disabled
                          selected
                          style={{ fontSize: "11px" }}
                        >
                          Select Day
                        </option>
                        <option value="1">Sunday</option>
                        <option value="2">Monday</option>
                        <option value="3">Tuesday</option>
                        <option value="4">Wednesday</option>
                        <option value="5">Thursday</option>
                        <option value="6">Friday</option>
                        <option value="7">Saturday</option>
                      </select>
                    </div>

                    <div
                      className="upper-dataTbl"
                      style={{ marginTop: "25px" }}
                    >
                      <div className="d-flex">
                        <input
                          placeholder="Search"
                          ref={searchInput}
                          type="text"
                          className="form-control form-control-sm searchField"
                          onChange={searchHandler}
                        />
                      </div>
                    </div>
                  </>
                }
              />
            )}
          </div>
        </>
      </div>

      {deletePopup.show && (
        <DeletePop
          handleDeleteTrue={handleDeleteTrue}
          handleDeleteFalse={handleDeleteFalse}
        />
      )}
    </>
  );
}
