import React, { useContext, useEffect, useState } from "react";
import { Fetchdata } from "../../hooks/getData";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { toast } from "react-toastify";
import SubjectContext from "./SubjectContext";

function SubjectState(props) {
  const { appURL } = useContext(SidebarContext);

  const [loading, setLoading] = useState(true);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const [closeChecked, setCloseChecked] = useState(false);

  const subjectValue = {
    class: "",
    section: "",
    teacher: "",
  };
  const [inputFields, setInputFields] = useState([]);
  const [subjectFormValue, setSubjectFormValue] = useState(subjectValue);
  const [subjectFormError, setSubjectFormError] = useState({});
  const [isSubmit, setIsSubmit] = useState({});

  const [teacherList, setTeacherList] = useState([]);
  const [selectTeacher, setSelectTeacher] = useState("");

  useEffect(() => {
    teachList();
  }, []);

  const teachList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/teacher`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.teachingres ? result.teachingres : "";
        setLoading(false);
        setTeacherList(postResult);
      } else {
        setLoading(false);
        setTeacherList([]);
      }
    });
  };

  const [popup, setPopup] = useState(false);
  const togglePopup = () => {
    setPopup(!popup);
  };

  // For popup
  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  //Everything to pop up and delete the student list
  const [deletePopup, setDeletePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const [delData, setDelData] = useState();

  const handleDelete = async (data) => {
    setDelData(data);

    setDeletePopup({
      show: true,
      data,
    });
  };

  // This will perform the deletion and hide the Confirmation Box

  const handleDeleteTrue = async () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "D",
      TeachingID: delData.toString(),
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/teaching-subject`, requestOptions).then(
      (result) => {
        result.json().then((resp) => {
          if (resp.StatusCode === 200) {
            teachingList();
            toast(resp.Message, {
              style: {
                color: "green",
                fontSize: "13px",
              },
            });
            setDeletePopup({
              show: false,
              data: null,
            });
          } else {
            toast(resp.Message, {
              style: {
                color: "red",
                fontSize: "13px",
              },
            });
          }
        });
      }
    );
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleDeleteFalse = () => {
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // Ends Here

  //API to create teaching subject
  const fetchdata = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "I",
      ClassID: subjectFormValue.class,
      SectionID: subjectFormValue.section,
      TeacherID:
        userinfo.UserType === "T" ? userinfo.UserID : subjectFormValue.teacher,
      Values: inputFields,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/teaching-subject`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        teachingList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };

  // API for teaching subject list
  const [searchTeachingSubject, setSearchTeachingSubject] = useState("");
  const [teachingSubjectList, setTeachingSubjectList] = useState([]);

  useEffect(() => {
    teachingList();
  }, [selectTeacher]);

  const teachingList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      TeacherID: userinfo.UserType === "T" ? userinfo.UserID : selectTeacher,
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/teaching-subject`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.teachingres ? result.teachingres : "";
        setLoading(false);
        setTeachingSubjectList(postResult);
        setSearchTeachingSubject(postResult);
      } else {
        setLoading(false);
        setTeachingSubjectList([]);
        setSearchTeachingSubject([]);
      }
    });
  };

  return (
    <SubjectContext.Provider
      value={{
        modal,
        setModal,
        toggleModal,
        loading,
        deletePopup,
        handleDeleteTrue,
        handleDeleteFalse,
        handleDelete,
        popup,
        setPopup,
        togglePopup,
        subjectFormValue,
        setSubjectFormValue,
        subjectFormError,
        setSubjectFormError,
        isSubmit,
        subjectValue,
        setIsSubmit,
        inputFields,
        setInputFields,
        fetchdata,
        teachingSubjectList,
        searchTeachingSubject,
        setTeachingSubjectList,
        closeChecked,
        setCloseChecked,
        teacherList,
        selectTeacher,
        setSelectTeacher,
        teachingList,
      }}
    >
      {props.children}
    </SubjectContext.Provider>
  );
}

export default SubjectState;
