import React, { useContext, useEffect, useState } from "react";
import CloseIcon from "../../images/CloseIcon.svg";
import SuggestionContext from "../contextfolder/SuggestionContext/SuggestionContext";
import '../AdminLeave/ApprovePop.css';


export default function ApprovePop({ handleApproveTrue, handleApproveFalse, approveNotify, setApproveNotify }) {

    const { popFormValue, setPopFormValue, popFormError, setPopFormError, isPopSubmit, setIsPopSubmit, popValue } = useContext(SuggestionContext)

    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;
        setPopFormValue({ ...popFormValue, [name]: value });
    };

    const handleOnChange = (e) => {
        setApproveNotify(!approveNotify)
    };

    const validate = (values) => {

        const errors = {};
        if (!values.approveRemarks) {
            errors.approveRemarks = "Required";
        }

        return errors;
    };


    const formSubmit = (e) => {
        e.preventDefault();
        setPopFormError(validate(popFormValue));
        setIsPopSubmit(true);
    }


    useEffect(() => {
        if (Object.keys(popFormError).length === 0 && isPopSubmit) {
            handleApproveTrue(popFormValue)
            setPopFormValue(popValue)
            setIsPopSubmit(false);
        }

    }, [popFormError]);



    return (
        <>
            <div className="container confirm-leave-popup">
                <div className="confirm-leave-popup-inner ">
                    <div className="popUpHeader ps-0 pe-0">
                        <div className="popUpDelTitle">Easy School</div>
                        <div className="popUpClose">
                            <img style={{ paddingRight: "10px", cursor: "pointer" }}
                                className="popUpCloseIcon"
                                src={CloseIcon}
                                alt="CloseIcon"
                                onClick={handleApproveFalse}
                            />
                        </div>
                    </div>

                    <div className="confirm-leave-body  ps-3 pe-3">
                        <div className="row text-start ">
                            <div className="delText">Are you sure want to Approve this?</div>
                        </div>
                    </div>
                    <div className="container-fluid basicform" >
                        <div className="form-group">
                            <label htmlFor="approveRemarks" style={{ fontSize: "12px" }}>
                                Remarks<sup style={{ color: "red" }}>*</sup>
                            </label>
                            <input
                                id="approveRemarks"
                                style={{ fontSize: "13px" }}
                                type="text"
                                className="form-control form-control-sm"
                                name="approveRemarks"
                                value={popFormValue.approveRemarks}
                                onChange={handleChange}
                            />
                            <p className="errormsg " style={{ marginBottom: "0" }}>{popFormError.approveRemarks}</p>

                        </div>
                    </div>
                    <div className="checkbox-close" style={{ marginLeft: "9px", paddingTop: "3px" }}>
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="exampleCheck3"
                            name="notWorking"
                            onChange={handleOnChange}
                            checked={approveNotify}
                        />
                        <label class="form-check-label" for="exampleCheck3" style={{ fontSize: "12px" }}>
                            Notify User
                        </label>
                    </div>

                    <div className="confirm-leave-footer">
                        <div className="row  mt-2 mb-2">
                            <div>
                                <button
                                    type="button"
                                    class="btn btn-sm me-2"
                                    style={{ background: "#0079bf", color: "white" }}
                                    onClick={formSubmit}
                                >
                                    Okay
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-sm btn-danger me-3"
                                    style={{ color: "white" }}
                                    onClick={handleApproveFalse}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

