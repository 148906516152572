import React, { useContext, useEffect } from "react";
import LogBookContext from "../../contextfolder/LogBookContext/LogBookContext";

export default function LogBookEdit({ closeEditPop }) {
  const context = useContext(LogBookContext);

  const {
    toggleEditPopup,
    logBookFormError,
    setlogBookFormError,
    editIsSubmit,
    setEditIsSubmit,
    logBookFormValue,
    setlogBookFormValue,
    setCloseChecked,
    closeChecked,
    editdata,
  } = context;

  const handleOnChange = () => {
    setCloseChecked(!closeChecked);
  };

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setlogBookFormValue({ ...logBookFormValue, [name]: value });
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setlogBookFormError(validate(logBookFormValue));
    setEditIsSubmit(true);
  };
  useEffect(() => {
    if (Object.keys(logBookFormError).length === 0 && editIsSubmit) {
      editdata(logBookFormValue);
      if (closeChecked === true) {
        toggleEditPopup();
      }
      setEditIsSubmit(false);
      setCloseChecked(false);
    }
  }, [logBookFormError]);

  const validate = (values) => {
    const errors = {};
    if (!values.lesson) {
      errors.lesson = "Required";
    }
    if (!values.page) {
      errors.page = "Required";
    }
    if (!values.lessonTaught) {
      errors.lessonTaught = "Required";
    }
    if (!values.assignmentGiven) {
      errors.assignmentGiven = "Required";
    }
    return errors;
  };

  return (
    <>
      <div className="container-fluid basicform">
        <form>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label
                className="form-label"
                htmlFor="lesson"
                style={{ fontSize: "12px" }}
              >
                Lesson No.<sup style={{ color: "red" }}>*</sup>
              </label>
              <input
                id="lesson"
                style={{ fontSize: "13px" }}
                type="text"
                className="form-control form-control-sm "
                name="lesson"
                value={logBookFormValue.lesson}
                onChange={handleChange}
              />
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {logBookFormError.lesson}
              </p>
            </div>
            <div className="form-group col-md-6">
              <label
                className="form-label"
                htmlFor="page"
                style={{ fontSize: "12px" }}
              >
                Page No.<sup style={{ color: "red" }}>*</sup>
              </label>
              <input
                id="page"
                style={{ fontSize: "13px" }}
                type="text"
                className="form-control form-control-sm "
                name="page"
                value={logBookFormValue.page}
                onChange={handleChange}
              />
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {logBookFormError.page}
              </p>
            </div>
          </div>

          <div className="form-group">
            <label
              className="form-label"
              htmlFor="lessonTaught"
              style={{ fontSize: "12px" }}
            >
              Lesson Taught<sup style={{ color: "red" }}>*</sup>
            </label>
            <textarea
              id="lessonTaught"
              style={{ fontSize: "13px" }}
              type="text"
              className="form-control form-control-sm "
              name="lessonTaught"
              value={logBookFormValue.lessonTaught}
              onChange={handleChange}
              rows="3"
            />
            <p className="errormsg " style={{ marginBottom: "0" }}>
              {logBookFormError.lessonTaught}
            </p>
          </div>

          <div className="form-group">
            <label
              className="form-label"
              htmlFor="assignmentGiven"
              style={{ fontSize: "12px" }}
            >
              Assignment Given<sup style={{ color: "red" }}>*</sup>
            </label>
            <textarea
              id="assignmentGiven"
              style={{ fontSize: "13px" }}
              type="text"
              className="form-control form-control-sm "
              name="assignmentGiven"
              value={logBookFormValue.assignmentGiven}
              onChange={handleChange}
              rows="3"
            />
            <p className="errormsg " style={{ marginBottom: "0" }}>
              {logBookFormError.assignmentGiven}
            </p>
          </div>
        </form>
      </div>
      <div className="FormFooter">
        <div className="checkedClose">
          <input
            type="checkbox"
            className="form-check-input"
            id="exampleCheck2"
            name="closeChecked"
            onChange={handleOnChange}
            checked={closeChecked}
          />
          <label class="form-check-label" for="exampleCheck2">
            Exit after submit
          </label>
        </div>
        <div>
          <button
            className="btn btn-sm btn-cmpy ml-2"
            style={{ background: "green" }}
            onClick={formSubmit}
          >
            Submit
          </button>
          <button className="btn btn-sm btn-cmpy ml-2" onClick={closeEditPop}>
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}
