import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../../css/Commission.css";
import Spinner from "../../loading/spinner";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { showImgPreview } from "../../hooks/ImagePreview";
import DeletePop from "./DeletePop";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { GetNepaliDate } from "../../hooks/dateConvertor";
import LogBookEdit from "./LogBookEdit";
import LogBookForm from "./LogBookForm";
import LogBookContext from "../../contextfolder/LogBookContext/LogBookContext";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";

export default function LogBookList() {
  const { todayDate, customStylesForImage, AcademicYear } =
    useContext(SidebarContext);
  const {
    loading,
    originalList,
    popup,
    togglePopup,
    setIsSubmit,
    setlogBookFormValue,
    setlogBookFormError,
    classList,

    subjectList,

    sectionList,
    teacherList,
    studentList,
    logBookValue,
    logBookList,
    setLogBookList,
    reportOption,
    setReportOption,
    deletePopup,
    handleDeleteTrue,
    handleDeleteFalse,
    handleDelete,
    selectClass,
    setSelectClass,
    selectTeacher,
    setSelectTeacher,

    selectSubject,
    setSelectSubject,

    selectSection,
    setSelectSection,

    toggleEditPopup,
    setEditIsSubmit,
    setCloseChecked,
    editPop,
    handleEdit,
    todaysDate,
    setTodaysDate,
  } = useContext(LogBookContext);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const [imgPrv, setImgPrv] = useState(false);
  const [imagePre, setImagePre] = useState("");

  const searchInput = useRef("");

  const columns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "Subject",
      width: "150px",
      selector: (row) => row.Subject,
    },
    {
      name: "Lesson Taught",
      minWidth: "200px",
      selector: (row) => row.Lesson,
    },
    {
      name: "Assignment Given",
      minWidth: "200px",
      selector: (row) => row.AssignmentGiven,
    },
    {
      name: "Date",
      width: "190px",
      selector: (row) => GetNepaliDate(row.CreatedDate),
    },

    {
      name: "Action",
      // grow: 0,
      center: true,
      width: "100px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <Tippy content="Edit">
                <i
                  className="fa fa-pencil solvespan--action"
                  onClick={() => handleEdit(row)}
                ></i>
              </Tippy>
              <Tippy content="Delete">
                <i
                  className="fa fa-trash-o rejectspan--action"
                  onClick={() => handleDelete(row)}
                ></i>
              </Tippy>
            </div>
          </>
        );
      },
    },
  ];

  const closePopup = (e) => {
    e.preventDefault();
    togglePopup();
    setlogBookFormValue(logBookValue);
    setlogBookFormError({});
    setCloseChecked(false);
    setIsSubmit(false);
  };

  const closeEditPop = (e) => {
    e.preventDefault();
    toggleEditPopup();
    setlogBookFormError({});
    setEditIsSubmit(false);
    setCloseChecked(false);
  };

  const addnew = (e) => {
    e.preventDefault();
    togglePopup();
    setIsSubmit(false);
    setlogBookFormValue(logBookValue);
  };

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["Title"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setLogBookList(srchResult);
      } else {
        setLogBookList({});
      }
    } else {
      setLogBookList(originalList);
    }
  };

  const handleClass = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectClass(value);
    setSelectTeacher("");
    setSelectSubject("");
    setSelectSection("");
  };

  const handleSubject = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectSubject(value);
  };

  const handleTeacher = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectTeacher(value);
    setSelectSubject("");
  };

  const handleSection = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectSection(value);
    setSelectTeacher("");
    setSelectSubject("");
  };

  const handleTodayDate = ({ adDate }) => {
    setTodaysDate({ ...todaysDate, todayDate: adDate });
  };

  return (
    <>
      {/* <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      /> */}
      <div className="col-lg-12 col-md-12 col-sm-12 ">
        {/* <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">Log Book</div>
            <div className="page-date">
              <div className="sec-content">
                <AcademicHeader/>
              </div>
            </div>
          </div>
        </div>
        <hr /> */}
        <>
          <div className="sec-dataTable ">
            <div className="upper-dataTbl">
              <div className="btn-addlnote">
                <button
                  type="button"
                  class="btn btn-sm"
                  style={{
                    background: "var(--MainColor)",
                    color: "white",
                  }}
                  onClick={addnew}
                >
                  Add New
                </button>
              </div>
            </div>
            <hr className="new-hr-new" />
            {loading ? (
              <Spinner />
            ) : (
              <DataTable
                columns={columns}
                data={logBookList}
                customStyles={customStylesForImage}
                pagination
                fixedHeader
                fixedHeaderScrollHeight="280px"
                highlightOnHover
                pointerOnHover
                responsive
                dense
                striped
                subHeader
                subHeaderAlign="left"
                subHeaderComponent={
                  <>
                    <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                      <label className="list-label">Class</label>
                      <select
                        style={{ fontSize: "11px" }}
                        name="snotifiaction"
                        value={selectClass}
                        onChange={handleClass}
                        className="form-control form-control-sm"
                        aria-label="Default control example "
                      >
                        <option
                          value=""
                          disabled
                          selected
                          style={{ fontSize: "11px" }}
                        >
                          Select Class
                        </option>

                        {classList.map((item) => (
                          <option key={item.ClassID} value={item.ClassID}>
                            {item.ClassName}
                          </option>
                        ))}
                      </select>
                      <i class="fas fa-angle-down  notificationpositon"></i>
                    </div>

                    <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                      <label className="list-label">Section</label>
                      <select
                        style={{ fontSize: "11px" }}
                        name="status"
                        className="form-control form-control-sm"
                        aria-label="Default select example "
                        value={selectSection}
                        onChange={handleSection}
                      >
                        <option
                          selected
                          disabled
                          value=""
                          style={{ fontSize: "11px" }}
                        >
                          Select Option
                        </option>
                        {sectionList.map((item) => (
                          <option key={item.SectionID} value={item.SectionID}>
                            {item.SectionName}
                          </option>
                        ))}
                      </select>
                      {/* )} */}
                      <i className="fas fa-angle-down  notificationpositon"></i>
                    </div>

                    {userinfo.UserType !== "T" && (
                      <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                        <label className="list-label">Teacher</label>
                        <select
                          style={{ fontSize: "11px" }}
                          name="snotifiaction"
                          value={selectTeacher}
                          onChange={handleTeacher}
                          className="form-control form-control-sm"
                          aria-label="Default select example"
                        >
                          <option
                            value=""
                            disabled
                            selected
                            style={{ fontSize: "11px" }}
                          >
                            Select Teacher
                          </option>
                          <option value="-1">Select All</option>
                          {teacherList.map((item) => (
                            <option key={item.UserID} value={item.UserID}>
                              {item.FullName}
                            </option>
                          ))}
                        </select>
                        <i class="fas fa-angle-down  notificationpositon"></i>
                      </div>
                    )}

                    <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                      <label className="list-label">Subject</label>
                      <select
                        style={{ fontSize: "11px" }}
                        name="snotifiaction"
                        value={selectSubject}
                        onChange={handleSubject}
                        className="form-control form-control-sm"
                        aria-label="Default select example"
                      >
                        <option
                          value=""
                          disabled
                          selected
                          style={{ fontSize: "11px" }}
                        >
                          Select Subject
                        </option>

                        <option value="-1">Select All</option>
                        {subjectList.map((item) => (
                          <option key={item.SubjectID} value={item.SubjectID}>
                            {item.SubjectName}
                          </option>
                        ))}
                      </select>
                      <i class="fas fa-angle-down  notificationpositon"></i>
                    </div>

                    <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                      <label className="list-label">Today Date</label>
                      <Calendar
                        className="form-control form-control-sm searchField"
                        dateFormat="YYYY/MM/DD"
                        theme="default"
                        language="en"
                        values={todaysDate}
                        onChange={handleTodayDate}
                      />
                    </div>
                  </>
                }
              />
            )}
          </div>
        </>
      </div>

      {popup && (
        <div className="modals">
          <div className="overlay"></div>

          <div className="notification-model-content">
            <div className="popUpHeader ">
              <div className="popUpHeaderText ">Log Book</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closePopup}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="inside-modal">
              <LogBookForm closePopup={closePopup} />
            </div>
          </div>
        </div>
      )}

      {editPop && (
        <div className="modals">
          <div className="overlay"></div>

          <div className="notification-model-content">
            <div className="popUpHeader ">
              <div className="popUpHeaderText ">Log Book Edit</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closeEditPop}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="inside-modal">
              <LogBookEdit closeEditPop={closeEditPop} />
            </div>
          </div>
        </div>
      )}

      {deletePopup.show && (
        <DeletePop
          handleDeleteTrue={handleDeleteTrue}
          handleDeleteFalse={handleDeleteFalse}
        />
      )}
    </>
  );
}
