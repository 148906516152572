import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";

import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { GetNepaliDate } from "../hooks/dateConvertor";
import JobForm from "./JobForm";
import DesignationContext from "../contextfolder/DesignationContext/DesignationContext";
import JobEditForm from "./JobEditForm";
import Delete from "./Delete";
import EliteJobContext from "../contextfolder/EliteJobContext/EliteJobContext";
import JobActivate from "./JobActivate";
import JobDeactivate from "./JobDeactivate";
import ViewJob from "./ViewJob";
import Candidate from "./Candidate/Candidate";
import CandidateContext from "../contextfolder/CandidateContext/CandidateContext";
import { BsGlobe } from "react-icons/bs";
import { BiLogoPlayStore } from "react-icons/bi";
export default function Job({ handleSubmit }) {
  const { todayDate, customStyles, AcademicYear } = useContext(SidebarContext);
  const {
    setJobFormValue,
    jobvalue,
    popup,
    editPop,
    originalList,

    jobList,
    handleEdit,
    jobStatus,
    setJobStatus,
    changeStatus,
    setJobList,
    togglePopup,
    setJobFormError,
    setIsSubmit,
    setCloseChecked,
    setImage,
    setIsUploaded,
    toggleEditPopup,
    setEditIsSubmit,
    setNegotiable,
    deletePopup,
    handleDeleteTrue,
    handleDeleteFalse,
    handleDelete,

    handleActivate,
    handleInActivate,
    activatePopup,
    inActivatePopup,
    handleActivateTrue,
    handleActivateFalse,
    handleInActivateTrue,
    handleInActivateFalse,
    handleView,
    viewPop,
    toggleViewPopup,
    viewJob,

    setDescription,

    setQualification,

    setResponsibility,
    candidatePop,
    setCandidatePop,
  } = useContext(EliteJobContext);

  const { tableData, setTableData } = useContext(CandidateContext);
  // const { designationTopicList } = useContext(DesignationContext)
  const searchInput = useRef("");
  const closeViewPopup = (e) => {
    e.preventDefault();
    toggleViewPopup();
  };
  const columns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },
    // {
    //   name: "Title",
    //   minWidth: "250px",
    //   selector: (row) => row.Title,
    // },
    {
      name: "Designation",
      width: "200px",
      selector: (row) => row.Designation,
    },
    {
      name: "Job Type",
      selector: (row) => row.JobType,
    },
    // {
    //   name: "Shift",
    //   selector: (row) => row.Shift,
    // },
    {
      name: "No of Position",
      width: "113px",
      selector: (row) => row.NoOfPos,
    },
    {
      name: "Applicant",
      width: "87px",
      selector: (row) => (
        <span
          style={{ color: "var(--MainColor)", textDecoration: "underline" }}
          onClick={() => handleCandidate(row)}
        >
          {row.NoOfApplication}
        </span>
      ),
    },
    {
      name: "No of View",
      width: "94px",
      selector: (row) => row.NoOfView,
    },
    {
      name: "Expiry Date",
      width: "101px",
      selector: (row) => row.EndDate,
    },
    {
      name: "Created Date",
      width: "108px",
      selector: (row) => row.CreatedDate,
    },

    // {
    //   name: "Shift Type",
    //   selector: (row) => row.ShiftType,
    // },

    // {
    //   name: "Interview Date",
    //   width: "120px",
    //   selector: (row) => row.InterviewDate,
    // },
    {
      name: "Status",
      // grow: 0,
      center: true,
      width: "120px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              {row.IsPublished === "I" ? (
                <>
                  <button
                    type="button"
                    class="badge badge-success"
                    onClick={() => handleActivate(row)}
                  >
                    Publish
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    class="badge badge-danger"
                    onClick={() => handleInActivate(row)}
                  >
                    Unpublish
                  </button>
                </>
              )}
            </div>
          </>
        );
      },
    },

    {
      name: "Action",
      center: true,
      width: "100px",
      selector: (row) => {
        return (
          <>
            <>
              <div className="ln-verition d-flex">
                {/* <Tippy content="View">
                  <i
                    className="bi bi-eye  viewspan--action"
                    onClick={() => handleView(row)}
                  ></i>
                </Tippy> */}
                <Tippy content="Edit">
                  <i
                    className="fa fa-pencil solvespan--action"
                    onClick={() => handleEdit(row)}
                  ></i>
                </Tippy>

                <Tippy content="Delete">
                  <i
                    className="fa fa-trash-o rejectspan--action"
                    onClick={() => handleDelete(row.JobID)}
                  ></i>
                </Tippy>
              </div>
            </>
          </>
        );
      },
    },
  ];
  const closePopup = (e) => {
    e.preventDefault();
    togglePopup();
    setJobFormValue(jobvalue);
    setJobFormError({});
    setIsSubmit(false);
    setCloseChecked(false);
    setImage("");
    setIsUploaded(false);
    setNegotiable(false);
  };
  const closeEditPopup = (e) => {
    e.preventDefault();
    toggleEditPopup();
    setJobFormValue(jobvalue);
    setJobFormError({});
    setEditIsSubmit(false);
    setCloseChecked(false);
    setImage("");
    setIsUploaded(false);
    setNegotiable(false);
  };

  const addnew = (e) => {
    e.preventDefault();
    togglePopup();
    setIsSubmit(false);
    setJobFormValue(jobvalue);
    setCloseChecked(false);
    setNegotiable(false);
    setDescription("");
    setQualification("");
    setResponsibility("");
  };

  const handleCandidate = (data) => {
    setCandidatePop(true);
    setTableData(data);
  };

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["Designation"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setJobList(srchResult);
      } else {
        setJobList({});
      }
    } else {
      setJobList(originalList);
    }
  };

  return (
    <>
      <div className="upper-dataTbl uk-flex-middle">
        <div className="btn-addlnote">
          <button
            type="button"
            class="btn btn-sm"
            style={{
              background: "var(--MainColor)",
              color: "white",
            }}
            onClick={addnew}
          >
            Add New
          </button>
        </div>
        <div className="btn-addlnote mx-2">
          <button
            className="btn btn-sm btn-cmpy"
            style={{
              background: "var(--MainColor)",
              color: "white",
            }}
            onClick={handleSubmit}
          >
            Sync Company
          </button>
        </div>
        <div>
          <Tippy content="Visit Website">
            <a href="https://jobs.eliteinfotechnp.com/" target="_blank">
              <BsGlobe color="var(--MainColor)" size="1rem" />
            </a>
          </Tippy>
          <Tippy content="Download App">
            <a
              href="https://play.google.com/store/apps/details?id=com.eliteinfotech.elitejobs"
              target="_blank"
            >
              <BiLogoPlayStore
                style={{ marginLeft: "10px" }}
                color="var(--MainColor)"
                size="1.3rem"
              />
            </a>
          </Tippy>
        </div>
      </div>
      <hr className="new-hr-new" />
      {/* {loading ? (
                            <Spinner />
                        ) : ( */}
      <DataTable
        columns={columns}
        data={jobList}
        customStyles={customStyles}
        pagination
        fixedHeader
        fixedHeaderScrollHeight="280px"
        highlightOnHover
        pointerOnHover
        responsive
        dense
        striped
        subHeader
        subHeaderAlign="left"
        subHeaderComponent={
          <>
            {/* <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                                        <label className="list-label">Select Job Title</label>
                                        <select
                                            style={{ fontSize: "11px" }}
                                            name="status"
                                            className="form-control form-control-sm"
                                            aria-label="Default select example "
                                            value={chooseDesignation}
                                            onChange={handleDesignation}
                                        >
                                            <option selected disabled value="0" style={{ fontSize: "11px" }}>
                                                Job Title
                                            </option>

                                            {designationTopicList.map((item) => (
                                                <option
                                                    key={item.DesignationID}
                                                    value={item.DesignationID}
                                                >
                                                    {item.Designation}
                                                </option>
                                            ))}

                                        </select>
                                        <i class="fas fa-angle-down  notificationpositon"></i>
                                    </div>


                                    <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                                        <label className="list-label">Job Type</label>
                                        <select
                                            style={{ fontSize: "11px" }}
                                            name="snotifiaction"
                                            value={chooseJobType}
                                            onChange={handleJobType}
                                            className="form-control form-control-sm"
                                            aria-label="Default control example "
                                        >
                                            <option
                                                value=""
                                                disabled
                                                selected
                                                style={{ fontSize: "11px" }}
                                            >
                                                Select Job Type
                                            </option>
                                            <option value="Full Time">
                                                Full Time
                                            </option>
                                            <option value="Part Time">
                                                Part Time
                                            </option>
                                            <option value="Intern">
                                                Intern
                                            </option>
                                            <option value="Paid Intern">
                                                Paid Intern
                                            </option>
                                            <option value="Freelance">
                                                Freelance
                                            </option>
                                            <option value="Contract">
                                                Contract
                                            </option>
                                            <option value="Training">
                                                Training
                                            </option>
                                        </select>
                                        <i class="fas fa-angle-down  notificationpositon"></i>
                                    </div> */}

            <div
              className="col-lg-2 offset-lg-10 col-md-2 offset-md-10 col-sm-2 align-self-end Search"
              style={{ padding: "inherit", marginBottom: "10px" }}
            >
              <div className="d-flex">
                <input
                  placeholder="Search"
                  ref={searchInput}
                  type="text"
                  className="form-control form-control-sm searchField"
                  onChange={searchHandler}
                />
              </div>
            </div>
          </>
        }
      />

      {popup && (
        <div className="modals">
          <div className="overlay"></div>

          <div
            className="notification-model-content"
            style={{ width: "90%", maxWidth: "900px", height: "88vh" }}
          >
            <div className="popUpHeader ">
              <div className="popUpHeaderText ">Job</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closePopup}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="inside-modal" style={{ height: "73vh" }}>
              <JobForm closePopup={closePopup} />
            </div>
          </div>
        </div>
      )}
      {editPop && (
        <div className="modals">
          <div className="overlay"></div>

          <div
            className="notification-model-content"
            style={{ width: "90%", maxWidth: "900px", height: "88vh" }}
          >
            <div className="popUpHeader ">
              <div className="popUpHeaderText ">Job Edit</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closeEditPopup}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="inside-modal" style={{ height: "73vh" }}>
              <JobEditForm closePopup={closeEditPopup} />
            </div>
          </div>
        </div>
      )}

      {deletePopup.show && (
        <Delete
          handleDeleteTrue={handleDeleteTrue}
          handleDeleteFalse={handleDeleteFalse}
        />
      )}

      {activatePopup.show && (
        <JobActivate
          handleActivateTrue={handleActivateTrue}
          handleActivateFalse={handleActivateFalse}
        />
      )}
      {inActivatePopup.show && (
        <JobDeactivate
          handleInActivateTrue={handleInActivateTrue}
          handleInActivateFalse={handleInActivateFalse}
        />
      )}

      {/* {viewPop && (
        <div className="modals">
          <div className="overlay"></div>

          <div className="notification-model-content">
            <div className="popUpHeader ">
              <div className="popUpHeaderText ">View Admission</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closeViewPopup}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="inside-modal">
              <ViewJob
                viewAdmission={viewJob}
                closeViewPopup={closeViewPopup}
              />
            </div>
          </div>
        </div>
      )} */}

      {candidatePop && (
        <div className="modals">
          <div className="overlay"></div>

          <div
            className="notification-model-content"
            style={{ width: "90%", maxWidth: "900px" }}
          >
            <div className="popUpHeader ">
              <div className="popUpHeaderText ">Candidate</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={() => setCandidatePop(false)}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="inside-modal">
              <Candidate />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
