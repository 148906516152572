import React, { useContext, useRef } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "jspdf-autotable";
import CreateOrder from "./CreateOrder";
import StudentMenuContext from "../contextfolder/StudentMenuContext/StudentMenuContext";
import DeletePop from "./DeletePop";
import ViewOrderInfo from "./ViewOrderInfo";
import AcademicHeader from "../AcademicHeader";

export default function StudentOrder() {
  const { todayDate, customStyles, AcademicYear } = useContext(SidebarContext);
  const {
    ordLoading,
    ordOriginalList,
    modal,
    toggleModal,
    setOrdIsSubmit,
    setOrderFormValue,
    setOrderFormError,
    orderValue,
    setOrdCloseChecked,
    setCartItems,
    choosePayment,
    setChoosePayment,
    chooseOrder,
    setChooseOrder,
    itemOrderList,
    setItemOrderList,
    deletePopup,
    handleDeleteTrue,
    handleDeleteFalse,
    handleDelete,
    setActiveTab,
    popup,
    togglePopup,
    handleInfo,
    setInfoID,
  } = useContext(StudentMenuContext);

  const handlePayment = (e) => {
    const target = e.target;
    const value = target.value;
    setChoosePayment(value);
  };

  const handleChooseOrder = (e) => {
    const target = e.target;
    const value = target.value;
    setChooseOrder(value);
  };

  const searchInput = useRef("");

  const columns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "OrderID",
      width: "110px",
      selector: (row) => row.OrderID,
    },
    {
      name: "Date",
      selector: (row) => row.OrderDate,
    },
    {
      name: "No of Item",
      width: "100px",
      selector: (row) => row.NoOfItem,
    },
    {
      name: "Amount",
      width: "100px",
      selector: (row) => row.Amount,
    },
    {
      name: "Order Status",
      width: "110px",
      selector: (row) => row.OrderStatus,
    },
    {
      name: "Payment Status",
      width: "130px",
      selector: (row) => row.PaymentStatus,
    },

    {
      name: "Action",
      // grow: 0,
      center: true,
      width: "79px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <Tippy content="View Order Info">
                <i
                  className="bi bi-eye viewspan--action"
                  onClick={() => handleInfo(row.OrderID)}
                ></i>
              </Tippy>

              {row.PaymentStatus === "Pending" && (
                <Tippy content="Delete">
                  <i
                    className="fa fa-trash-o rejectspan--action"
                    onClick={() => handleDelete(row.OrderID)}
                  ></i>
                </Tippy>
              )}
            </div>
          </>
        );
      },
    },
  ];

  const closePopup = (e) => {
    e.preventDefault();
    toggleModal();
    setOrderFormValue(orderValue);
    setOrderFormError({});
    setOrdIsSubmit(false);
    setOrdCloseChecked(false);
    setCartItems([]);
  };

  const closeOrder = (e) => {
    e.preventDefault();
    togglePopup();
    setInfoID("");
  };

  const addnew = (e) => {
    e.preventDefault();
    toggleModal();
    setOrdIsSubmit(false);
    setOrderFormValue(orderValue);
    setActiveTab(1);
  };

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = ordOriginalList.filter((list) => {
        return list["OrderID"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setItemOrderList(srchResult);
      } else {
        setItemOrderList({});
      }
    } else {
      setItemOrderList(ordOriginalList);
    }
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
        <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">Order</div>
            <div className="page-date">
              <div className="sec-content">
                <AcademicHeader
                  todayDate={todayDate}
                  AcademicYear={AcademicYear}
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <>
          <div className="sec-dataTable content-section main-content">
            <div className="upper-dataTbl">
              <div className="btn-addlnote">
                <button
                  type="button"
                  class="btn btn-sm"
                  style={{
                    background: "var(--MainColor)",
                    color: "white",
                  }}
                  onClick={addnew}
                >
                  Add New
                </button>
                {/* <div className="exportDiv">
                                    <Tippy content="Excel">
                                        <button className="exportButtons mx-2" 
                                        // onClick={toExcel}
                                        >
                                            <i className="bi bi-file-earmark-excel-fill exportButtonIconsExcel"></i>
                                        </button>
                                    </Tippy>

                                    <Tippy content="PDF">
                                        <button className="exportButtons "
                                            // onClick={toPdf}
                                        >
                                            <i className="bi bi-file-earmark-pdf-fill exportButtonIconsPdf"></i>
                                        </button>
                                    </Tippy>

                                </div> */}
              </div>
            </div>
            <hr className="new-hr" />
            {ordLoading ? (
              <Spinner />
            ) : (
              <DataTable
                columns={columns}
                data={itemOrderList}
                customStyles={customStyles}
                pagination
                fixedHeader
                fixedHeaderScrollHeight="280px"
                highlightOnHover
                pointerOnHover
                responsive
                dense
                striped
                subHeader
                subHeaderAlign="left"
                subHeaderComponent={
                  <>
                    <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                      <label className="list-label">Order Status</label>
                      <select
                        style={{ fontSize: "11px" }}
                        name="snotifiaction"
                        value={chooseOrder}
                        onChange={handleChooseOrder}
                        className="form-control form-control-sm"
                        aria-label="Default select example"
                      >
                        <option
                          value=""
                          disabled
                          selected
                          style={{ fontSize: "11px" }}
                        >
                          Select Order Status
                        </option>
                        <option value="-1">All</option>
                        <option value="P">Pending</option>
                        <option value="D">Delivered</option>
                        <option value="C">Canceled</option>
                      </select>
                      <i class="fas fa-angle-down  notificationpositon"></i>
                    </div>

                    <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                      <label className="list-label">Payment Status</label>
                      <select
                        style={{ fontSize: "11px" }}
                        name="snotifiaction"
                        value={choosePayment}
                        onChange={handlePayment}
                        className="form-control form-control-sm"
                        aria-label="Default control example "
                      >
                        <option
                          value=""
                          disabled
                          selected
                          style={{ fontSize: "11px" }}
                        >
                          Select Payment Status
                        </option>
                        <option value="-1">All</option>
                        <option value="Y">Paid</option>
                        <option value="N">Pending</option>
                      </select>
                      <i class="fas fa-angle-down  notificationpositon"></i>
                    </div>

                    <div
                      className="col-lg-2 offset-lg-6 col-md-2 offset-md-6 col-sm-2 align-self-end Search"
                      style={{
                        marginTop: "25px",
                        padding: "inherit",
                        marginBottom: "10px",
                      }}
                    >
                      <div className="d-flex">
                        <input
                          placeholder="Search"
                          ref={searchInput}
                          type="text"
                          className="form-control form-control-sm searchField"
                          onChange={searchHandler}
                        />
                      </div>
                    </div>
                  </>
                }
              />
            )}
          </div>
        </>
      </div>

      {modal && (
        <div className="modals">
          <div className="overlay"></div>

          <div className="order-model-content">
            <div className="popUpHeader ">
              <div className="popUpHeaderText ">Add Order</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closePopup}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="inside-order-modal">
              <CreateOrder closePopup={closePopup} />
            </div>
          </div>
        </div>
      )}

      {popup && (
        <div className="modals">
          <div className="overlay">
            <div className="notification-model-content">
              <div className="popUpHeader ">
                <div className="popUpHeaderText ">Order Info</div>
                <div style={{ cursor: "pointer" }}>
                  <i
                    className="bi bi-x "
                    onClick={closeOrder}
                    style={{ fontSize: "25px" }}
                  ></i>
                </div>
              </div>
              <div className="inside-modal">
                <ViewOrderInfo closeOrder={closeOrder} />
              </div>
            </div>
          </div>
        </div>
      )}

      {deletePopup.show && (
        <DeletePop
          handleDeleteTrue={handleDeleteTrue}
          handleDeleteFalse={handleDeleteFalse}
        />
      )}
    </>
  );
}
