import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { GetNepaliDate } from "../hooks/dateConvertor";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import StudentLogBookContext from "../contextfolder/StudentLogBookContext/StudentLogBookContext";
import ViewInfo from "./ViewInfo";
import AcademicHeader from "../AcademicHeader";

export default function StudentLogBookList() {
  const { todayDate, customStylesForImage, AcademicYear } =
    useContext(SidebarContext);
  const {
    loading,
    originalList,
    popup,
    togglePopup,
    logBookList,
    setLogBookList,
    todaysDate,
    setTodaysDate,
    handleView,
    setInfoID,
  } = useContext(StudentLogBookContext);

  const closePopup = (e) => {
    e.preventDefault();
    togglePopup();
    setInfoID("");
  };

  const searchInput = useRef("");

  const columns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "Subject",
      width: "150px",
      selector: (row) => row.Subject,
    },
    {
      name: "Lesson Taught",
      minWidth: "200px",
      selector: (row) => row.Lesson,
    },
    {
      name: "Assignment Given",
      minWidth: "200px",
      selector: (row) => row.AssignmentGiven,
    },
    {
      name: "Date",
      width: "190px",
      selector: (row) => GetNepaliDate(row.CreatedDate),
    },

    {
      name: "Action",
      // grow: 0,
      center: true,
      width: "100px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <Tippy content="View">
                <i
                  className="bi bi-eye viewspan--action"
                  onClick={() => handleView(row)}
                ></i>
              </Tippy>
            </div>
          </>
        );
      },
    },
  ];

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["Title"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setLogBookList(srchResult);
      } else {
        setLogBookList({});
      }
    } else {
      setLogBookList(originalList);
    }
  };

  const handleTodayDate = ({ adDate }) => {
    setTodaysDate({ ...todaysDate, todayDate: adDate });
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
        <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">Log Book</div>
            <div className="page-date">
              <div className="sec-content">
                <AcademicHeader
                  todayDate={todayDate}
                  AcademicYear={AcademicYear}
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <>
          <div className="sec-dataTable content-section main-content">
            {loading ? (
              <Spinner />
            ) : (
              <DataTable
                columns={columns}
                data={logBookList}
                customStyles={customStylesForImage}
                pagination
                fixedHeader
                fixedHeaderScrollHeight="280px"
                highlightOnHover
                pointerOnHover
                responsive
                dense
                striped
                subHeader
                subHeaderAlign="left"
                subHeaderComponent={
                  <>
                    <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                      <label className="list-label">Today Date</label>
                      <Calendar
                        className="form-control form-control-sm searchField"
                        dateFormat="YYYY/MM/DD"
                        theme="default"
                        language="en"
                        values={todaysDate}
                        onChange={handleTodayDate}
                      />
                    </div>
                  </>
                }
              />
            )}
          </div>
        </>
      </div>

      {popup && (
        <div className="modals">
          <div className="overlay"></div>

          <div className="notification-model-content">
            <div className="popUpHeader ">
              <div className="popUpHeaderText ">View Log Book</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closePopup}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="inside-modal">
              <ViewInfo closePopup={closePopup} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
