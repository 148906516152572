import React, { useContext, useRef } from "react";
import DataTable from "react-data-table-component";

import '../../css/Commission.css';

import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import StudentContext from "../contextfolder/StudentContext/StudentContext";



export default function ReportTab() {
    const { customStyles } = useContext(SidebarContext);
    const { reportList, setReportList, reportSearch, reportLoad } = useContext(StudentContext);


    const searchInput = useRef("");

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    const columns = [
        {
            name: "S.N.",
            width: "63px",
            center: true,
            cell: (row, index) => index + 1,
        },
        {
            name: "Terminal",

            selector: (row) => row.TermName,
        },
        // {
        //     name: "Status",
        //     // grow: 0,
        //     center: true,
        //     selector: (row) => row.IsAllow === "Y" ? "Allowed" : "Disallowed",
        // },
        {
            name: "Action",
            center: true,
            width: "150px",
            selector: (row) => {
                return (
                    <>
                        <div className="ln-verition d-flex">


                            <Tippy content="View">
                                <i
                                    className="bi bi-eye  solvespan--action" style={{ paddingTop: "3px" }}
                                    onClick={() => openInNewTab(row.ReportUrl)}
                                >
                                </i>
                            </Tippy>
                        </div>
                    </>
                );
            },
        },

    ];






    const searchHandler = (e) => {
        e.preventDefault();

        const srchQuery = searchInput.current.value.toLowerCase();
        if (srchQuery) {

            let srchResult = reportSearch.filter((list) => {
                return list["TermName"].toLowerCase().includes(srchQuery);
            });

            if (srchResult) {

                setReportList(srchResult);
            } else {
                setReportList({});
            }
        } else {
            setReportList(reportSearch);
        }
    };





    return (


        <div className="sec-dataTable content-section main-content">

            {reportLoad ? (
                <Spinner />
            ) : (
                <DataTable
                    columns={columns}
                    data={reportList}
                    customStyles={customStyles}
                    pagination
                    fixedHeader
                    fixedHeaderScrollHeight="280px"
                    highlightOnHover
                    pointerOnHover
                    responsive
                    dense
                    striped
                    subHeader
                    subHeaderAlign="left"
                    subHeaderComponent={
                        <>


                            <div className="col-lg-3 offset-lg-9 offset-md-9 offset-sm-9 col-md-3 col-sm-3 align-self-end Search" style={{ paddingRight: "1px", marginBottom: "10px" }}>
                                <div className="d-flex">
                                    <input
                                        placeholder="Search"
                                        ref={searchInput}
                                        type="text"
                                        className="form-control form-control-sm searchField"
                                        onChange={searchHandler}
                                    />
                                </div>
                            </div>
                        </>
                    }
                />
            )}
        </div>

    );
}
