import React, { useContext, useEffect, useState } from "react";
import "../../css/Dashboard.css";
import SidebarContext from "../sidebarcontext/SidebarContext";
import { Fetchdata } from "../hooks/getData";
import { Link } from "react-router-dom";
import EventContext from "../contextfolder/EventContext/EventContext";
import { showImgPreview } from "../hooks/ImagePreview";
import { GetNepaliDate } from "../hooks/dateConvertor";
import AcademicHeader from "../AcademicHeader";

const StudentDashboard = () => {
  const { todayDate, appURL } = useContext(SidebarContext);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const UserImage = userinfo.UserImage;
  const FullName = userinfo.FullName;
  const ClassName = userinfo.ClassName;
  const RollNo = userinfo.RollNo;
  const SectionName = userinfo.SectionName;
  const AcademicYear = userinfo.AcademicYear;
  const SchName = userinfo.SchName;

  const [loading, setLoading] = useState(true);

  const [dashboardList, setDashboardList] = useState([]);

  const [imgPrv, setImgPrv] = useState(false);
  const [imagePre, setImagePre] = useState("");

  //API to hit Dashboard Admin list

  useEffect(() => {
    getDashboardList();
  }, []);

  const getDashboardList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      DFlag: "DS",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst[0] ? result.referencelst[0] : "";

        setLoading(false);
        setDashboardList(postResult);
      } else {
        setLoading(false);
        setDashboardList([]);
      }
    });
  };

  // API for personal notification list
  const [notificationList, setNotificationList] = useState([]);

  useEffect(() => {
    getPersonalNotificationList();
  }, []);

  const getPersonalNotificationList = () => {
    const params = {
      SchID: userinfo.SchID,
      Flag: "SP",
      ClassID: userinfo.ClassID.toString(),
      SectionID: userinfo.SectionID.toString(),
      MemID: userinfo.UserID.toString(),
      UserType: userinfo.UserType.toString(),
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/notification`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.NotificationLst ? result.NotificationLst : "";
        setLoading(false);
        setNotificationList(postResult);
      } else {
        setLoading(false);
        setNotificationList([]);
      }
    });
  };

  // API for genral notification list
  const [generalList, setGeneralList] = useState([]);

  useEffect(() => {
    getGeneralNotificationList();
  }, []);

  const getGeneralNotificationList = () => {
    const params = {
      SchID: userinfo.SchID,
      Flag: "SG",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/notification`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.NotificationLst ? result.NotificationLst : "";
        setLoading(false);
        setGeneralList(postResult);
      } else {
        setLoading(false);
        setGeneralList([]);
      }
    });
  };

  // API for upcoming event list
  const [eventList, setEventList] = useState([]);

  useEffect(() => {
    getEventList();
  }, []);

  const getEventList = () => {
    const params = {
      SchID: userinfo.SchID,
      Count: "-1",

      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/upcoming-event`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.CalendarEventLst
          ? result.CalendarEventLst
          : "";
        setLoading(false);
        setEventList(postResult);
      } else {
        setLoading(false);
        setEventList([]);
      }
    });
  };

  return (
    <>
      <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
        <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">Dashboard</div>
            <div className="page-date">
              <div className="sec-content">
                <AcademicHeader
                  todayDate={todayDate}
                  AcademicYear={AcademicYear}
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        {/* <section className="content-section main-content"> */}
        <section style={{ paddingLeft: "10px", paddingRight: "10px" }}>
          <div className="dashboard--container" style={{ borderRadius: "5px" }}>
            <div className="row">
              <div className="col-sm-4 dashboard--about">
                <div className="middle--heading">
                  <h6 className="h6">Student Profile</h6>
                  <div className="middle--about">
                    <div>
                      <div>
                        <img
                          className="dashboard--logo"
                          src={UserImage}
                          alt=""
                        />{" "}
                      </div>
                    </div>
                    <div className="dashboard--info">
                      <p>{FullName}</p>{" "}
                      <p className="dashboard--address">
                        Class : {ClassName} &nbsp; Section: {SectionName} <br />{" "}
                        Roll No: {RollNo} <br />
                        {SchName}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-4 dashboard--about">
                <div className="middle--heading">
                  <h6 className="h6">Assignment</h6>
                  <div className="middle--first">
                    <div>
                      <p>
                        {dashboardList.AssignmentCt === null
                          ? "0"
                          : dashboardList.AssignmentCt}
                      </p>
                    </div>
                    <div>
                      <Link to="#">
                        {" "}
                        <i
                          className="bi bi-book-half dash--icon"
                          aria-hidden="true"
                        ></i>{" "}
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="middle--heading">
                  <h6 className="h6">Appreciation</h6>
                  <div className="middle--first">
                    <div>
                      <p>
                        {dashboardList.AppreciationCt === null
                          ? "0"
                          : dashboardList.AppreciationCt}
                      </p>
                    </div>
                    <div>
                      <Link to="#">
                        {" "}
                        <i
                          className="bi bi-star-fill dash--icon"
                          aria-hidden="true"
                        ></i>{" "}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-4 dashboard--about">
                <div className="middle--heading">
                  <h6 className="h6">Leave Note</h6>
                  <div className="middle--first">
                    <div>
                      <p>
                        {dashboardList.LeaveCt === null
                          ? "0"
                          : dashboardList.LeaveCt}
                      </p>
                    </div>

                    <div>
                      <Link to="#">
                        {" "}
                        <i
                          className="bi bi-journals dash--icon"
                          aria-hidden="true"
                        ></i>{" "}
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="middle--heading">
                  <h6 className="h6">Notification</h6>
                  <div className="middle--first">
                    <div>
                      <p>
                        {dashboardList.NotificationCt === null
                          ? "0"
                          : dashboardList.NotificationCt}
                      </p>
                    </div>
                    <div>
                      <Link to="/student-notification">
                        <i className="fa fa-bell dash--icon" aria-hidden="true">
                          {" "}
                        </i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-4 dashboard--about">
                <div className="personal-notice-detail">
                  <h6 className="h6">Personal Notification</h6>

                  <div className="personal-notice-about">
                    {notificationList.length > 0 ? (
                      notificationList.map((item, i) => (
                        <>
                          <div className="personal-notice-about-inner" key={i}>
                            {item.Attachment === null ||
                            item.Attachment === "" ? (
                              <></>
                            ) : (
                              <div>
                                <div>
                                  <img
                                    className="personal-notice-icon"
                                    src={item.Attachment}
                                    alt=""
                                    onClick={() => {
                                      setImagePre(item.Attachment);
                                      setImgPrv(true);
                                    }}
                                  />{" "}
                                </div>
                              </div>
                            )}
                            <div className="personal-notice-info">
                              <p>{item.Title}</p>{" "}
                              <p className="dashboard--address">
                                {GetNepaliDate(
                                  item.NotificationDate.split(" ")[0]
                                )}{" "}
                                <br /> {item.Description} <br />
                              </p>
                            </div>
                          </div>
                          <hr className="personal-notice-hr" />
                        </>
                      ))
                    ) : (
                      <div className="text-center mt-4">
                        <p>There are no records to display.</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-sm-4 dashboard--about">
                <div className="personal-notice-detail">
                  <h6 className="h6">General Notification</h6>

                  <div className="personal-notice-about">
                    {generalList.length > 0 ? (
                      generalList.map((item, i) => (
                        <>
                          <div className="personal-notice-about-inner" key={i}>
                            {item.Attachment === null ||
                            item.Attachment === "" ? (
                              <></>
                            ) : (
                              <div>
                                <div>
                                  <img
                                    className="personal-notice-icon"
                                    src={item.Attachment}
                                    alt=""
                                    onClick={() => {
                                      setImagePre(item.Attachment);
                                      setImgPrv(true);
                                    }}
                                  />{" "}
                                </div>
                              </div>
                            )}
                            <div className="personal-notice-info">
                              <p>{item.Title}</p>{" "}
                              <p className="dashboard--address">
                                {GetNepaliDate(
                                  item.NotificationDate.split(" ")[0]
                                )}{" "}
                                <br /> {item.Description} <br />
                              </p>
                            </div>
                          </div>
                          <hr className="personal-notice-hr" />
                        </>
                      ))
                    ) : (
                      <div className="text-center mt-4">
                        <p>There are no records to display.</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-sm-4 dashboard--about">
                <div className="personal-notice-detail">
                  <h6 className="h6">Upcoming Event</h6>

                  <div className="personal-notice-about">
                    {eventList.length > 0 ? (
                      eventList.map((item, i) => (
                        <>
                          <div className="personal-notice-about-inner" key={i}>
                            {item.Banner === null || item.Banner === "" ? (
                              <></>
                            ) : (
                              <div>
                                <div>
                                  <img
                                    className="personal-notice-icon"
                                    src={item.Banner}
                                    alt=""
                                    onClick={() => {
                                      setImagePre(item.Banner);
                                      setImgPrv(true);
                                    }}
                                  />{" "}
                                </div>
                              </div>
                            )}
                            <div className="personal-notice-info">
                              <p>{item.Title}</p>{" "}
                              <p className="dashboard--address">
                                {GetNepaliDate(item.EnglishDate)}
                                <br /> {item.Venue} <br />
                              </p>
                            </div>
                          </div>
                          <hr className="personal-notice-hr" />
                        </>
                      ))
                    ) : (
                      <div className="text-center mt-4">
                        <p>There are no records to display.</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {imgPrv &&
        showImgPreview({
          img: imagePre,
          setTrigger: setImgPrv,
        })}
    </>
  );
};

export default StudentDashboard;
