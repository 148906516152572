import React, { useContext, useState, useEffect } from 'react'
import AdminLeaveContext from '../contextfolder/AdminLeaveContext/AdminLeaveContext';
import { GetNepaliDate } from '../hooks/dateConvertor';
import Spinner from '../loading/spinner';



export default function ViewAdminLeave({ closePopup }) {

    const context = useContext(AdminLeaveContext)

    const { toggleModal, remarksFormValue, setRemarksFormValue, remarksIsSubmit, setRemarksIsSubmit, pushNotice, setPushNotice, closeChecked, setCloseChecked, viewdata, state, } = context;

    const handleOnChange = () => {
        setCloseChecked(!closeChecked);
    };

    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;
        setRemarksFormValue({ ...remarksFormValue, [name]: value });
    };


    const [newState, setNewState] = useState(false)
    const formSubmit = (e) => {
        e.preventDefault();
        setRemarksIsSubmit(true);
        setNewState(true);
    }

    useEffect(() => {
        if (remarksIsSubmit && newState) {
            viewdata(remarksFormValue)
            if (closeChecked === true) {
                toggleModal();
            }
            setRemarksIsSubmit(false);
            setCloseChecked(false)

        }

    }, [remarksIsSubmit]);



    const handlePushNotification = () => {
        setPushNotice(!pushNotice)
    }



    return (
        <>

            <div className="container-fluid basicform" >

                <div>


                    {/* <div className="outer-wrapper"> */}
                    <table className="complain-table" style={{ width: "100%" }}>

                        <thead>
                            <tr className='tableHead '>
                                <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", position: "relative" }}>Date</td>
                                <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", position: "relative" }}>Name</td>
                                <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", position: "relative" }}>From Date</td>
                                <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", position: "relative" }}>To Date</td>
                            </tr>
                        </thead>
                        <tbody>




                            {state &&
                                <tr>
                                    <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", paddingTop: "0px" }} >{GetNepaliDate((state.CreatedDate).split(" ")[0])}</td>
                                    <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", paddingTop: "0px" }}>{state.Name}</td>
                                    <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", paddingTop: "0px" }}>{GetNepaliDate(state.From
                                    )}</td>
                                    <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", paddingTop: "0px" }}>

                                        {GetNepaliDate(state.To)}
                                    </td>
                                </tr>
                            }


                        </tbody>

                    </table>
                    {/* </div> */}
                </div>
                {state &&
                    <div >
                        <div style={{ padding: "0px 5px" }} >
                            <h4 style={{ fontSize: "12px", fontWeight: "bold" }}> Title </h4>
                            <p style={{ fontSize: "12px", color: "#212529" }}>{state.Title}</p>
                        </div>
                    </div>
                }
                {state &&
                    <div >
                        <div style={{ padding: "0px 5px" }} >
                            <h4 style={{ fontSize: "12px", fontWeight: "bold" }}> Description </h4>
                            <p style={{ fontSize: "12px", color: "#212529" }}>{state.Description}</p>
                        </div>
                    </div>
                }

                <form >
                    <div className="form-group">
                        <div>
                            <label className="form-label" htmlFor="purpose">Status</label>
                        </div>
                        <div>
                            {state.IsApprovedID === "Y" ? (
                                <span
                                    className="badge badge-success"

                                >
                                    {state.IsApproved}
                                </span>
                            ) : (
                                <span
                                    className="badge badge-danger"

                                >
                                    {state.IsApproved}
                                </span>
                            )}
                        </div>
                    </div>

                    {state.IsApprovedID !== "P" && (
                        <div className="form-group  ">
                            <label htmlFor="exampleFormControlTextarea1" className="form-label">Remarks</label>
                            <p style={{ fontSize: "12px", color: "#212529" }}>{state.ApprovedRemarks}</p>

                        </div>
                    )}

                </form>


            </div>
            <div className="FormFooter">
                <div className='checkedClose'>

                </div>
                <div>

                    <button className='btn btn-sm btn-cmpy ml-2' onClick={closePopup}>Cancel</button>
                </div>
            </div>

        </>

    )
}
