import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";
import Select from "react-select";
import AssignmentContext from "../contextfolder/AssignmentContext/AssignmentContext";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

export default function BulkCheckAssignment({ closeCheckPopup }) {
  const { customStyles } = useContext(SidebarContext);
  const {
    loading,
    bulkCheckAssignmentList,
    setBulkCheckAssignmentList,
    bulkCheckOriginalList,
    bulkSubmit,
    closeBulkChecked,
    setCloseBulkChecked,
    toggleBulkCheckPopup,
    inputFields,
    setInputFields,
    isBulkSubmit,
    setIsBulkSubmit,
  } = useContext(AssignmentContext);

  const searchInput = useRef("");

  const columns = [
    {
      name: "S.N.",
      width: "58px",
      cell: (row, index) => index + 1,
    },
    {
      name: "Full Name",
      center: true,
      selector: (row) => row.FullName,
    },
    {
      name: "Point",
      // grow: 0,
      center: true,
      width: "80px",
      selector: (row) => row.Point,
    },
    {
      name: "Submitted Date",
      // grow: 0,
      width: "130px",
      center: true,
      selector: (row) => row.SubDate,
    },
    {
      name: "Status",
      width: "110px",
      center: true,
      selector: (row) => row.MemStatus,
    },

    {
      name: "Action",
      // grow: 0,
      center: true,
      width: "200px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              {row.MemStatusID === "A" ? (
                <label>
                  Approve
                  <input
                    type="radio"
                    name={`check-${row.MemID}`}
                    value="A"
                    checked={
                      inputFields.find(
                        (attendance) => attendance.MemID === row.MemID
                      )?.MemStatus === "A"
                    }
                    onChange={handleAssignmentChange(row.MemID)}
                  />
                </label>
              ) : row.MemStatusID === "R" ? (
                <label>
                  Reject
                  <input
                    type="radio"
                    name={`check-${row.MemID}`}
                    value="R"
                    checked={
                      inputFields.find(
                        (attendance) => attendance.MemID === row.MemID
                      )?.MemStatus === "R"
                    }
                    onChange={handleAssignmentChange(row.MemID)}
                  />
                </label>
              ) : (
                <>
                  <label>
                    Approve
                    <input
                      type="radio"
                      name={`check-${row.MemID}`}
                      value="A"
                      checked={
                        inputFields.find(
                          (attendance) => attendance.MemID === row.MemID
                        )?.MemStatus === "P" || "A"
                      }
                      onChange={handleAssignmentChange(row.MemID)}
                    />
                  </label>
                  |
                  <label>
                    Reject
                    <input
                      type="radio"
                      name={`check-${row.MemID}`}
                      value="R"
                      checked={
                        inputFields.find(
                          (attendance) => attendance.MemID === row.MemID
                        )?.MemStatus === "R"
                      }
                      onChange={handleAssignmentChange(row.MemID)}
                    />
                  </label>
                </>
              )}
            </div>
          </>
        );
      },
    },
  ];

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = bulkCheckOriginalList.filter((list) => {
        return list["FullName"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setBulkCheckAssignmentList(srchResult);
      } else {
        setBulkCheckAssignmentList({});
      }
    } else {
      setBulkCheckAssignmentList(bulkCheckOriginalList);
    }
  };

  const handleOnChange = () => {
    setCloseBulkChecked(!closeBulkChecked);
  };

  useEffect(() => {
    const assignment = bulkCheckAssignmentList.map((student) => ({
      MemID: student.MemID,
      MemStatus: student.MemStatusID === "P" ? "A" : student.MemStatusID,
    }));
    setInputFields(assignment);
  }, [bulkCheckAssignmentList]);

  const handleAssignmentChange = (studentID) => (e) => {
    const newAssignmentList = inputFields.map((assignment) =>
      assignment.MemID === studentID
        ? {
          MemID: studentID,
          MemStatus: e.target.value,
        }
        : assignment
    );
    setInputFields(newAssignmentList);
  };


  const formSubmit = (e) => {
    e.preventDefault();

    const isChecked = inputFields.some(
      (attendance) => attendance.MemStatus === "P"
    );

    if (isChecked) {
      setIsBulkSubmit(false);
      toast("Please check all field", {
        style: {
          color: "red",
          fontSize: "13px",
        },
      });
      return;
    } else {
      setIsBulkSubmit(true);
    }
  };
  useEffect(() => {
    if (isBulkSubmit) {
      bulkSubmit();
      if (closeBulkChecked === true) {
        toggleBulkCheckPopup();
      }

      setInputFields([]);
      setIsBulkSubmit(false);
    }
  }, [isBulkSubmit]);

  return (
    <>
      <div className="sec-dataTable" style={{ margin: "0px 10px 10px 10px" }}>
        {loading ? (
          <Spinner />
        ) : (
          <DataTable
            columns={columns}
            data={bulkCheckAssignmentList}
            customStyles={customStyles}
            pagination
            fixedHeader
            fixedHeaderScrollHeight="340px"
            highlightOnHover
            pointerOnHover
            responsive
            dense
            striped
            subHeader
            subHeaderAlign="right"
            subHeaderComponent={
              <>
                <div>
                  <div>
                    <input
                      placeholder="Search"
                      ref={searchInput}
                      type="text"
                      className="form-control form-control-sm searchField"
                      onChange={searchHandler}
                    />
                  </div>
                </div>
              </>
            }
          />
        )}
      </div>
      <div className="FormFooter">
        <div className="checkedClose">
          <input
            type="checkbox"
            className="form-check-input"
            id="exampleCheck2"
            name="closeChecked"
            onChange={handleOnChange}
            checked={closeBulkChecked}
          />
          <label class="form-check-label" for="exampleCheck2">
            Exit after submit
          </label>
        </div>
        <div>
          <button
            className="btn btn-sm btn-cmpy ml-2"
            style={{ background: "green" }}
            onClick={formSubmit}
          >
            Submit
          </button>
          <button
            className="btn btn-sm btn-cmpy ml-2"
            onClick={closeCheckPopup}
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}
