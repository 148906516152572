import React, { useContext, useEffect, useState } from "react";
import SidebarContext from "../sidebarcontext/SidebarContext";
import { Fetchdata } from "../hooks/getData";
import ChapterContext from "../contextfolder/ChapterContext/ChapterContext";

export default function ChapterEdit({ closeEditPopup }) {
  const { appURL } = useContext(SidebarContext);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const context = useContext(ChapterContext);

  const {
    toggleEditPopup,
    chapterFormError,
    setChapterFormError,
    editIsSubmit,
    setEditIsSubmit,
    chapterValue,
    chapterFormValue,
    setChapterFormValue,
    fetchdata,
    closeChecked,
    setCloseChecked,
    inputFields,
    setInputFields,
    setNewData,
    editdata,
  } = context;

  const [error, setError] = useState("");

  const handleOnChange = () => {
    setCloseChecked(!closeChecked);
  };

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setChapterFormValue({ ...chapterFormValue, [name]: value });
  };

  /* for dynamic input fields */
  const handleAddFields = () => {
    const values = [...inputFields];
    values.push({ value: "", id: values.length + 1 });
    setInputFields(values);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
  };

  const handleInputChange = (event, id) => {
    const values = [...inputFields];
    const index = values.findIndex((value) => value.id === id);
    values[index].value = event.target.value;
    setInputFields(values);
  };

  /* for dynamic input fields ends*/

  const formSubmit = (e) => {
    e.preventDefault();
    setChapterFormError(validate(chapterFormValue));

    const values = inputFields
      .map((field) => field.value.trim())
      .filter(Boolean);
    if (values.length === 0) {
      setError("Please enter at least one value");
    } else {
      const inputString = values.join(", ");
      setNewData(inputString);
      setInputFields([{ value: "", id: 1 }]);
      setError("");
    }

    setEditIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(chapterFormError).length === 0 && editIsSubmit) {
      editdata(chapterFormValue);
      if (closeChecked === true) {
        toggleEditPopup();
      }
      setChapterFormValue(chapterValue);
      setNewData("");
      setEditIsSubmit(false);
    }
  }, [chapterFormError]);

  const validate = (values) => {
    const errors = {};
    if (!values.class) {
      errors.class = "Required";
    }
    if (!values.chapterNumber) {
      errors.chapterNumber = "Required";
    }
    if (!values.title) {
      errors.title = "Required";
    }
    if (!values.subject) {
      errors.subject = "Required";
    }

    return errors;
  };

  //API for class list
  const [classList, setClassList] = useState([]);
  useEffect(() => {
    getClassList();
  }, []);

  const getClassList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      DFlag: "C",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setClassList(postResult);
      } else {
        setClassList([]);
      }
    });
  };

  //API for subject list
  const [subjectList, setSubjectList] = useState([]);

  useEffect(() => {
    getSubjectList();
  }, [chapterFormValue.class]);

  const getSubjectList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      ClassID: chapterFormValue.class,
      DFlag: "SB",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";

        setSubjectList(postResult);
      } else {
        setSubjectList([]);
      }
    });
  };

  return (
    <>
      <div className="container-fluid basicform">
        <form>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label className="form-label" htmlFor="class">
                Class<sup style={{ color: "red" }}>*</sup>
              </label>
              <select
                id="class"
                name="class"
                onChange={handleChange}
                value={chapterFormValue.class}
                className="form-select form-select-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="" disabled selected style={{ fontSize: "11px" }}>
                  Select Class
                </option>
                {classList.map((item) => (
                  <option key={item.ClassID} value={item.ClassID}>
                    {item.ClassName}
                  </option>
                ))}
              </select>
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {chapterFormError.class}
              </p>
            </div>

            <div className="form-group col-md-6">
              <label className="form-label" htmlFor="subject">
                Subject<sup style={{ color: "red" }}>*</sup>
              </label>
              <select
                id="subject"
                name="subject"
                onChange={handleChange}
                value={chapterFormValue.subject}
                className="form-select form-select-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="" disabled selected style={{ fontSize: "11px" }}>
                  Select Subject
                </option>
                {subjectList.map((item) => (
                  <option key={item.SubjectID} value={item.SubjectID}>
                    {item.SubjectName}
                  </option>
                ))}
              </select>
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {chapterFormError.subject}
              </p>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-3">
              <label
                className="form-label"
                htmlFor="chapterNumber"
                style={{ fontSize: "12px" }}
              >
                Number<sup style={{ color: "red" }}>*</sup>
              </label>
              <input
                id="chapterNumber"
                style={{ fontSize: "13px" }}
                type="text"
                className="form-control form-control-sm"
                name="chapterNumber"
                value={chapterFormValue.chapterNumber}
                onChange={handleChange}
              />
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {chapterFormError.chapterNumber}
              </p>
            </div>

            <div className="form-group col-md-9">
              <label
                className="form-label"
                htmlFor="title"
                style={{ fontSize: "12px" }}
              >
                Title<sup style={{ color: "red" }}>*</sup>
              </label>
              <input
                id="title"
                style={{ fontSize: "13px" }}
                type="text"
                className="form-control form-control-sm "
                name="title"
                value={chapterFormValue.title}
                onChange={handleChange}
              />
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {chapterFormError.title}
              </p>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-12">
              {inputFields.map((inputField) => (
                <div key={inputField.id}>
                  <label
                    className="form-label"
                    style={{ fontSize: "12px" }}
                    htmlFor={`inputField${inputField.id}`}
                  >
                    {`Learning ${inputField.id}`}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="text"
                      id={`inputField${inputField.id}`}
                      name={`inputField${inputField.id}`}
                      className="form-control form-control-sm"
                      value={inputField.value}
                      onChange={(event) =>
                        handleInputChange(event, inputField.id)
                      }
                      required={inputField.id === 1}
                    />
                    {inputField.id > 1 && (
                      <button
                        className="btn btn-sm"
                        style={{
                          width: "auto",
                          marginLeft: "6px",
                          height: "27px",
                          background: "#df3e3e",
                          color: "white",
                          marginBottom: "2px",
                        }}
                        onClick={() => handleRemoveFields(inputField.id)}
                      >
                        <i className="fa fa-minus" aria-hidden="true"></i>
                      </button>
                    )}
                  </div>
                </div>
              ))}
              {error && (
                <p className="errormsg " style={{ marginBottom: "0" }}>
                  {error}
                </p>
              )}
            </div>

            <div className="form-group">
              <button
                type="button"
                className="btn btn-sm"
                style={{
                  width: "auto",
                  marginLeft: "6px",
                  height: "27px",
                  background: "#459147",
                  color: "white",
                  marginBottom: "2px",
                  marginTop: "2px",
                }}
                onClick={handleAddFields}
              >
                <i className="fa fa-plus" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="FormFooter">
        <div className="checkedClose">
          <input
            type="checkbox"
            className="form-check-input"
            id="exampleCheck2"
            name="closeChecked"
            onChange={handleOnChange}
            checked={closeChecked}
          />
          <label class="form-check-label" for="exampleCheck2">
            Exit after submit
          </label>
        </div>
        <div>
          <button
            className="btn btn-sm btn-cmpy ml-2"
            style={{ background: "green" }}
            onClick={formSubmit}
          >
            Submit
          </button>
          <button className="btn btn-sm btn-cmpy ml-2" onClick={closeEditPopup}>
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}
