import React, { useContext, useEffect } from "react";
import { useState } from "react";
import CloseIcon from "../../images/CloseIcon.svg"
import OnlineClassContext from "../contextfolder/OnlineClassContext/OnlineClassContext";
import './ActivatePop.css';


export default function ActivatePop({ handleActivateTrue, handleActivateFalse }) {

    const { activateStatusFormError, setActivateStatusFormError, activateIsSubmit, setActivateIsSubmit, activatestatusvalue, activateStatusFormValue, setActivateStatusFormValue } = useContext(OnlineClassContext)

    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.type === "checkbox" ? target.checked : target.value;
        setActivateStatusFormValue({ ...activateStatusFormValue, [name]: value });
    };

    const [state, setState] = useState(false)

    const formNext = (e) => {
        e.preventDefault();
        setState(true)
        setActivateIsSubmit(true);
    };

    useEffect(() => {

        if (state === true && activateIsSubmit) {

            handleActivateTrue(activateStatusFormValue)
        }
        setActivateIsSubmit(false);
    }, [state]);

    return (
        <>
            <div className="container confirm-popup">
                <div className="confirms-popup-inner ">
                    <div className="popUpHeader ps-0 pe-0">
                        <div className="popUpDelTitle">Easy School</div>
                        <div className="popUpClose">
                            <img style={{ paddingRight: "10px", cursor: "pointer" }}
                                className="popUpCloseIcon"
                                src={CloseIcon}
                                alt="CloseIcon"
                                onClick={handleActivateFalse}
                            />
                        </div>
                    </div>

                    <div className="confirms-body ps-3 pe-3">
                        <div className="row text-start ">
                            <div className="delText">Are you sure want to Activate this?</div>
                        </div>
                        <div className="col-md-12 col-sm-12 col-lg-12" style={{ marginTop: "10px" }}>
                            <div className="form-group">
                                <input
                                    type="checkbox"
                                    // className="form-check-input"
                                    id="exampleCheck3"
                                    name="notify"
                                    onChange={handleChange}
                                    checked={activateStatusFormValue.notify}
                                />
                                <label class="form-check-label" for="exampleCheck3" style={{ fontSize: "12px", paddingLeft: "3px" }}>
                                    Notify
                                </label>
                            </div>
                        </div>
                        <div className="col-md-12 col-sm-12 col-lg-12" style={{ marginTop: "10px" }}>
                            <div className="form-group">
                                <input
                                    type="checkbox"
                                    // className="form-check-input"
                                    id="exampleCheck4"
                                    name="autoAttendance"
                                    onChange={handleChange}
                                    checked={activateStatusFormValue.autoAttendance}
                                />
                                <label class="form-check-label" for="exampleCheck4" style={{ fontSize: "12px", paddingLeft: "3px" }}>
                                    Auto Attendance
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="confirm-footer">
                        <div className="row  mt-2 mb-2">
                            <div>
                                <button
                                    type="button"
                                    class="btn btn-sm me-2"
                                    style={{ background: "#0079bf", color: "white" }}
                                    onClick={formNext}
                                >
                                    Okay
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-sm btn-danger me-3"
                                    style={{ color: "white" }}
                                    onClick={handleActivateFalse}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

