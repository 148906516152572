import React from 'react'
import landinglogo from "./../../images/landinglogo.png";
const Header = () => {

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }
    return (
        <div id="header" className="fixed-top">
            <div className="container d-flex align-items-center justify-content-between">

                <a href="#hero" className="logo"><img src={landinglogo} alt="Easy Software" /></a>

                <nav id="navvbarr" className="navvbarr">
                    <ul>
                        <li><a className="nav-link scrollto actives" href="#hero">Home</a></li>
                        <li><a className="nav-link scrollto" href="#about">About</a></li>
                        <li className="dropdown"><a><span>Features</span> <i className="bi bi-chevron-down"></i></a>
                            <ul>
                                <li><a className="scrollto" href="#student">Student</a></li>
                                <li><a className="scrollto" href="#teacher">Teacher</a></li>
                                <li><a className="scrollto" href="#admin">Admin</a></li>
                            </ul>
                        </li>
                        <li onClick={() => openInNewTab("https://jobs.eliteinfotechnp.com/")}><a className="nav-link scrollto" href="##">Jobs</a></li>
                        <li><a className="nav-link scrollto " href="#contact" >Contact Us</a></li>
                        <li><button style={{ border: "none" }} className="getstarted" onClick={() => openInNewTab("/login")}>Log In</button></li>
                    </ul>
                    <i className="bi bi-list mobile-nav-toggle"></i>
                </nav>

            </div>
        </div>
    )
}

export default Header