import React, { useContext, useState, useEffect } from 'react'
import CloseIcon from "../../images/CloseIcon.svg";
import Plus from "../../images/Plus.png";
import SliderContext from '../contextfolder/SliderContext/SliderContext';

export default function SliderForm({ closePopup }) {

    const context = useContext(SliderContext)

    const { togglePopup, sliderFormError, setSliderFormError, isSubmit, setIsSubmit, sliderValue, sliderFormValue, setSliderFormValue, isUploaded, setIsUploaded, setTypeFile, image, setImage, fetchdata, closeChecked, setCloseChecked } = context;


    const handleOnChange = () => {
        setCloseChecked(!closeChecked);
    };


    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;
        setSliderFormValue({ ...sliderFormValue, [name]: value });
    };
    function handleImageChange(e) {
        if (e.target.files && e.target.files[0]) {
            setTypeFile(e.target.files[0].type);
            let reader = new FileReader();

            reader.onload = function (e) {
                setImage(e.target.result);
                setIsUploaded(true);
            };

            reader.readAsDataURL(e.target.files[0]);
        }
    }


    const formSubmit = (e) => {
        e.preventDefault();
        setSliderFormError(validate(sliderFormValue));
        setIsSubmit(true);
    }

    useEffect(() => {
        if (Object.keys(sliderFormError).length === 0 && isSubmit) {
            fetchdata(sliderFormValue)
            if (closeChecked === true) {
                togglePopup();
            }
            setSliderFormValue(sliderValue)
            setIsSubmit(false);
            setIsUploaded(false)
        setImage("");

        }

    }, [sliderFormError]);

    const validate = (values) => {

        const errors = {};
        if (!values.title) {
            errors.title = "Required";
        }
        if (!values.order) {
            errors.order = "Required";
        }

        return errors;
    };



    return (
        <>

            <div className="container-fluid basicform" >

                <form >

                    <div className="form-group">
                        <label className="form-label" htmlFor="title" style={{ fontSize: "12px" }}>
                            Title<sup style={{ color: "red" }}>*</sup>
                        </label>
                        <input
                            id="title"
                            style={{ fontSize: "13px" }}
                            type="text"
                            className="form-control form-control-sm "
                            name="title"
                            value={sliderFormValue.title}
                            onChange={handleChange}
                        />
                        <p className="errormsg " style={{ marginBottom: "0" }}>{sliderFormError.title}</p>

                    </div>




                    <div className="form-group">
                        <label className="form-label" htmlFor="url" style={{ fontSize: "12px" }}>
                            Operational Url
                        </label>
                        <input
                            id="url"
                            style={{ fontSize: "13px" }}
                            type="text"
                            className="form-control form-control-sm "
                            name="url"
                            value={sliderFormValue.url}
                            onChange={handleChange}
                        />

                    </div>
                    <div className="form-group">
                        <label className="form-label" htmlFor="url" style={{ fontSize: "12px" }}>
                            Order<sup style={{ color: "red" }}>*</sup>
                        </label>
                        <select
                            name="order"
                            className="form-select form-select-sm"
                            aria-label="Default select example "
                            value={sliderFormValue.order}
                            onChange={handleChange}
                        >
                            <option selected disabled value="" style={{ fontSize: "11px" }}>
                                Select Option
                            </option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                        </select>
                        <p className="errormsg " style={{ marginBottom: "0" }}>{sliderFormError.order}</p>
                    </div>


                    <div className="form-group  ">
                        <div
                            className="form-label"
                            htmlFor="text"
                            style={{ fontSize: "12px", textAlign: "left" }}
                        >
                            Upload Image
                        </div>

                        <div className="BoxUpload">
                            <div className="image-upload">
                                {!isUploaded ? (
                                    <>
                                        <label htmlFor="upload-input">
                                            <img
                                                src={Plus}
                                                draggable={"false"}
                                                alt="placeholder"
                                                style={{ width: 90, height: 100, paddingTop: "10px" }}
                                            />
                                        </label>

                                        <input
                                            id="upload-input"
                                            type="file"
                                            accept=".jpg,.jpeg,.gif,.png,.mov,.mp4"
                                            onChange={handleImageChange}
                                            name="image"
                                        />
                                    </>
                                ) : (
                                    <div className="ImagePreview">
                                        <img
                                            className="close-icon"
                                            src={CloseIcon}
                                            alt="CloseIcon"
                                            onClick={() => {
                                                setIsUploaded(false);
                                                setImage(null);
                                            }}
                                        />

                                        <img
                                            id="uploaded-image"
                                            src={image}
                                            draggable={false}
                                            alt="uploaded-img"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                </form>


            </div>
            <div className="FormFooter">
                <div className='checkedClose'>
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck2"
                        name="closeChecked"
                        onChange={handleOnChange}
                        checked={closeChecked}
                    />
                    <label class="form-check-label" for="exampleCheck2">
                        Exit after submit
                    </label>
                </div>
                <div>
                    <button className='btn btn-sm btn-cmpy ml-2' style={{ background: "green" }} onClick={formSubmit} >Submit</button>
                    <button className='btn btn-sm btn-cmpy ml-2' onClick={closePopup}>Cancel</button>
                </div>
            </div>

        </>

    )
}
