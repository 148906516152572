import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import '../../css/Commission.css';
import EventContext from "../contextfolder/EventContext/EventContext";
import { showImgPreview } from "../hooks/ImagePreview";
import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";
import DeletePop from "./DeletePop";
import EventEdit from "./EventEdit";
import EventForm from "./EventForm";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { GetTwelveHourFormatTime, GetNepaliDate } from "../hooks/dateConvertor";


export default function Event() {
    const { todayDate, customStylesForImage, AcademicYear, hideAction } = useContext(SidebarContext);
    const { loading, originalList, setEventList, popup, togglePopup, setIsSubmit, setEventFormValue, setEventFormError, eventValue, eventList, handleEdit, editPop, toggleEditPopup, setEditIsSubmit,
        deletePopup, handleDeleteTrue, handleDeleteFalse, handleDelete, setCloseChecked, setIsUploaded,
        setImage } = useContext(EventContext);

    const userinfo = JSON.parse(localStorage.getItem("userInfo"));
    const [imgPrv, setImgPrv] = useState(false);
    const [imagePre, setImagePre] = useState("");

    const searchInput = useRef("");



    const columns = [
        {
            name: "S.N.",
            width: "63px",
            center: true,
            cell: (row, index) => index + 1,
        },
        {
            name: "Banner",
            center: true,
            width: "79px",
            selector: (row) => {
                return (
                    <>
                        <div className="staffContentLogo"><div className="staffImg">
                        <img src={row.Banner}
                            alt=""
                            onClick={() => { setImagePre(row.Banner); setImgPrv(true) }}

                        /></div></div>
                    </>
                )
            }
        },
        {
            name: "Title",
            selector: (row) => row.Title,
        },
        {
            name: "Date",
            width: "105px",
            selector: (row) => GetNepaliDate(row.EngDateFrom),
        },
        {
            name: "Start",
            width: "110px",
            selector: (row) => GetTwelveHourFormatTime(row.TimeStart),
        },
        {
            name: "End",
            width: "110px",
            selector: (row) => GetTwelveHourFormatTime(row.TimeEnd),
        },
        {
            name: "Venue",
            selector: (row) => row.Venue,
        },
        {
            name: "Category",
            selector: (row) => row.Category,
        },
        {
            name: "Action",
            center: true,
            width: "100px",
            selector: (row) => {
                return (
                    <>
                        <div className="ln-verition d-flex">
                            <Tippy content="Edit">
                                <i
                                    className="fa fa-pencil solvespan--action"
                                    onClick={() => handleEdit(row)}
                                >
                                </i>
                            </Tippy>
                            <Tippy content="Delete">
                                <i
                                    className="fa fa-trash-o rejectspan--action"
                                    onClick={() => handleDelete(row.EventID)}
                                >
                                </i>
                            </Tippy>
                        </div>
                    </>
                );
            },
            omit: hideAction,
        },


    ];

    const closePopup = (e) => {
        e.preventDefault();
        togglePopup();
        setEventFormValue(eventValue)
        setEventFormError({})
        setIsSubmit(false)
        setCloseChecked(false)
        setIsUploaded(false)
        setImage("");
    }

    const closeEditPop = (e) => {
        e.preventDefault();
        toggleEditPopup();
        setEventFormError({})
        setEditIsSubmit(false)
        setCloseChecked(false)
    }



    const searchHandler = (e) => {
        e.preventDefault();
        

        const srchQuery = searchInput.current.value.toLowerCase();
        if (srchQuery) {

            let srchResult = originalList.filter((list) => {
                return list["Title"].toLowerCase().includes(srchQuery);
            });

            if (srchResult) {

                setEventList(srchResult);
            } else {
                setEventList({});
            }
        } else {
            setEventList(originalList);
        }
    };


    /* This is for export csv */

    const dataToExcel = eventList.map((d, i) => ({
        "S.N.": i + 1,
        "Title": d.Title,
        "Date": d.EngDateFrom,
        "Time": d.TimeStart,
        "Venue": d.Venue,
        Category: d.Category,
        Contact: d.Contact,

    }));

    const toExcel = () => {
        import("xlsx").then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(dataToExcel);
            var wscols = [
                { wch: 5 },
                { wch: 20 },
                { wch: 20 },
                { wch: 15 },
                { wch: 15 },
                { wch: 15 },
                { wch: 15 },
            ];
            worksheet["!cols"] = wscols;
            const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: "xlsx",
                type: "array",
            });
            saveAsExcelFile(excelBuffer, "Event");
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import("file-saver").then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE =
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
                let EXCEL_EXTENSION = ".xlsx";
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE,
                });

                module.default.saveAs(
                    data,
                    fileName + "_" + "export" + EXCEL_EXTENSION
                );
            }
        });
    };

    //end

    //This is to export pdf 

    const dataWithIndex = eventList.map((d, i) => ({
        ...d,
        Index: i + 1,
    }));

    const cols = [
        { header: "S.N", field: "Index" },
        { header: "Title", field: "Title" },
        { header: "Date", field: "EngDateFrom" },
        { header: "Time", field: "TimeStart" },
        { header: "Venue", field: "Venue" },
        { header: "Category", field: "Category" },
        { header: "Contact", field: "Contact" },
    ];


    const exportColumns = cols.map((col) => ({
        title: col.header,
        dataKey: col.field,
    }));

    const toPdf = () => {
        const doc = new jsPDF("p", "pt", "a4", true);

        var midPage = doc.internal.pageSize.getWidth() / 2;

        // doc.addImage(`${userinfo.SchPhoto}`, "JPG", 40, 40, 70, 60);

        doc.setFontSize(18);
        doc.text(`${userinfo.SchName}`, midPage, 50, null, null, "center");

        doc.setFontSize(15);
        doc.text(
            `${userinfo.SchAddress}`,
            midPage,
            68,
            null,
            null,
            "center"
        );

        doc.setFontSize(10);
        doc.text(`Tel:${userinfo.Phone}, E-mail:${userinfo.Email}`, midPage, 85, null, null, "center");

        doc.autoTable({
            startY: 100,
            theme: "grid",
            columns: exportColumns,
            body: dataWithIndex,
        });
        doc.save("Event.pdf");
    };
    //end


    return (

        <>
            <div className="sec-dataTable">
                <div className="upper-dataTbl">

                    <Tippy content="PDF">
                        <button className="exportButtons ml-1"
                            onClick={toPdf}
                        >
                            <i className="bi bi-file-earmark-pdf-fill exportButtonIconsPdf"></i>
                        </button>
                    </Tippy>
                    <Tippy content="Excel">
                        <button className="exportButtons" onClick={toExcel}>
                            <i className="bi bi-file-earmark-excel-fill exportButtonIconsExcel"></i>
                        </button>
                    </Tippy>



                </div>
                <hr className="new-hr" />

                {loading ? (
                    <Spinner />
                ) : (
                    <DataTable
                        columns={columns}
                        data={eventList}
                        customStyles={customStylesForImage}
                        pagination
                        fixedHeader
                        fixedHeaderScrollHeight="280px"
                        highlightOnHover
                        pointerOnHover
                        responsive
                        dense
                        striped
                        subHeader
                        subHeaderAlign="left"
                        subHeaderComponent={
                            <>
                                {/* <div className="col-md-2 col-sm-2 col-lg-2" style={{ marginRight: "-15px" }}>
                                            <label style={{ fontSize: "14px", textAlign: "left", display: "block" }}>Class</label>
                                            <select
                                                style={{ fontSize: "11px", marginBottom: "3px" }}
                                                name="snotifiaction"
                                                value={selectClass}
                                                onChange={handleClass}
                                                className="form-select form-select-sm"
                                                aria-label="Default select example "
                                            >
                                                <option
                                                    value=""
                                                    disabled
                                                    selected
                                                    style={{ fontSize: "11px" }}
                                                >
                                                    Select Class
                                                </option>
                                                {classList.map((item) => (
                                                    <option
                                                        key={item.ClassID}
                                                        value={item.ClassID}
                                                    >
                                                        {item.ClassName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div> */}

                                <div className="col-lg-2 offset-lg-10 offset-md-10 offset-sm-0 col-md-2 col-sm-2 align-self-end Search" style={{ paddingRight: "1px", marginBottom: "10px" }}>
                                    <div className="d-flex">
                                        <input
                                            placeholder="Search"
                                            ref={searchInput}
                                            type="text"
                                            className="form-control form-control-sm searchField"
                                            onChange={searchHandler}
                                        />
                                    </div>
                                </div>
                            </>
                        }
                    />
                )}
            </div>



            {popup && (
                <div className="modals">
                    <div className="overlay">

                        <div className="notification-model-content">
                            <div className="popUpHeader ">
                                <div className='popUpHeaderText '>Add Event</div>
                                <div style={{ cursor: "pointer" }}><i className="bi bi-x " onClick={closePopup} style={{ fontSize: "25px" }}></i></div>
                            </div>
                            <div className="inside-modal">
                                <EventForm closePopup={closePopup} />
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {editPop && (
                <div className="modals">
                    <div className="overlay">

                        <div className="notification-model-content">
                            <div className="popUpHeader ">
                                <div className='popUpHeaderText '>Edit Event</div>
                                <div style={{ cursor: "pointer" }}><i className="bi bi-x " onClick={closeEditPop} style={{ fontSize: "25px" }}></i></div>
                            </div>
                            <div className="inside-modal">
                                <EventEdit closeEditPop={closeEditPop} />
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {imgPrv &&
                showImgPreview({
                    img: imagePre,
                    setTrigger: setImgPrv,
                })}

            {deletePopup.show && (
                <DeletePop
                    handleDeleteTrue={handleDeleteTrue}
                    handleDeleteFalse={handleDeleteFalse}
                />
            )}
        </>
    );
}
