import React, { useEffect } from "react";
import placeholder from "./../../images/school/no-available-image.jpg";
import Aos from "aos";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useState } from "react";
import { Fetchdata } from "../hooks/getData";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { FaCamera, FaPhotoVideo } from "react-icons/fa";
import ViewPhoto from "./ViewPhoto";
const Album = ({ schoolInfo }) => {
  const appURL = process.env.REACT_APP_URL;
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  useEffect(() => {
    Aos.init();
    Fancybox.bind("[data-fancybox]");
  }, []);

  const [albumList, setAlbumList] = useState([]);
  useEffect(() => {
    getAlbumList();
  }, [schoolInfo]);

  const getAlbumList = () => {
    const params = {
      SchID: schoolInfo?.SchID,
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/gallery/album`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.AlbumLst ? result.AlbumLst : "";
        setAlbumList(postResult);
      } else {
        setAlbumList([]);
      }
    });
  };
  const [viewPop, setViewPop] = useState(false);
  const toggleViewPopup = () => {
    setViewPop(true);
  };
  const [photoId, setPhotoId] = useState("");
  const [photoList, setPhotoList] = useState([]);
  const handleClick = (data) => {
    setPhotoId(data.AlbumID);
    toggleViewPopup();
    getPhotoList();
  };
  const closeViewPopup = (e) => {
    e.preventDefault();
    setViewPop(false);
  };
  useEffect(() => {
    getPhotoList();
  }, [schoolInfo, photoId]);

  const getPhotoList = () => {
    const params = {
      SchID: schoolInfo?.SchID,
      Flag: "S",
      AlbumID: photoId,
      Type: "POST",
      AcademicYear: schoolInfo?.AcademicYear,
      FetchURL: `${appURL}api/gallery/photo`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.AlbumLst ? result.AlbumLst : "";
        setPhotoList(postResult);
      } else {
        setPhotoList([]);
      }
    });
  };
  return (
    <>
      {albumList.length > 0 && (
        <div className="section-b album__section" id="album__section">
          <div className="uk-container">
            <div
              class=" new__title uk-text-left"
              data-aos="fade-up"
              data-aos-anchor-placement="center-bottom"
              data-aos-duration="1000"
            >
              <h2
                className="the__title small"
                style={{
                  color: `${schoolInfo.ScColor}`,
                }}
              >
                Our Albums
              </h2>
              <p className="sub__heading">See our Latest Photos</p>
            </div>
            <div className="my__carousel">
              <OwlCarousel
                className="owl-theme"
                dots={false}
                // autoWidth
                // loop
                margin={30}
                items={3}
                nav
                responsive={{
                  0: {
                    items: 1,
                  },
                  767: {
                    items: 2,
                  },
                  960: {
                    items: 3,
                  },
                }}
              >
                {albumList.map((item) => (
                  <div key={item.AlbumID} style={{ border: "1px solid #eee" }}>
                    <div className="my__img" onClick={() => handleClick(item)}>
                      {item.Thumbnail ? (
                        <img src={item.Thumbnail} alt="Album" />
                      ) : (
                        <img src={placeholder} alt="Album" />
                      )}
                    </div>
                    <div className="uk-flex uk-flex-middle pb-3 px-3 uk-flex-between mt-3">
                      <h3
                        style={{
                          color: `${schoolInfo.ScColor}`,
                        }}
                        className="the__title mt-0"
                      >
                        {item.AlbumName}
                      </h3>
                      <div className="sub__title">
                        <FaCamera
                          style={{ marginTop: "-2px", marginRight: "5px" }}
                        />
                        {item.NoOfPhoto !== 0 && <>{item.NoOfPhoto}</>}{" "}
                        {item.NoOfPhoto === 0
                          ? "No Photos"
                          : item.NoOfPhoto === "1"
                          ? "Photo"
                          : "Photos"}
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </div>
      )}

      {viewPop && (
        <div className="custom__modal">
          <div className="custom__overlay"></div>

          <div className={`custom__modal__content ${viewPop && "active"}`}>
            <div className="custom__modal__header ">
              <div className="the__title small mt-0 ">View Photo</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closeViewPopup}
                  style={{ fontSize: "20px" }}
                ></i>
              </div>
            </div>
            <div className="custom__modal__body">
              <ViewPhoto viewList={photoList} />
            </div>
            <div className="custom__modal__footer">
              <div className="navvbarr">
                <button
                  style={{
                    border: "none",
                    background: `${schoolInfo.ScColor}`,
                    color: "white",
                  }}
                  className="getstarted ms-0"
                  onClick={closeViewPopup}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Album;
