import React from "react";
export default function ViewPhoto({ viewList }) {
  return (
    <>
      {viewList.length > 0 ? (
        <>
          <div
            className="uk-grid uk-grid-small uk-child-width-1-3@m uk-child-width-1-2@s"
            data-uk-grid
          >
            {viewList.map((item) => (
              <div key={item.PhotoID}>
                <a
                  href={item.PhotoUrl}
                  data-fancybox
                  data-caption={item.PhotoName}
                >
                  <img src={item.PhotoUrl} alt="Photo" />
                </a>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div>No photos available</div>
      )}
    </>
  );
}
