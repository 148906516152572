import React, { useContext, useEffect, useRef } from 'react'
import OrderContext from '../contextfolder/OrderContext/OrderContext';

export default function PaymentCreate({ closePopup }) {

    const context = useContext(OrderContext)

    const { togglePayModal, paymentFormError, setPaymentFormError, payIsSubmit, setPayIsSubmit, paymentValue, paymentFormValue, setPaymentFormValue, payCloseChecked, setPayCloseChecked, orderIdSubmit, setOrderIdSubmit, infoData, newOrder, setNewOrder, paymentData } = context;


    const handleOnChange = () => {
        setPayCloseChecked(!payCloseChecked);
    };


    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;
        setPaymentFormValue({ ...paymentFormValue, [name]: value });
    };


    const formSubmit = (e) => {
        e.preventDefault();
        setPaymentFormError(validate(paymentFormValue));
        setPayIsSubmit(true);

    }

    useEffect(() => {
        if (Object.keys(paymentFormError).length === 0 && payIsSubmit) {
            paymentData(paymentFormValue)
            if (payCloseChecked === true) {
                togglePayModal();
            }
            setPaymentFormValue(paymentValue)
            setPayIsSubmit(false);
        }

    }, [paymentFormError]);

    const validate = (values) => {

        const errors = {};
        const numv = /^[0-9]+$/i;


        if (paymentFormValue.type === "A") {
            if (!values.userName) {
                errors.userName = "Required";
            }
        }

        if (newOrder && newOrder.PaymentStatus !== "Paid") {
            if (!values.amount) {
                errors.amount = "Required";
            } else if (!numv.test(values.amount)) {
                errors.amount = "Must be digits";
            }

        }
        return errors;
    };

    // for Order ID search case submit 

    const payValidate = (values) => {
        const errors = {};
        if (!values.orderID) {
            errors.orderID = "Required";
        }
        return errors;
    }
    const paySubmit = (e) => {
        e.preventDefault();
        setPaymentFormError(payValidate(paymentFormValue));
        setOrderIdSubmit(true);

    }

    useEffect(() => {

        if (Object.keys(paymentFormError).length === 0 && orderIdSubmit) {
            infoData(paymentFormValue)
            setOrderIdSubmit(false);
        }

    }, [paymentFormError]);


    // const handleFilter = (e) => {

    //     const target = e.target;
    //     const name = target.name;
    //     const value = target.value;
    //     setPaymentFormValue({ ...paymentFormValue, [name]: value });

    //     const searchWord = e.target.value
    //     const newFilter = newOrder.filter((value) => {
    //         return value.Amount.toLowerCase().includes(searchWord.toLowerCase()) || value.FullName.toLowerCase().includes(searchWord.toLowerCase()) || value.NoOfItem.toLowerCase().includes(searchWord.toLowerCase()) || value.OrderDate.toLowerCase().includes(searchWord.toLowerCase()) || value.OrderID.toLowerCase().includes(searchWord.toLowerCase()) || value.OrderStatus.toLowerCase().includes(searchWord.toLowerCase()) || value.PaymentStatus.toLowerCase().includes(searchWord.toLowerCase());
    //     });
    //     if (searchWord === "") {
    //         setNewOrder([]);
    //     } else {
    //         setNewOrder(newFilter);
    //     }
    // };


    return (
        <>

            <div className="container-fluid basicform" >

                <form >

                    <div className="form-group">
                        <div>
                            <label className="form-label" htmlFor="purpose">Payment Status</label>
                        </div>
                        <div>
                            <input type="radio" className="radio--button" name="type" id="advanced" onChange={handleChange} value="A" checked={paymentFormValue.type === "A"} />

                            <label className="label-radio-routine" htmlFor="advanced" style={{ marginRight: "10px" }}>Advanced</label>

                            <input type="radio" className="radio--button" name="type" id="payment" onChange={handleChange} value="P" checked={paymentFormValue.type === "P"} />

                            <label className="label-radio-routine" htmlFor="payment" style={{ marginRight: "10px" }}>Payment</label>


                        </div>
                    </div>

                    {paymentFormValue.type === "P" && (

                        <>
                            <div className="form-row mt-3">

                                <div className="form-group col-md-11">
                                    <label className="form-label" htmlFor="orderID" style={{ fontSize: "12px" }}>
                                        Order ID
                                    </label>
                                    <input
                                        id="orderID"
                                        style={{ fontSize: "13px" }}
                                        type="text"
                                        className="form-control form-control-sm "
                                        name="orderID"
                                        value={paymentFormValue.orderID}
                                        onChange={handleChange}
                                    // onBlur={handleFilter}
                                    />
                                    <p className="errormsg " style={{ marginBottom: "0" }}>{paymentFormError.orderID}</p>
                                </div>

                                <div className="form-group col-md-1">
                                    <div className="add-title-button">
                                        <button
                                            className="btn btn-sm btn-cmpy"
                                            style={{
                                                background: "0079bf",
                                                width: "39px",
                                                marginTop: "3px"
                                            }}
                                            onClick={paySubmit}
                                        >
                                            <i className="fa fa-search" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                </div>

                            </div>

                            {newOrder.length === undefined ? (
                                <div className="form-group">

                                    <table className="complain-table" style={{ width: "100%" }}>

                                        <thead>
                                            <tr className='tableHead viewHead'>
                                                <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", position: "relative" }}>OrderID</td>

                                            </tr>
                                        </thead>

                                        <tbody>
                                            {newOrder &&
                                                <tr className="viewHead">
                                                    <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", paddingTop: "0px" }} >
                                                        {newOrder.OrderID}
                                                    </td>

                                                </tr>
                                            }
                                        </tbody>

                                        <thead>
                                            <tr className='tableHead viewHead'>
                                                <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", position: "relative" }}>Date</td>
                                                <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", position: "relative" }}>No. of Item</td>
                                                <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", position: "relative" }}>Amount</td>
                                            </tr>
                                        </thead>
                                        <tbody>




                                            {newOrder &&
                                                <tr className="viewHead">
                                                    <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", paddingTop: "0px" }} >
                                                        {newOrder.OrderDate}
                                                    </td>
                                                    <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", paddingTop: "0px" }}>
                                                        {newOrder.NoOfItem}
                                                    </td>
                                                    <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", paddingTop: "0px" }}>
                                                        {parseFloat(newOrder.Amount).toFixed(2)}
                                                    </td>
                                                </tr>
                                            }




                                        </tbody>

                                        <thead>
                                            <tr className='tableHead viewHead'>
                                                <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", position: "relative" }}>Full Name</td>
                                                <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", position: "relative" }}>Order Status</td>
                                                <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", position: "relative" }}>Payment Status</td>
                                            </tr>
                                        </thead>

                                        <tbody>

                                            {newOrder &&
                                                <tr className="viewHead">
                                                    <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", paddingTop: "0px" }} >
                                                        {newOrder.FullName}
                                                    </td>
                                                    <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", paddingTop: "0px" }}>
                                                        {newOrder.OrderStatus}
                                                    </td>
                                                    <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", paddingTop: "0px" }}>
                                                        {newOrder.PaymentStatus}
                                                    </td>
                                                </tr>
                                            }

                                        </tbody>

                                    </table>
                                </div>) : (

                                <div className="text-center mt-4">Please Enter a Valid Order ID </div>
                            )}
                        </>
                    )}



                    <div className="form-row mt-3">
                        {paymentFormValue.type === "A" && (
                            <div className="form-group col-md-6">
                                <label className="form-label" htmlFor="userName" style={{ fontSize: "12px" }}>
                                    UserName<sup style={{ color: "red" }}>*</sup>
                                </label>
                                <input
                                    id="userName"
                                    style={{ fontSize: "13px" }}
                                    type="text"
                                    className="form-control form-control-sm "
                                    name="userName"
                                    value={paymentFormValue.userName}
                                    onChange={handleChange}
                                />
                                <p className="errormsg " style={{ marginBottom: "0" }}>{paymentFormError.userName}</p>
                            </div>
                        )}

                        {newOrder && newOrder.PaymentStatus === "Paid" ? (<></>) : (
                            <div className={paymentFormValue.type === "P" ? "form-group col-md-12" : "form-group col-md-6"}>
                                <label className="form-label" htmlFor="amount" style={{ fontSize: "12px" }}>
                                    Amount<sup style={{ color: "red" }}>*</sup>
                                </label>
                                <input
                                    id="amount"
                                    style={{ fontSize: "13px" }}
                                    type="text"
                                    className="form-control form-control-sm "
                                    name="amount"
                                    value={paymentFormValue.amount}
                                    defaultValue={newOrder === undefined ? "newOrder.Amount" : "aaa"}
                                    onChange={handleChange}
                                />
                                <p className="errormsg " style={{ marginBottom: "0" }}>{paymentFormError.amount}</p>
                            </div>
                        )}
                    </div>







                </form>


            </div>
            <div className="FormFooter">
                <div className='checkedClose'>
                    {newOrder && newOrder.PaymentStatus === "Paid" ? (<></>) : (
                        <>
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="exampleCheck2"
                                name="closeChecked"
                                onChange={handleOnChange}
                                checked={payCloseChecked}
                            />
                            <label class="form-check-label" for="exampleCheck2">
                                Exit after submit
                            </label>
                        </>
                    )}

                </div>
                <div>
                    {newOrder && newOrder.PaymentStatus === "Paid" ? (<></>) : (
                        <button className='btn btn-sm btn-cmpy ml-2' style={{ background: "green" }} onClick={formSubmit} >Submit</button>)}
                    <button className='btn btn-sm btn-cmpy ml-2' onClick={closePopup}>Cancel</button>
                </div>
            </div>
        </>

    )
}
