import React from "react";
import "../../css/lLandingPage.css";
import About from "./About";
import Contact from "./Contact";
import Features from "./Features";
import Footer from "./Footer";
import Header from "./Header";
import Hero from "./Hero";
import HowItWorks from "./HowItWorks";
import FilteredJobList from "./JobFilter";

const LandingPage = () => {
  return (
    <>
      <Header />
      {/* <Hero />
            <About />
            <Features />
            <HowItWorks />
            <Contact /> */}
      <FilteredJobList />
      <Footer />
    </>
  );
};

export default LandingPage;
