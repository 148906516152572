import React, { useContext, useRef } from "react";
import DataTable from "react-data-table-component";

import '../../css/Commission.css';

import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";
import { GetNepaliDate } from "../hooks/dateConvertor";
import StudentContext from "../contextfolder/StudentContext/StudentContext";



export default function LeaveTab() {
    const { customStyles } = useContext(SidebarContext);
    const { leaveList, setLeaveList, leaveLoad, leaveSearch } = useContext(StudentContext);


    const searchInput = useRef("");

    const columns = [
        {
            name: "S.N.",
            width: "63px",
            center: true,
            cell: (row, index) => index + 1,
        },
        {
            name: "Title",
            width: "150px",
            selector: (row) => row.Title,
        },
        {
            name: "Description",
            selector: (row) => row.Description,
        },
        {
            name: "From",
            width: "120px",
            selector: (row) => GetNepaliDate(row.From),
        },
        {
            name: "To",
            width: "120px",
            selector: (row) => GetNepaliDate(row.To),
        },

        {
            name: "Status",
            width: "110px",
            selector: (row) => row.IsApprovedID === "P" ? "Pending" : row.IsApprovedID === "Y" ? "Approved" : "Rejected"
        },
        {
            name: "Remarks",
            selector: (row) => row.ApprovedRemarks === null || row.IsApprovedID === "P" ? "Pending" : row.ApprovedRemarks,
        },
        {
            name: "Approved By",
            selector: (row) => row.ApprovedBy === null || row.IsApprovedID === "P" ? "Pending" : row.ApprovedBy,
        },


    ];






    const searchHandler = (e) => {
        e.preventDefault();

        const srchQuery = searchInput.current.value.toLowerCase();
        if (srchQuery) {

            let srchResult = leaveSearch.filter((list) => {
                return list["Title"].toLowerCase().includes(srchQuery);
            });

            if (srchResult) {

                setLeaveList(srchResult);
            } else {
                setLeaveList({});
            }
        } else {
            setLeaveList(leaveSearch);
        }
    };





    return (


        <div className="sec-dataTable content-section main-content">

            {leaveLoad ? (
                <Spinner />
            ) : (
                <DataTable
                    columns={columns}
                    data={leaveList}
                    customStyles={customStyles}
                    pagination
                    fixedHeader
                    fixedHeaderScrollHeight="280px"
                    highlightOnHover
                    pointerOnHover
                    responsive
                    dense
                    striped
                    subHeader
                    subHeaderAlign="left"
                    subHeaderComponent={
                        <>


                            <div className="col-lg-3 offset-lg-9 offset-md-9 offset-sm-9 col-md-3 col-sm-3 align-self-end Search" style={{ paddingRight: "1px", marginBottom: "10px" }}>
                                <div className="d-flex">
                                    <input
                                        placeholder="Search"
                                        ref={searchInput}
                                        type="text"
                                        className="form-control form-control-sm searchField"
                                        onChange={searchHandler}
                                    />
                                </div>
                            </div>
                        </>
                    }
                />
            )}
        </div>

    );
}
