import React, { useContext, useEffect, useState } from "react";
import SidebarContext from "../sidebarcontext/SidebarContext";
import { Fetchdata } from "../hooks/getData";
import CloseIcon from "../../images/CloseIcon.svg";
import AssignmentContext from "../contextfolder/AssignmentContext/AssignmentContext";
import Select from "react-select";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import { getDisableDate } from "../hooks/dateConvertor";

export default function AssignmentForm({ closePopup }) {
  const { appURL } = useContext(SidebarContext);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const context = useContext(AssignmentContext);

  const {
    togglePopup,
    assignmentFormError,
    setAssignmentFormError,
    isSubmit,
    setIsSubmit,
    assignmentValue,
    assignmentFormValue,
    setAssignmentFormValue,
    fetchdata,
    closeChecked,
    setCloseChecked,
    isUploaded,
    setIsUploaded,
    setTypeFile,
    image,
    setImage,
    section,
    setSection,
    selectedImages,
    setSelectedImages,
    selectedBase64,
    setSelectedBase64,
    typeFile,
  } = context;

  const handleOnChange = () => {
    setCloseChecked(!closeChecked);
  };

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    setAssignmentFormValue({ ...assignmentFormValue, [name]: value });
  };

  const handleAssignmentDate = ({ adDate }) => {
    setAssignmentFormValue({ ...assignmentFormValue, submissionDate: adDate });
  };

  const handleImageChange = (e) => {
    if (e.target.files) {
      const selected_files = Object.values(e.target.files);
      const extension = e.target.files[0].name;
      const fileType = extension.split(".");
      // const fileName = fileType[0]
      const dot = ".";
      const fileExt = dot.concat(fileType[1]);

      setTypeFile(fileExt);

      selected_files.map((file) => {
        let reader = new FileReader();

        reader.onload = function (e) {
          const result = reader.result;

          setSelectedImages((prevImages) =>
            prevImages.concat({ Type: typeFile, File: result })
          );
          setSelectedBase64((prevImages) =>
            prevImages.concat({
              Type: typeFile,
              File: result !== null ? result.split(",")[1] : "",
            })
          );
        };

        reader.readAsDataURL(file);
      });
    }
  };

  const handleImageRemove = (data) => {
    data[0].preventDefault();
    setSelectedImages(
      selectedImages.filter((file) => file.File !== data[1].File)
    );
    setSelectedBase64(
      selectedBase64.filter((file) => file.File !== data[1].File)
    );
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setAssignmentFormError(validate(assignmentFormValue));
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(assignmentFormError).length === 0 && isSubmit) {
      fetchdata(assignmentFormValue);
      if (closeChecked === true) {
        togglePopup();
      }
      setAssignmentFormValue(assignmentValue);
      setImage("");
      setIsUploaded(false);
      setIsSubmit(false);
      setSelectedImages([]);
      setSelectedBase64([]);
    }
  }, [assignmentFormError]);

  function count(numb) {
    if (Number.isInteger(numb)) {
      return numb;
    } else {
      return numb.toString().split(".")[0].length;
    }
  }

  const validate = (values) => {
    const errors = {};

    const numv = /[+-]?([0-9]*[.])?[0-9]+/;
    const digits = /\b(0|[1-9][0-9]?|9999)\b/;

    if (!values.class) {
      errors.class = "Required";
    }
    if (userinfo.UserType !== "T") {
      if (!values.teacher) {
        errors.teacher = "Required";
      }
    }
    if (!values.subject) {
      errors.subject = "Required";
    }
    if (!values.submissionDate) {
      errors.submissionDate = "Required";
    }
    if (assignmentFormValue.addPoint === true) {
      if (!values.points) {
        errors.points = "Required";
      } else if (!numv.test(values.points)) {
        errors.points = "Must be digits or decimal number";
      } else if (count(values.points) > 4) {
        errors.points = "Must be less than or equals to four digits";
      }
    }
    if (!values.title) {
      errors.title = "Required";
    }
    if (!values.description) {
      errors.description = "Required";
    }
    return errors;
  };

  //API for class list
  const [classList, setClassList] = useState([]);
  useEffect(() => {
    getClassList();
  }, []);

  const getClassList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      DFlag: "C",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setClassList(postResult);
      } else {
        setClassList([]);
      }
    });
  };

  //API for Section List
  const [sectionList, setSectionList] = useState([]);
  useEffect(() => {
    getSectionList();
  }, [assignmentFormValue.class]);

  const getSectionList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      ClassID: assignmentFormValue.class,
      DFlag: "S",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";

        setSectionList(postResult);
      } else {
        setSectionList([]);
      }
    });
  };

  //API for teacher list
  const [teacherList, setTeacherList] = useState([]);

  useEffect(() => {
    teachList();
  }, [assignmentFormValue.class, assignmentFormValue.section]);

  const teachList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      DFlag: "TL",
      Flag: "S",
      ClassID: assignmentFormValue.class,
      SectionID: assignmentFormValue.section,
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setTeacherList(postResult);
      } else {
        setTeacherList([]);
      }
    });
  };

  //API for subject list
  const [subjectList, setSubjectList] = useState([]);

  useEffect(() => {
    getSubjectList();
  }, [
    assignmentFormValue.class,
    assignmentFormValue.teacher,
    assignmentFormValue.section,
    section,
  ]);

  const getSubjectList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID:
        userinfo.UserType === "T"
          ? userinfo.UserID
          : assignmentFormValue.teacher,
      ClassID: assignmentFormValue.class,
      SectionID: assignmentFormValue.section,
      DFlag: "SB",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    const paramsA = {
      SchID: userinfo.SchID,
      UserID:
        userinfo.UserType === "T"
          ? userinfo.UserID
          : assignmentFormValue.teacher,
      ClassID: assignmentFormValue.class,
      SectionIDs: section,
      DFlag: "SB",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(userinfo.UserType === "A" ? params : paramsA).then(function (
      result
    ) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";

        setSubjectList(postResult);
      } else {
        setSubjectList([]);
      }
    });
  };

  const dropDownValue = sectionList.map((item) => ({
    value: item.SectionID,
    label: item.SectionName,
  }));

  return (
    <>
      <div className="container-fluid basicform">
        <form>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label className="form-label" htmlFor="class">
                Class<sup style={{ color: "red" }}>*</sup>
              </label>
              <select
                id="class"
                name="class"
                onChange={handleChange}
                value={assignmentFormValue.class}
                className="form-select form-select-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="" disabled selected style={{ fontSize: "11px" }}>
                  Select Class
                </option>
                {classList.map((item) => (
                  <option key={item.ClassID} value={item.ClassID}>
                    {item.ClassName}
                  </option>
                ))}
              </select>
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {assignmentFormError.class}
              </p>
            </div>

            <div className="form-group col-md-6">
              <label className="form-label" htmlFor="section">
                Section<sup style={{ color: "red" }}>*</sup>
              </label>
              {userinfo.UserType === "T" ? (
                <Select
                  className="select"
                  style={{ fontSize: "11px", marginBottom: "3px" }}
                  isMulti
                  options={dropDownValue}
                  onChange={(item) =>
                    setSection(
                      item.map((item) => ({
                        SectionID: item.value,
                      }))
                    )
                  }
                  isSearchable={false}
                />
              ) : (
                <select
                  id="sections"
                  name="section"
                  onChange={handleChange}
                  value={assignmentFormValue.section}
                  className="form-select form-select-sm mb-1"
                  style={{ fontSize: "12px" }}
                >
                  <option value="" selected disabled>
                    Select Option
                  </option>
                  {sectionList.map((item, i) => (
                    <option key={i} value={item.SectionID}>
                      {item.SectionName}
                    </option>
                  ))}
                </select>
              )}
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {assignmentFormError.section}
              </p>
            </div>
          </div>

          <div className="form-row">
            {userinfo.UserType !== "T" && (
              <div className="form-group col-md-6">
                <label className="form-label" htmlFor="teachers">
                  Teacher<sup style={{ color: "red" }}>*</sup>
                </label>
                <select
                  id="teachers"
                  name="teacher"
                  onChange={handleChange}
                  value={assignmentFormValue.teacher}
                  className="form-select form-select-sm mb-1"
                  style={{ fontSize: "12px" }}
                >
                  <option
                    value=""
                    disabled
                    selected
                    style={{ fontSize: "11px" }}
                  >
                    Select Teacher
                  </option>
                  {teacherList.map((item) => (
                    <option key={item.UserID} value={item.UserID}>
                      {item.FullName}
                    </option>
                  ))}
                </select>
                <p className="errormsg " style={{ marginBottom: "0" }}>
                  {assignmentFormError.teacher}
                </p>
              </div>
            )}

            <div
              className={
                userinfo.UserType === "T"
                  ? "form-group col-md-12"
                  : "form-group col-md-6"
              }
            >
              <label className="form-label" htmlFor="subject">
                Subject<sup style={{ color: "red" }}>*</sup>
              </label>
              <select
                id="subject"
                name="subject"
                onChange={handleChange}
                value={assignmentFormValue.subject}
                className="form-select form-select-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="" disabled selected style={{ fontSize: "11px" }}>
                  Select Subject
                </option>
                {subjectList.map((item) => (
                  <option key={item.SubjectID} value={item.SubjectID}>
                    {item.SubjectName}
                  </option>
                ))}
              </select>
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {assignmentFormError.subject}
              </p>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-6"></div>

            <div className="form-group col-md-6">
              <input
                type="checkbox"
                // className="form-check-input"
                id="exampleCheck3"
                name="addPoint"
                onChange={handleChange}
                checked={assignmentFormValue.addPoint}
              />
              <label
                class="form-check-label"
                for="exampleCheck3"
                style={{ fontSize: "12px", paddingLeft: "3px" }}
              >
                Add Point
              </label>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-6">
              <label
                className="form-label"
                htmlFor="submissionDate"
                style={{ fontSize: "12px" }}
              >
                Submission Date<sup style={{ color: "red" }}>*</sup>
              </label>
              <Calendar
                className="form-control form-control-sm mb-1"
                dateFormat="YYYY/MM/DD"
                theme="default"
                language="en"
                values={assignmentFormValue.submissionDate}
                onChange={handleAssignmentDate}
                minDate={getDisableDate()}
              />
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {assignmentFormError.submissionDate}
              </p>
            </div>

            <div className="form-group col-md-6">
              <label
                className="form-label"
                htmlFor="points"
                style={{ fontSize: "12px" }}
              >
                Points{" "}
                {assignmentFormValue.addPoint === true ? (
                  <sup style={{ color: "red" }}>*</sup>
                ) : (
                  <></>
                )}
              </label>
              <input
                id="points"
                style={{ fontSize: "13px" }}
                type="text"
                className="form-control form-control-sm"
                name="points"
                value={assignmentFormValue.points}
                onChange={handleChange}
                disabled={assignmentFormValue.addPoint === false}
              />
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {assignmentFormError.points}
              </p>
            </div>
          </div>

          <div className="form-group">
            <label
              className="form-label"
              htmlFor="title"
              style={{ fontSize: "12px" }}
            >
              Title<sup style={{ color: "red" }}>*</sup>
            </label>
            <input
              id="title"
              style={{ fontSize: "13px" }}
              type="text"
              className="form-control form-control-sm"
              name="title"
              value={assignmentFormValue.title}
              onChange={handleChange}
            />
            <p className="errormsg " style={{ marginBottom: "0" }}>
              {assignmentFormError.title}
            </p>
          </div>

          <div className="form-group">
            <label
              className="form-label"
              htmlFor="description"
              style={{ fontSize: "12px" }}
            >
              Description<sup style={{ color: "red" }}>*</sup>
            </label>
            <textarea
              id="description"
              style={{ fontSize: "13px" }}
              type="text"
              className="form-control form-control-sm"
              name="description"
              value={assignmentFormValue.description}
              onChange={handleChange}
            />
            <p className="errormsg " style={{ marginBottom: "0" }}>
              {assignmentFormError.description}
            </p>
          </div>

          <div className="form-group">
            <label
              className="form-label"
              htmlFor="link"
              style={{ fontSize: "12px" }}
            >
              Link
            </label>
            <input
              id="link"
              style={{ fontSize: "13px" }}
              type="text"
              className="form-control form-control-sm"
              name="link"
              value={assignmentFormValue.link}
              onChange={handleChange}
            />
          </div>

          <div className="form-row" style={{ marginTop: "12px" }}>
            <div className="form-group col-md-6">
              <input
                type="checkbox"
                // className="form-check-input"
                id="exampleCheck4"
                name="noSubmissionRequired"
                onChange={handleChange}
                checked={assignmentFormValue.noSubmissionRequired}
              />
              <label
                class="form-check-label"
                for="exampleCheck4"
                style={{ fontSize: "12px", paddingLeft: "3px" }}
              >
                No Submission Required
              </label>
            </div>

            <div className="form-group col-md-6">
              <input
                type="checkbox"
                // className="form-check-input"
                id="exampleCheck5"
                name="notifyUser"
                onChange={handleChange}
                checked={assignmentFormValue.notifyUser}
              />
              <label
                class="form-check-label"
                for="exampleCheck5"
                style={{ fontSize: "12px", paddingLeft: "3px" }}
              >
                Notify User
              </label>
            </div>
          </div>

          <div className="form-group  ">
            <div
              className="form-label"
              htmlFor="text"
              style={{ fontSize: "12px", textAlign: "left" }}
            >
              Upload Image
            </div>

            <section>
              <label className="multi-label">
                + Add Images
                <br />
                <span>up to 10 images</span>
                <input
                  className="multi-input"
                  type="file"
                  name="images"
                  onChange={handleImageChange}
                  multiple
                  accept="image/png , image/jpeg, image/webp"
                />
              </label>
              <br />

              <input className="multi-input" type="file" multiple />

              {selectedImages.length > 0 &&
                (selectedImages.length > 10 ? (
                  <p className="error">
                    You can't upload more than 10 images! <br />
                    <span>
                      Please delete <b> {selectedImages.length - 10} </b> of
                      them{" "}
                    </span>
                  </p>
                ) : (
                  <span
                    className="upload-btn"
                    // onClick={() => {
                    //
                    // }}
                  >
                    {selectedImages.length} Image Selected
                    {/* {selectedImages.length === 1 ? "" : "S"} */}
                  </span>
                ))}

              <div className="images">
                {selectedImages &&
                  selectedImages.map((image, i) => {
                    return (
                      <div key={i} className="image">
                        <img
                          className="multi-img"
                          src={image.File}
                          height="200"
                          alt="upload"
                        />
                        <button onClick={(e) => handleImageRemove([e, image])}>
                          <img src={CloseIcon} alt="cross" />
                        </button>
                      </div>
                    );
                  })}
              </div>
            </section>
          </div>
        </form>
      </div>
      <div className="FormFooter">
        <div className="checkedClose">
          <input
            type="checkbox"
            className="form-check-input"
            id="exampleCheck2"
            name="closeChecked"
            onChange={handleOnChange}
            checked={closeChecked}
          />
          <label class="form-check-label" for="exampleCheck2">
            Exit after submit
          </label>
        </div>
        <div>
          <button
            className="btn btn-sm btn-cmpy ml-2"
            style={{ background: "green" }}
            onClick={formSubmit}
          >
            Submit
          </button>
          <button className="btn btn-sm btn-cmpy ml-2" onClick={closePopup}>
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}
