import React, { useContext, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import SidebarContext from "../sidebarcontext/SidebarContext";
import CloseIcon from "../../images/CloseIcon.svg";
import Plus from "../../images/Plus.png";
import { showImgPreview } from "../hooks/ImagePreview";
import { useEffect } from "react";
import AcademicHeader from "../AcademicHeader";

export default function AboutSchool() {
  const {
    todayDate,
    AcademicYear,
    isUploaded,
    setIsUploaded,
    typeFile,
    setTypeFile,
    image,
    setImage,
    aboutFormValue,
    setAboutFormValue,
    isSubmit,
    setIsSubmit,
    editdata,
    aboutFormError,
    setAboutFormError,
  } = useContext(SidebarContext);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const SchPhoto = userinfo.SchPhoto;
  const SchLogo = userinfo.SchLogo;

  const [imgPrv, setImgPrv] = useState(false);
  const [ImgPreview, setImgPreview] = useState("");

  function handleImageChange(e) {
    if (e.target.files && e.target.files[0]) {
      setTypeFile(e.target.files[0].type);
      let reader = new FileReader();

      reader.onload = function (e) {
        setImage(e.target.result);
        setIsUploaded(true);
      };

      reader.readAsDataURL(e.target.files[0]);
    }
  }

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setAboutFormValue({ ...aboutFormValue, [name]: value });
  };

  const formNext = (e) => {
    e.preventDefault();
    setAboutFormError(validate(aboutFormValue));
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(aboutFormError).length === 0 && isSubmit) {
      editdata(aboutFormValue);
    }
    setIsSubmit(false);
  }, [aboutFormError]);

  const validate = (values) => {
    const errors = {};
    const numv = /^-?\d+(\.\d{1,50})?$/;
    if (values.latitude.length === 0) {
      setIsSubmit(true);
    } else if (!numv.test(values.latitude)) {
      errors.latitude = "Must be digits";
    }
    if (values.longitude.length === 0) {
      setIsSubmit(true);
    } else if (!numv.test(values.longitude)) {
      errors.longitude = "Must be digits";
    }
    return errors;
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
        <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">About School</div>
            <div className="page-date">
              <div className="sec-content">
                <AcademicHeader
                  todayDate={todayDate}
                  AcademicYear={AcademicYear}
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="row">
          <div
            className="col-sm-4 col-md-3"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div
              className="form-label"
              htmlFor="text"
              style={{ fontSize: "12px", textAlign: "left" }}
            >
              Logo
            </div>

            <div className="contentLogoAbout tc">
              <div className="mgmtImgAbout tc">
                <img
                  src={SchLogo}
                  alt="dp"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setImgPreview(SchLogo);
                    setImgPrv(true);
                  }}
                />
              </div>
            </div>

            <>
              <div
                className="form-label"
                htmlFor="text"
                style={{ fontSize: "12px", textAlign: "left" }}
              >
                Upload Image
              </div>

              <div
                className={
                  userinfo.UserType === "T"
                    ? "BoxUploadAbout BoxUploadTeacher"
                    : "BoxUploadAbout"
                }
              >
                <div className="image-upload">
                  {!image ? (
                    <>
                      <label htmlFor="upload-input">
                        <img
                          src={Plus}
                          draggable={"false"}
                          alt="placeholder"
                          style={{ width: 90, height: 100, paddingTop: "10px" }}
                        />
                      </label>

                      <input
                        id="upload-input"
                        type="file"
                        accept=".jpg,.jpeg,.gif,.png,.mov,.mp4"
                        onChange={handleImageChange}
                        name="image"
                      />
                    </>
                  ) : (
                    <div className="ImagePreview">
                      {userinfo.UserType !== "T" && (
                        <img
                          className="close-icon"
                          src={CloseIcon}
                          alt="CloseIcon"
                          onClick={() => {
                            setIsUploaded(false);
                            setImage(null);
                          }}
                        />
                      )}

                      <img
                        id="uploaded-image"
                        src={image}
                        draggable={false}
                        alt="uploaded-img"
                      />
                    </div>
                  )}
                </div>
              </div>
            </>
          </div>

          <div
            className="col-sm-8 col-md-9"
            style={{ height: "69vh", overflowY: "scroll" }}
          >
            <form className="mt-3 mt-sm-0">
              <div className="container-fluid basicform">
                <div className="form-group">
                  <label
                    htmlFor="schoolName"
                    className="form-label"
                    style={{ fontSize: "12px" }}
                  >
                    School Name
                  </label>
                  <input
                    id="schoolName"
                    style={{ fontSize: "13px" }}
                    type="text"
                    className="form-control form-control-sm "
                    name="schoolName"
                    value={aboutFormValue.schoolName}
                    onChange={handleChange}
                    disabled
                  />
                  {/* <p className="errormsg " style={{ marginBottom: "0" }}>{blogFormError.schoolName}</p> */}
                </div>

                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label
                      htmlFor="address"
                      className="form-label"
                      style={{ fontSize: "12px" }}
                    >
                      Address
                    </label>
                    <input
                      id="address"
                      style={{ fontSize: "13px" }}
                      type="text"
                      className="form-control form-control-sm "
                      name="address"
                      value={aboutFormValue.address}
                      onChange={handleChange}
                      disabled={userinfo.UserType === "T"}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label
                      htmlFor="ss"
                      className="form-label"
                      style={{ fontSize: "12px" }}
                    >
                      Contact
                    </label>
                    <input
                      id="ss"
                      style={{ fontSize: "13px" }}
                      type="text"
                      className="form-control form-control-sm "
                      name="contact"
                      value={aboutFormValue.contact}
                      onChange={handleChange}
                      disabled={userinfo.UserType === "T"}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label
                    htmlFor="person"
                    className="form-label"
                    style={{ fontSize: "12px" }}
                  >
                    Contact Person
                  </label>
                  <input
                    id="person"
                    style={{ fontSize: "13px" }}
                    type="text"
                    className="form-control form-control-sm "
                    name="person"
                    value={aboutFormValue.person}
                    onChange={handleChange}
                    disabled={userinfo.UserType === "T"}
                  />
                  {/* <p className="errormsg " style={{ marginBottom: "0" }}>{blogFormError.person}</p> */}
                </div>

                <div className="form-group">
                  <label
                    htmlFor="abouts"
                    className="form-label"
                    style={{ fontSize: "12px" }}
                  >
                    About
                  </label>
                  <textarea
                    id="abouts"
                    style={{ fontSize: "13px" }}
                    type="text"
                    className="form-control form-control-sm "
                    name="about"
                    value={aboutFormValue.about}
                    onChange={handleChange}
                    disabled={userinfo.UserType === "T"}
                  />
                  {/* <p className="errormsg " style={{ marginBottom: "0" }}>{blogFormError.about}</p> */}
                </div>
                <div className="form-group">
                  <label
                    htmlFor="message"
                    className="form-label"
                    style={{ fontSize: "12px" }}
                  >
                    Message
                  </label>
                  <textarea
                    id="message"
                    style={{ fontSize: "13px" }}
                    type="text"
                    className="form-control form-control-sm "
                    name="message"
                    value={aboutFormValue.message}
                    onChange={handleChange}
                    disabled={userinfo.UserType === "T"}
                  />
                </div>

                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label
                      htmlFor="latitude"
                      className="form-label"
                      style={{ fontSize: "12px" }}
                    >
                      Latitude
                    </label>
                    <input
                      id="latitude"
                      style={{ fontSize: "13px" }}
                      type="text"
                      className="form-control form-control-sm "
                      name="latitude"
                      value={aboutFormValue.latitude}
                      onChange={handleChange}
                      disabled={userinfo.UserType === "T"}
                    />
                    <p className="errormsg " style={{ marginBottom: "0" }}>
                      {aboutFormError.latitude}
                    </p>
                  </div>
                  <div className="form-group col-md-6">
                    <label
                      htmlFor="longitude"
                      className="form-label"
                      style={{ fontSize: "12px" }}
                    >
                      Longitude
                    </label>
                    <input
                      id="longitude"
                      style={{ fontSize: "13px" }}
                      type="text"
                      className="form-control form-control-sm "
                      name="longitude"
                      value={aboutFormValue.longitude}
                      onChange={handleChange}
                      disabled={userinfo.UserType === "T"}
                    />
                    <p className="errormsg " style={{ marginBottom: "0" }}>
                      {aboutFormError.longitude}
                    </p>
                  </div>
                </div>

                <div className="form-group">
                  <label
                    htmlFor="website"
                    className="form-label"
                    style={{ fontSize: "12px" }}
                  >
                    Website
                  </label>
                  <input
                    id="website"
                    style={{ fontSize: "13px" }}
                    type="text"
                    className="form-control form-control-sm "
                    name="website"
                    value={aboutFormValue.website}
                    onChange={handleChange}
                    disabled={userinfo.UserType === "T"}
                  />
                </div>

                <div className="form-group">
                  <label
                    htmlFor="facebook"
                    className="form-label"
                    style={{ fontSize: "12px" }}
                  >
                    Facebook
                  </label>
                  <input
                    id="facebook"
                    style={{ fontSize: "13px" }}
                    type="text"
                    className="form-control form-control-sm "
                    name="facebook"
                    value={aboutFormValue.facebook}
                    onChange={handleChange}
                    disabled={userinfo.UserType === "T"}
                  />
                </div>

                <div className="form-group">
                  <label
                    htmlFor="twitter"
                    className="form-label"
                    style={{ fontSize: "12px" }}
                  >
                    Twitter
                  </label>
                  <input
                    id="twitter"
                    style={{ fontSize: "13px" }}
                    type="text"
                    className="form-control form-control-sm "
                    name="twitter"
                    value={aboutFormValue.twitter}
                    onChange={handleChange}
                    disabled={userinfo.UserType === "T"}
                  />
                </div>

                <div className="form-group">
                  <label
                    htmlFor="youtube"
                    className="form-label"
                    style={{ fontSize: "12px" }}
                  >
                    Youtube
                  </label>
                  <input
                    id="youtube"
                    style={{ fontSize: "13px" }}
                    type="text"
                    className="form-control form-control-sm "
                    name="youtube"
                    value={aboutFormValue.youtube}
                    onChange={handleChange}
                    disabled={userinfo.UserType === "T"}
                  />
                </div>
              </div>
            </form>
            {userinfo.UserType !== "T" && (
              <button
                className="btn btn-sm btn-cmpy"
                style={{
                  background: "green",
                  marginTop: "10px",
                  marginBottom: "10px",
                  marginLeft: "14px",
                }}
                onClick={formNext}
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </div>

      {imgPrv &&
        showImgPreview({
          img: ImgPreview,
          setTrigger: setImgPrv,
        })}
    </>
  );
}
