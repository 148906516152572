import React, { useContext, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import "../../css/Notification.css";
import SidebarContext from "../sidebarcontext/SidebarContext";
import Spinner from "../loading/spinner";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useEffect } from "react";
import { Fetchdata } from "../hooks/getData";

export default function NotLoggedinTab() {
  const { customStylesForImage, appURL } = useContext(SidebarContext);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);
  const [tabList, setTabList] = useState([]);

  useEffect(() => {
    getList();
  }, []);

  const getList = () => {
    const params = {
      AuthCode: "r1d3r",
      SchID: userinfo.SchID,
      Flag: "R",
      DFlag: "NL",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/app-usuage`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.StudentLst ? result.StudentLst : "";
        setLoading(false);
        setTabList(postResult);
        setOriginalList(postResult);
      } else {
        setTabList([]);
        setLoading(false);
        setOriginalList([]);
      }
    });
  };

  const searchInput = useRef("");

  const columns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },

    {
      name: "Full Name",
      selector: (row) => row.FullName,
    },
    // {
    //   name: "User Type",
    //   selector: (row) => row.UserType,
    // },
    {
      name: "Last Log In Date",
      width: "400px",
      selector: (row) => row.LastLoginedDate,
    },
  ];

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["FullName"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setTabList(srchResult);
      } else {
        setTabList({});
      }
    } else {
      setTabList(originalList);
    }
  };

  /* This is for export csv */

  const dataToExcel = tabList.map((d, i) => ({
    "S.N.": i + 1,
    "Full Name": d.FullName,
    // "User Type": d.UserType,
    "Last Login": d.LastLoginedDate,
  }));

  const toExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(dataToExcel);
      var wscols = [{ wch: 5 }, { wch: 20 }, { wch: 20 }, { wch: 20 }];
      worksheet["!cols"] = wscols;
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "App Usage");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "export" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  //end

  //This is to export pdf

  const dataWithIndex = tabList.map((d, i) => ({
    ...d,
    Index: i + 1,
  }));

  const cols = [
    { header: "S.N", field: "Index" },
    { header: "Full Name", field: "FullName" },
    // { header: "User Type", field: "UserType" },
    { header: "Last Log In", field: "LastLoginedDate" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const toPdf = () => {
    const doc = new jsPDF("p", "pt", "a4", true);

    var midPage = doc.internal.pageSize.getWidth() / 2;

    // doc.addImage(userinfo.SchLogo, "PNG", 40, 40, 70, 60);

    doc.setFontSize(18);
    doc.text(userinfo.SchName, midPage, 50, null, null, "center");

    doc.setFontSize(15);
    doc.text(`${userinfo.SchAddress}`, midPage, 68, null, null, "center");

    doc.setFontSize(10);
    doc.text(
      `Tel:${userinfo.Phone}, E-mail:${userinfo.Email}`,
      midPage,
      85,
      null,
      null,
      "center"
    );

    doc.autoTable({
      startY: 100,
      theme: "grid",
      headStyles: {
        fillColor: [227, 227, 227],
        textColor: [0, 0, 0],
      },
      columns: exportColumns,
      body: dataWithIndex,
    });
    doc.save("App Usage.pdf");
  };
  //end

  return (
    <>
      <div className="sec-dataTable">
        <div className="upper-dataTbl">
          <Tippy content="PDF">
            <button className="exportButtons ml-1" onClick={toPdf}>
              <i className="bi bi-file-earmark-pdf-fill exportButtonIconsPdf"></i>
            </button>
          </Tippy>

          <Tippy content="Excel">
            <button className="exportButtons" onClick={toExcel}>
              <i className="bi bi-file-earmark-excel-fill exportButtonIconsExcel"></i>
            </button>
          </Tippy>
        </div>
        <hr className="new-hr-new" />
        {loading ? (
          <Spinner />
        ) : (
          <>
            <DataTable
              columns={columns}
              data={tabList}
              customStyles={customStylesForImage}
              pagination
              fixedHeader
              fixedHeaderScrollHeight="280px"
              highlightOnHover
              pointerOnHover
              responsive
              dense
              striped
              subHeader
              subHeaderAlign="left"
              subHeaderComponent={
                <>
                  <div
                    className="col-lg-2 offset-lg-10 offset-md-10 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
                    style={{
                      marginTop: "0px",
                      paddingRight: "1px",
                      marginBottom: "10px",
                    }}
                  >
                    <div className="d-flex">
                      <input
                        placeholder="Search"
                        ref={searchInput}
                        type="text"
                        className="form-control form-control-sm searchField"
                        onChange={searchHandler}
                      />
                    </div>
                  </div>
                </>
              }
            />
          </>
        )}
      </div>
    </>
  );
}
