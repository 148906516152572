import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import SidebarContext from "../sidebarcontext/SidebarContext";
import Spinner from "../loading/spinner";
import SliderContext from "../contextfolder/SliderContext/SliderContext";
import SliderForm from "./SliderForm";
import { showImgPreview } from "../hooks/ImagePreview";
import DeletePop from "./DeletePop";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import AcademicHeader from "../AcademicHeader";

export default function SliderList() {
  const { todayDate, customStylesForImage, AcademicYear, hideAction } =
    useContext(SidebarContext);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const {
    loading,
    originalList,
    popup,
    togglePopup,
    setSliderFormValue,
    setSliderFormError,
    setIsSubmit,
    sliderValue,
    setImage,
    setIsUploaded,
    sliderList,
    seSliderList,
    deletePopup,
    handleDeleteTrue,
    handleDeleteFalse,
    handleDelete,
    setCloseChecked,
  } = useContext(SliderContext);

  const closePopup = (e) => {
    e.preventDefault();
    togglePopup();
    setSliderFormValue(sliderValue);
    setSliderFormError({});
    setIsSubmit(false);
    setIsUploaded(false);
    setImage("");
    setCloseChecked(false);
  };

  const addnew = (e) => {
    e.preventDefault();
    togglePopup();
    setIsSubmit(false);
    setSliderFormValue(sliderValue);
    setIsUploaded(false);
    setImage("");
  };

  const [imgPrv, setImgPrv] = useState(false);
  const [imagePre, setImagePre] = useState("");

  const searchInput = useRef("");

  const columns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "Order",
      width: "70px",
      center: true,
      selector: (row) => row.Order,
    },
    {
      name: "Image",
      center: true,
      width: "70px",
      selector: (row) => {
        return (
          <>
            <div className="staffContentLogo">
              <div className="staffImg">
                <img
                  src={row.Image}
                  alt=""
                  onClick={() => {
                    setImagePre(row.Image);
                    setImgPrv(true);
                  }}
                />
              </div>
            </div>
          </>
        );
      },
    },
    {
      name: "Title",
      width: "200px",
      selector: (row) => row.Title,
    },
    {
      name: "Redirection Url",
      selector: (row) => row.RedUrl,
    },
    {
      name: "Action",
      center: true,
      width: "90px",
      omit: hideAction,
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <Tippy content="Delete">
                <i
                  className="fa fa-trash-o rejectspan--action"
                  onClick={() => handleDelete(row.SliderID)}
                ></i>
              </Tippy>
            </div>
          </>
        );
      },
    },
  ];

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["Title"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        seSliderList(srchResult);
      } else {
        seSliderList({});
      }
    } else {
      seSliderList(originalList);
    }
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
        <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">Slider</div>
            <div className="page-date">
              <div className="sec-content">
                <AcademicHeader
                  todayDate={todayDate}
                  AcademicYear={AcademicYear}
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <>
          <div className="sec-dataTable content-section main-content">
            {userinfo.UserType !== "T" && (
              <div className="upper-dataTbl">
                <div className="btn-addlnote">
                  <button
                    type="button"
                    class="btn btn-sm"
                    style={{
                      background: "var(--MainColor)",
                      color: "white",
                    }}
                    onClick={addnew}
                  >
                    Add New
                  </button>
                </div>
              </div>
            )}
            <hr className="new-hr" />
            {loading ? (
              <Spinner />
            ) : (
              <DataTable
                columns={columns}
                data={sliderList}
                customStyles={customStylesForImage}
                pagination
                fixedHeader
                fixedHeaderScrollHeight="280px"
                highlightOnHover
                pointerOnHover
                responsive
                dense
                striped
                subHeader
                subHeaderAlign="left"
                subHeaderComponent={
                  <>
                    <div
                      className="col-lg-2 offset-lg-10 offset-md-10 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
                      style={{ paddingRight: "1px", marginBottom: "10px" }}
                    >
                      <div className="d-flex">
                        <input
                          placeholder="Search"
                          ref={searchInput}
                          type="text"
                          className="form-control form-control-sm searchField"
                          onChange={searchHandler}
                        />
                      </div>
                    </div>
                  </>
                }
              />
            )}
          </div>
        </>
      </div>
      {popup && (
        <div className="modals">
          <div className="overlay"></div>

          <div className="notification-model-content">
            <div className="popUpHeader ">
              <div className="popUpHeaderText ">Add Slider</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closePopup}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="inside-modal">
              <SliderForm closePopup={closePopup} />
            </div>
          </div>
        </div>
      )}

      {deletePopup.show && (
        <DeletePop
          handleDeleteTrue={handleDeleteTrue}
          handleDeleteFalse={handleDeleteFalse}
        />
      )}

      {imgPrv &&
        showImgPreview({
          img: imagePre,
          setTrigger: setImgPrv,
        })}
    </>
  );
}
