import React, { useContext, useEffect, useState } from "react";
import { Fetchdata } from "../../hooks/getData";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { toast } from "react-toastify";
import UploadRoutineContext from "./UploadRoutineContext";

function UploadRoutineState(props) {
  const { appURL } = useContext(SidebarContext);

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const [closeCheckeds, setCloseCheckeds] = useState(false);

  const uploadValue = {
    classDetails: "",
    routineType: "C",
    section: "",
    term: "",
  };
  const [pdfBase64, setPdfBase64] = useState("");

  const [uploadFormValue, setUploadFormValue] = useState(uploadValue);
  const [uploadFormError, setUploadFormError] = useState({});
  const [uploadIsSubmit, setUploadIsSubmit] = useState(false);

  const [uploadPopup, setUploadPopup] = useState(false);
  const toggleUploadPopup = () => {
    setUploadPopup(!uploadPopup);
  };

  const [reportOption, setReportOption] = useState("");

  //API to create class routine
  const fetchdata = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "i",
      ClassID: uploadFormValue.classDetails,
      SectionID: uploadFormValue.section,
      TermID: uploadFormValue.term,
      RoutineType: uploadFormValue.routineType,
      Attachment: pdfBase64 !== "" ? pdfBase64.split(",")[1] : "",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/attach-routine`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getuploadRoutineList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };

  // API for class routine list
  const [uploadRoutineList, setUploadRoutineList] = useState([]);

  useEffect(() => {
    getuploadRoutineList();
  }, [reportOption]);

  const getuploadRoutineList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      Flag: "S",
      RoutineType: reportOption,
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/attach-routine`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.RoutineLst ? result.RoutineLst : "";
        setLoading(false);
        setUploadRoutineList(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        setUploadRoutineList([]);
        setOriginalList([]);
      }
    });
  };

  //Everything to pop up and delete the event list
  const [deletePopup, setDeletePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const [delData, setDelData] = useState();

  const handleDelete = async (data) => {
    setDelData(data);

    setDeletePopup({
      show: true,
      data,
    });
  };

  // This will perform the deletion and hide the Confirmation Box

  const handleDeleteTrue = async () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "R",
      RoutineID: delData.toString(),
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/attach-routine`, requestOptions).then(
      (result) => {
        result.json().then((resp) => {
          if (resp.StatusCode === 200) {
            getuploadRoutineList();
            toast(resp.Message, {
              style: {
                color: "green",
                fontSize: "13px",
              },
            });
          } else {
            toast(resp.Message, {
              style: {
                color: "red",
                fontSize: "13px",
              },
            });
          }
        });
      }
    );
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleDeleteFalse = () => {
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // Ends Here

  return (
    <UploadRoutineContext.Provider
      value={{
        loading,
        originalList,
        deletePopup,
        handleDeleteTrue,
        handleDeleteFalse,
        handleDelete,
        uploadPopup,
        setUploadPopup,
        toggleUploadPopup,
        uploadFormValue,
        setUploadFormValue,
        uploadFormError,
        setUploadFormError,
        uploadIsSubmit,
        uploadValue,
        setUploadIsSubmit,
        setUploadRoutineList,
        fetchdata,
        uploadRoutineList,
        closeCheckeds,
        setCloseCheckeds,
        reportOption,
        setReportOption,
        pdfBase64,
        setPdfBase64,
      }}
    >
      {props.children}
    </UploadRoutineContext.Provider>
  );
}

export default UploadRoutineState;
