import React, { useContext, useEffect, useState } from "react";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { Fetchdata } from "../../hooks/getData";
import BusContext from "../../contextfolder/BusContext/BusContext";
import AssignBusContext from "../../contextfolder/AssignBusContext/AssignBusContext";
import Select from "react-select";

export default function AssignBusForm({ closePopup }) {
  const { appURL } = useContext(SidebarContext);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const { dataList } = useContext(BusContext);
  const context = useContext(AssignBusContext);

  const {
    togglePopup,
    formError,
    setFormError,
    isSubmit,
    setIsSubmit,
    initialValue,
    formValue,
    setFormValue,
    fetchdata,
    closeChecked,
    setCloseChecked,
    section,
    setSection,
    inputFields,
    setInputFields,
  } = context;

  const handleOnChange = () => {
    setCloseChecked(!closeChecked);
  };

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setFormValue({ ...formValue, [name]: value });
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setFormError(validate(formValue));
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(formError).length === 0 && isSubmit) {
      fetchdata(formValue);
      if (closeChecked === true) {
        togglePopup();
      }
      setFormValue(initialValue);
      setInputFields([]);
      setIsSubmit(false);
    }
  }, [formError]);

  const validate = (values) => {
    const errors = {};
    if (!values.Bus) {
      errors.Bus = "Required";
    }
    if (!values.Class) {
      errors.Class = "Required";
    }

    return errors;
  };

  const [classList, setClassList] = useState([]);
  const [sectionList, setSectionList] = useState([]);

  //API to hit class list
  useEffect(() => {
    getClassList();
  }, []);

  const getClassList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      DFlag: "C",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setClassList(postResult);
      } else {
        setClassList([]);
      }
    });
  };

  //API to hit section list
  useEffect(() => {
    getSectionList();
  }, [formValue.Class]);

  const getSectionList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      ClassID: formValue.Class,
      DFlag: "S",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";

        setSectionList(postResult);
      } else {
        setSectionList([]);
      }
    });
  };

  const [studentList, setStudentList] = useState([]);

  useEffect(() => {
    stdList();
  }, [formValue.Class, formValue.Section]);

  const stdList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      Flag: "S",
      ClassID: formValue.Class,
      SectionID:
        userinfo.UserType === "T"
          ? section
          : [
              {
                SectionID: formValue.Section,
              },
            ],
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/student`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.StudentLst ? result.StudentLst : "";
        setStudentList(postResult);
      } else {
        setStudentList([]);
      }
    });
  };

  const dropDownValue = sectionList.map((item) => ({
    value: item.SectionID,
    label: item.SectionName,
  }));

  const handleAssign = (e, studentID) => {
    // Destructuring
    const { value, checked } = e.target;

    // Case 1 : The user checks the box
    if (checked) {
      inputFields.push({
        StudentID: studentID,
      });
    }

    // Case 2  : The user unchecks the box
    else {
      setInputFields(
        inputFields.filter((list) => list.StudentID !== studentID)
      );
    }
  };

  return (
    <>
      <div className="container-fluid basicform">
        <form>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label className="form-label" htmlFor="class">
                Class<sup style={{ color: "red" }}>*</sup>
              </label>
              <select
                id="class"
                name="Class"
                onChange={handleChange}
                value={formValue.Class}
                className="form-select form-select-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="" selected disabled>
                  Select Option
                </option>
                {classList.map((item, i) => (
                  <option key={i} value={item.ClassID}>
                    {item.ClassName}
                  </option>
                ))}
              </select>
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {formError.Class}
              </p>
            </div>

            <div className="form-group col-md-6">
              <label className="form-label" htmlFor="sections">
                Section<sup style={{ color: "red" }}>*</sup>
              </label>
              {userinfo.UserType === "T" ? (
                <Select
                  className="select"
                  style={{ fontSize: "11px", marginBottom: "3px" }}
                  isMulti
                  options={dropDownValue}
                  onChange={(item) =>
                    setSection(
                      item.map((item) => ({
                        SectionID: item.value,
                      }))
                    )
                  }
                  isSearchable={false}
                />
              ) : (
                <select
                  id="sections"
                  name="Section"
                  onChange={handleChange}
                  value={formValue.Section}
                  className="form-select form-select-sm mb-1"
                  style={{ fontSize: "12px" }}
                >
                  <option value="" selected disabled>
                    Select Option
                  </option>
                  {sectionList.map((item, i) => (
                    <option key={i} value={item.SectionID}>
                      {item.SectionName}
                    </option>
                  ))}
                </select>
              )}
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-12">
              <label
                className="form-label"
                htmlFor="Bus"
                style={{ fontSize: "12px" }}
              >
                Bus<sup style={{ color: "red" }}>*</sup>
              </label>
              <select
                id="Bus"
                name="Bus"
                onChange={handleChange}
                value={formValue.Bus}
                className="form-select form-select-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="" selected disabled>
                  Select Bus
                </option>
                {dataList.map((item) => (
                  <option key={item.SchoolBusID} value={item.SchoolBusID}>
                    {item.BusName}
                  </option>
                ))}
              </select>
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {formError.Bus}
              </p>
            </div>
          </div>
        </form>

        <div className="sec-dataTable" style={{ marginTop: "10px" }}>
          <>
            {studentList.length > 0 ? (
              <div className="table-responsive br-3">
                <table className="table table-bordereds text-nowrap mb-0">
                  <thead>
                    <tr className="tableHead">
                      <td className="tc" style={{ width: "20px" }}>
                        S.N
                      </td>

                      <td className="tl">Full Name</td>
                      <td className="tc" style={{ width: "100px" }}>
                        Status
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {studentList.map((student, i) => (
                      <tr key={i}>
                        <td className="tc" style={{ width: "70px" }}>
                          {i + 1}
                        </td>

                        <td className="tl">{student.StdFullName}</td>

                        <td className="tc">
                          <input
                            type="checkbox"
                            id="assignCheck"
                            name="assignCheck"
                            onChange={(e) => handleAssign(e, student.StudentID)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="table-responsive br-3">
                <div className="text-center mt-4">
                  <p>There are no records to display.</p>
                </div>
              </div>
            )}
          </>
        </div>
      </div>
      <div className="FormFooter">
        <div className="checkedClose">
          <input
            type="checkbox"
            className="form-check-input"
            id="exampleCheck2"
            name="closeChecked"
            onChange={handleOnChange}
            checked={closeChecked}
          />
          <label class="form-check-label" for="exampleCheck2">
            Exit after submit
          </label>
        </div>
        <div>
          <button
            className="btn btn-sm btn-cmpy ml-2"
            style={{ background: "green" }}
            onClick={formSubmit}
          >
            Submit
          </button>
          <button className="btn btn-sm btn-cmpy ml-2" onClick={closePopup}>
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}
