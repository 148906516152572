import React, { useContext, useEffect, useState } from "react";
import { Fetchdata } from "../../hooks/getData";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { toast } from "react-toastify";
import OnlineClassContext from "./OnlineClassContext";

function OnlineClassState(props) {
  const { appURL } = useContext(SidebarContext);

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const [closeChecked, setCloseChecked] = useState(false);

  const onlineValue = {
    class: "",
    section: "",
    teacher: "",
    subject: "",
    tool: "",
    meetingID: "",
    meetingPassword: "",
    meetingUrl: "",
  };
  const [onlineFormValue, setOnlineFormValue] = useState(onlineValue);
  const [onlineFormError, setOnlineFormError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const [popup, setPopup] = useState(false);
  const togglePopup = () => {
    setPopup(!popup);
  };

  //State for Activate Status
  const [activateStatusFormError, setActivateStatusFormError] = useState({});
  const [activateIsSubmit, setActivateIsSubmit] = useState(false);

  const activatestatusvalue = {
    notify: "",
    autoAttendance: "",
  };

  const [activateStatusFormValue, setActivateStatusFormValue] =
    useState(activatestatusvalue);
  //end

  //State for Inactivate Status
  const [inActivateStatusFormError, setInActivateStatusFormError] = useState(
    {}
  );
  const [inActivateIsSubmit, setInActivateIsSubmit] = useState(false);

  const inactivatestatusvalue = {
    notify: "",
    autoAttendance: "",
  };

  const [inActivateStatusFormValue, setInActivateStatusFormValue] = useState(
    inactivatestatusvalue
  );
  //end

  const [classList, setClassList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [teacherList, setTeacherList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);

  const [selectClass, setSelectClass] = useState("");
  const [selectSection, setSelectSection] = useState("");
  const [selectTeacher, setSelectTeacher] = useState("");
  const [selectSubject, setSelectSubject] = useState("");

  useEffect(() => {
    getClassList();
  }, []);

  const getClassList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      DFlag: "C",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setClassList(postResult);
      } else {
        setClassList([]);
      }
    });
  };

  useEffect(() => {
    getSectionList();
  }, [selectClass]);

  const getSectionList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      ClassID: selectClass,
      DFlag: "S",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setSectionList(postResult);
      } else {
        setSectionList([]);
      }
    });
  };

  //API to hit Teacher list

  useEffect(() => {
    teachList();
  }, [selectClass, selectSection]);

  const teachList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      DFlag: "TL",
      Flag: "S",
      ClassID: selectClass,
      SectionID: selectSection,
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setTeacherList(postResult);
      } else {
        setTeacherList([]);
      }
    });
  };

  //API to hit Subject List

  useEffect(() => {
    getSubjectList();
  }, [selectClass, selectTeacher, selectSection]);

  const getSubjectList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : selectTeacher,
      ClassID: selectClass,
      SectionID: selectSection,
      DFlag: "SB",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";

        setSubjectList(postResult);
      } else {
        setSubjectList([]);
      }
    });
  };

  //API to create Online Class
  const fetchdata = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "i",
      ClassID: onlineFormValue.class,
      SectionID: onlineFormValue.section,
      SubjectID: onlineFormValue.subject,
      TeacherID:
        userinfo.UserType === "T" ? userinfo.UserID : onlineFormValue.teacher,
      Tool: onlineFormValue.tool,
      MeetingID: onlineFormValue.meetingID,
      MeetingPwd: onlineFormValue.meetingPassword,
      MeetingUrl: onlineFormValue.meetingUrl,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/online-class`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getOnlineList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };

  // API for online List
  const [onlineList, setOnlineList] = useState([]);

  useEffect(() => {
    getOnlineList();
  }, [selectClass, selectSection, selectSubject, selectTeacher]);

  const getOnlineList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "S",
      ClassID: selectClass,
      SectionID: selectSection,
      SubjectID: selectSubject,
      TeacherID: userinfo.UserType === "T" ? userinfo.UserID : selectTeacher,
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/online-class`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.OnlineClassLst ? result.OnlineClassLst : "";
        setLoading(false);
        setOnlineList(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        setOnlineList([]);
        setOriginalList([]);
      }
    });
  };

  // //Everything to pop up and delete the event list
  const [deletePopup, setDeletePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const [delData, setDelData] = useState();

  const handleDelete = async (data) => {
    setDelData(data);
    setDeletePopup({
      show: true,
      data,
    });
  };

  // This will perform the deletion and hide the Confirmation Box

  const handleDeleteTrue = async () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "R",
      OnlineClassID: delData,
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/online-class`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          getOnlineList();
          toast(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
        } else {
          toast(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
    });
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleDeleteFalse = () => {
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // Ends Here

  //Activate popup starts from here
  //Everything to pop up and approve the  list
  const [activatePopup, setActivatePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });
  // To approve the credit List

  const [Acdata, setACData] = useState();
  const [activeData, setActiveData] = useState();

  const handleActivate = async (data) => {
    setACData(data.OnlineClassID);
    setActiveData(data.IsActive);

    setActivatePopup({
      show: true,
      data,
    });
  };

  // This will perform the approve and hide the Confirmation Box

  const handleActivateTrue = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "US",
      OnlineClassID: Acdata,
      IsActive: "Y",
      IsNotify: activateStatusFormValue.notify === true ? "Y" : "N",
      IsAutoAttendance:
        activateStatusFormValue.autoAttendance === true ? "Y" : "N",
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/online-class`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          getOnlineList();
          toast(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
        } else {
          toast(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
    });
    setActivatePopup({
      show: false,
      data: null,
    });
    setActivateStatusFormValue(activatestatusvalue);
    setActivateStatusFormError({});
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleActivateFalse = () => {
    setActivatePopup({
      show: false,
      data: null,
    });
    setActivateStatusFormValue(activatestatusvalue);
    setActivateStatusFormError({});
  };

  //Everything to pop up and activate  ends

  //Activate popup starts from here
  //Everything to pop up and approve the  list
  const [inActivatePopup, setInActivatePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });
  // To approve the credit List

  const [inactivedata, setInactiveData] = useState();
  const [inactiveStatus, setInactiveStatus] = useState();

  const handleInActivate = async (data) => {
    setInactiveData(data.OnlineClassID);
    setInactiveStatus(data.IsActive);

    setInActivatePopup({
      show: true,
      data,
    });
  };

  // This will perform the approve and hide the Confirmation Box

  const handleInActivateTrue = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "US",
      OnlineClassID: inactivedata,
      IsActive: "N",
      IsNotify: inActivateStatusFormValue.notify === true ? "Y" : "N",
      IsAutoAttendance:
        inActivateStatusFormValue.autoAttendance === true ? "Y" : "N",
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/online-class`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          getOnlineList();
          toast(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
        } else {
          toast(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
    });
    setInActivatePopup({
      show: false,
      data: null,
    });
    setInActivateStatusFormValue(inactivatestatusvalue);
    setInActivateStatusFormError({});
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleInActivateFalse = () => {
    setInActivatePopup({
      show: false,
      data: null,
    });
    setInActivateStatusFormValue(inactivatestatusvalue);
    setInActivateStatusFormError({});
  };

  //Everything to pop up and approve the credit list ends

  return (
    <OnlineClassContext.Provider
      value={{
        loading,
        originalList,
        deletePopup,
        handleDeleteTrue,
        handleDeleteFalse,
        handleDelete,
        popup,
        setPopup,
        togglePopup,
        onlineList,
        setOnlineList,
        onlineFormValue,
        setOnlineFormValue,
        onlineFormError,
        setOnlineFormError,
        isSubmit,
        onlineValue,
        setIsSubmit,
        classList,
        sectionList,
        teacherList,
        selectClass,
        setSelectClass,
        selectSection,
        setSelectSection,
        selectTeacher,
        setSelectTeacher,
        closeChecked,
        setCloseChecked,
        subjectList,
        selectSubject,
        setSelectSubject,
        fetchdata,

        activateStatusFormError,
        setActivateStatusFormError,
        activateIsSubmit,
        setActivateIsSubmit,
        activatestatusvalue,
        activateStatusFormValue,
        setActivateStatusFormValue,
        inActivateStatusFormError,
        setInActivateStatusFormError,
        inActivateIsSubmit,
        setInActivateIsSubmit,
        inActivateStatusFormValue,
        setInActivateStatusFormValue,
        inactivatestatusvalue,

        activatePopup,
        setActivatePopup,
        handleActivate,
        handleActivateTrue,
        handleActivateFalse,
        inActivatePopup,
        setInActivatePopup,
        handleInActivate,
        handleInActivateTrue,
        handleInActivateFalse,
      }}
    >
      {props.children}
    </OnlineClassContext.Provider>
  );
}

export default OnlineClassState;
