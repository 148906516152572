import React, { useContext, useState, useEffect, useRef } from "react";
import DataTable from "react-data-table-component";
import AttendanceContext from "../contextfolder/AttendanceContext/AttendanceContext";
import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";
import { Fetchdata } from "../hooks/getData";
import { showImgPreview } from "../hooks/ImagePreview";
import Select from "react-select";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import { getDisableDates } from "../hooks/dateConvertor";

export default function AttendancePop({ closePopup }) {
  const context = useContext(AttendanceContext);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const { appURL } = useContext(SidebarContext);

  const {
    togglePopup,
    attendanceFormError,
    setAttendanceFormError,
    isSubmit,
    setIsSubmit,
    attendanceValue,
    attendanceFormValue,
    setAttendanceFormValue,
    fetchdata,
    loading,
    setCloseChecked,
    closeChecked,
    section,
    setSection,
    inputFields,
    setInputFields,
    subjectWise,
    setSubjectWise,
  } = context;

  const handleOnChange = () => {
    setCloseChecked(!closeChecked);
  };
  const handleSubjectWise = () => {
    setSubjectWise(!subjectWise);
  };

  const { customStylesForImage } = useContext(SidebarContext);

  const [imgPrv, setImgPrv] = useState(false);
  const [imagePre, setImagePre] = useState("");

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setAttendanceFormValue({ ...attendanceFormValue, [name]: value });
  };

  const handleAttendanceDate = ({ adDate }) => {
    setAttendanceFormValue({ ...attendanceFormValue, attendanceDate: adDate });
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setAttendanceFormError(validate(attendanceFormValue));
    setIsSubmit(true);
  };
  useEffect(() => {
    if (Object.keys(attendanceFormError).length === 0 && isSubmit) {
      fetchdata(attendanceFormValue);
      if (closeChecked === true) {
        togglePopup();
      }
      setAttendanceFormValue(attendanceValue);
      setSection([]);
      setInputFields([]);
      setIsSubmit(false);
      setSubjectWise(false);
    }
  }, [attendanceFormError]);

  const validate = (values) => {
    const errors = {};
    if (!values.class) {
      errors.class = "Required";
    }
    if (userinfo.UserType !== "T") {
      if (!values.teacher) {
        errors.teacher = "Required";
      }
    }

    if (!values.attendanceDate) {
      errors.attendanceDate = "Required";
    }
    if (subjectWise === true) {
      if (!values.subject) {
        errors.subject = "Required";
      }
    }

    return errors;
  };

  const [classList, setClassList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);

  //API to hit class list
  useEffect(() => {
    getClassList();
  }, []);

  const getClassList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      DFlag: "C",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setClassList(postResult);
      } else {
        setClassList([]);
      }
    });
  };

  //API to hit section list
  useEffect(() => {
    getSectionList();
  }, [attendanceFormValue.class]);

  const getSectionList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      ClassID: attendanceFormValue.class,
      DFlag: "S",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";

        setSectionList(postResult);
      } else {
        setSectionList([]);
      }
    });
  };

  useEffect(() => {
    getSubjectList();
  }, [
    attendanceFormValue.class,
    attendanceFormValue.section,
    attendanceFormValue.teacher,
    section,
  ]);

  const getSubjectList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID:
        userinfo.UserType === "T"
          ? userinfo.UserID
          : attendanceFormValue.teacher,
      ClassID: attendanceFormValue.class,
      SectionID: attendanceFormValue.section,
      DFlag: "SB",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };
    const paramsA = {
      SchID: userinfo.SchID,
      UserID:
        userinfo.UserType === "T"
          ? userinfo.UserID
          : attendanceFormValue.teacher,
      ClassID: attendanceFormValue.class,
      SectionIDs: section,
      DFlag: "SB",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(userinfo.UserType === "A" ? params : paramsA).then(function (
      result
    ) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";

        setSubjectList(postResult);
      } else {
        setSubjectList([]);
      }
    });
  };

  //API to hit Teacher list
  const [teacherList, setTeacherList] = useState([]);

  useEffect(() => {
    teachList();
  }, [attendanceFormValue.class, attendanceFormValue.section]);

  const teachList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      DFlag: "TL",
      Flag: "S",
      ClassID: attendanceFormValue.class,
      SectionID: attendanceFormValue.section,
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setTeacherList(postResult);
      } else {
        setTeacherList([]);
      }
    });
  };

  //API to hit Student list
  const [studentList, setStudentList] = useState([]);
  const [originalList, setOriginalList] = useState("");

  useEffect(() => {
    stdList();
  }, [attendanceFormValue.class, section, attendanceFormValue.section]);

  const stdList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      Flag: "S",
      ClassID: attendanceFormValue.class,
      SectionID:
        userinfo.UserType === "T"
          ? section
          : [
              {
                SectionID: attendanceFormValue.section,
              },
            ],
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/student`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.StudentLst ? result.StudentLst : "";
        setStudentList(postResult);
        setOriginalList(postResult);
      } else {
        setStudentList([]);
        setOriginalList([]);
      }
    });
  };

  // <>
  //     <div key={row.StudentID}>
  //         <label>
  //             <input type="radio" name={`${row.StudentID}_${row.SectionID}`} value="P" onChange={handleAttendanceChange} defaultChecked={false}/>
  //             P
  //         </label>
  //         |
  //         <label>
  //             <input type="radio" name={`${row.StudentID}_${row.SectionID}`} value="A" onChange={handleAttendanceChange} defaultChecked={true}/>
  //             A
  //         </label>
  //     </div>
  // </>

  // <>
  //     {attendance.map((attendee) => (
  //         <div key={attendee.StudentID}>
  //             <p>{`Student ID: ${attendee.StudentID}`}</p>
  //             <label>
  //                 <input
  //                     type="radio"
  //                     name={attendee.StudentID}
  //                     value="P"
  //                     checked={attendee.AttenStatus === "P"}
  //                     onChange={() =>
  //                         handleAttendanceChange(attendee.StudentID, "P")
  //                     }
  //                 />
  //                 Present
  //             </label>
  //             <label>
  //                 <input
  //                     type="radio"
  //                     name={attendee.StudentID}
  //                     value="A"
  //                     checked={attendee.AttenStatus === "A"}
  //                     onChange={() =>
  //                         handleAttendanceChange(attendee.StudentID, "A")
  //                     }
  //                 />
  //                 Absent
  //             </label>
  //         </div>
  //     ))}

  // </>
  <></>;

  // function getAttendanceArray() {
  //     return Object.values(inputFields);
  // }

  // const attendanceArray = getAttendanceArray();

  //
  //

  // const handleAttendanceChange = (event) => {
  //     const { name, value } = event.target;
  //     const [memID, sectionID] = name.split("_");
  //     const attenStatus = value === "P" ? "P" : "A";
  //     const attendanceL = { MemID: memID, AttenStatus: attenStatus, SectionID: sectionID };
  //     const newAttendanceList = inputFields.filter((att) => att.MemID !== memID);
  //     setInputFields([...newAttendanceList, attendanceL]);
  // };

  useEffect(() => {
    const attendance = studentList.map((student) => ({
      MemID: student.StudentID,
      AttenStatus: "P",
      SectionID: student.SectionID,
    }));
    setInputFields(attendance);
  }, [studentList]);

  const handleAttendanceChange = (studentID) => (e) => {
    const newAttendanceList = inputFields.map((attendance) =>
      attendance.MemID === studentID
        ? {
            MemID: studentID,
            AttenStatus: e.target.value,
            SectionID: attendance.SectionID,
          }
        : attendance
    );
    setInputFields(newAttendanceList);
  };

  const searchInput = useRef("");

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["StdFullName"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setStudentList(srchResult);
      } else {
        setStudentList({});
      }
    } else {
      setStudentList(originalList);
    }
  };

  const dropDownValue = sectionList.map((item) => ({
    value: item.SectionID,
    label: item.SectionName,
  }));

  return (
    <>
      <div className="container-fluid basicform">
        <form>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label className="form-label" htmlFor="class">
                Class<sup style={{ color: "red" }}>*</sup>
              </label>
              <select
                id="class"
                name="class"
                onChange={handleChange}
                value={attendanceFormValue.class}
                className="form-select form-select-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="" selected disabled>
                  Select Option
                </option>
                {classList.map((item, i) => (
                  <option key={i} value={item.ClassID}>
                    {item.ClassName}
                  </option>
                ))}
              </select>
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {attendanceFormError.class}
              </p>
            </div>

            <div className="form-group col-md-6">
              <label className="form-label" htmlFor="sections">
                Section<sup style={{ color: "red" }}>*</sup>
              </label>
              {userinfo.UserType === "T" ? (
                <Select
                  className="select"
                  style={{ fontSize: "11px", marginBottom: "3px" }}
                  isMulti
                  options={dropDownValue}
                  onChange={(item) =>
                    setSection(
                      item.map((item) => ({
                        SectionID: item.value,
                      }))
                    )
                  }
                  isSearchable={false}
                />
              ) : (
                <select
                  id="sections"
                  name="section"
                  onChange={handleChange}
                  value={attendanceFormValue.section}
                  className="form-select form-select-sm mb-1"
                  style={{ fontSize: "12px" }}
                >
                  <option value="" selected disabled>
                    Select Option
                  </option>
                  {sectionList.map((item, i) => (
                    <option key={i} value={item.SectionID}>
                      {item.SectionName}
                    </option>
                  ))}
                </select>
              )}
            </div>
          </div>
          <div className="form-row">
            {userinfo.UserType !== "T" ? (
              <div className="form-group col-md-6">
                <label className="form-label" htmlFor="teachers">
                  Teacher<sup style={{ color: "red" }}>*</sup>
                </label>
                <select
                  id="teachers"
                  name="teacher"
                  onChange={handleChange}
                  value={attendanceFormValue.teacher}
                  className="form-select form-select-sm mb-1"
                  style={{ fontSize: "12px" }}
                >
                  <option value="" selected disabled>
                    Select Option
                  </option>
                  {teacherList.map((item, i) => (
                    <option key={i} value={item.UserID}>
                      {item.FullName}
                    </option>
                  ))}
                </select>
                <p className="errormsg " style={{ marginBottom: "0" }}>
                  {attendanceFormError.teacher}
                </p>
              </div>
            ) : (
              <></>
            )}

            <div
              className={
                userinfo.UserType === "T"
                  ? "form-group col-md-12"
                  : "form-group col-md-6"
              }
            >
              <label
                className="form-label"
                htmlFor="attendanceDate"
                style={{ fontSize: "12px" }}
              >
                Date<sup style={{ color: "red" }}>*</sup>
              </label>
              <Calendar
                className="form-control form-control-sm mb-1"
                dateFormat="YYYY/MM/DD"
                theme="default"
                language="en"
                values={attendanceFormValue.attendanceDate}
                onChange={handleAttendanceDate}
                maxDate={getDisableDates()}
              />
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {attendanceFormError.attendanceDate}
              </p>
            </div>
          </div>

          <div className="form-group">
            <div style={{ marginTop: "5px" }}>
              <input
                type="checkbox"
                // className="form-check-input"
                id="exampleCheck3"
                name="notWorking"
                onChange={handleSubjectWise}
                checked={subjectWise}
              />
              <label
                class="form-check-label"
                for="exampleCheck3"
                style={{ fontSize: "12px", paddingLeft: "3px" }}
              >
                Subject
              </label>
            </div>
          </div>

          {subjectWise === true && (
            <div className="form-group">
              <label className="form-label" htmlFor="subject">
                Subject<sup style={{ color: "red" }}>*</sup>
              </label>
              <select
                id="subject"
                name="subject"
                onChange={handleChange}
                value={attendanceFormValue.subject}
                className="form-select form-select-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="" selected disabled>
                  Select Option
                </option>
                {subjectList.map((item, i) => (
                  <option key={i} value={item.SubjectID}>
                    {item.SubjectName}
                  </option>
                ))}
              </select>
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {attendanceFormError.subject}
              </p>
            </div>
          )}
        </form>

        <div className="sec-dataTable" style={{ marginTop: "10px" }}>
          {loading ? (
            <Spinner />
          ) : (
            <>
              {studentList.length > 0 ? (
                <div className="table-responsive br-3">
                  <table className="table table-bordereds text-nowrap mb-0">
                    <thead>
                      <tr className="tableHead">
                        <td className="tc" style={{ width: "20px" }}>
                          Roll No.
                        </td>
                        <td className="tl" style={{ width: "20px" }}>
                          Image
                        </td>
                        <td className="tl">Student</td>
                        <td className="tc" style={{ width: "100px" }}>
                          Action
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {studentList.map((student) => (
                        <tr key={student.RollNo}>
                          <td className="tc" style={{ width: "70px" }}>
                            {student.RollNo}
                          </td>
                          <td className="tl">
                            <>
                              <div className="staffContentLogo">
                                <div className="staffImg">
                                  <img src={student.Image} alt="" />
                                </div>
                              </div>
                            </>
                          </td>
                          <td className="tl">{student.StdFullName}</td>

                          <td className="tc">
                            {/* <div key={item.StudentID}>
                                                            <label>
                                                                <input type="radio" name={`${item.StudentID}_${item.SectionID}`} value="P" onChange={handleAttendanceChange} />
                                                                P
                                                            </label>
                                                            |
                                                            <label>
                                                                <input type="radio" name={`${item.StudentID}_${item.SectionID}`} value="A" onChange={handleAttendanceChange} />
                                                                A
                                                            </label>
                                                        </div> */}
                            <label>
                              P
                              <input
                                type="radio"
                                name={`attendance-${student.StudentID}`}
                                value="P"
                                checked={
                                  inputFields.find(
                                    (attendance) =>
                                      attendance.MemID === student.StudentID
                                  )?.AttenStatus === "P"
                                }
                                onChange={handleAttendanceChange(
                                  student.StudentID
                                )}
                              />
                            </label>
                            |
                            <label>
                              A
                              <input
                                type="radio"
                                name={`attendance-${student.StudentID}`}
                                value="A"
                                checked={
                                  inputFields.find(
                                    (attendance) =>
                                      attendance.MemID === student.StudentID
                                  )?.AttenStatus === "A"
                                }
                                onChange={handleAttendanceChange(
                                  student.StudentID
                                )}
                              />
                            </label>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="table-responsive br-3">
                  <div className="text-center mt-4">
                    <p>There are no records to display.</p>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className="FormFooter">
        <div className="checkedClose">
          <input
            type="checkbox"
            className="form-check-input"
            id="exampleCheck2"
            name="closeChecked"
            onChange={handleOnChange}
            checked={closeChecked}
          />
          <label class="form-check-label" for="exampleCheck2">
            Exit after submit
          </label>
        </div>
        <div>
          <button
            className="btn btn-sm btn-cmpy ml-2"
            style={{ background: "green" }}
            onClick={formSubmit}
          >
            Submit
          </button>
          <button className="btn btn-sm btn-cmpy ml-2" onClick={closePopup}>
            Cancel
          </button>
        </div>
      </div>

      {imgPrv &&
        showImgPreview({
          img: imagePre,
          setTrigger: setImgPrv,
        })}
    </>
  );
}

{
  /* <DataTable
                            columns={columns}
                            data={studentList}
                            customStyles={customStylesForImage}
                            pagination
                            fixedHeader
                            fixedHeaderScrollHeight="280px"
                            highlightOnHover
                            pointerOnHover
                            responsive
                            dense
                            striped
                            subHeader
                            subHeaderComponent={
                                <>
                                    <div className="upper-dataTbl">
                                        <div className="d-flex">
                                            <input
                                                placeholder="Search"
                                                ref={searchInput}
                                                type="text"
                                                className="form-control form-control-sm searchField"
                                                onChange={searchHandler}
                                            />
                                        </div>
                                    </div>
                                </>
                            }
                        /> */
}
