import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import ExtraActivityContext from "../contextfolder/ExtraActivityContext/ExtraActivityContext";
import MarksInputContext from "../contextfolder/MarksInputContext/MarksInputContext";
import ExtraActivity from "../ExtraActivity/ExtraActivity";
import MarksInput from "../MarksInput/MarksInput";
import SidebarContext from "../sidebarcontext/SidebarContext";
import "../TabbedView/TabbedView.css";
import ExtraActMonth from "../ExtraActMonth/ExtraActMonth";
import ExtraActMonthContext from "../contextfolder/ExtraActMonthContext/ExtraActMonthContext";
import AttenInput from "../AttenInput/AttenInput";
import AttenInputContext from "../contextfolder/AttenInputContext/AttenInputContext";
import AcademicHeader from "../AcademicHeader";

export default function MarksCombined() {
  const { AcademicYear, todayDate } = useContext(SidebarContext);

  const { setIsSubmit, isSubmit, fetchdata, marksInputList, setMarkList } =
    useContext(MarksInputContext);
  const {
    setIsAttenSubmit,
    isAttenSubmit,
    fetchAttendata,
    attenInputList,
    setAttenList,
  } = useContext(AttenInputContext);

  const { setEisSubmit, isEsubmit, fetchExtradata, eList } =
    useContext(ExtraActivityContext);
  const { setMisSubmit, isMsubmit, fetchMExtradata, mList } =
    useContext(ExtraActMonthContext);

  const [activeTab, setActiveTab] = useState({
    tab1: true,
    tab2: false,
    tab3: false,
    tab4: false,
  });
  const handleTab1 = () => {
    setActiveTab({
      tab1: true,
      tab2: false,
      tab3: false,
      tab4: false,
    });
  };
  const handleTab2 = () => {
    setActiveTab({
      tab1: false,
      tab2: true,
      tab3: false,
      tab4: false,
    });
  };
  const handleTab3 = () => {
    setActiveTab({
      tab1: false,
      tab2: false,
      tab3: true,
      tab4: false,
    });
  };
  const handleTab4 = () => {
    setActiveTab({
      tab1: false,
      tab2: false,
      tab3: false,
      tab4: true,
    });
  };

  const [state, setState] = useState(false);

  const formSubmit = (e) => {
    e.preventDefault();
    setIsSubmit(true);
    setState(true);
  };
  useEffect(() => {
    if (state && isSubmit) {
      fetchdata();
      setIsSubmit(false);
    }
    setMarkList([]);
  }, [state, isSubmit]);

  // This is for extra activity marks and month extra marks this has been done after project has been finalized

  const [eState, setEState] = useState(false);

  const formESubmit = (e) => {
    e.preventDefault();
    setEisSubmit(true);
    setEState(true);
  };
  useEffect(() => {
    if (eState && isEsubmit) {
      fetchExtradata();
      setEisSubmit(false);
    }
  }, [eState, isEsubmit]);

  const [mState, setMState] = useState(false);

  const formMSubmit = (e) => {
    e.preventDefault();
    setMisSubmit(true);
    setMState(true);
  };
  useEffect(() => {
    if (mState && isMsubmit) {
      fetchMExtradata();
      setMisSubmit(false);
    }
  }, [mState, isMsubmit]);

  // This is for extra activity marks and month extra marks this has been done after project has been finalized

  const [aState, setAState] = useState(false);

  const formASubmit = (e) => {
    e.preventDefault();
    setIsAttenSubmit(true);
    setAState(true);
  };
  useEffect(() => {
    if (aState && isAttenSubmit) {
      fetchAttendata();
      setIsAttenSubmit(false);
    }
    setAttenList([]);
  }, [aState, isAttenSubmit]);
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
        <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">
              {activeTab.tab1
                ? "Exam Marks"
                : activeTab.tab2
                ? "Extra Activity Marks"
                : activeTab.tab3
                ? "Monthly Evaluation"
                : activeTab.tab4
                ? "Atten Input"
                : ""}
            </div>
            <div className="page-date">
              <div className="sec-content">
                <AcademicHeader
                  todayDate={todayDate}
                  AcademicYear={AcademicYear}
                />
              </div>
            </div>
          </div>
        </div>
        <hr />

        <div className="tabview ">
          <div className="insidePopup--div">
            <ul>
              <li
                className={activeTab.tab1 === true ? "active" : ""}
                onClick={handleTab1}
              >
                <i className="fa fa-file-text-o mr-1 icon"></i>Exam Marks{" "}
              </li>
              <li
                className={activeTab.tab2 === true ? "active" : ""}
                onClick={handleTab2}
              >
                <i className="fa fa-file-text-o mr-1 icon"></i>Extra Activity
                Marks{" "}
              </li>
              <li
                className={activeTab.tab4 === true ? "active" : ""}
                onClick={handleTab4}
              >
                <i className="fa fa-file-text-o mr-1 icon"></i>Atten Input{" "}
              </li>
              <li
                className={activeTab.tab3 === true ? "active" : ""}
                onClick={handleTab3}
              >
                <i className="fa fa-file-text-o mr-1 icon"></i>Monthly
                Evaulation{" "}
              </li>
            </ul>
          </div>

          {activeTab.tab2 === true && eList.length > 0 ? (
            <div className="upper-dataTbl add-btn" style={{ float: "right" }}>
              <div className="btn-addlnote">
                <button
                  type="button"
                  class="btn btn-sm"
                  style={{
                    background: "var(--MainColor)",
                    color: "white",
                  }}
                  onClick={formESubmit}
                >
                  Update
                </button>
              </div>
            </div>
          ) : (
            <></>
          )}
          {activeTab.tab3 === true && mList.length > 0 ? (
            <div className="upper-dataTbl add-btn" style={{ float: "right" }}>
              <div className="btn-addlnote">
                <button
                  type="button"
                  class="btn btn-sm"
                  style={{
                    background: "var(--MainColor)",
                    color: "white",
                  }}
                  onClick={formMSubmit}
                >
                  Update
                </button>
              </div>
            </div>
          ) : (
            <></>
          )}
          {activeTab.tab1 === true && marksInputList.length > 0 ? (
            <div className="upper-dataTbl add-btn" style={{ float: "right" }}>
              <div className="btn-addlnote">
                <button
                  type="button"
                  class="btn btn-sm"
                  style={{
                    background: "var(--MainColor)",
                    color: "white",
                  }}
                  onClick={formSubmit}
                >
                  Update
                </button>
              </div>
            </div>
          ) : (
            <></>
          )}
          {activeTab.tab4 === true && attenInputList.length > 0 ? (
            <div className="upper-dataTbl add-btn" style={{ float: "right" }}>
              <div className="btn-addlnote">
                <button
                  type="button"
                  class="btn btn-sm"
                  style={{
                    background: "var(--MainColor)",
                    color: "white",
                  }}
                  onClick={formASubmit}
                >
                  Update
                </button>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className="content-section main-content">
          {activeTab.tab1 && <MarksInput />}
          {activeTab.tab2 && <ExtraActivity />}
          {activeTab.tab3 && <ExtraActMonth />}
          {activeTab.tab4 && <AttenInput />}
        </div>
      </div>
    </>
  );
}
