import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";
import { showImgPreview } from "../hooks/ImagePreview";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { GetNepaliDate } from "../hooks/dateConvertor";
import AcademicYearForm from "./AcademicYearForm";
import AcademicYearContext from "../contextfolder/AcademicYearContext/AcademicYearContext";
import { FetchNewdata } from "../hooks/getData";
import AcademicHeader from "../AcademicHeader";

export default function AcademicYearList() {
  const { todayDate, customStylesForImage, appURL, AcademicYear } =
    useContext(SidebarContext);
  const {
    loading,
    originalList,
    popup,
    togglePopup,
    setIsSubmit,
    setFormValue,
    setFormError,
    AcademicYearValue,
    AcademicYearList,
    setAcademicYearList,
    getAcademicYearList,
    setCloseChecked,
  } = useContext(AcademicYearContext);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const searchInput = useRef("");

  const columns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "Academic Year",
      selector: (row) => row.AcademicYear,
    },

    {
      name: "Start Date",
      width: "120px",
      center: true,
      selector: (row) => row.YearFrom,
    },
    {
      name: "End Date",
      width: "120px",
      center: true,
      selector: (row) => row.YearTo,
    },
    {
      name: "Current",
      width: "100px",
      center: true,
      selector: (row) => {
        return (
          <>
            <input
              type="checkbox"
              id="exampleCheck2"
              checked={row.IsActive === "Yes" ? true : false}
              style={{ fontSize: "12px", cursor: "pointer" }}
              onChange={() => {
                handleFlag(row.AcademicYear);
              }}
            />
          </>
        );
      },
    },
  ];
  const handleFlag = (AcademicYear) => {
    const dataForm = {
      SchID: userinfo.SchID,
      Flag: "CA",
      AcademicYear: AcademicYear,
      AuthCode: "r1d3r",
      Type: "POST",
      FetchURL: `${appURL}api/admin/academic-year`,
    };

    FetchNewdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getAcademicYearList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };
  const closePopup = (e) => {
    e.preventDefault();
    togglePopup();
    setFormValue(AcademicYearValue);
    setFormError({});
    setCloseChecked(false);
    setIsSubmit(false);
  };

  const addnew = (e) => {
    e.preventDefault();
    togglePopup();
    setIsSubmit(false);
    setFormValue(AcademicYearValue);
  };

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["FullName"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setAcademicYearList(srchResult);
      } else {
        setAcademicYearList({});
      }
    } else {
      setAcademicYearList(originalList);
    }
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
        <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">Academic Year</div>
            <div className="page-date">
              <div className="sec-content">
                <AcademicHeader
                  todayDate={todayDate}
                  AcademicYear={AcademicYear}
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <>
          <div className="sec-dataTable content-section main-content">
            {/* <div className="upper-dataTbl">
              <div className="btn-addlnote">
                <button
                  type="button"
                  class="btn btn-sm"
                  style={{
                    background: "var(--MainColor)",
                    color: "white",
                  }}
                  onClick={addnew}
                >
                  Add
                </button>
              </div>
            </div> */}
            <hr className="new-hr-new" />
            {loading ? (
              <Spinner />
            ) : (
              <DataTable
                columns={columns}
                data={AcademicYearList}
                customStyles={customStylesForImage}
                pagination
                fixedHeader
                fixedHeaderScrollHeight="280px"
                highlightOnHover
                pointerOnHover
                responsive
                dense
                striped
                // subHeader
                // subHeaderComponent={
                //   <>
                //     <div
                //       className="col-lg-2 offset-lg-10 offset-md-10 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
                //       style={{ paddingRight: "1px", marginBottom: "10px" }}
                //     >
                //       <div className="d-flex">
                //         <input
                //           placeholder="Search"
                //           ref={searchInput}
                //           type="text"
                //           className="form-control form-control-sm searchField"
                //           onChange={searchHandler}
                //         />
                //       </div>
                //     </div>
                //   </>
                // }
              />
            )}
          </div>
        </>
      </div>

      {popup && (
        <div className="modals">
          <div className="overlay"></div>

          <div className="notification-model-content">
            <div className="popUpHeader ">
              <div className="popUpHeaderText ">Academic Year</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closePopup}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="inside-modal">
              <AcademicYearForm closePopup={closePopup} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
