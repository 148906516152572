import React, { useContext, useEffect, useState } from "react";
import "../../css/Dashboard.css";
import SidebarContext from "../sidebarcontext/SidebarContext";
import { Fetchdata } from "../hooks/getData";
import { Link } from "react-router-dom";
import EventContext from "../contextfolder/EventContext/EventContext";
import { showImgPreview } from "../hooks/ImagePreview";
import { GetNepaliDate } from "../hooks/dateConvertor";
import NepaliDate from "nepali-date-converter";
import StudentContext from "../contextfolder/StudentContext/StudentContext";
import AcademicHeader from "../AcademicHeader";

const Dashboard = () => {
  const { todayDate, appURL, remainingDays, formattedRemainingDate } =
    useContext(SidebarContext);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const rolePermission = JSON.parse(localStorage.getItem("rolePermission"));
  // const SchPhoto = userinfo.SchPhoto;
  const UserImage = userinfo.UserImage;
  const UserName = userinfo.UserName;
  const FullName = userinfo.FullName;
  const TempAddress = userinfo.TempAddress;
  const Phone = userinfo.Phone;
  const AcademicYear = userinfo.AcademicYear;

  const [loading, setLoading] = useState(true);

  const [dashboardList, setDashboardList] = useState([]);

  const [imgPrv, setImgPrv] = useState(false);
  const [imagePre, setImagePre] = useState("");

  //API to hit Dashboard Admin list

  useEffect(() => {
    getDashboardList();
  }, []);

  const getDashboardList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      DFlag: "DA",
      Flag: "S",
      Type: "POST",
      AcademicYear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst[0] ? result.referencelst[0] : "";

        setLoading(false);
        setDashboardList(postResult);
      } else {
        setLoading(false);
        setDashboardList([]);
      }
    });
  };

  //API to hit Dashboard Teacher list

  const [dashboardTeacherList, setDashboardTeacherList] = useState([]);

  useEffect(() => {
    getDashboardTeacherList();
  }, []);

  const getDashboardTeacherList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      DFlag: "DT",
      Flag: "S",
      Type: "POST",
      AcademicYear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst[0] ? result.referencelst[0] : "";

        setLoading(false);
        setDashboardTeacherList(postResult);
      } else {
        setLoading(false);
        setDashboardTeacherList([]);
      }
    });
  };

  // API for personal notification list
  const [notificationList, setNotificationList] = useState([]);

  useEffect(() => {
    getPersonalNotificationList();
  }, []);

  const getPersonalNotificationList = () => {
    const params = {
      SchID: userinfo.SchID,
      Flag: "SP",
      ClassID: userinfo.ClassID.toString(),
      SectionID: userinfo.SectionID.toString(),
      MemID: userinfo.UserID.toString(),
      UserType: userinfo.UserType.toString(),
      Type: "POST",
      AcademicYear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/notification`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.NotificationLst ? result.NotificationLst : "";
        setLoading(false);
        setNotificationList(postResult);
      } else {
        setLoading(false);
        setNotificationList([]);
      }
    });
  };

  // API for genral notification list
  const [generalList, setGeneralList] = useState([]);

  useEffect(() => {
    getGeneralNotificationList();
  }, []);

  const getGeneralNotificationList = () => {
    const params = {
      SchID: userinfo.SchID,
      Flag: "SG",
      Type: "POST",
      AcademicYear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/notification`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.NotificationLst ? result.NotificationLst : "";
        setLoading(false);
        setGeneralList(postResult);
      } else {
        setLoading(false);
        setGeneralList([]);
      }
    });
  };

  // API for attendance list
  const [attenList, setAttenList] = useState([]);

  useEffect(() => {
    getAttenList();
  }, []);

  const getAttenList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      Flag: "CLASS",
      DFlag: "ATTENDANCE",
      Values: "01",
      Type: "POST",
      AcademicYear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/summary`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setLoading(false);
        setAttenList(postResult);
      } else {
        setLoading(false);
        setAttenList([]);
      }
    });
  };

  // API for upcoming event list
  const [eventList, setEventList] = useState([]);

  useEffect(() => {
    getEventList();
  }, []);

  const getEventList = () => {
    const params = {
      SchID: userinfo.SchID,
      Count: "-1",

      Type: "POST",
      AcademicYear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/upcoming-event`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.CalendarEventLst
          ? result.CalendarEventLst
          : "";
        setLoading(false);
        setEventList(postResult);
      } else {
        setLoading(false);
        setEventList([]);
      }
    });
  };

  // For Today Report at admin

  // Api for today date value
  const [todayList, setTodayList] = useState([]);

  useEffect(() => {
    getTodayList();
  }, [todayDate]);

  const getTodayList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      Flag: "RD",
      FromDate: todayDate,
      Type: "POST",
      AcademicYear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/account`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.AccLst ? result.AccLst : "";
        setLoading(false);
        setTodayList(postResult);
      } else {
        setTodayList([]);
      }
    });
  };

  let credit = todayList.reduce(function (prev, current) {
    return prev + parseFloat(current.Credit);
  }, 0);

  const [visual, setisVisual] = useState();
  // useEffect(() => {
  //   if (userinfo.UserType === "A") {

  //     setisVisual(rolePermission.some(
  //       (role) =>
  //         role.UserID === userinfo.UserID &&
  //         role.Permission === "ONLINEPAYMENTREPORT"
  //     ))

  //   }
  // }, [])

  // API for personal notification list
  const [notificationListstd, setNotificationListstd] = useState([]);
  useEffect(() => {
    getPersonalNotificationListstd();
  }, []);

  const getPersonalNotificationListstd = () => {
    const params = {
      SchID: userinfo.SchID,
      Flag: "SP",
      ClassID: userinfo.ClassID.toString(),
      SectionID: userinfo.SectionID.toString(),
      MemID: userinfo.UserID.toString(),
      UserType: userinfo.UserType.toString(),
      Type: "POST",
      AcademicYear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/notification`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.NotificationLst ? result.NotificationLst : "";
        setLoading(false);
        setNotificationListstd(postResult);
      } else {
        setLoading(false);
        setNotificationListstd([]);
      }
    });
  };

  return (
    <>
      <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
        <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">Dashboard</div>
            <div className="page-date">
              <div className="sec-content">
                <AcademicHeader
                  todayDate={todayDate}
                  AcademicYear={AcademicYear}
                  remainingDays={remainingDays}
                />{" "}
                | Remaining Days: {remainingDays}
              </div>
            </div>
          </div>
        </div>
        <hr />
        {/* <section className="content-section main-content"> */}
        <section style={{ paddingLeft: "10px", paddingRight: "10px" }}>
          <div className="dashboard--container" style={{ borderRadius: "5px" }}>
            <div className="row">
              <div className="col-sm-4 dashboard--about">
                <div className="middle--heading">
                  <h6 className="h6">
                    {userinfo.UserType === "A"
                      ? "About Admin"
                      : "About Teacher"}
                  </h6>
                  <div className="middle--about">
                    <div>
                      <div>
                        <img
                          className="dashboard--logo"
                          src={UserImage}
                          alt=""
                        />{" "}
                      </div>
                    </div>
                    <div className="dashboard--info">
                      <p>{FullName}</p>{" "}
                      <p className="dashboard--address">
                        {" "}
                        Username: {UserName}
                        <br />
                        {TempAddress}
                        <br />
                        {Phone}
                      </p>
                    </div>
                  </div>
                </div>
                {/* {remainingDays <= 7 && (
                  <div className="expiry-card">
                    <div className="expiry-remaining-days">
                      <p>
                        <b>{remainingDays}</b> day(s) <span>Remaining</span>
                      </p>
                    </div>

                    <div className="expiry-content">
                      <h2>Expiry Soon</h2>
                      <p>
                        Your Application Service will Expire on
                        <span>{formattedRemainingDate}</span>
                      </p>
                    </div>
                  </div>
                )} */}
              </div>

              {/* <div className="col-sm-4 dashboard--about">
                <div className="middle--heading">
                  <h6 className="h6">No. of Customer </h6>
                  <div className="middle--first">
                    <div>
                      <p>{dashboardList.numOfCustomer === null ? "0" : dashboardList.numOfCustomer}</p>
                      {/* <p>{(dashboardList.numOfCustomer).split('"')[1]}</p> 
                    </div>
                    <div><i className="fa fa-users dash--icon" aria-hidden="true"></i></div>
                  </div>
                </div>

                <div className="middle--heading">
                  <h6 className="h6">No. of Transaction</h6>
                  <div className="middle--first">
                    <div>
                      <p>{dashboardList.numOfTxn === null ? "0" : dashboardList.numOfTxn}</p>
                    </div>
                    <div><i className="fa fa-money dash--icon" aria-hidden="true"></i></div>
                  </div>
                </div>
              </div> */}

              <div className="col-sm-4 dashboard--about">
                <div className="middle--heading">
                  <h6 className="h6">
                    {userinfo.UserType === "T" ? "Teaching Subject" : "Student"}
                  </h6>
                  <div className="middle--first">
                    <div>
                      <p>
                        {userinfo.UserType === "T"
                          ? dashboardTeacherList.TeachingSubjectCt === null
                            ? "0"
                            : dashboardTeacherList.TeachingSubjectCt
                          : dashboardList.StudentCt === null
                          ? "0"
                          : dashboardList.StudentCt}
                      </p>
                    </div>
                    <div>
                      {userinfo.UserType === "A" ? (
                        <Link to="/student-list">
                          {" "}
                          <i
                            className="fa fa-users dash--icon"
                            aria-hidden="true"
                          ></i>{" "}
                        </Link>
                      ) : (
                        <Link to="/subject">
                          {" "}
                          <i
                            className="bi bi-book-half dash--icon"
                            aria-hidden="true"
                          ></i>{" "}
                        </Link>
                      )}
                    </div>
                  </div>
                </div>

                <div className="middle--heading">
                  <h6 className="h6">
                    {userinfo.UserType === "T" ? "Leave Note" : "Teacher"}
                  </h6>
                  <div className="middle--first">
                    <div>
                      <p>
                        {userinfo.UserType === "T"
                          ? dashboardTeacherList.LeaveCt === null
                            ? "0"
                            : dashboardTeacherList.LeaveCt
                          : dashboardList.TeacherCt === null
                          ? "0"
                          : dashboardList.TeacherCt}
                      </p>
                    </div>
                    {/* <div><i className={userinfo.UserType === "T" ? "fa fa-sticky-note dash--icon" : "fa fa-users dash--icon"} aria-hidden="true"></i></div> */}
                    <div>
                      {userinfo.UserType === "A" ? (
                        <Link to="/teacher-list">
                          {" "}
                          <i
                            className="fa fa-users dash--icon"
                            aria-hidden="true"
                          ></i>{" "}
                        </Link>
                      ) : (
                        <Link to="/leave-application">
                          {" "}
                          <i
                            className="fa fa-sticky-note dash--icon"
                            aria-hidden="true"
                          ></i>{" "}
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-4 dashboard--about">
                <div className="middle--heading">
                  <h6 className="h6">
                    {userinfo.UserType === "T" ? "Assignment" : "Admin"}
                  </h6>
                  <div className="middle--first">
                    <div>
                      <p>
                        {userinfo.UserType === "T"
                          ? dashboardTeacherList.AssignmentCt === null
                            ? "0"
                            : dashboardTeacherList.AssignmentCt
                          : dashboardList.AdminCt === null
                          ? "0"
                          : dashboardList.AdminCt}
                      </p>
                    </div>
                    {/* <div>
                      <i className={userinfo.UserType === "T" ? "bi bi-journals dash--icon" : "fa fa-users dash--icon"} aria-hidden="true"></i>
                    </div> */}

                    <div>
                      {userinfo.UserType === "A" ? (
                        <Link to="/admin-list">
                          {" "}
                          <i
                            className="fa fa-users dash--icon"
                            aria-hidden="true"
                          ></i>{" "}
                        </Link>
                      ) : (
                        <Link to="/assignment">
                          {" "}
                          <i
                            className="bi bi-journals dash--icon"
                            aria-hidden="true"
                          ></i>{" "}
                        </Link>
                      )}
                    </div>
                  </div>
                </div>

                <div className="middle--heading">
                  <h6 className="h6">Notification</h6>
                  <div className="middle--first">
                    <div>
                      <p>
                        {userinfo.UserType === "T"
                          ? dashboardTeacherList.NotificationCt === null
                            ? "0"
                            : dashboardTeacherList.NotificationCt
                          : dashboardList.NotificationCt === null
                          ? "0"
                          : dashboardList.NotificationCt}
                      </p>
                    </div>
                    <div>
                      <Link
                        to={
                          userinfo.UserType === "S"
                            ? "/student-notification"
                            : "/notification"
                        }
                      >
                        <i className="fa fa-bell dash--icon" aria-hidden="true">
                          {" "}
                        </i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {userinfo.UserType === "T" ? (
              <div className="row">
                <div className="col-sm-4 dashboard--about">
                  <div className="personal-notice-detail">
                    <h6 className="h6">Personal Notification</h6>

                    <div className="personal-notice-about">
                      {notificationList.length > 0 ? (
                        notificationList.map((item, i) => (
                          <>
                            <div
                              className="personal-notice-about-inner"
                              key={i}
                            >
                              {item.Attachment === null ||
                              item.Attachment === "" ? (
                                <></>
                              ) : (
                                <div>
                                  <div>
                                    <img
                                      className="personal-notice-icon"
                                      src={item.Attachment}
                                      alt=""
                                      onClick={() => {
                                        setImagePre(item.Attachment);
                                        setImgPrv(true);
                                      }}
                                    />{" "}
                                  </div>
                                </div>
                              )}
                              <div className="personal-notice-info">
                                <p>{item.Title}</p>{" "}
                                <p className="dashboard--address">
                                  {GetNepaliDate(
                                    item.NotificationDate.split(" ")[0]
                                  )}{" "}
                                  <br /> {item.Description} <br />
                                </p>
                              </div>
                            </div>
                            <hr className="personal-notice-hr" />
                          </>
                        ))
                      ) : (
                        <div className="text-center mt-4">
                          <p>There are no records to display.</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-sm-4 dashboard--about">
                  <div className="personal-notice-detail">
                    <h6 className="h6">General Notification</h6>

                    <div className="personal-notice-about">
                      {generalList.length > 0 ? (
                        generalList.map((item, i) => (
                          <>
                            <div
                              className="personal-notice-about-inner"
                              key={i}
                            >
                              {item.Attachment === null ||
                              item.Attachment === "" ? (
                                <></>
                              ) : (
                                <div>
                                  <div>
                                    <img
                                      className="personal-notice-icon"
                                      src={item.Attachment}
                                      alt=""
                                      onClick={() => {
                                        setImagePre(item.Attachment);
                                        setImgPrv(true);
                                      }}
                                    />{" "}
                                  </div>
                                </div>
                              )}

                              <div className="personal-notice-info">
                                <p>{item.Title}</p>{" "}
                                <p className="dashboard--address">
                                  {GetNepaliDate(
                                    item.NotificationDate.split(" ")[0]
                                  )}
                                  <br /> {item.Description} <br />
                                </p>
                              </div>
                            </div>
                            <hr
                              className="personal-notice-hr"
                              style={{ borderTop: "1px solid #cdafaf" }}
                            />
                          </>
                        ))
                      ) : (
                        <div className="text-center mt-4">
                          <p>There are no records to display.</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-sm-4 dashboard--about">
                  <div className="personal-notice-detail">
                    <h6 className="h6">Upcoming Event</h6>

                    <div className="personal-notice-about">
                      {eventList.length > 0 ? (
                        eventList.map((item, i) => (
                          <>
                            <div
                              className="personal-notice-about-inner"
                              key={i}
                            >
                              {item.Banner === null || item.Banner === "" ? (
                                <></>
                              ) : (
                                <div>
                                  <div>
                                    <img
                                      className="personal-notice-icon"
                                      src={item.Banner}
                                      alt=""
                                      onClick={() => {
                                        setImagePre(item.Banner);
                                        setImgPrv(true);
                                      }}
                                    />{" "}
                                  </div>
                                </div>
                              )}
                              <div
                                className={
                                  item.IsHoliday === "Y"
                                    ? "personal-notice-info-holiday"
                                    : "personal-notice-info"
                                }
                              >
                                <p>{item.Title}</p>{" "}
                                <p
                                  className={
                                    item.IsHoliday === "Y"
                                      ? "dashboard--address--holiday"
                                      : "dashboard--address"
                                  }
                                >
                                  {item.NepaliDate}
                                  <br /> {item.Venue} <br />
                                </p>
                              </div>
                            </div>
                            <hr className="personal-notice-hr" />
                          </>
                        ))
                      ) : (
                        <div className="text-center mt-4">
                          <p>There are no records to display.</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              // For admin dashboard

              <div className="row">
                {visual ? (
                  <div className="col-sm-5 dashboard--about">
                    <div className="personal-notice-detail">
                      <h6 className="h6">Personal Notification</h6>

                      <div className="personal-notice-about">
                        {notificationListstd.length > 0 ? (
                          notificationListstd.map((item, i) => (
                            <>
                              <div
                                className="personal-notice-about-inner"
                                key={i}
                              >
                                {item.Attachment === null ||
                                item.Attachment === "" ? (
                                  <></>
                                ) : (
                                  <div>
                                    <div>
                                      <img
                                        className="personal-notice-icon"
                                        src={item.Attachment}
                                        alt=""
                                        onClick={() => {
                                          setImagePre(item.Attachment);
                                          setImgPrv(true);
                                        }}
                                      />{" "}
                                    </div>
                                  </div>
                                )}
                                <div className="personal-notice-info">
                                  <p>{item.Title}</p>{" "}
                                  <p className="dashboard--address">
                                    {GetNepaliDate(
                                      item.NotificationDate.split(" ")[0]
                                    )}{" "}
                                    <br /> {item.Description} <br />
                                  </p>
                                </div>
                              </div>
                              <hr className="personal-notice-hr" />
                            </>
                          ))
                        ) : (
                          <div className="text-center mt-4">
                            <p>There are no records to display.</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="col-sm-5 dashboard--about">
                    <div className="personal-notice-detail">
                      <h6 className="h6">Today Fee Report</h6>

                      <div className="attendance-notice-about">
                        {/* {attenList.length > 0 ?
                      <div className="table-responsive br-3" style={{ height: "237px" }}>

                        <table className="table table-striped table-bordereds text-nowrap mb-0" >
                          <thead>
                            <tr className="tableHead">
                              <td className="tl">Class</td>
                              <td className="tr">Present</td>
                              <td className="tr">Absent</td>
                              <td className="tr">Total</td>
                            </tr>
                          </thead>
                          <tbody>
                            {attenList.map((item, i) => (
                              <tr key={i}>
                                <td className="tl">{item.Class}</td>
                                <td className="tr">{item.CPresentCt}</td>
                                <td className="tr">{item.CAbsentCt}</td>
                                <td className="tr">{parseInt(item.CPresentCt) + parseInt(item.CAbsentCt)}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>

                      </div> : <div className="text-center mt-4">
                        <p>There are no records to display.</p>
                      </div>
                    } */}

                        {todayList.length > 0 ? (
                          <div
                            className="table-responsive br-3"
                            style={{
                              height: "245px",
                              overflowX: "auto",
                              overflowY: "auto",
                            }}
                          >
                            <table className="table table-striped table-bordereds text-nowrap mb-0">
                              <thead>
                                <tr className="tableHead">
                                  <td className="tc" style={{ width: "70px" }}>
                                    S.N.
                                  </td>
                                  <td className="tl" style={{ width: "145px" }}>
                                    Name
                                  </td>
                                  <td className="tl">Particulars</td>
                                  <td className="tr" style={{ width: "150px" }}>
                                    Credit
                                  </td>
                                </tr>
                              </thead>
                              <tbody>
                                {todayList.map((item, i) => (
                                  <tr key={i}>
                                    <td
                                      className="tc"
                                      style={{ width: "70px" }}
                                    >
                                      {i + 1}
                                    </td>
                                    <td className="tl">{item.FullName}</td>
                                    <td className="tl">{item.Particulars}</td>
                                    <td className="tr">
                                      {parseFloat(item.Credit).toFixed(2)}
                                    </td>
                                  </tr>
                                ))}
                                <tr className="lastt">
                                  <td colspan="3">
                                    <b style={{ paddingLeft: "15px" }}>Total</b>
                                  </td>
                                  <td className="tr ">
                                    <b>{credit.toFixed(2)}</b>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        ) : (
                          <div
                            className="table-responsive br-3"
                            style={{
                              height: "245px",
                              overflowX: "auto",
                              overflowY: "auto",
                            }}
                          >
                            <div className="text-center mt-4">
                              <p>There are no records to display.</p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                <div className="col-sm-4 dashboard--about">
                  <div className="personal-notice-detail">
                    <h6 className="h6">General Notification</h6>

                    <div className="personal-notice-about">
                      {generalList.length > 0 ? (
                        generalList.map((item, i) => (
                          <>
                            <div
                              className="personal-notice-about-inner"
                              key={i}
                            >
                              {item.Attachment === null ||
                              item.Attachment === "" ? (
                                <></>
                              ) : (
                                <div>
                                  <div>
                                    <img
                                      className="personal-notice-icon"
                                      src={item.Attachment}
                                      alt=""
                                      onClick={() => {
                                        setImagePre(item.Attachment);
                                        setImgPrv(true);
                                      }}
                                    />{" "}
                                  </div>
                                </div>
                              )}
                              <div className="personal-notice-info">
                                <p>{item.Title}</p>{" "}
                                <p className="dashboard--address">
                                  {GetNepaliDate(
                                    item.NotificationDate.split(" ")[0]
                                  )}
                                  <br /> {item.Description} <br />
                                </p>
                              </div>
                            </div>
                            <hr
                              className="personal-notice-hr"
                              style={{ borderTop: "1px solid #cdafaf" }}
                            />
                          </>
                        ))
                      ) : (
                        <div className="text-center mt-4">
                          <p>There are no records to display.</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-sm-3 dashboard--about">
                  <div className="personal-notice-detail">
                    <h6 className="h6">Upcoming Event</h6>

                    <div className="personal-notice-about">
                      {eventList.length > 0 ? (
                        eventList.map((item, i) => (
                          <>
                            <div
                              className="personal-notice-about-inner"
                              key={i}
                            >
                              {item.Banner === null || item.Banner === "" ? (
                                <></>
                              ) : (
                                <div>
                                  <div>
                                    <img
                                      className="personal-notice-icon"
                                      src={item.Banner}
                                      alt=""
                                      onClick={() => {
                                        setImagePre(item.Banner);
                                        setImgPrv(true);
                                      }}
                                    />{" "}
                                  </div>
                                </div>
                              )}

                              <div
                                className={
                                  item.IsHoliday === "Y"
                                    ? "personal-notice-info-holiday"
                                    : "personal-notice-info"
                                }
                              >
                                <p>{item.Title}</p>{" "}
                                <p
                                  className={
                                    item.IsHoliday === "Y"
                                      ? "dashboard--address--holiday"
                                      : "dashboard--address"
                                  }
                                >
                                  {item.NepaliDate} <br /> {item.Venue} <br />
                                </p>
                              </div>
                            </div>
                            <hr className="personal-notice-hr" />
                          </>
                        ))
                      ) : (
                        <div className="text-center mt-4">
                          <p>There are no records to display.</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
      </div>
      {imgPrv &&
        showImgPreview({
          img: imagePre,
          setTrigger: setImgPrv,
        })}
    </>
  );
};

export default Dashboard;
