import React, { useContext, useState, useEffect } from "react";
import { useRef } from "react";
import DataTable from "react-data-table-component";
import SubjectContext from "../contextfolder/SubjectContext/SubjectContext";
import TeacherContext from "../contextfolder/TeacherContext/TeacherContext";
import { Fetchdata } from "../hooks/getData";
import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";
import Select from "react-select";

export default function SubjectForm() {
  const context = useContext(SubjectContext);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const { appURL } = useContext(SidebarContext);

  const {
    togglePopup,
    subjectFormError,
    setSubjectFormError,
    isSubmit,
    setIsSubmit,
    subjectValue,
    subjectFormValue,
    setSubjectFormValue,
    inputFields,
    setInputFields,
    fetchdata,
    closeChecked,
    setCloseChecked,
  } = context;

  const { customStyles } = useContext(SidebarContext);
  const { teacherList } = useContext(TeacherContext);

  const [originalList, setOriginalList] = useState("");

  const handleOnChange = () => {
    setCloseChecked(!closeChecked);
  };

  const dropDownValue = teacherList.map((item) => ({
    value: item.TeacherID,
    label: item.TeacherName,
  }));

  const handleSelectTeacher = (e) => {
    let name = "teacher";

    setSubjectFormValue({
      ...subjectFormValue,
      [name]: e.value,
    });
  };

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setSubjectFormValue({ ...subjectFormValue, [name]: value });
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setSubjectFormError(validate(subjectFormValue));
    setIsSubmit(true);
  };
  useEffect(() => {
    if (Object.keys(subjectFormError).length === 0 && isSubmit) {
      fetchdata(subjectFormValue);
      if (closeChecked === true) {
        togglePopup();
      }
      setSubjectFormValue(subjectValue);
      setInputFields([]);
      setIsSubmit(false);
    }
  }, [subjectFormError]);

  const closePopup = (e) => {
    e.preventDefault();
    togglePopup();
    setSubjectFormValue(subjectValue);
    setSubjectFormError({});
    setIsSubmit(false);
    setCloseChecked(false);
  };

  const validate = (values) => {
    const errors = {};
    if (!values.class) {
      errors.class = "Required";
    }
    if (!values.section) {
      errors.section = "Required";
    }
    if (userinfo.UserType !== "T") {
      if (!values.teacher) {
        errors.teacher = "Required";
      }
    }

    return errors;
  };

  // const handleOnChange = (e) => {
  //     setNotify(!notify)
  // };

  const [classList, setClassList] = useState([]);

  //API to hit class list
  useEffect(() => {
    getClassList();
  }, []);

  const getClassList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      DFlag: "C",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setClassList(postResult);
      } else {
        setClassList([]);
      }
    });
  };

  //API to hit section list
  const [sectionList, setSectionList] = useState([]);

  useEffect(() => {
    getSectionList();
  }, [subjectFormValue.class]);

  const getSectionList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      ClassID: subjectFormValue.class,
      DFlag: "S",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";

        setSectionList(postResult);
      } else {
        setSectionList([]);
      }
    });
  };

  //APi to hit subject List
  const [subjectList, setSubjectList] = useState([]);

  useEffect(() => {
    getSubjectList();
  }, [subjectFormValue.class]);

  const getSubjectList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      ClassID: subjectFormValue.class,
      DFlag: "SB",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";

        setSubjectList(postResult);
        setOriginalList(postResult);
      } else {
        setSubjectList([]);
        setOriginalList([]);
      }
    });
  };

  const columns = [
    {
      name: "S.N.",
      grow: 0,
      center: true,
      cell: (row, index) => index + 1,
    },

    {
      name: "Subject",
      center: true,
      // grow: 0,
      selector: (row) => row.SubjectName,
    },
    {
      name: "Action",
      center: true,
      selector: (row) => {
        return (
          <>
            <input
              type="checkbox"
              onChange={(e) => {
                if (e.target.checked) {
                  setInputFields([
                    ...inputFields,
                    {
                      SubjectID: row.SubjectID,
                    },
                  ]);
                } else {
                  setInputFields(
                    inputFields.filter(
                      (item) => item.SubjectID !== row.SubjectID
                    )
                  );
                }
              }}
              value={inputFields}
            />
          </>
        );
      },
    },
  ];

  const searchInput = useRef("");

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["SubjectName"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setSubjectList(srchResult);
      } else {
        setSubjectList({});
      }
    } else {
      setSubjectList(originalList);
    }
  };

  return (
    <>
      <div className="container-fluid basicform">
        <form>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label className="form-label" htmlFor="class">
                Class <sup style={{ color: "red" }}>*</sup>
              </label>
              <select
                id="class"
                name="class"
                onChange={handleChange}
                value={subjectFormValue.class}
                className="form-select form-select-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="" selected disabled>
                  Select Option
                </option>
                {classList.map((item, i) => (
                  <option key={i} value={item.ClassID}>
                    {item.ClassName}
                  </option>
                ))}
              </select>
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {subjectFormError.class}
              </p>
            </div>

            <div className="form-group col-md-6">
              <label className="form-label" htmlFor="section">
                Section <sup style={{ color: "red" }}>*</sup>
              </label>
              <select
                id="section"
                name="section"
                onChange={handleChange}
                value={subjectFormValue.section}
                className="form-select form-select-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="" selected disabled>
                  Select Option
                </option>
                {sectionList.map((item, i) => (
                  <option key={i} value={item.SectionID}>
                    {item.SectionName}
                  </option>
                ))}
              </select>
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {subjectFormError.section}
              </p>
            </div>
          </div>
          {userinfo.UserType !== "T" ? (
            <div className="form-group">
              <label className="form-label" htmlFor="teachera">
                Teacher/Admin <sup style={{ color: "red" }}>*</sup>
              </label>
              {/* <select
                id="teachera"
                name="teacher"
                onChange={handleChange}
                value={subjectFormValue.teacher}
                className="form-select form-select-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="" selected disabled>
                  Select Option
                </option>
                {teacherList.map((item, i) => (
                  <option key={i} value={item.TeacherID}>
                    {item.TeacherName}
                  </option>
                ))}
              </select> */}
              <Select
                className="select"
                style={{ fontSize: "11px", marginBottom: "3px" }}
                options={dropDownValue}
                onChange={(item) => handleSelectTeacher(item)}
                isSearchable={true}
              />
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {subjectFormError.teacher}
              </p>
            </div>
          ) : (
            <></>
          )}
        </form>

        <div className="sec-dataTable">
          {/* {loading ? (
                            <Spinner />
                        ) : ( */}
          <DataTable
            columns={columns}
            data={subjectList}
            customStyles={customStyles}
            pagination
            fixedHeader
            fixedHeaderScrollHeight="280px"
            highlightOnHover
            pointerOnHover
            responsive
            dense
            striped
            subHeader
            subHeaderComponent={
              <>
                <div className="upper-dataTbl">
                  <div className="d-flex">
                    <input
                      placeholder="Search"
                      ref={searchInput}
                      type="text"
                      className="form-control form-control-sm searchField"
                      onChange={searchHandler}
                    />
                  </div>
                </div>
              </>
            }
          />
          {/* )} */}
        </div>
      </div>
      <div className="FormFooter">
        <div className="checkedClose">
          <input
            type="checkbox"
            className="form-check-input"
            id="exampleCheck2"
            name="closeChecked"
            onChange={handleOnChange}
            checked={closeChecked}
          />
          <label class="form-check-label" for="exampleCheck2">
            Exit after submit
          </label>
        </div>
        <div>
          <button
            className="btn btn-sm btn-cmpy ml-2"
            style={{ background: "green" }}
            onClick={formSubmit}
          >
            Submit
          </button>
          <button className="btn btn-sm btn-cmpy ml-2" onClick={closePopup}>
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}
