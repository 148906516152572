import React, { useContext, useEffect, useState } from 'react'
import Spinner from '../loading/spinner';
import OrderContext from '../contextfolder/OrderContext/OrderContext';



export default function CreateOrder({ closePopup }) {

    const { menuListForOrder, activeTab, setActiveTab, catLoad, onAdd, cartItems, onRemove, ordCloseChecked, setOrdCloseChecked, toggleModal, setOrdIsSubmit, ordIsSubmit, categoryData, setCartItems, categoryList, orderStatus, setOrderStaus, isPaid, setIsPaid, setOrderFormValue, orderFormValue, orderFormError, setOrderFormError, orderValue } = useContext(OrderContext)

    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;
        setOrderFormValue({ ...orderFormValue, [name]: value });
    };


    const handleOnChange = () => {
        setOrdCloseChecked(!ordCloseChecked);
    };

    const handleOrderStatus = () => {
        setOrderStaus(!orderStatus);
    };
    const handlePaid = () => {
        setIsPaid(!isPaid);
    };


    const validate = (values) => {

        const errors = {};
        if (isPaid === true) {
            if (!values.payAmount) {
                errors.payAmount = "Required";
            }
        }

        if (!values.userName) {
            errors.userName = "Required";
        }


        return errors;
    };

    const handleTabClick = (categoryId) => {
        setActiveTab(categoryId);
    };


    const filteredValues = menuListForOrder.filter((value) => value.CategoryID === activeTab);

    const itemsPrice = cartItems.reduce((a, c) => a + c.Price * c.Qty, 0);

    const remainingAmount = orderFormValue.payAmount - itemsPrice;



    const formSubmit = (e) => {
        e.preventDefault();
        setOrderFormError(validate(orderFormValue))
        setOrdIsSubmit(true);

    }

    useEffect(() => {
        if (Object.keys(orderFormError).length === 0 && ordIsSubmit) {
            categoryData()
            if (ordCloseChecked === true) {
                toggleModal();
            }
            setOrdIsSubmit(false);
            setCartItems([])
            setOrdCloseChecked(false);
            setOrderStaus(false);
            setIsPaid(false);
            setOrderFormValue(orderValue)
        }

    }, [orderFormError]);

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 col-sm-6 col-lg-6 padding-order">
                        <div className="tabview ">
                            <div className="order--div">
                                <ul>
                                    {categoryList.map((value) => (
                                        <li
                                            key={value.CategoryID}
                                            className={`tab ${value.CategoryID === activeTab ? "active" : ""}`}
                                            onClick={() => handleTabClick(value.CategoryID)}
                                        >
                                            {value.Category}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="order--main">
                            <div className="order-content">
                                {catLoad ? <Spinner /> : (
                                    filteredValues.length > 0 ? (filteredValues.map((value, i) => (
                                        <>
                                            <div className="order-content-item" key={i} >
                                                <div className="orderImage">
                                                    <div className="OrderImageMenu">
                                                        <img
                                                            src={value.Image}
                                                            alt="dp"
                                                            style={{ cursor: "pointer" }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="order-name">{value.Name}</div>
                                                <div className="order-price-add">
                                                    <div className="order-price">{value.Currency}.{value.Price}</div>
                                                    <button className='add--order' onClick={() => onAdd(value)}><i className="bi bi-plus"></i></button>
                                                </div>
                                            </div>
                                        </>
                                    ))) : <div style={{ display: "flex", justifyContent: "center" }}>No data found</div>
                                )}

                            </div>
                        </div>

                    </div>

                    <div className="col-md-6 col-sm-6 col-lg-6 padding-order">
                        <div className="tab--details--order">
                            <div className="order--details">
                                <p>Order Details</p>
                                {/* <div> {cartItems.length === 0 && <div>Cart Is Empty</div>} </div>
                        {cartItems.map((item) => (
                            <div key={item.MenuID} className="addtoCart">
                                <div>{item.Name}</div>
                                <div>
                                    <button onClick={() => onAdd(item)}> + </button>
                                    <button onClick={() => onRemove(item)}> - </button>
                                </div>
                                <div>{item.qty} * {item.Price}</div>
                            </div>))} */}
                            </div>
                        </div>

                        {cartItems.length > 0 ? (
                            <div className="table-responsive br-3" style={{ height: "270px" }}>

                                <table className="table table-striped table-bordereds text-nowrap mb-0">
                                    <thead>
                                        <tr className="tableHead">
                                            <td className="tc" style={{ width: "60px" }}>S.N.</td>
                                            <td className="tl">Name</td>
                                            <td className="tl">Qty</td>
                                            <td className="tr">Price</td>
                                            <td className="tr">Total</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {cartItems.map((item, i) => (
                                            <tr key={i}>
                                                <td className="tc" style={{ width: "50px" }}>{i + 1}</td>
                                                <td className="tl">{item.Name}</td>
                                                <td className="tl">
                                                    <button className='badge addItemsOrder' onClick={() => onAdd(item)}> + </button> <span className="badge quantityUpdate"> {item.Qty} </span>
                                                    <button className='badge removeItemsOrder' onClick={() => onRemove(item)}> - </button></td>
                                                <td className="tr">{parseFloat(item.Price).toFixed(2)}</td>
                                                <td className="tr">{parseFloat(item.Qty * item.Price).toFixed(2)}</td>
                                            </tr>
                                        ))}
                                        <tr className="lastt">
                                            <td colspan="4" ><b style={{ paddingLeft: "15px" }}>Total</b></td>
                                            <td className="tr"><b>Rs.&nbsp;{itemsPrice.toFixed(2)}</b></td>

                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        ) : (
                            <div className="table-responsive br-3" style={{ height: "270px" }}>

                                <div className="text-center mt-4">
                                    <p>There are no orders to display.</p>
                                </div>
                            </div>
                        )}
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label className="form-label" htmlFor="userName" style={{ fontSize: "12px" }}>
                                    UserName<sup style={{ color: "red" }}>*</sup>
                                </label>
                                <input
                                    id="userName"
                                    style={{ fontSize: "13px" }}
                                    type="text"
                                    className="form-control form-control-sm "
                                    name="userName"
                                    value={orderFormValue.userName}
                                    onChange={handleChange}
                                />
                                <p className="errormsg " style={{ marginBottom: "0" }}>{orderFormError.userName}</p>
                            </div>

                            <div className="form-group col-md-6" >
                                <label className="form-label display--center" style={{ fontSize: "12px" }}>
                                    Action
                                </label>
                                <div className="display--center">
                                    <div style={{ marginTop: "5px" }}>
                                        <input
                                            type="checkbox"
                                            // className="form-check-input"
                                            id="exampleCheck3"
                                            name="notWorking"
                                            onChange={handleOrderStatus}
                                            checked={orderStatus}
                                        />
                                        <label class="form-check-label" for="exampleCheck3" style={{ fontSize: "12px", paddingLeft: "3px" }}>
                                            Delivered
                                        </label>
                                    </div>

                                    <div style={{ marginTop: "5px", marginLeft: "10px" }}>
                                        <input
                                            type="checkbox"
                                            // className="form-check-input"
                                            id="exampleCheck4"
                                            name="notWorking"
                                            onChange={handlePaid}
                                            checked={isPaid}
                                        />
                                        <label class="form-check-label" for="exampleCheck4" style={{ fontSize: "12px", paddingLeft: "3px" }}>
                                            Pay
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {isPaid === true && (
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label className="form-label" htmlFor="payAmount" style={{ fontSize: "12px" }}>
                                        Pay Amount {isPaid === true && <sup style={{ color: "red" }}>*</sup>}
                                    </label>
                                    <input
                                        id="payAmount"
                                        style={{ fontSize: "13px" }}
                                        type="text"
                                        className="form-control form-control-sm "
                                        name="payAmount"
                                        value={orderFormValue.payAmount}
                                        onChange={handleChange}
                                    />
                                    <p className="errormsg" style={{ marginBottom: "0" }}>{orderFormError.payAmount}</p>

                                </div>
                                <div className="form-group col-md-6">
                                    <label className="form-label" style={{ fontSize: "12px" }}>
                                        Remaining Amount
                                    </label>
                                    <div>
                                        <input
                                            id="remainingAmount"
                                            style={{ fontSize: "13px" }}
                                            type="text"
                                            className="form-control form-control-sm "
                                            value={orderFormValue.payAmount === "" ? 0 : remainingAmount}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

            </div>

            <div className="FormFooter">
                <div className='checkedClose'>
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck2"
                        name="closeChecked"
                        onChange={handleOnChange}
                        checked={ordCloseChecked}
                    />
                    <label class="form-check-label" for="exampleCheck2">
                        Exit after submit
                    </label>
                </div>
                <div>
                    <button className='btn btn-sm btn-cmpy ml-2' style={{ background: "green" }} onClick={formSubmit} >Submit</button>
                    <button className='btn btn-sm btn-cmpy ml-2' onClick={closePopup}>Cancel</button>
                </div>
            </div>


        </>

    )
}
