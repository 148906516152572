import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import { showImgPreview } from "../hooks/ImagePreview";
import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";
import AttendanceContext from "../contextfolder/AttendanceContext/AttendanceContext";
import AttendancePop from "./AttendancePop";
import Select from "react-select";
import { GetEnglishDate } from "../hooks/dateConvertor";
import DeletePop from "./DeletePop";
import ReactTooltip from "react-tooltip";
import Tippy from "@tippyjs/react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { NavLink } from "react-router-dom";
import AcademicHeader from "../AcademicHeader";

export default function Attendance() {
  const { todayDate, customStylesForImage, AcademicYear } =
    useContext(SidebarContext);
  const {
    loading,
    originalList,
    setAttendanceList,
    popup,
    togglePopup,
    setIsSubmit,
    setAttendanceFormValue,
    setAttendanceFormError,
    attendanceValue,
    classList,
    sectionList,
    teacherList,
    selectClass,
    setSelectClass,
    selectTeacher,
    setSelectTeacher,
    todaysDate,
    setTodaysDate,
    attendanceList,
    setSelectSubject,
    selectSubject,
    subjectList,
    reportOption,
    setReportOption,
    selectedOptions,
    setSelectedOptions,
    setSection,
    setInputFields,
    deletePopup,
    handleDeleteTrue,
    handleDeleteFalse,
    handleDelete,
    setSelectedOptionsOne,
    selectedOptionsOne,
    setSubjectWise,
  } = useContext(AttendanceContext);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const styles = {
    popup: {
      marginTop:
        reportOption === "S" ? "0px" : reportOption === "C" ? "27px" : "25px",
      paddingLeft:
        reportOption === "S" || reportOption === "C" ? "0px" : "15px",
      marginBottom: "10px",
      paddingRight: "1px",
    },
  };

  const [imgPrv, setImgPrv] = useState(false);
  const [imagePre, setImagePre] = useState("");

  const searchInput = useRef("");

  const handleReport = (e) => {
    const target = e.target;
    const value = target.value;
    setReportOption(value);
    setSelectClass("");
    setSelectedOptionsOne("");
    setSelectTeacher("");
    setSelectSubject("");
  };

  const handleClass = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectClass(value);
    setSelectedOptionsOne("");
    setSelectTeacher("");
    setSelectSubject("");
  };

  const handleSection = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectedOptionsOne(value);
    setSelectTeacher("");
    setSelectSubject("");
  };

  const handleSubject = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectSubject(value);
  };

  const handleTeacher = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectTeacher(value);
    setSelectSubject("");
  };

  const handleTodayDate = ({ adDate }) => {
    setTodaysDate({ ...todaysDate, todayDate: adDate });
  };

  const columns = [
    {
      name: "Roll No.",
      width: "90px",
      center: true,
      cell: (row) => row.RollNo,
    },
    {
      name: "Image",
      center: true,
      width: "80px",
      selector: (row) => {
        return (
          <>
            <div className="staffContentLogo">
              <div className="staffImg">
                <img
                  src={row.Image}
                  alt=""
                  onClick={() => {
                    setImagePre(row.Image);
                    setImgPrv(true);
                  }}
                />
              </div>
            </div>
          </>
        );
      },
    },
    {
      name: "Student",

      selector: (row) => row.FullName,
    },
    {
      name: "Status",
      // grow: 0,
      width: "100px",
      center: true,
      selector: (row) => {
        return (
          <>
            {row.AttenStatus === "P" ? (
              <div className="badge badge-success"> Present </div>
            ) : (
              <div className="badge badge-danger">Absent</div>
            )}
          </>
          //
        );
      },
    },
  ];

  const closePopup = (e) => {
    e.preventDefault();
    togglePopup();
    setAttendanceFormValue(attendanceValue);
    setAttendanceFormError({});
    setIsSubmit(false);
    setSection([]);
    setInputFields([]);
    setSubjectWise(false);
  };

  // const closeEditPop = (e) => {
  //     e.preventDefault();
  //     toggleEditPopup();
  //     setAttendanceFormError({})
  //     setIsSubmit(false)
  // }

  const addnew = (e) => {
    e.preventDefault();
    togglePopup();
    setIsSubmit(false);
    setAttendanceFormValue(attendanceValue);
    setAttendanceFormError({});
  };

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["FullName"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setAttendanceList(srchResult);
      } else {
        setAttendanceList({});
      }
    } else {
      setAttendanceList(originalList);
    }
  };

  const dropDownValue = sectionList.map((item) => ({
    value: item.SectionID,
    label: item.SectionName,
  }));

  /* This is for export csv */

  const dataToExcel = attendanceList.map((d, i) => ({
    "Roll No.": d.RollNo,
    "Full Name": d.FullName,
    Status: d.AttenStatus === "P" ? "Present" : "Absent",
  }));

  const toExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(dataToExcel);
      var wscols = [{ wch: 5 }, { wch: 20 }, { wch: 15 }];
      worksheet["!cols"] = wscols;
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "Attendance");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_" + "export" + EXCEL_EXTENSION
        );
      }
    });
  };

  //end

  //This is to export pdf

  const dataWithIndex = attendanceList.map((d, i) => ({
    ...d,
    Index: i + 1,
    Status: d.AttenStatus === "P" ? "Present" : "Absent",
  }));

  const cols = [
    { header: "Roll No", field: "RollNo" },
    { header: "Full Name", field: "FullName" },
    { header: "Status", field: "Status" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const toPdf = () => {
    const doc = new jsPDF("p", "pt", "a4", true);

    var midPage = doc.internal.pageSize.getWidth() / 2;

    // doc.addImage(`${userinfo.SchPhoto}`, "JPG", 40, 40, 70, 60);

    doc.setFontSize(18);
    doc.text(`${userinfo.SchName}`, midPage, 50, null, null, "center");

    doc.setFontSize(15);
    doc.text(`${userinfo.SchAddress}`, midPage, 68, null, null, "center");

    doc.setFontSize(10);
    doc.text(
      `Tel:${userinfo.Phone}, E-mail:${userinfo.Email}`,
      midPage,
      85,
      null,
      null,
      "center"
    );

    doc.autoTable({
      startY: 100,
      theme: "grid",
      columns: exportColumns,
      body: dataWithIndex,
    });
    doc.save("Attendance.pdf");
  };
  //end

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />

      <div className="container-fluid classatten-wrapper  mt-3 ps-4 pe-4">
        <div className="row mt-3">
          <div className="page-header">
            <div>
              <h4>Attendance</h4>
            </div>
            <div className="page-date">
              <div className="sec-content">
                <AcademicHeader
                  todayDate={todayDate}
                  AcademicYear={AcademicYear}
                />
              </div>
            </div>
          </div>
          <div
            className="d-flex justify-content-between"
            style={{
              marginBottom: "1rem",
              backgroundColor: "#f4f8fb",
              marginLeft: "14px",
              padding: "0",
              maxWidth: "1230px",
            }}
          >
            <div className="d-flex">
              <NavLink
                className="nav-link"
                to="/attendance"
                style={{
                  padding: "0.75rem 1rem",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                activeClassName="active"
              >
                <i
                  className="fa fa-file-text-o mx-1 icon"
                  aria-hidden="true"
                ></i>
                <b>Attendance</b>
              </NavLink>
              <NavLink
                className="nav-link"
                to="/absent"
                style={{
                  padding: "0.75rem 1rem",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                activeClassName="active"
              >
                <i
                  className="fa fa-file-text-o mx-1 icon"
                  aria-hidden="true"
                ></i>
                <b>Absent</b>
              </NavLink>

              {/* <NavLink
                to="/attendanceReport"
                className="nav-link"
                style={{
                  padding: "0.75rem 1rem",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                activeClassName="active"
              >
                <i
                  className="fa fa-file-text-o mx-1 icon"
                  aria-hidden="true"
                ></i>
                <b>Attendance Report</b>
              </NavLink> */}
              <NavLink
                to="/monthlyReport"
                className="nav-link"
                style={{
                  padding: "0.75rem 1rem",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                activeClassName="active"
              >
                <i
                  className="fa fa-file-text-o mx-1 icon"
                  aria-hidden="true"
                ></i>
                <b>Report</b>
              </NavLink>
              <NavLink
                to="/monthlySummary"
                className="nav-link"
                style={{
                  padding: "0.75rem 1rem",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                activeClassName="active"
              >
                <i
                  className="fa fa-file-text-o mx-1 icon"
                  aria-hidden="true"
                ></i>
                <b>Summary</b>
              </NavLink>
            </div>
            <div className="btn-addlnote mt-2 mr-4">
              <button
                type="button"
                className="btn btn-sm"
                style={{
                  background: "var(--MainColor)",
                  color: "white",
                }}
                onClick={addnew}
              >
                Add Attendance
              </button>

              {todaysDate.todayDate === GetEnglishDate(todayDate) &&
              attendanceList.length > 0 ? (
                <button
                  data-tip="Remove Today's Attendance"
                  type="button"
                  className="btn btn-sm ml-2"
                  style={{
                    background: "var(--MainColor)",
                    color: "white",
                  }}
                  onClick={handleDelete}
                >
                  Remove Attandance
                </button>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <>
          <div className="sec-dataTable content-section main-content">
            <div className="upper-dataTbl">
              <div className="exportDiv">
                <Tippy content="Excel">
                  <button className="exportButtons  mx-2" onClick={toExcel}>
                    <i className="bi bi-file-earmark-excel-fill exportButtonIconsExcel"></i>
                  </button>
                </Tippy>

                <Tippy content="PDF">
                  <button className="exportButtons" onClick={toPdf}>
                    <i className="bi bi-file-earmark-pdf-fill exportButtonIconsPdf"></i>
                  </button>
                </Tippy>
              </div>
            </div>
            <hr className="new-hr-new" />
            {loading ? (
              <Spinner />
            ) : (
              <DataTable
                columns={columns}
                data={attendanceList}
                customStyles={customStylesForImage}
                pagination
                fixedHeader
                fixedHeaderScrollHeight="280px"
                highlightOnHover
                pointerOnHover
                responsive
                dense
                striped
                subHeader
                subHeaderAlign="left"
                subHeaderComponent={
                  <>
                    <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                      <label className="list-label">Select Option</label>
                      <select
                        style={{ fontSize: "11px" }}
                        name="status"
                        className="form-control form-control-sm"
                        aria-label="Default select example "
                        value={reportOption}
                        onChange={handleReport}
                      >
                        <option
                          selected
                          disabled
                          value=""
                          style={{ fontSize: "11px" }}
                        >
                          Select Option
                        </option>
                        <option value="C">Class Wise</option>
                        <option value="S">Subject Wise</option>
                      </select>
                      <i class="fas fa-angle-down  notificationpositon"></i>
                    </div>
                    {reportOption === "C" || reportOption === "S" ? (
                      <>
                        <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                          <label className="list-label">Class</label>
                          <select
                            style={{ fontSize: "11px" }}
                            name="snotifiaction"
                            value={selectClass}
                            onChange={handleClass}
                            className="form-control form-control-sm"
                            aria-label="Default select example "
                          >
                            <option
                              value=""
                              disabled
                              selected
                              style={{ fontSize: "11px" }}
                            >
                              Select Class
                            </option>
                            <option value="-1">All</option>
                            {classList.map((item) => (
                              <option key={item.ClassID} value={item.ClassID}>
                                {item.ClassName}
                              </option>
                            ))}
                          </select>
                          <i class="fas fa-angle-down  notificationpositon"></i>
                        </div>

                        <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                          <label className="list-label">Section</label>
                          {/* {userinfo.UserType === "T" ? (
                                                    <Select
                                                        className="select"
                                                        style={{ fontSize: "11px" }}
                                                        isMulti
                                                        options={dropDownValue}
                                                        onChange={(item) => setSelectedOptions(item.map((item) => ({
                                                            "SectionID": item.value,
                                                        })))}
                                                        isSearchable={false}
                                                    />
                                                ) : ( */}
                          <select
                            style={{ fontSize: "11px" }}
                            name="status"
                            className="form-control form-control-sm"
                            aria-label="Default select example "
                            value={selectedOptionsOne}
                            onChange={handleSection}
                          >
                            <option
                              selected
                              disabled
                              value=""
                              style={{ fontSize: "11px" }}
                            >
                              Select Option
                            </option>
                            <option value="-1">All</option>
                            {sectionList.map((item) => (
                              <option
                                key={item.SectionID}
                                value={item.SectionID}
                              >
                                {item.SectionName}
                              </option>
                            ))}
                          </select>
                          {/* )} */}

                          <i className="fas fa-angle-down  notificationpositon"></i>
                        </div>
                        {userinfo.UserType !== "T" ? (
                          <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                            <label className="list-label">Teacher</label>
                            <select
                              style={{ fontSize: "11px" }}
                              name="snotifiaction"
                              value={selectTeacher}
                              onChange={handleTeacher}
                              className="form-control form-control-sm"
                              aria-label="Default select example"
                            >
                              <option
                                value=""
                                disabled
                                selected
                                style={{ fontSize: "11px" }}
                              >
                                Select Teacher
                              </option>

                              {teacherList.map((item) => (
                                <option key={item.UserID} value={item.UserID}>
                                  {item.FullName}
                                </option>
                              ))}
                            </select>
                            <i class="fas fa-angle-down  notificationpositon"></i>
                          </div>
                        ) : (
                          <> </>
                        )}
                      </>
                    ) : (
                      <> </>
                    )}

                    {reportOption === "S" && (
                      <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                        <label className="list-label">Subject</label>
                        <select
                          style={{ fontSize: "11px" }}
                          name="snotifiaction"
                          value={selectSubject}
                          onChange={handleSubject}
                          className="form-control form-control-sm"
                          aria-label="Default select example"
                        >
                          <option
                            value=""
                            disabled
                            selected
                            style={{ fontSize: "11px" }}
                          >
                            Select Subject
                          </option>
                          {subjectList.map((item) => (
                            <option key={item.SubjectID} value={item.SubjectID}>
                              {item.SubjectName}
                            </option>
                          ))}
                        </select>
                        <i class="fas fa-angle-down  notificationpositon"></i>
                      </div>
                    )}
                    {reportOption === "C" || reportOption === "S" ? (
                      <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                        <label className="list-label">Today Date</label>
                        <Calendar
                          className="form-control form-control-sm searchField"
                          dateFormat="YYYY/MM/DD"
                          theme="default"
                          language="en"
                          values={todaysDate}
                          onChange={handleTodayDate}
                        />
                      </div>
                    ) : (
                      <> </>
                    )}
                    <div
                      className={
                        reportOption === "C" && userinfo.UserType === "T"
                          ? "col-lg-2 offset-lg-2 col-md-2 col-sm-2 dropdown2"
                          : reportOption === "C"
                          ? "col-lg-2 offset-lg-0 col-md-2 col-sm-2 dropdown2"
                          : reportOption === "S" && userinfo.UserType === "T"
                          ? "col-lg-2 offset-lg-0 col-md-2 col-sm-2 align-self-end Search"
                          : reportOption === "S"
                          ? "col-lg-2 offset-lg-10 offset-md-10 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
                          : reportOption === ""
                          ? "col-lg-2 offset-lg-8 offset-md-8 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
                          : ""
                      }
                      style={styles.popup}
                    >
                      <div className="d-flex w-100 justify-content-end">
                        <input
                          placeholder="Search"
                          ref={searchInput}
                          type="text"
                          className="form-control form-control-sm searchField"
                          onChange={searchHandler}
                        />
                      </div>
                    </div>
                  </>
                }
              />
            )}
          </div>
        </>
      </div>

      {popup && (
        <div className="modals">
          <div className="overlay"></div>

          <div className="notification-model-content">
            <div className="popUpHeader ">
              <div className="popUpHeaderText ">Add Attendance</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closePopup}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="inside-modal">
              <AttendancePop closePopup={closePopup} />
            </div>
          </div>
        </div>
      )}

      {imgPrv &&
        showImgPreview({
          img: imagePre,
          setTrigger: setImgPrv,
        })}

      {deletePopup.show && (
        <DeletePop
          handleDeleteTrue={handleDeleteTrue}
          handleDeleteFalse={handleDeleteFalse}
        />
      )}
      <ReactTooltip />
    </>
  );
}
