import React, { useContext, useEffect, useState } from "react";
import CloseIcon from "../../images/CloseIcon.svg";
import AdminLeaveContext from "../contextfolder/AdminLeaveContext/AdminLeaveContext";
import OrderContext from "../contextfolder/OrderContext/OrderContext";



export default function OrderStatus({ handleOrderFalse, handleOrderTrue }) {

    const { payFormValue, setPayFormValue, payFormError, setPayFormError, orderIsSubmit, setOrderIsSubmit, payValue } = useContext(OrderContext)



    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;
        setPayFormValue({ ...payFormValue, [name]: value });
    };


    const [state, setState] = useState(false)
    const formSubmit = (e) => {
        e.preventDefault();
        setState(true)
        setOrderIsSubmit(true);
    }


    useEffect(() => {
        if (state && orderIsSubmit) {
            handleOrderTrue(payFormValue)
            setOrderIsSubmit(false);
        }

    }, [state, orderIsSubmit]);



    return (
        <>
            <div className="container confirm-leave-popup">
                <div className="confirm-leave-popup-inner ">
                    <div className="popUpHeader ps-0 pe-0">
                        <div className="popUpDelTitle">Easy School</div>
                        <div className="popUpClose">
                            <img style={{ paddingRight: "10px", cursor: "pointer" }}
                                className="popUpCloseIcon"
                                src={CloseIcon}
                                alt="CloseIcon"
                                onClick={handleOrderFalse}
                            />
                        </div>
                    </div>

                    <div className="confirm-pay-body  ps-3 pe-3">
                        <div className="row text-start ">
                            <div className="delText">What do you want to do with this order?</div>
                        </div>
                    </div>
                    <div className="container-fluid basicform">

                        <div className="form-group">
                            <div>
                                <label className="form-label" htmlFor="purpose">Order Status</label>
                            </div>
                            <div>
                                <input type="radio" className="radio--button" name="orderStatus" id="pending" onChange={handleChange} value="P" checked={payFormValue.orderStatus === "P"} />
                                <label className="label-radio-routine" htmlFor="pending" style={{ marginRight: "10px" }}>Pending</label>

                                <input type="radio" className="radio--button" name="orderStatus" id="delivered" onChange={handleChange} value="D" checked={payFormValue.orderStatus === "D"} />

                                <label className="label-radio-routine" htmlFor="delivered" style={{ marginRight: "10px" }}>Delivered</label>

                                <input type="radio" className="radio--button" name="orderStatus" id="cancelled" onChange={handleChange} value="C" checked={payFormValue.orderStatus === "C"} />
                                <label className="label-radio-routine" htmlFor="cancelled" style={{ marginRight: "10px" }}>Cancelled</label>
                            </div>
                        </div>
                    </div>


                    <div className="confirm-leave-footer">
                        <div className="row  mt-2 mb-2">
                            <div>
                                <button
                                    type="button"
                                    class="btn btn-sm me-2"
                                    style={{ background: "#0079bf", color: "white" }}
                                    onClick={formSubmit}
                                >
                                    Okay
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-sm btn-danger me-3"
                                    style={{ color: "white" }}
                                    onClick={handleOrderFalse}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

