import React, { useContext, useEffect, useState } from "react";
import { Fetchdata } from "../../hooks/getData";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import StudentMenuContext from "./StudentMenuContext";
import { toast } from "react-toastify";

function StudentMenuState(props) {
  const { appURL } = useContext(SidebarContext);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);

  const [categoryList, setCategoryList] = useState([]);
  const [selectCategory, setSelectCategory] = useState("-1");
  const [selectMenu, setSelectMenu] = useState("-1");

  const [menuList, setMenuList] = useState([]);

  useEffect(() => {
    getCategoryList();
  }, []);

  const getCategoryList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "SM",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/menu`,
    };
    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setCategoryList(postResult);
      } else {
        setCategoryList([]);
      }
    });
  };

  useEffect(() => {
    getMenuList();
  }, [selectCategory, selectMenu]);

  const getMenuList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      CategoryID: selectCategory,
      MenuType: selectMenu,
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/menu`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setLoading(false);
        setMenuList(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        setMenuList([]);
        setOriginalList("");
      }
    });
  };

  /* This is for Student Order */

  const [ordLoading, setOrdLoading] = useState(true);
  const [ordOriginalList, setOrdOriginalList] = useState(null);

  const [ordCloseChecked, setOrdCloseChecked] = useState(false);

  const orderValue = {
    topic: "",
    title: "",
    description: "",
  };

  const [orderFormValue, setOrderFormValue] = useState(orderValue);
  const [orderFormError, setOrderFormError] = useState({});
  const [ordIsSubmit, setOrdIsSubmit] = useState({});

  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  const [activeTab, setActiveTab] = useState(1);

  const [catLoad, setCatLoad] = useState(true);
  const [catSearch, setCatSearch] = useState(null);

  const [choosePayment, setChoosePayment] = useState("-1");
  const [chooseOrder, setChooseOrder] = useState("-1");

  //API to hit Menu list
  const [menuListForOrder, setMenuListForOrder] = useState([]);

  useEffect(() => {
    getMenuListforOrder();
  }, [activeTab]);

  const getMenuListforOrder = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "S",
      IsActive: "-1",
      IsAvail: "-1",
      MenuType: "-1",
      CategoryID: activeTab,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/menu`,
      Type: "POST",
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setMenuListForOrder(postResult);
        setCatSearch(postResult);
        setCatLoad(false);
      } else {
        setCatSearch([]);
        setMenuListForOrder([]);
      }
    });
  };

  // This is for add to cart items

  const [cartItems, setCartItems] = useState([]);

  const onAdd = (product) => {
    const exist = cartItems.find((x) => x.MenuID === product.MenuID);
    if (exist) {
      setCartItems(
        cartItems.map((x) =>
          x.MenuID === product.MenuID ? { ...exist, Qty: exist.Qty + 1 } : x
        )
      );
    } else {
      setCartItems([...cartItems, { ...product, Qty: 1 }]);
    }
  };

  const onRemove = (product) => {
    const exist = cartItems.find((x) => x.MenuID === product.MenuID);
    if (exist.Qty === 1) {
      setCartItems(cartItems.filter((x) => x.MenuID !== product.MenuID));
    } else {
      setCartItems(
        cartItems.map((x) =>
          x.MenuID === product.MenuID ? { ...exist, Qty: exist.Qty - 1 } : x
        )
      );
    }
  };

  const extractedData = cartItems.map((item) => {
    return {
      MenuID: item.MenuID.toString(),
      Price: item.Price,
      Qty: item.Qty.toString(),
    };
  });

  // Create API for student order

  const categoryData = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "i",
      IsPaid: "N",
      Values: extractedData,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/menu-order`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getItemOrderList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
          theme: "light",
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        });
      }
    });
  };

  // to list the order items

  const [itemOrderList, setItemOrderList] = useState([]);

  useEffect(() => {
    getItemOrderList();
  }, [choosePayment, chooseOrder]);

  const getItemOrderList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "S",
      OrderStatus: chooseOrder,
      IsPaid: choosePayment,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/menu-order`,
      Type: "POST",
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setItemOrderList(postResult);
        setOrdOriginalList(postResult);
        setOrdLoading(false);
      } else {
        setOrdOriginalList([]);
        setItemOrderList([]);
      }
    });
  };

  //API to delete Job

  // //Everything to pop up and delete the notification list
  const [deletePopup, setDeletePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const [delId, setDelId] = useState("");

  const handleDelete = async (datas) => {
    setDeletePopup({
      show: true,
    });
    setDelId(datas);
  };
  const trimmedOrderID = delId.substring(1);
  // This will perform the deletion and hide the Confirmation Box

  const handleDeleteTrue = async () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "R",
      OrderID: trimmedOrderID,
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/menu-order`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          getItemOrderList();
          toast(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
        } else {
          toast(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
    });
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleDeleteFalse = () => {
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // Ends Here

  const [popup, setPopup] = useState(false);

  const togglePopup = () => {
    setPopup(!popup);
  };

  const [infoID, setInfoID] = useState("");

  const handleInfo = (data) => {
    setInfoID(data);
    togglePopup();
  };

  const trimmedID = infoID.substring(1);

  const [orderInfo, setOrderInfo] = useState([]);

  useEffect(() => {
    getOrderInfo();
  }, [trimmedID]);

  const getOrderInfo = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "SI",
      OrderID: trimmedID,
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/menu-order`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values[0] ? result.Values[0] : "";
        setLoading(false);
        setOrderInfo(postResult);
      } else {
        setLoading(false);
        setOrderInfo([]);
      }
    });
  };

  /* This is for Student Order ends*/

  return (
    <StudentMenuContext.Provider
      value={{
        loading,
        originalList,
        categoryList,
        setCategoryList,
        selectCategory,
        setSelectCategory,
        menuList,
        setMenuList,
        selectMenu,
        setSelectMenu,

        ordLoading,
        setOrdLoading,
        ordOriginalList,
        setOrdOriginalList,
        ordCloseChecked,
        setOrdCloseChecked,
        orderValue,
        orderFormValue,
        setOrderFormValue,
        orderFormError,
        setOrderFormError,
        ordIsSubmit,
        setOrdIsSubmit,
        toggleModal,
        modal,
        menuListForOrder,
        activeTab,
        setActiveTab,
        catLoad,
        catSearch,
        setCatSearch,

        onAdd,
        onRemove,
        cartItems,
        setCartItems,
        categoryData,
        choosePayment,
        setChoosePayment,
        chooseOrder,
        setChooseOrder,
        itemOrderList,
        setItemOrderList,

        deletePopup,
        handleDeleteTrue,
        handleDeleteFalse,
        handleDelete,

        togglePopup,
        popup,
        handleInfo,
        orderInfo,
        setInfoID,
      }}
    >
      {props.children}
    </StudentMenuContext.Provider>
  );
}

export default StudentMenuState;
