import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import { showImgPreview } from "../hooks/ImagePreview";
import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";
import AttendanceContext from "../contextfolder/AttendanceContext/AttendanceContext";
import AttendancePop from "./AttendancePop";
import Select from "react-select";
import { GetEnglishDate, GetNepaliDate } from "../hooks/dateConvertor";
import DeletePop from "./DeletePop";
import ReactTooltip from "react-tooltip";
import Tippy from "@tippyjs/react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { NavLink } from "react-router-dom";
import { Fetchdata } from "../hooks/getData";
import NepaliDate from "nepali-date-converter";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { PDFExport } from "@progress/kendo-react-pdf";
import AcademicHeader from "../AcademicHeader";

export default function MonthlyReport() {
  const { appURL, todayDate, customStylesForImage, AcademicYear, currentYear } =
    useContext(SidebarContext);
  const [monthlyReport, setMonthlyReport] = useState([]);
  const [selectedFromDate, setSelectedFromDate] = useState("");

  const {
    loading,
    setLoading,
    setOriginalList,
    originalList,
    setAttendanceList,
    popup,
    togglePopup,
    setIsSubmit,
    setAttendanceFormValue,
    setAttendanceFormError,
    attendanceValue,
    classList,
    sectionList,
    teacherList,
    selectClass,
    setSelectClass,
    selectTeacher,
    setSelectTeacher,
    todaysDate,
    setTodaysDate,
    attendanceList,
    setSelectSubject,
    selectSubject,
    subjectList,
    reportOption,
    setReportOption,
    selectedOptions,
    setSelectedOptions,
    setSection,
    setInputFields,
    deletePopup,
    handleDeleteTrue,
    handleDeleteFalse,
    handleDelete,
    setSelectedOptionsOne,
    selectedOptionsOne,
    setSubjectWise,
  } = useContext(AttendanceContext);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const searchInput = useRef("");

  useEffect(() => {
    setSelectClass("");
    setSelectedOptionsOne("");
  }, []);

  // const handleReport = (e) => {
  //     const target = e.target;
  //     const value = target.value;
  //     setReportOption(value);
  // };

  const handleClass = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectClass(value);
    setSelectedOptionsOne("");
  };

  const handleSection = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectedOptionsOne(value);
  };

  // const handleSubject = (e) => {
  //     const target = e.target;
  //     const value = target.value;
  //     setSelectSubject(value);
  // };

  // const handleTeacher = (e) => {
  //     const target = e.target;
  //     const value = target.value;
  //     setSelectTeacher(value);
  // };

  const handleTodayDate = ({ adDate }) => {
    const rDate = Date.parse(adDate);
    const nepDate = new NepaliDate(rDate).getBS();
    var cm = nepDate.month + 1;
    let zm = cm < 10 ? `0${cm}` : cm;
    //
    var cd = nepDate.date;
    let zd = cd < 10 ? `0${cd}` : cd;
    let strDate = nepDate.year + "/" + zm + "/" + zd;
    setTodaysDate(strDate);
  };

  // Assuming selectedFromDate is in BS format
  // const bsDate = selectedFromDate;
  // const adDate = NepaliDateConverter.getBS(bsDate);

  const handleFormDate = ({ adDate }) => {
    const rDate = Date.parse(adDate);
    const nepDate = new NepaliDate(rDate).getBS();

    var cm = nepDate.month + 1;
    let zm = cm < 10 ? `0${cm}` : cm;
    //
    var cd = nepDate.date;
    let zd = cd < 10 ? `0${cd}` : cd;
    let strDate = nepDate.year + "/" + zm + "/" + zd;

    setSelectedFromDate(strDate);
  };

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["FullName"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setMonthlyReport(srchResult);
      } else {
        setMonthlyReport({});
      }
    } else {
      setMonthlyReport(originalList);
    }
  };

  //end

  useEffect(() => {
    getAttendanceList();
  }, [
    todaysDate,
    selectSubject,
    selectClass,
    selectedOptionsOne,
    selectedFromDate,
  ]);

  const getAttendanceList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "S",
      ClassID: selectClass,
      SectionID: selectedOptionsOne.toString(),
      IsSubjective: reportOption === "S" ? "Y" : "N",
      SubjectID: reportOption === "S" ? selectSubject : "1",
      DFlag: "R",
      DtFlag: "F",
      DateFrom: selectedFromDate,
      DateTo: todaysDate,
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/attendance-repsum`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.AttenLst ? result.AttenLst : "";

        setLoading(false);
        setMonthlyReport(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        setMonthlyReport([]);
        setOriginalList([]);
      }
    });
  };

  // -----new monthly report list grouped by fullname-----------

  const groupedByName = monthlyReport.reduce((acc, curr) => {
    if (acc[curr.FullName]) {
      acc[curr.FullName].push(curr);
    } else {
      acc[curr.FullName] = [curr];
    }
    return acc;
  }, {});

  const newMonthlyReportList = Object.keys(groupedByName).map((name) => ({
    FullName: name,
    data: groupedByName[name],
  }));

  // ------- list of unique date only -------
  const uniqueDate = [...new Set(monthlyReport.map((item) => item.NepaliDate))];

  // ----new object to match AttenDate with unique date ----

  // create an object to store the attendance data
  const monthlyReportData = {};

  // loop through the data and populate the object
  newMonthlyReportList.forEach((item) => {
    monthlyReportData[item.FullName] = {};

    uniqueDate.forEach((date) => {
      const data = item.data.find((d) => d.NepaliDate === date);

      if (data) {
        monthlyReportData[item.FullName][date] = {
          AttendStatus: data.AttendStatus,
          NepaliDate: data.NepaliDate,
          UserID: data.UserID,
        };
      } else {
        monthlyReportData[item.FullName][date] = null;
      }
    });
  });

  // FOr pdf for complex table in teacher attendance report

  const pdfExportComponent = useRef(null);

  const toPdf = (e) => {
    pdfExportComponent.current.save();
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />

      <div className="container-fluid classatten-wrapper  mt-3 ps-4 pe-4">
        <div className="row mt-3">
          <div className="page-header">
            <div>
              <h4>Report</h4>
            </div>
            <div className="page-date">
              <div className="sec-content">
                <AcademicHeader
                  todayDate={todayDate}
                  AcademicYear={AcademicYear}
                />
              </div>
            </div>
          </div>
          <div
            className="d-flex justify-content-between"
            style={{
              marginBottom: "1rem",
              backgroundColor: "#f4f8fb",
              marginLeft: "14px",
              padding: "0",
              maxWidth: "1230px",
            }}
          >
            <div className="d-flex">
              <NavLink
                className="nav-link"
                to="/attendance"
                style={{
                  padding: "0.75rem 1rem",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                activeClassName="active"
              >
                <i
                  className="fa fa-file-text-o mx-1 icon"
                  aria-hidden="true"
                ></i>
                <b>Attendance</b>
              </NavLink>
              <NavLink
                className="nav-link"
                to="/absent"
                style={{
                  padding: "0.75rem 1rem",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                activeClassName="active"
              >
                <i
                  className="fa fa-file-text-o mx-1 icon"
                  aria-hidden="true"
                ></i>
                <b>Absent</b>
              </NavLink>

              {/* <NavLink
                to="/attendanceReport"
                className="nav-link"
                style={{
                  padding: "0.75rem 1rem",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                activeClassName="active"
              >
                <i
                  className="fa fa-file-text-o mx-1 icon"
                  aria-hidden="true"
                ></i>
                <b>Attendance Report</b>
              </NavLink> */}
              <NavLink
                to="/monthlyReport"
                className="nav-link"
                style={{
                  padding: "0.75rem 1rem",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                activeClassName="active"
              >
                <i
                  className="fa fa-file-text-o mx-1 icon"
                  aria-hidden="true"
                ></i>
                <b>Report</b>
              </NavLink>
              <NavLink
                to="/monthlySummary"
                className="nav-link"
                style={{
                  padding: "0.75rem 1rem",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                activeClassName="active"
              >
                <i
                  className="fa fa-file-text-o mx-1 icon"
                  aria-hidden="true"
                ></i>
                <b>Summary</b>
              </NavLink>
            </div>
          </div>
        </div>
        <>
          <div className="sec-dataTable content-section main-content">
            <div className="upper-dataTbl">
              <div className="exportDiv">
                {/* <button className="exportButtons  mx-2" onClick={toExcel}>
                    <i className="bi bi-file-earmark-excel-fill exportButtonIconsExcel"></i>
                  </button> */}

                <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  className="exportButtons"
                  table="table-to-xls"
                  filename="tablexls"
                  sheet="tablexls"
                  buttonText={
                    <i className="bi bi-file-earmark-excel-fill exportButtonIconsExcel"></i>
                  }
                />

                <Tippy content="PDF">
                  <button className="exportButtons" onClick={toPdf}>
                    <i className="bi bi-file-earmark-pdf-fill exportButtonIconsPdf"></i>
                  </button>
                </Tippy>
              </div>
            </div>
            <hr className="new-hr-new" />

            <div className="d-flex">
              <>
                <>
                  <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                    <label className="list-label">Class</label>
                    <select
                      style={{ fontSize: "11px" }}
                      name="snotifiaction"
                      value={selectClass}
                      onChange={handleClass}
                      className="form-control form-control-sm"
                      aria-label="Default select example "
                    >
                      <option
                        value=""
                        disabled
                        selected
                        style={{ fontSize: "11px" }}
                      >
                        Select Class
                      </option>
                      {/* <option value="-1">All</option> */}
                      {classList.map((item) => (
                        <option key={item.ClassID} value={item.ClassID}>
                          {item.ClassName}
                        </option>
                      ))}
                    </select>
                    <i class="fas fa-angle-down  notificationpositon"></i>
                  </div>

                  <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                    <label className="list-label">Section</label>
                    {/* {userinfo.UserType === "T" ? (
                                                    <Select
                                                        className="select"
                                                        style={{ fontSize: "11px" }}
                                                        isMulti
                                                        options={dropDownValue}
                                                        onChange={(item) => setSelectedOptions(item.map((item) => ({
                                                            "SectionID": item.value,
                                                        })))}
                                                        isSearchable={false}
                                                    />
                                                ) : ( */}
                    <select
                      style={{ fontSize: "11px" }}
                      name="status"
                      className="form-control form-control-sm"
                      aria-label="Default select example "
                      value={selectedOptionsOne}
                      onChange={handleSection}
                    >
                      <option
                        selected
                        disabled
                        value=""
                        style={{ fontSize: "11px" }}
                      >
                        Select Option
                      </option>
                      {/* <option value="-1">All</option> */}
                      {sectionList.map((item) => (
                        <option key={item.SectionID} value={item.SectionID}>
                          {item.SectionName}
                        </option>
                      ))}
                    </select>
                    {/* )} */}

                    <i className="fas fa-angle-down  notificationpositon"></i>
                  </div>
                </>
                {/* <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                  <label className="list-label">From Date</label>
                  <Calendar
                    className="form-control form-control-sm searchField"
                    dateFormat="YYYY/MM/DD"
                    theme="default"
                    language="en"
                    values={selectedFromDate}
                    onChange={handleFormDate}
                  />
                </div> */}

                {/* <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                  <label className="list-label">Today Date</label>
                  <Calendar
                    className="form-control form-control-sm searchField"
                    dateFormat="YYYY/MM/DD"
                    theme="default"
                    language="en"
                    values={todaysDate}
                    onChange={handleTodayDate}
                  />
                </div> */}

                <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                  <label className="list-label">From Date</label>
                  <Calendar
                    className="form-control form-control-sm searchField"
                    dateFormat="YYYY/MM/DD"
                    theme="default"
                    language="en"
                    values={selectedFromDate}
                    onChange={handleFormDate}
                  />
                </div>

                <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                  <label className="list-label">Today Date</label>
                  <Calendar
                    className="form-control form-control-sm searchField"
                    dateFormat="YYYY/MM/DD"
                    theme="default"
                    language="en"
                    values={todaysDate}
                    onChange={handleTodayDate}
                  />
                </div>
                <div className="d-flex mt-3 justify-content-end absent-student-list flex-grow-1">
                  <div className="d-flex">
                    <input
                      placeholder="Search"
                      ref={searchInput}
                      type="text"
                      className="form-control form-control-sm searchField"
                      onChange={searchHandler}
                    />
                  </div>
                </div>
              </>
            </div>

            {monthlyReport.length > 0 ? (
              <>
                <PDFExport ref={pdfExportComponent}>
                  <div className="table-responsive br-3">
                    <table
                      className="table table-striped table-bordereds text-nowrap mb-0"
                      id="table-to-xls"
                    >
                      {/* head */}

                      <tr className="tableHead">
                        <td
                          className="rdt_TableHeadRow tableHead text-center"
                          width="70px"
                          style={{
                            verticalAlign: "middle",
                            borderBottom: "0px",
                          }}
                        >
                          S.N.
                        </td>
                        <td
                          className="rdt_TableHeadRow tableHead text-start"
                          // width="200px"
                          style={{
                            verticalAlign: "middle",
                            borderBottom: "0px",
                          }}
                        >
                          Full Name
                        </td>

                        {uniqueDate.map((date) => {
                          // To show only the day, not the month and year
                          const showDay = date.split("/")[2];
                          return (
                            <td
                              className="rdt_TableHeadRow tableHead text-start"
                              width="100px"
                              style={{ borderBottom: "0px" }}
                            >
                              {date}
                            </td>
                          );
                        })}

                        <td
                          className="rdt_TableHeadRow tableHead text-start"
                          width="200px"
                          style={{
                            verticalAlign: "middle",
                            borderBottom: "0px",
                          }}
                        >
                          Total present
                        </td>
                        <td
                          className="rdt_TableHeadRow tableHead text-start"
                          width="200px"
                          style={{
                            verticalAlign: "middle",
                            borderBottom: "0px",
                          }}
                        >
                          Total absent
                        </td>
                        <td
                          className="rdt_TableHeadRow tableHead text-start"
                          width="200px"
                          style={{
                            verticalAlign: "middle",
                            borderBottom: "0px",
                          }}
                        >
                          Total days
                        </td>
                      </tr>

                      {/* head */}

                      {/* cell */}
                      {Object.keys(monthlyReportData).map((fullName, i) => {
                        const userData = monthlyReportData[fullName];
                        const totalPresent = Object.values(userData).filter(
                          (data) => data && data.AttendStatus === "P"
                        ).length;
                        const totalAbsent = Object.values(userData).filter(
                          (data) => data && data.AttendStatus === "A"
                        ).length;
                        return (
                          <>
                            <tr>
                              <td className="rdt_TableCell tablecell  text-center">
                                {i + 1}
                              </td>

                              <td className="rdt_TableCell tablecell text-start">
                                {fullName}
                              </td>
                              {uniqueDate.map((date) => {
                                const data = monthlyReportData[fullName][date];

                                if (data) {
                                  return (
                                    <td className="rdt_TableCell tablecell text-start">
                                      {data.AttendStatus}
                                    </td>
                                  );
                                } else {
                                  return (
                                    <td className="rdt_TableCell tablecell text-start">
                                      -
                                    </td>
                                  );
                                }
                              })}
                              <td className="rdt_TableCell tablecell text-start">
                                {totalPresent}
                              </td>
                              <td className="rdt_TableCell tablecell text-start">
                                {totalAbsent}
                              </td>
                              <td className="rdt_TableCell tablecell text-start">
                                {totalPresent + totalAbsent}
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </table>
                  </div>
                </PDFExport>
              </>
            ) : (
              <div className="table-responsive br-3">
                <div className="text-center mt-4">
                  <p>There are no records to display.</p>
                </div>
              </div>
            )}
          </div>
        </>
      </div>

      <ReactTooltip />
    </>
  );
}
