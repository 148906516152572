import React, { useContext, useEffect, useState } from "react";
import { Fetchdata } from "../../hooks/getData";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { toast } from "react-toastify";
import GalleryContext from "./GalleryContext";

function GalleryState(props) {
  const { appURL } = useContext(SidebarContext);

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const photoValue = {
    selectdrop: "",
    class: "",
    section: "",
    student: "",
    album: "",
    title: "",
  };
  const [photoFormValue, setPhotoFormValue] = useState(photoValue);
  const [photoFormError, setPhotoFormError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const [popups, setPopups] = useState(false);
  const togglePopup = () => {
    setPopups(!popups);
  };

  const [isUploaded, setIsUploaded] = useState(false);
  const [typeFile, setTypeFile] = useState("");
  const [image, setImage] = useState("");

  const [closeChecked, setCloseChecked] = useState(false);

  const [reportOption, setReportOption] = useState("");

  const [classList, setClassList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [studentList, setStudentList] = useState([]);
  const [albumList, setAlbumList] = useState([]);

  const [selectClass, setSelectClass] = useState("");
  const [selectSection, setSelectSection] = useState("");
  const [selectStudent, setSelectStudent] = useState("");
  const [selectAlbum, setSelectAlbum] = useState("");

  // For multiple image upload
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedBase64, setSelectedBase64] = useState([]);

  useEffect(() => {
    getClassList();
  }, []);

  const getClassList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      DFlag: "C",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setLoading(false);
        setClassList(postResult);
        togglePopup();
      } else {
        setClassList([]);
      }
    });
  };

  useEffect(() => {
    getSectionList();
  }, [selectClass]);

  const getSectionList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      ClassID: selectClass,
      DFlag: "S",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";

        setSectionList(postResult);
      } else {
        setSectionList([]);
      }
    });
  };

  //API to hit student list

  useEffect(() => {
    stdList();
  }, [selectClass, selectSection]);

  const stdList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      Flag: "S",
      ClassID: selectClass,
      SectionID: [
        {
          SectionID: selectSection,
        },
      ],
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/student`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.StudentLst ? result.StudentLst : "";
        setStudentList(postResult);
      } else {
        setStudentList([]);
      }
    });
  };

  useEffect(() => {
    getAlbumList();
  }, [selectClass, selectSection, selectStudent]);

  const getAlbumList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "S",
      ClassID: selectClass,
      SectionID: selectSection,
      MemID: selectStudent,
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/gallery/album`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.AlbumLst ? result.AlbumLst : "";
        setAlbumList(postResult);
      } else {
        setAlbumList([]);
      }
    });
  };

  //API to create gallery-photo
  const fetchdata = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "i",
      Title: photoFormValue.title,
      AlbumID: photoFormValue.album,
      Photo: selectedBase64,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/gallery/photo`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getPhotoList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };

  // API for photo List
  const [photoList, setPhotoList] = useState([]);

  useEffect(() => {
    getPhotoList();
  }, [selectAlbum]);

  const getPhotoList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      AlbumID: selectAlbum,
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/gallery/photo`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.AlbumLst ? result.AlbumLst : "";
        setLoading(false);
        setPhotoList(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        setPhotoList([]);
        setOriginalList([]);
      }
    });
  };

  //Everything to pop up and delete the event list
  const [deletePopup, setDeletePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const [delData, setDelData] = useState();

  const handleDelete = async (data) => {
    setDelData(data);

    setDeletePopup({
      show: true,
      data,
    });
  };

  // This will perform the deletion and hide the Confirmation Box

  const handleDeleteTrue = async () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "R",
      PhotoID: delData,
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/gallery/photo`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          getPhotoList();
          toast(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
        } else {
          toast(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
    });
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleDeleteFalse = () => {
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // Ends Here

  return (
    <GalleryContext.Provider
      value={{
        loading,
        originalList,
        photoList,
        setPhotoList,
        popups,
        setPopups,
        togglePopup,
        photoFormValue,
        setPhotoFormValue,
        photoFormError,
        setPhotoFormError,
        isSubmit,
        setIsSubmit,
        photoValue,
        setImage,
        setIsUploaded,
        isUploaded,
        typeFile,
        setTypeFile,
        image,
        fetchdata,
        deletePopup,
        handleDeleteTrue,
        handleDeleteFalse,
        handleDelete,
        closeChecked,
        setCloseChecked,
        classList,
        sectionList,
        studentList,
        albumList,
        selectClass,
        setSelectClass,
        selectSection,
        setSelectSection,
        selectStudent,
        setSelectStudent,
        selectAlbum,
        setSelectAlbum,
        selectedImages,
        setSelectedImages,
        selectedBase64,
        setSelectedBase64,
        reportOption,
        setReportOption,
        getAlbumList,
      }}
    >
      {props.children}
    </GalleryContext.Provider>
  );
}

export default GalleryState;
