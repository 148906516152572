import React, { useContext, useEffect, useState } from "react";
import { Fetchdata } from "../../hooks/getData";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { convertToNep } from "../../hooks/dateConvertor";
import AccountContext from "./AccountContext";
import { toast } from "react-toastify";

function AccountState(props) {
  const { appURL, newMonth, todayDate } = useContext(SidebarContext);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);
  const [summarySearch, setSummarySearch] = useState(null);
  const [todaySearch, setTodaySearch] = useState(null);
  const [allSearch, setAllSearch] = useState(null);
  const [pdfSearch, setPdfSearch] = useState(null);

  const [classList, setClassList] = useState([]);
  const [sectionList, setSectionList] = useState([]);

  const [selectClass, setSelectClass] = useState("");
  const [selectSection, setSelectSection] = useState("");
  const [selectStudent, setSelectStudent] = useState("");

  const reportValue = {
    fromDate: newMonth,
    toDate: "",
  };

  const [reportDateValue, setReportDateValue] = useState(reportValue);

  const summaryValue = {
    fromDate: newMonth,
    toDate: "",
  };

  const [summaryDateValue, setSummaryDateValue] = useState(summaryValue);

  const todayValue = {
    specificDate: todayDate,
  };

  const [todayDateValue, setTodayDateValue] = useState(todayValue);

  const [classLists, setClassLists] = useState([]);
  const [sectionLists, setSectionLists] = useState([]);

  const [selectClasses, setSelectClasses] = useState("");
  const [selectSections, setSelectSections] = useState("");

  useEffect(() => {
    getClassList();
  }, []);

  const getClassList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      DFlag: "C",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setLoading(false);
        setClassList(postResult);
      } else {
        setClassList([]);
      }
    });
  };

  useEffect(() => {
    getSectionList();
  }, [selectClass]);

  const getSectionList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      ClassID: selectClass,
      DFlag: "S",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";

        setSectionList(postResult);
      } else {
        setSectionList([]);
      }
    });
  };

  //API to hit Student list
  const [studentList, setStudentList] = useState([]);

  useEffect(() => {
    stdList();
  }, [selectClass, selectSection]);

  const stdList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      Flag: "S",
      ClassID: selectClass,
      SectionID: [
        {
          SectionID: selectSection,
        },
      ],
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/student`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.StudentLst ? result.StudentLst : "";
        setStudentList(postResult);
      } else {
        setStudentList([]);
      }
    });
  };

  // API for account report list
  const [accountReportList, setAccountReportList] = useState([]);

  useEffect(() => {
    getreportList();
  }, [selectStudent, reportDateValue]);

  const getreportList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      Flag: "R",
      MemID: selectStudent,
      FromDate: reportDateValue.fromDate,
      ToDate: reportDateValue.toDate,
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/account`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.AccLst ? result.AccLst : "";
        setLoading(false);
        setAccountReportList(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        setAccountReportList([]);
        setOriginalList([]);
      }
    });
  };

  // API for account summary list

  useEffect(() => {
    getClassLists();
  }, []);

  const getClassLists = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      DFlag: "C",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setLoading(false);
        setClassLists(postResult);
      } else {
        setClassLists([]);
      }
    });
  };

  useEffect(() => {
    getSectionLists();
  }, [selectClasses]);

  const getSectionLists = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      ClassID: selectClasses,
      DFlag: "S",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";

        setSectionLists(postResult);
      } else {
        setSectionLists([]);
      }
    });
  };

  const [accountSummaryList, setAccountSummaryList] = useState([]);

  useEffect(() => {
    getsummaryList();
  }, [selectClasses, selectSections, summaryDateValue]);

  const getsummaryList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      Flag: "S",
      ClassID: selectClasses,
      SectionID: selectSections,
      FromDate: summaryDateValue.fromDate,
      ToDate: summaryDateValue.toDate,
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/account`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.AccLst ? result.AccLst : "";
        setLoading(false);
        setAccountSummaryList(postResult);
        setSummarySearch(postResult);
      } else {
        setLoading(false);
        setAccountSummaryList([]);
        setSummarySearch([]);
      }
    });
  };

  // Api for total debit and credit without daTE FILTER
  const [totalList, setTotalList] = useState([]);

  useEffect(() => {
    getTotalList();
  }, [selectStudent]);

  const getTotalList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: selectStudent,
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/account`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.AccLst ? result.AccLst : "";
        setLoading(false);

        setTotalList(postResult);
      } else {
        setLoading(false);
        setTotalList([]);
      }
    });
  };

  // Api for today date value
  const [todayList, setTodayList] = useState([]);

  useEffect(() => {
    getTodayList();
  }, [todayDateValue]);

  const getTodayList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      Flag: "RD",
      FromDate: todayDateValue.specificDate,
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/account`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.AccLst ? result.AccLst : "";
        setLoading(false);
        setTodayList(postResult);
        setTodaySearch(postResult);
      } else {
        setTodayList([]);
        setTodaySearch([]);
      }
    });
  };

  // Api for all value
  const [accountAllList, setAccountAllList] = useState([]);

  useEffect(() => {
    getAllList();
  }, [selectClasses, selectSections]);

  const getAllList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      Flag: "S",
      ClassID: selectClasses,
      SectionID: selectSections,
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/account`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.AccLst ? result.AccLst : "";
        setLoading(false);
        setAccountAllList(postResult);
        setAllSearch(postResult);
      } else {
        setAccountAllList([]);
        setAllSearch([]);
      }
    });
  };

  // Api for total pdf value
  const [accountPdfList, setAccountPdfList] = useState([]);
  const [selectYear, setSelectYear] = useState("");
  const [selectMonth, setSelectMonth] = useState("");
  const [isAllow, setIsAllow] = useState("-1");
  useEffect(() => {
    getPdfList();
  }, [selectClasses, selectSections, selectYear, selectMonth]);

  const getPdfList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      Flag: "S",
      AcademicYear: selectYear,
      MonthID: selectMonth,
      ClassID: selectClasses,
      SectionID: selectSections,
      IsAllow: "-1",
      AuthCode: "r1d3r",
      Type: "POST",
      Academicyear: userinfo.AcademicYear.toString(),
      FetchURL: `${appURL}api/admin/attach-account`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.ReportCardLst ? result.ReportCardLst : "";
        setLoading(false);
        setAccountPdfList(postResult);
        setPdfSearch(postResult);
      } else {
        setAccountPdfList([]);
        setPdfSearch([]);
      }
    });
  };

  //Activate popup starts from here
  //Everything to pop up and approve the  list
  const [activatePopup, setActivatePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });
  // To approve the credit List

  const [Acdata, setACData] = useState();

  const handleActivate = async (data) => {
    setACData(data.AccountID);

    setActivatePopup({
      show: true,
      data,
    });
  };

  // This will perform the approve and hide the Confirmation Box

  const handleActivateTrue = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "AI",
      AccountID: Acdata.toString(),
      MonthID: selectMonth,
      ClassID: selectClasses,
      SectionID: selectSections,
      IsAllow: "Y",
      AuthCode: "r1d3r",
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/attach-account`, requestOptions).then(
      (result) => {
        result.json().then((resp) => {
          if (resp.StatusCode === 200) {
            getPdfList();
            toast(resp.Message, {
              style: {
                color: "green",
                fontSize: "13px",
              },
            });
          } else {
            toast(resp.Message, {
              style: {
                color: "red",
                fontSize: "13px",
              },
            });
          }
        });
      }
    );
    setActivatePopup({
      show: false,
      data: null,
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleActivateFalse = () => {
    setActivatePopup({
      show: false,
      data: null,
    });
  };

  //Everything to pop up and activate  ends

  //Activate popup starts from here
  //Everything to pop up and approve the  list
  const [inActivatePopup, setInActivatePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });
  // To approve the credit List

  const [inactivedata, setInactiveData] = useState();

  const handleInActivate = async (data) => {
    setInactiveData(data.AccountID);

    setInActivatePopup({
      show: true,
      data,
    });
  };

  // This will perform the approve and hide the Confirmation Box

  const handleInActivateTrue = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "AI",
      AccountID: inactivedata.toString(),
      MonthID: selectMonth,
      ClassID: selectClasses,
      SectionID: selectSections,
      IsAllow: "N",
      AuthCode: "r1d3r",
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/attach-account`, requestOptions).then(
      (result) => {
        result.json().then((resp) => {
          if (resp.StatusCode === 200) {
            getPdfList();
            toast(resp.Message, {
              style: {
                color: "green",
                fontSize: "13px",
              },
            });
          } else {
            toast(resp.Message, {
              style: {
                color: "red",
                fontSize: "13px",
              },
            });
          }
        });
      }
    );
    setInActivatePopup({
      show: false,
      data: null,
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleInActivateFalse = () => {
    setInActivatePopup({
      show: false,
      data: null,
    });
  };

  return (
    <AccountContext.Provider
      value={{
        loading,
        originalList,
        accountReportList,
        setAccountReportList,
        classList,
        sectionList,
        selectClass,
        setSelectClass,
        selectSection,
        setSelectSection,
        studentList,
        selectStudent,
        setSelectStudent,
        reportDateValue,
        setReportDateValue,
        summaryDateValue,
        setSummaryDateValue,
        accountSummaryList,
        setAccountSummaryList,
        summarySearch,

        classLists,
        setClassLists,
        sectionLists,
        setSectionLists,
        selectClasses,
        setSelectClasses,
        selectSections,
        setSelectSections,
        totalList,

        todayDateValue,
        setTodayDateValue,
        todaySearch,
        todayList,
        setTodayList,

        accountAllList,
        setAccountAllList,
        allSearch,

        accountPdfList,
        setAccountPdfList,
        pdfSearch,
        setPdfSearch,
        selectYear,
        setSelectYear,
        selectMonth,
        setSelectMonth,
        isAllow,
        setIsAllow,

        handleActivate,
        handleInActivate,
        activatePopup,
        inActivatePopup,
        handleActivateTrue,
        handleActivateFalse,
        handleInActivateTrue,
        handleInActivateFalse,
      }}
    >
      {props.children}
    </AccountContext.Provider>
  );
}

export default AccountState;
