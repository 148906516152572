import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import GalleryContext from "../contextfolder/GalleryContext/GalleryContext";
import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";
import GalleryForm from "./GalleryForm";
import DeletePop from "./DeletePop";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { showImgPreview } from "../hooks/ImagePreview";
import CloseIcon from "../../images/CloseIcon.svg";
import AcademicHeader from "../AcademicHeader";

export default function AlbumList() {
  const { todayDate, customStyles, AcademicYear } = useContext(SidebarContext);
  const {
    loading,
    originalList,
    popups,
    togglePopup,
    setIsSubmit,
    setPhotoFormValue,
    setPhotoFormError,
    photoValue,
    setIsUploaded,
    setImage,
    deletePopup,
    handleDeleteTrue,
    handleDeleteFalse,
    handleDelete,
    classList,
    sectionList,
    studentList,
    albumList,
    selectClass,
    setSelectClass,
    selectStudent,
    setSelectStudent,
    setCloseChecked,
    setSelectSection,
    selectSection,
    selectAlbum,
    setSelectAlbum,
    photoList,
    setPhotoList,
    reportOption,
    setReportOption,
    setSelectedImages,
  } = useContext(GalleryContext);

  const [imgPrv, setImgPrv] = useState(false);
  const [imagePre, setImagePre] = useState("");

  const handleReport = (e) => {
    const target = e.target;
    const value = target.value;
    setReportOption(value);
    setSelectClass("");
    setSelectSection("");
    setSelectStudent("");
    setSelectAlbum("");
  };

  const handleClass = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectClass(value);
    setSelectSection("");
    setSelectStudent("");
    setSelectAlbum("");
  };

  const handleSection = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectSection(value);
    setSelectStudent("");
    setSelectAlbum("");
  };
  const handleStudent = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectStudent(value);
    setSelectAlbum("");
  };

  const handleAlbum = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectAlbum(value);
  };

  // const dropDownValue = sectionList.map((item) => ({
  //     "value": item.SectionID,
  //     "label": item.SectionName
  // }))

  const searchInput = useRef("");

  const columns = [
    {
      name: "S.N.",
      width: "59px",
      center: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "Photo Name",
      center: true,
      selector: (row) => row.PhotoName,
    },
    {
      name: "Action",
      // grow: 0,
      center: true,
      width: "100px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <Tippy content="Delete">
                <i
                  className="fa fa-trash-o rejectspan--action"
                  onClick={() => handleDelete(row.PhotoID)}
                ></i>
              </Tippy>
            </div>
          </>
        );
      },
    },
  ];

  const closePopup = (e) => {
    e.preventDefault();
    togglePopup();
    setPhotoFormValue(photoValue);
    setPhotoFormError({});
    setSelectedImages([]);
    setIsSubmit(false);
    setCloseChecked(false);
    setIsUploaded(false);
    setImage("");
  };

  const addnew = (e) => {
    e.preventDefault();
    setIsSubmit(false);
    togglePopup(true);
    setPhotoFormValue(photoValue);
    setCloseChecked(false);
  };

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["PhotoName"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setPhotoList(srchResult);
      } else {
        setPhotoList({});
      }
    } else {
      setPhotoList(originalList);
    }
  };

  if (photoList.length > 0) {
    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
        />
        <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
          <div className="row">
            <div className="page-header">
              <div className="text-start  page-title">Photo</div>
              <div className="page-date">
                <div className="sec-content">
                  <AcademicHeader
                    todayDate={todayDate}
                    AcademicYear={AcademicYear}
                  />
                </div>
              </div>
            </div>
          </div>
          <hr />
          <>
            <div className="sec-dataTable content-section main-content">
              <div className="upper-dataTbl">
                <div className="btn-addlnote">
                  <button
                    type="button"
                    class="btn btn-sm"
                    style={{
                      background: "var(--MainColor)",
                      color: "white",
                    }}
                    onClick={addnew}
                  >
                    Add New
                  </button>
                </div>
              </div>
              <hr className="new-hr-new" />

              {loading ? (
                <Spinner />
              ) : (
                <>
                  <div
                    className="row"
                    style={{ paddingLeft: "15px", paddingRight: "15px" }}
                  >
                    <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                      <label className="list-label">Select Option</label>
                      <select
                        style={{ fontSize: "11px" }}
                        name="status"
                        className="form-control form-control-sm"
                        aria-label="Default select example "
                        value={reportOption}
                        onChange={handleReport}
                      >
                        <option
                          selected
                          disabled
                          value=""
                          style={{ fontSize: "11px" }}
                        >
                          Select Option
                        </option>
                        <option value="-1">All</option>
                        <option value="C">Class</option>
                        <option value="S">Section</option>
                        <option value="I">Individual</option>
                      </select>
                      <i class="fas fa-angle-down  notificationpositon"></i>
                    </div>
                    {reportOption === "C" ||
                    reportOption === "S" ||
                    reportOption === "I" ? (
                      <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                        <label className="list-label">Class</label>
                        <select
                          style={{ fontSize: "11px" }}
                          name="snotifiaction"
                          value={selectClass}
                          onChange={handleClass}
                          className="form-control form-control-sm"
                          aria-label="Default select example "
                        >
                          <option
                            value=""
                            disabled
                            selected
                            style={{ fontSize: "11px" }}
                          >
                            Select Class
                          </option>
                          {classList.map((item) => (
                            <option key={item.ClassID} value={item.ClassID}>
                              {item.ClassName}
                            </option>
                          ))}
                        </select>
                        <i class="fas fa-angle-down  notificationpositon"></i>
                      </div>
                    ) : (
                      <> </>
                    )}
                    {reportOption === "S" || reportOption === "I" ? (
                      <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                        <label className="list-label">Section</label>
                        <select
                          style={{ fontSize: "11px" }}
                          name="snotifiaction"
                          value={selectSection}
                          onChange={handleSection}
                          className="form-control form-control-sm"
                          aria-label="Default select example "
                        >
                          <option
                            value=""
                            disabled
                            selected
                            style={{ fontSize: "11px" }}
                          >
                            Select Section
                          </option>

                          {sectionList.map((item) => (
                            <option key={item.SectionID} value={item.SectionID}>
                              {item.SectionName}
                            </option>
                          ))}
                        </select>
                        <i class="fas fa-angle-down  notificationpositon"></i>
                      </div>
                    ) : (
                      <> </>
                    )}
                    {reportOption === "I" ? (
                      <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                        <label className="list-label">Student</label>
                        <select
                          style={{ fontSize: "11px" }}
                          name="snotifiaction"
                          value={selectStudent}
                          onChange={handleStudent}
                          className="form-control form-control-sm"
                          aria-label="Default select example"
                        >
                          <option
                            value=""
                            disabled
                            selected
                            style={{ fontSize: "11px" }}
                          >
                            Select Student
                          </option>
                          <option value="-1">All</option>
                          {studentList.map((item) => (
                            <option key={item.StudentID} value={item.StudentID}>
                              {item.StdFullName}
                            </option>
                          ))}
                        </select>
                        <i class="fas fa-angle-down  notificationpositon"></i>
                      </div>
                    ) : (
                      <> </>
                    )}

                    <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                      <label className="list-label">Album</label>
                      <select
                        style={{ fontSize: "11px" }}
                        name="snotifiaction"
                        value={selectAlbum}
                        onChange={handleAlbum}
                        className="form-control form-control-sm"
                        aria-label="Default select example"
                      >
                        <option
                          value=""
                          disabled
                          selected
                          style={{ fontSize: "11px" }}
                        >
                          Select Album
                        </option>

                        {albumList.map((item) => (
                          <option key={item.AlbumID} value={item.AlbumID}>
                            {item.AlbumName}
                          </option>
                        ))}
                      </select>
                      <i class="fas fa-angle-down  notificationpositon"></i>
                    </div>

                    <div
                      className={
                        reportOption === "C"
                          ? "col-lg-2 offset-lg-4 col-md-2 offset-md-4 col-sm-2 align-self-end Search"
                          : reportOption === "S"
                          ? "col-lg-2 offset-lg-2 offset-md-2 offset-sm-2 col-md-2 col-sm-2 align-self-end Search"
                          : reportOption === "I"
                          ? "col-lg-2 offset-lg-0 offset-md-0 offset-sm-2 col-md-2 col-sm-2"
                          : "col-lg-2 offset-lg-6 col-md-2 offset-md-6 col-sm-2 align-self-end Search"
                      }
                      style={{
                        marginTop: "25px",
                        padding: "0px",
                        marginBottom: "10px",
                      }}
                    >
                      <div className="d-flex">
                        <input
                          placeholder="Search"
                          ref={searchInput}
                          type="text"
                          className="form-control form-control-sm searchField"
                          onChange={searchHandler}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="basic-dyno-gallery">
                    {photoList.map((item, i) => {
                      return (
                        <div className="profilecontentLogoGallery tl" key={i}>
                          <div className="profilemgmtImgGallery tl">
                            <img
                              src={item.PhotoUrl}
                              alt="dp"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setImagePre(item.PhotoUrl);
                                setImgPrv(true);
                              }}
                            />
                            <i
                              className="bi bi-trash close-icon1"
                              alt="cross"
                              onClick={() => handleDelete(item.PhotoID)}
                            ></i>
                            {/* <button className="close-icon1" src={CloseIcon} alt="cross" onClick={() => handleDelete(item.PhotoID)} > del </button> */}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          </>
        </div>

        {popups && (
          <div className="modals">
            <div className="overlay"></div>

            <div className="notification-model-content">
              <div className="popUpHeader ">
                <div className="popUpHeaderText ">Create Photo</div>
                <div style={{ cursor: "pointer" }}>
                  <i
                    className="bi bi-x "
                    onClick={closePopup}
                    style={{ fontSize: "25px" }}
                  ></i>
                </div>
              </div>
              <div className="inside-modal">
                <GalleryForm closePopup={closePopup} />
              </div>
            </div>
          </div>
        )}

        {deletePopup.show && (
          <DeletePop
            handleDeleteTrue={handleDeleteTrue}
            handleDeleteFalse={handleDeleteFalse}
          />
        )}

        {imgPrv &&
          showImgPreview({
            img: imagePre,
            setTrigger: setImgPrv,
          })}
      </>
    );
  } else {
    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
        />
        <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
          <div className="row">
            <div className="page-header">
              <div className="text-start  page-title">Photo</div>
              <div className="page-date">
                <div className="sec-content">
                  <AcademicHeader
                    todayDate={todayDate}
                    AcademicYear={AcademicYear}
                  />
                </div>
              </div>
            </div>
          </div>
          <hr />
          <>
            <div className="sec-dataTable content-section main-content">
              <div className="upper-dataTbl">
                <div className="btn-addlnote">
                  <button
                    type="button"
                    class="btn btn-sm"
                    style={{
                      background: "var(--MainColor)",
                      color: "white",
                    }}
                    onClick={addnew}
                  >
                    Add New
                  </button>
                </div>
              </div>
              <hr className="new-hr-new" />
              {loading ? (
                <Spinner />
              ) : (
                <>
                  <div
                    className="row"
                    style={{ paddingLeft: "15px", paddingRight: "15px" }}
                  >
                    <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                      <label className="list-label">Select Option</label>
                      <select
                        style={{ fontSize: "11px" }}
                        name="status"
                        className="form-control form-control-sm"
                        aria-label="Default select example "
                        value={reportOption}
                        onChange={handleReport}
                      >
                        <option
                          selected
                          disabled
                          value=""
                          style={{ fontSize: "11px" }}
                        >
                          Select Option
                        </option>
                        <option value="-1">All</option>
                        <option value="C">Class</option>
                        <option value="S">Section</option>
                        <option value="I">Individual</option>
                      </select>
                      <i class="fas fa-angle-down  notificationpositon"></i>
                    </div>
                    {reportOption === "C" ||
                    reportOption === "S" ||
                    reportOption === "I" ? (
                      <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                        <label className="list-label">Class</label>
                        <select
                          style={{ fontSize: "11px" }}
                          name="snotifiaction"
                          value={selectClass}
                          onChange={handleClass}
                          className="form-control form-control-sm"
                          aria-label="Default select example "
                        >
                          <option
                            value=""
                            disabled
                            selected
                            style={{ fontSize: "11px" }}
                          >
                            Select Class
                          </option>
                          {classList.map((item) => (
                            <option key={item.ClassID} value={item.ClassID}>
                              {item.ClassName}
                            </option>
                          ))}
                        </select>
                        <i class="fas fa-angle-down  notificationpositon"></i>
                      </div>
                    ) : (
                      <> </>
                    )}
                    {reportOption === "S" || reportOption === "I" ? (
                      <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                        <label className="list-label">Section</label>
                        <select
                          style={{ fontSize: "11px" }}
                          name="snotifiaction"
                          value={selectSection}
                          onChange={handleSection}
                          className="form-control form-control-sm"
                          aria-label="Default select example "
                        >
                          <option
                            value=""
                            disabled
                            selected
                            style={{ fontSize: "11px" }}
                          >
                            Select Section
                          </option>

                          {sectionList.map((item) => (
                            <option key={item.SectionID} value={item.SectionID}>
                              {item.SectionName}
                            </option>
                          ))}
                        </select>
                        <i class="fas fa-angle-down  notificationpositon"></i>
                      </div>
                    ) : (
                      <> </>
                    )}
                    {reportOption === "I" ? (
                      <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                        <label className="list-label">Student</label>
                        <select
                          style={{ fontSize: "11px" }}
                          name="snotifiaction"
                          value={selectStudent}
                          onChange={handleStudent}
                          className="form-control form-control-sm"
                          aria-label="Default select example"
                        >
                          <option
                            value=""
                            disabled
                            selected
                            style={{ fontSize: "11px" }}
                          >
                            Select Student
                          </option>
                          <option value="-1">All</option>
                          {studentList.map((item) => (
                            <option key={item.StudentID} value={item.StudentID}>
                              {item.StdFullName}
                            </option>
                          ))}
                        </select>
                        <i class="fas fa-angle-down  notificationpositon"></i>
                      </div>
                    ) : (
                      <> </>
                    )}

                    <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                      <label className="list-label">Album</label>
                      <select
                        style={{ fontSize: "11px" }}
                        name="snotifiaction"
                        value={selectAlbum}
                        onChange={handleAlbum}
                        className="form-control form-control-sm"
                        aria-label="Default select example"
                      >
                        <option
                          value=""
                          disabled
                          selected
                          style={{ fontSize: "11px" }}
                        >
                          Select Album
                        </option>

                        {albumList.map((item) => (
                          <option key={item.AlbumID} value={item.AlbumID}>
                            {item.AlbumName}
                          </option>
                        ))}
                      </select>
                      <i class="fas fa-angle-down  notificationpositon"></i>
                    </div>

                    <div
                      className={
                        reportOption === "C"
                          ? "col-lg-2 offset-lg-4 col-md-2 offset-md-4 col-sm-2 align-self-end Search"
                          : reportOption === "S"
                          ? "col-lg-2 offset-lg-2 offset-md-2 offset-sm-2 col-md-2 col-sm-2 align-self-end Search"
                          : reportOption === "I"
                          ? "col-lg-2 offset-lg-0 offset-md-0 offset-sm-2 col-md-2 col-sm-2"
                          : "col-lg-2 offset-lg-6 col-md-2 offset-md-6 col-sm-2 align-self-end Search"
                      }
                      style={{
                        marginTop: "25px",
                        padding: "0px",
                        marginBottom: "10px",
                      }}
                    >
                      <div className="d-flex">
                        <input
                          placeholder="Search"
                          ref={searchInput}
                          type="text"
                          className="form-control form-control-sm searchField"
                          onChange={searchHandler}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="text-center mt-4">
                    <p>There are no records to display.</p>
                  </div>
                </>
              )}
            </div>
          </>
        </div>

        {popups && (
          <div className="modals">
            <div className="overlay"></div>

            <div className="notification-model-content">
              <div className="popUpHeader ">
                <div className="popUpHeaderText ">Create Photo</div>
                <div style={{ cursor: "pointer" }}>
                  <i
                    className="bi bi-x "
                    onClick={closePopup}
                    style={{ fontSize: "25px" }}
                  ></i>
                </div>
              </div>
              <div className="inside-modal">
                <GalleryForm closePopup={closePopup} />
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
