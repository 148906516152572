import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import SuggestionContext from "../contextfolder/SuggestionContext/SuggestionContext";
import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";
import DeletePop from "./DeletePop";
import SuggestionForm from "./SuggestionForm";
import ViewSuggestion from "./ViewSuggestion";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { GetNepaliDate } from "../hooks/dateConvertor";
import ApprovePop from "./ApprovePop";
import RejectPop from "./RejectPop";
import AcademicHeader from "../AcademicHeader";

export default function SuggestionList() {
  const { todayDate, customStyles, AcademicYear, hideAction } =
    useContext(SidebarContext);
  const {
    loading,
    originalList,
    modal,
    toggleModal,
    setIsSubmit,
    setSuggestionFormValue,
    setSuggestionFormError,
    suggestionValue,
    suggestionList,
    setSuggestionList,
    deletePopup,
    handleDeleteTrue,
    handleDeleteFalse,
    handleDelete,
    popup,
    togglePopup,
    setRemarksFormValue,
    setRemarksFormError,
    setRemarksIsSubmit,
    remarksvalue,
    selectSuggTopic,
    setSelectSuggTopic,
    suggestionTopicList,
    view,
    setCloseChecked,
    setPushNotice,

    approvePopup,
    handleApproveFalse,
    handleApproveTrue,
    handleApprove,
    approveNotify,
    setApproveNotify,
    rejectPopup,
    handleRejectTrue,
    handleRejectFalse,
    handleReject,
    rejectNotify,
    setRejectNotify,
  } = useContext(SuggestionContext);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const searchInput = useRef("");

  const columns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "Full Name",

      selector: (row) => row.FullName,
    },
    {
      name: "Suggestion topic",
      width: "170px",
      selector: (row) => row.Topic,
    },
    {
      name: "Title",
      width: "170px",
      selector: (row) => row.Title,
    },
    // {
    //     name: "Description",
    //     width: "170px",
    //     selector: (row) => row.Description,
    // },

    {
      name: "Status",
      width: "140px",
      selector: (row) => {
        return (
          <>
            {row.SuggestionStatusID === "P" ? (
              <>
                <>
                  <button
                    type="button"
                    class="badge badge-success"
                    onClick={() => handleApprove(row.SuggestionID)}
                    style={{ marginRight: "2px" }}
                  >
                    Approve
                  </button>
                </>

                <>
                  {/* <Tippy content="Reject">
                                        <i
                                            className="fa fa-thumbs-down rejectspan--action"
                                            onClick={() => handleReject(row.LeaveID)}
                                        >
                                        </i>
                                    </Tippy> */}
                  <button
                    type="button"
                    class="badge badge-danger"
                    onClick={() => handleReject(row.SuggestionID)}
                  >
                    Reject
                  </button>
                </>
              </>
            ) : row.SuggestionStatusID === "A" ? (
              "Approved"
            ) : row.SuggestionStatusID === "R" ? (
              "Rejected"
            ) : (
              ""
            )}
          </>
        );
      },
    },
    {
      name: "Created Date",
      // grow: 0,
      width: "130px",
      selector: (row) => GetNepaliDate(row.CreatedDate),
    },
    {
      name: "Action",
      // grow: 0,
      center: true,
      width: "100px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <Tippy content="View">
                <i
                  className="bi bi-eye  solvespan--action"
                  style={{ paddingTop: "3px" }}
                  onClick={() => view(row)}
                ></i>
              </Tippy>
              <Tippy content="Delete">
                <i
                  className="fa fa-trash-o rejectspan--action"
                  onClick={() => handleDelete(row.SuggestionID)}
                ></i>
              </Tippy>
            </div>
          </>
        );
      },
      omit: hideAction,
    },
  ];

  const closePopup = (e) => {
    e.preventDefault();
    toggleModal();
    setSuggestionFormValue(suggestionValue);
    setSuggestionFormError({});
    setIsSubmit(false);
    setCloseChecked(false);
  };

  const closeViewPopup = (e) => {
    e.preventDefault();
    togglePopup();
    setRemarksFormValue(remarksvalue);
    setRemarksFormError({});
    setRemarksIsSubmit(false);
    setCloseChecked(false);
    setPushNotice(false);
  };

  const addnew = (e) => {
    e.preventDefault();
    toggleModal();
    setIsSubmit(false);
    setSuggestionFormValue(suggestionValue);
  };

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["Topic"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setSuggestionList(srchResult);
      } else {
        setSuggestionList({});
      }
    } else {
      setSuggestionList(originalList);
    }
  };

  const handleSugg = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectSuggTopic(value);
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />

      <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
        <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">Suggestion</div>
            <div className="page-date">
              <div className="sec-content">
                <AcademicHeader
                  todayDate={todayDate}
                  AcademicYear={AcademicYear}
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <>
          <div className="sec-dataTable content-section main-content">
            {userinfo.UserType !== "T" && (
              <div className="upper-dataTbl">
                <div className="btn-addlnote">
                  <button
                    type="button"
                    class="btn btn-sm"
                    style={{
                      background: "var(--MainColor)",
                      color: "white",
                    }}
                    onClick={addnew}
                  >
                    Add Topic
                  </button>

                  {/* <button
                                    type="button"
                                    class="btn btn-sm"
                                    style={{
                                        background: "var(--MainColor)",
                                        color: "white",
                                    }}
                                    onClick={view}
                                >
                                    View
                                </button> */}
                </div>
              </div>
            )}
            <hr className="new-hr-new" />
            {loading ? (
              <Spinner />
            ) : (
              <DataTable
                columns={columns}
                data={suggestionList}
                customStyles={customStyles}
                pagination
                fixedHeader
                fixedHeaderScrollHeight="280px"
                highlightOnHover
                pointerOnHover
                responsive
                dense
                striped
                subHeader
                subHeaderAlign="left"
                subHeaderComponent={
                  <>
                    <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                      <label className="list-label">Suggestion Topic</label>
                      <select
                        style={{ fontSize: "11px", marginBottom: "3px" }}
                        name="snotifiaction"
                        value={selectSuggTopic}
                        onChange={handleSugg}
                        className="form-control form-control-sm"
                        aria-label="Default select example "
                      >
                        <option
                          value=""
                          disabled
                          selected
                          style={{ fontSize: "11px" }}
                        >
                          Select Suggestion Topic
                        </option>
                        <option value="-1">All</option>

                        {suggestionTopicList.map((item) => (
                          <option key={item.TopicID} value={item.TopicID}>
                            {item.Topic}
                          </option>
                        ))}
                      </select>
                      <i class="fas fa-angle-down  notificationpositon"></i>
                    </div>

                    <div
                      className="col-lg-2 offset-lg-8 offset-md-8 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
                      style={{
                        marginTop: "25px",
                        paddingRight: "1px",
                        marginBottom: "10px",
                      }}
                    >
                      <div className="d-flex">
                        <input
                          placeholder="Search"
                          ref={searchInput}
                          type="text"
                          className="form-control form-control-sm searchField"
                          onChange={searchHandler}
                        />
                      </div>
                    </div>
                  </>
                }
              />
            )}
          </div>
        </>
      </div>

      {approvePopup.show && (
        <ApprovePop
          handleApproveTrue={handleApproveTrue}
          handleApproveFalse={handleApproveFalse}
          approveNotify={approveNotify}
          setApproveNotify={setApproveNotify}
        />
      )}
      {rejectPopup.show && (
        <RejectPop
          handleRejectTrue={handleRejectTrue}
          handleRejectFalse={handleRejectFalse}
          rejectNotify={rejectNotify}
          setRejectNotify={setRejectNotify}
        />
      )}

      {modal && (
        <div className="modals">
          <div className="overlay"></div>

          <div className="notification-model-content">
            <div className="popUpHeader ">
              <div className="popUpHeaderText ">Suggestion Topic</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closePopup}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="inside-modal">
              <SuggestionForm closePopup={closePopup} />
            </div>
          </div>
        </div>
      )}

      {popup && (
        <div className="modals">
          <div className="overlay">
            <div className="notification-model-content">
              <div className="popUpHeader ">
                <div className="popUpHeaderText ">View Suggestion</div>
                <div style={{ cursor: "pointer" }}>
                  <i
                    className="bi bi-x "
                    onClick={closeViewPopup}
                    style={{ fontSize: "25px" }}
                  ></i>
                </div>
              </div>
              <div className="inside-modal">
                <ViewSuggestion closeViewPopup={closeViewPopup} />
              </div>
            </div>
          </div>
        </div>
      )}

      {deletePopup.show && (
        <DeletePop
          handleDeleteTrue={handleDeleteTrue}
          handleDeleteFalse={handleDeleteFalse}
        />
      )}
    </>
  );
}
