import React, { useContext, useEffect, useState } from "react";
import { Fetchdata } from "../../hooks/getData";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { toast } from "react-toastify";
import OrderContext from "./OrderContext";

function OrderState(props) {
  const { appURL } = useContext(SidebarContext);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);

  const [categoryList, setCategoryList] = useState([]);
  const [selectCategory, setSelectCategory] = useState("-1");
  const [selectMenu, setSelectMenu] = useState("-1");

  const [menuList, setMenuList] = useState([]);

  useEffect(() => {
    getCategoryList();
  }, []);

  const getCategoryList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "SM",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/menu`,
    };
    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setCategoryList(postResult);
      } else {
        setCategoryList([]);
      }
    });
  };

  useEffect(() => {
    getMenuList();
  }, [selectCategory, selectMenu]);

  const getMenuList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      CategoryID: selectCategory,
      MenuType: selectMenu,
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/menu`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setLoading(false);
        setMenuList(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        setMenuList([]);
        setOriginalList("");
      }
    });
  };

  /* This is for Student Order */

  const [ordLoading, setOrdLoading] = useState(true);
  const [ordOriginalList, setOrdOriginalList] = useState(null);

  const [ordCloseChecked, setOrdCloseChecked] = useState(false);

  const orderValue = {
    payAmount: "",
    userName: "",
  };

  const [orderFormValue, setOrderFormValue] = useState(orderValue);
  const [orderFormError, setOrderFormError] = useState({});
  const [ordIsSubmit, setOrdIsSubmit] = useState(false);

  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  const [activeTab, setActiveTab] = useState(1);

  const [catLoad, setCatLoad] = useState(true);
  const [catSearch, setCatSearch] = useState(null);

  const [choosePayment, setChoosePayment] = useState("-1");
  const [chooseOrder, setChooseOrder] = useState("-1");

  const [orderStatus, setOrderStaus] = useState(false);
  const [isPaid, setIsPaid] = useState(false);

  //API to hit Menu list
  const [menuListForOrder, setMenuListForOrder] = useState([]);

  useEffect(() => {
    getMenuListforOrder();
  }, [activeTab]);

  const getMenuListforOrder = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "S",
      IsActive: "-1",
      IsAvail: "-1",
      MenuType: "-1",
      CategoryID: activeTab,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/menu`,
      Type: "POST",
    };
    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setMenuListForOrder(postResult);
        setCatSearch(postResult);
        setCatLoad(false);
      } else {
        setCatSearch([]);
        setMenuListForOrder([]);
      }
    });
  };

  // This is for add to cart items

  const [cartItems, setCartItems] = useState([]);

  const onAdd = (product) => {
    const exist = cartItems.find((x) => x.MenuID === product.MenuID);
    if (exist) {
      setCartItems(
        cartItems.map((x) =>
          x.MenuID === product.MenuID ? { ...exist, Qty: exist.Qty + 1 } : x
        )
      );
    } else {
      setCartItems([...cartItems, { ...product, Qty: 1 }]);
    }
  };

  const onRemove = (product) => {
    const exist = cartItems.find((x) => x.MenuID === product.MenuID);
    if (exist.Qty === 1) {
      setCartItems(cartItems.filter((x) => x.MenuID !== product.MenuID));
    } else {
      setCartItems(
        cartItems.map((x) =>
          x.MenuID === product.MenuID ? { ...exist, Qty: exist.Qty - 1 } : x
        )
      );
    }
  };

  const extractedData = cartItems.map((item) => {
    return {
      MenuID: item.MenuID.toString(),
      Price: item.Price,
      Qty: item.Qty.toString(),
    };
  });

  // Create API for  order

  const categoryData = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "i",
      MemID: orderFormValue.userName,
      OrderStatus: orderStatus === true ? "D" : "P",
      IsPaid: isPaid === true ? "Y" : "N",
      PayAmt: isPaid === true ? orderFormValue.payAmount : "",
      PayVia: "C",
      PayType: "I",
      Values: extractedData,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/menu-order`,
      Type: "POST",
    };
    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getItemOrderList();
        getPaymentList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
          theme: "light",
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        });
      }
    });
  };

  // to list the order items

  const [itemOrderList, setItemOrderList] = useState([]);

  useEffect(() => {
    getItemOrderList();
  }, [choosePayment, chooseOrder]);

  const getItemOrderList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      Flag: "S",
      MemID: "-1",
      OrderStatus: chooseOrder,
      IsPaid: choosePayment,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/menu-order`,
      Type: "POST",
    };
    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setItemOrderList(postResult);
        setOrdOriginalList(postResult);
        setOrdLoading(false);
      } else {
        setOrdOriginalList([]);
        setItemOrderList([]);
      }
    });
  };

  //API to delete Order

  // //Everything to pop up and delete the notification list
  const [deletePopup, setDeletePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const [delId, setDelId] = useState("");

  const handleDelete = async (datas) => {
    setDeletePopup({
      show: true,
    });
    setDelId(datas);
  };
  const trimmedOrderID = delId.substring(1);
  // This will perform the deletion and hide the Confirmation Box

  const handleDeleteTrue = async () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "R",
      OrderID: trimmedOrderID,
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/menu-order`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          getItemOrderList();
          toast(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
        } else {
          toast(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
    });
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleDeleteFalse = () => {
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // Ends Here

  // For order info

  const [popup, setPopup] = useState(false);

  const togglePopup = () => {
    setPopup(!popup);
  };

  const [infoID, setInfoID] = useState("");

  const handleInfo = (data) => {
    setInfoID(data);
    togglePopup();
  };

  const trimmedID = infoID.substring(1);

  const [orderInfo, setOrderInfo] = useState([]);

  useEffect(() => {
    getOrderInfo();
  }, [trimmedID]);

  const getOrderInfo = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "SI",
      OrderID: trimmedID,
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/menu-order`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values[0] ? result.Values[0] : "";
        setLoading(false);
        setOrderInfo(postResult);
      } else {
        setLoading(false);
        setOrderInfo([]);
      }
    });
  };

  // For order info ends

  // Remarks value for payAmount and delivered

  const payValue = {
    payAmount: "",
    orderStatus: "P",
  };

  const [payFormValue, setPayFormValue] = useState(payValue);
  const [payFormError, setPayFormError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  //Everything to pop up and payment

  const [deliveredWithPay, setDeliveredWithPay] = useState(false);

  const [payPopup, setPayPopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const [Acdata, setACData] = useState("");
  const [tAmount, setTamount] = useState();

  const handlePay = async (data) => {
    setACData(data[0]);
    setTamount(data[1]);
    setPayPopup({
      show: true,
      data,
    });
  };

  const trimmedIDForPayment = Acdata.substring(1);

  // This will perform the deletion and hide the Confirmation Box

  const handlePayTrue = async () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "PO",
      OrderID: trimmedIDForPayment,
      OrderStatus: deliveredWithPay === true ? "D" : "P",
      IsPaid: "Y",
      PayAmt: payFormValue.payAmount,
      PayVia: "C",
      PayType: "I",
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/menu-order`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          getItemOrderList();
          toast(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });

          setPayPopup({
            show: false,
          });
          setDeliveredWithPay(false);

          setIsSubmit(false);
        } else {
          toast(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handlePayFalse = () => {
    setPayFormError({});
    setPayPopup({
      show: false,
      data: null,
    });
    setIsSubmit(false);
    setDeliveredWithPay(false);
    setPayFormValue(payValue);
  };

  // Ends Here

  //Everything to pop up and update order Status

  const [orderIsSubmit, setOrderIsSubmit] = useState(false);

  const [orderPopup, setOrderPopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const [rdata, setRData] = useState("");

  const handleOrder = async (data) => {
    setRData(data);

    setOrderPopup({
      show: true,
      data,
    });
  };

  const trimmedIDForOrder = rdata.substring(1);

  // This will perform the deletion and hide the Confirmation Box

  const handleOrderTrue = async () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "UO",
      OrderID: trimmedIDForOrder,
      OrderStatus: payFormValue.orderStatus,
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/menu-order`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          getItemOrderList();
          toast(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
          setOrderPopup({
            show: false,
          });
          setOrderIsSubmit(false);
        } else {
          toast(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleOrderFalse = () => {
    setPayFormValue(payValue);
    setOrderPopup({
      show: false,
      data: null,
    });
    setOrderIsSubmit(false);
  };

  // Ends Here

  /* This is for  Order ends*/

  /* Let's start for the Payment after order */

  const [payLoad, setPayLoad] = useState(true);
  const [paySearch, setPaySearch] = useState(null);

  const [choosePaymentStatus, setChoosePaymentStatus] = useState("-1");

  const [payCloseChecked, setPayCloseChecked] = useState(false);

  const paymentValue = {
    type: "A",
    userName: "",
    amount: "",
    orderID: "",
  };

  const [paymentFormValue, setPaymentFormValue] = useState(paymentValue);
  const [paymentFormError, setPaymentFormError] = useState({});
  const [payIsSubmit, setPayIsSubmit] = useState(false);

  const [orderIdSubmit, setOrderIdSubmit] = useState(false);

  const [payModal, setPayModal] = useState(false);

  const togglePayModal = () => {
    setPayModal(!payModal);
  };

  /* API to search for Order using Order ID using orderinfo API */
  const [newOrder, setNewOrder] = useState([]);

  const infoData = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "SI",
      OrderID: paymentFormValue.orderID,
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/menu-order`,
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
          theme: "light",
        });
        const postResult = result.Values[0] ? result.Values[0] : "";
        setNewOrder(postResult);
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        });
        setNewOrder([]);
      }
    });
  };

  // Create API for  payment

  const paymentData = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "PO",
      OrderID: paymentFormValue.orderID,
      OrderStatus: "D",
      IsPaid: "Y",
      PayAmt: paymentFormValue.amount,
      PayVia: "C",
      PayType: "I",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/menu-order`,
      Type: "POST",
    };
    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getPaymentList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
          theme: "light",
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        });
      }
    });
  };

  //To get payment list

  const [paymentList, setPaymentList] = useState([]);

  useEffect(() => {
    getPaymentList();
  }, [choosePaymentStatus]);

  const getPaymentList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      Flag: "S",
      MemID: "-1",
      PayStatus: choosePaymentStatus,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/menu-payment`,
      Type: "POST",
    };
    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setPaymentList(postResult);
        setPaySearch(postResult);
        setPayLoad(false);
      } else {
        setPaySearch([]);
        setPaymentList([]);
      }
    });
  };

  // Remarks value for payAmount and delivered

  const payValues = {
    payAmount: "",
  };

  const [payFormValues, setPayFormValues] = useState(payValues);
  const [payFormErrors, setPayFormErrors] = useState({});
  const [isSubmits, setIsSubmits] = useState(false);

  //Everything to pop up and payment

  const [payPopups, setPayPopups] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const [Acdatas, setACDatas] = useState("");
  const [tAmounts, setTamounts] = useState();

  const handlePays = async (data) => {
    setACDatas(data[0]);
    setTamounts(data[1]);
    setPayPopups({
      show: true,
      data,
    });
  };

  // This will perform the deletion and hide the Confirmation Box

  const handlePayTrues = async () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "PO",
      OrderID: Acdatas,
      OrderStatus: "D",
      IsPaid: "Y",
      PayAmt: payFormValues.payAmount,
      PayVia: "C",
      PayType: "I",
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/menu-order`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          getPaymentList();
          toast(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });

          setPayPopups({
            show: false,
          });
          setIsSubmits(false);
        } else {
          toast(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handlePayFalses = () => {
    setPayFormErrors({});
    setPayPopups({
      show: false,
      data: null,
    });
    setIsSubmits(false);
    setPayFormValues(payValues);
  };

  // Ends Here

  // For payment info

  const [paymentPopup, setPaymentPopup] = useState(false);

  const togglePaymentPopup = () => {
    setPaymentPopup(!paymentPopup);
  };

  const [infoPayment, setInfoPayment] = useState("");

  const handleInfoPayment = (data) => {
    setInfoPayment(data);
    togglePaymentPopup();
  };

  // const trimmedID = infoID.substring(1);

  const [orderInfoPayment, setOrderInfoPayment] = useState([]);

  useEffect(() => {
    getOrderInfoPayment();
  }, [infoPayment]);

  const getOrderInfoPayment = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "SI",
      OrderID: infoPayment,
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/menu-order`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values[0] ? result.Values[0] : "";
        setLoading(false);
        setOrderInfoPayment(postResult);
      } else {
        setLoading(false);
        setOrderInfoPayment([]);
      }
    });
  };

  // For order info ends

  return (
    <OrderContext.Provider
      value={{
        loading,
        originalList,
        categoryList,
        setCategoryList,
        selectCategory,
        setSelectCategory,
        menuList,
        setMenuList,
        selectMenu,
        setSelectMenu,

        ordLoading,
        setOrdLoading,
        ordOriginalList,
        setOrdOriginalList,
        ordCloseChecked,
        setOrdCloseChecked,
        orderValue,
        orderFormValue,
        setOrderFormValue,
        orderFormError,
        setOrderFormError,
        ordIsSubmit,
        setOrdIsSubmit,
        toggleModal,
        modal,
        menuListForOrder,
        activeTab,
        setActiveTab,
        catLoad,
        catSearch,
        setCatSearch,

        onAdd,
        onRemove,
        cartItems,
        setCartItems,
        categoryData,
        choosePayment,
        setChoosePayment,
        chooseOrder,
        setChooseOrder,
        itemOrderList,
        setItemOrderList,

        deletePopup,
        handleDeleteTrue,
        handleDeleteFalse,
        handleDelete,

        togglePopup,
        popup,
        handleInfo,
        orderInfo,
        setInfoID,
        orderStatus,
        setOrderStaus,
        isPaid,
        setIsPaid,

        payFormValue,
        setPayFormValue,
        payFormError,
        setPayFormError,
        isSubmit,
        setIsSubmit,
        payValue,
        payPopup,
        setPayPopup,
        handlePayFalse,
        handlePayTrue,
        handlePay,
        deliveredWithPay,
        setDeliveredWithPay,
        tAmount,

        handleOrderFalse,
        handleOrderTrue,
        handleOrder,
        orderPopup,
        orderIsSubmit,
        setOrderIsSubmit,

        payLoad,
        paySearch,
        setPaySearch,
        paymentList,
        setPaymentList,
        choosePaymentStatus,
        setChoosePaymentStatus,
        payCloseChecked,
        setPayCloseChecked,
        paymentFormValue,
        setPaymentFormValue,
        paymentValue,
        paymentFormError,
        setPaymentFormError,
        payIsSubmit,
        setPayIsSubmit,
        togglePayModal,
        payModal,
        setPayModal,
        orderIdSubmit,
        setOrderIdSubmit,
        infoData,
        newOrder,
        setNewOrder,

        payFormValues,
        setPayFormValues,
        payFormErrors,
        setPayFormErrors,
        isSubmits,
        setIsSubmits,
        payValues,
        payPopups,
        setPayPopups,
        handlePayFalses,
        handlePayTrues,
        handlePays,
        tAmounts,

        togglePaymentPopup,
        paymentPopup,
        handleInfoPayment,
        orderInfoPayment,
        setInfoPayment,
        paymentData,
      }}
    >
      {props.children}
    </OrderContext.Provider>
  );
}

export default OrderState;
