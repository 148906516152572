import React from "react";

export default function ViewAdmission({ viewAdmission, closeViewPopup }) {
  const data = [
    {
      id: 1,
      title: "Class",
      desc: viewAdmission.Class,
    },
    {
      id: 2,
      title: "Section",
      desc: viewAdmission.Section,
    },
    {
      id: 3,
      title: "Full Name",
      desc: viewAdmission.FirstName + " " + viewAdmission.LastName,
    },
    {
      id: 4,
      title: "Father Name",
      desc: viewAdmission.FatherName,
    },
    {
      id: 5,
      title: "Father Phone",
      desc: viewAdmission.FatherPhone,
    },
    {
      id: 6,
      title: "Father Mobile",
      desc: viewAdmission.FatherMobile,
    },
    {
      id: 7,
      title: "Mother Name",
      desc: viewAdmission.MotherName,
    },
    {
      id: 8,
      title: "Mother Phone",
      desc: viewAdmission.MotherPhone,
    },
    {
      id: 9,
      title: "Mother Mobile",
      desc: viewAdmission.MotherMobile,
    },
    {
      id: 24,
      title: "Temporary Address",
      desc: viewAdmission.TempAddress
      ? viewAdmission.TempAddress
      : "Not mentioned",
    },
    {
      id: 25,
      title: "Permanent Address",
      desc: viewAdmission.PermAddress
        ? viewAdmission.PermAddress
        : "Not mentioned",
    },
    {
      id: 10,
      title: "User Mobile",
      desc: viewAdmission.UserMobile,
    },
    {
      id: 11,
      title: "Date Of Birth",
      desc: viewAdmission.DateOfBirth,
    },
    {
      id: 12,
      title: "Gender",
      desc: viewAdmission.Gender,
    },
    {
      id: 13,
      title: "Emergency Full Name",
      desc: viewAdmission.EmergFullName,
    },
    {
      id: 14,
      title: "Emergency Mobile",
      desc: viewAdmission.EmergMobile,
    },
    {
      id: 15,
      title: "Emergency Relation",
      desc: viewAdmission.EmergRelation,
    },
    {
      id: 16,
      title: "Email",
      desc: viewAdmission.Email,
    },
    {
      id: 17,
      title: "Dalit Or Janjati",
      desc: viewAdmission.DalitOrJanjati,
    },
    {
      id: 18,
      title: "Nationality",
      desc: viewAdmission.Nationality,
    },
    {
      id: 19,
      title: "Blood Group",
      desc: viewAdmission.BloodGroup,
    },
    {
      id: 20,
      title: "Religion",
      desc: viewAdmission.Religion,
    },
    {
      id: 21,
      title: "Previous School",
      desc: viewAdmission.PreviousSchool,
    },
    {
      id: 22,
      title: "Hobbies",
      desc: viewAdmission.Hobbies,
    },
    {
      id: 23,
      title: "Remarks",
      desc: viewAdmission.Remarks,
    },
  ];
  return (
    <>
      <div className="container-fluid basicform">
        <div className="row">
          {data.map((props) => {
            const { id, title, desc } = props;
            return (
              <div key={id} className="col-md-4">
                <div>
                  <div style={{ padding: "0px 5px" }}>
                    <h4 style={{ fontSize: "12px", fontWeight: "bold" }}>
                      {title}
                    </h4>
                    <p style={{ fontSize: "12px", color: "#212529" }}>{desc}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="FormFooter">
        <div className="checkedClose"></div>
        <div>
          {/* <button className='btn btn-sm btn-cmpy ml-2' style={{ background: "green" }} onClick={formSubmit} >Submit</button> */}
          <button className="btn btn-sm btn-cmpy ml-2" onClick={closeViewPopup}>
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}
