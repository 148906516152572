import React, { useContext, useEffect } from "react";
import CloseIcon from "../../images/CloseIcon.svg";
import AdminLeaveContext from "../contextfolder/AdminLeaveContext/AdminLeaveContext";
import './ApprovePop.css';


export default function RejectPop({ handleRejectTrue, handleRejectFalse, setRejectNotify, rejectNotify }) {

    const { leaveFormValue, setLeaveFormValue, leaveFormError, setLeaveFormError, isSubmit, setIsSubmit, leaveValue } = useContext(AdminLeaveContext)

    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;
        setLeaveFormValue({ ...leaveFormValue, [name]: value });
    };

    const handleOnChange = (e) => {
        setRejectNotify(!rejectNotify)
    };

    const validate = (values) => {

        const errors = {};
        if (!values.rejectRemarks) {
            errors.rejectRemarks = "Required";
        }

        return errors;
    };

    const formSubmit = (e) => {
        e.preventDefault();
        setLeaveFormError(validate(leaveFormValue));
        setIsSubmit(true);
    }


    useEffect(() => {
        if (Object.keys(leaveFormError).length === 0 && isSubmit) {
            handleRejectTrue(leaveFormValue)
            setLeaveFormValue(leaveValue)
            setIsSubmit(false);
        }

    }, [leaveFormError]);

    return (
        <>
            <div className="container confirm-popup">
                <div className="confirm-leave-popup-inner ">
                    <div className="popUpHeader ps-0 pe-0">
                        <div className="popUpDelTitle">Easy School</div>
                        <div className="popUpClose">
                            <img style={{ paddingRight: "10px", cursor: "pointer" }}
                                className="popUpCloseIcon"
                                src={CloseIcon}
                                alt="CloseIcon"
                                onClick={handleRejectFalse}
                            />
                        </div>
                    </div>

                    <div className="confirm-leave-body ps-3 pe-3">
                        <div className="row text-start ">
                            <div className="delText">Are you sure want to Reject this?</div>
                        </div>

                    </div>
                    <div className="container-fluid basicform" >
                        <div className="form-group">
                            <label htmlFor="rejectRemarks" style={{ fontSize: "12px" }}>
                                Remarks<sup style={{ color: "red" }}>*</sup>
                            </label>
                            <input
                                id="rejectRemarks"
                                style={{ fontSize: "13px" }}
                                type="text"
                                className="form-control form-control-sm"
                                name="rejectRemarks"
                                value={leaveFormValue.rejectRemarks}
                                onChange={handleChange}
                            />
                            <p className="errormsg " style={{ marginBottom: "0" }}>{leaveFormError.rejectRemarks}</p>

                        </div>
                    </div>
                    <div className="checkbox-close" style={{ marginLeft: "9px", paddingTop: "3px" }}>
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="exampleCheck3"
                            name="notWorking"
                            onChange={handleOnChange}
                            checked={rejectNotify}
                        />
                        <label class="form-check-label" for="exampleCheck3" style={{ fontSize: "12px", paddingLeft: "5px" }}>
                            Notify User
                        </label>
                    </div>

                    <div className="confirm-leave-footer">
                        <div className="row  mt-2 mb-2">
                            <div>
                                <button
                                    type="button"
                                    class="btn btn-sm me-2"
                                    style={{ background: "#0079bf", color: "white" }}
                                    onClick={formSubmit}
                                >
                                    Okay
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-sm btn-danger me-3"
                                    style={{ color: "white" }}
                                    onClick={handleRejectFalse}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

