import React, { useContext, useState, useEffect } from 'react'
import AssignmentContext from '../contextfolder/AssignmentContext/AssignmentContext';
import { showImgPreview } from '../hooks/ImagePreviewPopUpinside';
import Spinner from "../loading/spinner";



export default function ViewAssignment({ closeViewPopup }) {

    const context = useContext(AssignmentContext)

    const { toggleViewPopup, remarksFormValue, setRemarksFormValue, remarksIsSubmit, setRemarksIsSubmit, remarksvalue, pushNotice, setPushNotice, state, closeChecked, setCloseChecked, remarksFormError, setRemarksFormError, viewSubmitData, setLoading, loading, newState, setNewState, option, setoption } = context;

    const [state1, setState1] = useState(false)

    const [imgPrv, setImgPrv] = useState(false);
    const [imagePre, setImagePre] = useState("");

    const handleOnChange = () => {
        setCloseChecked(!closeChecked);
    };

    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;
        setRemarksFormValue({ ...remarksFormValue, [name]: value });
    };


    const formSubmit = (e) => {
        e.preventDefault();
        setRemarksIsSubmit(true);
        setRemarksFormError(validate(remarksFormValue));
        setState1(true)
    }

    useEffect(() => {
        if (Object.keys(remarksFormError).length === 0 && remarksIsSubmit) {
            viewSubmitData(remarksFormValue)
            if (closeChecked === true) {
                toggleViewPopup();
            }

        }
        setRemarksIsSubmit(false);

    }, [remarksFormError]);

    const validate = (values) => {
        const errors = {};
        const numv = /[+-]?([0-9]*[.])?[0-9]+/;
        if (state.Point !== "-1") {
            if (!values.points) {
                errors.points = "Required";
            }
            else if (parseFloat(values.points) < parseFloat(0)) {
                errors.points = "Must not be negative number";
            }
            else if (!numv.test(values.points)) {
                errors.points = "Must be digits or decimal number";
            }
            else if (parseFloat(values.points) > (parseFloat(state.Point))) {
                errors.points = "Points must be less than or equal to assigned point";
            }
        }


        return errors;
    };


    const handlePushNotification = () => {
        setPushNotice(!pushNotice)
    }





    // code for Assignsubmit list


    useEffect(() => {

        const arr = [state.AssignSubmit]
        if (arr.length > 0) {
            if (arr === undefined) {
                setNewState()
            }
            else {
                setNewState(state.AssignSubmit)
            }
        }
        else {

        }
    }, [state.AssignSubmit])




    // code for Assignsubmit list end



    // useEffect(() => {
    //     if (newState?.length > 0) {
    //         newState.map((data) => {
    //             let opt = {
    //                 Attachment: data.Attachment,
    //                 AssignStatus: data.AssignStatus,
    //                 Point: data.Point,
    //                 Comment: data.Comment,
    //                 SubDate: data.SubDate
    //             }
    //             option.push(opt)
    //             // setoption(option)
    //             setLoading(false)
    //         })
    //     }
    // }, [newState])

    useEffect(() => {
        if (newState?.length > 0) {
            newState.forEach((data) => {
                let opt = {
                    Attachment: data.Attachment,
                    AssignStatus: data.AssignStatus,
                    Point: data.Point,
                    Comment: data.Comment,
                    SubDate: data.SubDate
                };
                if (!option.some(o => o.SubDate === opt.SubDate)) {
                    setoption(prevOption => [...prevOption, opt]);
                }
            });
            setLoading(false);
        }
    }, [newState, option]);






    // code for Attachment

    const [attachState, setAttachState] = useState([])

    useEffect(() => {
        const arr = (state.Attachment)

        if (arr && arr === undefined) {
            setAttachState([])
        }
        else {
            setAttachState(state.Attachment)
        }
    }, [state.Attachment])

    // code for Attachment ends





    // code for Attachment inside Assignsubmit list

    const [attachmentState, setAttachmentState] = useState([])

    useEffect(() => {

        if (newState === undefined) {
            setAttachmentState([])
        }
        else {
            setAttachmentState(newState.AssignStatus)
        }
    }, [newState])


    // code for Attachment inside Assignsubmit list ends



    return (
        <>

            <div className="container-fluid basicform" >



                <div>

                    {state &&

                        <>
                            <div style={{ padding: "0px 5px" }} >
                                <label className="form-label" htmlFor="purpose">Assignment Title</label>
                                <p style={{ fontSize: "12px", color: "#212529" }}>
                                    {state.Title === "" ? "Pending" : state.Title}

                                </p>
                            </div>

                            <div style={{ padding: "0px 5px" }} >
                                <label className="form-label" htmlFor="purpose">Images</label>
                                <div className="scrollonAssignment">
                                    {attachState &&
                                        attachState.map((i) => (
                                            <div className="viewStaffContentLogo">
                                                <div className="viewStaffImg">
                                                    <img src={i.File} alt=""
                                                        onClick={() => { setImagePre(i.File); setImgPrv(true) }}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </>
                    }



                    <table className="complain-table" style={{ width: "100%" }}>

                        <thead>
                            <tr className='tableHead viewHead'>
                                <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", position: "relative" }}>Subject</td>
                                <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", position: "relative" }}>Created Date</td>
                                <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", position: "relative" }}>Submission Date</td>
                            </tr>
                        </thead>
                        <tbody>




                            {state &&
                                <tr className="viewHead">
                                    <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", paddingTop: "0px" }} >
                                        {state.Subject === "" ? "Pending" : state.Subject}
                                    </td>
                                    <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", paddingTop: "0px" }}>
                                        {state.EnglishDate === "" ? "Pending" : state.EnglishDate}
                                    </td>
                                    <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", paddingTop: "0px" }}>
                                        {state.Deadline === "" ? "Pending" : state.Deadline}
                                    </td>
                                </tr>
                            }




                        </tbody>

                        <thead>
                            <tr className='tableHead viewHead'>
                                <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", position: "relative" }}>Points</td>
                                {/* <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", position: "relative" }}>Submitted Date</td>
                                <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", position: "relative" }}>Assignment Status</td> */}
                            </tr>
                        </thead>

                        <tbody>


                            <tr className="viewHead">
                                <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", paddingTop: "0px" }} >
                                    {state.Point === "" ? "Pending" : state.Point === "-1" ? "No Point" : state.Point}
                                </td>
                                {/* <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", paddingTop: "0px" }}>
                                    {newState === undefined ? "Pending" : newState.SubDate}
                                </td>
                                <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", paddingTop: "0px" }}>
                                    {newState === undefined ? "Pending" : state.Point === "S" ? "Submitted" : "Pending"}
                                </td> */}
                            </tr>

                        </tbody>

                    </table>

                </div>
                <div>


                </div>
                {state.AssignStatus === "R" || state.AssignStatus === "A" ?
                    <div style={{ padding: "5px 5px" }} >
                        <label className="form-label">Assignment Details</label> <br />
                    </div> : <></>}


                {option && option.map((data) => {
                    const { Attachment, AssignStatus, Comment, Point, SubDate } = data;
                    return (
                        state.AssignStatus === "R" || state.AssignStatus === "A" || state.AssignStatus === "S" ?
                            <>
                                <table className="complain-table" style={{ width: "100%" }}>

                                    <thead>
                                        <tr className='tableHead viewHead'>
                                            <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", position: "relative" }}>Status</td>
                                            <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", position: "relative" }}>Point</td>
                                            <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", position: "relative" }}>Submitted Date</td>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr className="viewHead">
                                            <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", paddingTop: "0px" }} >
                                                {AssignStatus === "R" ? "Redo" : "Submitted"}
                                            </td>
                                            <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", paddingTop: "0px" }}>
                                                {Point === null ? "Not Mentioned" : Point}
                                            </td>
                                            <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", paddingTop: "0px" }}>
                                                {SubDate}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div style={{ padding: "5px 5px" }} >
                                    <label className="form-label">Comment</label>
                                    <h4 style={{ fontSize: "12px", color: "#212529" }}>{Comment}</h4>
                                </div>

                                <div style={{ padding: "0px 5px" }} >
                                    <label className="form-label" htmlFor="purpose">Submitted Attachment</label>
                                    <div className="scrollonAssignment">
                                        {
                                            Attachment && Attachment.map((data) => {
                                                return (
                                                    <div className="viewStaffContentLogo">
                                                        <div className="viewStaffImg">
                                                            <img src={data.File}
                                                                alt=""
                                                                onClick={() => { setImagePre(data.File); setImgPrv(true) }}
                                                            />
                                                        </div></div>
                                                )
                                            })
                                        }

                                    </div>
                                    <div>

                                    </div>



                                </div>

                                <hr style={{ color: "blue" }} />

                            </> : <></>
                    )

                })}




                {/* {state.AssignStatus === "P" ? (<></>) : ( */}
                <>

                    <form >
                        <div className="form-group">
                            <div>
                                <label className="form-label" htmlFor="purpose">Status</label>
                            </div>
                            <div>
                                <input type="radio" className="radio--button" name="status" id="pending" onChange={handleChange} value="A" checked={remarksFormValue.status === "A"} />
                                <label className="label-radio" htmlFor="pending" style={{ marginRight: "10px" }}>Approve</label>

                                <input type="radio" className="radio--button" name="status" id="approved" onChange={handleChange} value="R" checked={remarksFormValue.status === "R"} />
                                <label className="label-radio" htmlFor="approved" style={{ marginRight: "10px" }}>Redo</label>
                            </div>
                            {/* <p className="errormsg ">{remarksFormError.complainStatus}</p> */}
                        </div>
                        {state.Point === "-1" ? (<></>) : (
                            <div className="form-group">
                                <label className="form-label" htmlFor="points" style={{ fontSize: "12px" }}>
                                    Obtained Points<sup style={{ color: "red" }}>*</sup>
                                </label>
                                <input
                                    id="points"
                                    style={{ fontSize: "13px" }}
                                    type="text"
                                    className="form-control form-control-sm "
                                    name="points"
                                    value={remarksFormValue.points}
                                    onChange={handleChange}
                                />
                                <p className="errormsg " style={{ marginBottom: "0" }}>{remarksFormError.points}</p>
                            </div>
                        )}


                    </form>


                    <div style={{ paddingTop: "9px" }}>
                        <input
                            type="checkbox"
                            // className="form-check-input"
                            id="exampleCheck3"
                            name="notWorking"
                            onChange={handlePushNotification}
                            checked={pushNotice}
                        />
                        <label class="form-check-label" for="exampleCheck3" style={{ fontSize: "12px", paddingLeft: "3px" }}>
                            Notify User
                        </label>
                    </div>
                </>
                {/* )} */}

            </div>


            <div className="FormFooter">

                <div className='checkedClose'>
                    {/* {state.AssignStatus === "P" ? (<></>) : ( */}
                    <>
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="exampleCheck2"
                            name="closeChecked"
                            onChange={handleOnChange}
                            checked={closeChecked}
                        />
                        <label class="form-check-label" for="exampleCheck2">
                            Exit after submit
                        </label>
                    </>
                    {/* )} */}

                </div>
                <div>

                    {/* {state.AssignStatus === "P" ? (<></>) : ( */}
                    <button className='btn btn-sm btn-cmpy ml-2' style={{ background: "green" }} onClick={formSubmit} >Submit</button>
                    {/* )} */}
                    <button className='btn btn-sm btn-cmpy ml-2' onClick={closeViewPopup}>Cancel</button>
                </div>
            </div>


            {imgPrv &&
                showImgPreview({
                    img: imagePre,
                    setTrigger: setImgPrv,
                })}

        </>

    )
}
