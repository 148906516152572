import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import JobContext from "./JobContext";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { Fetchdata } from "../../hooks/getData";
import { GetNepaliDate } from "../../hooks/dateConvertor";

function JobState(props) {
  const { appURL } = useContext(SidebarContext);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const [popup, setPopup] = useState(false);

  const togglePopup = () => {
    setPopup(!popup);
  };

  const [applicantPop, setApplicantPop] = useState(false);
  const [editPop, setEditPop] = useState(false);

  const toggleEditPopup = () => {
    setEditPop(!editPop);
  };

  const [jobFormError, setJobFormError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [editIsSubmit, setEditIsSubmit] = useState(false);
  const [loading, setLoading] = useState(true);

  const [closeChecked, setCloseChecked] = useState(false);

  const jobvalue = {
    department: "",
    subDepartment: "",
    designation: "",
    shifts: "",
    shiftType: "",
    jobType: "",
    title: "",
    description: "",
    responsibility: "",
    position: "",
    education: "",
    experience: "",
    startDate: "",
    endDate: "",
    salary: "",
    interviewDate: "",
  };

  const [jobFormValue, setJobFormValue] = useState(jobvalue);
  const [image, setImage] = useState("");
  const [isUploaded, setIsUploaded] = useState(false);
  const [typeFile, setTypeFile] = useState("");

  const [originalList, setOriginalList] = useState(null);
  const [searchList, setSearchList] = useState(null);
  const [negotiable, setNegotiable] = useState(false);

  const [chooseDesignation, setChooseDesignation] = useState("-1");
  const [chooseShift, setChooseShift] = useState("-1");
  const [chooseShiftType, setChooseShiftType] = useState("-1");
  const [chooseJobType, setChooseJobType] = useState("-1");

  const [jobStatus, setJobStatus] = useState("");

  //API to hit Job List

  const [jobList, setJobList] = useState([]);

  useEffect(() => {
    getJobList();
  }, []);

  const getJobList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/job`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.AccLst ? result.AccLst : "";
        setLoading(false);
        setJobList(postResult);
        setOriginalList(postResult);
      } else {
        setJobList([]);
        setOriginalList([]);
      }
    });
  };

  const [titleId, setTitleID] = useState();

  const handleEdit = (datas) => {
    setEditPop(true);
    setTitleID(datas.JobID);
    easyList();
  };

  //API to hit Job Info

  // const [staffInfoList, setStaffInfoList] = useState([])

  useEffect(() => {
    easyList();
  }, [titleId]);

  const easyList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "SI",
      JobID: titleId,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/job`,
      Type: "POST",
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const data = result.AccLst[0] ? result.AccLst[0] : "";

        setJobFormValue({
          designation: data.Designation,
          shifts: data.Shift,
          shiftType: data.ShiftType,
          jobType: data.JobType,
          title: data.Title,
          description: data.Description,
          responsibility: data.Responsibility,
          position: data.NoPos,
          education: data.Education,
          experience: data.Experience,
          startDate: data.StartDate,
          endDate: data.EndDate,
          salary: data.Salary,
          interviewDate: data.InterviewDate,
        });
        // setImage(data.Banner)
        setNegotiable(data.IsNegID);
      } else {
      }
    });
  };

  // to edit job

  const editJobdata = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "U",
      JobID: titleId,
      Designation: jobFormValue.designation,
      Shift: jobFormValue.shifts,
      ShiftType: jobFormValue.shiftType,
      JobType: jobFormValue.jobType,
      Title: jobFormValue.title,
      Description: jobFormValue.description,
      Banner: image !== null ? image.split(",")[1] : "",
      Responsibility: jobFormValue.responsibility,
      Education: jobFormValue.education,
      NoPos: jobFormValue.position,
      Experience: jobFormValue.experience,
      IsNeg: negotiable ? "Y" : "N",
      Salary: negotiable ? "0" : jobFormValue.salary,
      StartDate: jobFormValue.startDate,
      EndDate: jobFormValue.endDate,
      InterviewDate: jobFormValue.interviewDate,
      JobStatus: "A",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/job`,
      Type: "POST",
    };
    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getJobList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
          theme: "light",
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        });
      }
    });
  };

  // to edit job ends

  //API to delete Job

  // //Everything to pop up and delete the notification list
  const [deletePopup, setDeletePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const [delId, setDelId] = useState("");

  const handleDelete = async (datas) => {
    setDeletePopup({
      show: true,
    });
    setDelId(datas);
  };

  // This will perform the deletion and hide the Confirmation Box

  const handleDeleteTrue = async () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "R",
      JobID: delId,
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/job`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          getJobList();
          toast(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
        } else {
          toast(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
    });
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleDeleteFalse = () => {
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // Ends Here

  //API to hit Job status

  const changeStatus = (data) => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "UJS",
      JobID: data[0],
      JobStatus: data[1],
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/job`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getJobList();

        // toast(result.Message, {
        //   style: {
        //     color: "green",
        //     fontSize: "13px",
        //   },
        //   theme: "light",
        // });
      }
      // else {
      //   toast("Error: " + result.Message, {
      //     style: {
      //       color: "red",
      //       fontSize: "13px",
      //     },
      //     theme: "light",
      //   });
      // }
    });
  };

  return (
    <JobContext.Provider
      value={{
        jobFormValue,
        setJobFormValue,
        jobvalue,
        popup,
        setPopup,
        jobFormError,
        setJobFormError,
        isSubmit,
        setIsSubmit,
        loading,
        setLoading,
        applicantPop,
        setApplicantPop,

        image,
        setImage,
        isUploaded,
        setIsUploaded,
        typeFile,
        setTypeFile,
        negotiable,
        setNegotiable,
        editPop,
        setEditPop,
        editIsSubmit,
        setEditIsSubmit,
        originalList,
        setOriginalList,

        jobList,
        getJobList,
        handleEdit,
        titleId,
        setJobList,

        searchList,

        chooseDesignation,
        setChooseDesignation,
        chooseShift,
        setChooseShift,
        chooseShiftType,
        setChooseShiftType,
        chooseJobType,
        setChooseJobType,

        jobStatus,
        setJobStatus,
        togglePopup,
        closeChecked,
        setCloseChecked,
        toggleEditPopup,
        editJobdata,

        deletePopup,
        handleDeleteTrue,
        handleDeleteFalse,
        handleDelete,
        changeStatus,
      }}
    >
      {props.children}
    </JobContext.Provider>
  );
}
export default JobState;
