import React, { useContext, useEffect, useState } from 'react'
import { useRef } from 'react';
import DataTable from 'react-data-table-component';
import DesignationContext from '../contextfolder/DesignationContext/DesignationContext';
import SidebarContext from '../sidebarcontext/SidebarContext';
import Spinner from '../loading/spinner';
import DesignationForm from './DesignationForm';

export default function CreateDesignation({ closeCreatePopup, closePopup, addnew }) {

    const context = useContext(DesignationContext)

    const { togglePopup, createFormError, setCreateFormError, createIsSubmit, setCreateIsSubmit, createValue, createFormValue, setCreateFormValue, loading, designationTopicList, teacherList, setTeacherList, createTopicSearch, inputFields, setInputFields, designationdata, closeChecked, setCloseChecked, modal } = context;

    const { customStyles } = useContext(SidebarContext)

    const handleOnChange = () => {
        setCloseChecked(!closeChecked);
    };

    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;
        setCreateFormValue({ ...createFormValue, [name]: value });
    };

    const formSubmit = (e) => {
        e.preventDefault();
        setCreateFormError(validate(createFormValue));
        setCreateIsSubmit(true);
    }
    useEffect(() => {
        if (Object.keys(createFormError).length === 0 && createIsSubmit) {
            designationdata(createFormValue)
            if (closeChecked === true) {
                togglePopup();
            }
            setInputFields([]);
            setCreateFormValue(createValue)
            setCreateIsSubmit(false);
        }

    }, [createFormError]);


    const validate = (values) => {
        const errors = {};
        if (!values.topic) {
            errors.topic = "Required";
        }
        return errors;
    };

    const searchInput = useRef("");

    const searchHandler = (e) => {
        e.preventDefault();

        const srchQuery = searchInput.current.value.toLowerCase();
        if (srchQuery) {

            let srchResult = createTopicSearch.filter((list) => {
                return list["TeacherName"].toLowerCase().includes(srchQuery)
            });

            if (srchResult) {

                setTeacherList(srchResult);
            } else {
                setTeacherList({});
            }
        } else {
            setTeacherList(createTopicSearch);
        }
    };


    const columns = [
        {
            name: "S.N.",
            width: "59px",
            center: true,
            cell: (row, index) => index + 1,
        },

        {
            name: "Full Name",
            center: true,
            // grow: 0,
            selector: (row) => row.TeacherName,
        },
        {
            name: "Action",
            center: true,
            width: "120px",
            selector: (row) => {
                return (
                    <>
                        <input type="checkbox"
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setInputFields([
                                        ...inputFields, {
                                            MemID: row.TeacherID,
                                            UserType: "T"
                                        }
                                    ])
                                } else {

                                    setInputFields(
                                        inputFields.filter((item) => item.MemID !== row.TeacherID)
                                    )

                                }

                            }}
                            value={inputFields}
                        />
                    </>
                );
            },
        },

    ];



    return (
        <>

            <div className="container-fluid basicform" >

                <form >
                    <div className="form-row">
                        <div className="form-group col-md-11">
                            <label className="form-label" htmlFor="order" style={{ fontSize: "12px" }}>
                                Designation<sup style={{ color: "red" }}>*</sup>
                            </label>
                            <select id="topic" name="topic"
                                onChange={handleChange}
                                value={createFormValue.topic} className="form-select form-select-sm mb-1 " style={{ fontSize: '12px' }}>
                                <option value="" selected disabled>Select Option</option>
                                {designationTopicList.map((item, i) => <option key={i} value={item.DesignationID}>{item.Designation}</option>)}
                            </select>
                            <p className="errormsg " style={{ marginBottom: "0" }}>{createFormError.topic}</p>
                        </div>

                        <div className="form-group col-md-1">
                            <div className="add-title-button">
                                <button
                                    className="btn btn-sm btn-cmpy"
                                    style={{
                                        background: "0079bf",
                                        width: "50px",
                                        marginTop: "4px"
                                    }}
                                    onClick={addnew}
                                >
                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                </button>
                            </div>

                        </div>

                    </div>
                </form>

                <div className="sec-dataTable">

                    {loading ? (
                        <Spinner />
                    ) : (
                        <DataTable
                            columns={columns}
                            data={teacherList}
                            customStyles={customStyles}
                            pagination
                            fixedHeader
                            fixedHeaderScrollHeight="280px"
                            highlightOnHover
                            pointerOnHover
                            responsive
                            dense
                            striped
                            subHeader
                            subHeaderComponent={
                                <>
                                    <div className="upper-dataTbl">
                                        <div className="d-flex">
                                            <input
                                                placeholder="Search"
                                                ref={searchInput}
                                                type="text"
                                                className="form-control form-control-sm searchField"
                                                onChange={searchHandler}
                                            />
                                        </div>
                                    </div>
                                </>
                            }
                        />
                    )}
                </div>
            </div>
            <div className="FormFooter">
                <div className='checkedClose'>
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck2"
                        name="closeChecked"
                        onChange={handleOnChange}
                        checked={closeChecked}
                    />
                    <label class="form-check-label" for="exampleCheck2">
                        Exit after submit
                    </label>
                </div>
                <div>
                    <button className='btn btn-sm btn-cmpy ml-2' style={{ background: "green" }} onClick={formSubmit} >Submit</button>
                    <button className='btn btn-sm btn-cmpy ml-2' onClick={closeCreatePopup}>Cancel</button>
                </div>
            </div>


            {modal && (
                <div className="modals">
                    <div className="popup-overlay"></div>

                    <div className="credit-model-content">
                        <div className="popUpHeader ">
                            <div className='popUpHeaderText '>Designation</div>
                            <div style={{ cursor: "pointer" }}><i className="bi bi-x " onClick={closePopup} style={{ fontSize: "25px" }}></i></div>
                        </div>
                        <div className="inside-modal">
                            <DesignationForm closePopup={closePopup} />
                        </div>
                    </div>
                </div>
            )}
        </>

    )
}
