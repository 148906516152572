import React, { useContext, useEffect, useState } from "react";
import { Fetchdata } from "../../hooks/getData";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { toast } from "react-toastify";
import EventContext from "./EventContext";
import { useMemo } from "react";
import { GetCurrTime, GetEnglishDate } from "../../hooks/dateConvertor";

function EventState(props) {
  const { appURL, getEnglishDate } = useContext(SidebarContext);

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const [closeChecked, setCloseChecked] = useState(false);

  const eventValue = {
    title: "",
    description: "",
    fromDate: getEnglishDate,
    toDate: "",
    fromTime: "",
    toTime: "",
    venue: "",
    email: "",
    contact: "",
    category: "",
    organizer: userinfo.SchName,
    latitude: "",
    longitude: "",
  };
  const [eventFormValue, setEventFormValue] = useState(eventValue);
  const [eventFormError, setEventFormError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [editIsSubmit, setEditIsSubmit] = useState(false);

  const [popup, setPopup] = useState(false);
  const togglePopup = () => {
    setPopup(!popup);
  };

  const [editPop, setEditPop] = useState(false);
  const toggleEditPopup = () => {
    setEditPop(!editPop);
  };

  const [isUploaded, setIsUploaded] = useState(false);
  const [typeFile, setTypeFile] = useState("");
  const [image, setImage] = useState("");

  //API to create event
  const fetchdata = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "i",
      DFlag: "E",
      Title: eventFormValue.title,
      Description: eventFormValue.description,
      From: eventFormValue.fromDate,
      To: eventFormValue.toDate,
      TimeStart: eventFormValue.fromTime,
      TimeEnd: eventFormValue.toTime,
      Venue: eventFormValue.venue,
      Category: eventFormValue.category,
      Contact: eventFormValue.contact,
      Email: eventFormValue.email,
      Latitude: eventFormValue.latitude,
      Longitude: eventFormValue.longitude,
      Banner: image !== null ? image.split(",")[1] : "",
      OrganizedBy: eventFormValue.organizer,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/event`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getEventList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };

  // API for eventlist
  const [eventList, setEventList] = useState([]);

  useEffect(() => {
    getEventList();
  }, []);

  const getEventList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/event`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.EventLst ? result.EventLst : "";
        setLoading(false);
        setEventList(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        setEventList([]);
        setOriginalList([]);
      }
    });
  };

  const [eventId, setEventId] = useState("");

  const handleEdit = (data) => {
    setEditPop(!editPop);

    setEventId(data.EventID);
    setEventFormValue({
      title: data.Title,
      description: data.Description,
      fromDate: data.EngDateFrom,
      toDate: data.EngDateTo,
      fromTime: data.TimeStart,
      toTime: data.TimeEnd,
      venue: data.Venue,
      email: data.Email,
      contact: data.Contact,
      category: data.Category,
      organizer: data.OrganizedBy,
      latitude: data.Latitude,
      longitude: data.Longitude,
    });
    setImage(data.Banner);
    setEditIsSubmit(false);
  };

  //API to edit event
  const editdata = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "U",
      EventID: eventId.toString(),
      DFlag: "E",
      Title: eventFormValue.title,
      Description: eventFormValue.description,
      From: eventFormValue.fromDate,
      To: eventFormValue.toDate,
      TimeStart: eventFormValue.fromTime,
      TimeEnd: eventFormValue.toTime,
      Venue: eventFormValue.venue,
      Category: eventFormValue.category,
      Contact: eventFormValue.contact,
      Email: eventFormValue.email,
      Latitude: eventFormValue.latitude,
      Longitude: eventFormValue.longitude,
      Banner: image !== null ? image.split(",")[1] : "",
      OrganizedBy: eventFormValue.organizer,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/event`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getEventList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };

  //Everything to pop up and delete the event list
  const [deletePopup, setDeletePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const [delData, setDelData] = useState();

  const handleDelete = async (data) => {
    setDelData(data);

    setDeletePopup({
      show: true,
      data,
    });
  };

  // This will perform the deletion and hide the Confirmation Box

  const handleDeleteTrue = async () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "D",
      EventID: delData.toString(),
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/event`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          getEventList();
          toast(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
        } else {
          toast(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
    });
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleDeleteFalse = () => {
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // Ends Here

  return (
    <EventContext.Provider
      value={{
        loading,
        originalList,
        deletePopup,
        handleDeleteTrue,
        handleDeleteFalse,
        handleDelete,
        popup,
        setPopup,
        togglePopup,
        eventFormValue,
        setEventFormValue,
        eventFormError,
        setEventFormError,
        isSubmit,
        eventValue,
        setIsSubmit,
        setEventList,
        isUploaded,
        setIsUploaded,
        typeFile,
        setTypeFile,
        image,
        setImage,
        editIsSubmit,
        setEditIsSubmit,
        fetchdata,
        eventList,
        editPop,
        setEditPop,
        toggleEditPopup,
        handleEdit,
        editdata,
        closeChecked,
        setCloseChecked,
      }}
    >
      {props.children}
    </EventContext.Provider>
  );
}

export default EventState;
