import React, { useContext, useState } from "react";
import SidebarContext from "../sidebarcontext/SidebarContext";
import { ToastContainer } from "react-toastify";
import { useEffect } from "react";
import { FetchJobdata } from "../hooks/getJobData";
import Spinner from "../loading/spinner";
import EliteJobDashboard from "./EliteJobDashboard";
import EliteJobLogin from "./EliteJobLogin";

const EliteJob = () => {
  const { jobURL } = useContext(SidebarContext);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const [checkCompany, setCheckCompany] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getCompany();
  }, []);

  const getCompany = () => {
    const params = {
      ComID: userinfo.SchID,
      Flag: "CK",
      Type: "POST",
      FetchURL: `${jobURL}api/job/com-sync`,
    };

    FetchJobdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        setCheckCompany(true);
        setLoading(false);
      } else {
        setCheckCompany(false);
        setLoading(false);
      }
    });
  };

  // to check if there is a user
  // const [checkUser, setCheckUser] = useState(false);
  // useEffect(() => {
  //   if (checkCompany) {
  //     getUser();
  //   }
  // }, [checkUser, checkCompany]);

  // const getUser = () => {
  //   const params = {
  //     ComID: userinfo.SchID,
  //     Flag: "CK",
  //     UserName: userinfo.UserName,
  //     Type: "POST",
  //     FetchURL: `${jobURL}api/job/user-sync`,
  //   };

  //   FetchJobdata(params).then(function (result) {
  //     if (result.StatusCode === 200) {
  //       setCheckUser(true);
  //     } else {
  //       setCheckUser(false);
  //     }
  //   });
  // };


  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      {loading ? (
        <Spinner />
      ) : checkCompany ? (
        // && checkUser
        <EliteJobDashboard />
      ) : (
        <EliteJobLogin
          // checkUser={checkUser}
          // setCheckUser={setCheckUser}
          checkCompany={checkCompany}
          setCheckCompany={setCheckCompany}
        />
      )}
    </>
  );
};

export default EliteJob;
