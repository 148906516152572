import React, { useContext, useEffect, useState } from "react";
import TeacherAttendanceContext from "../contextfolder/TeacherAttendanceContext/TeacherAttendanceContext";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import { Fetchdata } from "../hooks/getData";
import { toast } from "react-toastify";
import SidebarContext from "../sidebarcontext/SidebarContext";
import {
  GetEnglishDate,
  GetTwelveHourFormatTime,
  getDisableDates,
} from "../hooks/dateConvertor";
import TeacherContext from "../contextfolder/TeacherContext/TeacherContext";
import { json } from "react-router-dom";
import Select from "react-select";
export default function TeacherAttendanceForm({ closePopup, getList }) {
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const { appURL } = useContext(SidebarContext);

  const context = useContext(TeacherAttendanceContext);

  const {
    togglePopup,
    attendanceReportFormError,
    setAttendanceReportFormError,
    reportIsSubmit,
    setReportIsSubmit,
    attendanceReportValue,
    attendanceReportFormValue,
    setAttendanceReportFormValue,
    closeChecked,
    setCloseChecked,
  } = context;

  const { teacherList } = useContext(TeacherContext);
  const dropDownValue = teacherList.map((item) => ({
    value: item.TeacherID,
    label: item.TeacherName,
  }));

  const handleSelectTeacher = (e) => {
    let name = "teacher";
    setAttendanceReportFormValue({
      ...attendanceReportFormValue,
      [name]: e.value,
    });
  };
  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setAttendanceReportFormValue({
      ...attendanceReportFormValue,
      [name]: value,
    });
  };

  const handleDate = ({ adDate }) => {
    setAttendanceReportFormValue({
      ...attendanceReportFormValue,
      date: adDate,
    });
  };

  const handleOnChange = () => {
    setCloseChecked(!closeChecked);
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setAttendanceReportFormError(validate(attendanceReportFormValue));
    setReportIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(attendanceReportFormError).length === 0 && reportIsSubmit) {
      const dataForm = {
        SchID: userinfo.SchID,
        UserID: attendanceReportFormValue.teacher,
        Flag: "i",
        AttenDate: GetEnglishDate(attendanceReportFormValue.date),
        AttenTime: GetTwelveHourFormatTime(attendanceReportFormValue.time),
        AttenStatus: attendanceReportFormValue.status,
        AttenPlace: "O",
        AttenVia: "M",
        SSID: "Web",
        Academicyear: userinfo.AcademicYear,
        FetchURL: `${appURL}api/admin/create-attendance`,
        Type: "POST",
      };

      Fetchdata(dataForm).then(function (result) {
        if (result.StatusCode === 200) {
          getList();
          toast(result.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
        } else {
          toast("Error: " + result.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
      if (closeChecked === true) {
        togglePopup();
      }
      setAttendanceReportFormValue(attendanceReportValue);
      setReportIsSubmit(false);
    }
  }, [attendanceReportFormError]);

  const validate = (values) => {
    const errors = {};
    if (!values.date) {
      errors.date = "Required";
    }
    if (!values.time) {
      errors.time = "Required";
    }
    if (!values.status) {
      errors.status = "Required";
    }
    if (!values.teacher) {
      errors.teacher = "Required";
    }
    return errors;
  };

  return (
    <>
      <div className="container-fluid basicform">
        <form>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label
                className="form-label"
                htmlFor="date"
                style={{ fontSize: "12px" }}
              >
                Date<sup style={{ color: "red" }}>*</sup>
              </label>
              <Calendar
                className="form-control form-control-sm mb-1"
                dateFormat="YYYY/MM/DD"
                theme="default"
                language="en"
                values={attendanceReportFormValue.date}
                onChange={handleDate}
                maxDate={getDisableDates()}
              />
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {attendanceReportFormError.date}
              </p>
            </div>

            <div className="form-group col-md-6">
              <label
                className="form-label"
                htmlFor="time"
                style={{ fontSize: "12px" }}
              >
                Time<sup style={{ color: "red" }}>*</sup>
              </label>
              <input
                id="time"
                style={{ fontSize: "13px" }}
                type="time"
                className="form-control form-control-sm "
                name="time"
                value={attendanceReportFormValue.time}
                onChange={handleChange}
              />
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {attendanceReportFormError.time}
              </p>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-6">
              <label className="form-label" htmlFor="teachers">
                Teacher<sup style={{ color: "red" }}>*</sup>
              </label>
              {/* <select id="teachers" name="teacher"
                                onChange={handleChange}
                                value={attendanceReportFormValue.teacher} className="form-select form-select-sm mb-1" style={{ fontSize: '12px' }}>
                                <option
                                    value=""
                                    disabled
                                    selected
                                    style={{ fontSize: "11px" }}
                                >
                                    Select Teacher
                                </option>
                                {teacherList.map((item) => (
                                    <option
                                        key={item.TeacherID}
                                        value={item.TeacherID}
                                    >
                                        {item.TeacherName}
                                    </option>
                                ))}
                            </select> */}
              <Select
                className="select"
                style={{ fontSize: "11px", marginBottom: "3px" }}
                options={dropDownValue}
                onChange={(item) => handleSelectTeacher(item)}
                isSearchable={true}
              />
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {attendanceReportFormError.teacher}
              </p>
            </div>

            <div className="form-group col-md-6">
              <label
                className="form-label"
                htmlFor="status"
                style={{ fontSize: "12px" }}
              >
                Status<sup style={{ color: "red" }}>*</sup>
              </label>
              <select
                id="status"
                name="status"
                onChange={handleChange}
                value={attendanceReportFormValue.status}
                className="form-select form-select-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="" disabled selected style={{ fontSize: "11px" }}>
                  Select status
                </option>
                <option value="I">Check In</option>
                <option value="O">Check out</option>
              </select>
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {attendanceReportFormError.status}
              </p>
            </div>
          </div>
        </form>
      </div>
      <div className="FormFooter">
        <div className="checkedClose">
          <input
            type="checkbox"
            className="form-check-input"
            id="exampleCheck2"
            name="closeChecked"
            onChange={handleOnChange}
            checked={closeChecked}
          />
          <label class="form-check-label" for="exampleCheck2">
            Exit after submit
          </label>
        </div>
        <div>
          <button
            className="btn btn-sm btn-cmpy ml-2"
            style={{ background: "green" }}
            onClick={formSubmit}
          >
            Submit
          </button>
          <button className="btn btn-sm btn-cmpy ml-2" onClick={closePopup}>
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}
