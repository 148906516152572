import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";
import "tippy.js/dist/tippy.css";
import { Fetchdata } from "../hooks/getData";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import { convertToNep } from "../hooks/dateConvertor";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { formatNumber } from "../hooks/drCr";

export default function AccountReport() {
  const { customStyles, appURL, monthsBack } = useContext(SidebarContext);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);

  const reportValue = {
    fromDate: convertToNep(monthsBack),
    toDate: "",
  };

  const [reportDateValue, setReportDateValue] = useState(reportValue);

  const handleFormDate = ({ bsDate }) => {
    setReportDateValue({ ...reportDateValue, fromDate: bsDate });
  };

  const handleToDate = ({ bsDate }) => {
    setReportDateValue({ ...reportDateValue, toDate: bsDate });
  };

  // API for account report list
  const [accountReportList, setAccountReportList] = useState([]);

  useEffect(() => {
    getreportList();
  }, [reportDateValue]);

  const getreportList = () => {
    const params = {
      SchID: userinfo.SchID,
      Flag: "R",
      MemID: userinfo.UserID.toString(),
      FromDate: reportDateValue.fromDate,
      ToDate: reportDateValue.toDate,
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/account`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.AccLst ? result.AccLst : "";
        setLoading(false);
        setAccountReportList(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        setAccountReportList([]);
        setOriginalList([]);
      }
    });
  };

  let credit = accountReportList.reduce(function (prev, current) {
    return prev + parseFloat(current.Credit);
  }, 0);

  let debit = accountReportList.reduce(function (prev, current) {
    return prev + parseFloat(current.Debit);
  }, 0);

  const searchInput = useRef("");

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["Particulars"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setAccountReportList(srchResult);
      } else {
        setAccountReportList({});
      }
    } else {
      setAccountReportList(originalList);
    }
  };

  /* This is for export csv */

  const dataToExcel = accountReportList.map((d, i) => ({
    "S.N.": i + 1,
    Date: d.Date,
    Particulars: d.Particulars,
    Debit: d.Debit,
    Credit: d.Credit,
  }));

  const toExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(dataToExcel);
      var wscols = [
        { wch: 5 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
      ];
      worksheet["!cols"] = wscols;
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "accountReportList");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_" + "export" + EXCEL_EXTENSION
        );
      }
    });
  };

  //end

  //This is to export pdf

  const dataWithIndex = accountReportList.map((d, i) => ({
    ...d,
    Index: i + 1,
  }));

  const cols = [
    { header: "S.N", field: "Index" },
    { header: "Date", field: "Date" },
    { header: "Particulars", field: "Particulars" },
    { header: "Debit", field: "Debit" },
    { header: "Credit", field: "Credit" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const toPdf = () => {
    const doc = new jsPDF("p", "pt", "a4", true);

    var midPage = doc.internal.pageSize.getWidth() / 2;

    // doc.addImage(`${userinfo.SchPhoto}`, "JPG", 40, 40, 70, 60);

    doc.setFontSize(18);
    doc.text(`${userinfo.SchName}`, midPage, 50, null, null, "center");

    doc.setFontSize(15);
    doc.text(`${userinfo.SchAddress}`, midPage, 68, null, null, "center");

    doc.setFontSize(10);
    doc.text(
      `Tel:${userinfo.Phone}, E-mail:${userinfo.Email}`,
      midPage,
      85,
      null,
      null,
      "center"
    );

    doc.autoTable({
      startY: 100,
      theme: "grid",
      columns: exportColumns,
      body: dataWithIndex,
    });
    doc.save("accountReportList.pdf");
  };
  //end

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <div className="sec-dataTable">
        <div className="upper-dataTbl">
          <Tippy content="PDF">
            <button className="exportButtons mx-2" onClick={toPdf}>
              <i className="bi bi-file-earmark-pdf-fill exportButtonIconsPdf"></i>
            </button>
          </Tippy>
          <Tippy content="Excel">
            <button className="exportButtons" onClick={toExcel}>
              <i className="bi bi-file-earmark-excel-fill exportButtonIconsExcel"></i>
            </button>
          </Tippy>
        </div>
        <hr className="new-hr-new" />
        <div className="totalTrans">
          {" "}
          {formatNumber((debit - credit).toFixed(2))}{" "}
          <b> &nbsp;Balance :&nbsp;</b>
          {credit.toFixed(2)} | <b> &nbsp;Total Credit :&nbsp;</b>
          {debit.toFixed(2)} | <b>&nbsp;Total Debit :&nbsp;</b>
        </div>
        <div className="row" style={{ margin: "0" }}>
          {/* <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                        <label className="list-label">From Date</label>
                        <Calendar
                            className="form-control form-control-sm"
                            dateFormat="YYYY/MM/DD"

                            hideDefaultValue={true}
                            language="en"
                            placeholder={convertToNep(monthsBack)}
                            values={reportDateValue.fromDate}
                            onChange={handleFormDate}
                        />
                    </div>

                    <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                        <label className="list-label">To Date</label>
                        <Calendar
                            className="form-control form-control-sm"
                            dateFormat="YYYY/MM/DD"

                            language="en"
                            values={reportDateValue.toDate}
                            onChange={handleToDate}
                        />
                    </div> */}

          <div
            className="col-lg-2 offset-lg-10 offset-md-10 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
            style={{
              marginTop: "4px",
              paddingRight: "1px",
              marginBottom: "10px",
            }}
          >
            <div className="d-flex">
              <input
                placeholder="Search"
                ref={searchInput}
                type="text"
                className="form-control form-control-sm searchField"
                onChange={searchHandler}
              />
            </div>
          </div>
        </div>
        {loading ? (
          <Spinner />
        ) : (
          <>
            {accountReportList.length > 0 ? (
              <div className="table-responsive br-3">
                <table className="table table-bordereds text-nowrap mb-0">
                  <thead>
                    <tr className="tableHead">
                      <td className="tc" style={{ width: "70px" }}>
                        S.N.
                      </td>
                      <td className="tl" style={{ width: "145px" }}>
                        Date
                      </td>
                      <td className="tl">Particulars</td>
                      <td className="tr" style={{ width: "150px" }}>
                        Debit
                      </td>
                      <td className="tr" style={{ width: "150px" }}>
                        Credit
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {accountReportList.map((item, i) => (
                      <tr key={i}>
                        <td className="tc" style={{ width: "70px" }}>
                          {i + 1}
                        </td>
                        <td className="tl">{item.Date}</td>
                        <td className="tl">{item.Particulars}</td>
                        <td className="tr">
                          {parseFloat(item.Debit).toFixed(2)}
                        </td>
                        <td className="tr">
                          {parseFloat(item.Credit).toFixed(2)}
                        </td>
                      </tr>
                    ))}
                    <tr className="lastt">
                      <td colspan="3">
                        <b style={{ paddingLeft: "15px" }}>Total</b>
                      </td>
                      <td className="tr">
                        <b>{debit.toFixed(2)}</b>
                      </td>
                      <td className="tr ">
                        <b>{credit.toFixed(2)}</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="table-responsive br-3">
                <div className="text-center mt-4">
                  <p>There are no records to display.</p>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}
