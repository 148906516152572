import React, { useContext, useEffect, useState } from "react";
import ExamRoutineContext from "../../contextfolder/ExamRoutineContext/ExamRoutineContext";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { Fetchdata } from "../../hooks/getData";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";

export default function ExamRoutineForm({ closePopup }) {
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const { appURL } = useContext(SidebarContext);

  const context = useContext(ExamRoutineContext);

  const {
    toggleEPopup,
    examFormError,
    setExamFormError,
    eIsSubmit,
    setEIsSubmit,
    examValue,
    examFormValue,
    setExamFormValue,
    fetchdata,
    eCloseChecked,
    setECloseChecked,
  } = context;

  const handleOnChange = () => {
    setECloseChecked(!eCloseChecked);
  };

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setExamFormValue({ ...examFormValue, [name]: value });
  };

  const handleExamDate = ({ adDate }) => {
    setExamFormValue({ ...examFormValue, examDate: adDate });
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setExamFormError(validate(examFormValue));
    setEIsSubmit(true);
  };
  useEffect(() => {
    if (Object.keys(examFormError).length === 0 && eIsSubmit) {
      fetchdata(examFormValue);
      if (eCloseChecked === true) {
        toggleEPopup();
      }
      setExamFormValue(examValue);
      setEIsSubmit(false);
    }
  }, [examFormError]);

  const validate = (values) => {
    const errors = {};
    if (!values.class) {
      errors.class = "Required";
    }
    if (!values.terminal) {
      errors.terminal = "Required";
    }
    if (!values.day) {
      errors.day = "Required";
    }

    if (!values.fromTime) {
      errors.fromTime = "Required";
    }
    if (!values.examDate) {
      errors.examDate = "Required";
    }
    if (!values.toTime) {
      errors.toTime = "Required";
    }

    if (!values.subject) {
      errors.subject = "Required";
    }
    if (!values.examShift) {
      errors.examShift = "Required";
    }
    if (!values.roomNo) {
      errors.roomNo = "Required";
    }

    return errors;
  };

  const [classList, setClassList] = useState([]);
  const [termList, setTermList] = useState([]);

  //API to hit class list
  useEffect(() => {
    getClassList();
  }, []);

  const getClassList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      DFlag: "C",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setClassList(postResult);
      } else {
        setClassList([]);
      }
    });
  };

  //API to get term list
  useEffect(() => {
    getTermList();
  }, []);

  const getTermList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      DFlag: "T",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";

        setTermList(postResult);
      } else {
        setTermList([]);
      }
    });
  };

  //API to hit subject List
  const [subjectList, setSubjectList] = useState([]);

  useEffect(() => {
    getSubjectList();
  }, [examFormValue.class]);

  const getSubjectList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      ClassID: examFormValue.class,
      DFlag: "SB",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";

        setSubjectList(postResult);
      } else {
        setSubjectList([]);
      }
    });
  };

  return (
    <>
      <div className="container-fluid basicform">
        <form>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label className="form-label" htmlFor="class">
                Class<sup style={{ color: "red" }}>*</sup>
              </label>
              <select
                id="class"
                name="class"
                onChange={handleChange}
                value={examFormValue.class}
                className="form-select form-select-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="" selected disabled>
                  Select Option
                </option>
                {classList.map((item, i) => (
                  <option key={i} value={item.ClassID}>
                    {item.ClassName}
                  </option>
                ))}
              </select>
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {examFormError.class}
              </p>
            </div>

            <div className="form-group col-md-6">
              <label className="form-label" htmlFor="terminal">
                Terminal<sup style={{ color: "red" }}>*</sup>
              </label>
              <select
                id="terminal"
                name="terminal"
                onChange={handleChange}
                value={examFormValue.terminal}
                className="form-select form-select-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="" selected disabled>
                  Select Option
                </option>
                {termList.map((item, i) => (
                  <option key={i} value={item.TermID}>
                    {item.TermName}
                  </option>
                ))}
              </select>
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {examFormError.terminal}
              </p>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-6">
              <label
                className="form-label"
                htmlFor="examDate"
                style={{ fontSize: "12px" }}
              >
                Exam Date<sup style={{ color: "red" }}>*</sup>
              </label>
              <Calendar
                className="form-control form-control-sm mb-1"
                dateFormat="YYYY/MM/DD"
                theme="default"
                language="en"
                values={examFormValue.examDate}
                onChange={handleExamDate}
              />
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {examFormError.examDate}
              </p>
            </div>

            <div className="form-group col-md-6">
              <label className="form-label" htmlFor="examShift">
                Shift <sup style={{ color: "red" }}>*</sup>
              </label>
              <select
                id="examShift"
                name="examShift"
                onChange={handleChange}
                value={examFormValue.examShift}
                className="form-select form-select-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="" selected disabled>
                  Select Option
                </option>
                <option value="M">Morning</option>
                <option value="D">Day</option>
                <option value="E">Evening</option>
                <option value="N">Night</option>
              </select>
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {examFormError.examShift}
              </p>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-6">
              <label className="form-label" htmlFor="subject">
                Subject<sup style={{ color: "red" }}>*</sup>
              </label>
              <select
                id="subject"
                name="subject"
                onChange={handleChange}
                value={examFormValue.subject}
                className="form-select form-select-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="" selected disabled>
                  Select Option
                </option>
                {subjectList.map((item, i) => (
                  <option key={i} value={item.SubjectID}>
                    {item.SubjectName}
                  </option>
                ))}
              </select>
              <p className="errormsg" style={{ marginBottom: "0" }}>
                {examFormError.subject}
              </p>
            </div>

            <div className="form-group col-md-6">
              <label className="form-label" htmlFor="day">
                Day <sup style={{ color: "red" }}>*</sup>
              </label>
              <select
                id="day"
                name="day"
                onChange={handleChange}
                value={examFormValue.day}
                className="form-select form-select-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="" selected disabled>
                  Select Option
                </option>
                <option value="1">Sunday</option>
                <option value="2">Monday</option>
                <option value="3">Tuesday</option>
                <option value="4">Wednesday</option>
                <option value="5">Thursday</option>
                <option value="6">Friday</option>
                <option value="7">Saturday</option>
              </select>
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {examFormError.day}
              </p>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-2">
              <label
                className="form-label"
                htmlFor="roomNo"
                style={{ fontSize: "12px" }}
              >
                Room No.<sup style={{ color: "red" }}>*</sup>
              </label>
              <input
                id="roomNo"
                style={{ fontSize: "13px" }}
                type="text"
                className="form-control form-control-sm "
                name="roomNo"
                value={examFormValue.roomNo}
                onChange={handleChange}
              />
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {examFormError.roomNo}
              </p>
            </div>

            <div className="form-group col-md-5">
              <label
                className="form-label"
                htmlFor="fromTime"
                style={{ fontSize: "12px" }}
              >
                From Time<sup style={{ color: "red" }}>*</sup>
              </label>
              <input
                id="fromTime"
                style={{ fontSize: "13px" }}
                type="time"
                className="form-control form-control-sm "
                name="fromTime"
                value={examFormValue.fromTime}
                onChange={handleChange}
              />
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {examFormError.fromTime}
              </p>
            </div>

            <div className="form-group col-md-5">
              <label
                className="form-label"
                htmlFor="toTime"
                style={{ fontSize: "12px" }}
              >
                To Time<sup style={{ color: "red" }}>*</sup>
              </label>
              <input
                id="toTime"
                style={{ fontSize: "13px" }}
                type="time"
                className="form-control form-control-sm "
                name="toTime"
                value={examFormValue.toTime}
                onChange={handleChange}
              />
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {examFormError.toTime}
              </p>
            </div>
          </div>
        </form>
      </div>
      <div className="FormFooter">
        <div className="checkedClose">
          <input
            type="checkbox"
            className="form-check-input"
            id="exampleCheck2"
            name="closeChecked"
            onChange={handleOnChange}
            checked={eCloseChecked}
          />
          <label class="form-check-label" for="exampleCheck2">
            Exit after submit
          </label>
        </div>
        <div>
          <button
            className="btn btn-sm btn-cmpy ml-2"
            style={{ background: "green" }}
            onClick={formSubmit}
          >
            Submit
          </button>
          <button className="btn btn-sm btn-cmpy ml-2" onClick={closePopup}>
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}
