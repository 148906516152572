import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import SidebarContext from "../sidebarcontext/SidebarContext";
import Spinner from "../loading/spinner";
import AccountContext from "../contextfolder/AccountContext/AccountContext";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";

import { convertToNep } from "../hooks/dateConvertor";
import Tippy from "@tippyjs/react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { formatNumber } from "../hooks/drCr";

export default function AccountAll() {
  const { todayDate, customStyles, newMonth, userinfo } =
    useContext(SidebarContext);

  const {
    loading,
    originalList,
    classLists,
    sectionLists,
    selectClasses,
    setSelectClasses,
    selectSections,
    setSelectSections,
    accountAllList,
    setAccountAllList,
    allSearch,
  } = useContext(AccountContext);

  const handleClass = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectClasses(value);
    setSelectSections("");
  };

  const handleSection = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectSections(value);
  };

  const searchInput = useRef("");

  let credit = accountAllList.reduce(function (prev, current) {
    return prev + current.Credit;
  }, 0);

  let debit = accountAllList.reduce(function (prev, current) {
    return prev + current.Debit;
  }, 0);

  const columns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "Student",
      selector: (row) => row.Student,
    },
    {
      name: "Debit",
      width: "150px",
      right: true,
      selector: (row) => parseFloat(row.Debit).toFixed(2),
    },
    {
      name: "Credit",
      width: "150px",
      right: true,
      selector: (row) => parseFloat(row.Credit).toFixed(2),
    },
    {
      name: "Balance",
      width: "160px",
      right: true,
      selector: (row) => parseFloat(row.Debit - row.Credit).toFixed(2),
    },
  ];

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = allSearch.filter((list) => {
        return list["Student"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setAccountAllList(srchResult);
      } else {
        setAccountAllList({});
      }
    } else {
      setAccountAllList(allSearch);
    }
  };

  /* This is for export csv */

  const dataToExcel = accountAllList.map((d, i) => ({
    "S.N.": i + 1,
    Student: d.Student,
    Debit: d.Debit,
    Credit: d.Credit,
  }));

  const toExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(dataToExcel);
      var wscols = [{ wch: 5 }, { wch: 20 }, { wch: 15 }, { wch: 15 }];
      worksheet["!cols"] = wscols;
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "Account_All");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_" + "export" + EXCEL_EXTENSION
        );
      }
    });
  };

  //end

  //This is to export pdf

  const dataWithIndex = accountAllList.map((d, i) => ({
    ...d,
    Index: i + 1,
  }));

  const cols = [
    { header: "S.N", field: "Index" },
    { header: "Student", field: "Student" },
    { header: "Debit", field: "Debit" },
    { header: "Credit", field: "Credit" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const toPdf = () => {
    const doc = new jsPDF("p", "pt", "a4", true);

    var midPage = doc.internal.pageSize.getWidth() / 2;

    // doc.addImage(`${userinfo.SchPhoto}`, "JPG", 40, 40, 70, 60);

    doc.setFontSize(18);
    doc.text(`${userinfo.SchName}`, midPage, 50, null, null, "center");

    doc.setFontSize(15);
    doc.text(`${userinfo.SchAddress}`, midPage, 68, null, null, "center");

    doc.setFontSize(10);
    doc.text(
      `Tel:${userinfo.Phone}, E-mail:${userinfo.Email}`,
      midPage,
      85,
      null,
      null,
      "center"
    );

    doc.autoTable({
      startY: 100,
      theme: "grid",
      columns: exportColumns,
      body: dataWithIndex,
    });
    doc.save("Account_All.pdf");
  };
  //end

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />

      <div className="sec-dataTable">
        <div className="upper-dataTbl">
          <Tippy content="PDF">
            <button className="exportButtons ml-1" onClick={toPdf}>
              <i className="bi bi-file-earmark-pdf-fill exportButtonIconsPdf"></i>
            </button>
          </Tippy>

          <Tippy content="Excel">
            <button className="exportButtons" onClick={toExcel}>
              <i className="bi bi-file-earmark-excel-fill exportButtonIconsExcel"></i>
            </button>
          </Tippy>
        </div>
        <hr className="new-hr-new" />

        <div className="row" style={{ margin: "0" }}>
          <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
            <label className="list-label">Class</label>
            <select
              style={{ fontSize: "11px", marginBottom: "3px" }}
              name="snotifiaction"
              value={selectClasses}
              onChange={handleClass}
              className="form-control form-control-sm"
              aria-label="Default select example "
            >
              <option value="" disabled selected style={{ fontSize: "11px" }}>
                Select Class
              </option>
              {classLists.map((item) => (
                <option key={item.ClassID} value={item.ClassID}>
                  {item.ClassName}
                </option>
              ))}
            </select>
            <i class="fas fa-angle-down  notificationpositon"></i>
          </div>

          <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
            <label className="list-label">Section</label>
            <select
              style={{ fontSize: "11px", marginBottom: "3px" }}
              name="snotifiaction"
              value={selectSections}
              onChange={handleSection}
              className="form-control form-control-sm"
              aria-label="Default select example "
            >
              <option value="" disabled selected style={{ fontSize: "11px" }}>
                Select Section
              </option>
              {sectionLists.map((item) => (
                <option key={item.SectionID} value={item.SectionID}>
                  {item.SectionName}
                </option>
              ))}
            </select>
            <i class="fas fa-angle-down  notificationpositon"></i>
          </div>

          <div
            className="col-lg-2 offset-lg-6 offset-md-6 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
            style={{
              marginTop: "20px",
              paddingRight: "1px",
              marginBottom: "16px",
            }}
          >
            <div className="d-flex">
              <input
                placeholder="Search"
                ref={searchInput}
                type="text"
                className="form-control form-control-sm searchField"
                onChange={searchHandler}
              />
            </div>
          </div>
        </div>

        {loading ? (
          <Spinner />
        ) : (
          <>
            {accountAllList.length > 0 ? (
              <div className="table-responsive br-3">
                <table className="table table-bordereds table-striped text-nowrap mb-0">
                  <thead>
                    <tr className="tableHead">
                      <td className="tc" style={{ width: "70px" }}>
                        S.N.
                      </td>
                      <td className="tl">Student</td>
                      <td className="tr" style={{ width: "150px" }}>
                        Debit
                      </td>
                      <td className="tr" style={{ width: "150px" }}>
                        Credit
                      </td>
                      <td className="tr" style={{ width: "150px" }}>
                        Balance
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {accountAllList.map((item, i) => (
                      <tr key={i}>
                        <td className="tc" style={{ width: "70px" }}>
                          {i + 1}
                        </td>
                        <td className="tl">{item.Student}</td>
                        <td className="tr">
                          {parseFloat(item.Debit).toFixed(2)}
                        </td>
                        <td className="tr">
                          {parseFloat(item.Credit).toFixed(2)}
                        </td>
                        <td className="tr">
                          {formatNumber(
                            parseFloat(item.Debit - item.Credit).toFixed(2)
                          )}
                        </td>
                      </tr>
                    ))}
                    <tr className="lastt">
                      <td colspan="2">
                        <b style={{ paddingLeft: "15px" }}>Total</b>
                      </td>
                      <td className="tr">
                        <b>{debit.toFixed(2)}</b>
                      </td>
                      <td className="tr ">
                        <b>{credit.toFixed(2)}</b>
                      </td>
                      <td className="tr ">
                        <b>{formatNumber((debit - credit).toFixed(2))}</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="table-responsive br-3">
                <div className="text-center mt-4">
                  <p>There are no records to display.</p>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}
