import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";

import "tippy.js/dist/tippy.css";
import { Fetchdata } from "../hooks/getData";
import AdminContext from "../contextfolder/AdminContext/AdminContext";

export default function RolePermission() {
  const { todayDate, customStyles, AcademicYear, appURL } =
    useContext(SidebarContext);
  const { loading, originalList, adminList, setAdminList } =
    useContext(AdminContext);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  //API to hit Admin list
  const [roleList, setRoleList] = useState([]);

  useEffect(() => {
    roleLst();
  }, []);

  const roleLst = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      Flag: "S",
      MemID: "-1",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/admin-permission`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.teachingres ? result.teachingres : "";
        setRoleList(postResult);
      } else {
        setRoleList([]);
      }
    });
  };

  const columns = [
    "Super Admin",
    "Account",
    "Report Card",
    "Monthly Report",
    "Online Payment Report",
    "SMS",
  ];

  const [selectedOptions, setSelectedOptions] = useState({});

  useEffect(() => {
    handleRole();
  }, [selectedOptions]);

  const handleRole = () => {
    const params = selectedOptions;

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        setSelectedOptions({});
        roleLst();
      } else {
        setSelectedOptions({});
      }
    });
  };

  const searchInput = useRef("");

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["TeacherName"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setAdminList(srchResult);
      } else {
        setAdminList({});
      }
    } else {
      setAdminList(originalList);
    }
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />

      <>
        <div className="sec-dataTable ">
          <>
            <div
              className="col-lg-2 offset-lg-10 offset-md-10 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
              style={{ paddingRight: "1px", marginBottom: "10px" }}
            >
              <div className="d-flex">
                <input
                  placeholder="Search"
                  ref={searchInput}
                  type="text"
                  className="form-control form-control-sm searchField"
                  onChange={searchHandler}
                />
              </div>
            </div>
          </>
          {loading ? (
            <Spinner />
          ) : (
            <>
              {adminList.length > 0 ? (
                <div className="table-responsive br-3">
                  <table
                    className="table table-striped table-bordereds text-nowrap mb-0"
                    id="table-to-xls"
                  >
                    <tr className="tableHead">
                      <td
                        className="rdt_TableHeadRow tableHead text-center"
                        width="70px"
                        style={{ verticalAlign: "middle", borderBottom: "0px" }}
                      >
                        S.N.
                      </td>
                      <td
                        className="rdt_TableHeadRow tableHead text-start"
                        width="200px"
                        style={{ verticalAlign: "middle", borderBottom: "0px" }}
                      >
                        Admin
                      </td>

                      {columns.map((date) => {
                        return (
                          <td
                            className="rdt_TableHeadRow tableHead text-center"
                            width="max-content"
                            style={{
                              verticalAlign: "middle",
                              borderBottom: "0px",
                            }}
                          >
                            {date}
                          </td>
                        );
                      })}
                    </tr>

                    {adminList.map((data, i) => {
                      return (
                        <>
                          <tr>
                            <td
                              className="rdt_TableCell tablecell  text-center"
                              style={{
                                verticalAlign: "middle",
                              }}
                            >
                              {i + 1}
                            </td>

                            <td
                              className="rdt_TableCell tablecell text-start"
                              style={{
                                verticalAlign: "middle",
                              }}
                            >
                              {data.TeacherName}
                            </td>

                            {columns.map((column, j) => {
                              const columnKey = column.replace(/\s/g, ""); // Remove spaces from the column name
                              const checkboxId = `${data.TeacherName}-${columnKey}`; // Unique identifier for each checkbox
                              const isChecked = roleList.some(
                                (role) =>
                                  role.UserID === data.TeacherID &&
                                  role.Permission === columnKey.toUpperCase()
                              ); // Check if the admin has the permission for this column

                              return (
                                <td
                                  className=" rdt_TableCell tablecell text-center"
                                  style={{
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <div className="">
                                    <input
                                      type="checkbox"
                                      name={checkboxId}
                                      id={checkboxId}
                                      checked={!isChecked} // Set checked attribute based on isSelected value
                                      className="me-2"
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      onChange={(e) => {
                                        const isChecked = e.target.checked;

                                        if (!isChecked) {
                                          setSelectedOptions({
                                            SchID: userinfo.SchID,
                                            UserID: userinfo.UserID,
                                            Flag: "i",
                                            Permission: columnKey.toUpperCase(),
                                            MemID: data.TeacherID.toString(),
                                            Type: "POST",
                                            Academicyear: userinfo.AcademicYear,
                                            FetchURL: `${appURL}api/admin/admin-permission`,
                                          });
                                        } else {
                                          setSelectedOptions({
                                            SchID: userinfo.SchID,
                                            UserID: userinfo.UserID,
                                            Flag: "R",
                                            Permission: columnKey.toUpperCase(),
                                            MemID: data.TeacherID.toString(),
                                            Type: "POST",
                                            Academicyear: userinfo.AcademicYear,
                                            FetchURL: `${appURL}api/admin/admin-permission`,
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                </td>
                              );
                            })}
                          </tr>
                        </>
                      );
                    })}
                  </table>
                </div>
              ) : (
                <p className="text-center">No data</p>
              )}
            </>
          )}
        </div>
      </>
    </>
  );
}
