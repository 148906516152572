import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import SidebarContext from "../sidebarcontext/SidebarContext";
import Spinner from "../loading/spinner";
import ReportContext from "../contextfolder/ReportContext/ReportContext";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import AcademicHeader from "../AcademicHeader";

export default function Report() {
  const { todayDate, customStyles, AcademicYear } = useContext(SidebarContext);

  const {
    loading,
    originalList,
    classList,
    sectionList,
    selectClass,
    setSelectClass,
    selectSection,
    setSelectSection,
    reportList,
    setReportList,
    selectTerm,
    setSelectTerm,
    termList,
    selectType,
    setSelectType,
    selectStatus,
    setSelectStatus,
    deactivateAgree,
  } = useContext(ReportContext);

  const handleClass = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectClass(value);
  };

  const handleSection = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectSection(value);
  };

  const handleTerm = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectTerm(value);
  };

  const handleType = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectType(value);
  };

  const handleStatus = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectStatus(value);
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const searchInput = useRef("");

  const columns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "Student Name",
      selector: (row) => row.FullName,
    },
    {
      name: "Terminal",
      width: "150px",
      selector: (row) => row.TermName,
    },
    {
      name: "Status",
      width: "100px",
      center: true,
      // selector: (row) => row.IsAllow === "Y" ? "Allowed" : "Disallowed",
      selector: (row) => {
        return (
          <button
            type="button"
            class="badge badge-primary"
            onClick={() => changeAgree(row.ReportID, row.IsAllow)}
          >
            {checkAgree(row.IsAllow)}
          </button>
        );
      },
    },
    {
      name: "Action",
      center: true,
      width: "80px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <Tippy content="View">
                <i
                  className="bi bi-eye  solvespan--action"
                  style={{ paddingTop: "3px" }}
                  onClick={() => openInNewTab(row.ReportUrl)}
                ></i>
              </Tippy>

              {/* {row.IsAllow === "Y" ?

                                <Tippy content="Allowed">
                                    <i
                                        className="fa fa-toggle-on toggle--button--action"
                                        onClick={() => changeAgree(row.ReportID, row.IsAllow)}
                                    >
                                    </i>
                                </Tippy> :
                                <Tippy content="Disallowed">
                                    <i
                                        className="fa fa-toggle-off toggle--button--action"
                                        onClick={() => changeAgree(row.ReportID, row.IsAllow)}
                                    >
                                    </i>
                                </Tippy>
                            } */}
            </div>
          </>
        );
      },
    },
  ];

  // For change agree and disagree

  const changeAgree = (repID, IsActive) => {
    deactivateAgree(repID, IsActive);
  };

  const checkAgree = (IsActive) => {
    if (IsActive === "Y") {
      return "Disallow";
    } else if (IsActive === "N") {
      return "Allow";
    }
  };

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["FullName"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setReportList(srchResult);
      } else {
        setReportList({});
      }
    } else {
      setReportList(originalList);
    }
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
        <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">Report</div>
            <div className="page-date">
              <div className="sec-content">
                <AcademicHeader
                  todayDate={todayDate}
                  AcademicYear={AcademicYear}
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <>
          <div className="sec-dataTable content-section main-content">
            {loading ? (
              <Spinner />
            ) : (
              <DataTable
                columns={columns}
                data={reportList}
                customStyles={customStyles}
                pagination
                fixedHeader
                fixedHeaderScrollHeight="280px"
                highlightOnHover
                pointerOnHover
                responsive
                dense
                striped
                subHeader
                subHeaderAlign="left"
                subHeaderComponent={
                  <>
                    <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                      <label className="list-label">Class</label>
                      <select
                        style={{ fontSize: "11px", marginBottom: "3px" }}
                        name="snotifiaction"
                        value={selectClass}
                        onChange={handleClass}
                        className="form-control form-control-sm"
                        aria-label="Default select example "
                      >
                        <option
                          value=""
                          disabled
                          selected
                          style={{ fontSize: "11px" }}
                        >
                          Select Class
                        </option>
                        <option value="-1">All</option>
                        {classList.map((item) => (
                          <option key={item.ClassID} value={item.ClassID}>
                            {item.ClassName}
                          </option>
                        ))}
                      </select>
                      <i class="fas fa-angle-down  notificationpositon"></i>
                    </div>

                    <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                      <label className="list-label">Section</label>
                      <select
                        style={{ fontSize: "11px", marginBottom: "3px" }}
                        name="snotifiaction"
                        value={selectSection}
                        onChange={handleSection}
                        className="form-control form-control-sm"
                        aria-label="Default select example "
                      >
                        <option
                          value=""
                          disabled
                          selected
                          style={{ fontSize: "11px" }}
                        >
                          Select Section
                        </option>
                        <option value="-1">All</option>

                        {sectionList.map((item) => (
                          <option key={item.SectionID} value={item.SectionID}>
                            {item.SectionName}
                          </option>
                        ))}
                      </select>
                      <i class="fas fa-angle-down  notificationpositon"></i>
                    </div>

                    <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                      <label className="list-label">Term</label>
                      <select
                        style={{ fontSize: "11px", marginBottom: "3px" }}
                        name="snotifiaction"
                        value={selectTerm}
                        onChange={handleTerm}
                        className="form-control form-control-sm"
                        aria-label="Default select example "
                      >
                        <option
                          value=""
                          disabled
                          selected
                          style={{ fontSize: "11px" }}
                        >
                          Select Term
                        </option>
                        {termList.map((item) => (
                          <option key={item.TermID} value={item.TermID}>
                            {item.TermName}
                          </option>
                        ))}
                      </select>
                      <i class="fas fa-angle-down  notificationpositon"></i>
                    </div>

                    {/* <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                                            <label className="list-label">Type</label>
                                            <select
                                                style={{ fontSize: "11px", marginBottom: "3px" }}
                                                name="snotifiaction"
                                                value={selectType}
                                                onChange={handleType}
                                                className="form-control form-control-sm"
                                                aria-label="Default select example "
                                            >
                                                <option
                                                    value=""
                                                    disabled
                                                    selected
                                                    style={{ fontSize: "11px" }}
                                                >
                                                    Select Type
                                                </option>
                                                <option value="M">Marksheet</option>
                                                <option value="G">GradeSheet</option>
                                            </select>
                                            <i class="fas fa-angle-down  notificationpositon"></i>
                                        </div> */}

                    <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                      <label className="list-label">Status</label>
                      <select
                        style={{ fontSize: "11px", marginBottom: "3px" }}
                        name="snotifiaction"
                        value={selectStatus}
                        onChange={handleStatus}
                        className="form-control form-control-sm"
                        aria-label="Default select example "
                      >
                        <option
                          value=""
                          disabled
                          selected
                          style={{ fontSize: "11px" }}
                        >
                          Select Status
                        </option>
                        <option value="-1">All</option>
                        <option value="Y">Allow</option>
                        <option value="N">Disallow</option>
                      </select>
                      <i class="fas fa-angle-down  notificationpositon"></i>
                    </div>

                    <div
                      className="col-lg-2 offset-lg-2 offset-md-2 col-md-2 col-sm-2 dropdown2"
                      style={{
                        marginTop: "25px",
                        paddingRight: "1px",
                        marginBottom: "10px",
                      }}
                    >
                      <div className="d-flex">
                        <input
                          placeholder="Search"
                          ref={searchInput}
                          type="text"
                          className="form-control form-control-sm searchField"
                          onChange={searchHandler}
                        />
                      </div>
                    </div>
                  </>
                }
              />
            )}
          </div>
        </>
      </div>
    </>
  );
}
