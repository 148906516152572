import React, { useEffect } from "react";
import img1 from "./../../images/school-img.png";
import Aos from "aos";
const About = ({ schoolInfo }) => {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div className="section about__section" id="about__section">
      <div className="uk-container">
        <div className="uk-grid uk-child-width-1-2@m" data-uk-grid>
          <div>
            <div
              class=" uk-text-left"
              data-aos="fade-up"
              data-aos-anchor-placement="center-bottom"
              data-aos-duration="1000"
            >
              <h2
                className="uk-text-left the__title small"
                style={{
                  color: `${schoolInfo.ScColor}`,
                }}
              >
                About Us
              </h2>
            </div>

            <div className="the__content mt-3">
              <p className="mt-0">{schoolInfo.AboutUs}</p>
            </div>
            <h2
              data-aos="fade-up"
              data-aos-anchor-placement="center-bottom"
              data-aos-duration="1000"
              className="mt-5 uk-text-left the__title small"
              style={{
                color: `${schoolInfo.ScColor}`,
              }}
            >
              Message from Principal
            </h2>
            <h3 className="the__title uk-text-left">{schoolInfo.Msg}</h3>
          </div>

          <div>
            <div className="about__img">
              <img src={img1} alt="About School" />
              {/* <img src={schoolInfo.SchPhoto} alt="About School" /> */}

              <div
                className="about__bg1"
                style={{
                  background: `${schoolInfo.ScColor}`,
                  opacity: 0.2,
                }}
              ></div>
              <div
                className="about__bg2"
                style={{
                  background: `${schoolInfo.ScColor}`,
                  opacity: 0.2,
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
