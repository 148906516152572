import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import SidebarContext from "../sidebarcontext/SidebarContext";
import Spinner from "../loading/spinner";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { Fetchdata } from "../hooks/getData";
import AcademicHeader from "../AcademicHeader";

export default function StudentOnlineClass() {
  const { todayDate, customStyles, AcademicYear, appURL } =
    useContext(SidebarContext);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const searchInput = useRef("");

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const columns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "Tool",

      selector: (row) =>
        row.Tool === "G"
          ? "Google Meet"
          : row.Tool === "T"
          ? "Microsoft Team"
          : row.Tool === "M"
          ? "Messenger"
          : row.Tool === "Z"
          ? "Zoom"
          : row.Tool === "J"
          ? "Jitsi"
          : "",
    },
    {
      name: "Subject",

      selector: (row) => row.Subject,
    },
    {
      name: "Teacher",

      selector: (row) => row.MemName,
    },

    {
      name: "Action",

      center: true,
      width: "100px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex" disabled={row.IsActive === "N"}>
              {/* <Tippy content="Start">
                                <i

                                    className={row.IsActive === "N" ? "bi bi-skip-start solvespan--action--disabled" : "bi bi-skip-start solvespan--action"}
                                >
                                </i>
                            </Tippy> */}
              <Tippy content="Start">
                <i
                  style={{ paddingTop: "3px" }}
                  className={
                    row.IsActive === "N"
                      ? "bi bi-arrow-right-circle solvespan--action--disabled"
                      : "bi bi-arrow-right-circle solvespan--action"
                  }
                  onClick={() => openInNewTab(row.MeetingUrl)}
                ></i>
              </Tippy>
            </div>
          </>
        );
      },
    },
  ];

  const [onlineClassList, setOnlineClassList] = useState([]);

  useEffect(() => {
    getReportList();
  }, []);

  const getReportList = () => {
    const params = {
      SchID: userinfo.SchID,
      Flag: "S",
      ClassID: userinfo.ClassID.toString(),
      SectionID: userinfo.SectionID.toString(),
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/online-class`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.OnlineClassLst ? result.OnlineClassLst : "";
        setLoading(false);
        setOnlineClassList(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        setOnlineClassList([]);
        setOriginalList([]);
      }
    });
  };

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["Subject"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setOnlineClassList(srchResult);
      } else {
        setOnlineClassList({});
      }
    } else {
      setOnlineClassList(originalList);
    }
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
        <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">Online Class</div>
            <div className="page-date">
              <div className="sec-content">
                <AcademicHeader
                  todayDate={todayDate}
                  AcademicYear={AcademicYear}
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <>
          <div className="sec-dataTable content-section main-content">
            {loading ? (
              <Spinner />
            ) : (
              <DataTable
                columns={columns}
                data={onlineClassList}
                customStyles={customStyles}
                pagination
                fixedHeader
                fixedHeaderScrollHeight="280px"
                highlightOnHover
                pointerOnHover
                responsive
                dense
                striped
                subHeader
                subHeaderAlign="left"
                subHeaderComponent={
                  <>
                    <div
                      className="col-lg-2 offset-lg-10 offset-md-10 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
                      style={{ paddingRight: "1px", marginBottom: "10px" }}
                    >
                      <div className="d-flex">
                        <input
                          placeholder="Search"
                          ref={searchInput}
                          type="text"
                          className="form-control form-control-sm searchField"
                          onChange={searchHandler}
                        />
                      </div>
                    </div>
                  </>
                }
              />
            )}
          </div>
        </>
      </div>
    </>
  );
}
