import React from "react";
import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "../css/Sidebarone.css";
import classes from "../css/abs.css";

const SidebarItem = ({ item, handleDrawer }) => {
  const [open, setOpen] = useState(false);
  if (item.childrens) {
    return (
      <div className={open ? "sidebar-item open" : "sidebar-item"}>
        <div className="sidebar-title" onClick={() => setOpen(!open)}>
          <span>
            {item.icon && <i className={item.icon}></i>}
            {/* <i className="fa fa-users mx-3 icon" ></i> */}
            {item.title}
          </span>
          <i
            className={open ? "fas fa-angle-up" : "fas fa-angle-down"}
            style={{ top: "4px", position: "relative" }}
          ></i>
        </div>
        <div className="sidebar-content" onClick={handleDrawer}>
          {item.childrens.map((child, i) => (
            <SidebarItem key={i} item={child} />
          ))}
        </div>
      </div>
    );
  } else if (!item.title && !item.icon && !item.path) {
    return <div style={{ display: "none" }}></div>;
  } else {
    return (
      <NavLink
        activeclassname={classes.active}
        to={item.path}
        className="sidebar-item plain arrow"
        onClick={handleDrawer}
      >
        <div className="sidebar-title">
          <span>
            {item.icon && <i className={item.icon}></i>}
            {item.title}
            {item.title === "Job" && <span className="menu__item__dot"></span>}
          </span>
        </div>
      </NavLink>
    );
  }
};

export default SidebarItem;
