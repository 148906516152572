import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import SidebarContext from "../sidebarcontext/SidebarContext";
import Spinner from "../loading/spinner";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { Fetchdata } from "../hooks/getData";
import AcademicHeader from "../AcademicHeader";

export default function StudentReport() {
  const { todayDate, customStyles, AcademicYear, appURL } =
    useContext(SidebarContext);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const searchInput = useRef("");

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);

  const columns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "Terminal",

      selector: (row) => row.TermName,
    },
    // {
    //     name: "Status",
    //     // grow: 0,
    //     center: true,
    //     selector: (row) => row.IsAllow === "Y" ? "Allowed" : "Disallowed",
    // },
    {
      name: "Action",
      center: true,
      width: "150px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <Tippy content="View">
                <i
                  className="bi bi-eye  solvespan--action"
                  style={{ paddingTop: "3px" }}
                  onClick={() => openInNewTab(row.ReportUrl)}
                ></i>
              </Tippy>
            </div>
          </>
        );
      },
    },
  ];

  const [reportList, setReportList] = useState([]);

  useEffect(() => {
    getReportList();
  }, []);

  const getReportList = () => {
    const params = {
      SchID: userinfo.SchID,
      ReportType: userinfo.MarksSheetType,
      MemID: userinfo.UserID.toString(),
      TermID: "-1",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/report-card`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.ReportCardLst ? result.ReportCardLst : "";
        setLoading(false);
        setReportList(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        setReportList([]);
        setOriginalList([]);
      }
    });
  };

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["TermName"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setReportList(srchResult);
      } else {
        setReportList({});
      }
    } else {
      setReportList(originalList);
    }
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
        <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">Report</div>
            <div className="page-date">
              <div className="sec-content">
                <AcademicHeader
                  todayDate={todayDate}
                  AcademicYear={AcademicYear}
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <>
          <div className="sec-dataTable content-section main-content">
            {loading ? (
              <Spinner />
            ) : (
              <DataTable
                columns={columns}
                data={reportList}
                customStyles={customStyles}
                pagination
                fixedHeader
                fixedHeaderScrollHeight="280px"
                highlightOnHover
                pointerOnHover
                responsive
                dense
                striped
                subHeader
                subHeaderAlign="left"
                subHeaderComponent={
                  <>
                    <div
                      className="col-lg-2 offset-lg-10 offset-md-10 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
                      style={{ paddingRight: "1px", marginBottom: "10px" }}
                    >
                      <div className="d-flex">
                        <input
                          placeholder="Search"
                          ref={searchInput}
                          type="text"
                          className="form-control form-control-sm searchField"
                          onChange={searchHandler}
                        />
                      </div>
                    </div>
                  </>
                }
              />
            )}
          </div>
        </>
      </div>
    </>
  );
}
