import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import '../../css/Commission.css';
import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";




export default function SubjectView() {
    const { subjectSearch, customStyles, loading, subjectList, setSubjectList, classList, selectClass, setSelectClass } = useContext(SidebarContext);

    const handleClass = (e) => {
        const target = e.target;
        const value = target.value;
        setSelectClass(value);
    };



    const searchInput = useRef("");

    const columns = [
        {
            name: "S.N.",
            width: "63px",
            center: true,
            cell: (row, index) => index + 1,
        },
        {
            name: "Subject",
            selector: (row) => row.SubjectName,
        },

    ];





    const searchHandler = (e) => {
        e.preventDefault();

        const srchQuery = searchInput.current.value.toLowerCase();
        if (srchQuery) {

            let srchResult = subjectSearch.filter((list) => {
                return list["SubjectName"].toLowerCase().includes(srchQuery);
            });

            if (srchResult) {

                setSubjectList(srchResult);
            } else {
                setSubjectList({});
            }
        } else {
            setSubjectList(subjectSearch);
        }
    };

    return (


        <>
            <div className="sec-dataTable">

                {loading ? (
                    <Spinner />
                ) : (
                    <DataTable
                        columns={columns}
                        data={subjectList}
                        customStyles={customStyles}
                        pagination
                        fixedHeader
                        fixedHeaderScrollHeight="280px"
                        highlightOnHover
                        pointerOnHover
                        responsive
                        dense
                        striped
                        subHeader
                        subHeaderAlign="left"
                        subHeaderComponent={
                            <>
                                <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                                    <label className="list-label">Class</label>
                                    <select
                                        style={{ fontSize: "11px", marginBottom: "3px" }}
                                        name="snotifiaction"
                                        value={selectClass}
                                        onChange={handleClass}
                                        className="form-control form-control-sm"
                                        aria-label="Default select example "
                                    >
                                        <option
                                            value=""
                                            disabled
                                            selected
                                            style={{ fontSize: "11px" }}
                                        >
                                            Select Class
                                        </option>
                                        {classList.map((item) => (
                                            <option
                                                key={item.ClassID}
                                                value={item.ClassID}
                                            >
                                                {item.ClassName}
                                            </option>
                                        ))}
                                    </select>
                                    <i class="fas fa-angle-down  notificationpositon"></i>
                                </div>
                                <div className="col-lg-2 offset-lg-8 offset-md-8 offset-sm-0 col-md-2 col-sm-2 align-self-end Search" style={{ marginTop: "25px", paddingRight: "1px", marginBottom: "10px" }}>
                                    <div className="d-flex">
                                        <input
                                            placeholder="Search"
                                            ref={searchInput}
                                            type="text"
                                            className="form-control form-control-sm searchField"
                                            onChange={searchHandler}
                                        />
                                    </div>
                                </div>
                            </>
                        }
                    />
                )}
            </div>
        </>
    );
}
