import React, { useContext, useEffect, useState } from "react";
import { Fetchdata } from "../../hooks/getData";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { toast } from "react-toastify";
import ExamRoutineContext from "./ExamRoutineContext";
import { GetCurrTime } from "../../hooks/dateConvertor";

function ExamRoutineState(props) {
  const { appURL } = useContext(SidebarContext);

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const [eCloseChecked, setECloseChecked] = useState(false);

  const examValue = {
    class: "",
    terminal: "",
    subject: "",
    day: "",
    examDate: "",
    examShift: "",
    roomNo: "",
    fromTime: "",
    toTime: "",
  };
  const [examFormValue, setExamFormValue] = useState(examValue);
  const [examFormError, setExamFormError] = useState({});
  const [eIsSubmit, setEIsSubmit] = useState(false);
  const [editIsSubmit, setEditIsSubmit] = useState(false);

  const [popup, setPopup] = useState(false);
  const toggleEPopup = () => {
    setPopup(!popup);
  };

  const [editPop, setEditPop] = useState(false);
  const toggleEditPopup = () => {
    setEditPop(!editPop);
  };

  const [classList, setClassList] = useState([]);
  const [termList, setTermList] = useState([]);

  const [selectClass, setSelectClass] = useState("");
  const [selectTerm, setSelectTerm] = useState("");

  //API to get class list

  useEffect(() => {
    getClassList();
  }, []);

  const getClassList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      DFlag: "C",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setLoading(false);
        setClassList(postResult);
      } else {
        setClassList([]);
      }
    });
  };

  //API to get term list
  useEffect(() => {
    getTermList();
  }, []);

  const getTermList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      DFlag: "T",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";

        setLoading(false);
        setTermList(postResult);
      } else {
        setTermList([]);
      }
    });
  };

  //API to create exam Routine
  const fetchdata = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "I",
      ClassID: examFormValue.class,
      SubjectID: examFormValue.subject,
      WeekID: examFormValue.day,
      TermID: examFormValue.terminal,
      ShiftType: examFormValue.examShift,
      StartTime: examFormValue.fromTime,
      EndTime: examFormValue.toTime,
      RoomNo: examFormValue.roomNo,
      ExamDate: examFormValue.examDate,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/exam-routine`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getExamRoutineList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };

  // API for exam routine list
  const [examRoutineList, setExamRoutineList] = useState([]);

  useEffect(() => {
    getExamRoutineList();
  }, [selectClass, selectTerm]);

  const getExamRoutineList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      Flag: "S",
      ClassID: selectClass,
      TermID: selectTerm,
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/exam-routine`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.RoutineLst ? result.RoutineLst : "";
        setLoading(false);
        setExamRoutineList(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        setExamRoutineList([]);
        setOriginalList([]);
      }
    });
  };

  const [routineId, setRoutineId] = useState("");

  const handleEdit = (data) => {
    setEditPop(!editPop);

    setRoutineId(data.RoutineID);
    setExamFormValue({
      class: data.ClassID.toString(),
      terminal: data.TermID,
      subject: data.SubjectID,
      day: data.WeekID,
      examDate: data.ExamDate,
      examShift: data.ShiftTypeID,
      roomNo: data.RoomNo,
      fromTime: data.StartTime,
      toTime: data.EndTime,
    });
    setEditIsSubmit(false);
  };

  //API to edit event
  const editdata = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "U",
      RoutineID: routineId.toString(),
      ClassID: examFormValue.class,
      SubjectID: examFormValue.subject,
      WeekID: examFormValue.day,
      TermID: examFormValue.terminal,
      ShiftType: examFormValue.examShift,
      StartTime: examFormValue.fromTime,
      EndTime: examFormValue.toTime,
      RoomNo: examFormValue.roomNo,
      ExamDate: examFormValue.examDate,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/exam-routine`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getExamRoutineList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };

  //Everything to pop up and delete the event list
  const [deletePopup, setDeletePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const [delData, setDelData] = useState();

  const handleDelete = async (data) => {
    setDelData(data);

    setDeletePopup({
      show: true,
      data,
    });
  };

  // This will perform the deletion and hide the Confirmation Box

  const handleDeleteTrue = async () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "R",
      RoutineID: delData.toString(),
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/exam-routine`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          getExamRoutineList();
          toast(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
        } else {
          toast(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
    });
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleDeleteFalse = () => {
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // Ends Here

  return (
    <ExamRoutineContext.Provider
      value={{
        loading,
        originalList,
        deletePopup,
        handleDeleteTrue,
        handleDeleteFalse,
        handleDelete,
        popup,
        setPopup,
        toggleEPopup,
        examFormValue,
        setExamFormValue,
        examFormError,
        setExamFormError,
        eIsSubmit,
        setEIsSubmit,
        examValue,
        setExamRoutineList,
        editIsSubmit,
        setEditIsSubmit,
        fetchdata,
        examRoutineList,
        editPop,
        setEditPop,
        toggleEditPopup,
        handleEdit,
        editdata,
        classList,
        termList,
        selectClass,
        setSelectClass,
        selectTerm,
        setSelectTerm,
        eCloseChecked,
        setECloseChecked,
      }}
    >
      {props.children}
    </ExamRoutineContext.Provider>
  );
}

export default ExamRoutineState;
