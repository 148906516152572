import React, { useContext, useState, useEffect } from 'react'
import CalendarEventContext from '../contextfolder/CalendarEventContext/CalendarEventContext';
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";


export default function CalendarEventEdit({ closeEditPop }) {

    const context = useContext(CalendarEventContext)

    const { toggleEditPopup, eventFormError, setEventFormError, editIsSubmit, setEditIsSubmit, eventEFormValue, setEventEFormValue, editdata, closeEChecked, setCloseEChecked } = context;

    const handleOnChange = () => {
        setCloseEChecked(!closeEChecked);
    };

    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;
        setEventEFormValue({ ...eventEFormValue, [name]: value });
    };

    const handleEventDate = ({ adDate }) => {
        setEventEFormValue({ ...eventEFormValue, eventDate: adDate });
    };


    const formSubmit = (e) => {
        e.preventDefault();
        setEventFormError(validate(eventEFormValue));
        setEditIsSubmit(true);
    }

    useEffect(() => {
        if (Object.keys(eventFormError).length === 0 && editIsSubmit) {
            editdata(eventEFormValue)
            if (closeEChecked === true) {
                toggleEditPopup();
            }
            setEditIsSubmit(false);
        }

    }, [eventFormError]);

    const validate = (values) => {

        const errors = {};
        if (!values.title) {
            errors.title = "Required";
        }
        if (!values.description) {
            errors.description = "Required";
        }
        if (!values.eventDate) {
            errors.eventDate = "Required";
        }
        if (!values.holiday) {
            errors.holiday = "Required";
        }
        return errors;
    };



    return (
        <>
            <div className="container-fluid basicform" >

                <form >

                    <div className="form-group">
                        <label className="form-label" htmlFor="title" style={{ fontSize: "12px" }}>
                            Title<sup style={{ color: "red" }}>*</sup>
                        </label>
                        <input
                            id="title"
                            style={{ fontSize: "13px" }}
                            type="text"
                            className="form-control form-control-sm "
                            name="title"
                            value={eventEFormValue.title}
                            onChange={handleChange}
                        />
                        <p className="errormsg " style={{ marginBottom: "0" }}>{eventFormError.title}</p>

                    </div>

                    <div className="form-group">
                        <label className="form-label" htmlFor="description" style={{ fontSize: "12px" }}>
                            Description<sup style={{ color: "red" }}>*</sup>
                        </label>
                        <textarea
                            id="description"
                            style={{ fontSize: "13px" }}
                            type="text"
                            className="form-control form-control-sm "
                            name="description"
                            value={eventEFormValue.description}
                            onChange={handleChange}
                        />
                        <p className="errormsg " style={{ marginBottom: "0" }}>{eventFormError.description}</p>

                    </div>

                    <div className="form-row">

                        <div className="form-group col-md-6">
                            <label className="form-label" htmlFor="eventDate" style={{ fontSize: "12px" }}>
                                Date<sup style={{ color: "red" }}>*</sup>
                            </label>
                            <Calendar
                                className="form-control form-control-sm mb-1"
                                dateFormat="YYYY/MM/DD"
                                theme="default"
                                language="en"
                                values={eventEFormValue.eventDate}
                                onChange={handleEventDate}
                            />
                            <p className="errormsg " style={{ marginBottom: "0" }}>{eventFormError.eventDate}</p>
                        </div>


                        <div className="form-group col-md-6">
                            <label className="form-label" htmlFor="holiday">Holiday/Event <sup style={{ color: "red" }}>*</sup></label>
                            <select id="holiday" name="holiday"
                                onChange={handleChange}
                                value={eventEFormValue.holiday} className="form-control form-control-sm mb-1" style={{ fontSize: '12px' }}>
                                <option value="" selected disabled>Select Option</option>
                                <option value="H">Holiday</option>
                                <option value="E">Event</option>
                            </select>
                            <p className="errormsg " style={{ marginBottom: "0" }}>{eventFormError.holiday}</p>
                        </div>

                    </div>
                </form>


            </div>

            <div className="FormFooter">
                <div className='checkedClose'>
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck2"
                        name="closeChecked"
                        onChange={handleOnChange}
                        checked={closeEChecked}
                    />
                    <label class="form-check-label" for="exampleCheck2">
                        Exit after update
                    </label>
                </div>
                <div>
                    <button className='btn btn-sm btn-cmpy ml-2' style={{ background: "green" }} onClick={formSubmit} >Update</button>
                    <button className='btn btn-sm btn-cmpy ml-2' onClick={closeEditPop}>Cancel</button>
                </div>
            </div>

        </>

    )
}
