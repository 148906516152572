import React, { useState, useEffect, useContext } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../App.css";
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import bankingimage from "../images/banking.png";
import "../css/login.css";
import AuthContext from "./auth-context";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import Tippy from "@tippyjs/react";
import TimeOutPopUp from "./TimeOutPopUp";
import SessionContext from "./SessionContext";
import { Fetchdata } from "./hooks/getData";

export default function Login() {
  const authCtx = useContext(AuthContext);
  const { isActive, setIsActive } = useContext(SessionContext);

  const initalvalue = { SchoolID: "", Password: "", UserName: "" };
  const [formValues, setFormValues] = useState(initalvalue);
  const [formErrors, setformErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [passwordType, setPasswordType] = useState("password");

  // const togglePassword = () => {
  //     if (passwordType === "password") {
  //         setPasswordType("text")
  //         return;
  //     }
  //     setPasswordType("password")
  // }

  const appURL = process.env.REACT_APP_URL;

  const date = new Date();
  let year = date.getFullYear();

  let navigate = useNavigate();

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setformErrors(validate(formValues));
    setIsSubmit(true);
  };

  let location = useLocation();

  const fetchdata = async (dataToSend) => {
    const response = await fetch(`${appURL}api/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataToSend),
    });
    const cooptive = await response.json();

    return cooptive;
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      const dataForm = {
        SchID: formValues.SchoolID,
        Password: formValues.Password,
        UserName: formValues.UserName,
        NotificationToken: "ewqewqe",
        DeviceID: "1",
        Source: "A",
      };

      fetchdata(dataForm).then(function (result) {
        const resp =
          result.sessionRes === null ? result.sessionRes : result.sessionRes[0];
        const res = resp;
        const results = result.StatusCode;
        if (results === 200) {
          const userInfo = {
            UserType: res.UserType,
            UserName: res.UserName,
            UserImage: res.UserImage,
            SchPhoto: res.SchPhoto,
            SchLogo: res.SchLogo,
            SchName: res.SchName,
            SchTel: res.SchTel,
            SchID: res.SchID,
            UserID: res.UserID,
            SchAddress: res.SchAddress,
            SchContactPerson: res.SchContactPerson,
            MarksSheetType: res.MarksSheetType,
            Phone: res.Phone,
            Email: res.Email,
            AboutUs: res.AboutUs,
            Msg: res.Msg,
            LocLat: res.LocLat,
            LocLong: res.LocLong,
            SchWebsite: res.SchWebsite,
            SchTwitter: res.SchTwitter,
            SchYt: res.SchYt,
            SchFb: res.SchFb,
            FullName: res.FullName,
            AcademicYear: res.AcademicYear,
            ClassName: res.ClassName,
            RollNo: res.RollNo,
            SectionName: res.SectionName,
            SectionID: res.SectionID,
            ClassID: res.ClassID,
            AdmissionDate: res.AdmissionDate,
            DateOfBirth: res.DateOfBirth,
            Religion: res.Religion,
            BloodGroup: res.BloodGroup,
            House: res.House,
            MedicalHistory: res.MedicalHistory,
            TempAddress: res.TempAddress,
            PermAddress: res.PermAddress,
            GuardianName: res.GuardianName,
            GuardianOccupation: res.GuardianOccupation,
            GuardianPhone: res.GuardianPhone,
            FatherName: res.FatherName,
            FatherOccupation: res.FatherOccupation,
            FatherPhone: res.FatherPhone,
            MotherName: res.MotherName,
            MotherOccupation: res.MotherOccupation,
            MotherPhone: res.MotherPhone,
            SchExpiry: res.SchExpiry,
          };

          userInfo.UserType === "A" && AdminRole(userInfo);

          setTimeout(() => {
            localStorage.setItem("userInfo", JSON.stringify(userInfo));
            sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
            setIsActive(true);
            authCtx.login(res.UserType);
            userInfo.UserType === "S"
              ? navigate("/student-dashboard")
              : navigate("/dashboard");
            setIsSubmit(false);
          }, 1000);
        } else {
          setIsSubmit(false);
          setformErrors({
            ...formErrors,
            errorv: result.Message,
          });
        }
      });
      // .catch((err) => {

      //     setIsSubmit(false)
      //     setformErrors({ ...formErrors, errorv: "Error" })
      // })

      setIsSubmit(false);
    } else {
      setIsSubmit(false);
    }
  }, [formErrors]);

  const validate = (values) => {
    const errors = {};
    if (!values.SchoolID) {
      errors.SchoolID = "School ID is required";
    }
    if (!values.UserName) {
      errors.UserName = "Username is required";
    }
    if (!values.Password) {
      errors.Password = "Password  is required";
    }
    return errors;
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const showPassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  const AdminRole = (userInfo) => {
    const params = {
      SchID: userInfo.SchID,
      UserID: "-1",
      Flag: "S",
      MemID: "-1",
      Type: "POST",
      FetchURL: `${appURL}api/latest-blog?SchID=ES25&Count=-1`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.teachingres ? result.teachingres : "";
        localStorage.setItem("rolePermission", JSON.stringify(postResult));
        sessionStorage.setItem("rolePermission", JSON.stringify(postResult));
      } else {
      }
    });
  };

  useEffect(() => {
    authCtx.logout();
    sessionStorage.clear();
    localStorage.clear();
  }, []);

  return authCtx.isLoggedIn ? (
    <Navigate to="/dashboard" replace state={{ from: location }} />
  ) : (
    <>
      {isActive === false ? <TimeOutPopUp /> : <></>}
      <div className="login-page">
        <div className="uk-container">
          <div className=" login-container">
            <div className="uk-grid uk-grid-match uk-flex-middle uk-child-width-1-2@s m-0 resp-login">
              <div className="p-0 img-wrapper">
                <div className=" login-form-2 text-center">
                  <div className="login-wrapper">
                    <h3>
                      Welcome to the <br /> <span>easy software</span>
                    </h3>
                    <h6>Banking Software</h6>
                    <OwlCarousel items={1} margin={20} autoplay={true}>
                      <div className="item">
                        <img src={bankingimage} alt="bankimage" />{" "}
                      </div>
                      <div className="item">
                        <img src={bankingimage} alt="bankimage" />
                      </div>
                      <div className="item">
                        <img src={bankingimage} alt="bankimage" />
                      </div>
                    </OwlCarousel>
                    <p>{year} Easy Software Pvt.Ltd. All Rights Reserved</p>
                  </div>
                  <div className="overlay"></div>
                </div>
              </div>

              <div className="form-warpper">
                <div className=" login-form-1 ">
                  <div className="text-center">
                    <h5>Log in</h5>

                    <p>Enter your valid credentials below</p>
                  </div>
                  <form>
                    <p className="errormsg"> {formErrors.errorv}</p>

                    <div className="form-group">
                      <label htmlFor="text">School ID</label>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        name="SchoolID"
                        onChange={handleChange}
                        value={formValues.SchoolID}
                        placeholder="Enter your ID"
                      />

                      <p className="errormsg mt-1">{formErrors.SchoolID}</p>
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Username</label>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        name="UserName"
                        value={formValues.UserName}
                        onChange={handleChange}
                        placeholder="Enter your username"
                      />

                      <p className="errormsg mt-1">{formErrors.UserName}</p>
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">Password</label>
                      <div className="password-field">
                        <input
                          type={passwordType}
                          className="form-control form-control-sm"
                          name="Password"
                          value={formValues.Password}
                          onChange={handleChange}
                          placeholder="Enter your password"
                        />

                        <span
                          className="toggle-password"
                          uk-toggle="target: .toggle"
                          onClick={showPassword}
                        >
                          <Tippy content="Show Password">
                            <i className="toggle">
                              <FaRegEyeSlash />
                            </i>
                          </Tippy>

                          <Tippy content="Hide Password">
                            <i className="toggle" hidden>
                              <FaRegEye />
                            </i>
                          </Tippy>
                        </span>
                      </div>
                      {/* {passwordType === "password" ? <i className="bi bi-eye-slash" onClick={togglePassword}></i> : <i className="bi bi-eye" onClick={togglePassword}></i>} */}

                      <p className="errormsg mt-1">{formErrors.Password}</p>
                    </div>
                    <div className="form-group ">
                      <button
                        type="submit"
                        onClick={handleSubmit}
                        className="btnSubmit"
                      >
                        {isSubmit ? (
                          <span>Loading ...</span>
                        ) : (
                          <span>Login</span>
                        )}
                      </button>
                    </div>
                  </form>
                  <div className="text-center">
                    <p style={{ marginTop: "10px", marginBottom: "1rem" }}>
                      By{" "}
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          openInNewTab("https://easysoftware.com.np/")
                        }
                      >
                        <span style={{ color: "red" }}>Easy</span>{" "}
                        <span style={{ color: "#0079bf" }}>Software</span>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
