import React, { useContext, useEffect, useState } from 'react'
import StudentMenuContext from '../contextfolder/StudentMenuContext/StudentMenuContext'
import jsPDF from "jspdf";
import "jspdf-autotable";

const ViewOrderInfo = ({ closeOrder }) => {
    const userinfo = JSON.parse(localStorage.getItem("userInfo"));


    const { orderInfo } = useContext(StudentMenuContext)

    const [tableValues, setTableValues] = useState([])
    useEffect(() => {
        const arr = [orderInfo.Values]

        if (arr.length > 0) {
            if (arr === undefined) {
                setTableValues()
            }
            else {
                setTableValues(orderInfo.Values)
            }
        }
        else {

        }

    }, [orderInfo.Values])

    const [option, setoption] = useState([])


    useEffect(() => {
        if (tableValues?.length > 0) {
            tableValues.forEach((data) => {
                let opt = {
                    Menu: data.Menu,
                    Price: data.Price,
                    Qty: data.Qty,
                };
                if (!option.some(o => o.MenuID === opt.MenuID)) {
                    setoption(prevOption => [...prevOption, opt]);
                }
            });

        }
    }, [tableValues, option]);




    var itemsPrice = option.reduce((a, c) => a + c.Price * c.Qty, 0);



    //This is to export pdf 

    const dataWithIndex = option.map((d, i) => ({
        ...d,
        Index: i + 1,
        Price: parseFloat(d.Price).toFixed(2),
        Total: parseFloat(d.Qty * d.Price).toFixed(2),
    }));

    const cols = [
        { header: "S.N", field: "Index" },
        { header: "Name", field: "Menu" },
        { header: "Quantity", field: "Qty" },
        { header: "Price", field: "Price" },
        { header: "Total", field: "Total" },
    ];

    const exportColumns = cols.map((col) => ({
        title: col.header,
        dataKey: col.field,
    }));

    const toPdf = () => {
        const doc = new jsPDF("p", "pt", "a4", true);

        var midPage = doc.internal.pageSize.getWidth() / 2;

        // doc.addImage(userinfo.SchLogo, "PNG", 40, 40, 70, 60);

        doc.setFontSize(18);
        doc.text(userinfo.SchName, midPage, 50, null, null, "center");

        doc.setFontSize(15);
        doc.text(
            `${userinfo.SchAddress}`,
            midPage,
            68,
            null,
            null,
            "center"
        );

        doc.setFontSize(10);
        doc.text(`Tel:${userinfo.Phone}, E-mail:${userinfo.Email}`, midPage, 85, null, null, "center");

        doc.setFontSize(11);
        doc.text(`Order ID: ${orderInfo.OrderID}`, 40, 105);

        doc.text(`Full Name: ${orderInfo.FullName}`, 40, 130);

        doc.text(`Date: ${orderInfo.OrderDate} ${" ".repeat(15)} No. of Item: ${orderInfo.NoOfItem} ${" ".repeat(20)} Total Amount: ${parseFloat(orderInfo.Amount).toFixed(2)}`, 40, 155);

        doc.text(`Order Status: ${orderInfo.OrderStatus} ${" ".repeat(7)} Payment Status: ${orderInfo.PaymentStatus}`, 40, 180);



        doc.autoTable({
            startY: 195,
            theme: "grid",
            headStyles: {
                fillColor: [227, 227, 227],
                textColor: [0, 0, 0],
            },
            columns: exportColumns,
            body: dataWithIndex,
        });
        doc.save("ViewOrder.pdf");
    };
    //end




    return (
        <>
            <div className="container-fluid basicform">

                <table className="complain-table" style={{ width: "100%" }}>

                    <thead>
                        <tr className='tableHead viewHead'>
                            <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", position: "relative" }}>OrderID</td>

                        </tr>
                    </thead>

                    <tbody>
                        {orderInfo &&
                            <tr className="viewHead">
                                <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", paddingTop: "0px" }} >
                                    {orderInfo.OrderID}
                                </td>

                            </tr>
                        }
                    </tbody>

                    <thead>
                        <tr className='tableHead viewHead'>
                            <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", position: "relative" }}>Date</td>
                            <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", position: "relative" }}>No. of Item</td>
                            <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", position: "relative" }}>Amount</td>
                        </tr>
                    </thead>
                    <tbody>




                        {orderInfo &&
                            <tr className="viewHead">
                                <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", paddingTop: "0px" }} >
                                    {orderInfo.OrderDate}
                                </td>
                                <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", paddingTop: "0px" }}>
                                    {orderInfo.NoOfItem}
                                </td>
                                <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", paddingTop: "0px" }}>
                                    {parseFloat(orderInfo.Amount).toFixed(2)}
                                </td>
                            </tr>
                        }




                    </tbody>

                    <thead>
                        <tr className='tableHead viewHead'>
                            <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", position: "relative" }}>Full Name</td>
                            <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", position: "relative" }}>Order Status</td>
                            <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", position: "relative" }}>Payment Status</td>
                        </tr>
                    </thead>

                    <tbody>

                        {orderInfo &&
                            <tr className="viewHead">
                                <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", paddingTop: "0px" }} >
                                    {orderInfo.FullName}
                                </td>
                                <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", paddingTop: "0px" }}>
                                    {orderInfo.OrderStatus}
                                </td>
                                <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", paddingTop: "0px" }}>
                                    {orderInfo.PaymentStatus}
                                </td>
                            </tr>
                        }

                    </tbody>

                </table>

                {option.length > 0 ? (
                    <div className="table-responsive br-3" style={{ height: "216px", marginTop: "5px" }}>

                        <table className="table table-striped table-bordereds text-nowrap mb-0">
                            <thead>
                                <tr className="tableHead">
                                    <td className="tc" style={{ width: "60px" }}>S.N.</td>
                                    <td className="tl">Name</td>
                                    <td className="tl">Qty</td>
                                    <td className="tr">Price</td>
                                    <td className="tr">Total</td>
                                </tr>
                            </thead>
                            <tbody>
                                {option.map((item, i) => (
                                    <tr key={i}>
                                        <td className="tc" style={{ width: "50px" }}>{i + 1}</td>
                                        <td className="tl">{item.Menu}</td>
                                        <td className="tl">
                                            {item.Qty} </td>
                                        <td className="tr">{parseFloat(item.Price).toFixed(2)}</td>
                                        <td className="tr">{parseFloat(item.Qty * item.Price).toFixed(2)}</td>
                                    </tr>
                                ))}
                                <tr className="lastt">
                                    <td colspan="4" ><b style={{ paddingLeft: "15px" }}>Total</b></td>
                                    <td className="tr"><b>Rs.&nbsp;{itemsPrice.toFixed(2)}</b></td>

                                </tr>
                            </tbody>
                        </table>

                    </div>
                ) : (
                    <div className="table-responsive br-3" style={{ height: "216px", marginTop: "5px" }}>

                        <div className="text-center mt-4">
                            <p>There are no records to display.</p>
                        </div>
                    </div>
                )}

            </div>

            <div className="FormFooter">
                <div>

                </div>
                <div>

                    <button className='btn btn-sm btn-cmpy ml-2' style={{ background: "green" }} onClick={toPdf} >PDF</button>
                    <button className='btn btn-sm btn-cmpy ml-2' onClick={closeOrder}>Cancel</button>
                </div>
            </div>
        </>
    )
}

export default ViewOrderInfo