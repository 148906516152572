import React, { useContext, useEffect, useState } from "react";
import { Fetchdata } from "../../hooks/getData";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { toast } from "react-toastify";
import LogPhotoContext from "./LogPhotoContext";
import { GetEnglishDate } from "../../hooks/dateConvertor";

function LogPhotoState(props) {
  const { appURL } = useContext(SidebarContext);

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const [closeChecked, setCloseChecked] = useState(false);
  var d = new Date();
  const logPhotoValue = {
    teacher: "",
    title: "",
    date: GetEnglishDate(d),
  };

  const [logPhotoFormValue, setlogPhotoFormValue] = useState(logPhotoValue);
  const [logPhotoFormError, setlogPhotoFormError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const [popup, setPopup] = useState(false);
  const togglePopup = () => {
    setPopup(!popup);
  };

  const [classList, setClassList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [teacherList, setTeacherList] = useState([]);
  const [selectOption, setSelectOption] = useState("DW");

  const [selectClass, setSelectClass] = useState("");
  const [selectSection, setSelectSection] = useState("");
  const [selectTeacher, setSelectTeacher] = useState("");

  const [todaysDate, setTodaysDate] = useState("");

  const [isUploaded, setIsUploaded] = useState(false);
  const [typeFile, setTypeFile] = useState("");
  const [image, setImage] = useState("");

  useEffect(() => {
    getClassList();
  }, []);

  const getClassList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      DFlag: "C",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setClassList(postResult);
      } else {
        setClassList([]);
      }
    });
  };

  useEffect(() => {
    getSectionList();
  }, [selectClass]);

  const getSectionList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID : "-1",
      ClassID: selectClass,
      DFlag: "S",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setSectionList(postResult);
      } else {
        setSectionList([]);
      }
    });
  };

  //API to hit Teacher list

  useEffect(() => {
    teachList();
  }, []);

  const teachList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      Flag: "ST",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/teacher`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.teachingres ? result.teachingres : "";
        setTeacherList(postResult);
      } else {
        setTeacherList([]);
      }
    });
  };

  //API to create log Photo

  const fetchdata = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "i",
      MemID:
        userinfo.UserType === "T"
          ? userinfo.UserID.toString()
          : logPhotoFormValue.teacher,
      Lesson: logPhotoFormValue.title,
      Image: image !== "" ? image.split(",")[1] : "",
      Date: logPhotoFormValue.date,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/log-photo`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getLogPhotoList();
        setImage(null);
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };
  // API for log Photo List
  const [logPhotoList, setLogPhotoList] = useState([]);

  useEffect(() => {
    getLogPhotoList();
  }, [selectOption, selectTeacher, todaysDate]);

  const getLogPhotoList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag:
        userinfo.UserType === "T" ? "SU" : selectOption === "DW" ? "SD" : "SU",
      MemID:
        userinfo.UserType === "T"
          ? userinfo.UserID.toString()
          : selectOption === "DW"
          ? ""
          : selectTeacher,

      Date: selectOption === "DW" ? todaysDate.todayDate : "",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/log-photo`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.AttenLst ? result.AttenLst : "";
        setLoading(false);
        setLogPhotoList(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        setLogPhotoList([]);
        setOriginalList([]);
      }
    });
  };

  // //Everything to pop up and delete the notification list
  const [deletePopup, setDeletePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const [delId, setDelId] = useState("");

  const handleDelete = async (datas) => {
    setDeletePopup({
      show: true,
    });
    setDelId(datas.LessonID);
  };

  // This will perform the deletion and hide the Confirmation Box

  const handleDeleteTrue = async () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      MemID:
        userinfo.UserType === "T"
          ? userinfo.UserID.toString()
          : selectTeacher.toString(),
      Flag: "R",
      LessonID: delId.toString(),
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/log-photo`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          getLogPhotoList();
          toast(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
        } else {
          toast(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
    });
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleDeleteFalse = () => {
    setDeletePopup({
      show: false,
      data: null,
    });
  };

  // Ends Here

  return (
    <LogPhotoContext.Provider
      value={{
        loading,
        originalList,
        deletePopup,
        handleDeleteTrue,
        handleDeleteFalse,
        handleDelete,

        logPhotoList,
        setLogPhotoList,
        logPhotoFormValue,
        setlogPhotoFormValue,
        logPhotoFormError,
        setlogPhotoFormError,
        isSubmit,
        logPhotoValue,
        setIsSubmit,
        classList,
        sectionList,
        teacherList,
        selectClass,
        setSelectClass,
        selectSection,
        setSelectSection,
        selectTeacher,
        setSelectTeacher,

        closeChecked,
        setCloseChecked,

        fetchdata,

        todaysDate,
        setTodaysDate,

        isUploaded,
        setIsUploaded,
        typeFile,
        setTypeFile,
        image,
        setImage,
        popup,
        setPopup,
        togglePopup,
        selectOption,
        setSelectOption,
      }}
    >
      {props.children}
    </LogPhotoContext.Provider>
  );
}

export default LogPhotoState;
