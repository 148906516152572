import React, { useEffect, useState } from "react";
import Aos from "aos";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Fetchdata } from "../hooks/getData";
import placeholder from "./../../images/school/placeholder.png";
import { FaPhoneAlt } from "react-icons/fa";

const Team = ({ schoolInfo }) => {
  const appURL = process.env.REACT_APP_URL;
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  useEffect(() => {
    Aos.init();
  }, []);

  //API for class list
  const [teamList, setTeamList] = useState([]);
  useEffect(() => {
    getTeamList();
  }, [schoolInfo]);

  const getTeamList = () => {
    const params = {
      SchID: schoolInfo?.SchID,
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/staff`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.DesignationLst ? result.DesignationLst : "";
        setTeamList(postResult);
      } else {
        setTeamList([]);
      }
    });
  };

  return (
    <>
      {teamList.length > 0 && (
        <div className="section-b team__section" id="team__section">
          <div className="uk-container">
            <div
              class=" new__title uk-text-left"
              data-aos="fade-up"
              data-aos-anchor-placement="center-bottom"
              data-aos-duration="1000"
            >
              <h2
                className="the__title small"
                style={{
                  color: `${schoolInfo.ScColor}`,
                }}
              >
                Our Team
              </h2>
              <p className="sub__heading">See our Team Members</p>
            </div>
            <div className="my__carousel">
              <OwlCarousel
                className="owl-theme"
                dots={false}
                // autoWidth
                // loop
                margin={30}
                items={3}
                nav
                responsive={{
                  0: {
                    items: 1,
                  },
                  767: {
                    items: 2,
                  },
                  960: {
                    items: 3,
                  },
                }}
              >
                {teamList.map((item) => (
                  <div key={item.MemID}>
                    <div className="my__img">
                      {item.MemImage ? (
                        <img src={item.MemImage} alt="Team" />
                      ) : (
                        <img src={placeholder} alt="Team" />
                      )}
                      <div
                        className="team__contact"
                        style={{
                          background: `${schoolInfo.ScColor}`,
                        }}
                      >
                        <a
                          href={`tel:${item.MemContact}`}
                          data-uk-tooltip={item.MemContact}
                        >
                          <FaPhoneAlt
                            style={{
                              color: "#fff",
                            }}
                          />
                        </a>
                        <h3
                          style={{
                            color: "#fff",
                          }}
                          className="the__title"
                        >
                          {item.MemName}
                        </h3>
                        <span
                          style={{
                            color: "#fff",
                          }}
                          className="sub__title"
                        >
                          {item.Designation}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Team;
