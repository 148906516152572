import React, { useContext } from "react";
import StudentContext from "../contextfolder/StudentContext/StudentContext";


function AboutTab() {


    const { aboutList } = useContext(StudentContext)
    const services = [
        {
            title: "Full Name",
            body:
                aboutList.FullName === null || aboutList.FullName === "-"
                    ? "Not Mentioned"
                    : aboutList.FullName,
        },
        {
            title: "Date Of Birth",
            body: aboutList.DateOfBirth === null || aboutList.DateOfBirth === "-"
                ? "Not Mentioned"
                : aboutList.DateOfBirth,
        },
        {
            title: "Phone Number",
            body:
                aboutList.Phone === null || aboutList.Phone === "-"
                    ? "Not Mentioned"
                    : aboutList.Phone,
        },
        {
            title: "Email",
            body:
                aboutList.Email === null || aboutList.Email === "-"
                    ? "Not Mentioned"
                    : aboutList.Email,
        },
        {
            title: "Religion",
            body:
                aboutList.Religion === null || aboutList.Religion === "-"
                    ? "Not Mentioned"
                    : aboutList.Religion,
        },
        {
            title: "Blood Group",
            body:
                aboutList.BloodGroup === null || aboutList.BloodGroup === "-"
                    ? "Not Mentioned"
                    : aboutList.BloodGroup,
        },
        {
            title: "Home",
            body:
                aboutList.House === null || aboutList.House === "-"
                    ? "Not Mentioned"
                    : aboutList.House,
        },
        {
            title: "Medical History",
            body:
                aboutList.MedicalHistory === null || aboutList.MedicalHistory === "-"
                    ? "Not Mentioned"
                    : aboutList.MedicalHistory,
        },
        {
            title: "Temporary Address",
            body:
                aboutList.TempAddress === null || aboutList.TempAddress === "-"
                    ? "Not Mentioned"
                    : aboutList.TempAddress,
        },
        {
            title: "Permanent Address",
            body:
                aboutList.PermAddress === null || aboutList.PermAddress === "-"
                    ? "Not Mentioned"
                    : aboutList.PermAddress,
        },
        {
            title: "Father's Name",
            body:
                aboutList.FatherName === null || aboutList.FatherName === "-"
                    ? "Not Mentioned"
                    : aboutList.FatherName,
        },
        {
            title: "Father's Occupation",
            body: aboutList.FatherOccupation === null || aboutList.FatherOccupation === "-"
                ? "Not Mentioned"
                : aboutList.FatherOccupation,
        },
        {
            title: "Father's Phone",
            body:
                aboutList.FatherPhone === null || aboutList.FatherPhone === "-"
                    ? "Not Mentioned"
                    : aboutList.FatherPhone,
        },
        {
            title: "Mother's Name",
            body:
                aboutList.MotherName === null || aboutList.MotherName === "-"
                    ? "Not Mentioned"
                    : aboutList.MotherName,
        },
        {
            title: "Mother's Occupation",
            body:
                aboutList.MotherOccupation === null || aboutList.MotherOccupation === "-"
                    ? "Not Mentioned"
                    : aboutList.MotherOccupation,
        },
        {
            title: "Mother's Phone",
            body:
                aboutList.MotherPhone === null || aboutList.MotherPhone === "-"
                    ? "Not Mentioned"
                    : aboutList.MotherPhone,
        },
        {
            title: "Guardian's Name",
            body:
                aboutList.GuardianName === null || aboutList.GuardianName === "-"
                    ? "Not Mentioned"
                    : aboutList.GuardianName,
        },
        {
            title: "Guardian's Occupation",
            body:
                aboutList.GuardianOccupation === null || aboutList.GuardianOccupation === "-"
                    ? "Not Mentioned"
                    : aboutList.GuardianOccupation,
        },
        {
            title: "Guardian's Phone",
            body:
                aboutList.GuardianPhone === null || aboutList.GuardianPhone === "-"
                    ? "Not Mentioned"
                    : aboutList.GuardianPhone,
        },
    ];

    return (
        <>
            <div className="profile-info">
                <div className="profilecontentLogo tl">
                    <div className="profilemgmtImg tl">
                        <img
                            src={aboutList.UserImage}
                            alt="dp"
                            style={{ cursor: "pointer" }}

                        />
                    </div>
                </div>
                <div>
                    <h6 style={{ paddingLeft: "10px", textAlign: "left" }}>{aboutList.FullName}</h6>
                    <p style={{ textAlign: "left", paddingLeft: "10px" }}>
                        Class:&nbsp;{aboutList.ClassName}, Section:&nbsp;{aboutList.SectionName}, Roll No.:&nbsp;{aboutList.RollNo}<br />
                        Admission Date:&nbsp;{aboutList.AdmissionDate} <br />
                        {aboutList.SchName}
                        <br />
                    </p>
                </div>
            </div>
            <div className="basic-center ">
                <div className="basic-dyno-about">
                    {services.map((item, index) => {
                        return (
                            <article key={index} className="basic-info">
                                <h6 className="basic-title">{item.title}</h6>
                                <p style={{ fontSize: "14px" }}>{item.body}</p>
                            </article>
                        );
                    })}
                </div>
            </div>
        </>
    );
}

export default AboutTab;
