import React, { useContext, useRef } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import StudentAssignmentContext from "../contextfolder/StudentAssignmentContext/StudentAssignmentContext";
import StudentViewAssignment from "./StudentViewAssignment";
import { GetNepaliDate } from "../hooks/dateConvertor";
import AcademicHeader from "../AcademicHeader";

export default function StudentAssignmentList() {
  const { todayDate, customStyles, AcademicYear } = useContext(SidebarContext);
  const {
    loading,
    originalList,
    viewPop,
    toggleViewPopup,
    subjectList,
    assignmentList,
    setAssignmentList,
    selectSubject,
    setSelectSubject,
    setImage,
    setIsUploaded,
    viewAssignment,
    chooseStatus,
    setChooseStatus,
    setRemarksFormError,
    setRemarksFormValue,
    remarksvalue,
    newState,
    setNewState,
    option,
    setoption,
  } = useContext(StudentAssignmentContext);

  const searchInput = useRef("");

  const columns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "Subject",
      selector: (row) => row.Subject,
    },
    {
      name: "Assignment",
      selector: (row) => row.Title,
    },
    {
      name: "Start",
      width: "150px",

      selector: (row) => GetNepaliDate(row.StEnglishDate),
    },
    {
      name: "Submission",
      width: "150px",

      selector: (row) => GetNepaliDate(row.DeadLine),
    },
    {
      name: "Point",
      width: "100px",
      selector: (row) => (row.Point === "-1" ? "No Point" : row.Point),
    },
    {
      name: "Status",
      width: "100px",
      selector: (row) =>
        row.AssignStatus === "P"
          ? "Pending"
          : row.AssignStatus === "A"
          ? "Approved"
          : row.AssignStatus === "R"
          ? "Redo"
          : row.AssignStatus === "S"
          ? "Submitted"
          : "Not Valid",
    },

    {
      name: "Action",
      // grow: 0,
      center: true,
      width: "100px",
      selector: (row) => {
        return (
          <>
            {row.IsSubReq === "Y" ? (
              <div className="ln-verition d-flex">
                {row.AssignStatus === "R" || row.AssignStatus === "P" ? (
                  <button
                    className="badge badge-primary"
                    onClick={() => viewAssignment(row.AssignmentID)}
                  >
                    Submit
                  </button>
                ) : (
                  <button
                    className="badge badge-success"
                    onClick={() => viewAssignment(row.AssignmentID)}
                  >
                    View
                  </button>
                )}
              </div>
            ) : (
              <div className="ln-verition d-flex">
                {" "}
                <button
                  className="badge badge-success"
                  onClick={() => viewAssignment(row.AssignmentID)}
                >
                  View
                </button>
              </div>
            )}
          </>
        );
      },
    },
  ];

  const closePopup = (e) => {
    e.preventDefault();
    toggleViewPopup();
    setRemarksFormError({});
    setRemarksFormValue(remarksvalue);
    setImage("");
    setIsUploaded(false);
    setNewState([]);
    setoption([]);
  };

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["Topic"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setAssignmentList(srchResult);
      } else {
        setAssignmentList({});
      }
    } else {
      setAssignmentList(originalList);
    }
  };

  const handleSubject = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectSubject(value);
  };

  const handleStatus = (e) => {
    const target = e.target;
    const value = target.value;
    setChooseStatus(value);
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
        <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">Assignment</div>
            <div className="page-date">
              <div className="sec-content">
                <AcademicHeader
                  todayDate={todayDate}
                  AcademicYear={AcademicYear}
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <>
          <div className="sec-dataTable content-section main-content">
            {loading ? (
              <Spinner />
            ) : (
              <DataTable
                columns={columns}
                data={assignmentList}
                customStyles={customStyles}
                pagination
                fixedHeader
                fixedHeaderScrollHeight="280px"
                highlightOnHover
                pointerOnHover
                responsive
                dense
                striped
                subHeader
                subHeaderAlign="left"
                subHeaderComponent={
                  <>
                    <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                      <label className="list-label">Subject</label>
                      <select
                        style={{ fontSize: "11px" }}
                        name="snotifiaction"
                        value={selectSubject}
                        onChange={handleSubject}
                        className="form-control form-control-sm"
                        aria-label="Default select example"
                      >
                        <option
                          value=""
                          disabled
                          selected
                          style={{ fontSize: "11px" }}
                        >
                          Select Subject
                        </option>

                        {subjectList.map((item) => (
                          <option key={item.Subject} value={item.Subject}>
                            {item.SubjectName}
                          </option>
                        ))}
                      </select>
                      <i class="fas fa-angle-down  notificationpositon"></i>
                    </div>

                    <div className="col-md-2 col-sm-2 col-lg-2 dropdown2">
                      <label className="list-label">Status</label>
                      <select
                        style={{ fontSize: "11px", marginBottom: "3px" }}
                        name="snotifiaction"
                        value={chooseStatus}
                        onChange={handleStatus}
                        className="form-control form-control-sm"
                        aria-label="Default select example "
                      >
                        <option
                          value=""
                          disabled
                          selected
                          style={{ fontSize: "11px" }}
                        >
                          Select Day
                        </option>
                        <option value="-1">All</option>
                        <option value="P">Pending</option>
                        <option value="A">Approved</option>
                        <option value="R">Redo</option>
                        <option value="S">Submitted</option>
                      </select>
                      <i class="fas fa-angle-down  notificationpositon"></i>
                    </div>

                    <div
                      className="col-lg-2 offset-lg-6 offset-md-6 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
                      style={{
                        marginTop: "25px",
                        paddingRight: "1px",
                        marginBottom: "10px",
                      }}
                    >
                      <div className="d-flex">
                        <input
                          placeholder="Search"
                          ref={searchInput}
                          type="text"
                          className="form-control form-control-sm searchField"
                          onChange={searchHandler}
                        />
                      </div>
                    </div>
                  </>
                }
              />
            )}
          </div>
        </>
      </div>

      {viewPop && (
        <div className="modals">
          <div className="overlay"></div>

          <div className="notification-model-content">
            <div className="popUpHeader ">
              <div className="popUpHeaderText">View Assignment</div>
              <div style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-x "
                  onClick={closePopup}
                  style={{ fontSize: "25px" }}
                ></i>
              </div>
            </div>
            <div className="inside-modal">
              <StudentViewAssignment closePopup={closePopup} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
