import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Commission.css";
import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";
import "tippy.js/dist/tippy.css";
import { Fetchdata } from "../hooks/getData";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Tippy from "@tippyjs/react";
import { GetTwelveHourFormatTime, GetNepaliDate } from "../hooks/dateConvertor";

export default function StudentExam() {
  const { customStyles, appURL } = useContext(SidebarContext);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);

  const [termList, setTermList] = useState([]);
  const [selectTerm, setSelectTerm] = useState("");

  //API to get term list
  useEffect(() => {
    getTermList();
  }, []);

  const getTermList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      DFlag: "T",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";

        setLoading(false);
        setTermList(postResult);
      } else {
        setTermList([]);
      }
    });
  };

  // API for class routine list
  const [examRoutineList, setExamRoutineList] = useState([]);

  useEffect(() => {
    getClassRoutineList();
  }, [selectTerm]);

  const getClassRoutineList = () => {
    const params = {
      SchID: userinfo.SchID,
      RoutineType: "E",
      ClassID: userinfo.ClassID.toString(),
      SectionID: userinfo.SectionID.toString(),
      TermID: selectTerm.toString(),
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/routine`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.RoutineLst ? result.RoutineLst : "";
        setLoading(false);
        setExamRoutineList(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        setExamRoutineList([]);
        setOriginalList([]);
      }
    });
  };

  const handleTerm = (e) => {
    const target = e.target;
    const value = target.value;
    setSelectTerm(value);
  };

  const searchInput = useRef("");

  const columns = [
    {
      name: "S.N.",
      width: "63px",
      center: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "Date",
      selector: (row) => GetNepaliDate(row.ExamDate),
    },
    {
      name: "Room No.",
      width: "150px",
      selector: (row) => row.RoomNo,
    },
    {
      name: "Subject",
      selector: (row) => row.Subject,
    },
    {
      name: "Day",
      selector: (row) => row.Week,
    },
    {
      name: "Start Time",
      selector: (row) => GetTwelveHourFormatTime(row.StartTime),
    },
    {
      name: "End Time",
      selector: (row) => GetTwelveHourFormatTime(row.EndTime),
    },
    {
      name: "Shift",
      selector: (row) => row.Shift,
    },
  ];

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["Title"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setExamRoutineList(srchResult);
      } else {
        setExamRoutineList({});
      }
    } else {
      setExamRoutineList(originalList);
    }
  };

  /* This is for export csv */

  const dataToExcel = examRoutineList.map((d, i) => ({
    "S.N.": i + 1,
    Date: d.ExamDate,
    "Room No.": d.RoomNo,
    Subject: d.Subject,
    Day: d.Week,
    Time: d.StartTime,
    Shift: d.Shift,
  }));

  const toExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(dataToExcel);
      var wscols = [
        { wch: 5 },
        { wch: 20 },
        { wch: 20 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ];
      worksheet["!cols"] = wscols;
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "Exam_Routine");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_" + "export" + EXCEL_EXTENSION
        );
      }
    });
  };

  //end

  //This is to export pdf

  const dataWithIndex = examRoutineList.map((d, i) => ({
    ...d,
    Index: i + 1,
  }));

  const cols = [
    { header: "S.N", field: "Index" },
    { header: "Date", field: "ExamDate" },
    { header: "Room No.", field: "RoomNo" },
    { header: "Subject", field: "Subject" },
    { header: "Day", field: "Week" },
    { header: "Time", field: "StartTime" },
    { header: "Shift", field: "Shift" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const toPdf = () => {
    const doc = new jsPDF("p", "pt", "a4", true);

    var midPage = doc.internal.pageSize.getWidth() / 2;

    // doc.addImage(`${userinfo.SchPhoto}`, "JPG", 40, 40, 70, 60);

    doc.setFontSize(18);
    doc.text(`${userinfo.SchName}`, midPage, 50, null, null, "center");

    doc.setFontSize(15);
    doc.text(`${userinfo.SchAddress}`, midPage, 68, null, null, "center");

    doc.setFontSize(10);
    doc.text(
      `Tel:${userinfo.Phone}, E-mail:${userinfo.Email}`,
      midPage,
      85,
      null,
      null,
      "center"
    );

    doc.autoTable({
      startY: 100,
      theme: "grid",
      columns: exportColumns,
      body: dataWithIndex,
    });
    doc.save("Exam_Routine.pdf");
  };
  //end

  // API for class routine list
  const [examPdf, setExamPdf] = useState([]);
  useEffect(() => {
    getExamPdf();
  }, [selectTerm]);

  const getExamPdf = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "S",
      ClassID: userinfo.ClassID.toString(),
      TermID: selectTerm.toString(),
      RoutineType: "E",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/attach-routine`,
    };
    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.RoutineLst ? result.RoutineLst : "";
        setExamPdf(postResult);
      } else {
        setExamPdf([]);
      }
    });
  };

  return (
    <>
      <div className="sec-dataTable">
        <div className="upper-dataTbl">
          <Tippy content="PDF">
            <button className="exportButtons ml-1" onClick={toPdf}>
              <i className="bi bi-file-earmark-pdf-fill exportButtonIconsPdf"></i>
            </button>
          </Tippy>
          <Tippy content="Excel">
            <button className="exportButtons" onClick={toExcel}>
              <i className="bi bi-file-earmark-excel-fill exportButtonIconsExcel"></i>
            </button>
          </Tippy>
        </div>
        <hr className="new-hr-new" />
        {loading ? (
          <Spinner />
        ) : (
          <>
            <DataTable
              columns={columns}
              data={examRoutineList}
              customStyles={customStyles}
              pagination
              fixedHeader
              fixedHeaderScrollHeight="280px"
              highlightOnHover
              pointerOnHover
              responsive
              dense
              striped
              subHeader
              subHeaderAlign="left"
              subHeaderComponent={
                <>
                  <div className="col-md-2 col-sm-2 col-lg-2 dropdown">
                    <label className="list-label">Term</label>
                    <select
                      style={{ fontSize: "11px", marginBottom: "3px" }}
                      name="snotifiaction"
                      value={selectTerm}
                      onChange={handleTerm}
                      className="form-control form-control-sm"
                      aria-label="Default select example "
                    >
                      <option
                        value=""
                        disabled
                        selected
                        style={{ fontSize: "11px" }}
                      >
                        Select Term
                      </option>
                      {termList.map((item) => (
                        <option key={item.TermID} value={item.TermID}>
                          {item.TermName}
                        </option>
                      ))}
                    </select>
                    <i class="fas fa-angle-down  notificationpositon"></i>
                  </div>

                  {/* <div
                    className="col-md-1 col-sm-1 col-lg-1 dropdown"
                    style={{ padding: "0px" }}
                  >
                    {examPdf.map((item) => (
                      <div key={item.RoutineID}>
                        <Tippy content="Routine PDF">
                          <button
                            className="exportButtons ml-1"
                            style={{ marginTop: "15px" }}
                            onClick={() => window.open(item.Attachment)}
                          >
                            <i className="bi bi-file-earmark-pdf-fill exportButtonIconsPdf"></i>
                          </button>
                        </Tippy>
                      </div>
                    ))}
                  </div> */}

                  <div
                    className="col-lg-2 offset-lg-7 offset-md-7 offset-sm-0 col-md-2 col-sm-2 align-self-end Search"
                    style={{
                      marginTop: "15px",
                      paddingRight: "1px",
                      marginBottom: "10px",
                    }}
                  >
                    <div className="d-flex">
                      <input
                        placeholder="Search"
                        ref={searchInput}
                        type="text"
                        className="form-control form-control-sm searchField"
                        onChange={searchHandler}
                      />
                    </div>
                  </div>
                </>
              }
            />
          </>
        )}
      </div>
    </>
  );
}
