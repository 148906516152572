import React from "react";
import "./StudentProfile.css";

function Family({ userinfo }) {
    const services = [
        {
            title: "Father's Name",
            body:
                userinfo.FatherName === null || userinfo.FatherName === "-"
                    ? "Not Mentioned"
                    : userinfo.FatherName,
        },
        {
            title: "Father's Occupation",
            body: userinfo.FatherOccupation === null || userinfo.FatherOccupation === "-"
                ? "Not Mentioned"
                : userinfo.FatherOccupation,
        },
        {
            title: "Father's Phone",
            body:
                userinfo.FatherPhone === null || userinfo.FatherPhone === "-"
                    ? "Not Mentioned"
                    : userinfo.FatherPhone,
        },
        {
            title: "Mother's Name",
            body:
                userinfo.MotherName === null || userinfo.MotherName === "-"
                    ? "Not Mentioned"
                    : userinfo.MotherName,
        },
        {
            title: "Mother's Occupation",
            body:
                userinfo.MotherOccupation === null || userinfo.MotherOccupation === "-"
                    ? "Not Mentioned"
                    : userinfo.MotherOccupation,
        },
        {
            title: "Mother's Phone",
            body:
                userinfo.MotherPhone === null || userinfo.MotherPhone === "-"
                    ? "Not Mentioned"
                    : userinfo.MotherPhone,
        },
        {
            title: "Guardian's Name",
            body:
                userinfo.GuardianName === null || userinfo.GuardianName === "-"
                    ? "Not Mentioned"
                    : userinfo.GuardianName,
        },
        {
            title: "Guardian's Occupation",
            body:
                userinfo.GuardianOccupation === null || userinfo.GuardianOccupation === "-"
                    ? "Not Mentioned"
                    : userinfo.GuardianOccupation,
        },
        {
            title: "Guardian's Phone",
            body:
                userinfo.GuardianPhone === null || userinfo.GuardianPhone === "-"
                    ? "Not Mentioned"
                    : userinfo.GuardianPhone,
        },

    ];

    return (
        <div className="basic-center ">
            <div className="basic-dyno">
                {services.map((item, index) => {
                    return (
                        <article key={index} className="basic-info">
                            <h6 className="basic-title">{item.title}</h6>
                            <p style={{ fontSize: "14px" }}>{item.body}</p>
                        </article>
                    );
                })}
            </div>
        </div>
    );
}

export default Family;
