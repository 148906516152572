import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import '../../css/Commission.css';
import Spinner from "../loading/spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";


export default function ClassView() {
    const { classSearch, setClassSearch, customStyles, loading, classList, setClassList } = useContext(SidebarContext);


    const searchInput = useRef("");

    const columns = [
        {
            name: "S.N.",
            width: "63px",
            center: true,
            cell: (row, index) => index + 1,
        },
        {
            name: "Class",
            selector: (row) => row.ClassName,
        },
        {
            name: "Male",
            width: "140px",
            selector: (row) => row.Male,
        },
        {
            name: "Female",
            width: "140px",
            selector: (row) => row.Female,
        },
        {
            name: "Total",
            width: "140px",
            selector: (row) => row.Total,
        }

    ];





    const searchHandler = (e) => {
        e.preventDefault();

        const srchQuery = searchInput.current.value.toLowerCase();
        if (srchQuery) {

            let srchResult = classSearch.filter((list) => {
                return list["ClassName"].toLowerCase().includes(srchQuery);
            });

            if (srchResult) {

                setClassList(srchResult);
            } else {
                setClassList({});
            }
        } else {
            setClassList(classSearch);
        }
    };

    return (


        <>
            <div className="sec-dataTable">

                {loading ? (
                    <Spinner />
                ) : (
                    <DataTable
                        columns={columns}
                        data={classList}
                        customStyles={customStyles}
                        pagination
                        fixedHeader
                        fixedHeaderScrollHeight="280px"
                        highlightOnHover
                        pointerOnHover
                        responsive
                        dense
                        striped
                        subHeader
                        subHeaderAlign="left"
                        subHeaderComponent={
                            <>
                                <div className="col-lg-2 offset-lg-10 offset-md-10 offset-sm-0 col-md-2 col-sm-2 align-self-end Search" style={{ paddingRight: "1px", marginBottom: "10px" }}>
                                    <div className="d-flex">
                                        <input
                                            placeholder="Search"
                                            ref={searchInput}
                                            type="text"
                                            className="form-control form-control-sm searchField"
                                            onChange={searchHandler}
                                        />
                                    </div>
                                </div>
                            </>
                        }
                    />
                )}
            </div>
        </>
    );
}
