import React, { useContext, useState, useEffect } from 'react'
import { useRef } from 'react';
import DataTable from 'react-data-table-component';
import Spinner from '../loading/spinner';
import SidebarContext from '../sidebarcontext/SidebarContext';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import MenuContext from '../contextfolder/MenuContext/MenuContext';
import MenuCategoryActivate from './MenuActivate';
import MenuCategoryDeactivate from './MenuDeactivate';


export default function MenuCategory({ closeAlbumPopup }) {

    const context = useContext(MenuContext)

    const { categoryPopup,
        setCategoryPopup,
        categoryFormValue,
        setCategoryFormValue,
        categoryFormError,
        setCategoryFormError,
        categoryIsSubmit,
        setCategoryIsSubmit,
        categoryTopicSearch, disEdit, setDisEdit, categoryData, menuCategoryList, setMenuCategoryList, editData, selectUser, deactivateCategory, closeChecked, setCloseChecked, categoryValue, toggleCategoryPopup,


    } = context;

    const userinfo = JSON.parse(localStorage.getItem("userInfo"));

    const handleOnChange = () => {
        setCloseChecked(!closeChecked);
    };

    const { customStyles } = useContext(SidebarContext)


    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;
        setCategoryFormValue({ ...categoryFormValue, [name]: value });
    };

    const formSubmit = (e) => {
        e.preventDefault();
        setCategoryFormError(validate(categoryFormValue));
        setCategoryIsSubmit(true);
    }
    useEffect(() => {
        if (Object.keys(categoryFormError).length === 0 && categoryIsSubmit) {
            categoryData(categoryFormValue)
            if (closeChecked === true) {
                toggleCategoryPopup();
            }
            setCategoryIsSubmit(false);
            setCloseChecked(false);
            setCategoryFormValue(categoryValue)
        }

    }, [categoryFormError]);





    const validate = (values) => {
        const errors = {};
        if (!values.categoryName) {
            errors.categoryName = "Required";
        }


        return errors;
    };

    const changeStatus = (cID, IsActive) => {
        deactivateCategory(cID, IsActive);
    };

    const checkStatus = (IsActive) => {
        if (IsActive === "A") {
            return "Deactivate";
        } else if (IsActive === "I") {
            return "Activate";
        }
    }




    const columns = [
        {
            name: "S.N.",
            width: "59px",
            cell: (row, index) => index + 1,
        },

        {
            name: "Category Name",
            center: true,
            // grow: 0,
            selector: (row) => row.Name,
        },

        {
            name: "No. of Items",
            center: true,
            // grow: 0,
            selector: (row) => row.NoOfItem,
        },
        {
            name: "Status",
            center: true,
            selector: (row) => {
                return (
                    <>
                        <>

                            <div className="ln-verition d-flex">
                                <button
                                    type="button"
                                    class="badge badge-success"
                                    onClick={() => changeStatus(
                                        row.CategoryID, row.IsActive
                                    )}
                                >
                                    {checkStatus(row.IsActive)}
                                </button>

                            </div>

                        </>

                    </>
                );
            },
        },
        {
            name: "Action",
            center: true,
            selector: (row) => {
                return (
                    <>
                        <>
                            <div className="ln-verition d-flex">
                                <Tippy content="Edit">
                                    <i
                                        className="fa fa-pencil solvespan--action"
                                        onClick={() => selectUser(row)}
                                    >
                                    </i>
                                </Tippy>

                            </div>
                        </>

                    </>
                );
            },
        },

    ];

    const searchInput = useRef("");
    const searchHandler = (e) => {
        e.preventDefault();

        const srchQuery = searchInput.current.value.toLowerCase();
        if (srchQuery) {
            let srchResult = categoryTopicSearch.filter((list) => {
                return (
                    list["NoOfItem"].toLowerCase().includes(srchQuery) ||
                    list["Name"].toLowerCase().includes(srchQuery)
                );
            });

            if (srchResult) {
                setMenuCategoryList(srchResult);
            } else {
                setMenuCategoryList({});
            }
        } else {
            setMenuCategoryList(categoryTopicSearch);
        }
    };



    return (
        <>

            <div className="container-fluid basicform" >

                <form >




                    <div className="form-group">
                        <label className="form-label" htmlFor="categoryName" style={{ fontSize: "12px" }}>
                            Category Name<sup style={{ color: "red" }}>*</sup>
                        </label>
                        <input
                            id="name"
                            style={{ fontSize: "13px" }}
                            type="text"
                            className="form-control form-control-sm "
                            name="categoryName"
                            value={categoryFormValue.categoryName}
                            onChange={handleChange}
                        />
                        {categoryFormError.categoryName && (
                            <p className="errormsg">{categoryFormError.categoryName}</p>
                        )}
                    </div>




                </form>

                <div className="sec-dataTable">

                    {/* {loading ? (
                        <Spinner />
                    ) : ( */}
                    <DataTable
                        columns={columns}
                        data={menuCategoryList}
                        customStyles={customStyles}
                        pagination
                        fixedHeader
                        fixedHeaderScrollHeight="280px"
                        highlightOnHover
                        pointerOnHover
                        responsive
                        dense
                        striped
                        subHeader
                        subHeaderAlign="right"
                        subHeaderComponent={
                            <>
                                <div className="upper-dataTbl">
                                    <div className="d-flex">
                                        <input
                                            placeholder="Search"
                                            ref={searchInput}
                                            type="text"
                                            className="form-control form-control-sm searchField"
                                            onChange={searchHandler}
                                        />
                                    </div>
                                </div>
                            </>
                        }
                    />
                    {/* )} */}
                </div>
            </div>
            <div className="FormFooter">
                <div className='checkedClose'>
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck2"
                        name="closeChecked"
                        onChange={handleOnChange}
                        checked={closeChecked}
                    />
                    <label class="form-check-label" for="exampleCheck2">
                        Exit after {!disEdit ? "submit" : "update"}
                    </label>
                </div>
                <div>

                    {!disEdit ?
                        <button className='btn btn-sm btn-cmpy ml-2' style={{ background: "green" }} onClick={formSubmit} >Submit</button> :
                        <button className='btn btn-sm btn-cmpy ml-2' style={{ background: "green" }}
                            onClick={editData}
                        >Update</button>
                    }
                    <button className='btn btn-sm btn-cmpy ml-2' onClick={closeAlbumPopup}>Cancel</button>

                </div>
            </div>


        </>

    )
}
